import { translate } from 'app/i18n/src/locale';
import pt from 'app/i18n/src/locale/pt';
import { normalize } from 'app/services/utils';
import colors from 'app/styles/colors';
import fonts from 'app/styles/fonts';
import React from 'react';
import { Image, StyleSheet, Text } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import partnerIcon from '../../../../images/icon_business_light.png';

const styles = StyleSheet.create({
  container: {
    paddingVertical: 4,
    paddingHorizontal: 12,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 50,
  },
  img: {
    marginRight: 5,
  },
  text: {
    color: colors.white,
    fontFamily: fonts.MEDIUM,
    fontSize: normalize(12),
  },
});

const IconCorp = ({ userType, style, imgStyle, textStyle }) => {
  var colorsGradient = [colors.blueD8, colors.blueF5];
  if (userType === 'partner') {
    colorsGradient = [colors.blueD8, colors.blueF5];
  } else {
    colorsGradient = [colors.aquaMarineTwo, colors.aquaMarineTwoEC];
  }
  return (
    <LinearGradient useAngle angle={90} colors={colorsGradient} style={[styles.container, style]}>
      <Image style={[styles.img, imgStyle]} resizeMode="contain" source={partnerIcon} />
      <Text style={[styles.text, textStyle]}>
        {userType === 'partner' ? translate(pt.namu_partner) : translate(pt.business)}
      </Text>
    </LinearGradient>
  );
};
export default IconCorp;
