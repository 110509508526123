import { StyleSheet } from 'react-native';
import colors from './colors';
import fonts from './fonts';
import textStyles from './textStyles';

const commons = StyleSheet.create({
  flex: {
    flex: 1,
  },
  flexRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  flexRowCenter: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  flexRowBetween: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  flexRowEnd: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  justifyBetween: {
    justifyContent: 'space-between',
  },
  alignEnd: {
    alignItems: 'flex-end'
  },
  flexCenter: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  alignCenter: {
    alignItems: 'center',
  },
  defaultHorizontalMargin: {
    marginHorizontal: 20,
  },
  round: {
    marginLeft: 12,
    width: 36,
    height: 36,
    borderRadius: 18,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.blackAlpha18,
  },
  shadow: {
    shadowColor: colors.greyBlue,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 4,
    shadowOpacity: 0.18,
  },
  tabUnderline: {
    backgroundColor: colors.charcoalGreyTwo,
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
  },
  tabTitle: { fontFamily: fonts.REGULAR, fontSize: 14 },

  input: {
    ...textStyles.semi16,
    textAlign: 'center',
    marginTop: 24,
    marginBottom: 12,
    height: 45,
    borderRadius: 5,
    backgroundColor: colors.white,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: colors.lightPeriwinkle,
  },
  hitSlop: { top: 10, left: 10, bottom: 10, right: 10 },
  marginH4: { marginHorizontal: 4 },
  marginH8: { marginHorizontal: 8 },
  marginH14: { marginHorizontal: 14 },
  marginH16: { marginHorizontal: 16 },
  marginV4: { marginVertical: 4 },
  marginV8: { marginVertical: 8 },
  marginV16: { marginVertical: 16 },
});

export default commons;
