import produce from 'immer';

const INITIAL_STATE = {
  trailSelected: {},
};

export default function trail(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@trail/SET_TRAIL_SELECTED': {
        draft.trailSelected = action.payload.trailSelected;
        break;
      }
    }
  });
}
