import produce from 'immer';

const INITIAL_STATE = {
  currentGoal: {
    id: 1,
    createdAt: '2023-04-17T17:37:09.000Z',
    updatedAt: '2023-04-17T17:37:09.000Z',
    name: '3 dias ativos',
    badgeId: 4,
    total_points: 30,
    difficulty_level: 1,
    goal_message:
      'Parabéns por completar 3 dias de atividade! Você está no caminho para um estilo de vida mais saudável e feliz! Pronto para elevar a dificuldade da sua meta e alcançar resultados ainda maiores? Vamos juntos!',
  },
  isShowBoarding: false,
  startedJourney: false,
  userData: {},
};

export default function gamification(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@gamification/SET_CURRENT_GOAL': {
        draft.currentGoal = action.payload.data;
        break;
      }
      case '@gamification/SET_STARTED_JOURNEY': {
        draft.startedJourney = action.payload.value;
        break;
      }
      case '@gamification/SET_USER_DATA': {
        draft.userData = action.payload.data;
        break;
      }
      case '@gamification/SET_IS_SHOW_BOARDING': {
        draft.isShowBoarding = action.payload.status;
        break;
      }
      case '@auth/SIGN_OUT': {
        draft.currentGoal = INITIAL_STATE.currentGoal;
        draft.userData = INITIAL_STATE.userData;
        draft.isShowBoarding = INITIAL_STATE.isShowBoarding;
        draft.startedJourney = INITIAL_STATE.startedJourney;
        break;
      }
      default:
        return state;
    }
  });
}
