import pt from 'app/i18n/src/locale/pt';
import colors from 'app/styles/colors';
import fonts from 'app/styles/fonts';
import React from 'react';
import { Dimensions, Image, StyleSheet, Text, View, Modal } from 'react-native';
import { translate } from 'app/i18n/src/locale';
import ButtonBaseWhite from 'app/components/ButtonBaseWhite';

const ProfessionalTutorialModal = ({ open, onConfirm }) => {
  return (
    <Modal transparent style={styles.container} coverScreen visible={open}>
      <View style={styles.content}>
        <Text style={styles.title}>{translate(pt.welcome_namu_professional_area)}</Text>
        <Image source={require('app/images/profissional_circle.png')} style={styles.image} />
        <Text style={styles.subtitle}>
          {translate(pt.welcome_namu_professional_orientation_message)}
        </Text>
      </View>
      <View style={styles.bottomContainer}>
        <ButtonBaseWhite
          onPress={() => onConfirm(false)}
          color={colors.white}
          style={styles.buttonNotYet}
          title={translate(pt.not_yet)}
        />
        <ButtonBaseWhite
          onPress={() => onConfirm(true)}
          color={colors.white}
          style={styles.buttonLetsGo}
          title={translate(pt.lets_go)}
        />
      </View>
    </Modal>
  );
};

export default ProfessionalTutorialModal;

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 0,
  },
  content: {
    borderRadius: 20,
    marginHorizontal: 10,
    maxHeight: '90%',
    alignItems: 'center',
    width: '100%',
    paddingBottom: 20,
  },
  title: {
    fontFamily: fonts.BOLD,
    fontSize: 20,
    textAlign: 'center',
    color: colors.white,
  },
  subtitle: {
    fontFamily: fonts.BOLD,
    fontSize: 16,
    textAlign: 'center',
    color: colors.white,
  },
  image: {
    marginVertical: 20,
    width: '80%',
    height: 'auto',
    resizeMode: 'contain',
    aspectRatio: 1,
  },
  bottomContainer: {
    position: 'absolute',
    bottom: 40,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonNotYet: {
    minWidth: undefined,
    paddingHorizontal: 25,
    backgroundColor: 'transparent',
  },
  buttonLetsGo: {
    minWidth: undefined,
    paddingHorizontal: 25,
    backgroundColor: colors.aquaMarineTwo,
    borderRadius: 10,
  },
});
