import React, { useEffect } from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { NavigationContainer, useNavigation } from '@react-navigation/native';
import LoginScreen from 'app/screens/login/LoginScreen';
import { Image, Text, View } from 'react-native';
import HomeScreenNew from 'app/screens/core/home/HomeScreenNew';
import ManuChat from './screens/core/manuchat/ManuChat';
import SearchScreenNew from './screens/core/explorer/SearchScreenNew';
import BlogScreen from './screens/core/blog/BlogScreen';
import InternalBlogScreen from './screens/core/forum/InternalBlogScreen';
import ListClassesScreen from './screens/core/explorer/ListClassesScreen';
import LessonScreen from './screens/core/explorer/LessonScreen';
import LessonVideoClassScreenNew from './screens/LessonVideoClassScreenNew';
import MeditationLessonScreen from './screens/core/explorer/MeditationLessonScreen';
import MeditationPlayScreen from './screens/core/meditation/MeditationPlayScreen';
import TrailScreen from './screens/core/explorer/TrailScreen';
import PlateDetails from './screens/core/meal/components/PlateDetails';
import MealDetailScreen from './screens/core/explorer/MealDetailScreen';
import OnboardingScreen from './screens/intro/OnboardingScreenNew';
import LoadingScreenNew from './screens/profile/LoadingScreenNew';
import MeditationTrailScreen from './screens/core/explorer/MeditationTrailScreen';
import MealTrailScreen from './screens/core/explorer/MealTrailScreen';
import ListProgramsScreen from './screens/core/explorer/ListProgramsScreen';
import PaymentCompleteCCScreenNew from 'app/screens/core/payment/PaymentCompleteCCScreenNew';
import PaymentCompleteBillScreenNew from 'app/screens/core/payment/PaymentCompleteBillScreenNew';

import iconGym from './images/iconGym.png';
import iconHome from './images/iconHome.png';
import iconExplorer from './images/iconExplorar.png';
import iconForum from './images/iconChat.png';
import colors from './styles/colors';
import ListCoursesScreen from './screens/core/explorer/ListCoursesScreen';
import MasterClassDetailScreen from './screens/core/explorer/MasterClassDetailScreen';
import { useDispatch, useSelector } from 'react-redux';
import { setShowFavorites } from './store/modules/favorites/actions';
import { useGamification } from './hooks/useGamification';
import ProfileScreen from './screens/profile/ProfileScreen';
import { BirthdateEditing } from './screens/profile/components/BirthdateEditing';
import { GenderEditing } from './screens/profile/components/GenderEditing';
import ActivityOnboardingCheckList from './screens/core/activities/ActivityOnboardingCheckList';
import { GoalEditing } from './screens/core/gamification/GoalEditing';
import ObjectiveScreen from './screens/profile/ObjectiveScreen';
import InterestsScreen from './screens/profile/InterestsScreen';
import MealOnboardingRestrictScreen from './screens/core/meal/MealOnboardingRestrictScreen';
import ChronicDiseaseOnboardingRestrictScreen from './screens/core/chronicDesease/ChronicDiseaseOnboardingRestrictScreen';
import HomeChangeHourScreen from './screens/core/home/HomeChangeHourScreen';
import NotLoggedScreen from './screens/login/NotLoggedScreen';
import FilterScreen from './screens/core/explorer/FilterScreen';
import FilterCoursesScreen from './screens/core/explorer/FilterCoursesScreen';
import GymScreen from './screens/core/gym/GymScreen';
import ShowGymsScreen from './screens/core/gym/ShowGymsScreen';
import ShowGymsFilterScreen from './screens/core/gym/ShowGymsFilterScreen';
import GymDetailScreen from './screens/core/gym/GymDetailScreen';
import CheckinConfirmationScreen from './screens/core/gym/CheckinConfirmationScreen';
// import {useLocation} from 'react-router-dom';
import iconProfessional from 'app/images/iconProfessional.png';
import ProfessionalScreen from './screens/core/professional/ProfessionalScreen';
import PaymentScreen from './screens/core/payment/PaymentScreenNew';
import ChatAttachmentsScreen from './screens/core/chatAttachments/ChatAttachmentsScreen';
import CallScreenNew from './screens/core/callService/CallScreenNew';
import AttendanceHistory from './screens/core/professional/AttendanceHistory';
import CreditPurchases from './screens/core/professional/orientation/CreditPurchases';
import SuggestionScreen from './screens/profile/SuggestionScreen';
import CallServiceFeedbackScreen from './screens/core/callService/CallServiceFeedbackScreen';
import HandleActivity from './screens/core/activities/components/HandleActivity';
import HydrationScreen from './screens/core/hydration/HydrationScreen';
import MoodScreen from './screens/core/mood/MoodScreen';
import BusinessVerificationScreen from './screens/login/BusinessVerificationScreen';
import CreateAccountScreen from './screens/login/CreateAccountScreen';
import CalendarScreen from './screens/core/calendar/CalendarScreen';
import ProfessionalProfile from './screens/core/professionalProfile/ProfessionalProfile';
import ChangeHourScreen from './screens/core/calendar/ChangeHourScreen';
import ChangeDateScreen from './screens/core/calendar/ChangeDateScreen';
// import HydrationScreen from './screens/core/hydration/HydrationScreen';


const Tab = createBottomTabNavigator();
const Stack = createStackNavigator();

const Tabs = () => {

  const dispatch = useDispatch();
  const { profile } = useSelector(state => state.user);
  const navigation = useNavigation();

  useEffect(() => {
    const handleBrowserBack = () => {
      navigation.goBack();
    };

    window.addEventListener('popstate', handleBrowserBack);

    return () => {
      window.removeEventListener('popstate', handleBrowserBack);
    };
  }, [window.history]);


  const { closeBoarding, eventsGenerationByHandleActivity } = useGamification();
  return (
    <Tab.Navigator screenListeners={{
      tabPress: async (e) => {
        const tab = e.target.split('-')[0];
        try {
          const { data } = await HandleActivity({routeName: tab});
          eventsGenerationByHandleActivity(data);
        } catch (err) {
          console.log('ERR', err);
        }
      }
    }} screenOptions={() => ({ headerShown: false, tabBarActiveTintColor: colors.aquaMarineTwo })} >
      <Tab.Screen
        name="Início"
        listeners={{tabPress: () => {
            dispatch(setShowFavorites(false));
            closeBoarding();
        }}}
        component={HomeScreenNew}
        options={{
          tabBarIcon: ({ size, focused, color }) => <Image
            style={{ width: size, height: size }}
            source={{
              uri: iconHome,
            }}
            tintColor={focused ? colors.aquaMarineFour : colors.blackTwo}
          />
        }}
      />
      <Tab.Screen
        options={{
          tabBarIcon: ({ size, focused, color }) => <Image
            style={{ width: size, height: size }}
            source={{
              uri: iconExplorer,
            }}
            tintColor={focused ? colors.aquaMarineFour : colors.blackTwo}
          />
        }}
        name="Aulas"
        component={SearchScreenNew}
      />
      {profile?.plan_meta?.gym_enabled === true &&
        <Tab.Screen
          options={{
            tabBarIcon: ({ size, focused, color }) => <Image
              style={{ width: size, height: size, resizeMode: 'contain' }}
              source={{
                uri: iconGym,
              }}
              tintColor={focused ? colors.aquaMarineFour : colors.blackTwo}
            />
          }}
          name="Academias"
          component={GymScreen}
        />
      }
      <Tab.Screen
        options={{
          tabBarIcon: ({ size, focused, color }) => <Image
            style={{ width: size, height: size }}
            source={{
              uri: iconForum,
            }}
            tintColor={focused ? colors.aquaMarineFour : colors.blackTwo}
          />
        }}
        name="Blog"
        component={BlogScreen}
      />
      {profile?.plan_meta?.professional_enabled &&
        <Tab.Screen
          options={{
            tabBarIcon: ({ size, focused, color }) => <Image
              style={{ width: size, height: size, resizeMode: 'contain' }}
              source={{
                uri: iconProfessional,
              }}
              tintColor={focused ? colors.aquaMarineFour : colors.blackTwo}
            />
          }}
          name="Profissionais"
          component={ProfessionalScreen}
        />
      }
    </Tab.Navigator>
  );
};

export default function WebRoutes() {
  const { eventsGenerationByHandleActivity } = useGamification();
  return (
    <NavigationContainer onStateChange={async (e) => {
      const currentScreen = [...e.routes].pop();
      try {
        const { data } = await HandleActivity({
          routeName: currentScreen?.name,
          params: currentScreen?.params
        });
        eventsGenerationByHandleActivity(data);
      } catch (err) {
        console.log('ERR', err);
      }
    }}>
      <Stack.Navigator screenOptions={{ headerShown: false }} >
        <Stack.Screen name="CreateAccount" component={CreateAccountScreen} />
        <Stack.Screen name="Login" component={LoginScreen} />
        <Stack.Screen name="BusinessVerification" component={BusinessVerificationScreen} />
        <Stack.Screen name="NotLogged" component={NotLoggedScreen} />
        <Stack.Screen name="Onboarding" component={OnboardingScreen} />
        <Stack.Screen name="Loading" component={LoadingScreenNew} />
        <Stack.Screen name="Main" component={Tabs} />
        <Stack.Screen name="Manuchat" component={ManuChat} />
        <Stack.Screen name="BlogPost" component={InternalBlogScreen} />
        <Stack.Screen name="ShowMoreClasses" component={ListClassesScreen} />
        <Stack.Screen name="Filter" component={FilterScreen} />
        <Stack.Screen name="FilterCourses" component={FilterCoursesScreen} />
        <Stack.Screen name="LessonActivity" component={LessonScreen} />
        <Stack.Screen name="LessonMeditation" component={MeditationLessonScreen} />
        <Stack.Screen name="ActivityPlayer" component={LessonVideoClassScreenNew} />
        <Stack.Screen name="PlayMeditation" component={MeditationPlayScreen} />
        <Stack.Screen name="Trail" component={TrailScreen} />
        <Stack.Screen name="MeditationTrail" component={MeditationTrailScreen} />
        <Stack.Screen name="MealTrail" component={MealTrailScreen} />
        <Stack.Screen name="ShowMorePrograms" component={ListProgramsScreen} />
        <Stack.Screen name="ShowMoreCourses" component={ListCoursesScreen} />
        <Stack.Screen name="MasterclassDetail" component={MasterClassDetailScreen} />
        <Stack.Screen name="PlateDetail" component={PlateDetails} />
        <Stack.Screen name="MealDetail" component={MealDetailScreen} />
        <Stack.Screen name="Profile" component={ProfileScreen} />
        <Stack.Screen name="BirthdateEditing" component={BirthdateEditing} />
        <Stack.Screen name="GenderEditing" component={GenderEditing} />
        <Stack.Screen name="GoalEditing" component={GoalEditing} />
        <Stack.Screen name="Objectives" component={ObjectiveScreen} />
        <Stack.Screen name="Interests" component={InterestsScreen} />
        <Stack.Screen name="MealOnboardingRestrict" component={MealOnboardingRestrictScreen} />
        <Stack.Screen name="ChronicDeseaseOnboardingRestrict" component={ChronicDiseaseOnboardingRestrictScreen} />
        <Stack.Screen name="HomeChangeHour" component={HomeChangeHourScreen} />
        <Stack.Screen name="ActivityOnboardingCheckList" component={ActivityOnboardingCheckList} />
        <Stack.Screen name="ShowGyms" component={ShowGymsScreen} />
        <Stack.Screen name="ShowGymsFilter" component={ShowGymsFilterScreen} />
        <Stack.Screen name="GymDetail" component={GymDetailScreen} />
        <Stack.Screen name="CheckinConfirmation" component={CheckinConfirmationScreen} />
        <Stack.Screen name="Payment" component={PaymentScreen} />
        <Stack.Screen name="Suggestion" component={SuggestionScreen} />
        <Stack.Screen name="PaymentCompleteCC" component={PaymentCompleteCCScreenNew} />
        <Stack.Screen name="PaymentCompleteBill" component={PaymentCompleteBillScreenNew} />
        <Stack.Screen name="ChatAttachments" component={ChatAttachmentsScreen} />
        <Stack.Screen name="CallService" component={CallScreenNew} />
        <Stack.Screen name="CallServiceFeedback" component={CallServiceFeedbackScreen} />
        <Stack.Screen name="Attendance" component={AttendanceHistory} />
        <Stack.Screen name="CreditPurchases" component={CreditPurchases} />
        <Stack.Screen name="Hydration" component={HydrationScreen} />
        <Stack.Screen name="Mood" component={MoodScreen} />
        <Stack.Screen name="Schedule" component={CalendarScreen} />
        <Stack.Screen name="ProfessionalProfile" component={ProfessionalProfile} />
        <Stack.Screen name="ExplorerScheduleAdd" component={SearchScreenNew} />
        <Stack.Screen name="ChangeHour" component={ChangeHourScreen} />
        <Stack.Screen name="ChangeDate" component={ChangeDateScreen} />

      </Stack.Navigator>
    </NavigationContainer>
  );
}
