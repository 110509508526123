import React from 'react';
import { View, Text, StyleSheet, ImageBackground, TouchableOpacity } from 'react-native';
import colors from 'app/styles/colors';
import LinearGradient from 'react-native-linear-gradient';
import Spacer from 'app/components/Spacer';
import textStyles from 'app/styles/textStyles';
import CapsuleClockNew from 'app/components/CapsuleClockNew';
import CapsuleCategoryNew from 'app/components/CapsuleCategoryNew';

const CardFullImage = ({ blogPost, onPress }) => {
  return (
    <TouchableOpacity onPress={onPress}>
      <View style={styles.container}>
        <ImageBackground
          resizeMode="cover"
          imageStyle={styles.imageStyle}
          source={{ uri: blogPost.image }}
          style={styles.card}>
          <LinearGradient
            colors={[colors.transparent, colors.greyBlue + '88', colors.greyBlue + 'CC']}
            locations={[0, 0.45, 1]}
            style={styles.linear}
          />
          <View style={styles.holder}>
            <Text numberOfLines={2} style={[textStyles.bold18White, styles.titleContainer]}>
              {blogPost.post_title}
            </Text>
            <Spacer value={4} />
            <Text numberOfLines={2} style={[textStyles.regular14White, styles.textContainer]}>
              {blogPost.post_preview}
            </Text>
          </View>
        </ImageBackground>
        <View style={styles.bottom}>
          <CapsuleClockNew
            duration={blogPost.read_time_in_minutes}
            timeIn={'min'}
            style={styles.clock}
            small
          />
          {blogPost.categories?.split(',').map(category => (
            <CapsuleCategoryNew category={category} style={styles.clock} />
          ))}
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default CardFullImage;

const styles = StyleSheet.create({
  imageStyle: { borderRadius: 10 },
  holder: { margin: 16 },
  buttonBase: { marginTop: 0, paddingHorizontal: 0, minWidth: 96 },
  clock: {
    shadowColor: colors.greyBlue,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 2,
    shadowOpacity: 0.1,
    marginRight: 5,
  },
  bottom: {
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 16,
    marginTop: -40,
  },
  linear: {
    ...StyleSheet.absoluteFillObject,
    position: 'absolute',
    borderRadius: 4,
  },
  card: {
    paddingTop: 50,
    height: 241,
    backgroundColor: colors.white,
  },
  container: {
    paddingHorizontal: 18,
  },
  titleContainer: {
    lineHeight: 24,
    height: 48,
    width: 240,
  },
  textContainer: {
    width: 280,
  },
  opaqueBook: {
    marginBottom: 12,
  },
});
