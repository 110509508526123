import React, { useEffect, useRef, useState } from 'react';
import {
  StyleSheet,
  ScrollView,
  Text,
  View,
  Dimensions,
  TouchableOpacity,
  Image,
} from 'react-native';
import * as Animatable from 'react-native-animatable';

import colors from '../../../styles/colors';
import { translate } from '../../../i18n/src/locale';
import pt from '../../../i18n/src/locale/pt';
import fonts from 'app/styles/fonts';
import textStyles from 'app/styles/textStyles';
import ButtonBase from 'app/components/ButtonBase';
import iconList from 'app/assets/iconlist';
import ShadowView from '@liuyunjs/react-native-simple-shadow-view';
import commons from 'app/styles/commons';
import CardMoodByDay from 'app/screens/core/mood/components/CardMoodByDay';
import moment from 'moment';
import HeaderByMonth from 'app/screens/core/mood/components/HeaderByMonth';
import api from 'app/services/api';
import QueryString from 'qs';

const { width } = Dimensions.get('screen');
const cardWidth = (width - 48) / 2;

const STEPS = {
  LOG_VIEW: 0,
  SELECT_MOOD: 1,
  SELECT_REASON: 2,
};

const LogInternal = () => {
  const [currentMonth, setCurrentMonth] = useState(moment());
  const [showSuccess, setShowSuccess] = useState(false);
  const [currentStep, setCurrentStep] = useState(STEPS.LOG_VIEW);

  const [selectedMood, setSelectedMood] = useState(null);
  const [selectedCause, setSelectedCause] = useState(null);

  const successAnimationRef = useRef();
  const reasonViewRef = useRef();

  const [moods, setMoods] = useState([]);
  const [causes, setCauses] = useState([]);
  const [userMoods, setUserMoods] = useState([]);

  const getMoods = async () => {
    const { data } = await api.get('mood');
    setMoods(data);
  };

  const getCauses = async () => {
    const { data } = await api.get('mood/causes');
    setCauses(data);
  };

  const getUserMoods = async () => {
    const { data } = await api.get(`mood/by-month/${moment(currentMonth).format('Y-MM')}`);
    const dataReversed = Object.keys(data)
      .sort()
      .reverse()
      .map(key => ({ date: key, moods: data[key] }));
    setUserMoods(dataReversed);
  };

  const onDeleteMood = () => {
    getUserMoods();
  };

  useEffect(() => {
    getMoods();
    getCauses();
  }, []);

  useEffect(() => {
    getUserMoods();
  }, [currentMonth]);

  const showSuccessMessage = async () => {
    setShowSuccess(true);
    if (successAnimationRef.current) {
      await successAnimationRef.current.fadeIn(1000);
    }

    setTimeout(async () => {
      await successAnimationRef.current.fadeOut(1000);
      setShowSuccess(false);
    }, 3000);
  };

  const saveMood = async () => {
    await api.post(
      'mood',
      QueryString.stringify({
        mood_id: selectedMood,
        cause_id: selectedCause,
      })
    );
    getUserMoods();
    setCurrentStep(STEPS.LOG_VIEW);
    showSuccessMessage();
  };

  const startSaveNewMood = () => {
    if (selectedMood) {
      setSelectedMood(null);
    }
    if (selectedCause) {
      setSelectedCause(null);
    }
    setCurrentStep(STEPS.SELECT_MOOD);
  };

  const renderCapsuleItem = (item, selected, onPress) => {
    return (
      <TouchableOpacity onPress={() => onPress(item.id)}>
        <ShadowView
          style={[
            styles.card_choice,
            {
              borderColor: selected ? colors.aquaMarineTwo : styles.card_choice.borderColor,
              backgroundColor: selected ? colors.aquaMarineTwo : styles.card_choice.backgroundColor,
            },
          ]}>
          <View
            style={{
              ...commons.flex,
              justifyContent: 'center',
              width: item.fill ? cardWidth : null,
              borderRadius: 22.5,
              paddingHorizontal: 14,
            }}>
            <Text style={[textStyles.semi16, { color: selected ? colors.white : colors.black4 }]}>
              {iconList[item.icon] + ' ' + item.name}
            </Text>
          </View>
        </ShadowView>
      </TouchableOpacity>
    );
  };

  const nextMonth = () => {
    setCurrentMonth(moment(currentMonth).add(1, 'month'));
  };

  const previousMonth = () => {
    setCurrentMonth(moment(currentMonth).subtract(1, 'month'));
  };

  return (
    <View style={{ flex: 1, position: 'relative' }}>
      {currentStep === STEPS.LOG_VIEW ? (
        <>
          <Animatable.View animation="fadeInDown" style={{ marginTop: 20, marginHorizontal: 20 }}>
            <HeaderByMonth
              canFuture={false}
              month={currentMonth.locale('pt-br')}
              previousMonth={previousMonth}
              nextMonth={nextMonth}
            />
          </Animatable.View>

          {showSuccess && (
            <Animatable.View
              animation="fadeInDown"
              ref={successAnimationRef}
              style={{
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 15,
                marginBottom: 30,
              }}>
              <Image
                source={require('app/images/checkNewMessage.png')}
                style={{ width: 20, height: 20 }}
              />
              <Text style={[textStyles.regular18, { color: colors.darkPro, marginLeft: 10 }]}>
                {translate(pt.success_saved_log)}
              </Text>
            </Animatable.View>
          )}

          <ScrollView
            style={{ paddingHorizontal: 20 }}
            contentContainerStyle={{ paddingBottom: 100 }}>
            {userMoods.map(item => (
              <CardMoodByDay date={item.date} moods={item.moods} onDelete={onDeleteMood} />
            ))}
          </ScrollView>

          <View style={styles.btnRegisterContainer}>
            <ButtonBase
              fill
              style={styles.buttonRegister}
              title={translate(pt.log_new_mood)}
              onPress={startSaveNewMood}
            />
          </View>
        </>
      ) : currentStep === STEPS.SELECT_MOOD ? (
        <Animatable.View animation="fadeIn" style={{ flex: 1 }}>
          <ScrollView contentContainerStyle={{ paddingTop: 30 }} style={{ marginBottom: 120 }}>
            <View style={{ marginTop: 20 }}>
              <Text style={styles.title}>{translate(pt.your_feeling)}</Text>
            </View>
            <View
              style={{
                marginTop: 20,
                paddingHorizontal: 20,
                flexDirection: 'row',
                flexWrap: 'wrap',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              {moods.map(mood => {
                return renderCapsuleItem(mood, selectedMood === mood.id, setSelectedMood);
              })}
            </View>
          </ScrollView>
          <View style={styles.btnRegisterContainer}>
            <ButtonBase
              disabled={!selectedMood}
              fill
              style={{ height: 49 }}
              onPress={() => {
                setCurrentStep(STEPS.SELECT_REASON);
              }}
              title={translate(pt.forward)}
            />
          </View>
        </Animatable.View>
      ) : currentStep === STEPS.SELECT_REASON ? (
        <Animatable.View ref={reasonViewRef} style={{ flex: 1 }}>
          <ScrollView contentContainerStyle={{ justifyContent: 'center', paddingTop: 30 }}>
            <View style={{ marginTop: 20 }}>
              <Text style={styles.title}>{translate(pt.feeling_cause)}</Text>
            </View>
            <View
              style={{
                marginTop: 20,
                paddingHorizontal: 20,
                flexDirection: 'row',
                flexWrap: 'wrap',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              {causes.map(reason => {
                return renderCapsuleItem(reason, selectedCause === reason.id, setSelectedCause);
              })}
            </View>
          </ScrollView>
          <View style={styles.btnRegisterContainer}>
            <ButtonBase
              disabled={!selectedCause}
              fill
              style={{ height: 49 }}
              title={translate(pt.register)}
              onPress={saveMood}
            />
            <TouchableOpacity
              style={{ marginTop: 20 }}
              onPress={() => setCurrentStep(STEPS.SELECT_MOOD)}>
              <Text
                style={{
                  fontFamily: fonts.BOLD,
                  color: colors.darkPro2,
                  fontSize: 16,
                  textAlign: 'center',
                }}>
                {translate(pt.back)}
              </Text>
            </TouchableOpacity>
          </View>
        </Animatable.View>
      ) : null}
    </View>
  );
};

export default LogInternal;

const styles = StyleSheet.create({
  title: {
    fontFamily: fonts.BOLD,
    fontSize: 22,
    color: colors.darkPro,
    textAlign: 'center',
    lineHeight: 26,
  },
  card_choice: {
    borderWidth: 1,
    borderColor: '#C9CEE7',
    marginRight: 8,
    marginBottom: 10,
    height: 45,
    borderRadius: 22.5,
    backgroundColor: colors.white,
    alignContent: 'center',
    justifyContent: 'center',
  },
  btnRegisterContainer: {
    position: 'absolute',
    padding: 26,
    bottom: 20,
    width: '100%',
  },
  buttonRegister: {
    height: 49,
  },
});
