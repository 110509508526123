import React from 'react';

import { StyleSheet, Text, Image } from 'react-native';
import fonts from '../styles/fonts';
import colors from '../styles/colors';
import ShadowView from '@liuyunjs/react-native-simple-shadow-view';
import clock from '../images/clock.png';
import { calcDuration } from 'app/services/utils';

const CapsuleHour = ({ style, timeIn = 's', duration = 0, textStyle }) => {
  return (
    <ShadowView style={[styles.capsule, style]}>
      <Image source={clock} style={{ width: 14, height: 14, resizeMode: 'contain' }} />
      <Text style={{ ...styles.capsuleText, ...textStyle }}>
        {calcDuration(duration, { timeUnit: timeIn })}
      </Text>
    </ShadowView>
  );
};

export default CapsuleHour;

const styles = StyleSheet.create({
  capsuleText: {
    marginLeft: 4,
    fontFamily: fonts.REGULAR,
    fontSize: 12,
    color: '#666666',
  },
  capsule: {
    paddingHorizontal: 12,
    flexDirection: 'row',
    alignItems: 'center',
    height: 29,
    borderRadius: 18.2,
    backgroundColor: colors.whiteTwo,
  },
});
