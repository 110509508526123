import api from 'app/services/api';
import colors from 'app/styles/colors';
import React, { useEffect, useState } from 'react';
import Icon from 'react-native-vector-icons/dist/FontAwesome';
import { TouchableOpacity } from 'react-native';
import { sendActionEvent } from 'app/services/utils';
import { useDispatch, useSelector } from 'react-redux';
import { errorControl, showAlert } from 'app/services/utils';
import apiNode from 'app/services/apiNode';
import { handleFavorites } from 'app/store/modules/favorites/actions';

const FavoriteButtonShow = ({
  isFavorited,
  containerStyle,
  style,
  color,
  propType,
  itemId,
  size = 18,
}) => {
  const [isFavoritedItem, setIsFavoritedItem] = useState(isFavorited);
  const { profile } = useSelector(state => state.user);

  const { favorites } = useSelector(state => state.favorites);
  const dispatch = useDispatch();

  useEffect(() => {
    if (favorites && favorites.length) {
      let item = favorites.find(x => x.type === propType && x.id === itemId);
      if (item) {
        setIsFavoritedItem(item.checked);
      }
    }
  }, [dispatch, favorites, isFavoritedItem]);

  const getIconFavorite = () => {
    return isFavoritedItem ? 'heart' : 'heart-o';
  };

  const requestAddToFavorites = async () => {
    console.log('FAVORITANDO');
    dispatch(handleFavorites({ type: propType, id: itemId, checked: true }));
    const url = propType === 'trailId' ? '/user-favorite-trails/' : '/user-favorites-classes/';
    try {
      if (propType === 'courseId') {
        await api.post('/masterclass/favorite?course_id=' + itemId);
      } else {
        await apiNode.post(url, {
          [propType]: itemId,
        });
      }
      sendActionEvent(
        'favorites',
        {
          email: profile.email,
          item_type: propType,
          item_id: itemId,
        },
        profile.email
      );
    } catch (error) {
      console.log(errorControl(error));
    }
  };

  const requestRemoveFromFavorites = async () => {
    dispatch(handleFavorites({ type: propType, id: itemId, checked: false }));
    const url = propType == 'trailId' ? '/user-favorite-trails/' : '/user-favorites-classes/';
    try {
      sendActionEvent(
        'favorites',
        {
          email: profile.email,
          item_type: propType,
          item_id: itemId,
        },
        profile.email
      );

      if (propType === 'courseId') {
        await api.delete('/masterclass/favorite?course_id=' + itemId);
      } else {
        await apiNode.delete(url, {
          data: {
            [propType]: itemId,
            userId: profile.user_id,
          },
        });
      }
    } catch (error) {
      showAlert(errorControl(error));
    }
  };

  const handleRequest = () => {
    if (isFavoritedItem) {
      requestRemoveFromFavorites();
    } else {
      requestAddToFavorites();
    }
  };

  return isFavorited === null ? null : (
    <TouchableOpacity
      hitSlop={{ bottom: 2, top: 2, left: 2, right: 2 }}
      onPress={() => {
        handleRequest();
      }}
      style={[containerStyle]}>
      <Icon
        style={style}
        name={getIconFavorite()}
        size={size}
        color={isFavoritedItem ? colors.frenchRose : color ? color : colors.white}
      />
    </TouchableOpacity>
  );
};

export default FavoriteButtonShow;
