import pt from 'app/i18n/src/locale/pt';
import colors from 'app/styles/colors';
import fonts from 'app/styles/fonts';
import React from 'react';
import {
  Dimensions,
  Image,
  Linking,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import ReactNativeModal from 'react-native-modal';
import greenCorner from 'app/images/greenCorner.png';
import api from 'app/services/api';
const { width } = Dimensions.get('screen');
import profile_image from 'app/images/profile_image.png';
import { useDispatch, useSelector } from 'react-redux';

import editMenu from 'app/images/editMenu.svg';
import customizeMenu from 'app/images/customizeMenu.svg';
import membersFamilyMenu from 'app/images/membersFamilyMenu.svg';
import emailMenu from 'app/images/emailMenu.svg';
import lockMenu from 'app/images/lockMenu.svg';
import privacyMenu from 'app/images/privacyMenu.svg';
import signatureMenu from 'app/images/signatureMenu.svg';
import supportMenu from 'app/images/supportMenu.svg';
import trashMenu from 'app/images/trashMenu.svg';
import LogoutIcon from 'app/images/logoutMenu.svg';
import { translate } from 'app/i18n/src/locale';
import { signOut } from 'app/store/modules/auth/actions';
import { checkRequirements, deleteFcmToken } from 'app/services/utils';
import APP from 'app/configs/app';
import textStyles from 'app/styles/textStyles';

import { debounce } from 'lodash';
import PERFORMANCE from 'app/configs/performance';
import { useGamification } from 'app/hooks/useGamification';
import CustomIcon from 'app/styles/icon';
import { useNavigation } from '@react-navigation/native';

const SideMenuModal = ({ open, onClose }) => {
  const navigation = useNavigation();

  const arrMenu = [
    { title: pt.edit_profile, Icon: editMenu, onPress: () => navigation.navigate('Profile') },
    { title: pt.customize_home, Icon: customizeMenu, onPress: () => navigation.navigate('WidgetsControl')},
    {
      title: pt.members_family,
      Icon: membersFamilyMenu,
      onPress: () => navigation.navigate('MembersFamily'),
      features: ['plan_meta.dependent_enabled', '!is_dependent'],
    },
    { title: pt.view_email, Icon: emailMenu, onPress: () => navigation.navigate('ViewEmail') },
    { title: pt.change_password, Icon: lockMenu, onPress: () => navigation.navigate('ChangePassword') },
    { title: pt.terms_and_privacy, Icon: privacyMenu, onPress: () => navigation.navigate('Terms') },
    { title: pt.my_signature, Icon: signatureMenu, onPress: () => navigation.navigate('MySignature') },
    { title: pt.contact_us, Icon: supportMenu, onPress: () => navigation.navigate('Suggestion') },
    {
      title: pt.remove_account,
      Icon: trashMenu,
      onPress: () => {
        Linking.openURL('https://namu.com.br/account-removal');
      },
    },
  ];
  const { profile } = useSelector(state => state.user);
  const { onChangeCurrentGoal } = useGamification();
  const dispatch = useDispatch();

  const requestLogout = async () => {
    try {
      await api.post('logout');
    } catch (error) {}

    try {
      await deleteFcmToken();
    } catch (error) {}

    onChangeCurrentGoal(null);
    navigation.reset('Main');
    dispatch(signOut());
  };

  return (
    <ReactNativeModal
      style={styles.container}
      isVisible={open}
      animationIn={'slideInLeft'}
      animationOut={'slideOutLeft'}
      onBackdropPress={onClose}>
      <View style={styles.content}>
        <Image source={greenCorner} style={styles.bgCorner} />
        <ScrollView
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{ paddingTop: 42, paddingHorizontal: 28 }}>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <View>
              <Image
                style={styles.user}
                source={
                  profile.user_picture
                    ? {
                        uri: profile.user_picture,
                        headers: {
                          Authorization: api.defaults.headers.Authorization,
                        },
                      }
                    : profile_image
                }
              />
              <Text style={styles.profileName}>{profile.name}</Text>
              <Text style={styles.profileEmail}>{profile.email}</Text>
              <Text style={styles.profileMetrics}>
                {profile.weight && profile.weight + 'kg'}
                {profile.height && '  ' + (profile.height / 100).toFixed(2) + 'm'}
              </Text>
            </View>
            <TouchableOpacity
              hitSlop={{ bottom: 1, top: 1, left: 1, right: 1 }}
              onPress={() => onClose()}>
              <CustomIcon name={'close'} size={20} color={colors.black} />
            </TouchableOpacity>
          </View>

          <View style={{ marginTop: 34 }}>
            {arrMenu
              .filter(m => checkRequirements(profile, m.features))
              .map(({ title, Icon, onPress }) => {
                return (
                  <TouchableOpacity
                    style={styles.itemMenu}
                    onPress={debounce(
                      () => (onClose(), onPress()),
                      PERFORMANCE.ON_PRESS_DEBOUNCE_TIME
                    )}>
                    <View style={styles.itemMenuIcon}>
                      <Icon width={20} height={20} />
                    </View>
                    <Text style={styles.itemMenuTitle}>{translate(title)}</Text>
                  </TouchableOpacity>
                );
              })}
            <TouchableOpacity
              style={[styles.itemMenu, { marginTop: 20 }]}
              onPress={() => requestLogout()}>
              <View style={styles.itemMenuIcon}>
                <LogoutIcon width={20} height={20} />
              </View>
              <Text style={styles.itemMenuTitle}>{translate(pt.exit)}</Text>
            </TouchableOpacity>
          </View>
        </ScrollView>
        <Text style={[textStyles.regular12grey, { padding: 28, paddingVertical: 10 }]}>
          {APP.VERSION}
        </Text>
      </View>
    </ReactNativeModal>
  );
};

export default SideMenuModal;

const styles = StyleSheet.create({
  container: {
    margin: 0,
    alignItems: undefined,
    justifyContent: undefined,
  },
  content: {
    backgroundColor: colors.white,
    height: '100%',
    width: width > 400 ? 350 : width * 0.8,
    borderTopRightRadius: 15,
    borderBottomRightRadius: 15,
  },
  user: {
    width: 48,
    height: 48,
    borderRadius: 48,
    resizeMode: 'cover',
  },
  profileName: {
    fontFamily: fonts.BOLD,
    fontSize: 18,
    color: colors.darkPro,
    marginTop: 5,
  },
  profileEmail: {
    fontFamily: fonts.MEDIUM,
    fontSize: 14,
    color: colors.slateGrey,
    marginVertical: 2,
  },
  profileMetrics: {
    fontFamily: fonts.BOLD,
    fontSize: 16,
    color: colors.darkPro,
  },
  itemMenu: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 12,
  },
  itemMenuIcon: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 25,
    height: 25,
  },
  itemMenuTitle: {
    marginLeft: 12,
    fontFamily: fonts.MEDIUM,
    fontSize: 16,
    color: colors.darkPro,
  },
  bgCorner: {
    position: 'absolute',
    top: -20,
    left: 0,
    transform: [{ scaleX: -1 }, { scaleY: -1 }],
    width: 200,
    height: 200,
    resizeMode: 'contain',
    zIndex: -1,
  },
});
