import React from 'react';

import { View, Text, StyleSheet, Image, TouchableOpacity } from 'react-native';
import textStyles from '../../../../styles/textStyles';
import colors from '../../../../styles/colors';
import clock from '../../../../images/clock.png';
import Plus from '../../../../components/Plus';
import Minus from '../../../../components/Minus';
import api from 'app/services/api';
import MaskLevelPayment from 'app/components/mask-level-payment';
import { calcDuration } from 'app/services/utils';

const RowClass = ({ style, isAdd, checked, onPress, payment_level, classObj, onPressAdd }) => {
  const getActionAddIcons = () => {
    if (!classObj.isSelected) {
      return <Plus onPress={onPressAdd} style={styles.top10} />;
    }
    if (classObj.isSelected) {
      return <Minus onPress={onPressAdd} style={styles.top10} />;
    }
  };
  return (
    <TouchableOpacity
      onPress={payment_level === 1 ? null : onPress}
      disabled={checked}
      style={{ opacity: checked ? 0.5 : 1 }}>
      {isAdd && getActionAddIcons()}

      {payment_level === 1 && <MaskLevelPayment />}
      <View style={[styles.card, style]}>
        {classObj ? (
          <Image
            source={{
              uri: classObj.image_path || classObj.image.path,
              headers: {
                Authorization: api.defaults.headers.Authorization,
              },
            }}
            style={styles.image}
          />
        ) : (
          <Image source={{ uri: 'https://picsum.photos/301/301' }} style={styles.image} />
        )}
        <View style={styles.holder}>
          <Text numberOfLines={2} style={[textStyles.semi14, { marginRight: 0 }]}>
            {classObj ? classObj.name : 'Nome do drops'}
          </Text>

          <View style={styles.capsules}>
            <Image source={clock} style={{ height: 15, width: 15, marginRight: 4 }} />
            <Text style={textStyles.regular12grey}>
              {classObj ? calcDuration(classObj.class_duration || classObj.duration) : '40 min'}
            </Text>
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default RowClass;

const styles = StyleSheet.create({
  capsules: {
    alignItems: 'center',
    alignSelf: 'stretch',
    flexDirection: 'row',
  },
  holder: {
    paddingVertical: 10,
    marginLeft: 12,
    paddingRight: 20,
    flex: 1,
    justifyContent: 'center',
  },
  image: {
    width: 81,
    height: 71,
    borderTopLeftRadius: 3,
    borderBottomLeftRadius: 3,
    backgroundColor: colors.base,
  },
  card: {
    flexDirection: 'row',
    marginBottom: 14,
    borderRadius: 3,
    backgroundColor: colors.white,
  },
});
