import React, { useState } from 'react';
import { View, Text, ScrollView, StyleSheet, Image, TouchableOpacity } from 'react-native';
// import { Actions } from 'react-native-router-flux';

import { translate } from 'app/i18n/src/locale';
import pt from 'app/i18n/src/locale/pt';
import colors from 'app/styles/colors';
import textStyles from 'app/styles/textStyles';
import fonts from 'app/styles/fonts';

import HeaderRedLinear from 'app/components/HeaderRedLinear';
import ButtonBase from 'app/components/ButtonBase';
import CapsuleLevelNew from 'app/components/CapsuleLevelNew';
import CheckBox from 'app/components/CheckBox';

import { useEffect } from 'react';
import { useGamification } from 'app/hooks/useGamification';
import commons from 'app/styles/commons';
import LinearGradient from 'react-native-linear-gradient';
import CustomIcon from 'app/styles/icon';
import apiNode from 'app/services/apiNode';
import useGlobalAlert from 'app/hooks/useGlobalAlert';
import { useNavigation } from '@react-navigation/native';

export const GoalPoints = ({ total_points, iconSize, style, textStyle }) => {
  return (
    <LinearGradient
      colors={[colors.aquaMarineTwo, colors.aquaMarineTwo2]}
      useAngle
      angle={90}
      style={[styles.pointsContainer, style]}>
      <CustomIcon name={'game_point'} size={iconSize || 10} color={colors.white} />
      <Text style={[textStyles.regular10white, { color: colors.white, marginLeft: 5 }, textStyle]}>
        {`+ ${total_points || 0} Pontos`}
      </Text>
    </LinearGradient>
  );
};

export const GoalsComponent = ({ goal, onClick, isSelected }) => {
  const getEmoji = name => {
    switch (name) {
      case '3 dias ativos':
        return '🧘';
      case '5 dias ativos':
        return '🏃';
      case '7 dias ativos':
        return '💃';
      case '10 dias ativos':
        return '🤸';
      case '15 dias ativos':
        return '🏋';
      case '30 dias ativos':
        return '🏆';
      default:
        return '';
    }
  };

  const onClickRadio = value => {
    onClick(value);
  };

  const { difficulty_level, name, total_points } = goal;
  return (
    <TouchableOpacity
      style={styles.goalContainer}
      activeOpacity={0.8}
      onPress={() => onClickRadio(goal)}>
      <View style={commons.flexRow}>
        <CapsuleLevelNew
          difficulty={difficulty_level}
          style={{
            backgroundColor: colors.white,
            borderColor: colors.tagGrey,
            marginRight: 5,
          }}
        />
        <Text style={styles.goalNameText}>{`${name} ${getEmoji(name)}`}</Text>
      </View>
      <View style={commons.flexRow}>
        <GoalPoints total_points={total_points} />

        <View style={styles.checkboxContainer}>
          <CheckBox checked={isSelected} size={20} />
        </View>
      </View>
    </TouchableOpacity>
  );
};

export const GoalEditing = () => {
  const [goals, setGoals] = useState([]);
  const [selectedGoal, setSelectedGoal] = useState(null);
  const [ loadingSave, setLoadingSave ] = useState(false);
  const navigation = useNavigation();
  const { setAlert } = useGlobalAlert();

  const { onChangeCurrentGoal, openGoalsModal } = useGamification();

  const onPress = async () => {
    setLoadingSave(true);
    try {
      await apiNode.put('game-users/set-goal', {
        goalId: selectedGoal.id,
      });
      onChangeCurrentGoal(selectedGoal);
      openGoalsModal();
    } catch (e) {
      setAlert('Falha ao salvar meta!');
    }
    setLoadingSave(false);
    navigation.canGoBack() && navigation.goBack();
  };

  useEffect(() => {
    getGoals();
  }, []);

  const getGoals = async () => {
    try {
      const { data } = await apiNode.get('/game-user-achievements/available-goals');

      const dataFiltered = data?.filter(item => item.id < 7);
      setGoals(dataFiltered);
    } catch (error) {}
  };

  const onClickGoal = value => {
    setSelectedGoal(value);
  };

  return (
    <View style={styles.container}>
      <HeaderRedLinear
        title={translate(pt.active_days_target)}
        bgColor={[colors.aquaMarineTwo, colors.aquaMarineTwo2]}
      />
      <ScrollView>
        <View style={styles.contentContainer}>
          <Text style={styles.title}>Defina sua Meta de dias ativo 💪</Text>

          <Text style={styles.contentText}>
            Mantenha-se ativo todos os dias para não zerar sua meta! Sua meta de dias ativo conta
            quantos dias em seguida você esteve ativo. Se você perder um dia a meta é zerada.
          </Text>

          {goals.map(goal => (
            <GoalsComponent
              goal={goal}
              onClick={onClickGoal}
              isSelected={selectedGoal?.name === goal?.name}
            />
          ))}

          <ButtonBase
            backgroundColor={colors.aquaMarineTwo}
            title={translate(pt.set_goals)}
            disabled={!selectedGoal}
            loading={loadingSave}
            style={{ width: '100%' }}
            onPress={onPress}
          />
        </View>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    height: '100dvh'
  },
  contentContainer: {
    padding: 20,
  },
  title: {
    fontFamily: fonts.BOLD,
    fontSize: 20,
    color: colors.black,
    alignSelf: 'center',
    marginBottom: 20,
  },
  contentText: {
    fontFamily: fonts.MEDIUM,
    fontSize: 12,
    color: colors.slateGrey,
    alignSelf: 'center',
    marginBottom: 20,
  },
  goalContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: colors.white,
    marginBottom: 10,
    height: 45,
    borderRadius: 15,
    paddingHorizontal: 15,
  },
  goalNameText: {
    // width: 140,
    fontFamily: fonts.MEDIUM,
    fontSize: 14,
    color: colors.blackTwo,
  },
  pointsContainer: {
    borderRadius: 50,
    paddingHorizontal: 10,
    paddingVertical: 3,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    elevation: 5,
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 6,
    shadowOpacity: 0.2,
  },
  imgStyle: {
    height: 300,
    width: 200,
    marginLeft: 5,
    resizeMode: 'contain',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkboxContainer: {
    padding: 5,
    width: 20,
  },
});
