import React, { useRef, useEffect } from 'react';

import { StyleSheet, TouchableOpacity, Image, StatusBar, View, Text } from 'react-native';
import commons from '../styles/commons';
import chevronLeft from '../images/chevronLeft.png';
import colors from '../styles/colors';
import { useSafeArea } from 'react-native-safe-area-context';
import shareOpaque from '../images/shareOpaque.png';
import textStyles from '../styles/textStyles';
import { shareItem } from 'app/services/utils';
import { useNavigation } from '@react-navigation/native';

const HeaderOver = ({
  position,
  title,
  color = colors.aquaMarineTwo,
  maxSize,
  onBack = null,
  share = true,
  shareData,
}) => {
  const navigation = useNavigation();

  const Animations = {
    over: {
      from: styles.transparent,
      to: styles.whiteBack,
    },
    exit: {
      from: styles.whiteBack,
      to: styles.transparent,
    },
  };

  const safeAreaInsets = useSafeArea();
  const bar = useRef();

  useEffect(() => {
    // bar.current.fadeInDown(1000, 500);
  }, []);

  const show = position > maxSize - 160;
  const animate = show ? Animations.over : Animations.exit;

  return (
    <View
      style={{
        position: 'absolute',
        zIndex: 1000,
        left: 0,
        top: 0,
        right: 0,
        height: 100,
      }}>
      <StatusBar
        translucent
        backgroundColor="transparent"
        barStyle={show ? 'dark-content' : 'light-content'}
      />
      <View
        ref={bar}
        animation={animate}
        style={[commons.flexRowBetween, styles.header, { paddingTop: safeAreaInsets.top + 10 }]}>
        <TouchableOpacity style={{ ...styles.backButton, backgroundColor: color }} onPress={() => onBack || navigation.goBack()}>
          <Image source={chevronLeft} style={styles.backIcon} />
        </TouchableOpacity>
        {/* <Text
          numberOfLines={1}
          useNativeDriver
          animation={show ? 'fadeIn' : 'fadeOut'}
          style={[textStyles.bold20, { width: 250 }]}>
          {title}
        </Text> */}
        {share ? (
          <TouchableOpacity
            style={[styles.shareButton, { color }]}
            onPress={() => {
              shareItem(shareData);
            }}>
            <Image source={shareOpaque} />
          </TouchableOpacity>
        ) : (
          <View />
        )}
      </View>
    </View>
  );
};

export default HeaderOver;

const styles = StyleSheet.create({
  whiteBack: {
    backgroundColor: colors.white,
    borderBottomColor: colors.veryLightPink,
    borderBottomWidth: 1,
  },
  transparent: {
    backgroundColor: colors.transparent,
    borderBottomColor: colors.veryLightPink0,
    borderBottomWidth: 1,
  },
  backIcon: { width: 28, height: 28, tintColor: colors.white },
  shareButton: {
    width: 40,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    backgroundColor: colors.white,
  },
  backButton: {
    width: 40,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
  },
  header: {
    paddingBottom: 10,
    width: '100%',
    position: 'absolute',
    zIndex: 7,
    paddingHorizontal: 15,
  },
});
