import colors from 'app/styles/colors';
import React from 'react';
import { TouchableOpacity, Image } from 'react-native';

const ButtonImage = ({
  onPress,
  source,
  imageStyle,
  disabled = false,
  containerStyle,
  tintColor,
}) => {
  return (
    <TouchableOpacity style={containerStyle} disabled={disabled} onPress={onPress}>
      <Image
        style={[
          imageStyle,
          {
            resizeMode: 'contain',
            tintColor: !disabled ? (tintColor ? tintColor : colors.aquaMarineTwo) : '#d8d8e1',
          },
        ]}
        source={source}
      />
    </TouchableOpacity>
  );
};

export default ButtonImage;
