import React, { useContext, useEffect, useState } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Image, Modal } from 'react-native';
import ButtonBase from 'app/components/ButtonBase';
import Icon from 'react-native-vector-icons/dist/FontAwesome';

import colors from '../../../styles/colors';
import fonts from '../../../styles/fonts';
import textStyles from '../../../styles/textStyles';
import { translate } from '../../../i18n/src/locale';
import pt from '../../../i18n/src/locale/pt';

const INITIAL_OPTIONS = [
  { label: 'Bom atendimento', selected: false },
  { label: 'Limpeza', selected: false },
  { label: 'Qualidade', selected: false },
  { label: 'Boa infraestrutura', selected: false },
  { label: 'Bons equipamentos', selected: false },
  { label: 'Bons profissionais', selected: false },
];

export const GymReviewsModal = props => {
  const { isOpenModal, onClosed, onPress, gym } = props;
  const [currentNote, setCurrentNote] = useState(5);
  const [options, setOptions] = useState(INITIAL_OPTIONS);

  const renderStars = () => {
    let stars = [];
    const note = currentNote - 1;
    for (let i = 0; i < 5; i++) {
      const star =
        note >= i ? (
          <TouchableOpacity onPress={() => setCurrentNote(i + 1)}>
            <Icon name={'star'} size={42} color={colors.yellowOrange} />
          </TouchableOpacity>
        ) : (
          <TouchableOpacity onPress={() => setCurrentNote(i + 1)}>
            <Icon name={'star'} size={42} color={colors.brownGrey} />
          </TouchableOpacity>
        );

      stars.push(star);
    }
    return stars;
  };

  const handleClickActivities = name => {
    setOptions(state => {
      return state.map(item => {
        if (item.label === name) {
          return { ...item, selected: !item.selected };
        }
        return item;
      });
    });
  };

  const handleSubmit = () => {
    console.log('enviou');
    onClosed();
  };

  return (
    <>
      <Modal transparent={true} animationInTiming={600} visible={isOpenModal}>
        <View style={styles.container}>
          <TouchableOpacity onPress={onClosed} style={styles.iconClose}>
            <Icon name={'close'} size={24} color={colors.black2} onPress={onClosed} />
          </TouchableOpacity>

          <View style={styles.logoImgContainer}>
            <Image
              source={{ uri: gym?.images?.logo || '' }}
              style={styles.mainImgStyle}
              width={140}
              height={150}
            />
          </View>

          <View style={styles.titleTextContainer}>
            <Text style={[textStyles.bold16, { color: colors.greyBlue }]}>{gym?.title || ''}</Text>
          </View>

          <View style={styles.textContainer}>
            <Text style={[textStyles.bold16, { textAlign: 'center' }]}>
              Queremos saber como foi sua experiência com a Yofit Academia
            </Text>
          </View>

          <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10 }}>
            {renderStars(2)}
          </View>

          <View style={styles.tagContainer}>
            {options?.map(item => (
              <TouchableOpacity onPress={() => handleClickActivities(item?.label)}>
                <Text style={[styles.cardTag, item.selected && styles.cardTagSelected]}>
                  {item?.label}
                </Text>
              </TouchableOpacity>
            ))}
          </View>

          <ButtonBase
            title={translate(pt.send)}
            style={styles.button}
            backgroundColor={colors.aquaMarineTwo}
            disabledBackgroundColor={colors.aquaMarineTwo}
            onPress={handleSubmit}
          />
        </View>
      </Modal>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    backgroundColor: colors.white,
    borderRadius: 19,
    padding: 32,
  },
  iconClose: {
    position: 'absolute',
    right: 32,
    top: 32,
    width: 24,
    height: 24,
  },
  mainImgStyle: {
    width: 123,
    height: 123,
    borderRadius: 10,
    resizeMode: 'contain',
  },
  logoImgContainer: {
    zIndex: 40,
    width: 140,
    height: 150,
    alignSelf: 'center',
    position: 'relative',
    marginBottom: 20,
  },
  titleTextContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 5,
  },
  textContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    padding: 12,
    marginBottom: 5,
  },
  button: {
    width: 300,
    height: 50,
  },
  tagContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingHorizontal: 10,
  },
  cardTag: {
    borderColor: colors.aquaMarineTwo,
    borderWidth: 2,
    padding: 10,
    paddingHorizontal: 10,
    paddingVertical: 10,
    color: colors.aquaMarineTwo,
    borderRadius: 10,
    width: 125,
    marginBottom: 10,
    marginRight: 15,
    textAlign: 'center',
    fontSize: 11,
  },
  cardTagSelected: {
    color: colors.white,
    backgroundColor: colors.aquaMarineTwo,
  },
});
