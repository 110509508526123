import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Image,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ScrollView,
  Platform,
} from 'react-native';
import Calendar from 'react-calendar';
import ButtonBase from '../../../../components/ButtonBase';
import { translate } from '../../../../i18n/src/locale';
import pt from '../../../../i18n/src/locale/pt';
import closeGrey from '../../../../images/closeGrey.png';
import colors from '../../../../styles/colors';
import fonts from '../../../../styles/fonts';
import textStyles from '../../../../styles/textStyles';
import { useSafeArea } from 'react-native-safe-area-context';
import Icon from 'react-native-vector-icons/FontAwesome';
import { TouchableWithoutFeedback } from 'react-native';
import { errorControl, flashInfo, sendActionEvent, showAlert } from 'app/services/utils';
import moment from 'moment';
import { ActivityIndicator } from 'react-native';
import { showMessage } from 'react-native-flash-message';
import QueryString from 'qs';
import api from 'app/services/api';
import { useSelector } from 'react-redux';
import { Picker } from '@react-native-picker/picker';

import { eachQuarterHour } from '../../../../services/utils';
import Modal from 'react-native-modal';

const food = { key: 'food', color: colors.tomato };
const activity = { key: 'activity', color: colors.aquaMarine };
const meditation = { key: 'meditation', color: colors.pastelOrange };

export const TimeModal = ({
  isOpenModal,
  handleCloseOrOpenModal,
  timesPickerRef,
  onChangeTimeSelected,
  selectedTime,
  times,
}) => {
  const onPressButton = () => {
    handleCloseOrOpenModal();
  };

  const onChangeTime = value => {
    onChangeTimeSelected(value);
  };

  return (
    <Modal animationInTiming={600} isVisible={isOpenModal}>
      <View style={styles.pickerContainer}>
        <Text style={[textStyles.subtitle, { padding: 15, alignSelf: 'center' }]}>
          Selecione o horário
        </Text>
        <Picker 
          ref={timesPickerRef}
          style={styles.wheelPicker}
          selectedValue={selectedTime}
          onValueChange={onChangeTime}>
            {times.map((time, i) => {
              return <Picker.Item style={styles.itemWheelPicker} label={time} value={i} />
            })}
        </Picker>
        <ButtonBase
          onPress={() => {
            onPressButton();
          }}
          backgroundColor={colors.aquaMarineTwo}
          square
          style={styles.pickerButton}
          title={translate(pt.confirm)}
        />
        <ButtonBase
          onPress={() => {
            handleCloseOrOpenModal();
          }}
          backgroundColor={'transparent'}
          fill
          square
          style={styles.pickerButton}
          textStyle={{ color: colors.slateGrey }}
          title={translate(pt.cancel)}
        />
      </View>
    </Modal>
  );
};

const BottomSheetAddAgenda = ({ onClose, typeSchedule, id }) => {
  const data = ['Não repetir', 'Sim'];
  const times = eachQuarterHour('06:00', '22:00');
  const repetitions = ['1', '2', '3', '4', '5', '6', '7'];

  const _format = 'YYYY-MM-DD';
  const _today = moment().format(_format);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selected, setSelected] = useState(_today);
  const [selectedRecurrence, setSelectedRecurrence] = useState('0');
  const [markerDates, setMarkerDates] = useState({});
  const [isLoading, setIsLoading] = useState({ scheduleDots: false, sendSchedule: false });
  const [selectedTime, setSelectedTime] = useState(0);
  const [selectedRepetitions, setSelectedRepetitions] = useState(repetitions[0]);
  const [timeValueText, setTimeValueText] = useState(times[0]);
  const [repetitionsValueText, setRepetitionsValueText] = useState(repetitions[4]);
  const recurrencePickerRef = useRef();
  const timesPickerRef = useRef();
  const repetitionsPickerRef = useRef();
  const [daysWeekSelected, setDaysWeekSelected] = useState([
    { label: 'D', isSelected: false },
    { label: 'S', isSelected: true },
    { label: 'T', isSelected: true },
    { label: 'Q', isSelected: true },
    { label: 'Q', isSelected: true },
    { label: 'S', isSelected: true },
    { label: 'S', isSelected: false },
  ]);

  const insets = useSafeArea();

  const { profile } = useSelector(state => state.user);

  const getTypeProps = () => {
    if (typeSchedule === 'class') {
      return { class_id: id };
    }
    if (typeSchedule === 'meal') {
      return { meal_id: id };
    }
    if (typeSchedule === 'dish') {
      return { dish_id: id };
    }
  };

  const getRecurrence = () => {
    if (selectedRecurrence === '1') {
      return {
        recurrence_type: 'weeks',
      };
    }

    return {
      recurrence_type: 'days',
    };
  };

  const getBodyRequest = () => {
    return QueryString.stringify({
      ...getTypeProps(),
      ...getRecurrence(),
      week_days: getIndexDaysSelectedWeek(),
      recurrence: selectedRecurrence === '0' ? 0 : repetitionsValueText,
      seted_time: timeValueText,
      start_date: selected,
    });
  };

  const requestSchedule = async () => {
    setIsLoading({ ...isLoading, sendSchedule: true });
    try {
      const { data } = await api.post(`user-schedule/${typeSchedule}`, getBodyRequest());
      flashInfo(translate(pt.item_agenda_added));
      onClose();
      sendActionEvent(
        'save_schedule',
        { type: typeSchedule, id: id, email: profile.email },
        profile.email
      );
    } catch (error) {
      showMessage(errorControl(error));
    }
    setIsLoading({ ...isLoading, sendSchedule: false });
  };

  const getIndexDaysSelectedWeek = () => {
    if (selectedRecurrence === '0') {
      return 0;
    }

    const arrayIndexDaysSelected = daysWeekSelected.reduce((line, dayWeek, index) => {
      if (dayWeek.isSelected) {
        line.push(index + 1);
      }

      return line;
    }, []);
    return arrayIndexDaysSelected.toString();
  };

  const onDayPress = day => {
    setSelected(day);
  };

  useEffect(() => {
    setSelected(moment().format('YYYY-MM-DD'));
    requestScheduleFromMonth(moment());
  }, [requestScheduleFromMonth]);

  const WeekDayButton = ({ text, on, onPress }) => {
    return (
      <TouchableWithoutFeedback onPress={onPress}>
        <View
          style={[
            styles.weekdayButton,
            on ? { borderColor: colors.aquaMarineTwo, backgroundColor: colors.aquaMarineTwo } : {},
          ]}>
          <Text
            style={[
              styles.weekdayButtonText,
              on ? { color: colors.white, fontWeight: 'bold' } : {},
            ]}>
            {text}
          </Text>
        </View>
      </TouchableWithoutFeedback>
    );
  };

  const requestScheduleFromMonth = useCallback(
    async month => {
      setIsLoading({ ...isLoading, scheduleDots: true });
      const startOfMonth = moment(month).startOf('month').format('YYYY-MM-DD');
      const endOfMonth = moment(month).endOf('month').format('YYYY-MM-DD');
      try {
        const { data } = await api.get(
          `user-schedule?timestamp_start=${startOfMonth}&timestamp_end=${endOfMonth}`
        );
        generateDotsCalendar(data);
      } catch (error) {
        showAlert(errorControl(error));
      }
      setIsLoading({ ...isLoading, scheduleDots: false });
    },
    [isLoading]
  );

  const generateDotsCalendar = schedulesData => {
    const sectionList = schedulesData.reduce((line, data) => {
      const date = moment(data.start_date).format('YYYY-MM-DD');
      if (!line[date]) {
        line[date] = { dots: [] };
      }
      if (data.category_item === 1 && line[date].dots.indexOf(food) === -1) {
        line[date].dots.push(food);
      }
      if (data.category_item === 2 && line[date].dots.indexOf(activity) === -1) {
        line[date].dots.push(activity);
      }
      if (data.category_item === 3 && line[date].dots.indexOf(meditation) === -1) {
        line[date].dots.push(meditation);
      }
      return line;
    }, {});

    setMarkerDates(sectionList);
  };

  const selectDeselectDayWeek = indexWeek => {
    const newArrayDaysWeek = daysWeekSelected.map((dayWeek, index) => {
      return index === indexWeek ? { ...dayWeek, isSelected: !dayWeek.isSelected } : dayWeek;
    });
    setDaysWeekSelected(newArrayDaysWeek);
  };

  const handleCloseOrOpenModal = () => {
    setIsOpenModal(state => !state);
  };

  return (
    <View style={[styles.bottomSheet, { marginBottom: insets.bottom }]}>
      {isLoading.scheduleDots && (
        <View
          style={[styles.bottomSheet, { marginBottom: insets.bottom }, styles.indicatorLoading]}>
          <ActivityIndicator size="large" />
        </View>
      )}
      <View style={styles.header}>
        <Text style={textStyles.subtitle}>{translate(pt.when_do_you_want_to_add)}</Text>
        <TouchableOpacity onPress={onClose}>
          <Image source={closeGrey} style={{ width: 24, height: 24 }} />
        </TouchableOpacity>
      </View>
      <ScrollView>
        <View style={styles.calendarContainer}>
          <Calendar 
            onClickDay={(value) => {
              onDayPress(moment(value).format('YYYY-MM-DD'))
            }}
            defaultValue={new Date()}
            locale='pt'
            formatShortWeekday={(_, date) => moment(date).format('dddd')[0]}
            calendarType='hebrew'
            minDate={new Date()}
          />
        </View>
        <View style={styles.inputRow}>
          <Text style={[textStyles.subtitle, { padding: 15 }]}>Deseja criar uma recorrência?</Text>
          <Picker
            ref={recurrencePickerRef}
            style={styles.dropbox}
            selectedValue={selectedRecurrence}
            onValueChange={recurrenceValueText => {
              setSelectedRecurrence(recurrenceValueText);
            }}>
              {data.map((time, i) => {
                return <Picker.Item style={styles.itemWheelPicker} label={time} value={i} />
              })}
          </Picker>
        </View>
        <View style={styles.inputRow}>
          <Text style={[textStyles.subtitle, { padding: 15 }]}>Qual o horário?</Text>

          <TouchableOpacity style={styles.dropbox} onPress={handleCloseOrOpenModal}>
            <Text style={textStyles.semi16}>{timeValueText}</Text>

            <Icon name="angle-down" size={22} color={colors.aquaMarine} />
          </TouchableOpacity>
          <TimeModal
            isOpenModal={isOpenModal}
            timesPickerRef={timesPickerRef}
            selectedTime={selectedTime}
            handleCloseOrOpenModal={handleCloseOrOpenModal}
            onChangeTimeSelected={(newValue) => {
              setTimeValueText(times[newValue])
              setSelectedTime(newValue)
            }}
            times={times}
          />
        </View>
        {selectedRecurrence === '1' && (
          <View>
            <View style={styles.inputRow}>
              <Text style={[textStyles.subtitle, { padding: 15 }]}>Repete</Text>

              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <Picker
                  ref={recurrencePickerRef}
                  style={[styles.dropbox, { width: 80 }]}
                  selectedValue={selectedRepetitions}
                  onValueChange={repetitionsValueText => {
                    setRepetitionsValueText(repetitionsValueText);
                    setSelectedRepetitions(repetitionsValueText);
                  }}>
                    {repetitions.map((time, i) => {
                      return <Picker.Item style={styles.itemWheelPicker} label={time} value={i} />
                    })}
                </Picker>
                <Text style={styles.weeksRepetitionLabel}>{'Semana(s)'}</Text>
              </View>
            </View>
            <View style={styles.inputRow}>
              <Text style={[textStyles.subtitle, { padding: 15 }]}>Repetir às/aos</Text>
              <View style={styles.weekdaysRow}>
                {daysWeekSelected.map((dayWeek, index) => {
                  return (
                    <WeekDayButton
                      text={dayWeek.label}
                      on={dayWeek.isSelected}
                      onPress={() => selectDeselectDayWeek(index)}
                    />
                  );
                })}
              </View>
            </View>
          </View>
        )}
        <ButtonBase
          onPress={() => {
            requestSchedule();
          }}
          backgroundColor={colors.aquaMarineTwo}
          square
          fill
          loading={isLoading.sendSchedule}
          title={translate(pt.confirm)}
        />
      </ScrollView>
    </View>
  );
};

export default BottomSheetAddAgenda;

const styles = StyleSheet.create({
  weeksRepetitionLabel: { fontSize: 16, fontFamily: fonts.REGULAR, color: colors.slateGrey },
  indicatorLoading: {
    position: 'absolute',
    backgroundColor: colors.blackAlpha60,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 2,
    justifyContent: 'center',
  },
  dropbox: {
    marginHorizontal: 16,
    height: 47,
    flexDirection: 'row',
    borderRadius: 2,
    backgroundColor: colors.white,
    borderStyle: 'solid',
    borderWidth: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 16,
    borderColor: colors.lightPeriwinkle,
  },
  bottomSheet: { flex: 1 },
  header: {
    height: 60,
    paddingHorizontal: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottomColor: colors.veryLightPinkFour,
    borderBottomWidth: StyleSheet.hairlineWidth,
  },
  calendar: {},
  weekdaysRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
  },
  weekdayButton: {
    height: 45,
    width: 45,
    borderRadius: 22.5,
    alignItems: 'center',
    backgroundColor: colors.white,
    borderWidth: 1,
    borderColor: colors.lightPeriwinkle,
  },
  weekdayButtonText: {
    lineHeight: 41,
    fontSize: 17,
    color: colors.greyBlue,
  },
  itemWheelPicker: { fontSize: 36, fontFamily: fonts.BOLD },
  wheelPicker: { width: '100%', height: 60 },
  pickerContainer: {
    backgroundColor: colors.white,
    borderRadius: 19,
    padding: 20,
  },
  pickerButton: {
    borderRadius: 8,
    width: '90%'
  },
  calendarContainer: {
    backgroundColor: colors.white,
    paddingBottom: 25,
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
    alignSelf: 'center',
  }
});
