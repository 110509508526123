import produce from 'immer';
const INITIAL_STATE = {
  programs: [],
  programsLoad: false,
};
export default function programs(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@programs/SET_PROGRAMS': {
        draft.programs = action.payload;
        draft.programsLoad = false;
        break;
      }
      case '@programs/FETCH_PROGRAMS': {
        draft.programsLoad = true;
        break;
      }
    }
  });
}