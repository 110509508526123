import React, { useState, useRef, useEffect } from 'react';

import {
  View,
  StyleSheet,
  StatusBar,
  Text,
  TouchableOpacity,
  ScrollView,
  Image,
  SafeAreaView,
  Platform,
} from 'react-native';

import * as RNIap from 'react-native-iap';
import * as yup from 'yup';
import * as Animatable from 'react-native-animatable';
import colors from '../../styles/colors';
import commons from '../../styles/commons';
import { translate } from '../../i18n/src/locale';
import pt from '../../i18n/src/locale/pt';
import fonts from 'app/styles/fonts';
import AnimatedLottieView from 'lottie-react-native';
import enterprise1 from 'app/images/enterprise1.png';
import enterprise2 from 'app/images/enterprise2.png';
import enterprise3 from 'app/images/enterprise3.png';
import LogoSvg from 'app/components/icons/LogoSvg';
import anim from 'app/styles/anim';
import circles from '../../images/circles.png';
import textStyles from 'app/styles/textStyles';
import CardBenefits from 'app/components/CardBenefits';
import ButtonBase from 'app/components/ButtonBase';
import { useSafeArea } from 'react-native-safe-area-context';
import closeWhite from 'app/images/closeWhite.png';
import SecureTextInput from 'app/components/SecureTextInput';
import ReactNativeModal from 'react-native-modal';
import api from 'app/services/api';
import { errorControl, errorMessage, formatMoneyBR, sendActionEvent, successMessage } from 'app/services/utils';
import { signUpRequest } from 'app/store/modules/auth/actions';
import { updateProfileSuccess } from 'app/store/modules/user/actions';
import { useDispatch, useSelector } from 'react-redux';
import EVENTS from 'app/configs/events';
import QueryString from 'qs';
import RBSheet from 'react-native-raw-bottom-sheet';
import BottomSheetConfirmiOS from 'app/screens/core/payment/components/BottomSheetConfirmiOS';
import useGlobalAlert from 'app/hooks/useGlobalAlert';
import { useNavigation, useRoute } from '@react-navigation/native';
import APP from '../../configs/app';

const STEPS = {
  LOADING: 0,
  NO_INVITE: 1,
  HAS_INVITE: 2,
  ACCEPT_INVITE: 3,
  CREATE_ACCOUNT: 4,
  REJECTED_INVITE: 5,
};

const BusinessVerificationScreen = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [invitation, setInvitation] = useState();
  const [plan, setPlan] = useState();
  const [benefits, setBenefits] = useState([]);
  const [iOSPlan, setiOSPlan] = useState(null);
  const [noInvite, setNoInvite] = useState(false);

  const navigation = useNavigation();
  const { data, logged, onExists } = useRoute().params;

  const { signed } = useSelector(state => state.auth);

  const dispatch = useDispatch();

  const safeAreaInsets = useSafeArea();

  const circleBG = useRef();

  const iOSPaymentRef = useRef();

  const { setAlert: inviteAlert } = useGlobalAlert();

  const successLoggedVerification = async () => {
    if (signed && !logged) {
      try {
        successMessage('Conta criada com sucesso!');
        const { data: goCore } = await api.post(
          'user-ui-event/filter',
          QueryString.stringify({
            ui_event_name: EVENTS.ONBOARDING,
            status: 1,
          })
        );

        if (invitation?.plan_meta?.paid_by === 'user') {
          await paidPath();
          return;
        } else {
          // navigation.navigate(goCore ? 'Início' : 'Onboarding');
          navigation.navigate('Onboarding');
        }
      } catch (error) {
        console.log(error);
        errorMessage(errorControl(error));
      }
    }
  };

  useEffect(() => {
    successLoggedVerification();
  }, [signed]);

  const verifyInvitation = async () => {
    try {
      const params = { ...data };

      if (!signed) {
        params.business_ref = new URLSearchParams(window.location.search).get('company');
      }

      const { data: response } = await api.get(signed ? '/user/invite' : '/invite', {
        params
      });

      setBenefits(response.benefits);

      if (response?.status === 404) {
        setCurrentStep(STEPS.NO_INVITE);
        setNoInvite(true);
        return;
      }

      setInvitation(response);
      setCurrentStep(STEPS.HAS_INVITE);
    } catch (e) {
      if (e.response.status == '409') {
        onExists && onExists();
        return;
      }

      errorMessage('Não foi possível verificar seus dados. Tente novamente mais tarde.');
      navigation.goBack();
    }
  };

  useEffect(() => {
    if (invitation?.plan_meta?.paid_by == 'user') {
      loadPaymentInfo();
    }
  }, [invitation]);

  const loadPaymentInfo = async () => {
    const {
      plan_meta: { plan_id },
    } = invitation;
    const { data: response } = await api.get(`plans/${plan_id}`);
    if (Platform.OS === 'ios') {
      await RNIap.initConnection();
      await RNIap.clearTransactionIOS();
      const [iosPlan] = await RNIap.getSubscriptions({ skus: [response.apple_sku] });
      response.displayPrice = iosPlan.price;
      setiOSPlan(iosPlan);
    } else {
      const installments = response.days / 30;
      response.displayPrice = (response.price / 100 / installments).toFixed(2);
    }
    setPlan(response);
  };

  const paidPath = async () => {

    if (Platform.OS === 'ios') {
      setCurrentStep(STEPS.HAS_INVITE);
      iOSPaymentRef.current.open();
    } else {
      navigation.navigate('Payment', {
        business_verification: true,
        value: (plan.price / 100).toFixed(2),
        product: plan.id,
        trial_days: plan.trial_days,
        product_name: plan.name,
        installments: parseInt(plan.days / 30, 10),
        invite: true,
      });
    }
  };

  const acceptInvitation = async () => {
    try {
      if (!signed) {
        setCurrentStep(STEPS.CREATE_ACCOUNT);
        return;
      }

      if (invitation.plan_meta.paid_by === 'user') {
        await paidPath();
        return;
      }

      await api.post('/user/invite/accept');

      const reloadUsers = async () => {
        const { data: user } = await api.get('user');

        if (user) {
          dispatch(updateProfileSuccess(user));
        }
      };

      inviteAlert(
        translate(pt.business_accept_plan_alert_title),
        translate(pt.business_accept_plan_alert_message),
        {
          onPress: () => {
            reloadUsers()
              .then(() => {
                navigation.navigate('Início');
              })
              .catch(e => {
                errorMessage(
                  'Ops. O convite foi aceito, mas parece que tivemos um erro ao atualizar seu perfil. Por favor, feche o app e abra novamente.'
                );
              });
          },
          hideClose: true,
          text: translate(pt.business_accept_plan_alert_button),
        }
      );
    } catch (e) {
      console.log(e);
      errorMessage('Erro ao aceitar convite. Tente novamente.');
    }
  };

  const rejectInvitation = async () => {
    try {
      if (!signed) {
        setCurrentStep(STEPS.REJECTED_INVITE);
        return;
      }
      await api.post('/user/invite/reject');
      setCurrentStep(STEPS.REJECTED_INVITE);
    } catch (e) {
      errorMessage('Erro ao rejeitar convite. Tente novamente.');
    }
  };

  useEffect(() => {
    verifyInvitation();
  }, []);

  const InviteStep = ({ no_invite, rejected }) => {
    return (
      <>
        <ScrollView
          bounces={false}
          style={commons.flex}
          contentContainerStyle={[
            styles.scrollContainer,
            { paddingBottom: safeAreaInsets.bottom + 20 },
          ]}>
          <Animatable.View delay={0} style={styles.center} useNativeDriver animation="fadeInUp">
            <Image
              source={invitation ? enterprise2 : enterprise1}
              style={{ width: 240, height: 240, resizeMode: 'contain' }}
            />

            {invitation && !no_invite && !rejected ? (
              <>
                <Text style={[textStyles.bold16, styles.description, { marginTop: 16 }]}>
                  {translate(pt.you_have_an_invitation)}
                </Text>
                <Text
                  style={[textStyles.bold18, styles.description, { color: colors.aquaMarineTwo }]}>
                  {invitation.business_name}
                </Text>

                <View style={styles.card}>
                  <Text style={[styles.cardText, { fontSize: 16 }]}>
                    {'A empresa '}
                    <Text style={{ color: colors.aquaMarineTwo, fontFamily: fonts.BOLD }}>
                      {invitation.business_name}
                    </Text>
                    {' enviou um convite para você!'}
                  </Text>

                  {invitation.plan_conditions && (
                    <Text style={[styles.cardText, { marginTop: 16 }]}>
                      {'Condições: '}
                      {invitation.plan_conditions}
                    </Text>
                  )}
                </View>

                <View style={[styles.buttonFooter]}>
                  <ButtonBase
                    onPress={() =>
                      invitation
                        ? setCurrentStep(STEPS.ACCEPT_INVITE)
                        : navigation.navigate('Login', { method: 'login' })
                    }
                    style={styles.continue}
                    title={translate(pt.continue)}
                  />
                </View>
              </>
            ) : (
              <>
                <Text style={[textStyles.bold16, styles.description, { marginTop: 18 }]}>
                  {no_invite && translate(pt.no_benefits_for_you)}
                  {rejected && translate(pt.enterprise_plan_rejected_message)}
                </Text>

                <CardBenefits
                  containerStyle={{ marginVertical: 12 }}
                  benefits={benefits}
                  description={translate(pt.you_can_still_create_a_free_account)}
                />
                {/*<View style={[styles.buttonFooter]}>
                  <ButtonBase
                    onPress={() => (!signed ? setCurrentStep(STEPS.CREATE_ACCOUNT) : navigation.goBack())}
                    style={styles.continue}
                    title={translate(pt.continue_with_personal_acc)}
                  />
                </View>*/}
              </>
            )}
          </Animatable.View>
        </ScrollView>
      </>
    );
  };
  const PlanStep = () => {
    const [refuseModalVisible, setRefuseModalVisible] = useState(false);

    return (
      <>
        <Animatable.View delay={0} style={styles.center} useNativeDriver animation="fadeInUp">
          <ScrollView
            bounces={false}
            style={commons.flex}
            contentContainerStyle={[
              styles.scrollContainer,
              { paddingBottom: safeAreaInsets.bottom + 20 },
            ]}>
            <Text style={[textStyles.bold16, styles.description, { color: colors.aquaMarineTwo }]}>
              {'Plano Namu Business'}
            </Text>

            <CardBenefits containerStyle={{ marginTop: 12 }} benefits={benefits} />

          </ScrollView>

          <View style={[styles.buttonFooter, { marginBottom: 20 }]}>
            {plan && (
              <View style={{marginTop: 10}}>
                <Text
                  style={[textStyles.bold16, { textAlign: 'center', color: colors.grayText }]}>
                  {plan?.trial_days ? translate(pt.free_trial_test_before, { days: plan?.trial_days }) : 'Tudo por apenas'}
                </Text>
                <Text
                  style={[textStyles.regular16, { textAlign: 'center', color: colors.grayText }]}>
                  {formatMoneyBR(parseFloat(plan.displayPrice))} /{translate(pt.month)}
                </Text>
              </View>
            )}
            <ButtonBase
              onPress={() => acceptInvitation()}
              style={styles.continue}
              title={plan ?
                (plan?.trial_days ?
                  translate(pt.free_trial_test_days, { days: plan.trial_days })
                  : translate(pt.accept_offer_and_continue))
                : translate(pt.accept_and_continue)}
            />
            {(!!plan && !!plan?.trial_days) && <Text style={{...textStyles.regular12, color: colors.grayText, textAlign: 'center', marginTop: 15}}>{translate(pt.cancel_any_moment_in_test_period)}</Text>}
          </View>
          <RefuseModal
            isVisible={refuseModalVisible}
            onClose={() => setRefuseModalVisible(false)}
          />
        </Animatable.View>
      </>
    );
  };
  const CreateAccountStep = () => {
    const [password, setPassword] = useState(data?.password);

    const createAccount = async () => {
      setCurrentStep(STEPS.LOADING);
      const schema = yup.object().shape({
        password: yup.string().min(8, translate(pt.password_min)).required(),
      });

      schema
        .validate({ password })
        .then(async () => {
          dispatch(
            signUpRequest({
              name: 'APP',
              email: data.email,
              phone: data.phone,
              password,
              isBusiness: !noInvite,
              cpf: data.cpf,
              provider: APP.PLATFORM,
            })
          );
          sendActionEvent('create_account', { email: data.email }, data.email);
        })
        .catch(error => {
          setCurrentStep(STEPS.CREATE_ACCOUNT);
          errorMessage(error.message);
        });
    };

    return (
      <Animatable.View delay={0} style={styles.center} useNativeDriver animation="fadeInUp">
        <View style={[commons.flexCenter, { width: '100%' }]}>
          <View style={styles.createAccountContainer}>
            <Text style={[textStyles.bold18, { color: '#444242' }]}>
              {translate(pt.create_account)}
            </Text>

            <Text
              style={[
                textStyles.medium14,
                { color: '#7B7C8F', textAlign: 'center', marginVertical: 16 },
              ]}>
              {translate(pt.your_login_with_email)}
              {'\n'}
              <Text style={{ color: colors.aquaMarineTwo }}>{data.email}</Text>
              {'\n'}
              {translate(pt.create_access_password)}
            </Text>

            <SecureTextInput
              value={password}
              onChangeText={setPassword}
              style={{ marginHorizontal: 20 }}
            />

            <ButtonBase
              title={translate(pt.create_account)}
              style={styles.continue}
              onPress={() => createAccount()}
            />
          </View>
        </View>
      </Animatable.View>
    );
  };
  const RefuseModal = ({ isVisible, onClose }) => {
    return (
      <ReactNativeModal
        style={styles.container}
        isVisible={isVisible}
        onBackdropPress={onClose}
        onBackButtonPress={onClose}>
        <View style={styles.contentModal}>
          <ScrollView
            contentContainerStyle={{ alignItems: 'center' }}
            showsVerticalScrollIndicator={false}>
            <Image
              source={enterprise3}
              style={{ width: 220, height: 220, resizeMode: 'contain' }}
            />

            <Text style={[textStyles.bold18, { color: '#444242', marginTop: 16 }]}>
              {translate(pt.sure_you_want_to_refuse)}
            </Text>

            <Text
              style={[
                textStyles.medium14,
                { color: '#7B7C8F', textAlign: 'center', marginVertical: 16 },
              ]}>
              {
                'Se recusar você irá perder os benefícios oferecidos pela conta corporativa, mas ainda poderá criar uma conta pessoal gratuita limitada.'
              }
            </Text>

            <View style={[styles.buttonFooter]}>
              <ButtonBase
                onPress={onClose}
                style={styles.continue}
                title={translate(pt.continue_with_plan)}
              />
              <TouchableOpacity
                onPress={() => rejectInvitation()}
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: 50,
                  marginTop: 10,
                }}>
                <Text style={[textStyles.bold14, { color: colors.brownGrey }]}>
                  {translate(pt.disagree_offer)}
                </Text>
              </TouchableOpacity>
            </View>
          </ScrollView>
        </View>
      </ReactNativeModal>
    );
  };

  const renderStep = () => {
    switch (currentStep) {
      case STEPS.NO_INVITE:
        return <InviteStep no_invite={true} />;
      case STEPS.HAS_INVITE:
        return <InviteStep />;
      case STEPS.REJECTED_INVITE:
        return <InviteStep rejected={true} />;
      case STEPS.ACCEPT_INVITE:
        return <PlanStep />;
      case STEPS.CREATE_ACCOUNT:
        return <CreateAccountStep />;
      default:
        return null;
    }
  };

  return (
    <View style={[commons.flex, { backgroundColor: colors.greyF5 }]}>
      <StatusBar backgroundColor="transparent" translucent barStyle="dark-content" />

      {currentStep === STEPS.LOADING && (
        <View
          style={{
            zIndex: 100,
            width: '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <View
            style={{
              marginTop: -50,
              width: 150,
              height: 150,
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <AnimatedLottieView
              source={require('app/lotties/loading.json')}
              // onAnimationFinish={()=>setAnimFinished(true)}
              autoPlay
              loop={true}
              style={{ width: 150, transform: [{ scale: 1.2 }] }}
            />
          </View>
        </View>
      )}
      {currentStep !== STEPS.LOADING && (
        <>
          <View
            style={{
              zIndex: 1,
              position: 'absolute',
              top: safeAreaInsets.top + 15,
              left: 15,
              paddingHorizontal: 14,
            }}>
            <TouchableOpacity
              onPress={() => {
                navigation.goBack();
              }}
              style={styles.infoHolder}>
              <Image style={{ width: 20, height: 20 }} source={closeWhite} />
            </TouchableOpacity>
          </View>
          <LogoSvg width={135} style={{ marginTop: safeAreaInsets.top, alignSelf: 'center' }} />
          <SafeAreaView style={commons.flex}>
            <Animatable.View
              delay={500}
              useNativeDriver
              ref={circleBG}
              style={styles.animCircle}
              animation={anim.fadeIn}>
              <Image source={circles} style={styles.circleBg} />
            </Animatable.View>

            {renderStep()}
          </SafeAreaView>
        </>
      )}
      <RBSheet
        ref={iOSPaymentRef}
        height={300}
        customStyles={{ container: { borderTopEndRadius: 10, borderTopStartRadius: 10 } }}>
        <BottomSheetConfirmiOS
          invite={true}
          sku={iOSPlan?.productId}
          planValue={iOSPlan?.price}
          planPrice={iOSPlan?.localizedPrice}
          onClose={() => {
            iOSPaymentRef.current.close();
          }}
        />
      </RBSheet>
    </View>
  );
};

const styles = StyleSheet.create({
  infoHolder: {
    backgroundColor: colors.blackAlpha03,
    width: 22,
    height: 22,
    borderRadius: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
  animCircle: {
    position: 'absolute',
    width: 500,
    height: 500,
    bottom: -200,
    right: -250,
  },
  circleBg: {
    zIndex: -1,
    width: 500,
    height: 500,
  },
  scrollContainer: {
    flexGrow: 1,
    paddingBottom: 78,
    paddingHorizontal: 10,
    // flexDirection: 'column',
    // justifyContent: 'space-between',
  },
  center: {
    flex: 1,
    width: '100%',
    paddingHorizontal: 0,
    paddingTop: 20,
    alignItems: 'center',
  },
  buttonFooter: {
    width: '100%',
    paddingHorizontal: 20,
  },
  createAccountContainer: {
    width: '100%',
    marginTop: -100,
    paddingHorizontal: 37,
  },
  continue: {
    margin: 0,
    height: 50,
    width: '100%',
  },
  description: {
    marginHorizontal: 34,
    textAlign: 'center',
    color: '#444242',
  },
  card: {
    margin: 20,
    padding: 20,
    borderRadius: 4,
    backgroundColor: colors.white,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.2,
    shadowRadius: 2,

    elevation: 3,
  },
  cardText: {
    fontFamily: fonts.MEDIUM,
    color: colors.greyBlue,
    fontSize: 14,
    textAlign: 'center',
  },
  contentModal: {
    backgroundColor: colors.white,
    borderRadius: 20,
    paddingTop: 42,
    paddingHorizontal: 28,
    paddingBottom: 40,
  },
});

export default BusinessVerificationScreen;
