import { all } from 'redux-saga/effects';
import auth from './auth/sagas';
import user from './user/sagas';
import favorites from './favorites/sagas';
import classes from './classes/sagas';
import programs from './programs/sagas';

export default function* rootSaga() {
  return yield all([auth, user, classes, favorites, programs]);
}
