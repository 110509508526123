/* eslint-disable react-native/no-inline-styles */
import MaskLevelPaymentNew from 'app/components/MaskLevelPaymentNew';
import api from 'app/services/api';
import colors from 'app/styles/colors';
import fonts from 'app/styles/fonts';
import PropTypes from 'prop-types';
import React from 'react';
import { ImageBackground, StyleSheet, TouchableOpacity, Text, View } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import ShadowView from '@liuyunjs/react-native-simple-shadow-view';

const CardLastSeen = ({ style, title, image, gradientColor = 'none', onPress, paymentLevel }) => {
  const getBackgroundColor = () => {
    if (gradientColor === 'red') {
      return colors.darkRed;
    }
    if (gradientColor === 'green') {
      return colors.aquaMarineTwo;
    }
    if (gradientColor === 'orange') {
      return colors.brightOrange;
    }
    return colors.transparent;
  };

  const getBackgroundGradient = () => {
    if (gradientColor === 'red') {
      return ['#FD454500', '#D12E2EB3'];
    }
    if (gradientColor === 'green') {
      return ['#3ED1D200', '#18999AB3'];
    }
    if (gradientColor === 'orange') {
      return ['#FCAB6100', '#D17826B3'];
    }
    return [colors.white + '00', colors.white + 'B3'];
  };
  return (
    <TouchableOpacity onPress={onPress} disabled={!onPress}>
      {paymentLevel === 1 && (
        <MaskLevelPaymentNew containerStyles={{ height: '100%', width: '100%', paddingLeft: 20 }} />
      )}
      <ShadowView style={[styles.card, style]}>
        <View
          style={{
            zIndex: -1,
            position: 'absolute',
            alignSelf: 'center',
            height: '100%',
            width: '100%',
            marginTop: 4,
            backgroundColor: getBackgroundColor(),
            borderRadius: 10,
          }}
        />
        <ImageBackground
          source={{
            uri: image,
            headers: {
              Authorization: api.defaults.headers.Authorization,
            },
          }}
          imageStyle={{
            borderRadius: 10,
          }}
          style={styles.image}>
          <LinearGradient colors={getBackgroundGradient()} style={styles.linear} />
          <Text numberOfLines={2} style={[styles.title]}>
            {title}
          </Text>
        </ImageBackground>
      </ShadowView>
    </TouchableOpacity>
  );
};

export default CardLastSeen;

CardLastSeen.propTypes = {
  minus: PropTypes.bool,
  onPress: PropTypes.func,
  plus: PropTypes.bool,
  style: PropTypes.any,
  title: PropTypes.string,
  type: PropTypes.oneOf(['meditation', 'activity', 'meal', 'masterclass']),
};

const styles = StyleSheet.create({
  linear: {
    position: 'absolute',
    width: 117,
    height: 60,
    left: 0,
    bottom: 0,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  title: {
    fontFamily: fonts.BOLD,
    fontSize: 12,
    lineHeight: 17,
    color: colors.white,
  },
  card: {
    width: 117,
    height: 103,
    shadowColor: colors.black,
    shadowOpacity: 0.08,
    shadowRadius: 8,
    shadowOffset: { width: 0, height: 8 },
  },
  capsuleWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  image: {
    paddingHorizontal: 12,
    paddingVertical: 7,
    justifyContent: 'flex-end',
    height: 103,
    width: 117,
  },
});
