import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import fonts from 'app/styles/fonts';
import colors from 'app/styles/colors';
import CustomIcon from 'app/styles/icon';

/**
 * @typedef {keyof typeof Icons} IconList
 */

/**
 * @param {Object} prop
 * @param {IconList} prop.iconName
 */
const ListItemChecked = ({ title, iconName, iconSize = 28 }) =>{ 
  return(
  <View style={styles.containerListItem}>
    <CustomIcon name={iconName || 'check_circle_o'} color={colors.aquaMarineTwo} size={iconSize} />
    {/* <Image source={checkedCircle} style={{width: iconSize, height: iconSize, resizeMode: 'contain'}}  /> */}
    <Text style={styles.textListItem}>{title.label}</Text>
  </View>
)};

export default ListItemChecked;

const styles = StyleSheet.create({
  containerListItem: {
    flex: 1,
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: 10,
  },
  textListItem: {
    fontFamily: fonts.BOLD,
    color: colors.greyBlue,
    fontSize: 12,
    lineHeight: 16,
    marginLeft: 10,
  },
});
