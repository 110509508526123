import { StyleSheet } from 'react-native';
import colors from './colors';
import fonts from './fonts';

const textStyles = StyleSheet.create({
  money: {
    fontFamily: fonts.BOLD,
    fontSize: 22,
    color: colors.black,
  },
  title: {
    fontFamily: fonts.BOLD,
    fontSize: 20,
    color: colors.black,
  },
  center: {
    textAlign: 'center',
  },
  subtitle: {
    fontFamily: fonts.MEDIUM,
    fontSize: 16,
    color: colors.black,
  },
  subtitle17: {
    fontFamily: fonts.REGULAR,
    fontSize: 17,
    color: colors.black,
  },
  semiWhite16: {
    fontFamily: fonts.MEDIUM,
    fontSize: 16,
    color: colors.white,
  },
  semi16: {
    fontFamily: fonts.MEDIUM,
    fontSize: 16,
    color: '#3a3a3a',
  },
  semi11: {
    fontFamily: fonts.MEDIUM,
    fontSize: 11,
    color: '#3a3a3a',
  },
  semi10: {
    fontFamily: fonts.MEDIUM,
    fontSize: 10,
    color: '#3a3a3a',
  },
  semi10grey: {
    fontFamily: fonts.MEDIUM,
    fontSize: 10,
    color: colors.greyBlue,
  },
  semi10slateGreyOpacity: {
    fontFamily: fonts.MEDIUM,
    fontSize: 10,
    color: colors.slateGrey,
  },
  semi12: {
    fontFamily: fonts.MEDIUM,
    fontSize: 12,
    color: '#3a3a3a',
  },
  semi12brownGrey: {
    fontFamily: fonts.MEDIUM,
    fontSize: 12,
    color: colors.brownGrey,
  },
  semi12slateGrey: {
    fontFamily: fonts.MEDIUM,
    fontSize: 12,
    color: colors.slateGrey,
  },
  semi20: {
    fontFamily: fonts.MEDIUM,
    fontSize: 20,
    color: '#3a3a3a',
  },
  semi14: {
    fontFamily: fonts.MEDIUM,
    fontSize: 14,
    color: colors.charcoalGreyTwo,
  },
  semi14slateGrey: {
    fontFamily: fonts.MEDIUM,
    fontSize: 14,
    color: colors.slateGrey,
  },
  semi13: {
    fontFamily: fonts.MEDIUM,
    fontSize: 13,
    color: colors.charcoalGreyTwo,
  },
  semi16white: {
    fontFamily: fonts.MEDIUM,
    fontSize: 16,
    color: colors.white,
  },
  semi16slateGrey: {
    fontFamily: fonts.MEDIUM,
    fontSize: 16,
    color: colors.slateGrey,
  },
  semi14white: {
    fontFamily: fonts.MEDIUM,
    fontSize: 14,
    color: colors.white,
  },
  createAccount: {
    fontFamily: fonts.BOLD,
    fontSize: 14,
    color: colors.white,
  },
  createAccount12: {
    fontFamily: fonts.BOLD,
    fontSize: 12,
    color: colors.aquaMarineTwo,
  },
  createAccount18: {
    fontFamily: fonts.BOLD,
    fontSize: 18,
    color: colors.aquaMarineTwo,
  },
  medium14: {
    fontFamily: fonts.MEDIUM,
    fontSize: 14,
    color: colors.blackTwo,
  },
  medium14White: {
    fontFamily: fonts.MEDIUM,
    fontSize: 14,
    color: colors.white,
  },
  medium16: {
    fontFamily: fonts.MEDIUM,
    fontSize: 16,
    color: colors.blackTwo,
  },
  regular12: {
    fontFamily: fonts.REGULAR,
    fontSize: 12,
  },
  regular14: {
    fontFamily: fonts.REGULAR,
    fontSize: 14,
    color: colors.blackTwo,
  },
  regular14White: {
    fontFamily: fonts.REGULAR,
    fontSize: 14,
    color: colors.white,
  },
  regular16: {
    fontFamily: fonts.REGULAR,
    fontSize: 16,
    color: colors.blackTwo,
  },
  regular16White: {
    fontFamily: fonts.REGULAR,
    fontSize: 16,
    color: colors.white,
  },
  regular18: {
    fontFamily: fonts.REGULAR,
    fontSize: 18,
    color: colors.blackTwo,
  },
  regular24White: {
    fontFamily: fonts.REGULAR,
    fontSize: 24,
    color: colors.white,
    textAlign: 'center',
  },
  regular12grey: {
    fontFamily: fonts.REGULAR,
    fontSize: 12,
    color: colors.greyBlue,
  },
  regular14grey: {
    fontFamily: fonts.REGULAR,
    fontSize: 14,
    color: colors.brownishGrey,
  },
  regular9white: {
    fontFamily: fonts.REGULAR,
    fontSize: 9,
    color: colors.white,
  },
  regular10white: {
    fontFamily: fonts.REGULAR,
    fontSize: 10,
    color: colors.white,
  },
  regular11white: {
    fontFamily: fonts.REGULAR,
    fontSize: 11,
    color: colors.white,
  },
  regular11: {
    fontFamily: fonts.REGULAR,
    fontSize: 11,
    color: colors.blackTwo,
  },
  bold12: {
    fontFamily: fonts.BOLD,
    fontSize: 12,
    color: colors.blackTwo,
  },
  bold12grey: {
    fontFamily: fonts.BOLD,
    fontSize: 12,
    color: colors.greyBlue,
  },
  bold12White: {
    fontFamily: fonts.BOLD,
    fontSize: 12,
    color: colors.white,
  },
  bold10White: {
    fontFamily: fonts.BOLD,
    fontSize: 10,
    color: colors.white,
  },
  bold10grey: {
    fontFamily: fonts.BOLD,
    fontSize: 10,
    color: colors.greyBlue,
  },
  semi12White: {
    fontFamily: fonts.MEDIUM,
    fontSize: 12,
    color: colors.white,
  },
  bold14: {
    fontFamily: fonts.BOLD,
    fontSize: 14,
    color: colors.blackTwo,
  },
  bold15: {
    fontFamily: fonts.BOLD,
    fontSize: 15,
    color: colors.blackTwo,
  },
  bold14White: {
    fontFamily: fonts.BOLD,
    fontSize: 14,
    textAlign: 'center',
    color: colors.white,
  },
  bold14WhiteWithOutAlign: {
    fontFamily: fonts.BOLD,
    fontSize: 14,
    color: colors.white,
  },
  bold16White: {
    fontFamily: fonts.BOLD,
    fontSize: 16,
    color: colors.white,
  },
  shadow: {
    textShadowOffset: { width: 2, height: 2 },
    textShadowRadius: 8,
    textShadowColor: colors.blackAlpha18,
  },
  bold24White: {
    fontFamily: fonts.BOLD,
    fontSize: 24,
    color: colors.white,
  },
  bold20: {
    fontFamily: fonts.BOLD,
    fontSize: 20,
    color: colors.black,
  },
  bold20White: {
    fontFamily: fonts.BOLD,
    fontSize: 20,
    color: colors.white,
  },
  bold22: {
    fontFamily: fonts.BOLD,
    fontSize: 22,
    color: colors.black,
  },
  bold22grey: {
    fontFamily: fonts.BOLD,
    fontSize: 22,
    color: colors.greyBlue,
  },
  bold18: {
    fontFamily: fonts.BOLD,
    fontSize: 18,
    textAlign: 'center',
    color: colors.blackTwo,
  },
  bold18grey: {
    fontFamily: fonts.BOLD,
    fontSize: 18,
    color: colors.greyBlue,
  },
  bold18blackTwo: {
    fontFamily: fonts.BOLD,
    fontSize: 18,
    color: colors.blackTwo,
  },
  bold18Left: {
    fontFamily: fonts.BOLD,
    fontSize: 18,
    color: colors.blackTwo,
  },
  bold18White: {
    fontFamily: fonts.BOLD,
    fontSize: 18,
    color: colors.white,
  },
  bold16: {
    fontFamily: fonts.BOLD,
    fontSize: 16,
    color: colors.blackTwo,
  },
  bold16AquaMarine: {
    fontFamily: fonts.BOLD,
    fontSize: 16,
    color: colors.aquaMarineTwo,
  },
  bold16grey: {
    paddingTop: 2,
    fontFamily: fonts.BOLD,
    fontSize: 16,
    color: colors.greyBlue,
  },
  bold14grey: {
    paddingTop: 2,
    fontFamily: fonts.BOLD,
    fontSize: 14,
    color: colors.greyBlue,
  },
  bold24blackTwo: {
    fontFamily: fonts.BOLD,
    fontSize: 24,
    color: colors.blackTwo,
  },
  bold22White: {
    fontFamily: fonts.BOLD,
    fontSize: 22,
    color: colors.white,
  },
  black25: {
    fontFamily: fonts.BLACK,
    fontSize: 25,
  },
});

export default textStyles;
