import React from 'react';
import { View, Text, StyleSheet, Image, NativeModules, Platform, TouchableOpacity } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import ShadowView from '@liuyunjs/react-native-simple-shadow-view';
import ButtonBaseIcon from 'app/components/ButtonBaseIcon';
import Icon from 'react-native-vector-icons/dist/FontAwesome';
import LinearGradient from 'react-native-linear-gradient';

import { translate } from 'app/i18n/src/locale';
import pt from 'app/i18n/src/locale/pt';

import colors from '../../../styles/colors';
import fonts from '../../../styles/fonts';
import { useSelector } from 'react-redux';
import useGlobalAlert from 'app/hooks/useGlobalAlert';
// import { Camera } from 'react-native-vision-camera';
import { useNavigation } from '@react-navigation/native';
import CustomIcon from 'app/styles/icon';

export const HealthscanWidget = ({ widget }) => {
  const { profile } = useSelector(state => state.user);
  const { setAlert } = useGlobalAlert();

  const navigation = useNavigation();

  const onClickWidget = async () => {
    try {
      let architecture = null;
      console.log(Platform.OS)
      if(Platform.OS == 'web'){
        setAlert(
          'Funcionalidade incompatível no momento',
          'Funcionalidade incompatível com o seu dispositivo. Em breve estará disponível em próximas atualizações.'
        );
        return;
      }
      if (Platform.OS === 'android') {
        const { ArchitectureModule } = NativeModules;
        architecture = await ArchitectureModule.getArchitecture();
      }
      if (
        Platform.OS === 'ios' ||
        (architecture &&
          (architecture.includes('armv8') ||
            architecture.includes('arm64') ||
            architecture.includes('armeabi')))
      ) {
        const value = await AsyncStorage.getItem('accessInstructionsOneTime');
        const { birthdate, gender, weight, height } = profile;
        if (!birthdate || !gender || !weight || !height) {
          navigation.navigate('HealthscanMeasurementInfos',{
            go: value !== null ? 'health_scan' : 'health_scan_instructions',
          });
          return;
        }

        if (value !== null) {
          navigation.navigate('Healthscan');
        } else {
          navigation.navigate('HealthscanInstructions');
        }
      } else {
        setAlert(
          'Funcionalidade incompatível no momento',
          'Funcionalidade incompatível com o seu dispositivo. Em breve estará disponível em próximas atualizações.'
        );
      }
    } catch (error) {
      console.log('Erro ao recuperar o dado:', error);
    }
  };

  return (
    <ShadowView style={styles.shadowContainer}>
      <TouchableOpacity activeOpacity={0.7} onPress={onClickWidget}>
        <View style={styles.wrapper}>
          <LinearGradient
            colors={[colors.aquaMarineLighter, colors.aquaMarineTwo]}
            useAngle
            angle={270}
            style={styles.container}>
            <Image source={require('app/images/vitalscan.png')} style={styles.image} />

            <View style={{ flex: 1, marginVertical: 18, alignItems: 'flex-start' }}>
              <View style={{ width: '60%' }}>
                <Text style={styles.textTitle}>{translate(pt.namu_vitalscan)}</Text>
              </View>
              <View style={{ width: '60%' }}>
                <Text style={styles.textContent}>Conheça sobre sua saúde em segundos!</Text>
              </View>
            </View>
            <ButtonBaseIcon
              useIcon
              backgroundColor={colors.white}
              icon={<Icon name={'chevron-right'} color={colors.aquaMarineTwo} />}
              onPress={onClickWidget}
              style={styles.buttonBase}
            />
          </LinearGradient>
        </View>
      </TouchableOpacity>
    </ShadowView>
  );
};

const styles = StyleSheet.create({
  shadowContainer: {
    marginHorizontal: 20,
    backgroundColor: colors.white,
    borderRadius: 10,
    shadowColor: colors.black,
    shadowOpacity: 0.1,
    shadowRadius: 5,
    shadowOffset: { width: 0, height: 5 },
  },
  container: {
    flex: 1,
    paddingHorizontal: 20,
    borderRadius: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: colors.aquaMarineTwo,
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
  },
  wrapper: {
    overflow: 'hidden',
    borderRadius: 10,
  },
  logoContainer: {
    paddingHorizontal: 25,
    paddingVertical: 10,
    borderRadius: 10,
  },
  card: {
    borderRadius: 10,
    backgroundColor: colors.blackTwo + '1A',
    paddingVertical: 3,
    paddingHorizontal: 12,
    alignItems: 'center',
  },
  buttonBase: {
    height: 40,
    width: 40,
    position: 'absolute',
    right: 20,
    borderRadius: 10,
    shadowColor: colors.black,
    shadowOpacity: 0.2,
    shadowRadius: 5,
    shadowOffset: { width: 0, height: 5 },
  },
  image: {
    aspectRatio: 1.314,
    width: '50%',
    height: '100%',
    position: 'absolute',
    resizeMode: 'contain',
    right: 0,
  },
  textContent: {
    fontFamily: fonts.MEDIUM,
    fontSize: 13,
    lineHeight: 17,
    marginTop: 10,
    color: colors.white,
  },
  textTitle: {
    fontFamily: fonts.BOLD,
    fontSize: 16,
    lineHeight: 17,
    marginTop: 10,
    color: colors.white,
  },
});
