export function handleFavorites(favorite) {
  return {
    type: '@favorites/HANDLE_FAVORITE',
    payload: { favorite },
  };
}

export function updateFavoritesSuccess(favorites) {
  return {
    type: '@favorites/UPDATE_FAVORITES_SUCCESS',
    payload: { favorites },
  };
}

export function setShowFavorites(showFavorites) {
  return {
    type: '@favorites/SET_SHOW_FAVORITES',
    payload: { showFavorites },
  };
}
