import ButtonBase from 'app/components/ButtonBase';
import pt from 'app/i18n/src/locale/pt';
import colors from 'app/styles/colors';
import fonts from 'app/styles/fonts';
import React from 'react';
import { Image, StyleSheet, Text, View, Modal } from 'react-native';
import circle_question from 'app/images/circle_question.png';
import backgroundCustomSearch from 'app/images/backgroundCustomSearch.png';
import { useSelector } from 'react-redux';
import { translate } from 'app/i18n/src/locale';

const BusinessCreditsHelpModal = ({ open, onClose }) => {
  const { profile } = useSelector(state => state.user);

  const isPool = () => {
    return profile?.plan_meta?.tele_enabled && profile?.plan_meta?.tele_balance === 'pool';
  };

  const message = () => {
    const text = isPool()
      ? profile?.plan_meta?.tele_limit
        ? pt.shared_credits_message_pool
        : pt.shared_credits_message_pool_without_limit
      : pt.shared_credits_message;
    const credits = isPool() ? profile?.plan_meta?.tele_limit : profile?.plan_meta?.tele_credits;

    return translate(text, { business: profile?.business_title, credits, minutes: 20 });
  };

  return (
    <Modal transparent style={styles.container} visible={open} onBackdropPress={onClose}>
      <View style={styles.content}>
        <View style={styles.circleQuestion}>
          <Image
            source={circle_question}
            style={{ tintColor: colors.primaryPurple, width: 50, height: 50 }}
          />
        </View>
        <View style={styles.wrapper}>
          <Text style={styles.textHeader}>
            {translate(isPool() ? pt.shared_credits : pt.credits)}
          </Text>
          <View style={{ marginTop: 36 }}>
            <Text style={styles.textStyle}>{message()}</Text>
          </View>
          <ButtonBase
            onPress={() => onClose()}
            backgroundColor={colors.primaryPurple}
            style={styles.buttonOk}
            title={pt.okUnderstending}
          />
        </View>
        <Image
          source={backgroundCustomSearch}
          style={{
            position: 'absolute',
            tintColor: colors.primaryPurple,
            bottom: 0,
            width: '100%',
            height: 'auto',
            aspectRatio: 375 / 229,
            resizeMode: 'contain',
            zIndex: -1,
          }}
        />
      </View>
    </Modal>
  );
};

export default BusinessCreditsHelpModal;

const styles = StyleSheet.create({
  container: {
    margin: 0,
  },
  wrapper: {
    alignItems: 'center',
    paddingTop: 42,
    paddingHorizontal: 28,
  },
  circleQuestion: {
    width: 122,
    height: 122,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 2,
    borderColor: colors.primaryPurple,
    borderRadius: 122,
    shadowColor: colors.blackTwo,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowRadius: 18,
    shadowOpacity: 0.16,
    backgroundColor: 'white',
    elevation: 5,
    marginTop: -56,
  },
  content: {
    alignItems: 'center',
    backgroundColor: colors.white,
    marginTop: 'auto',
    maxHeight: 500,
    borderTopLeftRadius: 47,
    borderTopRightRadius: 47,
    paddingBottom: 40,
  },
  textStyle: {
    fontFamily: fonts.REGULAR,
    color: colors.greyLight4,
    fontSize: 14,
    paddingHorizontal: 20,
    textAlign: 'center',
  },
  textHeader: {
    fontFamily: fonts.BOLD,
    fontSize: 20,
    color: colors.primaryPurple,
  },
  buttonOk: {
    width: 220,
    height: 50,
    marginTop: 52,
    zIndex: 1,
  },
});
