import React, { useEffect, useRef, useState } from "react"
import moment from "moment";
import { translate } from "../../../../../i18n/src/locale";
import pt from "../../../../../i18n/src/locale/pt";
import colors from "../../../../../styles/colors";
import fonts from "../../../../../styles/fonts";
import APP from "../../../../../configs/app";
import Modal, { ModalButton } from "./Modal";
import { Image, TouchableOpacity } from "react-native";
import { useNavigation } from "@react-navigation/native";

const waitingSeconds = 60 * APP.SESSION_WAITING_MINUTES;

const WaitingRoom = ({ session, endSession }) => {

  const navigation = useNavigation();

  const [countdown, setCountdown] = useState(1);
  const counter = useRef(null);
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const resetCounter = () => {
      if (session !== null) {
        if (counter.current !== null)
        clearInterval(counter.current);
        initCountdown();
      }

      return () => {
        if (counter.current !== null)
          clearInterval(counter.current);
      }
    }
    resetCounter();
  }, [session]);

  useEffect(() => {
    if (countdown <= 0)
      timeIsUp();
  }, [countdown]);

  const timeIsUp = () => {
    clearInterval(counter.current);
    endSession(false);
    alert(
      translate(pt.credit_will_be_reversed),
      translate(pt.professional_will_not_be_able_to_attend)
    );
  }

  const counterTick = () => {
    const diff = moment().diff(moment(session.sessionDate), 'seconds');
    setCountdown(waitingSeconds - diff);
  }

  const initCountdown = () => {
    if (!session?.sessionDate) return;

    const diff = moment().diff(moment(session.sessionDate), 'seconds');
    const remainingTime = waitingSeconds - diff;
    if (diff >= waitingSeconds) {
      setCountdown(1);
    } else {
      clearInterval(counter.current);
      setCountdown(remainingTime);
      counter.current = setInterval(counterTick, 1000);
    }
  }

  return (
    <div style={styles.container}>
      <div style={styles.remainingTimeBox}>
        <TouchableOpacity style={{padding: 10}} onPress={navigation.goBack}>
          <Image source={require('app/images/chevronLeft.png')} style={{width: 26, height: 26, resizeMode: 'contain', tintColor: 'white'}}/>
        </TouchableOpacity>
        <div>
          <p style={styles.remainingTimeLabel}>{translate(pt.waiting_time)}</p>
          <p style={styles.remainingTimeText}>
            {moment
              .utc(moment.duration(countdown, 'seconds').asMilliseconds())
              .format('mm:ss')
            }
          </p>
        </div>
      </div>
      <div style={styles.middleBox}>
        <img src={require('app/images/logoBig.png')}/>
        <p style={styles.messageBox}>{translate(pt.waiting_pro)}</p>
      </div>
      <Modal
        title={"Namu"}
        isOpen={isModalOpen}
      >
        {translate(pt.credit_will_be_reversed)}
        {translate(pt.professional_will_not_be_able_to_attend)}
        <ModalButton
          text={"Ok"}
          onClick={() => {
            setModalOpen(false);
          }}
        />
      </Modal>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    background: `linear-gradient(70deg, ${colors.aquaMarineTwo}, ${colors.shadowBlue})`,
    height: '100%',
    width: '100%',
    padding: '1rem'
  },
  remainingTimeBox: {
    display: 'flex',
    height: '30px'
  },
  remainingTimeLabel: {
    margin: 0,
    color: colors.white,
    fontFamily: fonts.REGULAR,
    fontSize: 14,
    fontWeight: '400',
  },
  remainingTimeText: {
    margin: 0,
    color: colors.white,
    fontFamily: fonts.REGULAR,
    fontSize: 31,
    fontWeight: '400',
  },
  middleBox: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 50,
  },
  messageBox: {
    marginTop: 0,
    textAlign: 'center',
    color: colors.white,
    fontFamily: fonts.REGULAR,
    fontSize: 18,
    fontWeight: '700'
  }
}

export default WaitingRoom;
