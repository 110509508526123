import React from 'react';

import { View, Image, TouchableOpacity, StyleSheet, Text, StatusBar } from 'react-native';
import { useSafeArea } from 'react-native-safe-area-context';
import colors from '../styles/colors';
import fonts from 'app/styles/fonts';
import chevronLeft from '../images/chevronLeft.png';
import LinearGradient from 'react-native-linear-gradient';
import { useNavigation } from '@react-navigation/native';

const HeaderAquamarine = ({
  title,
  noPadding,
  useGradient,
  gradientColors,
  onPress,
  hideStatusBar,
  textStyle,
  color,
}) => {
  const navigation = useNavigation();
  const safeAreaInsets = useSafeArea();
  return (
    <View>
      {!hideStatusBar && (
        <StatusBar translucent backgroundColor="transparent" barStyle="light-content" />
      )}
      <LinearGradient
        useAngle
        angle={60}
        colors={
          useGradient
            ? gradientColors
            : color
            ? [color, color]
            : [colors.aquaMarineTwo, colors.aquaMarineGrd]
        }
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 1 }}
        location={[0.8, 1.1]}
        style={{ width: '100%' }}>
        <View
          style={{
            paddingTop: noPadding ? 0 : safeAreaInsets.top,
          }}>
          <View style={[styles.bar, { backgroundColor: 'transparent' }]}>
            <TouchableOpacity style={styles.backButton} onPress={onPress ? onPress : navigation.goBack}>
              <Image source={chevronLeft} style={[styles.backImage, { tintColor: colors.white }]} />
            </TouchableOpacity>
            <View style={styles.textContent}>
              <Text numberOfLines={1} style={[styles.title, textStyle]}>
                {title}
              </Text>
            </View>
          </View>
        </View>
      </LinearGradient>
    </View>
  );
};

export default HeaderAquamarine;

const styles = StyleSheet.create({
  title: {
    color: colors.white,
    fontSize: 18,
    fontWeight: '500',
    width: '75%',
    position: 'relative',
    textAlign: 'left',
    fontFamily: fonts.BOLD,
  },
  bar: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: 50,
    paddingHorizontal: 8,
    backgroundColor: colors.aquaMarineTwo,
  },
  textContent: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: 12,
    height: '100%',
  },
  backButton: {
    width: 26,
    height: 26,
    marginLeft: 6,
  },
  backImage: {
    resizeMode: 'contain',
    width: 26,
    height: 26,
  },
});
