import React, { useRef } from 'react';

import { View, StyleSheet, TouchableOpacity, Image } from 'react-native';
import chevronLeft from '../../../../images/chevronLeft.png';
import colors from '../../../../styles/colors';
import chevronRight from '../../../../images/chevronRight.png';
import filterWhiteOff from '../../../../images/filterWhiteOff.png';
import { Text } from 'react-native-animatable';
import textStyles from '../../../../styles/textStyles';
import arrowDropDown from '../../../../images/arrowRightOffline.png';
import { Menu, MenuItem } from 'react-native-enhanced-popup-menu';
import { translate } from '../../../../i18n/src/locale';
import pt from '../../../../i18n/src/locale/pt';
import menuMeal from '../../../../images/menuMeal.png';
import menuActivity from '../../../../images/menuActivity.png';
import MenuItemContent from '../../../profile/components/row/MenuItemContent';
import menuMeditation from '../../../../images/menuMeditation.png';
import menuCalendar from '../../../../images/menuCalendar.png';
import moment from 'moment';
import { sendActionEvent } from 'app/services/utils';
import { useSelector } from 'react-redux';
import { Divider } from 'react-native-paper';

const HeaderMonth = ({ open, onPress, month, previousMonth, nextMonth, selectCategory }) => {
  const menuRef = useRef();
  const buttonRef = useRef();
  const hideMenu = () => menuRef.current.hide();
  const showMenu = () => menuRef.current.show(buttonRef.current);

  const { profile } = useSelector(state => state.user);

  const selectTypeFilter = type => {
    selectCategory(type);

    if (type === 1) {
      sendActionEvent('click_filter_in_agenda', {
        type: 'meal',
        email: profile.email,
      }, profile.email);
    } else if (type === 2) {
      sendActionEvent('click_filter_in_agenda', {
        type: 'activity',
        email: profile.email,
      }, profile.email);
    } else if (type === 3) {
      sendActionEvent('click_filter_in_agenda', {
        type: 'meditation',
        email: profile.email,
      }, profile.email);
    }
  };

  return (
    <>
      <View style={styles.container}>
        {!open && (
          <>
            <View />
            <TouchableOpacity onPress={previousMonth}>
              <Image source={chevronLeft} style={{ tintColor: colors.aquaMarineTwo, width: 18, height: 18 }} />
            </TouchableOpacity>
          </>
        )}

        <TouchableOpacity style={styles.labelMonth} onPress={onPress}>
          <Text style={[textStyles.bold16]}>{moment(month).format('MMMM')}</Text>
          {open && <Image source={arrowDropDown} style={[styles.backarrow, { width: 8, height: 8 }]} />}
        </TouchableOpacity>

        {!open && (
          <TouchableOpacity onPress={nextMonth}>
            <Image source={chevronRight} style={{ tintColor: colors.aquaMarineTwo, width: 18, height: 18 }} />
          </TouchableOpacity>
        )}
        <TouchableOpacity onPress={showMenu} ref={buttonRef}>
          <Image source={filterWhiteOff} style={{ tintColor: colors.aquaMarineTwo, width: 18, height: 18 }} />
        </TouchableOpacity>
      </View>
      <Menu ref={menuRef}>
        <MenuItem
          onPress={() => {
            selectTypeFilter(1);
            hideMenu();
          }}>
          <MenuItemContent title={translate(pt.meal)} icon={menuMeal} />
        </MenuItem>
        <Divider />
        <MenuItem
          onPress={() => {
            selectTypeFilter(2);
            hideMenu();
          }}>
          <MenuItemContent title={translate(pt.activities)} icon={menuActivity} />
        </MenuItem>
        <Divider />
        <MenuItem
          onPress={() => {
            selectTypeFilter(3);
            hideMenu();
          }}>
          <MenuItemContent title={translate(pt.meditation)} icon={menuMeditation} />
        </MenuItem>
        <Divider />
        <MenuItem
          onPress={() => {
            selectTypeFilter(0);
            hideMenu();
          }}>
          <MenuItemContent title={translate(pt.filter_date)} icon={menuCalendar} />
        </MenuItem>
      </Menu>
    </>
  );
};

export default HeaderMonth;

const styles = StyleSheet.create({
  labelMonth: { paddingHorizontal: 16, flexDirection: 'row', alignItems: 'center' },
  container: {
    height: 60,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 16,
    justifyContent: 'space-between',
  },
  backarrow: {
    marginLeft: 5,
    transform: [{ rotateZ: '90deg' }],
    height: 10,
    marginTop: 3,
  },
});
