import HeaderAquamarine from 'app/components/HeaderAquamarine';
import { translate } from 'app/i18n/src/locale';
import pt from 'app/i18n/src/locale/pt';
import apiNode from 'app/services/apiNode';
import colors from 'app/styles/colors';
import fonts from 'app/styles/fonts';
import React, { useEffect, useCallback, useState, useRef } from 'react';
import { SafeAreaView, StyleSheet, Text, ScrollView, View } from 'react-native';
import ShadowView from '@liuyunjs/react-native-simple-shadow-view';
import ButtonBase from 'app/components/ButtonBase';
import ButtonLink from 'app/components/ButtonLink';
import MyMultiSelectView from 'app/components/MyMultiSelectView';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { ActivityIndicator } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';

const FilterCoursesScreen = ({ route }) => {
  const { onFilter, headerColors = [], options, color, filters = [] } = route?.params;
  const navigation = useNavigation();

  const dispatch = useDispatch();
  const [initialFilters, setInitialFilters] = useState({});
  const [currentFilters, setCurrentFilters] = useState({});
  const [filterSectionNames, setFilterSectionNames] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let initialFilters = '';
    if (options.hasOwnProperty('courseCategoryIds')) {
      const cats = Array.from(
        new Set(
          Object.entries(options.courseCategoryIds)
            .flat(2)
            .map(x => parseInt(x))
        )
      ).filter(x => x in options.categories);
      initialFilters = {
        categories: cats.map(o => ({
          id: o,
          active: filters.includes(parseInt(o)) ? true : false,
          checked: filters.includes(parseInt(o)) ? true : false,
          name: options.categories[o],
        })),
      };
    } else {
      initialFilters = { categories: [] };
    }
    const newFilterSectionNames = Object.keys(initialFilters).filter(name => name !== 'filter');
    setFilterSectionNames(newFilterSectionNames);
    setCurrentFilters(initialFilters);
  }, [initialFilters]);

  const handleSelectionChange = (status, index, sectionName) => {
    const newFilters = _.clone(currentFilters);

    const newSection = newFilters[sectionName].map((item, itemIndex) => {
      if (itemIndex !== index) {
        return item;
      }
      if (itemIndex === index) {
        const newValue = { ...item, ...{ active: status } };
        return newValue;
      }
    });

    newFilters[sectionName] = newSection;

    setCurrentFilters(newFilters);
  };

  const handlePressFilter = async () => {
    navigation.goBack();
    const filtros = currentFilters.categories.filter(f => f.checked == true).map(f => f.id);
    onFilter(filtros);
  };

  const desactiveCapsule = props => {
    return (
      <ShadowView style={[styles.card_choice, { borderColor: 'transparent' }]}>
        <Text style={[styles.text_choice, { color: colors.greyBlue }]}>{props.item.name}</Text>
      </ShadowView>
    );
  };

  const activeCapsule = props => {
    return (
      <ShadowView style={[styles.card_choice, { backgroundColor: color, borderColor: color }]}>
        <Text style={[styles.text_choice, { color: colors.white }]}>{props.item.name}</Text>
      </ShadowView>
    );
  };

  const handlePressClear = () => {
    let emptyFilters = { ...currentFilters };
    Object.keys(currentFilters).map(sectionName => {
      emptyFilters[sectionName] = emptyFilters[sectionName].map(option => {
        return { ...option, active: false, checked: false };
      });
    });
    setCurrentFilters(emptyFilters);
  };

  return (
    <View style={{ flex: 1, backgroundColor: colors.paleGrey }}>
      <HeaderAquamarine
        title="Filtro"
        textStyle={styles.headerText}
        useGradient={headerColors.length > 1}
        gradientColors={headerColors}
        color={color}
      />
      <View style={{ flex: 1 }}>
        <ScrollView style={styles.container}>
          {loading ? (
            <View style={styles.flexCenter}>
              <ActivityIndicator size="large" color={colors.aquaMarineTwo} />
            </View>
          ) : (
            <>
              {filterSectionNames.map(sectionName => (
                <View>
                  <Text style={styles.sectionTitle}>{translate(pt[sectionName])}</Text>
                  <View style={{ marginTop: 10 }}>
                    <MyMultiSelectView
                      checkedKey={'active'}
                      valueKey={'name'}
                      style={{ marginTop: 11, marginBottom: 30, flex: 1 }}
                      onSelectionStatusChange={(status, index) =>
                        handleSelectionChange(status, index, sectionName)
                      }
                      data={currentFilters[sectionName]}
                      desactiveView={desactiveCapsule}
                      activeView={activeCapsule}
                    />
                  </View>
                </View>
              ))}
            </>
          )}
        </ScrollView>
        <View style={styles.containerButtons}>
          <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
            <ButtonBase
              title={translate(pt.filter)}
              backgroundColor={color}
              fill
              onPress={handlePressFilter}
            />
          </View>
          <View style={{ justifyContent: 'center', alignItems: 'center', marginVertical: 36 }}>
            <ButtonLink title={translate(pt.clean_filter)} underline onPress={handlePressClear} />
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  headerText: {
    color: colors.white,
  },
  container: {
    paddingHorizontal: 22,
    paddingTop: 28,
    flex: 1,
  },
  dropdown: {
    borderRadius: 30,
    paddingHorizontal: 32,
  },
  dropdownText: {
    fontFamily: fonts.REGULAR,
    fontSize: 12,
    color: colors.greyBlue,
  },
  sectionTitle: {
    fontSize: 16,
    color: colors.slateGrey,
    fontFamily: fonts.BOLD,
    textTransform: 'uppercase',
  },
  card_choice: {
    borderWidth: 1,
    marginRight: 12,
    marginBottom: 8,
    height: 40,
    borderRadius: 30,
    backgroundColor: colors.white,
    alignContent: 'center',
    justifyContent: 'center',
    paddingHorizontal: 22,
  },
  text_choice: {
    fontSize: 12,
    fontFamily: fonts.REGULAR,
  },
  containerButtons: {
    paddingHorizontal: 12,
  },
  buttonClear: {
    backgroundColor: 'transparent',
    borderColor: colors.greyBlue,
    borderWidth: 1,
  },
  textButtonClear: {
    color: colors.greyBlue,
  },
  flexCenter: { flex: 1, alignItems: 'center', justifyContent: 'center' },
});

export default FilterCoursesScreen;
