import React from 'react';

import { View, Text, StyleSheet } from 'react-native';
import colors from '../../../styles/colors';
import textStyles from '../../../styles/textStyles';

const HeaderContentWhite = ({ title, content }) => {
  return (
    <View style={styles.container}>
      <Text numberOfLines={1} style={textStyles.semi14}>
        {title}
      </Text>
      {content && (
        <Text numberOfLines={1} style={textStyles.semi16}>
          {content}
        </Text>
      )}
    </View>
  );
};

export default HeaderContentWhite;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    paddingHorizontal: 15,
    height: 49,
    borderRadius: 6,
    marginBottom: 16,
    backgroundColor: colors.white,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});
