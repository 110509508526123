import React, { Component } from 'react';
import { View, Text, StyleSheet, Image, TouchableOpacity } from 'react-native';
import colors from 'app/styles/colors';
import videoLock from '../../images/videoLock.png';

class MaskLevelPayment extends Component {
  render() {
    return (
      <View style={styles.containerPaymentLevel}>
        <View style={styles.oval}>
          <Image source={videoLock} />
        </View>
        <View style={styles.mask} />
      </View>
    );
  }
}

export default MaskLevelPayment;

const styles = StyleSheet.create({
  containerPaymentLevel: {
    position: 'absolute',
    justifyContent: 'center',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 6,
  },
  oval: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 34 / 2,
    zIndex: 5,
    left: 26,
    width: 34,
    height: 34,
    backgroundColor: colors.greyBlue,
  },
  mask: {
    position: 'absolute',
    zIndex: 3,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    minHeight: 71,
    borderRadius: 3,
    opacity: 0.5,
    backgroundColor: colors.white,
  },
});
