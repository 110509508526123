import { translate } from 'app/i18n/src/locale';
import pt from 'app/i18n/src/locale/pt';
import colors from 'app/styles/colors';
import fonts from 'app/styles/fonts';
import React from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';

const MessageBar = props => {
  return (
    <View style={styles.container}>
      <TouchableOpacity
        style={styles.button}
        onPress={() => {
          if (!props.isSending) {
            props.selectDocument();
          }
        }}>
        <Text style={styles.textButton}>{translate(pt.new_attachment)}</Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.greyF5,
    flexDirection: 'column',
  },
  button: {
    borderRadius: 5,
    marginHorizontal: 40,
    marginVertical: 10,
    alignSelf: 'stretch',
    borderWidth: 2,
    padding: 5,
    borderColor: colors.cornflowerBlue,
  },
  textButton: {
    fontSize: 18,
    color: colors.cornflowerBlue,
    fontFamily: fonts.BOLD,
    textAlign: 'center',
  },
});

export default MessageBar;
