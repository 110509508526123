import React from 'react';

import { View, StyleSheet, Text } from 'react-native';
import fonts from '../styles/fonts';
import colors from '../styles/colors';

const InfoPortion = ({ style, InfoPortion, weightMeal, originalYield }) => {
  const InfoPortionText = () => {
    if (InfoPortion != null) {
      return (
        <View style={{ flex: 1 }}>
          <Text
            style={
              styles.content
            }>{`Esta receita tem como base o rendimento de ${originalYield} porção(s), consuma apenas ${Math.ceil(
            weightMeal
          )} gr, o equivalente a ${InfoPortion} porção(s) para estar de acordo com as orientações nutricionais da sua refeição/programa`}</Text>
        </View>
      );
    }

    return (
      <View style={{ flex: 1 }}>
        <Text />
      </View>
    );
  };

  return InfoPortionText();
};

export default InfoPortion;

const styles = StyleSheet.create({
  content: {
    fontFamily: fonts.REGULAR,
    fontSize: 12,
    textAlign: 'justify',
    color: colors.blackTwo,
    paddingHorizontal: 14,
    marginBottom: 18,
  },
});
