import React from 'react';
import { View, Text, StyleSheet, ScrollView } from 'react-native';
import colors from 'app/styles/colors';
import fonts from 'app/styles/fonts';

export const HomeAndYouComponents = () => (
  <ScrollView>
    <View style={styles.container}>
      <Text style={styles.textDefaultStyle}>
        <Text style={styles.textBold}>Abrir o APP:</Text> Abra o app e navegue por no mínimo 2
        minutos e curta essa experiência. Se manter a sequência, a pontuação aumenta para +5 a cada
        dia, ou seja,mantendo a sequência por 3 dias, a pontuação do dia será +15 por acessar o app.
        A sequência do aumento é dada por até 30 dias. Pontos: 5 (por dia).
      </Text>

      <Text style={styles.textDefaultStyleWithMargin}>
        <Text style={styles.textBold}>Home:</Text> A home é a tela inicial do app, com todas as
        funcionalidades, desde agenda, últimos acessos, aulas populares, treino por tempo, treino
        por objetivo, dica de leitura, hidratação e muito mais. Lá você consegue personalizar todos
        widget para auxiliar na sua jornada de saúde e bem-estar. Pontos: 1 (por dia - para pontuar
        basta clicar na opção (INÍCIO), localizado no menu inferior do APP).
      </Text>

      <Text style={styles.textDefaultStyleWithMargin}>
        <Text style={styles.textBold}>Personalizar home:</Text> Clique na sua imagem de usuário, no
        topo direito do app para acessar as suas configurações e assim escolher a opção
        “Personalizar início” para que você possa habilitar, desabilitar e determinar qual a ordem
        dos conteúdos, serviços e funcionalidades que você deseja ter acesso rápido a partir da sua
        página de Início. Pontos: 5 (uma única vez durante o período da ação)
      </Text>

      <Text style={styles.textDefaultStyleWithMargin}>
        <Text style={styles.textBold}>Diário emocional:</Text> Uma ferramenta que mede como anda o
        seu estado emocional e os seus comportamentos. Ela ajuda a ter um parâmetro geral da sua
        vida emocional. Pontos: 3 (por dia - para pontuar basta registrar o seu sentimento do dia,
        clicando em “REGISTRAR SENTIMENTO” na opção “DIÁRIO EMOCIONAL” localizada na home do
        aplicativo).
      </Text>

      <Text style={styles.textDefaultStyleWithMargin}>
        <Text style={styles.textBold}>Hidratação widget:</Text> Na sua página de início você pode
        registrar a quantidade de água consumida durante o dia. Tendo assim o controle da sua meta
        diária. Pontos: 5 (por dia - para pontuar basta clicar no texto “VER MAIS” na opção
        “HIDRATAÇÃO” localizada na home do aplicativo).
      </Text>
    </View>
  </ScrollView>
);

export const TeleOrientacao = () => (
  <ScrollView>
    <View style={styles.container}>
      <Text style={styles.textDefaultStyle}>
        <Text style={styles.textBold}>Tele Orientação:</Text> Uma rede qualificada de profissionais
        especializados em saúde física, saúde mental, saúde financeira e alimentação saudável,
        auxiliando com apoio e orientações em chamadas de vídeo de até 20 minutos cada. Pontos: 5
        (uma única vez durante o período da ação, para pontuar basta clicar na opção
        (PROFISSIONAIS), localizado no menu inferior do APP, não precisa realizar nenhum
        agendamento).
      </Text>
    </View>
  </ScrollView>
);

export const Agenda = () => (
  <ScrollView>
    <View style={styles.container}>
      <Text style={styles.textDefaultStyle}>
        <Text style={styles.textBold}>Agenda:</Text> A agenda auxilia no desenvolvimento das suas
        atividades do app, proporcionando um desempenho maior para controlar o seu tempo, além de
        organizar as suas atividades selecionadas para o dia. Pontos: 1 (por dia - para pontuar
        basta clicar na opção (AGENDA), localizado no menu inferior do APP).
      </Text>
    </View>
  </ScrollView>
);

export const BlogAndPost = () => (
  <ScrollView>
    <View style={styles.container}>
      <Text style={styles.textDefaultStyle}>
        <Text style={styles.textBold}>Blog e Posts:</Text>
        Tenha acesso aos mais atuais e relevantes conteúdos a respeito de Saúde, Qualidade de vida e
        Bem-estar. Pontos: 1 (por dia que entrar na opção “BLOG”, localizado no menu inferior do
        APP); Pontos: 1 (Por leitura de cada artigo (Post) com permanência mínima de 2 minutos -
        Pontuação máxima de 10 posts por dia ou seja 100 pontos por dia)
      </Text>
    </View>
  </ScrollView>
);

export const Exercicio = () => (
  <ScrollView>
    <View style={styles.container}>
      <Text style={styles.textDefaultStyle}>
        <Text style={styles.textBold}>Exercícios:</Text>
        São as sequências de vídeos com os exercícios que formam as aulas, e que por sua vez possuem
        características, recomendações e objetivos próprios.
        <Text style={styles.textBold}>Pontos: 1</Text> (pela visualização de cada página de
        especificações dos exercícios, com intervalo mínimo de 2 minutos entre os acessos. Pontuação
        máxima de 10 exercícios por dia ou seja 100 pontos por dia - para pontuar acesse a opção
        “AULAS”, localizado no menu inferior do APP e depois clique na categoria CORPO, localizada
        no menu superior logo abaixo da barra de busca de conteúdo, desça a tela para visualizar as
        aulas e subcategorias de corpo, escolha uma e clique, na página da aula desça a tela para
        visualizar a relação de “EXERCÍCIOS” daquela aula - e então clique em uma delas para
        visualizar as suas características.
      </Text>

      <Text style={styles.textDefaultStyleWithMargin}>
        <Text style={styles.textBold}>Aulas:</Text>
        Uma sequência de exercícios que dão uma origem à aula, cada uma delas possui um objetivo
        específico, pode ser tanto de alimentação saudável, saúde física e saúde mental. Pontos: 1
        (por dia que entrar na opção “AULAS”, localizado no menu inferior do APP e depois clicar na
        categoria ALIMENTAÇÃO - no menu superior logo abaixo da barra de busca de conteúdo). Pontos:
        1 (por dia que entrar na opção “AULAS”, localizado no menu inferior do APP e depois clicar
        na categoria CORPO - no menu superior logo abaixo da barra de busca de conteúdo). Pontos: 1
        (por dia que entrar na opção “AULAS”, localizado no menu inferior do APP e depois clicar na
        categoria MENTE - no menu superior logo abaixo da barra de busca de conteúdo). Pontos: 1
        (por aula acessada, com intervalo mínimo de 2 minutos entre os acessos. Pontuação máxima de
        15 aulas por dia ou seja 150 pontos por dia).
      </Text>

      <Text style={styles.textDefaultStyleWithMargin}>
        <Text style={styles.textBold}>Programas:</Text>
        São o agrupamento de aulas, que dentro de uma programação semanal, possuem determinado
        objetivo ou geram um benefício de saúde e bem-estar específico. Pontos: 5 (uma única vez
        durante o período da ação, para pontuar basta clicar no texto “VER MAIS” na opção
        “PROGRAMAS” localizada na home do aplicativo). Pontos: 1 (por programa acessado, com
        intervalo mínimo de 2 minutos entre os acessos. Pontuação máxima de 10 programas por dia ou
        seja 100 pontos por dia).
      </Text>
    </View>
  </ScrollView>
);

export const Course = () => (
  <ScrollView>
    <View style={styles.container}>
      <Text style={styles.textDefaultStyle}>
        <Text style={styles.textBold}>Cursos:</Text>
        Tenha acesso total às aulas e materiais complementares de mais de 50 cursos que irão te
        ajudar ainda mais a evoluir na sua jornada de bem-estar físico, mental, alimentar, social e
        financeiro. Pontos: 1 (por curso acessado, com intervalo mínimo de 2 minutos entre os
        acessos. Pontuação máxima de 10 cursos por dia ou seja 100 pontos por dia - para pontuar
        acesse a opção “AULAS”, localizado no menu inferior do APP e depois clique em uma das
        categorias (ALIMENTAÇÃO, CORPO OU MENTE) no menu superior logo abaixo da barra de busca de
        conteúdo) e aí clique em ‘VER TUDO’ para visualizar os cursos da categoria escolhida, e por
        fim clique em um deles para acessá lo.
      </Text>
    </View>
  </ScrollView>
);

export const DishesAndMeal = () => (
  <ScrollView>
    <View style={styles.container}>
      <Text style={styles.textDefaultStyle}>
        <Text style={styles.textBold}>Pratos e refeições:</Text>
        Traz o passo a passo, ingredientes, rendimento de porção e valores nutricionais dos pratos
        que irão compor a sua refeição completa, com foco no seu objetivo, sempre repleto de sabores
        e opções. Pontos: 1 (por prato acessado, com intervalo mínimo de 2 minutos entre os acessos.
        Pontuação máxima de 10 pratos por dia ou seja 100 pontos por dia).
      </Text>
      <Text style={styles.textDefaultStyleWithMargin}>
        <Text style={styles.textBold}>Refeições:</Text>A home é a tela inicial do app, com todas as
        funcionalidades, desde agenda, últimos acessos, aulas populares, treino por tempo, treino
        por objetivo, dica de leitura, hidratação e muito mais. Lá você consegue personalizar todos
        widget para auxiliar na sua jornada de saúde e bem-estar. Pontos: 1 (por dia - para pontuar
        basta clicar na opção (INÍCIO), localizado no menu inferior do APP).
      </Text>
    </View>
  </ScrollView>
);

const styles = StyleSheet.create({
  container: {
    padding: 5,
  },
  textDefaultStyle: {
    fontFamily: fonts.MEDIUM,
    fontSize: 14,
    color: colors.slateGrey,
  },
  textDefaultStyleWithMargin: {
    fontFamily: fonts.MEDIUM,
    fontSize: 14,
    color: colors.slateGrey,
    marginTop: 15,
  },
  textBold: {
    fontFamily: fonts.BOLD,
    fontSize: 14,
    color: colors.black,
  },
});
