
import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet, Image, Modal as ReactNativeModal, NativeModules, Platform, TouchableOpacity, TextInput, ScrollView } from 'react-native';
import ShadowView from '@liuyunjs/react-native-simple-shadow-view';
import ButtonBaseIcon from 'app/components/ButtonBaseIcon';
import Icon from 'react-native-vector-icons/dist/FontAwesome';
import LinearGradient from 'react-native-linear-gradient';
import useGlobalAlert from 'app/hooks/useGlobalAlert';
import { translate } from 'app/i18n/src/locale';
import pt from 'app/i18n/src/locale/pt';
import fonts from 'app/styles/fonts';
import { useSelector } from 'react-redux';
import colors from 'app/styles/colors';
import textStyles from 'app/styles/textStyles'
import api from 'app/services/api';
// import { showMessage } from 'react-native-flash-message';
import { errorMessage, successMessage } from 'app/services/utils';
import ButtonBase from 'app/components/ButtonBase';
import CustomIcon from 'app/styles/icon';
import { useNavigation } from '@react-navigation/native';

const RenderForm = ({visible, onClose}) => {
    const navigation = useNavigation();
    const [name, setName] = useState('')
    const [city, setCity] = useState('')
    const [observation, setObservation] = useState('')

    const onPressModal = async () => {

        try {
            if(!name || !city){
                errorMessage('Preencha os campos obrigatórios!')
                return;
            }
            console.log(name, city, observation)
            await api.post('gyms/recommendation', {
                gymName: name,
                city: city,
                message: observation
            }, { headers: { 'Content-Type': 'application/json'}})

            
            onClose();
            successMessage('Indicação enviada!')
        } catch (error){
            console.log(JSON.stringify(error, null, 2))
            onClose();
            errorMessage('Erro ao enviar indicação, tente mais tarde.')
        }
    }

    return (
    <ReactNativeModal
        // style={styles.container}
        coverScreen
        visible={visible}
        onBackdropPress={onClose}>
        <View style={{
            backgroundColor: colors.white,
            borderRadius: 20,
            // marginHorizontal: 10,
            overflow: 'hidden',
            maxHeight: '90%',
            alignItems: 'center',
            width: '100%',
            padding: 20,
            elevation: 5,
            shadowColor: colors.black,
            shadowOffset: {
            width: 0,
            height: 2,
            },
            shadowRadius: 6,
            shadowOpacity: 0.3,
            }}>
            <TouchableOpacity
              activeOpacity={0.7}
              onPress={() => onClose()}
              style={{
                position: 'absolute',
                top: 16,
                right: 16}}>
              <CustomIcon name={'close'} size={25} color={colors.black} />
            </TouchableOpacity>
            <Text style={{fontFamily: fonts.BOLD,
                fontSize: 18,
                color: colors.darkPro,
                marginBottom: 10,
                textAlign: 'center',}}>Indicar uma Academia</Text>
            <Text style={{fontFamily: fonts.REGULAR,
                fontSize: 14,
                color: colors.darkPro2,
                textAlign: 'center'}}>Insira as informações da academia que deseja indicar para o app.</Text>
            <ScrollView>
                <Text style={[textStyles.bold16, { marginVertical: 10 }]}>Nome da academia*</Text>
                <TextInput
                    autoCapitalize="none"
                    onChangeText={setName}
                    value={name}
                    placeholder={translate(pt.name)}
                    style={styles.input}
                />
                <Text style={[textStyles.bold16, { marginVertical: 10 }]}>Cidade da academia*</Text>
                <TextInput
                    autoCapitalize="none"
                    onChangeText={setCity}
                    value={city}
                    placeholder={translate(pt.city)}
                    style={styles.input}
                />
                <Text style={[textStyles.bold16, { marginVertical: 10 }]}>Observação</Text>
                <TextInput
                    autoCapitalize="none"
                    multiline
                    textAlignVertical='top'
                    numberOfLines={4}
                    onChangeText={setObservation}
                    value={observation}
                    placeholder={translate(pt.observation)}
                    style={styles.input}
                />
                <Text style={[textStyles.regular14grey]}>Se o local tornar-se um parceiro, você receberá um e-mail de notificação com mais detalhes.</Text>

            </ScrollView>
            <ButtonBase style={{width: '100%', height: 50}} onPress={onPressModal} title="Enviar" />

        </View>
    </ReactNativeModal>)
}

export const RecommendGymWidget = ({ }) => {
    const { setAlert } = useGlobalAlert();

    const [ modal, setModal ] = useState();
   
    const openIndicateFormModal = () => {
        setModal(true);
    }

    return (
        <>
            <ShadowView style={styles.shadowContainer}>
                <TouchableOpacity
                    onPress={() => openIndicateFormModal()}
                    style={styles.wrapper}>
                    <View
                        style={styles.container}>
                        <Image source={require('app/images/indicate_img.png')} style={styles.image} />

                        <View style={{ flex: 1, alignItems: 'flex-start' }}>
                            <View style={{ width: '60%' }}>
                                <Text style={styles.textTitle}>{translate(pt.which_gym_would_you_like_to_see_at_Namu_Pass)}</Text>
                            </View>
                            <View style={{ width: '60%' }}>
                                <Text style={styles.textContent}>{translate(pt.indicate_here)}</Text>
                            </View>
                        </View>
                        <ButtonBaseIcon
                            useIcon
                            backgroundColor={colors.aquaMarineTwo}
                            icon={<Icon name={'chevron-right'} color={colors.white} />}
                            onPress={() => openIndicateFormModal()}
                            style={styles.buttonBase}
                        />
                    </View>
                </TouchableOpacity>
            </ShadowView>
            {modal && <RenderForm visible={modal} onClose={() => setModal(false)}/>}
        </>
    );
};

const styles = StyleSheet.create({
    shadowContainer: {
        marginHorizontal: 20,
        backgroundColor: colors.white,
        borderRadius: 10,
        shadowColor: colors.black,
        shadowOpacity: 0.1,
        shadowRadius: 5,
        shadowOffset: { width: 0, height: 5 },
    },
    wrapper: {
        overflow: 'hidden',
        borderRadius: 10,
    },
    container: {
        flex: 1,
        padding: 20,
        borderRadius: 10,
        height: 105,
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: colors.white,
        alignItems: 'center',
        position: 'relative',
        overflow: 'hidden',
    },
    buttonBase: {
        height: 40,
        width: 40,
        position: 'absolute',
        right: 20,
        // bottom: -55,
        borderRadius: 10,
        shadowColor: colors.black,
        shadowOpacity: 0.2,
        shadowRadius: 5,
        shadowOffset: { width: 0, height: 5 },
    },
    image: {
        aspectRatio: 1.19,
        width: 150,
        height: "100%",
        resizeMode: 'contain',
        position: 'absolute',
        right: 0,
        bottom: 0,
    },
    textTitle: {
        fontFamily: fonts.BOLD,
        fontSize: 16,
        color: colors.grayText,
        lineHeight: 18
    },
    textContent: {
        fontFamily: fonts.MEDIUM,
        fontSize: 13,
        lineHeight: 17,
        marginTop: 5,
        color: colors.greyBlue,
    },
    input: {
        paddingHorizontal: 10,
        // flex: 1,
        borderRadius: 10,
        borderWidth: 1,
        borderColor: colors.lightPeriwinkle,
        color: colors.black,
        fontFamily: fonts.MEDIUM,
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
    },
});
