import React, { useCallback, useEffect, useState, useContext } from 'react';
import {
  View,
  Text,
  StyleSheet,
  Image,
  Base64,
  TouchableOpacity,
  NativeModules,
} from 'react-native';
// import RNFS from 'react-native-fs';
import ShadowView from '@liuyunjs/react-native-simple-shadow-view';
import ButtonBaseIcon from 'app/components/ButtonBaseIcon';
import Icon from 'react-native-vector-icons/dist/FontAwesome';
// import { Actions } from 'react-native-router-flux';
// import { GamificationContext } from 'app/screens/core/gamification/context/GamificationProvider';

import colors from '../../../styles/colors';
import fonts from '../../../styles/fonts';
import textStyles from '../../../styles/textStyles';
import { translate } from '../../../i18n/src/locale';
import pt from '../../../i18n/src/locale/pt';

import Pontos from '../../../assets/gamification/imagens/pontos.png';
import { useSelector } from 'react-redux';
import { useGamification } from 'app/hooks/useGamification';

import { base64Images } from 'app/screens/core/gamification/utils/conts';
import { getUserRankingBagde } from 'app/screens/core/gamification/GamificationLeaderboard';

// import Share from 'react-native-share';
import { Fragment } from 'react';
import apiNode from 'app/services/apiNode';
import { useNavigation } from '@react-navigation/native';
import CustomIcon from 'app/styles/icon';

const stapsArrays = {
  '3 dias ativos': [
    { day: 'dia 1', points: '+ 5 Pontos', value: 1, width: '20%', stap: 1 },
    { day: 'dia 2', points: '+ 10 Pontos', value: 2, width: '50%', stap: 2 },
    { day: 'dia 3', points: '+ 15 Pontos', value: 3, width: '100%', stap: 3 },
  ],
  '5 dias ativos': [
    { day: 'dia 1', points: '+ 5 Pontos', value: 1, width: '20%', stap: 1 },
    { day: 'dia 2', points: '+ 10 Pontos', value: 2, width: '30%', stap: 2 },
    { day: 'dia 3', points: '+ 15 Pontos', value: 3, width: '50%', stap: 3 },
    { day: 'dia 4', points: '+ 20 Pontos', value: 4, width: '72%', stap: 4 },
    { day: 'dia 5', points: '+ 25 Pontos', value: 5, width: '100%', stap: 5 },
  ],
  '7 dias ativos': [
    { day: 'dia 1', points: '+ 5 Pontos', value: 1, width: '20%', stap: 1 },
    { day: 'dia 2', points: '+ 10 Pontos', value: 2, width: '30%', stap: 2 },
    { day: 'dia 3', points: '+ 15 Pontos', value: 3, width: '50%', stap: 3 },
    { day: 'dia 5', points: '+ 25 Pontos', value: 5, width: '72%', stap: 4 },
    { day: 'dia 7', points: '+ 35 Pontos', value: 7, width: '100%', stap: 5 },
  ],
  '10 dias ativos': [
    { day: 'dia 1', points: '+ 5 Pontos', value: 1, width: '20%', stap: 1 },
    { day: 'dia 3', points: '+ 15 Pontos', value: 3, width: '30%', stap: 2 },
    { day: 'dia 5', points: '+ 25 Pontos', value: 5, width: '50%', stap: 3 },
    { day: 'dia 7', points: '+ 35 Pontos', value: 7, width: '72%', stap: 4 },
    { day: 'dia 10', points: '+ 50 Pontos', value: 10, width: '100%', stap: 5 },
  ],
  '15 dias ativos': [
    { day: 'dia 1', points: '+ 5 Pontos', value: 1, width: '20%', stap: 1 },
    { day: 'dia 3', points: '+ 15 Pontos', value: 3, width: '30%', stap: 2 },
    { day: 'dia 7', points: '+ 35 Pontos', value: 7, width: '50%', stap: 3 },
    { day: 'dia 10', points: '+ 50 Pontos', value: 10, width: '72%', stap: 4 },
    { day: 'dia 15', points: '+ 75 Pontos', value: 15, width: '100%', stap: 5 },
  ],
  '30 dias ativos': [
    { day: 'dia 1', points: '+ 5 Pontos', value: 1, width: '20%', stap: 1 },
    { day: 'dia 5', points: '+ 25 Pontos', value: 5, width: '30%', stap: 2 },
    { day: 'dia 10', points: '+ 50 Pontos', value: 10, width: '50%', stap: 3 },
    { day: 'dia 15', points: '+ 75 Pontos', value: 15, width: '72%', stap: 4 },
    { day: 'dia 30', points: '+ 150 Pontos', value: 30, width: '100%', stap: 5 },
  ],
};

const RankText = ({ rank }) => <Text style={textStyles.bold16AquaMarine}>{rank}</Text>;

export const GamificationWidget = ({ widget }) => {
  const navigation = useNavigation();
  const { openBoarding, onChangeCurrentGoal } = useGamification();
  const { userData, currentGoal } = useSelector(state => state.gamification);
  const { profile } = useSelector(state => state.user);
  const [achievements, setAchievements] = useState({});
  const totalSteps = currentGoal?.name === '3 dias ativos' ? 3 : 5;
  const [currentStep, setCurrentStep] = useState(1);
  const [width, setWidth] = useState(1);
  const stapsToRender = stapsArrays[currentGoal?.name] || [];

  useEffect(() => {
    getAchievements();
    getUserData();
  }, []);

  useEffect(() => {
    getCurrentStep();
  }, [currentGoal, userData]);

  const getUserData = async () => {
    try {
      const userDataResponse = await apiNode.get('/game-users');
      const { data } = await apiNode.get(
        `/game-goals/${userDataResponse?.data?.current_goal || 1}`
      );

      onChangeCurrentGoal(data);
    } catch (error) {}
  };

  const getAchievements = async () => {
    try {
      const { data } = await apiNode.get('/game-user-achievements/max-points');
      setAchievements(data);
    } catch (error) {}
  };

  const getBadgeForWidget = rank => {
    const rankToDefaultText = `${rank || '0'}°`;
    if (rank > 3) {
      return <RankText rank={rankToDefaultText} />;
    }
    return getUserRankingBagde(rank - 1);
  };

  const shareImage = async imagePath => {
    console.log(imagePath);
    try {
      const shareOptions = {
        title: 'Compartilhar imagem',
        message: 'Confira essa imagem',
        url: imagePath,
        type: 'image/png',
        failOnCancel: false,
      };

      // const result = await Share.open(shareOptions);
      // if (result.action === Share.sharedAction) {
      //   if (result.activityType) {
      //     console.log(`Compartilhado com ${result.activityType}`);
      //   } else {
      //     console.log('Compartilhado');
      //   }
      // } else if (result.action === Share.dismissedAction) {
      //   console.log('Compartilhamento cancelado');
      // }
    } catch (error) {
      console.log(error);
    }
  };

  const getCurrentStep = () => {
    let stapToSet = 1;
    let widthToSet = '20%';
    for (let index = 0; index < stapsToRender?.length; index++) {
      if (userData?.recurrence >= stapsToRender[index]?.value) {
        widthToSet = stapsToRender[index].width;
        stapToSet = stapsToRender[index].stap;
      }
    }
    setCurrentStep(stapToSet);
    setWidth(widthToSet);
  };

  const getEmoji = name => {
    switch (name) {
      case '3 dias ativos':
        return '🧘';
      case '5 dias ativos':
        return '🏃';
      case '7 dias ativos':
        return '💃';
      case '10 dias ativos':
        return '🤸';
      case '15 dias ativos':
        return '🏋';
      case '30 dias ativos':
        return '🏆';
      default:
        return '';
    }
  };

  const renderSteps = () => {
    const steps = [];

    for (let step = 1; step <= totalSteps; step++) {
      const isCompleted = step <= currentStep;
      const isNext = step > currentStep;
      const isFirstStep = step === 1;
      const index = step - 1;

      const values = stapsToRender[index] || { day: '', points: '' };

      steps.push(
        <View style={{ position: 'relative' }} key={step}>
          <View
            style={[
              styles.step,
              isCompleted && styles.completedStep,
              isNext && styles.currentStep,
            ]}>
            {isCompleted && isFirstStep && <Icon name="flag" color="white" />}
            {isCompleted && !isFirstStep && <Icon name="check" color="white" />}
          </View>
          <Text style={isCompleted ? styles.stapActiveDayText : styles.stapDayText}>
            {values.day}
          </Text>
          <Text style={isCompleted ? styles.stapActivePointsText : styles.stapPointsText}>
            {values.points}
          </Text>
        </View>
      );
    }

    return steps;
  };

  const onPress = () => {
    openBoarding();
  };

  if (!profile?.b2b_user) {
    return null;
  }

  return (
    <View style={styles.widgetWrapper}>
      <ShadowView style={styles.shadowContainer}>
        <View style={styles.container}>
          <View style={styles.pointsContainer}>
            <Image source={Pontos} style={styles.mainImgStyle} />
            <View style={styles.pointsTextContainer}>
              <Text style={textStyles.bold16grey}>{userData?.points || '0'}</Text>
              <Text style={{ ...textStyles.semi14, color: colors.greyLight5 }}>
                {translate(pt.points)}
              </Text>
            </View>
          </View>
          <View style={styles.weekContainer}>
            <View style={{ marginRight: 5, marginLeft: 5 }}>
              {getBadgeForWidget(userData?.rank || '0')}
            </View>
            <View style={styles.weekRankingContainer}>
              <View style={styles.weekWithIconContainer}>
                <Icon
                  name={'calendar'}
                  color={colors.slateGrey}
                  size={10}
                  style={{ marginRight: 5 }}
                />
                <Text style={textStyles.semi10}>{translate(pt.weekly)}</Text>
              </View>
              <Text style={textStyles.bold16grey}>Classificação</Text>
              <Text style={textStyles.semi10grey}>Utilize o app para aumentar sua pontuação!</Text>
            </View>
            <View style={styles.buttonContainer}>
              <ButtonBaseIcon
                useIcon
                backgroundColor={colors.white}
                onPress={onPress}
                icon={<CustomIcon name={'chevron_right'} color={colors.white} />}
                style={styles.buttonBase}
              />
            </View>
          </View>
        </View>
      </ShadowView>

      <ShadowView style={styles.shadowContainer}>
        <View style={styles.secondWidgetContainer}>
          <View style={styles.countAndShareContainer}>
            <View style={styles.secondTextContainer}>
              <Text style={textStyles.bold18White}>
                {/* <Text style={textStyles.bold22White}>3</Text> dias seguidos 🏃 */}
                <Text>{userData?.recurrence}</Text>
                {` Dia${userData?.recurrence > 1 ? 's' : ''} ativo${
                  userData?.recurrence > 1 ? 's' : ''
                }! ${getEmoji(currentGoal?.name)}`}
              </Text>
              <TouchableOpacity style={styles.secondIconStyle} onPress={()=> navigation.navigate('GoalEditing')}>
                <Text style={{ ...textStyles.regular14, color: colors.aquaMarineTwo }}>?</Text>
              </TouchableOpacity>
            </View>

            {/* {achievements?.goal_achievement && achievements?.goal_achievement?.id < 7 && (
              <ButtonBaseIcon
                useIcon
                backgroundColor={colors.white}
                onPress={() => shareImage(base64Images[achievements?.goal_achievement?.name])}
                icon={<Icon name={'share-alt'} color={colors.aquaMarineTwo} size={17} />}
                style={styles.shareButtonBase}
              />
            )} */}
          </View>
          <Text style={textStyles.semi10slateGreyOpacity}>
            Mantenha-se ativo todos os dias para não zerar sua meta!
          </Text>
          <View style={styles.stepContainer}>
            <View style={styles.stapRangeDiv} />
            <View style={[styles.stapRangeblueDiv, { width: width }]} />
            {renderSteps(stapsToRender)}
          </View>
        </View>
      </ShadowView>
    </View>
  );
};

const styles = StyleSheet.create({
  widgetWrapper: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColors: 'transparent',
  },
  shadowContainer: {
    marginTop: 20,
    borderRadius: 12,
    marginHorizontal: 20,
    backgroundColor: colors.white,
    shadowColor: colors.black,
    shadowOpacity: 0.1,
    shadowRadius: 5,
    shadowOffset: { width: 0, height: 5 },
  },
  container: {
    width: '100%',
    height: 105,
    backgroundColor: colors.white,
    // alignSelf: 'center',
    borderRadius: 8,
    zIndex: 1,
  },
  pointsContainerShadow: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 115,
    height: 105,
    shadowColor: colors.black,
    borderRadius: 12,
    shadowOffset: { width: 0, height: -8 },
    marginLeft: 3,
    shadowOpacity: 0.1,
    shadowRadius: 1,
    elevation: 4,
    zIndex: 1,
  },
  pointsContainer: {
    display: 'flex',
    shadowColor: colors.black,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.1,
    shadowRadius: 10,
    flexDirection: 'row',
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
    left: 0,
    width: 115,
    height: 105,
    borderRadius: 8,
    padding: 20,
    zIndex: 2,
    backgroundColor: colors.white,
  },
  pointsTextContainer: {
    marginLeft: 5,
  },
  weekContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    // width: 261,
    flex: 1,
    marginLeft: 105,
    height: 105,
    padding: 20,
    borderRadius: 8,
  },
  mainImgStyle: {
    height: 40,
    width: 40,
    resizeMode: 'contain',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  weekRankingContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: 130,
    height: 105,
    marginRight: 10,
    flex: 1,
    marginLeft: 5,
  },
  weekWithIconContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.veryLightGrat,
    borderRadius: 50,
    width: 65,
  },
  buttonContainer: {
    width: 20,
    height: 105,
    justifyContent: 'center',
  },
  button: {
    width: 20,
    height: 20,
  },
  buttonBase: {
    zIndex: 200,
    height: 40,
    width: 40,
    zIndex: 2,
    borderRadius: 10,
    backgroundColor: colors.aquaMarineTwo,
    shadowColor: colors.black,
    shadowOpacity: 0.2,
    shadowRadius: 5,
    shadowOffset: { width: 0, height: 5 },
  },

  secondShadowContainer: {
    marginTop: 20,
    borderRadius: 12,
    marginHorizontal: 20,
    backgroundColor: colors.white,
    shadowColor: colors.black,
    shadowOpacity: 0.1,
    shadowRadius: 5,
    shadowOffset: { width: 0, height: 5 },
  },

  secondWidgetContainer: {
    display: 'flex',
    padding: 15,
    zIndex: 1,
  },
  countAndShareContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    paddingRight: 5,
    marginRight: -5,
    paddingLeft: 5,
    marginLeft: -5,
    height: 60,
  },
  secondTextContainer: {
    // width: 260,
    flex: 1,
    height: 47,
    borderRadius: 10,
    padding: 10,
    backgroundColor: colors.aquaMarineTwo,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  secondIconStyle: {
    backgroundColor: colors.white,
    borderRadius: 20,
    resizeMode: 'contain',
    width: 20,
    height: 20,
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  shareButtonBase: {
    zIndex: 200,
    height: 47,
    width: 47,
    marginLeft: 10,
    alignSelf: 'center',
    borderRadius: 10,
    backgroundColor: colors.white,
    shadowColor: colors.black,
    shadowOpacity: 0.2,
    shadowRadius: 5,
    shadowOffset: { width: 0, height: 5 },
  },

  stepContainer: {
    position: 'relative',
    overflow: 'visible',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 40,
    marginTop: 20,
  },

  step: {
    overflow: 'visible',
    position: 'relative',
    width: 32,
    height: 32,
    borderRadius: 16,
    backgroundColor: colors.ligthGray,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 5,
  },
  completedStep: {
    backgroundColor: colors.aquaMarineTwo,
    borderColor: colors.aquaMarineTwo2,
    zIndex: 1,
  },
  currentStep: {
    backgroundColor: colors.greyEF,
    zIndex: 1,
  },
  stepLabel: {
    color: '#CCCCCC',
    fontSize: 16,
    zIndex: 1,
  },
  completedStepLabel: {
    color: '#FFFFFF',
  },
  currentStepLabel: {
    color: '#FFFFFF',
    fontWeight: 'bold',
    zIndex: 1,
  },
  stapDayText: {
    position: 'absolute',
    top: -15,
    left: 4,
    fontFamily: fonts.MEDIUM,
    fontSize: 10,
    color: colors.slateGrey,
    zIndex: 1,
  },
  stapPointsText: {
    position: 'absolute',
    top: 35,
    left: -10,
    width: 55,
    fontFamily: fonts.MEDIUM,
    fontSize: 8,
    color: colors.slateGrey,
  },
  stapActiveDayText: {
    position: 'absolute',
    top: -15,
    left: 4,
    fontFamily: fonts.MEDIUM,
    fontSize: 10,
    color: colors.aquaMarineTwo,
  },
  stapActivePointsText: {
    position: 'absolute',
    top: 35,
    left: -10,
    width: 55,
    fontFamily: fonts.MEDIUM,
    fontSize: 8,
    color: colors.aquaMarineTwo,
  },
  stapRangeDiv: {
    position: 'absolute',
    top: 4,
    width: '100%',
    borderRadius: 10,
    height: 24,
    backgroundColor: colors.baseGray,
    zIndex: -1,
  },
  stapRangeblueDiv: {
    position: 'absolute',
    top: 4,
    borderRadius: 10,
    height: 24,
    backgroundColor: colors.aquaMarineTwo2,
    zIndex: 0,
  },
});
