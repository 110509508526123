import api from './api';
import QueryString from 'qs';

const updateMeta = async (meta_key, meta_value) => {
  await api.post('user-metadata', QueryString.stringify({ meta_key, meta_value }));
};

const getMeta = async (meta_key, limit = 1) => {
  const { data } = await api.post(
    'user-metadata/filter',
    QueryString.stringify({ meta_key, limit })
  );
  return data;
};

export { getMeta, updateMeta };
