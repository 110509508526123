import React, { useState } from 'react';

import { View, StyleSheet, Image, TextInput, TouchableOpacity } from 'react-native';
import magnifier from '../images/magnifier.png';
import { translate } from '../i18n/src/locale';
import pt from '../i18n/src/locale/pt';
import colors from '../styles/colors';
import fonts from '../styles/fonts';
// import { Actions } from 'react-native-router-flux';
import closeGrey from '../images/closeGrey.png';
import { useNavigation } from '@react-navigation/native';

const SearchBar = ({ type, border, closed, search = '', main = false }) => {
  const navigation = useNavigation();
  const [text, setText] = useState(search);
  const onSubmit = () => {
    if (text.trim() !== '') {
      navigation.navigate('Search',{ text: text, main: main });
    }
  };

  return (
    <View style={[styles.search, border && styles.border]}>
      <TouchableOpacity onPress={() => search ? navigation.canGoBack() && navigation.goBack() : onSubmit()}>
        <Image source={closed ? closeGrey : magnifier} />
      </TouchableOpacity>
      <TextInput
        returnKeyType="search"
        value={text}
        editable={!search}
        onChangeText={value => setText(value)}
        style={styles.input}
        autoCorrect={false}
        autoCapitalize="none"
        onSubmitEditing={onSubmit}
        placeholder={translate(pt.search_content)}
      />
    </View>
  );
};

export default SearchBar;

const styles = StyleSheet.create({
  input: {
    flex: 1,
    marginLeft: 16,
    height: 45,
    fontSize: 13,
    fontFamily: fonts.REGULAR,
  },
  search: {
    paddingHorizontal: 13,
    flexDirection: 'row',
    alignItems: 'center',
    height: 45,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: colors.veryLightPinkTwo,
  },
  border: {
    backgroundColor: colors.paleGreyThree,
    borderRadius: 4,
    marginHorizontal: 14,
    marginTop: 8,
    borderColor: colors.veryLightPinkThree,
  },
});
