import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Circle, G, Rect, Text } from 'react-native-svg';
import { Dimensions } from 'react-native';
import { LineChart } from 'react-native-chart-kit';
import colors from 'app/styles/colors';
import moment from 'moment';

const screenWidth = Dimensions.get('window').width;

const Tooltip = ({ x, y, textX, textY, stroke, pointStroke, position, measure }) => {
  let tipW = 50,
    tipH = 36,
    tipX = 8,
    tipY = -9,
    tipTxtX = 12,
    tipTxtY = 6;
  const posY = y;
  const posX = x;

  if (posX > screenWidth - tipW) {
    tipX = -(tipX + tipW);
    tipTxtX = tipTxtX - tipW - 6;
  }

  const boxPosX = posX;

  return (
    <G>
      <Circle cx={posX} cy={posY} r={4} stroke={pointStroke} strokeWidth={2} fill={'blue'} />
      <G x={boxPosX} y={posY}>
        <Rect
          x={tipX + 1}
          y={tipY - 1}
          width={tipW - 2}
          height={tipH - 2}
          fill={'rgba(255, 255, 255, 0.9)'}
          rx={2}
          ry={2}
        />
        <Rect
          x={tipX}
          y={tipY}
          width={tipW}
          height={tipH}
          rx={2}
          ry={2}
          fill={'transparent'}
          stroke={stroke}
        />
        <Text stroke={colors.greyBlue} x={tipTxtX} y={tipTxtY} fontSize="10" textAnchor="start">
          {textX}
        </Text>

        <Text
          stroke={colors.greyBlue}
          x={tipTxtX}
          y={tipTxtY + 14}
          fontSize="12"
          textAnchor="start">
          {`${textY.replace(/\./g, ',')}${measure}`}
        </Text>
      </G>
    </G>
  );
};

Tooltip.propTypes = {
  x: PropTypes.func.isRequired,
  y: PropTypes.func.isRequired,
  height: PropTypes.number,
  stroke: PropTypes.string,
  pointStroke: PropTypes.string,
  textX: PropTypes.string,
  textY: PropTypes.string,
  position: PropTypes.string,
};

Tooltip.defaultProps = {
  position: 'rigth',
};

const tooltipDecorators = (state, data, valueFormatter, measure) => () => {
  if (state === null) {
    return null;
  }

  const { index, value, x, y } = state;
  const textX = moment(data?.itens[index]?.data).format('DD/MM');
  const position = data?.labels?.length === index + 1 ? 'left' : 'right';

  return (
    <Tooltip
      textX={String(textX)}
      textY={valueFormatter(value)}
      measure={measure}
      x={x}
      y={y}
      stroke={'#00ccff'}
      pointStroke={'#00ccff'}
      position={position}
    />
  );
};

const LineChartWithTooltips = ({ measure, valueFormatter, ...props }) => {
  const [state, setState] = useState(null);
  return (
    <LineChart
      {...props}
      decorator={tooltipDecorators(state, props.data, valueFormatter, measure)}
      onDataPointClick={value => {
        setState(value);
        setTimeout(() => {
          setState(null);
        }, 1000);
      }}
    />
  );
};

LineChartWithTooltips.propTypes = {
  valueFormatter: PropTypes.func,
};

LineChartWithTooltips.defaultProps = {
  valueFormatter: value => String(value),
};

export default LineChartWithTooltips;
