import React, { useState, useEffect } from 'react';

import {
  View,
  StyleSheet,
  StatusBar,
  Text,
  TouchableOpacity,
  ScrollView,
  Image,
} from 'react-native';

import { SafeAreaView } from 'react-native-safe-area-context';
import textStyles from '../../styles/textStyles';
import colors from '../../styles/colors';
import commons from '../../styles/commons';
import { translate } from '../../i18n/src/locale';
import pt from '../../i18n/src/locale/pt';
import LoginView from './components/LoginView';
import circles from '../../images/circles.png';
import fonts from 'app/styles/fonts';
import arrowRightWater2 from 'app/images/arrowRightWater2.png';
import { useNavigation } from '@react-navigation/native';
import { ActivityIndicator } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { signOut, updateToken } from '../../store/modules/auth/actions';
import logo_splash from 'app/lotties/logo_splash.json';
import LottieView from 'lottie-react-native';
import api from 'app/services/api';
import QueryString from 'qs';
import EVENTS from '../../configs/events';
import NamuLogo from 'app/assets/logo.png';

const NotLoggedScreen = ({ }) => {
  const navigation = useNavigation();

  return (
    <View style={[commons.flex, { backgroundColor: colors.paleGrey, justifyContent: 'center', alignItems: 'center' }]}>
      <Image source={NamuLogo} style={{width: '60px', height: '60px', marginBottom: 16}} />
      <Text style={[textStyles.bold14grey, { textAlign: 'center'}]}>Você não tem permissão para acessar este recurso.</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  button_now: {
    fontFamily: fonts.BOLD,
    fontSize: 16,
    color: colors.aquaMarineTwo,
    zIndex: 2,
    marginLeft: 5,
  },
  view_social: {
    alignSelf: 'flex-end',
    flexDirection: 'row',
  },
  logo: {
    width: 250,
    alignSelf: 'center',
  },
  oval: {
    marginLeft: 16,
    borderRadius: 18,
    width: 36,
    height: 36,
    backgroundColor: colors.facebook,
  },
  scrollContainer: {
    flexGrow: 1,
    // flexDirection: 'column',
    // justifyContent: 'space-between',
  },
  bottomSocial: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    // height: 70,
    paddingHorizontal: 26,
    paddingTop: 21,
    paddingBottom: 11,
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    backgroundColor: colors.white,
    shadowColor: '#676c88',
    shadowOffset: {
      width: 0,
      height: -6,
    },
    shadowRadius: 14,
    shadowOpacity: 0.12,
  },
  view_account: {
    width: '100%',
    marginTop: 30,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    // position: 'absolute',
    bottom: 30,
  },
  center: {
    flex: 1,
    width: '100%',
    paddingTop: 60,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  logo: {
    width: 180,
    alignSelf: 'center',
  },
  animCircle: {
    position: 'absolute',
    width: 500,
    height: 500,
  },
  animCircleLogin: {
    position: 'absolute',
    width: 500,
    height: 500,
    bottom: -100,
    left: -250,
  },
  animCircleRegister: {
    position: 'absolute',
    width: 500,
    height: 500,
    bottom: -100,
    right: -250,
  },
  circleBg: {
    // position: 'absolute',
    zIndex: -1,
    width: 500,
    height: 500,
    // bottom: -100,
    // right: -250
  },
});

export default NotLoggedScreen;
