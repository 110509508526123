export function selectOptionAlarm(id) {
  return {
    type: '@water/SELECT_OPTION_ALARM',
    payload: { id },
  };
}

export function addAllCustomAlarms(alarms) {
  return {
    type: '@water/ADD_ALL_ALARMS',
    payload: { alarms },
  };
}

export function addHourCustomAlarm(alarm) {
  return {
    type: '@water/ADD_CUSTOM_ALARM',
    payload: { alarm },
  };
}

export function removeHourCustomAlarm(alarm) {
  return {
    type: '@water/REMOVE_CUSTOM_ALARM',
    payload: { alarm },
  };
}
