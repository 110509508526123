import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Image, ScrollView } from 'react-native';
import textStyles from 'app/styles/textStyles';
import ButtonBase from 'app/components/ButtonBase';
import produce from 'immer';
import colors from 'app/styles/colors';
import commons from 'app/styles/commons';
import closeGrey from 'app/images/closeGrey.png';
import { useSelector, useDispatch } from 'react-redux';
import Spacer from 'app/components/Spacer';
import { updateEvent } from 'app/services/apiEvents';
import { updateMeta } from 'app/services/apiMetas';
import META from 'app/configs/meta';
import EVENTS from 'app/configs/events';
import fonts from 'app/styles/fonts';
import { updateProfileSuccess } from 'app/store/modules/user/actions';
import { showAlert, errorControl } from 'app/services/utils';
import { addHourCustomAlarm } from 'app/store/modules/water/actions';
import api from 'app/services/api';

const BottomSheetTimer = ({ doClose, type, timer }) => {
  const wheelPickerData = Array.from(Array(24).keys()).map(h => `${h}:00`);
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState();
  const [data, setData] = useState([]);
  const { customAlarms } = useSelector(state => state.water);
  const dispatch = useDispatch();

  useEffect(() => {
    setData(
      customAlarms.map(cutomAlarm => {
        return {
          meta_key: META.USER_WATER_NOTIFICATION,
          meta_value: cutomAlarm,
        };
      })
    );
  }, []);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const hour = `1970-01-01 ${current}`;
      await updateMeta(META.USER_ALARM_CLOCK, hour);
      await updateEvent(EVENTS.SET_ALARM_CLOCK);
      setLoading(false);

      dispatch(updateProfileSuccess({ alarm_clock: hour }));
      doClose();
    } catch (error) {
      showAlert(errorControl(error));
      setLoading(false);
      doClose();
    }
  };

  const handleCustomSubmit = async arrayAlarms => {
    setLoading(true);
    try {
      await api.put('user-metadata', {
        data: arrayAlarms,
      });
      doClose();
    } catch (error) {
      doClose();
    }
    setLoading(false);
  };

  const validTypeTimeAlerts = () => {
    if (type === 'NORMAL') {
      handleSubmit();
    }
    if (type === 'CUSTOM') {
      hasAlarmInArray();
    }
  };

  const hasAlarmInArray = value => {
    const alarmToString = (Number(current) * 3600).toString();
    if (customAlarms.includes(alarmToString)) {
      doClose();
      return;
    }

    dispatch(addHourCustomAlarm(alarmToString));
    const arrayAlarms = [
      ...data,
      {
        meta_key: META.USER_WATER_NOTIFICATION,
        meta_value: alarmToString,
      },
    ];
    handleCustomSubmit(arrayAlarms);
  };

  return (
    <View style={styles.container}>
      <Spacer value={24} />
      <View style={styles.containerHeader}>
        <Text style={textStyles.subtitle17}>Defina o horário do seu primeiro alerta</Text>
        <TouchableOpacity onPress={doClose}>
          <Image source={closeGrey} style={{ width: 20, height: 20 }} />
        </TouchableOpacity>
      </View>
      <Spacer value={24} />
      <ScrollView contentContainerStyle={styles.flexCenter} showsVerticalScrollIndicator={false}>
        {wheelPickerData.map((item, index) => 
          <TouchableOpacity 
            style={styles.pickerItemButton}
            key={`${item}-${index}`}
            onPress={() => setCurrent(item)}
          >
            <Text style={[styles.pickerItem, current === item && styles.selectedItem]}>
              {item}
            </Text>
          </TouchableOpacity>
        )}
      </ScrollView>
      <Spacer value={50} />
      <View style={styles.bottom}>
        <ButtonBase
          loading={loading}
          onPress={() => {
            validTypeTimeAlerts();
          }}
          style={styles.button}
          title="Salvar"
          fill
          square
        />
      </View>
    </View>
  );
};

export default BottomSheetTimer;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.paleGrey,
    flex: 1,
  },
  containerHeader: {
    ...commons.flexRowBetween,
    paddingHorizontal: 20,
  },
  button: {
    backgroundColor: colors.aquaMarineTwo,
  },
  flexCenter: { flex: 1, alignItems: 'center', marginBottom: 20 },
  bottom: {
    flex: 1,
    justifyContent: 'flex-end',
    alignSelf: 'stretch',
    paddingBottom: 0,
  },
  pickerItemButton: {
    width: '100%', 
    alignSelf: 'center',
    padding: 10
  },
  pickerItem: {
    textAlign: 'center', 
    fontFamily: fonts.REGULAR, 
    color: colors.greyDesc, 
    fontSize: 16 
  },
  selectedItem: {
    fontSize: 24,
    fontFamily: fonts.BOLD,
    color: colors.greyDark
  }
});
