import React, { useState } from 'react';

import { View, StyleSheet, Image, TextInput, TouchableOpacity } from 'react-native';
import magnifier from '../../../../images/magnifier.png';
import { translate } from '../../../../i18n/src/locale';
import pt from '../../../../i18n/src/locale/pt';
import colors from '../../../../styles/colors';
import fonts from '../../../../styles/fonts';

const SearchBar = ({ parentCallback }) => {
  const [text, setText] = useState('');

  const onSubmit = () => {
    parentCallback(text);
  };

  return (
    <View style={styles.search}>
      <TouchableOpacity onPress={onSubmit}>
        <Image source={magnifier} />
      </TouchableOpacity>
      <TextInput
        returnKeyType="search"
        value={text}
        onChangeText={value => setText(value)}
        style={styles.input}
        autoCorrect={false}
        autoCapitalize={false}
        onSubmitEditing={onSubmit}
        placeholder={translate(pt.search_content)}
      />
    </View>
  );
};

export default SearchBar;

const styles = StyleSheet.create({
  input: {
    flex: 1,
    marginLeft: 16,
    height: 45,
    fontSize: 13,
    fontFamily: fonts.REGULAR,
  },
  search: {
    paddingHorizontal: 13,
    flexDirection: 'row',
    alignItems: 'center',
    height: 45,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: colors.veryLightPinkThree,
  },
});
