import { AgoraRTCProvider } from "agora-rtc-react";
import AgoraRTC from "agora-rtc-sdk-ng";
import React, { useEffect, useState } from "react";
import apiNode from "../../../../services/apiNode";
import moment from "moment";
import APP from "../../../../configs/app";
import colors from "../../../../styles/colors";
import { ActivityIndicator } from "react-native-paper";
import CallRoom from "./components/CallRoom";
import Modal, { ModalButton } from "./components/Modal";
import { translate } from "../../../../i18n/src/locale";
import pt from "../../../../i18n/src/locale/pt";
import { useDispatch } from "react-redux";
import { doReloadSessions } from "../../../../store/modules/user/actions";
import { unsetCall } from "../../../../store/modules/call/actions";
import { useNavigation } from "@react-navigation/native";

const waitingSeconds = 60 * APP.SESSION_WAITING_MINUTES;

const CallScreenNew = ({ route }) => {
  const { sessionId } = route?.params;
  
  const navigation = useNavigation();

  const dispatch = useDispatch();
  const [session, setSession] = useState(null);
  const [channelId, setChannelId] = useState(null);
  const [validSession, setValidSession] = useState(false);
  const [sessionStarted, setSessionStarted] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  const agoraEngine = AgoraRTC.createClient({
    mode: 'rtc',
    codec: "vp9"
  });

  useEffect(() => {
    loadSession();
  }, []);

  useEffect(() => {
    if (session !== null)
      validateSession();
  }, [session]);

  const leaveCall = async () => {
    await agoraEngine.leave();
    dispatch(doReloadSessions());
    dispatch(unsetCall());
  }

  const peerLeft = async () => {
    const { status } = await loadSession();
    if (status == 'canceled' || status == 'done') {
      await leaveCall();
    }
  }

  const endSession = async (done) => {
    await apiNode.put(`sessions/${sessionId}`, {
      remainingMinutes: 0 / 60,
      status: done ? 'done' : 'canceled'
    });
    await leaveCall();
    if(done){
      navigation.navigate('CallServiceFeedback', { sessionId: session.id });
    }else{
      navigation.goBack();
    }
  }

  const loadSession = async () => {
    try {
      const { data } = await apiNode.get(`sessions/${sessionId}`);
      setChannelId(data.agoraIoChannel);
      setSession(data);
      if(data?.status == 'done'){
        navigation.navigate('CallServiceFeedback', { sessionId: session.id });
      }
      return data;
    } catch(e) {
      console.log('FAILED TO LOAD SESSION', e.response);
    }
  }

  const isSessionValid = () => {
    // canceled or finished
    if(['canceled', 'done'].includes(session.status))
      return false;

    // past the session duration
    if (moment() > moment(session.sessionDate).add(session.sessionDurationMinutes, 'minutes'))
      return false;

    // paused or started
    if (['paused', 'started'].includes(session.status)) {
      setSessionStarted(true);
      return true;
    }

    // waiting time is up
    if (moment().diff(moment(session.sessionDate), 'seconds') >= waitingSeconds && session.status === 'open')
      return false;

    return true;
  }

  const validateSession = () => {
    const valid = isSessionValid();
    if (valid) {
      setValidSession(true);
    } else {
      setModalOpen(true);
    }
  }

  return validSession ? (
    <AgoraRTCProvider client={agoraEngine}>
      <CallRoom session={session} channelId={channelId} sessionStarted={sessionStarted} endSession={endSession} peerLeft={peerLeft}/>
    </AgoraRTCProvider>
  ):(
    <div style={styles.container}>
      <ActivityIndicator animating size={'small'} color={colors.aquaMarine} />
      <Modal
        title={"Namu"}
        isOpen={isModalOpen}
      >
        {translate(pt.session_unavailable)}
        <ModalButton
          text={"Ok"}
          onClick={() => {
            setModalOpen(false);
          }}
        />
      </Modal>
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    background: `linear-gradient(70deg, ${colors.aquaMarineTwo}, ${colors.shadowBlue})`,
    height: '100%',
    width: '100%'
  },
  modal: {
    backgroundColor: "#E1E0E0",
    border: "none"
  }
}

export default CallScreenNew;

