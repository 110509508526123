/* eslint-disable react-native/no-inline-styles */
import HeaderHomeNew from 'app/components/HeaderHomeNew';
import ImageButton from 'app/components/ImageButton';
import StatusBarHolder from 'app/components/StatusBarHolder';
import TopFix from 'app/components/TopFix';
import META from 'app/configs/meta';
import PERFORMANCE from 'app/configs/performance';
import { translate } from 'app/i18n/src/locale';
import pt from 'app/i18n/src/locale/pt';
import api from 'app/services/api';
import { getMeta } from 'app/services/apiMetas';
import {
  buildURL,
  errorControl,
  errorMessage,
  isObjectEmpty,
  showAlert,
  successMessage,
} from 'app/services/utils';
import { setNameShare } from 'app/store/modules/category/actions';
import { updateProfileSuccess, updateReviewStatus } from 'app/store/modules/user/actions';
import { addAllCustomAlarms, selectOptionAlarm } from 'app/store/modules/water/actions';
// import NextAttendanceCard from 'app/screens/core/professional/components/NextAttendanceCard';
import colors from 'app/styles/colors';
import fonts from 'app/styles/fonts';
import { debounce } from 'lodash';
import apiNode from 'app/services/apiNode';
import moment from 'moment';
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import {
  Dimensions,
  FlatList,
  Platform,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  Text,
  View,
  Image,
  AppState,
  Modal,
} from 'react-native';
// import { Image, View } from 'react-native-animatable';
// import  from 'react-native-modal';
// import RBSheet from 'react-native-raw-bottom-sheet';
// import { Actions } from 'react-native-router-flux';
import { useDispatch, useSelector } from 'react-redux';
import apple1 from '../../../images/apple1.png';
import buddha1 from '../../../images/buddha1.png';
import document3 from '../../../images/document3.png';
import grow from '../../../images/grow.png';
import measure2 from '../../../images/measure2.png';
import mirrorImage2 from '../../../images/mirror-image2.png';
import power from '../../../images/power.png';
import praying1 from '../../../images/praying1.png';
import sleep2 from '../../../images/sleep2.png';
import time1quarter from '../../../images/time1quarter.png';
import time2quarters from '../../../images/time2quarters.png';
import time3quarters from '../../../images/time3quarters.png';
import yingYang1 from '../../../images/ying-yang1.png';
import yoga1 from '../../../images/yoga1.png';
import bar1 from '../../../images/bar1.png';
import bar2 from '../../../images/bar2.png';
import bar3 from '../../../images/bar3.png';
import CardFullImageNew from './components/CardFullImageNew';
import CardWaterNew from './components/CardWaterNew';
import ModalInfo from './components/ModalInfo';
import CardProfessional from 'app/components/CardProfessional';
// import CardCalories from 'app/screens/core/home/components/CardCalories';
import CardClassesNew from 'app/screens/profile/components/CardClassesNew';
import CardClassesHorizontal from 'app/screens/profile/components/CardClassesHorizontal';
import HeaderTitleNew from 'app/screens/core/home/components/HeaderTitleNew';
import { setWidgets } from 'app/store/modules/user/actions';
import CardLastSeen from 'app/components/CardLastSeen';
import CardClassesLast from 'app/screens/core/explorer/components/CardClassesLast';
// import { useIsFocused } from 'react-navigation-hooks';
import CardProgram from 'app/components/CardProgram';
import CardWidgetNoData from 'app/components/CardWidgetNoData';
import CardMoodWidget from 'app/components/CardMoodWidget';
// import CardChat from 'app/components/CardChat';
import CardSipatWidget from 'app/components/CardSipatWidget';
// import LastAttendanceCard from 'app/screens/core/professional/components/LastAttendanceCard';
import { setShowFavorites } from 'app/store/modules/favorites/actions';
import commons from 'app/styles/commons';
import CustomIcon from 'app/styles/icon';
// import Icon from 'react-native-vector-icons/FontAwesome5';
import useGlobalAlert from 'app/hooks/useGlobalAlert';
import InAppReview from 'react-native-in-app-review';
import HandleActivity from 'app/screens/core/activities/components/HandleActivity';
import * as RNIap from 'react-native-iap';
import CardBusinessAds from 'app/components/CardBusinessAds';
import CardManuChat from 'app/components/CardManuChat';
import { GamificationWidget } from 'app/screens/core/gamification/GamificationWidget';
import { HealthscanWidget } from 'app/screens/core/healthscan/HealthscanWidget';
import { GamificationLeaderboard } from 'app/screens/core/gamification/GamificationLeaderboard';
import { useGamification } from 'app/hooks/useGamification';
// import CardGymWidget from 'app/components/CardGymWidget';
import { setUserData } from 'app/store/modules/gamification/actions';
import { useNavigation, useIsFocused } from '@react-navigation/native';
import CardGymWidget from '../../../components/CardGymWidget';
import NextAttendanceCard from '../professional/components/NextAttendanceCard';
import LastAttendanceCard from '../professional/components/LastAttendanceCard';
import CardProfessionalSmall from '../../../components/CardProfessionalSmall';
import BarSchedule from '../../../components/BarSchedule';

const dimensions = {
  width: Dimensions.get('window').width,
  height: Dimensions.get('window').height,
};

const { width } = dimensions;

const widgetIds = {
  SCHEDULE: 1,
  LAST_ACCESSED: 2,
  POPULAR: 3,
  CATEGORY: 4,
  BODY_PARTS: 5,
  FAVORITES: 6,
  TRAININGS_BY_TIME: 7,
  TRAININGS_BY_GOAL: 8,
  LAST_ATTENDANCES: 9,
  HIRE_A_PROFESSIONAL: 10,
  READING_TIPS: 11,
  //CALORIC_CONTROL: 12,
  HIDRATATION: 13,
  FITNESS_SUGGESTIONS: 14,
  FOOD_SUGGESTIONS: 15,
  TRAININGS_BY_INTENSITY: 16,
  VIRTUAL_COACH: 17,
  SHOPPING_LIST: 18,
  NEXT_ATTENDANCE: 19,
  RECOMMENDEDS: 20,
  MIND_SUGGESTIONS: 21,
  PROGRAMS: 22,
  CHAT: 23,
  MOOD: 24,
  SIPAT: 25,
  AD: 28,
  MANUCHAT: 29,
  GYM: 30,
  GAMIFICACAO: 31,
  HEALTHSCAN: 32,
};

const cardTypes = {
  VERTICAL: 1,
  HORIZONTAL: 2,
  LAST_ACCESSED: 3,
  PROGRAMS: 4,
  RECIPE_SUGGESTION: 5,
};

const HomeScreenNew = ({ index = new Date().getDay(), jumpTo }) => {

  const navigation = useNavigation();


  const dispatch = useDispatch();
  const refRBSheet = useRef();
  const { profile, review, widgets } = useSelector(state => state.user);
  const { showFavorites } = useSelector(state => state.favorites);
  const { signed } = useSelector(state => state.auth);

  const { journeyHasBegunAction, eventsGenerationByHandleActivity } = useGamification();
  const { isShowBoarding, startedJourney } = useSelector(state => state.gamification);

  const { setAlert } = useGlobalAlert();

  const [showContent] = useState(true);

  const [modal, setModal] = useState(false);

  const [sessionDone, setSessionDone] = useState(null);
  const [nextSession, setNextSession] = useState(null);
  const [favoriteClasses, setFavoriteClasses] = useState([]);
  const [recommended, setRecommended] = useState([]);
  const [popular, setPopular] = useState([]);
  const [foodSuggestions, setFoodSuggestions] = useState([]);
  const [fitnessSuggestions, setFitnessSuggestions] = useState([]);
  const [mindSuggestions, setMindSuggestions] = useState([]);
  const [userWidgets, setUserWidgets] = useState([]);
  const [userSchedule, setUserSchedule] = useState([]);
  const [readingSuggestion, setReadingSuggestion] = useState([]);
  const [lastAccessed, setLastAccessed] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [, setIsLoading] = useState([]);
  const isFocused = useIsFocused();

  useEffect(() => {
    if (isFocused) {
      Promise.all([
        requestReadingSuggestion(),
        requestLastAccessed(),
        requestNextSession(),
        requestFavoriteClasses(),
        requestPopularClasses(),
        requestFoodSuggestions(),
        requestFitnessSuggestions(),
        requestMindSuggestions(),
        requestSchedule(),
        requestGamification(),
      ]);
    }
  }, [
    isFocused,
    requestReadingSuggestion,
    requestFoodSuggestions,
    requestFitnessSuggestions,
    requestMindSuggestions,
    requestPopularClasses,
    requestFavoriteClasses,
    requestLastAccessed,
    requestNextSession,
    requestSchedule,
  ]);

  useEffect(() => {
    fetchAllWidgets();
  }, [fetchAllWidgets]);

  useEffect(() => {
    if (showFavorites) {
      handleActivityFunction('favorites');
    }
  }, [showFavorites]);

  useEffect(() => {
    signed && handleAppState('active');
  }, [signed]);

  const handleAppState = useCallback(async nextAppState => {
    try{
      if (nextAppState === 'active') {
        const { data } = await HandleActivity({ routeName: 'app_entering' });
        eventsGenerationByHandleActivity(data);
      }
    }catch(e){
      //
    }
  }, []);

  const requestGamification = async () => {
    try {
      const response = await apiNode.get('/game-users');
      dispatch(setUserData(response.data));

      const { data } = await apiNode.get('/game-users/first');
      if (data === true && !startedJourney) {
        journeyHasBegunAction();
      }
    } catch (error) {
      console.log('entry error', error);
    }
  };

  const handleActivityFunction = async routeName => {
    try {
      const { data } = await HandleActivity({ routeName });
      eventsGenerationByHandleActivity(data);
    } catch (error) {}
  };

  const verifyInvitation = async () => {
    try {
      const { data } = await api.get('/user/invite');

      if (data?.status === 404) {
        return;
      }

      navigation.navigate('BusinessVerification', {
        data: {
          cpf: '',
          email: profile.email,
        },
        logged: true
      });
    } catch (e) {
      console.log('INVITATION:', e);
    }
  };

  useEffect(() => {
    const subs = AppState.addEventListener('change', handleAppState);

    if (Platform.OS === 'ios') {
      checkiOSHistory();
    }

    requestGamification();
    verifyInvitation();
    setTimeout(() => {
      checkReview();
    }, 3000);

    return () => {
      subs.remove();
    };
  }, []);

  const checkiOSHistory = async () => {
    console.log('CHECKING');
    await RNIap.initConnection();
    try {
      const history = await RNIap.getPurchaseHistory();
      console.log('HISTORY');
      const lastPurchase = history.sort((a, b) => a.transactionDate - b.transactionDate)[
        history.length - 1
      ];
      if (!lastPurchase) {
        console.log('Nenhuma transaçao');
        return;
      }

      const { data } = await api.post('payment/ios/sync', {
        receipt: lastPurchase.transactionReceipt,
      });

      getMetasByUser();

      if (data.isValid) {
        onClose();

        // navigation.navigate('PaymentCompleteiOS',({
        //   courseName: 'Plano Mensal',
        //   courseValue: plan.amount,
        // });
      }
    } catch (error) {
      console.log('SYNC ERR', JSON.stringify(error.response.data, null, 2));
    }
    await RNIap.endConnection();
  };

  const checkReview = () => {
    if (review?.shown) {
      return;
    }

    if (!review?.entering_days || review?.entering_days?.length < 2) {
      dispatch(
        updateReviewStatus({
          entering_days: Array.from(
            new Set([...(review?.entering_days || []), moment().format('DD-MM-YYYY')])
          ),
          shown: false,
        })
      );
    }
    if (review?.entering_days?.length >= 2) {
      setAlert(
        translate(pt.your_opinion_matters),
        translate(pt.review_message, { store: Platform.OS === 'ios' ? 'App' : 'Play' }),
        {
          icon: (
            <Image
              source={require('app/images/enterprise2.png')}
              style={{ width: 240, height: 240, resizeMode: 'contain' }}
            />
          ),
          text: 'Avaliar',
          onPress: handleReviewApp,
          secondaryText: translate(pt.not_yet),
          additionalComponent: (
            <View style={[commons.flexRow, { marginTop: 10 }]}>
              {[...Array(Math.ceil(5))].map(() => (
                <CustomIcon
                  name='star'
                  style={{marginHorizontal: 2}}
                  size={25}
                  color={colors.yellowOrange}
                />
                // <Icon
                //   key={colors.yellowOrange}
                //   style={{ marginHorizontal: 2 }}
                //   name={'star'}
                //   size={25}
                //   color={colors.yellowOrange}
                // />
              ))}
            </View>
          ),
        }
      );
      dispatch(
        updateReviewStatus({
          entering_days: [],
          shown: true,
        })
      );
    }
  };

  const handleReviewApp = () => {
    if (!InAppReview.isAvailable()) {
      errorMessage('Serviço indisponível no momento');
    }

    InAppReview.RequestInAppReview()
      .then(finishedSuccessfully => {
        if (finishedSuccessfully) {
          successMessage('Obrigado pela sua avaliação!');
        }
      })
      .catch(error => {
        errorMessage('Serviço indisponível no momento');
      });
  };

  const fetchAllWidgets = useCallback(async () => {
    setIsLoading(true);
    Promise.all([
      requestWidgets(),
      requestSessionsDone(),
      requestNextSession(),
      requestFavoriteClasses(),
      requestPopularClasses(),
      requestRecommended(),
      requestFoodSuggestions(),
      requestFitnessSuggestions(),
      requestMindSuggestions(),
      requestSchedule(),
      requestLastAccessed(),
      requestPrograms(),
    ]).finally(() => setIsLoading(false));
  }, [
    requestFavoriteClasses,
    requestFitnessSuggestions,
    requestFoodSuggestions,
    requestLastAccessed,
    requestPrograms,
    requestMindSuggestions,
    requestNextSession,
    requestPopularClasses,
    requestRecommended,
    requestSchedule,
    requestSessionsDone,
    requestWidgets,
  ]);

  useEffect(() => {
    if (widgets) {
      setUserWidgets(widgets);
    }
  }, [widgets, dispatch]);

  const openTrailNew = useCallback((categoryId, id, type) => {
    if (type === 'activities') {
      if (categoryId === 1) {
        navigation.navigate('MealTrail',{ mealTrailId: id });
      } else if (categoryId === 2) {
        navigation.navigate('Trail',{ trailId: id });
      } else if (categoryId === 3) {
        navigation.navigate('MeditationTrail',{ meditationId: id });
      }

      // sendActionEvent('click_at_trail', {
      //   id: id,
      //   email: profile.email,
      // });
    } else {
      if (categoryId === 1) {
        if (type == 'recent_class') {
          navigation.navigate('PlateDetail',{ plateId: id });
        } else {
          navigation.navigate('MealDetail',{ mealId: id });
        }
        // sendActionEvent('click_at_trail', {
        //   id: id,
        //   email: profile.email,
        // });
      } else if (categoryId === 2) {
        navigation.navigate('LessonActivity',{ lessonId: id });
        // sendActionEvent('click_at_exercise', {
        //   id: id,
        //   email: profile.email,
        // });
      } else if (categoryId === 3) {
        navigation.navigate('LessonMeditation',{ classId: id });
        // sendActionEvent('click_at_trail', {
        //   id: id,
        //   email: profile.email,
        // });
      }
    }
  }, []);

  const requestWidgets = useCallback(async () => {
    try {
      const { data } = await apiNode.get('/widget/favorites');
      setUserWidgets(data);
      dispatch(setWidgets(data));
    } catch (error) {
      showAlert(errorControl(error, 'requestWidgets'));
    }
  }, [dispatch]);

  const requestSessionsDone = useCallback(async () => {
    try {
      const { data } = await apiNode.get('/users/sessions/done');
      setSessionDone(data[0]);
    } catch (error) {
      showAlert(errorControl(error));
    }
  }, []);

  const requestNextSession = useCallback(async () => {
    try {
      const { data } = await apiNode.get('/users/sessions/next/one');
      setNextSession(data);
    } catch (error) {
      showAlert(errorControl(error));
    }
  }, []);

  const requestFavoriteClasses = useCallback(async () => {
    try {
      const { data } = await apiNode.get(
        `/user-favorites-classes/get-by-user-id/${profile.user_id}/?limit=20`
      );
      // add favorite because objects doesn't have favorite
      const newData = data.map(item => {
        const newItem = { ...item };
        newItem.class.favorite = true;
        return newItem;
      });
      setFavoriteClasses(newData);
    } catch (error) {
      showAlert(errorControl(error));
    }
  }, []);

  const requestPopularClasses = useCallback(async () => {
    try {
      const { data } = await apiNode.get('/user-activity-log/popular_classes');
      setPopular(data);
    } catch (error) {
      showAlert(errorControl(error));
    }
  }, []);

  const requestRecommended = useCallback(async () => {
    try {
      const { data } = await apiNode.get('/classes/recommended?limit=5');
      setRecommended(data);
    } catch (error) {
      showAlert(errorControl(error));
    }
  }, []);

  const requestFoodSuggestions = useCallback(async () => {
    try {
      const { data } = await api.get('/meals/recipe-suggestions');
      setFoodSuggestions(data);
    } catch (error) {
      showAlert(errorControl(error));
    }
  }, []);

  const requestFitnessSuggestions = useCallback(async () => {
    try {
      const { data } = await apiNode.get('/classes/suggestions?category=Corpo&limit=5');
      setFitnessSuggestions(prev => data);
    } catch (error) {
      showAlert(errorControl(error));
    }
  }, []);

  const requestMindSuggestions = useCallback(async () => {
    try {
      const { data } = await apiNode.get('/classes/suggestions?category=Mente&limit=5');
      setMindSuggestions(data);
    } catch (error) {
      showAlert(errorControl(error, 'requestMindSuggestions'));
    }
  }, []);

  const requestPrograms = useCallback(async () => {
    try {
      const { data } = await api.get('trails/filter');
      setPrograms(data.data || []);
    } catch (error) {
      showAlert(errorControl(error));
    }
  }, []);

  const requestSchedule = useCallback(async () => {
    try {
      const today = moment().format('YYYY-MM-DD');
      const tomorrow = moment().add(1, 'days').format('YYYY-MM-DD');
      const { data } = await api.get(
        `user-schedule?timestamp_start=${today}&timestamp_end=${tomorrow}&payment_level=0`
      );
      setUserSchedule(data);
    } catch (error) {
      showAlert(errorControl(error));
    }
  }, []);

  const requestLastAccessed = useCallback(async () => {
    try {
      const { data } = await apiNode.get('/activity-log/accessed_recently');
      setLastAccessed(data);
    } catch (error) {
      showAlert(errorControl(error));
    }
  }, []);

  const requestReadingSuggestion = useCallback(async () => {
    try {
      const { data } = await api.get('reading-suggestion');
      setReadingSuggestion(data);
    } catch (error) {
      showAlert(errorControl(error));
    }
  }, []);

  const formatDataForRender = data => {
    return data.map(item => {
      if (item.class) {
        return item.class;
      }
      return item;
    });
  };

  const renderClassesNew = (data, type = cardTypes.VERTICAL, lastItemProps) => {
    const formattedData = formatDataForRender(data);
    return (
      <FlatList
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={styles.flatSpace}
        style={{ marginBottom: -20 }}
        data={formattedData.slice(0, 4)}
        horizontal
        keyExtractor={item => item.id}
        ListFooterComponent={() => {
          return lastItemProps ? (
            <CardClassesLast
              color={lastItemProps.color}
              title={lastItemProps.title}
              style={{ marginHorizontal: 16 }}
              onPress={() => {
                handleShowMore({
                  url: lastItemProps.urlToFetch,
                  color: lastItemProps.color,
                  title: lastItemProps.title || 'Todas as aulas',
                  type,
                  node: lastItemProps.node,
                });
              }}
            />
          ) : null;
        }}
        renderItem={({ item }) => {
          return type === cardTypes.LAST_ACCESSED ? (
            <CardLastSeen
              title={item.name}
              id={item.id}
              image={item.image.path}
              style={styles.leftCard}
              paymentLevel={item.payment_level}
              onPress={debounce(() => {
                item.payment_level === 1
                  ? null//Actions.paywall()
                  : openTrailNew(item.category_id, item.id, 'recent_class');
                dispatch(setNameShare(item.name));
              }, PERFORMANCE.ON_PRESS_DEBOUNCE_TIME)}
              gradientColor={getGradientColor(item.category_id)}
            />
          ) : type === cardTypes.HORIZONTAL ? (
            <CardClassesHorizontal
              type={item.subcategory?.name}
              title={item.name}
              image={item.image.path}
              difficulty={item.difficulty}
              duration={item.duration}
              kcal={parseInt(item.calories, 10)}
              plus={null}
              minus={null}
              paymentLevel={item.payment_level}
              onPress={debounce(() => {
                item.payment_level === 1
                  ? null //Actions.paywall()
                  : openTrailNew(item.subcategory?.category_id, item.id, item.subcategory?.name);
                dispatch(setNameShare(item.name));
              }, PERFORMANCE.ON_PRESS_DEBOUNCE_TIME)}
              style={styles.leftCard}
              goals={item?.goals ? item?.goals : []}
              gradientColor={getGradientColor(item.subcategory?.category_id)}
              favorite={item.favorite}
              id={item.id}
            />
          ) : type === cardTypes.VERTICAL ? (
            <CardClassesNew
              type={item.subcategory?.name}
              title={item.name}
              image={item.image.path}
              difficulty={item.difficulty}
              duration={item.duration}
              kcal={parseInt(item.calories, 10)}
              plus={null}
              minus={null}
              paymentLevel={item.payment_level}
              onPress={debounce(() => {
                item.payment_level === 1
                  ? null //Actions.paywall()
                  : openTrailNew(item.subcategory?.category_id, item.id, item.subcategory?.name);
                dispatch(setNameShare(item.name));
              }, PERFORMANCE.ON_PRESS_DEBOUNCE_TIME)}
              containerStyle={styles.leftCard}
              gradientColor={getGradientColor(item.subcategory?.category_id)}
              goals={item?.goals ? item?.goals : []}
              favorite={item.favorite}
              id={item.id}
            />
          ) : type === cardTypes.RECIPE_SUGGESTION ? (
            <CardClassesNew
              type={item.subcategory_name}
              title={item.name}
              image={item.image_path}
              difficulty={item.difficulty}
              duration={item.duration}
              kcal={parseInt(item.calories, 10)}
              plus={null}
              minus={null}
              paymentLevel={item.payment_level}
              onPress={debounce(() => {
                item.payment_level === 1
                  ? null //Actions.paywall()
                  :  navigation.navigate('PlateDetail',{ plateId: item.id });
                dispatch(setNameShare(item.name));
              }, PERFORMANCE.ON_PRESS_DEBOUNCE_TIME)}
              containerStyle={styles.leftCard}
              gradientColor={getGradientColor(item.category_id)}
              goals={[]}
              id={item.id}
            />
          ) : null;
        }}
      />
    );
  };

  const renderCardPrograms = data => {
    return (
      <FlatList
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={styles.flatSpace}
        style={{ marginBottom: -20 }}
        data={data.slice(0, 4)}
        horizontal
        keyExtractor={item => item.id}
        renderItem={({ item }) => {
          return (
            <CardProgram
              type={item.subcategory_name}
              title={item.name}
              image={item.image_path}
              difficulty={item.difficulty}
              duration={item.duration_in_weeks} // in weeks
              kcal={parseInt(item.calories, 10)}
              plus={null}
              minus={null}
              paymentLevel={item.payment_level || item.trail_payment_level}
              onPress={debounce(() => {
                item.payment_level === 1 || item.trail_payment_level === 1
                  ? null// Actions.paywall()
                  : openTrailNew(item.category_id, item.id, 'activities');
                dispatch(setNameShare(item.name));
              }, PERFORMANCE.ON_PRESS_DEBOUNCE_TIME)}
              style={styles.leftCard}
              gradientColor={getGradientColor(item.category_id)}
              favorite={item.favorite}
              id={item.id}
            />
          );
        }}
      />
    );
  };

  const getGradientColor = categoryId => {
    if (categoryId === 1) {
      return 'red';
    }
    if (categoryId === 2) {
      return 'green';
    }
    return 'orange';
  };

  let categoryColor = '';

  // useEffect(() => {

  //   getUser();
  //   getUiEvent();
  // }, [getUiEvent, getUser]);

  const changeHour = () => {
    const defaultHour = '00:00';

    // setShowContent(false);
    // setShowExtra(true);
    // setModal(true);

    return  navigation.navigate('HomeChangeHour',{ hour: defaultHour });
  };

  const getMetasByUser = async () => {
    const { data: user } = await api.get('user');

    if (user) {
      const weight_result = await getMeta(META.USER_WEIGHT);

      if (weight_result?.length > 0) {
        user.weight = weight_result[0].meta_value;
      }

      const height_result = await getMeta(META.USER_HEIGHT);
      if (height_result?.length > 0) {
        user.height = height_result[0].meta_value;
      }
      dispatch(updateProfileSuccess(user));
    }
  };

  useEffect(() => {
    getMetasByUser();
  }, []);

  const updateAlertRecurrences = metaValue => {
    switch (metaValue) {
      case '1800':
        dispatch(selectOptionAlarm(1));
        break;
      case '3600':
        dispatch(selectOptionAlarm(2));
        break;
      case '7200':
        dispatch(selectOptionAlarm(3));
        break;
      default:
        dispatch(addAllCustomAlarms([metaValue]));
        dispatch(selectOptionAlarm(4));
        break;
    }
  };

  const validListCustomAlarms = userWaterNotification => {
    const newArrayAlarms = userWaterNotification.map(metaValue => {
      return metaValue.meta_value;
    });
    dispatch(addAllCustomAlarms(newArrayAlarms));
  };

  // const getUiEvent = useCallback(async () => {
  //   const seeWelcomeScreen = await getEvent(EVENTS.WELCOME);
  //   setShowAlarm(!(await getEvent(EVENTS.SET_ALARM_CLOCK)));
  //   if (seeWelcomeScreen) {
  //     setShowContent(false);
  //   } else {
  //     setShowInitial(false);
  //   }
  // }, []);

  const callTrailByCategoryId = (categoryId, itemId) => {
    if (categoryId === 1) {
      navigation.navigate('MealTrail',{ mealTrailId: itemId });
      return;
    }
    if (categoryId === 2) {
      navigation.navigate('Trail',{ trailId: itemId });
      return;
    }
    // sendActionEvent(
    //   'click_at_trail',
    //   {
    //     id: itemId,
    //     email: profile.email,
    //   },
    //   profile.email
    // );

    navigation.navigate('MeditationTrail',{ meditationId: itemId });
  };

  const checkActivityToday = () => {
    if (
      home?.day_activities?.length === 0 &&
      home?.day_meals?.length === 0 &&
      home?.day_recipes?.length === 0
    ) {
      return (
        <View>
          <Text style={styles.info}>{translate(pt.no_activity_recorded_for_today)}</Text>
          <ButtonBase
            onPress={debounce(() => {
              // sendActionEvent('click_i_want_to_do_an_activity', {}, profile.email);
              navigation.navigate('Aulas');//Actions.explorer();
            }, PERFORMANCE.ON_PRESS_DEBOUNCE_TIME)}
            backgroundColor={colors.aquaMarineTwo}
            title={translate(pt.i_want_to_do_an_activity)}
          />
        </View>
      );
    }
  };

  const renderSchedule = widget => (
    <>
      <HeaderTitleNew title={widget.widget_name} uppercaseLink onPress={() => navigation.navigate('Schedule')}  />
      {userSchedule.length > 0 ? (
        <BarSchedule
          schedule={userSchedule}
          onOpenTrail={openTrailNew}
          getGradientColor={getGradientColor}
        />
      ) : (
        <>
          <CardWidgetNoData
            title={translate(pt.schedule_no_data_title)}
            description={translate(pt.schedule_no_data_message)}
            color={colors.aquaMarineTwo}
            onPress={debounce(() =>  navigation.navigate('Schedule'), PERFORMANCE.ON_PRESS_DEBOUNCE_TIME)}
            imageStyle={{ marginRight: 25, marginLeft: 5, height: '100%' }}
            image={require('app/images/clockBg.png')}
          />
        </>
      )}
    </>
  );

  const renderNextAttendance = widget => (
    <>
      {!isObjectEmpty(nextSession) && (
        <>
          <HeaderTitleNew title={widget.widget_name} />
          <NextAttendanceCard item={nextSession} reload={requestNextSession} />
        </>
      )}
    </>
  );

  const renderLastAccessed = widget =>
    lastAccessed.length > 0 && (
      <View>
        <HeaderTitleNew title={widget.widget_name} />
        {renderClassesNew(lastAccessed, cardTypes.LAST_ACCESSED)}
      </View>
    );

  const handleShowMore = ({
    url,
    color,
    title,
    type = cardTypes.VERTICAL,
    node = true,
    paginate = false,
    canSearch = true,
    filters = [],
  }) => {
    if (type === cardTypes.PROGRAMS) {
      navigation.navigate('ShowMorePrograms', { color, urlToFetch: url, title, node, type });
      return;
    }

    navigation.navigate('ShowMoreClasses', {
      color,
      url,
      title,
      node,
      type,
      paginate,
      canSearch,
      filters,
    });
  };

  const renderFavorites = widget => (
    <View>
      {favoriteClasses.length > 0 && (
        <HeaderTitleNew
          title={widget.widget_name}
          uppercaseLink
          onPress={() => dispatch(setShowFavorites(true))}
        />
      )}
      {favoriteClasses.length > 0 && <>{renderClassesNew(favoriteClasses, cardTypes.VERTICAL)}</>}
    </View>
  );

  const renderRecommended = widget => (
    <View>
      {recommended.length > 0 && (
        <HeaderTitleNew
          title={widget.widget_name}
          uppercaseLink
          onPress={() => {
            handleShowMore({
              url: '/classes/recommended?limit=20',
              canSearch: false,
              title: widget.widget_name,
            });
          }}
        />
      )}
      {recommended.length > 0 && <>{renderClassesNew(recommended, cardTypes.VERTICAL)}</>}
    </View>
  );

  const handleImageButtonPress = (filters, paginate = false, showFilters = []) => {
    const path = buildURL({
      base: '/classes/',
      query: {
        limit: 50,
        userId: profile.user_id,
        category: 'Corpo|Mente',
        ...filters,
      },
    });

    const filtersToShow = Object.entries(filters)
      .filter(d => d[0] !== 'limit')
      .map(([key, value]) => ({ name: value }));

    handleShowMore({ url: path, paginate, filters: filtersToShow });
  };

  const renderTrainingsByTime = widget => (
    <View>
      <HeaderTitleNew title={widget.widget_name} />
      <ScrollView
        horizontal
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={{ paddingBottom: 20 }}
        style={[styles.imageGroupper, { marginBottom: -20 }]}>
        <ImageButton
          image={time1quarter}
          style={{ flex: 1, aspectRatio: 1 }}
          imageStyle={styles.image50}
          onPress={() => {
            handleImageButtonPress({ time: 15, limit: 10 }, true);
          }}
        />
        <ImageButton
          image={time2quarters}
          style={{ flex: 1, aspectRatio: 1 }}
          imageStyle={{ height: 50, width: 50, resizeMode: 'contain' }}
          onPress={() => {
            handleImageButtonPress({ time: 30, limit: 10 }, true);
          }}
        />
        <ImageButton
          image={time3quarters}
          style={{ flex: 1, aspectRatio: 1 }}
          imageStyle={{ height: 50, width: 50, resizeMode: 'contain' }}
          onPress={() => {
            handleImageButtonPress({ time: 45, limit: 10 }, true);
          }}
        />
      </ScrollView>
    </View>
  );

  const renderPopular = widget => {
    const formattedData = formatDataForRender(popular);

    return (
      <View>
        {formattedData.length > 0 && (
          <HeaderTitleNew
            title={widget.widget_name}
            uppercaseLink
            onPress={() =>
              handleShowMore({
                url: '/user-activity-log/popular_classes',
                title: 'Populares',
                canSearch: false,
              })
            }
          />
        )}
        {formattedData.length > 0 && (
          <View style={{ paddingHorizontal: 20 }}>
            {formattedData.slice(0, 3).map((item, indexMap) => {
              return (
                <CardClassesHorizontal
                  id={item.id}
                  type={item.subcategory?.name}
                  title={item.name}
                  image={item.image.path}
                  difficulty={item.difficulty}
                  duration={item.duration}
                  kcal={item.kcal}
                  plus={null}
                  minus={null}
                  paymentLevel={item.payment_level}
                  onPress={debounce(() => {
                    item.payment_level === 1
                      ? null//Actions.paywall()
                      : openTrailNew(
                          item.subcategory?.category_id,
                          item.id,
                          item.subcategory?.name
                        );
                    dispatch(setNameShare(item.name));
                  }, PERFORMANCE.ON_PRESS_DEBOUNCE_TIME)}
                  tag={item?.goals ? item?.goals[0].goal_name : null}
                  style={[
                    { width: '100%' },
                    // eslint-disable-next-line eqeqeq
                    indexMap == formattedData.slice(0, 3).length - 1 && { marginBottom: 0 },
                  ]}
                  gradientColor={getGradientColor(item.subcategory?.category_id)}
                  favorite={item.favorite}
                />
              );
            })}
          </View>
        )}
      </View>
    );
  };

  const renderTrainingsByGoal = widget => (
    <View>
      <HeaderTitleNew title={widget.widget_name} />
      <ScrollView
        horizontal
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={{ paddingBottom: 20 }}
        style={[styles.imageGroupper, { marginBottom: -20 }]}>
        <ImageButton
          imageStyle={styles.image50}
          image={measure2}
          title={translate(pt.lose_weight)}
          onPress={() => {
            handleImageButtonPress({ goals: translate(pt.lose_weight) });
          }}
        />
        <ImageButton
          imageStyle={styles.image50}
          image={sleep2}
          title={translate(pt.sleep_better)}
          onPress={() => {
            handleImageButtonPress({ goals: translate(pt.sleep_better) });
          }}
        />
        <ImageButton
          imageStyle={styles.image50}
          image={mirrorImage2}
          title={translate(pt.well_being)}
          onPress={() => {
            handleImageButtonPress({ goals: translate(pt.well_being) });
          }}
        />
        <ImageButton
          imageStyle={styles.image50}
          image={yoga1}
          title={translate(pt.flexibiliity)}
          onPress={() => {
            handleImageButtonPress({ goals: translate(pt.flexibiliity) });
          }}
        />
        <ImageButton
          imageStyle={styles.image50}
          image={yingYang1}
          title={translate(pt.lessen_anxiety)}
          onPress={() => {
            handleImageButtonPress({ goals: translate(pt.lessen_anxiety) });
          }}
        />
        <ImageButton
          imageStyle={styles.image50}
          image={buddha1}
          title={translate(pt.train_the_mind)}
          onPress={() => {
            handleImageButtonPress({ goals: translate(pt.train_the_mind) });
          }}
        />
        <ImageButton
          imageStyle={styles.image50}
          image={document3}
          title={translate(pt.physical_conditioning)}
          onPress={() => {
            handleImageButtonPress({ goals: translate(pt.physical_conditioning) });
          }}
        />
        <ImageButton
          imageStyle={styles.image50}
          image={apple1}
          title={translate(pt.healthy_eating)}
          onPress={() => {
            handleImageButtonPress({ goals: translate(pt.healthy_eating) });
          }}
        />
        <ImageButton
          imageStyle={styles.image50}
          image={grow}
          title={translate(pt.muscular_gain)}
          onPress={() => {
            handleImageButtonPress({ goals: translate(pt.muscular_gain) });
          }}
        />
        <ImageButton
          imageStyle={styles.image50}
          image={power}
          title={translate(pt.muscular_definition)}
          onPress={() => {
            handleImageButtonPress({ goals: translate(pt.muscular_definition) });
          }}
        />
        <ImageButton
          imageStyle={styles.image50}
          image={praying1}
          title={translate(pt.lessen_stress)}
          onPress={() => {
            handleImageButtonPress({ goals: translate(pt.lessen_stress) });
          }}
        />
      </ScrollView>
    </View>
  );

  const renderFoodSuggestions = widget => (
    <View>
      <HeaderTitleNew
        title={widget.widget_name}
        uppercaseLink
        onPress={
          foodSuggestions.length > 0
            ? () =>
                handleShowMore({
                  url: '/meals/recipe-suggestions',
                  color: colors.grapefruit,
                  title: widget.widget_name,
                  type: cardTypes.RECIPE_SUGGESTION,
                  node: false,
                  canSearch: false,
                })
            : null
        }
      />
      {foodSuggestions.length > 0 ? (
        <>
          {renderClassesNew(foodSuggestions, cardTypes.RECIPE_SUGGESTION, {
            urlToFetch: '/meals/recipe-suggestions',
            node: false,
            color: colors.grapefruit,
            title: widget.widget_name,
          })}
        </>
      ) : (
        <CardWidgetNoData
          gradientColors={['#FF5959', '#FA3333']}
          title={'Melhore sua alimentação'}
          description={'Adicione objetivos relacionados a alimentação para visualizar sugestões.'}
          color={'#FF5959'}
          onPress={debounce(() => {
            navigation.navigate('Profile',{ routeIndex: 0 });
          }, PERFORMANCE.ON_PRESS_DEBOUNCE_TIME)}
          image={require('app/images/food_suggestions.png')}
          imageStyle={{ marginRight: 10 }}
        />
      )}
    </View>
  );

  const renderFitnessSuggestions = widget => (
    <View>
      <HeaderTitleNew
        title={widget.widget_name}
        uppercaseLink
        onPress={
          fitnessSuggestions.length > 0
            ? () =>
                handleShowMore({
                  url: '/classes/suggestions?category=Corpo&limit=20',
                  color: colors.aquaMarineTwo,
                  title: widget.widget_name,
                  canSearch: false,
                })
            : null
        }
      />
      {fitnessSuggestions.length > 0 ? (
        <>
          {renderClassesNew(fitnessSuggestions, cardTypes.VERTICAL, {
            urlToFetch: '/classes/suggestions?category=Corpo&limit=20',
            color: colors.aquaMarineTwo,
            title: widget.widget_name,
          })}
        </>
      ) : (
        <CardWidgetNoData
          gradientColors={['#7CF3EC', '#03AAB1']}
          title={'Trabalhe seu corpo'}
          description={'Adicione interesses relacionados a atividades para vizualizar sugestões'}
          buttonTitle={'Adicionar objetivos'}
          onPress={debounce(() => {
            navigation.navigate('Profile',{ routeIndex: 0 });
          }, PERFORMANCE.ON_PRESS_DEBOUNCE_TIME)}
          color={colors.aquaMarineTwo}
          image={require('app/images/fitness_suggestions2.png')}
          imageStyle={{ height: '100%', marginRight: 10 }}
        />
      )}
    </View>
  );

  const renderMindSuggestions = widget => (
    <View>
      <HeaderTitleNew
        title={widget.widget_name}
        uppercaseLink
        onPress={
          mindSuggestions.length > 0
            ? () =>
                handleShowMore({
                  url: '/classes/suggestions?category=Mente&limit=20',
                  color: colors.pastelOrange,
                  title: widget.widget_name,
                  canSearch: false,
                })
            : null
        }
      />
      {mindSuggestions.length > 0 ? (
        <>
          {renderClassesNew(mindSuggestions, cardTypes.VERTICAL, {
            urlToFetch: '/classes/suggestions?category=Mente&limit=20',
            color: colors.pastelOrange,
            title: widget.widget_name,
          })}
        </>
      ) : (
        <CardWidgetNoData
          gradientColors={['#F8C26B', '#FE8E53']}
          title={'Relaxe sua mente'}
          description={'Adicione interesses relacionados a meditação para vizualizar sugestões.'}
          buttonTitle={'Adicionar objetivos'}
          onPress={debounce(() => {
            navigation.navigate('Profile',{ routeIndex: 0 });
          }, PERFORMANCE.ON_PRESS_DEBOUNCE_TIME)}
          color={'#FFA000'}
          image={require('app/images/mind_suggestions.png')}
          imageStyle={{ height: '100%', marginRight: 10 }}
        />
      )}
    </View>
  );

  const renderMoodWidget = widget => (
    <View>
      <HeaderTitleNew title={widget.widget_name} />
      <CardMoodWidget />
    </View>
  );

  const renderReadTips = widget => {
    return (
      <>
        {!!readingSuggestion && (
          <View>
            <HeaderTitleNew
              title={widget.widget_name}
              uppercaseLink
              onPress={() =>  navigation.navigate('Blog')}
            />
            <CardFullImageNew
              blogPost={readingSuggestion}
              onPress={debounce(() => {
                navigation.navigate('BlogPost',{
                  id: readingSuggestion.id,
                  blogPost: readingSuggestion,
                  categoryId: 15,
                });
              }, PERFORMANCE.ON_PRESS_DEBOUNCE_TIME)}
            />
          </View>
        )}
      </>
    );
  };

  const renderCaloricControl = widget => (
    <View>
      <HeaderTitleNew title={widget.widget_name} />
      {/* <CardCalories total={4000} currentValue={400} onPress={Actions.ingested_food} /> */}
    </View>
  );

  const renderHidratation = widget => (
    <View>
      <HeaderTitleNew title={widget.widget_name} />
      <CardWaterNew />
    </View>
  );

  const renderLastAttendance = widget => (
    <>
      {!isObjectEmpty(sessionDone) && (
        <>
          <HeaderTitleNew title={widget.widget_name} />
          <LastAttendanceCard item={sessionDone} done />
        </>
      )}
    </>
  );

  const renderTrainingByIntensity = widget => (
    <View>
      <HeaderTitleNew title={widget.widget_name} />
      <ScrollView
        horizontal
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={{ paddingBottom: 20 }}
        style={[styles.imageGroupper, { marginBottom: -20 }]}>
        <ImageButton
          imageStyle={styles.image50}
          style={{ flex: 1, aspectRatio: 1 }}
          image={bar1}
          title={translate(pt.beginner)}
          onPress={() => {
            handleImageButtonPress({ difficultyLevels: 'Fácil' });
          }}
        />
        <ImageButton
          imageStyle={styles.image50}
          style={{ flex: 1, aspectRatio: 1 }}
          image={bar2}
          title={translate(pt.intermediate)}
          onPress={() => {
            handleImageButtonPress({ difficultyLevels: 'Normal' });
          }}
        />
        <ImageButton
          imageStyle={styles.image50}
          style={{ flex: 1, aspectRatio: 1 }}
          image={bar3}
          title={translate(pt.advanced)}
          onPress={() => {
            handleImageButtonPress({ difficultyLevels: 'Difícil' });
          }}
        />
      </ScrollView>
    </View>
  );

  const renderPrograms = widget => (
    <View>
      {programs.length > 0 && (
        <HeaderTitleNew
          title={widget.widget_name}
          uppercaseLink
          onPress={() =>
            handleShowMore({
              url: '/trails/filter',
              color: null,
              title: widget.widget_name,
              type: cardTypes.PROGRAMS,
              node: false,
            })
          }
        />
      )}
      {programs.length > 0 && (
        <>
          {renderCardPrograms(programs, {
            urlToFetch: '/trails/filter',
            color: colors.aquaMarineTwo,
            title: translate(pt.fitness_suggestions),
          })}
        </>
      )}
    </View>
  );

  const renderHireAProfessional = () => {
    const showBoth = 
      profile?.b2b_user && 
      profile?.plan_meta?.professional_enabled &&
      profile?.plan_meta?.telemedicine_enabled;

    if (
      profile?.b2b_user &&
      !profile?.plan_meta?.telemedicine_enabled &&
      !profile?.plan_meta?.professional_enabled
    ) return;

    return (
      <View>
        <HeaderTitleNew title={translate(pt.professional_orientation)} />
        <View
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
            width: '100%',
            paddingHorizontal: 20,
          }}>
          {showBoth ? (
          <>
            <CardProfessionalSmall
              onPress={() => navigation.navigate('Profissionais')}
            />
            <CardProfessionalSmall
              onPress={() => navigation.navigate('Profissionais', { tab: 'telemedicine' })}
              telemed
            />
          </>
          ) : (
            <CardProfessional
              onPress={() => navigation.navigate('Profissionais')}
              attendance={profile?.plan_meta?.telemedicine_enabled}
            />
          )}
        </View>
      </View>
    )
  };

  const renderSipat = widget => (
    <View>
      <HeaderTitleNew title={widget.widget_name} />
      <CardSipatWidget />
    </View>
  );

  const renderBusinessAds = widget => <CardBusinessAds />;

  const renderChat = widget => (
    <View>
      <HeaderTitleNew title={widget.widget_name} />
      {/* <CardChat /> */}
    </View>
  );

  const renderGym = widget => (
    <View>
      <HeaderTitleNew title={widget.widget_name} />
      <CardGymWidget />
    </View>
  );

  const renderManuChat = widget => (
    <View>
      <HeaderTitleNew title={widget.widget_name} />
      <CardManuChat />
    </View>
  );

  const renderGamification = widget => <GamificationWidget widget={widget} />;

  const renderHealthScan = widget => (
    <View>
      <HeaderTitleNew title={'Monitore sua saúde'} />
      <HealthscanWidget widget={widget} />
    </View>
  );

  const widgetRenderers = {
    [widgetIds.SCHEDULE]: renderSchedule,
    [widgetIds.LAST_ACCESSED]: renderLastAccessed,
    [widgetIds.POPULAR]: renderPopular,
    // [widgetIds.CATEGORY]: () => null,
    [widgetIds.FAVORITES]: renderFavorites,
    [widgetIds.TRAININGS_BY_TIME]: renderTrainingsByTime,
    [widgetIds.TRAININGS_BY_GOAL]: renderTrainingsByGoal,
    [widgetIds.LAST_ATTENDANCES]: renderLastAttendance,
    [widgetIds.HIRE_A_PROFESSIONAL]: renderHireAProfessional,
    [widgetIds.READING_TIPS]: renderReadTips,
    //[widgetIds.CALORIC_CONTROL]: renderCaloricControl,
    [widgetIds.HIDRATATION]: renderHidratation,
    [widgetIds.FITNESS_SUGGESTIONS]: renderFitnessSuggestions,
    [widgetIds.FOOD_SUGGESTIONS]: renderFoodSuggestions,
    [widgetIds.TRAININGS_BY_INTENSITY]: renderTrainingByIntensity,
    // [widgetIds.VIRTUAL_COACH]: () => null,
    // [widgetIds.SHOPPING_LIST]: () => null,
    [widgetIds.NEXT_ATTENDANCE]: renderNextAttendance,
    [widgetIds.RECOMMENDEDS]: renderRecommended,
    [widgetIds.MIND_SUGGESTIONS]: renderMindSuggestions,
    [widgetIds.PROGRAMS]: renderPrograms,
    // [widgetIds.CHAT]: renderChat,
    [widgetIds.MOOD]: renderMoodWidget,
    [widgetIds.SIPAT]: renderSipat,
    [widgetIds.AD]: renderBusinessAds,
    [widgetIds.MANUCHAT]: renderManuChat,
    [widgetIds.GAMIFICACAO]: renderGamification,
    // [widgetIds.HEALTHSCAN]: renderHealthScan,
    [widgetIds.GYM]: renderGym,
  };

  const renderWidget = widget => {
    return widget.widgetId in widgetRenderers && widgetRenderers[widget.widgetId](widget);
  };

  return (
    <View style={{ flex: 1, backgroundColor: colors.paleGrey, width: '100%', height: '100dvh' }}>
      <StatusBarHolder backgroundColor="white" barStyle="dark-content" />
      <SafeAreaView style={{ flex: 1, paddingTop: 60 }}>
        <Fragment>
          <HeaderHomeNew ignoreInset={Platform.OS === 'android'} index={index} jumpTo={jumpTo} />
          <View style={{ flex: 1 }}>
            {isShowBoarding && <GamificationLeaderboard />}

            <ScrollView
              style={{ flex: 1 }}
              showsVerticalScrollIndicator
              bounces={true}
              contentContainerStyle={styles.scroll}>
              {showContent && (
                <Fragment>
                  <View
                    style={[styles.container, { paddingHorizontal: 0, paddingBottom: 100 }]}
                    // animation="fadeInUp"
                    // delay={200}
                    // useNativeDriver
                    >
                    {userWidgets.map(widget =>
                      widget.favorite || widget.standard ? renderWidget(widget) : null
                    )}
                    {/* <View style={[commons.alignCenter, { paddingHorizontal: 48, marginTop: 60 }]}>
                      <Text style={[textStyles.bold16, { color: colors.darkPro }]}>
                        {translate(pt.need_help)}
                      </Text>

                      <Text
                        style={[
                          textStyles.regular14,
                          { marginTop: 15, color: colors.darkPro2, textAlign: 'center' },
                        ]}>
                        {translate(pt.contact_us_through_our_channels)}
                      </Text>

                      <View style={[commons.flexRow, { marginTop: 20 }]}>
                        <CustomIcon name={'email'} size={18} />
                        <Text
                          style={[
                            textStyles.regular14,
                            { marginLeft: 10, color: colors.darkPro2 },
                          ]}>
                          {'suporte@namu.com.br'}
                        </Text>
                      </View>
                      <View style={[commons.flexRow, { marginTop: 20 }]}>
                        <CustomIcon name={'phone'} size={18} />
                        <Text
                          style={[
                            textStyles.regular14,
                            { marginLeft: 10, color: colors.darkPro2 },
                          ]}>
                          {'(11) 99242-3350'}
                        </Text>
                      </View>
                      <View style={[commons.flexRow, { marginTop: 26 }]}>
                        <TouchableOpacity
                          style={{ padding: 5 }}
                          onPress={() => Linking.openURL('https://www.facebook.com/somos.namu')}>
                          <Icon name={'facebook'} size={18} color={colors.darkPro} />
                        </TouchableOpacity>
                        <TouchableOpacity
                          style={{ padding: 5, marginLeft: 10 }}
                          onPress={() => Linking.openURL('https://www.instagram.com/somos.namu/')}>
                          <Icon name={'instagram'} size={18} color={colors.darkPro} />
                        </TouchableOpacity>
                      </View>
                    </View> */}
                  </View>
                </Fragment>
              )}
            </ScrollView>
          </View>
          <Modal
            backdropOpacity={0.1}
            onSwipeComplete={() => setModal(false)}
            swipeDirection={['up', 'left', 'right', 'down']}
            style={styles.modal}
            animationInTiming={600}
            visible={modal}>
            <ModalInfo
              onClose={() => {
                setModal(false);
              }}
            />
          </Modal>
        </Fragment>
      </SafeAreaView>
      {/* <RBSheet ref={refRBSheet}>
        <BottomSheetAddSchedule
          onClose={() => {
            refRBSheet.current.close();
          }}
        />
      </RBSheet> */}
      <TopFix backgroundColor="white" />
    </View>
  );
};

export default HomeScreenNew;

const styles = StyleSheet.create({
  tileBlog: {
    paddingHorizontal: 20,
  },
  containerBlog: {
    flexDirection: 'row',
    paddingHorizontal: 15,
    height: 60,
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: colors.paleGrey,
  },
  container: {
    // paddingHorizontal: 18,
    marginBottom: 32,
  },
  modal: {
    justifyContent: 'flex-end',
    marginBottom: 30,
  },
  info: {
    fontFamily: fonts.BOLD,
    fontSize: 16,
    textAlign: 'center',
    marginTop: 20,
    color: colors.greyBlue,
  },
  challenges: {
    padding: 21,
    height: 334,
    marginTop: 36,
    marginBottom: 100,
    borderTopRightRadius: 57,
    backgroundColor: colors.white,
  },
  scroll: {
    flexGrow: 1,
  },
  oval: {
    position: 'absolute',
    opacity: 0.6,
    width: width,
    bottom: 0,
  },
  flatSpace: {
    paddingHorizontal: 0,
    paddingBottom: 20,
    paddingRight: 20,
  },
  leftCard: { marginLeft: 20 },
  imageGroupper: {
    width: '100%',
    marginTop: 5,
    paddingRight: 15,
    marginBottom: 30,
    flexDirection: 'row',
  },
  fullView: {
    width: dimensions.width,
    height: dimensions.height,
    position: 'absolute',
    backgroundColor: '#000000',
  },
  remote: {
    flex: 1,
    backgroundColor: 'white',
  },
  selfBox: {
    width: 93,
    height: 140,
    position: 'absolute',
    borderRadius: 5,
    borderWidth: 2,
    borderColor: 'grey',
    marginLeft: 30,
    marginTop: 90,
    backgroundColor: 'white',
  },
  self: {
    width: 93,
    height: 140,
    borderRadius: 5,
    flex: 1,
    backgroundColor: 'white',
    //borderWidth: 2,
  },
  buttonFloatingContainer: {
    position: 'absolute',
    right: 0,
    bottom: Platform.OS === 'android' ? 67 : 100,
    zIndex: 1000,
    marginRight: 16,
  },
  buttonFloating: {
    height: 56,
    width: 56,
    borderRadius: 28,
    backgroundColor: '#4AB8CB',
  },

  image50: {
    height: 50,
    width: 50,
    resizeMode: 'contain',
  },
});
