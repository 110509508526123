export const diets = [
  { title: 'Dieta tracional', content: 'Sem restrições além das alérgicas' },
  {
    title: 'Pescetarianismo',
    content: 'Inclui peixes e frutos do mar, mas exclui a carne de outros animais.',
  },
  {
    title: 'Vegetariana',
    content:
      'Come frutas, verduras, legumes, cereais. Porém, restringe o consumo de carnes, aves, peixes, ovos, leite e derivados.',
  },
  {
    title: 'Ovolactovegetariana',
    content: 'Permitido comer apenas ovos, leite e derivados de origem animal.',
  },
  {
    title: 'Vegana',
    content:
      'Desprovida de qualquer produto de origem animal, incluindo carnes, ovos, laticínios e mel.',
  },
];

export const sampleMuscles = [
  { status: '', checked: false, title: 'Bíceps' },
  { status: '', checked: false, title: 'Costas' },
  { status: '', checked: false, title: 'Tríceps' },
  { status: '', checked: false, title: 'Peito' },
  { status: '', checked: false, title: 'Coxa' },
  { status: '', checked: false, title: 'Ombro' },
];

export const sampleMeditations = [
  { checked: false, title: 'Gostaria de dormir melhor' },
  { checked: false, title: 'Ter a vida mais leve' },
  { checked: false, title: 'Quero diminuir minha ansiedade' },
  { checked: false, title: 'Quero começar a meditar' },
];
export const sampleFilters = [
  'Nome do item',
  'Nome do item maior',
  'Item',
  'Nome do item',
  'Nome do item maior',
  'Item',
  'Item',
  'Nome do item',
  'Nome do item maior',
  'Item',
];

export const sampleAllergies = [
  'Ingredient',
  'Ingredient maior',
  'Item',
  'Ingredient',
  'Item',
  'Ingredient',
  'Ingredient maior',
  'Item',
  'Item',
  'Ingredient',
  'Ingredient maior',
  'Item',
];
