import axios from 'axios';
import APP from 'app/configs/app';

const apiVimeo = axios.create({
  baseURL: APP.API_VIMEO,
  headers: {
    Referer: 'https://namu.com.br',
  },
});

export default apiVimeo;
