import LottieView from 'lottie-react-native';
import React, { useEffect, useCallback } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { translate } from '../../i18n/src/locale';
import pt from '../../i18n/src/locale/pt';
import api from 'app/services/api';
import { showAlert, errorControl } from 'app/services/utils';
import { useSelector } from 'react-redux';
import QueryString from 'qs';
import EVENTS from 'app/configs/events';
import { Dimensions, Image } from 'react-native';
import fonts from '../../styles/fonts';
import gratefulWoman from 'app/images/gratefulWoman.png';
import greenBottom from '../../images/greenBottom.png';
import anim from 'app/styles/anim';
import logo_anime from 'app/lotties/loading.json';

const screenWidth = Dimensions.get('window').width;
const screenHeight = Dimensions.get('window').height;

const LoadingScreenNew = ({
  route,
  navigation
}) => {
  const {
    profile: { user_id },
  } = useSelector(state => state.user);
  const { interests, goals, dietaryRestrictions, physicalRestrictions, illnesses, others } = route.params;

  useEffect(() => {
    async function sendData() {
      try {
        await submitGoals();
        await submitInterests();
        await submitDietaryRestrictions();
        await submitIllnesses();
        await submitPhysicalRestrictions();
        await submitEvent();
  
        setTimeout(() => {
          navigation.navigate('Main');
        }, 500);
      } catch (error) {
        showAlert(errorControl(error));
      }
    }
    sendData();
  }, [submitEvent, submitGoals, submitInterests, submitDietaryRestrictions]);

  const submitEvent = useCallback(async () => {
    await api.post(
      'user-ui-event',
      QueryString.stringify({
        user_id,
        ui_event_name: EVENTS.ONBOARDING,
        status: 1,
      })
    );
  }, [user_id]);

  const submitInterests = useCallback(async () => {
    await api.post('user-interests', { 
      data: interests
    });
  }, [interests]);

  const submitGoals = useCallback(async () => {
    await api.post('user-goals', { 
      data: goals
    });
  }, [goals]);

  const submitDietaryRestrictions = useCallback(async () => {
    await api.post('user-dietary-restrictions', {
      data: dietaryRestrictions
    });
  }, [dietaryRestrictions]);

  const submitPhysicalRestrictions = useCallback(async () => {
    await api.patch('user-muscle-restrictions', {
      data: physicalRestrictions
    });
  }, [physicalRestrictions]);

  const submitIllnesses = useCallback(async () => {
    await api.post('chronic-diseases', {
      diseases: illnesses,
      other_disease: others.other_disease
    });
  }, [illnesses]);

  return (
    <View useNativeDriver animation={'fadeInUp'} delay={500}>
      <Image source={greenBottom} style={styles.backgroundBottom} />
      <View style={styles.container}>
        <View delay={100} useNativeDriver animation={anim.flippingAnimation}>
          <LottieView source={logo_anime} autoPlay loop={true} style={styles.logo} />
        </View>
        <View style={[styles.containerMessage]}>
          <Text style={styles.title}>{translate(pt.wonder)}</Text>
          <Text style={styles.content}>{translate(pt.you_will_be_redirected)}</Text>
        </View>
        <Image source={gratefulWoman} style={[styles.image]} />
      </View>
    </View>
  );
};

export default LoadingScreenNew;

const styles = StyleSheet.create({
  logo: {
    width: 300,
    alignSelf: 'center',
    marginTop: -10,
  },
  backgroundBottom: {
    position: 'absolute',
    width: screenWidth,
    height: screenHeight,
    bottom: -70,
    resizeMode: 'contain',
  },
  container: {
    height: screenHeight,
    width: screenWidth,
    alignItems: 'center',
    flexDirection: 'column',
  },
  title: {
    fontFamily: fonts.BOLD,
    fontSize: 24,
    lineHeight: 32.74,
    textAlign: 'center',
  },
  content: {
    fontFamily: fonts.REGULAR,
    fontSize: 16,
    lineHeight: 19,
    marginTop: 10,
    textAlign: 'center',
  },
  containerMessage: {
    alignItems: 'center',
    marginTop: -60,
    width: screenWidth,
    paddingHorizontal: 30,
  },
  image: {
    position: 'absolute',
    bottom: -100,
    width: screenWidth,
    height: 500,
    resizeMode: 'contain',
  },
});
