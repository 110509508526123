import ButtonBase from 'app/components/ButtonBase';
import { translate } from 'app/i18n/src/locale';
import pt from 'app/i18n/src/locale/pt';
import colors from 'app/styles/colors';
import fonts from 'app/styles/fonts';
import React from 'react';
import { View, Text, StyleSheet, Dimensions, Image, ScrollView, KeyboardAvoidingView, Platform } from 'react-native';
import * as Animatable from 'react-native-animatable';
import { useSafeArea } from 'react-native-safe-area-context';

const screenWidth = Dimensions.get('window').width;
const screenHeight = Dimensions.get('window').height;

const KeyboardFix = ({ children }) => {
  if(Platform.OS === 'android') {
    return children;
  }

  return (
    <KeyboardAvoidingView behavior={'height'} >
      {children}
    </KeyboardAvoidingView>
  );

}

const OnboardingView = ({
  ready = true,
  title,
  content,
  image,
  onPress,
  containerMessageStyles,
  children,
  imageStyles,
  topComponent,
  animation,
  backgroundImage,
  backgroundImageStyles,
  buttonColor,
  buttonText,
}) => {

  const insets = useSafeArea();


  return (
    <View style={{flex: 1, paddingTop: insets.top, width: "100%"}}>
      <KeyboardFix>
        <Animatable.View useNativeDriver animation={animation || 'fadeIn'} delay={500}>
          <Image source={backgroundImage} style={backgroundImageStyles} />
          <ScrollView contentContainerStyle={styles.container}>
            {topComponent}
            <View style={[styles.containerMessage, containerMessageStyles]}>
              <Text style={styles.title}>{title}</Text>
              {!!content && <Text style={styles.content}>{content}</Text>}
            </View>
            {!!children && (
              <ScrollView style={{ width: "95%", marginTop: 20, marginBottom: 20 }}>
                {children}
              </ScrollView>
            )}
            {image && <Image source={image} style={[styles.image, imageStyles]} />}
            <ButtonBase
              disabled={!ready}
              title={buttonText || translate(pt.forward)}
              style={styles.button}
              onPress={onPress}
              backgroundColor={buttonColor || colors.aquaMarineTwo}
              disabledBackgroundColor={buttonColor || colors.aquaMarineTwo}
            />
          </ScrollView>
        </Animatable.View>
      </KeyboardFix>
    </View>
  );
};

export default OnboardingView;

const styles = StyleSheet.create({
  container: {
    minHeight: screenHeight,
    width: "100%",
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  title: {
    fontFamily: fonts.BOLD,
    fontSize: 24,
    lineHeight: 32.74,
    textAlign: 'center',
  },
  content: {
    fontFamily: fonts.REGULAR,
    fontSize: 16,
    lineHeight: 19,
    marginTop: 10,
    textAlign: 'center',
  },
  containerMessage: {
    alignItems: 'center',
  },
  image: {
    position: 'absolute',
    // width: screenWidth,
    height: 400,
    bottom: 0,
    resizeMode: 'contain',
  },
  button: {
    width: "50%",
    height: 50,
    marginBottom: 90,
  },
});
