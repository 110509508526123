import React from 'react';
import colors from 'app/styles/colors';
import fonts from 'app/styles/fonts';
import { StyleSheet, View, Text } from 'react-native';

const BoxDescription = ({ text, extra }) => {
  return (
    <View>
      <View style={styles.textContainer}>
        <Text style={styles.contentTextStyle}>{text}</Text>
      </View>
      {extra}
    </View>
  );
};

const styles = StyleSheet.create({
  textContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.whiteTwo,
    borderRadius: 8,
    paddingHorizontal: 12,
    paddingVertical: 20,
    marginBottom: 10,
  },
  contentTextStyle: {
    fontFamily: fonts.REGULAR,
    fontSize: 16,
    color: colors.greyBlue,
    // lineHeight: 15.5,
    textAlign: 'center',
  },
});

export default BoxDescription;
