import React, { useState, useEffect, useCallback } from 'react';

import { View, StyleSheet, Text, Image, TextInput } from 'react-native';
import mailOutlined from 'app/images/mailOutlined.png';
import colors from '../../../styles/colors';
import fonts from '../../../styles/fonts';
import { translate } from '../../../i18n/src/locale';
import pt from '../../../i18n/src/locale/pt';
// import { Actions } from 'react-native-router-flux';
import SecureTextInput from '../../../components/SecureTextInput';
import LoginButtonBase from '../../../components/LoginButtonBase';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { signInRequest } from 'app/store/modules/auth/actions';
import EVENTS from 'app/configs/events';
import api from 'app/services/api';
import QueryString from 'qs';
import NetInfo from '@react-native-community/netinfo';
import { useNetInfo } from '@react-native-community/netinfo';
import { useNavigation } from '@react-navigation/native';

const LoginView = ({ onGoogleLogin }) => {
  const dispatch = useDispatch();
  const { loading, signed } = useSelector(state => state.auth);
  const netInfo = useNetInfo();

  const navigation = useNavigation();

  useEffect(() => {
    NetInfo.fetch().then(state => {
      entrance(state.isConnected);
    });
  }, [entrance, signed]);

  const entrance = useCallback(
    async isConn => {
      if (signed) {
        try {
          if (isConn) {
            const { data: goCore } = await api.post(
              'user-ui-event/filter',
              QueryString.stringify({
                ui_event_name: EVENTS.ONBOARDING,
                status: 1,
              })
            );
            console.log('Signed and with connection');
            navigation.navigate(goCore ? 'Main' : 'Onboarding');
          } else {
            console.log('Signed and no connection');
          }
        } catch (error) {
          console.log({ errorOnEntranceFunction: error.response });
        }
      } else {
      }
    },
    [signed, netInfo]
  );

  const [email, setEmail] = useState(''); //TODO: remove defautl state
  const [password, setPassword] = useState(''); //TODO: remove defautl state

  const handleSubmit = () => {
    const schema = yup.object().shape({
      email: yup.string().email(translate(pt.fill_email)).required(translate(pt.fill_email)),
      password: yup.string().required(translate(pt.fill_pass)),
    });

    schema
      .validate({ email, password })
      .then(() => {
        dispatch(signInRequest({ email, password }));
      })
      .catch(error => {
        console.log('ERROR MESSAGE', error.message);
      });
  };
  return (
    <View style={styles.card}>
      <Text style={styles.title}>
        {translate(pt.do_login)}
        <Text style={{ color: colors.aquaMarineTwo }}>{' Namu'}</Text>
      </Text>
      <View style={styles.inputContainer}>
        <Image source={mailOutlined} style={styles.inputIcon} />
        <TextInput
          autoCapitalize="none"
          onChangeText={setEmail}
          value={email}
          keyboardType="email-address"
          placeholder={translate(pt.email)}
          style={styles.input}
        />
      </View>
      <SecureTextInput value={password} onChangeText={setPassword} />

      <LoginButtonBase
        style={{ marginTop: 10 }}
        loading={loading}
        onPress={handleSubmit}
        title={translate(pt.enter)}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  forgot: {
    alignSelf: 'flex-end',
    color: colors.greyMedium,
    fontSize: 14,
    fontFamily: fonts.MEDIUM,
    marginVertical: 26,
  },
  inputContainer: {
    marginTop: 6,
    marginBottom: 12,
    paddingHorizontal: 14,
    height: 45,
    borderRadius: 5,
    backgroundColor: colors.white,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: colors.lightPeriwinkle,
    flexDirection: 'row',
    alignItems: 'center',
  },
  inputIcon: {
    width: 22,
    height: 22,
    resizeMode: 'contain',
    tintColor: colors.aquaMarineTwo,
  },
  input: {
    marginLeft: 10,
    flex: 1,
    color: colors.black,
    fontFamily: fonts.MEDIUM,
  },
  title: {
    fontFamily: fonts.BOLD,
    fontSize: 18,
    color: '#444242',
    textAlign: 'center',
    marginBottom: 25,
  },
  or: {
    marginVertical: 25,
    flexDirection: 'row',
    alignItems: 'center',
  },
  orText: {
    flex: 1,
    textAlign: 'center',
    color: colors.greyMedium,
    fontFamily: fonts.REGULAR,
    fontSize: 14,
  },
  line: {
    flex: 2,
    height: 1,
    backgroundColor: colors.greyMedium,
  },
  card: {
    marginHorizontal: 16,
    width: '100%',
    paddingVertical: 24,
    paddingHorizontal: 37,
  },
  loginGoogle: {
    width: '100%',
    height: 50,
    marginTop: 0,
    borderRadius: 45,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.2,
    shadowRadius: 2,

    elevation: 3,
  },
});

export default LoginView;
