import { Platform } from 'react-native';

import { SENTRY_DSN, NODE_API_URL, NODE_API_KEY, LARAVEL_API_URL, VIMEO_API_URL, IMAGE_DEFAULT_URL, ENV } from '@env';

const APP = {
  ENV: ENV,
  NAME: 'Namu',
  VERSION: 'v2.0.4',
  VERSION_NUMBER: 204,

  BUNDLE: Platform.OS === 'ios' ? 'br.com.namu.app' : 'br.com.namu',

  URL_BASE: LARAVEL_API_URL,
  URL_BASE_NODE: NODE_API_URL,

  API_VIMEO: VIMEO_API_URL,

  API_KEY_NODE: NODE_API_KEY,
  IMAGE_DEFAULT: IMAGE_DEFAULT_URL,
  GOOGLE_LOGIN: '979407597041-i0i6pj3rkukl77qgbprbrobqflnkjkg5.apps.googleusercontent.com',
  FACEBOOK_ID: '294797538338432',

  SENTRY_DSN: SENTRY_DSN,
  SENTRY_REPLAY_SAMPLE_RATE: ENV === 'production' ? 0.5 : 1.0,
  SENTRY_ERRORS_SAMPLE_RATE: ENV === 'production' ? 0.5 : 1.0,
  SENTRY_CONSOLE_LEVELS: ENV === 'production' ? ['warn', 'error', 'debug']: ['warn', 'error', 'debug', 'info', 'log', 'assert'],
  AGORA_APP_ID: ENV === 'production' ? 'ce8d70561e184fe1af47bf59190102c2' : 'e98c7265879e4d3fbe5d64732904f5c2',
  SESSION_WAITING_MINUTES: 10,

  PLATFORM: 'webapp'
};

export default APP;
