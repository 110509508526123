import React from 'react';
import { View, StyleSheet } from 'react-native';
import pauseCircleOutlined from 'app/images/pause_circle_outline.png';
import playOutline from 'app/images/play_outline.png';
import skipNext from 'app/images/skipNext.png';
import skip15sec from 'app/images/skip15sec.png';
import back15sec from 'app/images/back15sec.png';
import ButtonImage from 'app/components/ButtonImage';

const PlayerControl = ({
  handleNext,
  handleBack,
  handlePlayPause,
  currentIndex,
  lastIndex,
  handleAdvanceTime,
  handleRetreatTime,
  containerStyle,
  buttonsColor,
  isPlaying,
}) => {
  return (
    <View style={[styles.container, containerStyle]}>
      <ButtonImage
        tintColor={buttonsColor}
        source={back15sec}
        imageStyle={{ width: 30, height: 30 }}
        onPress={handleRetreatTime}
        containerStyle={styles.marginHorizontal10}
      />
      <ButtonImage
        tintColor={buttonsColor}
        disabled={currentIndex === 0}
        source={skipNext}
        imageStyle={[styles.flipImage, { width: 20, height: 20 }]}
        onPress={handleBack}
        containerStyle={styles.marginHorizontal10}
      />
      <ButtonImage
        tintColor={buttonsColor}
        source={isPlaying ? pauseCircleOutlined : playOutline}
        imageStyle={{ width: 75, height: 75 }}
        onPress={handlePlayPause}
        containerStyle={[{ width: 100, alignItems: 'center' }]}
      />
      <ButtonImage
        tintColor={buttonsColor}
        source={skipNext}
        disabled={currentIndex === lastIndex}
        imageStyle={{ width: 20, height: 20 }}
        onPress={handleNext}
        containerStyle={styles.marginHorizontal10}
      />
      <ButtonImage
        tintColor={buttonsColor}
        source={skip15sec}
        imageStyle={{ width: 30, height: 30 }}
        onPress={handleAdvanceTime}
        containerStyle={styles.marginHorizontal10}
      />
    </View>
  );
};

export default PlayerControl;

const styles = StyleSheet.create({
  marginHorizontal10: {
    marginHorizontal: 10,
  },
  container: {
    flex: 1,
    zIndex: 2,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  flipImage: {
    transform: [{ rotate: '180deg' }],
  },
});
