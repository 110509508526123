const pt = {
  categories: 'Categorias',
  gps_location_on: 'Localização por GPS ativada',
  turn_on_gps_to_search_gyms_near_to_you: 'Ative o GPS para visualizar academias próximas a você',
  subcategories: 'Subcategorias',
  difficultyLevels: 'Níveis de dificuldade',
  recommend_a_gym: 'Indique uma academia',
  goal: 'Objetivos',
  observation: 'Obsevação',
  training_by_goal: 'Treino por objetivo',
  type: 'Tipos',
  new_version: 'Nova versão do NAMU\ndisponível para download',
  new_version_desc: 'Faça a atualização para conseguir\ncontinuar utilizando o aplicativo.',
  to_update: 'Atualizar',
  no_userpro_with_freetime: 'Não existem profissionais com tempo disponível no momento.',
  groups: 'Grupo de mensagens',
  login: 'Login',
  points: 'pontos',
  expires_in: 'Expira em',
  included_in_day_pass: 'Incluídas na diária',
  x_days: '{{x}} dias',
  one_day: '1 dia',
  checkin: 'Check-in',
  which_gym_would_you_like_to_see_at_Namu_Pass: 'Qual academia gostaria de ver no Namu Pass?',
  indicate_here: 'Indique aqui',
  login_with_google: 'Entrar com o Google',
  continue_with_google: 'Continuar com o Google',
  continue_with_email: 'Continuar com o e-mail',
  continue_with_personal_acc: 'Continuar com conta pessoal',
  do_login: 'Faça login na sua conta',
  download_error: 'Ocorreu um erro ao fazer o download do arquivo. Tente novamente.',
  error_while_upload: 'Ocorreu um erro ao enviar o arquivo. Tente novamente.',
  fill_name: 'Por favor preencha o nome',
  fill_email: 'Por favor preencha o email',
  fill_cpf: 'Por favor preencha o CPF',
  fill_celular: 'Por favor preencha o Celular',
  email_to_long: 'Email muito longo',
  incorrect_email_or_password: 'E-mail ou senha incorretos',
  fill_pass: 'Por favor preencha a senha',
  not_match_pass: 'Senhas não conferem',
  fill_weight: 'Por favor preencha o peso',
  fill_height: 'Por favor preencha a altura',
  concluded_feedback: 'Feedback enviado com sucesso!',
  email: 'E-mail',
  invalid_credentials: 'Credenciais Inválidas',
  the_email_has_already_been_taken: 'O email já está em uso',
  type_email: 'Digite seu e-mail',
  password: 'Senha',
  password_min: 'Senha deve ter ao menos 8 caracteres',
  new_password_min: 'Nova senha deve ter ao menos 8 caracteres',
  type_pass: 'Digite sua senha',
  forgot_pass: 'Esqueci a senha',
  enter: 'Entrar',
  user_not_found: 'Usuário não encontrado',
  send: 'Enviar',
  unknow_error: 'Erro desconhecido',
  not_account: 'Não tem uma conta?',
  signup_now: 'Cadastre-se!',
  or_social: 'Ou faça login com\nas redes sociais',
  create_namu_account: 'Crie sua conta Namu',
  create_your_account: 'Crie sua conta',
  create_account: 'Criar conta',
  disagree_offer: 'Recusar oferta',
  your_login_with_email: 'Seu login deverá ser realizado com o email',
  create_access_password: 'Crie uma senha de acesso para sua conta Namu',
  accept_and_continue: 'Aceitar e continuar',
  accept_offer_and_continue: 'Aceitar oferta e continuar',
  cancel_any_moment_in_test_period: 'Cancele a qualquer momento dentro do período de teste',
  you_will_have_free_trial_days:
    'Você terá {{days}} dias de teste grátis, após este período o valor do plano passará a ser cobrado',
  continue_with_plan: 'Continuar com o plano',
  create_personal_account: 'Criar conta pessoal',
  create_enterprise_account: 'Criar conta corporativa',
  want_personal_profile: 'Quero um perfil pessoal',
  have_business_invite: 'Tenho um convite de empresa',
  enterprise_register_message:
    'Para verificarmos qual benefício está disponível para você, precisamos que insira seu CPF e Email de acordo com o solicitado por sua empresa.',
  personal_enterprise_register_message:
    'Insira suas informações para verificar os benefícios disponíveis para você.',
  sure_you_want_to_refuse: 'Tem certeza que deseja recusar?',
  already_exists_enterprise_account:
    'Já existe uma conta Namu com as informações inseridas. Se for a sua conta,',
  you_have_an_invitation: 'Você tem um convite de',
  no_benefits_for_you: 'Parece que não há um benefício disponível para você...',
  enterprise_plan_rejected_message: 'Uma pena que não queira prosseguir com o plano...',
  gym_widget_message: 'Mantenha-se ativo com acesso a diversas academias.',
  you_can_still_create_a_free_account:
    'Mas não fique triste, você ainda pode criar uma conta Namu gratuitamente com acesso a:',
  already_account: 'Já tem uma conta?',
  login_now: 'Faça login!',
  namu_attendance: 'Namu Orienta',
  gyms: 'Academias',
  see_gyms: 'Ver academias',
  performed_check_ins: 'Check-ins realizados',
  visited_gyms: 'Academias visitadas',
  nearby_gyms: 'Academias próximas',
  gyms_next_to_you: 'Academias próximas a você',
  you_already_saved: 'Você já\neconomizou',
  check_ins: 'Check-ins',
  cities: 'Cidades',
  back: 'Voltar',
  yes: 'Sim',
  no: 'Não',
  no_supported_file: 'Formato do arquivo não suportado.',
  size_exceeds: 'Tamanho do arquivo excede 8MB.',
  type_gym_name: 'Digite o nome da academia...',
  next: 'Próximo',
  follow: 'Cancelar plano',
  giveup: 'Voltar',
  begin: 'Iniciar',
  read_now: 'Ler agora',
  not_yet: 'Agora não',
  i_have_a_discount_code: 'Tenho um cupom',
  add: 'Adicionar',
  welcome: 'Seja bem-vindo(a)!',
  welcome_to: 'Bem-vindo(a) a',
  welcome_to_namu: 'Boas-vindas a Namu',
  register_mood: 'Registrar sentimento',
  register: 'Registrar',
  to_start: 'Para começar a utilizar o nosso aplicativo, primeiro nos diga, como quer ser chamado?',
  to_start2: 'Primeiro, queremos te conhecer!',
  how_you_want_to_be_called: 'Como deseja ser chamado(a)?',
  type_nick: 'Digite seu nome ou apelido',
  edit: 'Editar',
  thanks_nick: 'Ótimo, {{nick}}.',
  thanks_content:
    'Para que possamos te ajudar, precisamos te conhecer melhor, vamos começar pelos seus objetivos.',
  select_goals: 'Selecione seus objetivos',
  select_interests: 'Selecione seus interesses',
  select_category: 'Selecione uma categoria',
  have_any_conditions: 'Você possui alguma dessas condições?',
  any_conditions_text:
    'Selecione quais condições você possui para que possamos adaptar sua experiência no app de acordo com sua necessidade.',
  forward: 'Avançar',
  great_goals: 'Ótimos objetivos!',
  goals: 'Metas',
  active_days_target: 'Meta de dias ativo',
  set_goals: 'Definir metas',
  goals_change:
    'Caso queira mudar algum, você pode fazer ao longo da sua jornada conosco.\n\nMe diga, você tem interesse ou já praticou algumas das atividades abaixo?',
  yoga: 'Yoga',
  chronic_disease: 'Doenças crônicas',
  greatest_achievements: 'Maiores Conquistas',
  pilates: 'Pilates',
  fitness: 'Fitness',
  fitness_subtitle: 'Corrida; Funcional; Halter',
  aesthetics: 'Estética',
  aesthetics_subtitle: 'Massagem Facial, Máscaras',
  meditation: 'Mente',
  filter: 'Filtrar',
  filter_date: 'Filtrar datas',
  material_complement: 'Material complementar',
  meditation_end: 'Fim da meditação',
  meditation_end_content: 'Agora pare por um\nmomento, e pense em\ncomo você se sente',
  presentation_video: 'Vídeo de apresentação',
  trail_presentation: 'Apresentação do programa',
  meal: 'Refeição',
  meals: 'Refeições',
  plate: 'Prato',
  plates: 'Pratos',
  blog: 'Blog',
  forum: 'Fórum',
  master_class: 'Cursos',
  sipat: 'SIPAT',
  sipat_namu: 'SIPAT Namu',
  sipat_description:
    'O SIPAT da Namu é o conteúdo digital das palestras de uma SIPAT (Semana Interna de Prevenção de Acidente do Trabalho) atende à obrigatoriedade exigida pela portaria do Ministério do Trabalho e Emprego.',
  sipat_widget_message:
    'Conteúdo digital das palestras da Semana Interna de Prevenção de Acidente do Trabalho',
  download_material: 'Baixar material complementar',
  no_interest: 'Sem interesse específico',
  go_ahead: 'Legal!',
  go_ahead_content: 'E, por fim, diga se você tem alguma restrição alimentar.',
  no_restrict: 'Não tenho restrição',
  based_profile:
    'Com base no que você contou para a gente, encontramos várias aulas e programas para você começar agora mesmo!',
  translate: 'translate',
  apply: 'Aplicar',
  total: 'Total',
  training_length: 'Treino por tempo',
  good_morning: 'Bom dia',
  good_afternoon: 'Boa tarde',
  good_night: 'Boa noite',
  of: 'de',
  reeschedule: 'Remarcar',
  message: 'Mensagem',
  tell_your_goals: 'Queremos saber mais sobre você para personalizar sua experiência...',
  lets_go_to: 'Vamos lá!',
  Fill_in_the_fields_with_your_information: 'Preencha os campos com suas informações',
  define_your_goals: 'Defina seus objetivos',
  interests: 'Interesses',
  activities_of_interest: 'Atividades de interesse',
  food_restriction: 'Restrição Alimentar',
  food_restriction_message:
    'Quase acabando! Agora queremos saber se você possui alguma restrição alimentar!',
  wonder: 'Maravilha!',
  you_will_be_redirected: 'Você será direcionado(a) para a plataforma. Bom proveito! (:',

  //Social
  thanks: 'Obrigada',
  account_connected: 'Sua conta está conectada',
  account_facebook: 'Você já pode usar seu\nFacebook para fazer login',

  //card home
  title_initial: 'Olá!\nEu sou o seu card.',
  content_initial: 'Vou te mostrar como organizar sua rotina no Namu, é rapidinho.',
  know_more: 'Saiba mais',
  got: 'Entendi',
  title_removal: 'Suas atividades ficam aqui, quando não quiser mais vê-las, é só arrastar o card',
  content_removal:
    'Sempre que você não quiser mais ver uma informação, você pode arrastar o card para a esquerda e removê-lo',
  remove: 'Remover',
  remove_plate: 'Remover prato',
  config: 'Configurar',
  access: 'Acessar',
  food_eating: 'Alimentos ingeridos',
  total_values: 'Valores totais / valores de referência',
  procede: 'Avançar',
  title_config_schedule: 'Configure seus horários',
  content_config_schedule: 'Conte para a gente que horas o seu dia começa.',
  title_config_food: 'Alimentação do seu jeito',
  content_config_food: 'Queremos saber mais detalhes do que você não gosta ou não pode comer.',
  title_config_activity: 'Comece as suas atividades',
  choose_subscription: 'Escolher assinatura',
  content_config_activity:
    'Agora, vamos escolher programas e aulas para começar seus novos hábitos.',
  no_specialities: 'O profissional não tem nenhum registro de especialidades 😟',
  add_activity: 'Adicionar atividade',
  add_activity_today: 'Adicionar atividades para hoje',
  hydration: 'Hidratação',
  liters: '{{num}} litros',
  my_food: 'Minha Alimentação',
  kcal: '/ {{num}} kcal',
  no_app_formated_android:
    'Não foi possível encontrar um aplicativo compatível no seu celular para visualização do arquivo no formato {{format}}. Arquivo disponível na pasta de downloads.',
  no_app_formated_ios:
    'Não foi possível encontrar um aplicativo compatível no seu celular para visualização do arquivo no formato {{format}}. Arquivo disponível em: {{location}}',
  challenges_to_you: 'TEMOS DESAFIOS PARA VOCÊ!',
  challenge: 'Desafio',
  min: '{{num}} min',
  minu: 'min',
  level: 'Nível: ',
  seconds: '{{num}} segundos',
  turns: '{{num}} repetições',
  cals: '{{num}} Cal',
  series_repeat: 'Repetição de séries',
  series_repeat_help:
    'Os exercícios dentro de um circuito irão se repetir conforme a quantidade de séries, seguindo a ordem do primeiro para o último exercício.',
  config_schedule: 'Configure seus horários',
  config_food: 'Configure suas regras alimentares',
  config_activity: 'Comece uma atividade',
  month: 'mês',
  highlight: 'Em alta',
  check_out_other_trails: 'Confira outros programas',
  check_out_other_classes: 'Confira outras Aulas',
  calory_control: 'Controle calórico',
  fitness_suggestions: 'Sugestões de corpo',
  food_suggestions: 'Sugestões de alimentação',
  mind_suggestions: 'Sugestões de mente',
  last_accessed: 'Últimos acessados',
  popular: 'Populares',
  i_want_to_do_an_activity: 'Escolher uma atividade',
  no_activity_recorded_for_today: 'Sem atividades para o dia selecionado',
  lose_weight: 'Emagrecer',
  sleep_better: 'Dormir melhor',
  well_being: 'Bem-estar',
  flexibiliity: 'Flexibilidade',
  lessen_anxiety: 'Diminuir ansiedade',
  train_the_mind: 'Treinar a mente',
  physical_conditioning: 'Condicionamento físico',
  healthy_eating: 'Alimentação Saudável',
  muscular_gain: 'Ganho muscular',
  muscular_definition: 'Definição muscular',
  lessen_stress: 'Diminuir estresse',
  body_parts: 'Partes do corpo',
  superior: 'Superior',
  inferior: 'Inferior',
  abs: 'Abdomen',
  show_more: 'Ver mais',
  show_less: 'Ver menos',
  liters_to_the_goal: 'copos para\na meta',
  still_missing: 'Ainda faltam',
  calories_control: 'Controle de calorias',
  you_still_have: 'Você ainda possui',
  to_consume_today: 'para consumir hoje',
  need_help: 'Precisa de ajuda?',
  need_professional_help: 'Precisando de ajuda profissional?',
  contact_us_through_our_channels:
    'Entre em contato conosco atráves dos nossos canais de comunicação',
  sounds_like_you_are_ready_for_a_complete_guide:
    'Parece que você está pronto para uma mudança completa!',
  your_opinion_matters: 'Sua opinião importa para nós',
  review_message:
    'Você poderia nos ajudar avaliando o app na {{store}} Store?\nAssim podemos melhorar cada vez mais o App para você!',
  modalities: 'Modalidades',
  per_modality: 'Por modalidade',
  gym_infos: 'Sobre a Academia',
  book_daily: 'Reservar diária',
  contact: 'Contato',
  new_attachment: 'Adicionar novo anexo',
  working_hours: 'Horários de funcionamento',
  amenities: 'Comodidades',
  perform_your_checkin: 'Realize seu Check-in',
  consult_your_checkin: 'Consulte seu Check-in',
  performed_check_ins: 'Check-ins realizados',
  visited_gyms: 'Academias visitadas',
  nearby_gyms: 'Academias próximas',
  gyms_next_to_you: 'Academias próximas a você',
  favorite_gyms: 'Academias preferidas',
  see_gyms: 'Ver academias',
  see_gyms_with_free_checkin: 'Veja as academias com o primeiro check-in grátis',
  checkin: 'Check-in',
  free: 'Grátis',
  observation: 'Obsevação',
  city: 'Cidade',
  name: 'Nome',
  which_gym_would_you_like_to_see_at_Namu_Pass: 'Qual academia gostaria de ver no Namu Pass?',
  indicate_here: 'Indique aqui',
  recommend_a_gym: 'Indique uma academia',
  per_modality: 'Por modalidade',
  //profile
  course: 'Curso',
  institution: 'Instituição',
  conclusion_year: 'Ano de conclusão',
  name: 'Nome',
  add_weight: 'Adicionar peso e altura',
  profile: 'Perfil',
  favorites: 'Favoritos',
  configuration: 'Configurações',
  create_new_playlist: 'Criar nova Playlist',
  create_playlist: 'Criar Playlist',
  select_playlist: 'Selecione uma playlist',
  yours_playlist: 'Suas Playlists',
  add_photo: 'Adicionar foto de capa',
  description: 'Descrição',
  update_info: 'Atualizar informações',
  updated_with_success: 'Informações atualizadas com sucesso!',
  add_description: 'Adicionar descrição',
  activities_playlist: 'Coloque uma descrição para que as pessoas saibam o que tem na sua playlist',
  description_playlist:
    'Coloque uma descrição para que as pessoas saibam o que tem na sua playlist',
  new_playlist: 'Nova Playlist',
  name_your_playlist: 'Nome da sua playlist',
  tell_name_your_playlist: 'Digite o nome que deseja dar à sua playlist',
  present_schedule: 'Presentes em sua agenda',
  activity: 'Atividade',
  activities: 'Corpo',
  activities_out: 'Atividades avulsas',
  change_weight: 'Você alterou seu peso para ',
  to_change: 'Trocar',
  kg: '{{num}} kg',
  add_content: 'Adicionar um conteúdo',
  what_foods_can_you_not_consume: 'Quais alimentos você não pode consumir?',
  what_physical_restrictions: 'Quais restrições físicas?',
  what_chronic_diseases: 'Quais doenças crônicas?',
  what_chronic_illnesses_do_you_have: 'Quais doenças crônicas você possui?',
  muscles_can_you_not: 'Quais músculos você não pode forçar ou trabalhar de alguma forma?',
  restrict_muscle: 'Restrições musculares',
  objectives: 'Objetivos',
  food_restrict: 'Alimentação e restrição',
  begin_day: 'Início do dia',
  begin_activities: 'Suas atividades começam:',
  my_goals: 'Minhas Metas',
  data_goals: 'Os dados das metas são exibidos referênte ao seu progresso nos programas ativos',
  daily: 'Diário',
  daily_a: 'Diária',
  weekly: 'Semanal',
  monthly: 'Mensal',
  quarterly: 'Trimestral',
  yearly: 'Anual',
  trail: 'Programa',
  trails: 'Programas',
  recommended: 'Recomendado',
  recommended_content: 'Mostrando todos os Itens que estavam na sua lista de refeições para hoje',
  others: 'Outros',
  lesson: 'Aula',
  classes: 'Aulas',
  exercise: 'Exercício',
  add_item: 'Adicionar item ',
  exercice: 'Exercício',
  exercices: 'Exercícios',
  muscle_group: 'Regiões trabalhadas',
  trail_exercices: 'Exercícios dos programas',
  done_exercices: '{{num}} exercícios feitos',
  minutes_trained: '{{num}} minutos treinados',
  minutes: '{{num}} Min',
  time_meditation: '{{num}} Tempo meditando',
  calories_weekly: 'Calorias semanais',
  food_eat: '{{num}} alimentos ingeridos',
  calories: '{{num}} calorias',
  your_size: 'Suas medidas',
  weight: 'Peso',
  weight_body: 'Peso corporal',
  weight_body_content: 'Maior precisão e acompanhamento da sua evolução',
  search_content: 'Buscar conteúdo',
  weight_input: 'Digite o seu peso',
  height: 'Altura',
  vegan: 'Vegana',
  region_work: 'Regiões Trabalhadas',
  height_input: 'Digite a sua altura',
  save_data: 'Salvar dados',
  save_filters: 'Salvar Filtros',
  choose_name_to_filter: 'Escolha um nome para o filtro',
  save_choices: 'Salvar escolhas',
  save_in_my_meals: 'Salvar na minha alimentação',
  add_to_playlist: 'Adicionar à playlist',
  add_desc_to_playlist: 'Adicionar descrição da playlist',
  add_to_schedule: 'Adicionar à agenda',
  reschedule_activity: 'Reagendar atividade',
  edit_amount: 'Editar quantidade',
  amount_wish: 'Qual quantidade desejada',
  add_to_calendar: 'Adicionar à agenda',
  add_to_shop: 'Adicionar ao carrinho',
  add_to_shop_car: 'Adicionar ao carrinho de compra',
  add_food: 'Adicionar uma Refeição',
  add_activity_bottom: 'Adicionar uma Atividade',
  begin_playlist: 'Iniciar playlist',
  add_meditation: 'Adicionar uma Meditação',
  add_category_playlist: 'Adicionar de {{name}}',
  tell_your_size: 'Nos diga seu peso e altura',
  tell_your_size_content: 'Diga seu peso e altura que iremos adaptar as aulas para você.',
  contact_us: 'Entrar em contato',
  remove_account: 'Remover minha conta',
  customize_home: 'Personalizar início',
  add_members_family: 'Adicionar membro da família',
  type_relationship: 'Tipo de relacionamento',
  label_available_spaces: '{{qtdSpaces}} espaços disponíveis',
  btn_add_members_family: '+ Adicionar outro membro da família',
  delete_members: 'Tem certeza que deseja excluir este dependente?',
  info_delete_members:
    'Se excluir, seu familiar irá perder os benefícios oferecidos pela conta corporativa, mas ainda poderá manter a conta pessoal gratuita limitada.',
  existing_members: 'Este dependente já existe!',
  info_existing_members: 'Tente adicionar outro.',
  btn_delete_members: 'Excluir',
  btn_close_members: 'Voltar',
  header_members_family: 'Membros da família',
  fill_in_members_family_info: 'Preencha com as informações do familiar',
  new_members_family: 'Incluir novo membro da família',
  send_invite_members_family: 'Convite enviado com sucesso',
  empty_inputs_members_family: 'Preencha os campos',
  description_confirm_members_family:
    'Ao clicar em "Continuar", você confirma a veracidade das informações fornecidas acima e está ciente de que tanto a Namu quanto a empresa à qual está vinculado podem verificar a veracidade desses dados.',
  info_send_email_members_family:
    'Seu familiar recebeu um convite por e-mail para acessar o aplicativo. Por favor, peça a ele que verifique tanto a caixa de entrada quanto a pasta de spam.',

  //settings
  settings: 'Configurações',
  edit_name: 'Editar nome',
  pays: 'Pagamentos',
  view_email: 'E-mail de cadastro',
  members_family: 'Membros da família',
  son: 'Filho (a)',
  spouse: 'Cônjuge',
  notifications: 'Notificações',
  merge: 'Vincular',
  change_password: 'Alterar senha',
  terms: 'Termos de privacidade',
  help: 'Ajuda',
  suggestion: 'Sugestões',
  suggestion_title: 'Como podemos te ajudar?',
  suggestion_desc: 'Use o campo abaixo para mandar sua mensagem.',
  suggestion_input: 'Digite sua mensagem',
  suggestion_contacts: 'Você também pode nos contatar via WhatsApp ou e-mail.',
  exit: 'Sair',
  class: 'a aula',

  //mood
  emotional: 'Diário emocional',
  log: 'Registro',
  analytics: 'Análises',
  your_feeling: 'Como você está\nse sentindo?',
  feeling_cause: 'Qual é o motivo\ndesse sentimento?',
  delete: 'Excluir',
  success_saved_log: 'Registro salvo com sucesso',
  log_new_mood: '+ Registrar novo humor',
  period: 'Período',
  emotional_energy: 'Energia emocional',
  emotional_charge: 'Carga emocional',
  energetic: 'Enérgico',
  calm: 'Calmo',
  positive: 'Positivo',
  negative: 'Negativo',
  predominant: 'Predominante',
  mood: 'Humor',
  reason: 'Motivo',
  mood_reason: 'Motivo do Humor',
  mood_reason_relation: 'Relação humor e motivo',
  days: 'Dias',

  //food scan
  food_scan: 'Namu FoodScan',
  analise_food: 'Analisar refeição',

  //edit_name
  how_call: 'Como você deseja ser\nchamado?',
  name_will_show: 'Seu nome irá aparecer no seu perfil, nos\ne-mails e no forúm.',
  full_name: 'Nome Sobrenome',
  save_name: 'Salvar nome',

  //view_email
  email_change_msg:
    'Caso deseje alterar o email de cadastro, entre em contato com suporte@namu.com.br',

  //change_password
  actual_pass: 'Senha atual',
  type_actual_pass: 'Digite sua senha atual',
  confirm_actual_pass: 'Confirmar senha atual',
  confirm_new_pass: 'Confirmar nova senha',
  type_confirm_actual_pass: 'Digite novamente sua senha atual',
  type_confirm_new_pass: 'Digite novamente sua senha nova',
  new_pass: 'Nova senha',
  type_new_pass: 'Digite uma nova senha',
  save: 'Salvar',

  make_fast_activity: 'Faça uma\natividade rápida',
  fast_activity: 'Atividade rápida',
  start_planning: 'Comece o\nseu planejamento',
  meal_planning: 'Planejamento\nde alimentação',
  my_food_value: 'Minha alimentação\n610 / 2100 kcal',
  shopping_car: 'Carrinho\nde compras',
  eat_out: 'Comeu algo fora ?\nAdicione agora',
  do_you_eat: 'Você comeu esse prato hoje?',
  end_shop: 'Finalizar Compra',

  home: 'Casa',
  work: 'Trabalho',
  plane: 'Avião',
  park: 'Parque',
  school: 'Faculdade',

  lossWeight: 'Emagrecer',
  resistence: 'Resistência',
  start_train: 'Começar a treinar',
  start: 'Começar',
  oneWeek: '1 Semana',

  breakfast: 'Café da manhã',
  launch: 'Almoço',
  beverage: 'Bebida',
  launch_or_dinner: 'Almoço ou Jantar',
  dinner: 'Jantar',
  snack: 'Lanche',
  meal_times: 'Horário das refeições',

  change_data: 'Mudar Data',
  change_data_content: 'Qual a melhor data para\nfazer essa atividade?',

  change_hour: 'Mudar horário',
  change_hour_content: 'Qual o melhor horário para\nfazer essa atividade?',

  tell_hour: 'Que horas você costuma começar o seu dia?',
  tell_hour_content:
    'Vamos ajustar as atividades para a sua rotina, você ainda poderá alterar os horários manualmente no seu calendário',

  teacher: 'Instrutor',
  voice: 'Voz',
  teachers: 'Instrutores',
  forum_posts: 'Discussões do Fórum',
  see_benefits: 'Veja os benefícios',
  see_history: 'Ver histórico',
  history_sizes: 'Histórico das suas medidas',
  see_options: 'Ver opções',
  see_recipes: 'Ver receitas',
  understand_the_risks: 'Cuidados e Dicas',
  tips_and_tricks: 'Cuidados e Dicas',
  prerequisites: 'Pré-requisitos',
  resume_food: 'Resumo da alimentação',
  nutritional_value: 'Valor Nutricional',
  food_list: 'Lista dos alimentos',
  amount_eat: 'Qual a quantidade ingerida?',
  nutritional_value_average: 'Valor Nutricional médio/dia',
  resume_calendar: 'Resumo da sua agenda',
  trail_challenges: 'Programas do desafio',
  trail_class: 'Aulas do programa',
  trail_activity: 'Atividades do programa',
  begin_challenge: 'Iniciar desafio',
  begin_trail: 'Programar as aulas na agenda',
  begin_meal: 'Programar refeições na agenda',
  item_added_playlist: 'Item adicionado\nà playlist',
  want_change_ingredient: 'Deseja trocar por qual ingrediente?',
  may_change_result: 'A substituição pode afetar o resultado final do prato',
  save_back: 'Salvar e voltar',
  cancel_trail: 'Retirar as aulas da minha agenda',
  cancel_meal: 'Retirar as refeições da minha agenda',

  begin_exercise: 'Iniciar exercício',
  cancel_exercise: 'Cancelar exercício',
  begin_lesson: 'Iniciar aula',
  cancel_lesson: 'Cancelar aula',

  clear: 'Limpar',
  clean_filter: 'Limpar filtros',
  save_num_choices: 'Salvar {{num}} escolha',

  cancel: 'Cancelar',
  conclude: 'Concluir',
  continue: 'Continuar',
  confirm: 'Confirmar',
  yes_confirm: 'Sim, confirmar',
  no_pain: 'Não tenho dores',
  category_pain: 'Como você categoriza a sua\ndor no ',
  physical_restriction: 'Restrição física',
  physical_restriction_content:
    'Possui algum músculo que você não pode forçar ou trabalhar de forma alguma?',

  how_much_time: 'Quanto tempo você tem?',
  tell_where_activity: 'Nos conte onde você deseja fazer essa atividades',
  ten_seconds: '10 min',
  tweenty_seconds: '20 min',
  third_seconds: '30 min',

  we_have_suggestion: 'Temos sugestões de atividades para você',
  exit_later: 'Sair e fazer mais tarde',

  buy_list: 'Lista de compra',
  buy: 'Comprar',
  your_list_meals: 'Sua lista de refeição ',
  change_schedule: 'Mudar meu planejamento',
  your_list_meals_content: 'Lista de todas as refeições que você irá consumir durante a semana',

  plates_schedule: 'Pratos presentes nas sua agenda',
  buy_list_empty: 'Sua lista de compras está vazia',
  add_plates: 'Adicionar pratos',
  add_something_eat: 'Adicionar algo que comeu',
  last_buy_list: 'Últimas listas geradas',
  generate_buy_list: 'Gerar lista de compras',
  create_buy_list: 'Criar lista de compras',
  create_list: 'Gerar lista',
  num_plates: '{{num}} pratos',
  edit_data: 'Editar dados',
  edit_date: 'Editar data',
  details: 'Detalhes',
  snacks: 'Lanches',
  varieties: 'Variedades',
  num_varieties: '{{num}} variedades',
  see_all: 'Ver todos',
  see_all2: 'Ver tudo',
  see_details: 'Ver detalhes',
  reschedule: 'Reagendar',
  see_plate: 'Ver prato',
  see_plates: 'Ver pratos',
  itens_to_buy: 'Itens a serem comprados',
  selected_itens: 'itens selecionados',
  follow_any_diet: 'Você segue alguma dieta\nrestritiva atualmente?',
  diet_not_found: 'Minha dieta não está listada',
  allergies_and_restrictions: 'Alergias e restrições',
  ingredients: 'Ingredientes',
  preparation_mode: 'Modo de preparo',
  how_to: 'Modo de preparo',
  choose: 'Escolha',
  edit_photo: 'Editar foto',
  edit_profile: 'Editar perfil',
  choose_ingredients: 'Nos diga o que você não pode ou não deseja comer',

  next_video: 'Próximo vídeo',

  videos: 'Vídeos',
  podcasts: 'Podcast',
  texts: 'Textos',
  author: 'Autor',
  fill_profile: 'Completar perfil',
  fill_activity_profile: 'Complete seu perfil do {{name}}',
  fill_yoga_profile_content:
    'Vimos que você é novo por aqui. Responda algumas perguntas para entendermos o seu perfil.',

  fill_meal_profile: 'Complete seu perfil alimentar',
  fill_meal_profile_content:
    'Vimos que você é novo por aqui. Responda algumas perguntas para entendermos o seu perfil.',

  fill_meditation_profile: 'Complete seu perfil de Mente',
  fill_meditation_profile_content:
    'Vimos que você é novo por aqui. Responda algumas perguntas para entendermos o seu perfil.',

  about_meditation: 'Sobre a meditação',
  about_meditation_content: 'Nos conte quais são o seus objetivos na plataforma!',
  about_interests_content: 'Nos conte quais são as suas atividades de interesse!',

  completed_meditation_profile: 'Perfil de Mente completo',
  completed_meditation_profile_content:
    'Seu perfil de meditação está completo. Altere ou atualize as informações do seu perfil de meditação',
  update_profile: 'Atualizar perfil',

  analise_profile: 'Analisando perfil',
  plate_has_restrictions: 'Esse prato contém itens inclusos nas suas restrições',
  buy_day_limit: 'Até que dia deseja gerar o\ncarrinho de compras?',
  analise_content: 'Selecionando as melhores combinações de atividades para você!',

  forgot_password_success: 'Email para resetar a senha foi enviado com sucesso!',

  //purchase
  purchase_first_title: 'Tenha acesso completo',
  purchase_first_text:
    'Explore milhares de conteúdos feitos para você acessar quando e onde quiser.\n\nRole a tela para o lado e veja as vantagens de ser assinante.',

  purchase_second_title: 'Explore do seu jeito',
  purchase_second_meals: '+ 980 refeições de diversas dietas',
  purchase_second_classes: '+ 240 aulas de atividades físicas',
  purchase_second_meditations: '+ 150 meditações',
  purchase_second_texts: '+ 4.000 textos de blog',
  purchase_second_courses: '+ 50 cursos',
  purchase_second_subtitle: 'Garanta seu acesso completo',

  purchase_third_title: 'Quem assina, ama!',
  purchase_third_text:
    '“Eu amei o aplicativo, consegui chegar\n\nem meus objetivos. Ele foi muito além\n\ndo que eu imaginava”',
  purchase_third_subtitle: 'Você também consegue',

  premium_plan_credit: '1 Crédito do plano Premium',
  free_trial_test_before: '{{days}} dias de teste grátis, após esse perído',
  free_trial_test_days: 'Começar teste de {{days}} dias GRÁTIS',
  purchase_fourth_title: 'Assine hoje e ganhe ainda mais',
  purchase_fourth_text_1: 'Assinando hoje você tem ainda',
  purchase_fourth_text_accent: ' 7 dias para testar gratuitamente ',
  purchase_fourth_text_2:
    'tudo que preparamos para o seu bem-estar.\n\nReceba também em seu e-mail mais de 8 E-books sobre vida saudável.',
  purchase_fourth_subtitle: 'Escolha seu plano e aproveite!',

  purchase_fifth_title: 'Alcance seus objetivos\nconteúdos exclusivos para:',
  purchase_fifth_text: 'Emagrecimento\nFortalecimento\nBem Estar\nMelhorar o sono\nE muito mais...',
  purchase_fifth_subtitle: 'Assine e veja a diferença',

  plan: 'Plano',
  most_complete_plan_360: 'O plano 360° do APP mais completo para você!',
  pick_a_plan: 'Escolha seu plano',
  purchase_yearly_notes: 'Economize R$ 249,00 por ano com a assinatura anual',
  proceed_with_individual_course: 'Continuar apenas com o curso individual',
  cancel_policy: 'Política de cancelamento',
  cancel_policy_text_part1:
    'Caso o Usuário pretenda cancelar a contratação firmada junto ao Aplicativo, antes do fim do prazo do período do plano contratado, será cobrado do Usuário o valor mensal da utilização dos serviços (como exemplificado na cláusula IV 9.1.1 do Termo de Uso), pelos meses efetivamente utilizados até o momento, ainda que não integralmente (ou seja, sendo a unidade mínima um mês), acrescido de multa rescisória no importe de 10% do valor do plano contratado.',
  cancel_policy_text_part2:
    'Ex.O usuário que contratou o plano anual ou trimestral e cancelou no decorrer do plano, deverá fazer a seguinte conta para a solicitação do reembolso:',
  calculate_cancel_text:
    '(Valor do plano - (Meses Utilizados * Plano Mensal) - (Valor do Plano * 10% de Multa) = Valor a ser reembolsado.',
  will_be_able_to_cancel:
    'Você pode cancelar em até 7 dias após a compra e receberá o estorno total do valor pago.\n\nCancelamento do plano Trimestral ou Anual será calculado os meses utilizados',
  cancelling_checkbox: 'Declaro que li e concordo com a ',
  cancelling_signature: 'Cancelar assinatura',
  cancelling_signature_title: 'Ah, que pena! Estamos indo bem juntos.',
  cancelling_signature_text_part1: 'Entendemos que iniciar uma transformação pode ser desafiador.',
  cancelling_signature_text_part2:
    'Não sabemos os motivos que te trouxeram até aqui, mas antes de prosseguir, considere dar mais uma chance. Às vezes, um esforço extra pode fazer toda a diferença em sua jornada. Se você ainda deseja prosseguir com o cancelamento, por favor, compartilhe conosco como podemos melhorar para ajudá-lo melhor da próxima vez.',
  cancelling_declaring: 'Declaro que li e concordo com a',
  checkout: 'Checkout',
  payment_method: 'Forma de pagamento',
  credit_card: 'Cartão de crédito',
  add_new_credit_card: 'Adicionar novo cartão',
  add_credit_card: 'Adicionar cartão',
  confirm_checkin: 'Confirmar check-in',
  confirm_reserve: 'Confirmar reserva',
  neighborhood: 'Bairro',
  city: 'Cidade',
  state: 'Estado',
  country: 'País',
  billing_address: 'Endereço de cobrança',
  address: 'Endereço',
  postal_code: 'CEP',
  number: 'Número',
  cellphone: 'Celular',
  change_cellphone: 'Alterar celular',
  bill: 'Boleto',
  payment: 'Pagamento',
  paymentCreditCard: 'Pagamento com cartão de crédito',
  cc_banners: 'Bandeiras',
  complete_name: 'Nome completo',
  name_on_card: 'Nome no cartão',
  select_saved_card: 'Usar cartão salvo',
  select_other_saved_card: 'Escolher outro cartão',
  new_card: 'Novo cartão',
  field_required: '{{field}} obrigatório',
  field_incorrect: '{{field}} incorreto',
  namu_orientation: 'Namu Orienta',
  namu_vitalscan: 'Namu VitalScan',
  the_namu_vitalscan_is_not_a_substitute:
    'O Namu VitalScan não substitui o atendimento médico profissional. A ferramenta é apenas consultiva.',
  one_credit_for_teleorientation: '+ {{count}} crédito para orientação profissional por mês',
  meals_of_different_diets: '+ {{count}} refeições de diversas dietas',
  physical_activity_classes: '+ {{count}} aulas de atividades físicas',
  teleattendances_with_professionals_by_month: '+ {{count}} orientações com profissionais por mês',
  meditations: '+ {{count}} meditações',
  blog_posts: '+ {{count}} textos de blog',
  courses: '+ {{count}} cursos',
  courses_title: 'Cursos',
  days_free_trial: '+ {{count}} days free trial',
  healthy_living_ebooks: '+ {{count}} E-books de vida saudável',
  subscription_plus_courses: 'Assinatura + Cursos',
  basic_subscription: 'Assinatura Básica',
  total_access: 'Acesso total',
  have_total_access_premium: 'Tenha acesso total ao conteúdo premium + todos os cursos da Namu!',
  have_total_access: 'Tenha acesso completo',
  explore_millions_of_contents:
    'Explore milhares de conteúdos feitos para você acessar quando e onde quiser',
  about: 'sobre',
  healthy_life: 'vida saudável',
  reach_your_goals: 'Alcance seus objetivos',
  slimming: 'Emagrecimento',
  fortification: 'Fortalecimento',
  welfare: 'Bem Estar',
  improve_sleep: 'Melhorar o sono',
  and_much_more: 'E muito mais!',
  vitalscan_consent_topic1:
    'Ao continuar, declaro estar ciente de que o objetivo do Namu ViltalScan é melhorar o conhecimento sobre o bem-estar geral das pessoas, nunca expô-las ou prejudicá-las. Fornecendo assim informações para adoção de ações preventivas e melhor gestão do bem-estar e saúde das pessoas.',
  vitalscan_consent_topic2_0:
    'Sendo assim, concordo que no caso de medições consecutivas que obtiverem',
  vitalscan_consent_topic2_1:
    ', sejam compartilhadas automaticamente com o gestor de saúde a qual eu e a Namu estejamos veiculados.',
  vitalscan_consent_topic3:
    'os resultados gerados pelo VitalScan são apenas consultivos e não devem ser considerados como um diagnóstico médico definitivo. Se você tiver quaisquer preocupações ou dúvidas sobre sua saúde, é essencial que consulte um profissional.',
  accept_consent: 'Aceitar',
  reject_consent: 'Rejeitar',
  agree_terms: 'Eu concordo com os termos e condições',
  agree_policies: 'Eu concordo com as políticas de privacidade',
  changed_result_text:
    'Alguns dos resultados apresentam alterações. Sugerimos que repita o escaneamento para obter resultados mais precisos.',
  changed_result_btn: 'Repetir escaneamento',
  changed_result_widget: 'Resultados alterados, repita o escaneamento',

  voucherDiscount: 'Insira seu cupom',
  card_number: 'Número do cartão',
  valid_thru: 'Vencimento',
  cc_cvv: 'Código de segurança',
  document: 'CPF',
  payments: 'Parcelas',
  interest_free: 'sem juros',
  save_card: 'Salvar dados do cartão para próxima compra',
  discount_code: 'Código de desconto',
  if_you_have_discount_code: 'Se você possui um código de desconto, insira abaixo',
  type_code_here: 'digite o código aqui',
  purchase_summary: 'Resumo da compra',
  daily_stress: 'Qual seu nível de estresse diário?',
  promocional_price: 'Valores promocionais por tempo limitado',
  daily_slep: 'Qual sua qualidade de sono?',
  daily_familiarity: 'Qual sua familiaridade com meditação?',

  document_missing: 'Faltou preencher o campo CPF',
  name_missing: 'Faltou preencher o campo Nome',

  congratulations: 'Parabéns',
  my_signature: 'Minha assinatura',
  signature_success: 'Sua assinatura foi efetivada com\nsucesso!',
  payment_pending: 'Seu pedido está aguardando pagamento',
  payment_pending2: 'Aguardando pagamento',
  when_payment_successful: 'Assim que o pagamento for\nefetivado, sua assinatura estará\nativa',
  bill_url: 'Link do boleto',
  bill_barcode: 'Código de barras do boleto',
  copy_bill_barcode: 'Copie o código abaixo e faça o pagamento em seu banco',
  copy: 'Copiar',

  //about
  about_first_title: 'Bem-Vindo ao Namu',
  no_more_registers: 'Sem mais registros na agenda',
  about_first_subtitle: 'Seu super app de bem-estar 360',
  about_first_text:
    'Explore a maior plataforma integrada de alimentação, corpo e mente e construa a sua nova rotina de forma descomplicada',
  about_second_title: 'Você mais saudável',
  about_second_subtitle: 'Facilitamos sua alimentação',
  about_second_text:
    'Planejamos seu dia-a-dia com centenas de receitas para os seus objetivos, tenha acesso a sua lista de compras e videos do modo de preparo.',

  about_third_title: 'Vamos juntos nessa',
  about_third_subtitle: 'Somos o seu companheiro',
  about_third_text:
    'Ajudamos você a construir novos hábitos de verdade e levar uma vida mais equilibrada',

  //forum
  new_publishes: {
    zero: 'sem publicações',
    one: '1 nova publição',
    other: '{{count}} novas publicações',
  },
  today: 'Hoje',
  yesterday: 'Ontem',
  days_ago: {
    one: '1 dia atrás',
    other: '{{count}} dias atrás',
  },
  answer: 'Responder',
  add_topic: 'Adicionar um Tópico',
  add_answer: 'Adicionar uma Resposta',
  remove_comment: 'Remover comentário',
  do_you_want_remove_comment: 'Deseja remover esse comentário?',

  //Hydration
  ingested_quantity: 'Quantidade ingerida',
  see_reference_values: 'Ver valores de referência',
  reference_values: 'Valores de referência',
  consumption_configuration: 'Configuração de consumo',
  define_alerts: 'Definir os alertas',
  daily_goal: 'Meta diária',
  enable_notification: 'Habilitar notificação',
  recurrence_alerts: 'Recorrência alertas',
  first_alert: 'Primeiro alerta',
  every_half_hour: 'A cada meia hora',
  every_hour: 'A cada uma hora',
  every_two_hours: 'A cada duas horas',
  every_four_hours: 'A cada quatro horas',
  every_eight_hours: 'A cada oito horas',
  custom: 'Personalizado',
  enable: 'ON',
  disabled: 'OFF',

  hours_exercise: 'horas',
  minutes_exercise: 'minutos',
  seconds_exercise: 'segundos',

  //Trails
  weekly_abbreviation: 'Sem',
  config_days: 'Configurar dias',
  empty_playlist: 'Você ainda não tem itens em sua playlist',
  to_start_this_activity:
    'Para começar essa atividade, você precisa configurar quais dias e horários deseja treinar',
  to_start_this_meditation:
    'Para começar esse programa, você precisa configurar quais dias e horários deseja meditar',
  to_start_this_meal:
    'Para começar esse programa, você precisa configurar quais são os horários da sua alimentação',
  class_day: 'Dia da aula',
  class_days: 'Dias da aula',
  days_selected: '{{qtSelected}} de {{range}} dias selecionados',
  hours: 'horas',
  hours_minimal: 'h',
  day: 'Dia {{day}}',
  minutes_by_day: 'minutos por dia',
  minutes_by_week: 'minutos por semana',
  weeks: '{{num}} Semanas',
  when_do_you_want_to_start_trail: 'Quando você deseja\ncomeçar esse programa',
  your_schedule_will_adjust:
    'Sua agenda irá se ajustar para que todas\nas suas atividades se ajustem à sua rotina',
  not_all_days_are_selected: 'Quantidade insuficiente de dias selecionados',
  choice_program: 'Programas sugeridos',
  subTitlesugestion: 'Programas com rotinas de 1 ou mais semanas',
  subTitlesugestionClasses: 'Aulas individuais para você iniciar agora mesmo',

  //Schedule Class
  start_schedule: 'Início',
  period_schedule: 'Período',
  recurrence_schedule: 'Recorrência',
  morning_schedule: 'Manhã',
  afternoon_schedule: 'Tarde',
  night_schedule: 'Noite',
  finish_schedule: 'Concluir agendamento',
  ending_schedule: 'Término',
  never_schedule: 'Nunca',
  after_schedule: 'Após',
  sessions_schedule: 'Sessões',
  week: 'Semana',
  repeat_every: 'Repete a cada',
  repeat_every_to: 'Repetir às/aos',

  //Offline Content
  offline_content: 'Conteúdos Offline',

  //agenda
  your_agenda: 'Encontre seus hábitos saudáveis',
  your_agenda_content:
    'Aqui você encontra atividades individuais para experimentar, programas para criar novos hábitos, ou escolha um desafio para uma rotina completa',
  when_do_you_want_to_add: 'Quando você deseja adicionar?',
  do_you_want_to_create_a_recurrence: 'Deseja criar uma recorrência?',

  // Explorer
  move_your_body: 'Movimente o corpo',
  explore_activities: 'Explore e encontre uma atividade para você',
  feed_yourself_well: 'Alimente-se bem',
  explore_meals: 'Encontre um jeito saudável de se alimentar',
  take_care_mind: 'Cuide da sua mente',
  find_a_meditation: 'Encontre uma meditação que te faça bem',
  finish_exercise: 'Finalizar exercício',
  finish_class: 'Finalizar aula',
  no_results: 'Nenhum resultado encontrado',
  body: 'Corpo',
  label_hours_of_days: [
    'Primeiro horário do dia',
    'Segundo horário do dia',
    'Terceiro horário do dia',
    'Quarto horário do dia',
    'Quinto horário do dia',
    'Sexto horário do dia',
  ],

  period_interest: 'Escolha o período de interesse',
  show_interest: 'Solicitar Agenda',
  morning: 'Manhã',
  afternoon: 'Tarde',
  night: 'Noite',
  alert_interest: 'Seu interesse foi enviado, aguarde o profissional disponibilizar o horário.',

  attachments: 'Anexos',

  //  menu titles
  home_start: 'Início',
  explorer: 'Aulas',
  forum_content: 'Blog',
  calendar: 'Agenda',

  // feedback screens
  titleMeditationFeedback: 'Parabéns pela sua meditação',
  descriptionMeditationFeedback: 'Como você se sentiu\ncom a meditação?',
  leftContentMeditationFeedback: 'Não consegui\nme concentrar',
  rigthContentMeditationFeedback: 'Consegui relaxar\ne seguir os passos',

  titleExerciseFeedback: 'Parabéns pelo seu treino!',
  descriptionExerciseFeedback: 'Como você se sentiu fazendo o\nseu treino?',
  leftContentExerciseFeedback: 'Mal consigo\nrespirar',
  rigthContentExerciseFeedback: 'Consigo fazer\nmais um treino',

  change_plan: 'Alterar meu plano',

  water_intake_today: 'ingeridos hoje',
  water_intake_date: 'ingeridos',
  error_feedback: 'Erro ao enviar o feedback',
  item_agenda_added: 'Adicionado à agenda com sucesso',
  feedUnavailable: 'O feedback só estará disponível após o atendimento ser realizado',

  got_it: 'Entendi',
  apple_subscribe_error:
    'Aconteceu um erro ao efetuar a assinatura. Favor entrar em contato com o suporte.',
  apple_cancel_message:
    'Para cancelamento de assinaturas feitas em dispositivos Apple, é necessário que seja realizado no gerenciamento de assinaturas do AppStore do seu dispositivo.',
  politica_de_privacidade: 'Política de privacidade',
  termos_de_uso: 'Termos de uso',
  terms_and_privacy: 'Termos de uso e privacidade',
  difficulty_level: 'Treino por dificuldade',
  beginner: 'Iniciante',
  intermediate: 'Intermediário',
  advanced: 'Avançado',
  professional_orientation: 'Orientação profissional',
  hire_professional: 'Contrate um profissional',
  hire_professionals: 'Contrate profissionais',
  hire_professionals_message:
    'Você pode realizar orientações com diversos profissionais da saúde através do teleatendimento na área de “profissionais”',
  news: 'Novidade',
  welcome_namu_professional_area: 'Boas-vindas a área de profissionais da Namu!',
  welcome_namu_professional_orientation_message:
    'Aqui é possível realizar orientações com diversos profissionais de saúde. Preparamos um guia para te auxiliar na navegação, vamos lá?',
  professional_attendance: 'Atendimento\ncom profissionais',
  professional_assistance: 'Ajuda profissional',
  receive_up_to_min_teleorientation: 'Receba até 20 min de orientações com profissionais',
  orientation: 'Orientação',
  credit_will_be_reversed: 'Crédito será estornado',
  professional_will_not_be_able_to_attend:
    'O profissional não pôde comparecer. Pedimos desculpas pelo transtorno e seu crédito será estornado em alguns instantes.',
  orientation_finished: 'Orientação encerrada',
  time_waiting_limit_10_minutes:
    'O tempo limite de espera é de 10 minutos após o horário da orientação, após esse horário não é mais possível ingressar na chamada.',
  online_attendance: 'Faça atendimentos online com\nprofissionais especialistas',
  professional_hire: 'Contratar Profissional',
  mark_attendance: 'Marcar teleorientação',
  value_with_discount: 'Valor com desconto para Namu Business',
  value_with_comission: 'Valor com comissão Namu 10%',
  valueForNamuBusiness: 'Valor para Namu Business',
  finalValueForNamuBusiness: 'Valor final para Namu Business',
  namuBusinessClientDiscount20: 'Desconto para Namu Business 20% + Comissão Namu 10%',
  comission_namu: 'Comissão namu 10%',
  prefix: 'R$',

  // widgetScreen
  main_page_content: 'Conteúdos da Página Inicial',
  manage_your_content: 'Gerencie seus conteúdos',
  //quiz
  find_a_pro: 'Encontre um profissional',
  nominate_professional: 'Profissionais indicados',

  //professional
  service_value: 'Valor do atendimento',
  package: 'Pacote',
  Myattendance: 'Meus Atendimentos',
  attendance: 'Atendimento',
  telemedicine: 'Telemedicina',
  realize_medical_consultations: 'Realize consultas médicas online',
  realize_attendances_with_professionals: 'Realize atendimentos com profissionais especialistas',
  experience: 'Experiência',
  see_more: 'ver mais',
  see_less: 'ver menos',
  no_ratings_yet: 'Profissional ainda não possui avaliações.',
  professional_indicates: 'Profissionais indicados',
  namu_professional: 'Profissional Namu',
  // professional screen
  professionals: 'Profissionais',
  last_attendance: 'Último atendimento',
  next_attendance: 'Próximo atendimento',
  favorites_professionals: 'Profissionais favoritos',
  professional_by_area: 'Profissionais por área',
  no_attendance: 'Você não possui nenhum atendimento',
  namu_partner: 'Parceiro Namu',
  next_orientation: 'Próxima orientação',
  orientation_history: 'Histórico de orientações',
  orientation_history_br: 'Histórico de\norientações',
  credit_purchases: 'Compras de créditos',
  credit_purchases_br: 'Compras de\ncréditos',
  credits_for_orientations: 'Créditos para orientações',
  personal: 'Pessoal',
  corporative: 'Corporativo',
  shared: 'Compartilhados',

  //Loading
  searchText: 'Estamos selecionando os profissionais ideias pra você',

  //ProfileUserProfissional
  rating: 'Avaliações',
  bio: 'Bio',
  aboutMe: 'Sobre mim',
  specialties: 'Especialidades',
  specialtiesProfile: 'Experiência com',
  languages: 'Idiomas',
  my_articles: 'Meus artigos',
  next_hour: 'Próximo horário',
  qualification: 'Formação',
  rescheduling_and_cancellation: 'Politica de remarcação e cancelamento',
  schedule: 'Agendar',
  found_professionals: 'Profissionais encontrados',
  professional_online: 'profissionais encontrados',
  single_professional_online: 'profissional encontrado',
  start_chat: 'Iniciar chat',
  filters: 'Filtros',
  view_full_profile: 'Ver perfil completo',
  professional_area: 'Área do profissional',
  order_of_result: 'Ordem dos resultados',
  rewards: 'Recompensas',
  see_more_rewards: 'Veja mais',
  last_redeems: 'Últimos resgates',
  more_redeems: 'Mais resgatados',
  confirm_redeem: 'Confirmar resgate',
  shipping_price: 'Frete',
  call_online_with_expert_professionals:
    'Faça atendimentos online com profissionais especialistas ',
  schedule_now: 'Agendar agora',
  professional_service: 'Orientações com profissionais',
  professional_tip: 'Dica do seu Coach',
  keep_reading: 'Continue lendo',
  claim_now: 'Resgate agora',
  rewards_balance: 'Saldo de recompensas',
  service_start_in: 'Seu atendimento começa em',
  service_details: 'Detalhes do atendimento',
  service_tips: 'Dicas para o atendimento',
  service_on_the_time: 'Hora do seu atendimento',
  reading_tip: 'Dica de leitura',
  content_suggestions: 'Sugestões de conteúdo',

  // my attendence screen
  reschedule_service: 'Remarcar',
  done: 'Realizados',
  services_schedule: 'Próximos atendimentos',

  //Custom Search
  noResultsSearch:
    'Infelizmente não foram encontrados resultados referentes à sua busca. Mas não desanime, altere a sua busca e encontre profissionais',
  newSearch: 'Nova Busca',
  changeSearch: 'Alterar busca',
  //anamnese
  remaining_chars: 'caracteres restantes',
  fill_later: 'Preencher mais tarde',
  anamnese_title: 'Anamnese',
  // bottomSheet scheduling
  service_type: 'Tipo de consulta',
  attendance_unique: 'Atendimento Único',
  attendance_package: 'Pacote {{package}} Atendimentos',
  off: '{{discount}}% desconto',
  scheduling_confirmation: 'Confirmação de agendamento',

  // bottomsheet select hour
  choose_hour: 'Escolha seu horário',
  choose_your_interest_period: 'Escolha o período de interesse',

  // bottomsheet confirm payment
  payment_confirmation: 'Confirmação de pagamento',
  confirm_payment: 'Confirmar pagamento',

  // bottomsheet error
  error_title: 'Ops, ocorreu um erro ao\nefetuar o seu pagamento',
  errors_reported: 'Erros reportados:',
  correct_entered_data: 'Corrigir dados',

  // bottomsheet summary
  summary_sheet_title: 'Parabéns você marcou o seu\n atendimento Namu!',
  orientation_scheduled_with: 'Orientação marcada com',
  my_schedules: 'Ir para meus atendimentos',
  my_orientations: 'Ir para minhas orientações',
  fill_amnese: 'Preencher anmanese',
  release_profile: 'Liberar informações do Perfil',

  // namu staff schedulling
  failed_to_schedule: 'Erro ao agendar',
  not_available_anymore: 'O horário selecionado não está mais disponível.',
  no_funds: 'Créditos insuficientes.',
  schedule_error: 'Ocorreu um erro ao agendar, tente novamente mais tarde.',

  // summary scheduling
  summary_scheduling: 'Resumo do agendamento',
  valid_thru_placeHolder: '00/AA',
  security_placeHolder: 'CVV',
  cpf_placeHolder: '000.000.000-00',
  unique_service: 'Atendimento avulso',
  program_in_calendar: 'Programar na agenda',
  schedule_do: 'Realizar agendamento',
  schedule_no_data_message: 'Adicione aulas em sua agenda e comece a programar sua rotina.',
  schedule_no_data_title: 'Vai ficar parado hoje?',
  namu_business_discount: 'Desconto Namu Business',
  see_gyms_with_free_checkin: 'Veja as academias com o primeiro check-in grátis',

  payment_error: 'Erro',
  payment_erro_generic: 'Ocorreu um erro ao efetuar o pagamento. Tente novamente.',
  payment_credit_card_error_message:
    'Ocorreu um erro com seu cartão de crédito. Verifique os dados do cartão e tente novamente.',
  payment_transaction_error_message:
    'Ocorreu um problema ao processar seu pagamento. Tente novamente.',
  cpf_invalido: 'CPF inválido. Por favor verifique.',
  // list cards
  my_cards: 'Meus cartões',
  cards: 'Cartões',
  add_new_payment_type: 'Adicionar novo cartão',

  //  edit credit card
  edit_card: 'Editar Cartão',
  delete_card: 'Excluir Cartão',

  //Chat
  newMessage: 'Nova Mensagem...',
  complete_profile: 'Perfil Completo',
  picturesVideos: 'Fotos e Vídeos',
  documents: 'Documentos',
  reportAbuse: 'Reportar abuso',
  silenceUser: 'Silenciar Usuário',

  //report
  title_report: 'Reportar Abuso',
  type_abuse: 'Tipo de abuso',
  report_problem: 'Descrição do problema',
  report: 'Reportar',
  spam: 'Spam ou conteúdo indesejado',
  phishing: 'Fraude, Phishing e outras práticas enganosas',
  harassment: 'Conteúdo que represente assédio e discurso de ódio',
  content_sexual: 'Conteúdo sexual indesejado',

  //New Message
  sucess: 'Mensagem enviada com sucesso!',
  preTimeDescription: 'Sua mensagem foi enviada para Paula. O tempo de resposta estimado é de ',
  timeDescription: '20 minutos.',
  okUnderstending: 'Ok, entendi',

  // feed screen
  feed: 'Feed',
  search_publish: 'Buscar publicação',

  // bottomsheet report post
  //report: 'Denunciar',
  report_subtitle: 'Porque você está denunciando essa publicação?',
  report_option_spam: 'Spam ou conteúdo indesejado',
  report_option_phising: 'Fraude, phishing e outras práticas enganosas',
  report_option_hate_speech: 'Conteúdo que represente assédio e discurso de ódio',
  report_option_sex_content: 'Conteúdo sexual indesejado',

  // bottomsheet silence post
  silence: 'Silenciar',
  silence_subtitle:
    ' É possível reativar os essa pessoa dentro de configurações > perfils silenciados.',
  silence_option_group: 'Silenciar dentro do Grupo',
  silence_option_all: 'Silenciar todos os comentários',

  // filter post screen
  filter_title: 'Filtros',
  filter_clean: 'Limpar',

  //call service
  time: 'Tempo',
  call_service_time: 'Tempo de atendimento',
  waiting_pro: 'O profissional ainda não entrou, aguarde alguns instantes',
  chat_notes: 'Anotações',
  // bottomsheet need more time professional.
  more_time_end_call: 'Preciso de mais tempo para finalizar o atendimento com o cliente',
  request: 'Solicitar',
  client_requested_time:
    'Cliente solicitou mais tempo para finalizar esse atendimento.\nVocê está de acordo?',
  approve: 'Aprovar',
  deny: 'Recusar',
  minutes_popup: 'min',
  increase_time: 'Acréscimo de',

  disconnected_pro: 'O profissional desconectou da sala, aguardando retorno...',

  professional_not_came: 'O profissional não compareceu no tempo previsto',
  waiting_professional: 'O profissional ainda não entrou, aguarde alguns instantes.',
  waiting_time: 'Tempo restante de espera',
  check_cancel_policy: 'Entenda as políticas de cancelamento',
  request_more_time: 'Solicitar mais tempo de consulta',

  write_comment_about_professional: 'Escreva um comentário sobre o profissional..',

  //popups
  msgAlert: 'Alerta',
  msgAttention: 'Atenção',
  msgAlertEnterRoom: 'A sala é liberada apenas',
  msgAlertEnterRoomOrientationComplete: 'minutos antes da orientação, tente novamente mais tarde.',
  msgAlertEnterRoomComplete: 'minutos antes do atendimento, tente novamente mais tarde.',
  msgChangeHour: 'Não é possível alterar ou cancelar antes de',
  msgChangeHourComplete: 'horas do atendimento',
  politicsRemark: 'Entenda as políticas de remarcação',
  seeAtendiments: 'Ver meus atendimentos',
  remarkDescription:
    'Para remarcação em um intervalo de tempo menor que 48hrs é necessário a aprovação do profissional.',
  remarkTitle: 'Atendimento remarcado com sucesso',
  solicitateTitle: 'Solicitação enviada com sucesso',
  solicitateDescription: 'Aguarde a confirmação do profissional.',
  ConfirmRemarcation: 'Deseja enviar a solicitação de remarcação?',
  remarcationDescription:
    'Para remarcação em um intervalo de tempo menor que 48hrs é necessário a aprovação do profissional.',
  remarcationTitle: 'A remarcação só está liberada com 48hrs de antecedência da consulta.',
  confirmCancelation: 'Deseja cancelar a consulta?',
  cancelationDescription:
    'Realizando o cancelamento o valor pago pela consulta não será estornado.',
  cancelationTitle: 'O cancelamento só está liberado com 48hrs de antecedência da consulta.',
  chooseHour: 'Escolha seu horário',
  rememberTitle: 'Lembrete do seu Coach',
  startChat: 'Iniciar Chat',

  //Rewards
  rescue: 'Resgatar',

  without_next_schedule: 'Sem próximo horário',
  without_free_time: 'O Profissional não tem horário disponível nesse dia',
  free: 'Grátis',
  my_credits: 'Meus Créditos',
  shared_credits: 'Créditos compartilhados',
  exhausted_credits: 'Créditos esgotados',
  exhausted_credits_message:
    'Você não possui créditos para orientação. Adquira mais créditos para realizar o agendamento.',
  usage_limit_reached: 'Limite de uso atingido',
  monthly_credits_reached: 'Seu limite de uso de créditos mensal foi atingido.',
  credit: 'Crédito',
  credits: 'Créditos',
  get_credits: 'Adquira créditos',
  select_bundle: 'Selecione o pacote',
  continue_purchase: 'Continuar compra',
  order: 'Pedido',
  bundle: 'Pacote',
  bundle_x_credits: 'Pacote {{credits}} créditos',
  bundle_x_credit: 'Pacote {{credits}} crédito',
  you_can_reschedule_or_cancel_message:
    'Você poderá remarcar ou cancelar sua orientação apenas 12 horas antes da sessão.',

  credit_can_be_used_message_for_credit:
    'Os créditos só podem ser utilizados para orientações até {{minutes}} minutos. Serão consumidos apenas se os créditos corporativos estiverem esgotados.',
  credit_can_be_used_message_for_pool:
    'Os créditos só podem ser utilizados para orientações até {{minutes}} minutos. São individuais e serão consumidos apenas se os créditos compartilhados estiverem esgotados.',
  credit_can_be_used_message:
    'Os créditos só podem ser utilizados para orientações até {{minutes}} minutos.',
  shared_credits_message:
    'A empresa {{business}} disponibiliza {{credits}} créditos por {{interval}} para orientações até {{minutes}} minutos com profissionais da Namu Orienta.',
  shared_credits_message_pool:
    'A empresa {{business}} oferece um limite de {{credits}} créditos por mês para orientações até {{minutes}} minutos com profissionais da Namu Orienta.\n\nOs créditos são compartilhados com os demais colaboradores da {{business}}, o limite de uso é individual, portanto utilize com responsabilidade. ',
  shared_credits_message_pool_without_limit:
    'A empresa {{business}} oferece créditos compartilhados para orientações até {{minutes}} minutos com profissionais da Namu Orienta.\n\nOs créditos são compartilhados com os demais colaboradores da {{business}}, portanto utilize com responsabilidade.',
  orientation_time_message:
    'Utilize seus créditos para orientações até {{time}} minutos com profissionais Namu',
  select_orientation_area: 'Selecione a área de orientação',
  your_credits_are_available_for_user: 'Seus créditos já estão disponíveis para uso',
  purchase_done_successfully: 'Compra concluída com sucesso!',

  session_unavailable: 'Esse atendimento não está mais disponível.',
  session_hangup_alert: 'Realmente deseja sair do atendimento? A chamada será finalizada.',

  self_video_disabled: 'Seu video está desativado',
  user_video_disabled: 'Video do profissional está desativado',

  you_have: 'Você possui',
  to_attendance_min: 'para atendimentos de até',
  this_month: 'este mês',
  youWish: 'Você deseja?',
  reschedule_session: 'Reagendar atendimento',
  cancel_session: 'Cancelar atendimento',
  wouldLikeCofirmSessionCancel: 'Gostaria de confirmar o seu cancelamento?',
  cancelConfirmText:
    'Após a confirmação do cancelamento, seu pagamento será estornado em seu cartão.',

  missedCanceled: 'Cancelados / Expirados',
  reschedule_session_confirm: 'Confirmar novo horário',
  want_cancel_session: 'Quero cancelar o atendimento',

  session_updated: 'Atendimento remarcado com sucesso',
  get_it_call: 'Entrar na sala',

  currency_type: 'R$',

  no_academic_history: 'Profissional não cadastrou nenhuma formação.',
  category_select: 'Selecione a categoria',
  subcategory_select: 'Selecione uma subcategoria',
  feeding: 'Alimentação',
  filter_result: 'Resultado do filtro',
  saved_filters: 'Filtros salvos',
  bodyparts: 'Partes do corpo',

  session_permissions_alert:
    'É necessário permissão de acesso a Camera e Microfone para entrar em uma video chamada. Por favor autorize em suas configurações.',
  finish: 'Finalizar',
  waiting_time_up: 'Tempo de espera esgotado!',
  access_course: 'Acessar curso',
  begin_course: 'Iniciar curso',
  hire_a_partner: 'Contrate um parceiro',
  talk_to_manu: 'Conversar com a Manu',
  manu_chatgpt: 'Manu + ChatGPT',
  manuchat_widget_message:
    'Manu com inteligência artificial para te ajudar a esclarecer dúvidas relevantes sobre saúde.',
  lets_talk: 'Vamos Conversar',
  lets_go: 'Vamos lá!',
  manu_label: 'Manu',
  manu_message: 'Sou a Manu, sua assistente virtual.\nSe precisar de ajuda é só me chamar!',
  open_chat_error: 'Não foi possível abrir o chat.',
  you_have_completed: 'Você concluiu {{type}}',
  good_job_lets_choose_next:
    'Ótimo trabalho! Vamos escolher qual será {{article}} próxim{{type}} da sua jornada de transformação?',
  change_gender: 'Alterar Sexo',
  change: 'Alterar',
  change_date_of_birth: 'Alterar data de nascimento',
  birthdate: 'Data de nascimento',
  gender: 'Sexo',

  business_accept_plan_alert_title: 'Aviso',
  business_accept_plan_alert_message:
    'Obrigada por aceitar o convite. O app vai reiniciar e, em seguida, tudo estará pronto pra você 🤩',
  business_accept_plan_alert_button: 'Vamos nessa!',
};

export default pt;
