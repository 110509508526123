import React from 'react';

import { View, StyleSheet, TouchableOpacity, Image, Text } from 'react-native';
import chevronLeft from 'app/images/chevronLeft.png';
import colors from 'app/styles/colors';
import chevronRight from 'app/images/chevronRight.png';
import textStyles from 'app/styles/textStyles';
import moment from 'moment';

const HeaderByMonth = ({
  open,
  onPress,
  month,
  canFuture = true,
  previousMonth,
  nextMonth,
  selectCategory,
}) => {
  return (
    <>
      <View style={styles.container}>
        <TouchableOpacity onPress={previousMonth}>
          <Image
            source={chevronLeft}
            style={{ tintColor: colors.darkPro2, width: 24, height: 24, resizeMode: 'contain' }}
          />
        </TouchableOpacity>

        <TouchableOpacity style={styles.labelMonth} onPress={onPress}>
          <Text style={[textStyles.bold16, { textTransform: 'uppercase', color: colors.darkPro2 }]}>
            {moment(month).format('MMMM YYYY')}
          </Text>
        </TouchableOpacity>

        {(!canFuture && !month.isSame(moment(), 'month')) || canFuture ? (
          <TouchableOpacity onPress={nextMonth}>
            <Image
              source={chevronRight}
              style={{ tintColor: colors.darkPro2, width: 24, height: 24, resizeMode: 'contain' }}
            />
          </TouchableOpacity>
        ) : (
          <View style={{ height: 24, width: 24 }} />
        )}
      </View>
    </>
  );
};

export default HeaderByMonth;

const styles = StyleSheet.create({
  labelMonth: { paddingHorizontal: 16, flexDirection: 'row', alignItems: 'center' },
  container: {
    height: 40,
    marginBottom: 20,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 16,
    // marginVertical: 5,
    justifyContent: 'space-between',
  },
  backarrow: {
    marginLeft: 5,
    transform: [{ rotateZ: '90deg' }],
    height: 10,
    marginTop: 3,
  },
});
