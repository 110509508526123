import React from 'react';

import { View, StyleSheet, Text, Image } from 'react-native';
import fonts from '../styles/fonts';
import colors from '../styles/colors';
import { translate } from '../i18n/src/locale';
import pt from '../i18n/src/locale/pt';
import afternoon from '../images/afternoon.png';

const CapsuleLaunchOrDinner = () => (
  <View style={[styles.capsule]}>
    <Image source={afternoon} />
    <Text style={[styles.capsuleText]}>{translate(pt.launch)}</Text>
  </View>
);

export default CapsuleLaunchOrDinner;

const styles = StyleSheet.create({
  capsuleText: {
    marginLeft: 4,
    fontFamily: fonts.REGULAR,
    fontSize: 12,
    opacity: 0.9,
    color: colors.brownishGreyTwo,
  },
  capsule: {
    paddingLeft: 8,
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'baseline',
    height: 21,
    paddingRight: 10,
    borderRadius: 14.5,
    marginBottom: 8,
    backgroundColor: colors.white,
  },
});
