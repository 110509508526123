import React from 'react';
import { View, Text, Image, TouchableOpacity, Dimensions, StyleSheet } from 'react-native';
import ButtonBase from '../../../../components/ButtonBase';
import colors from '../../../../styles/colors';
import closeImage from '../../../../images/closeGrey.png';
import commons from '../../../../styles/commons';
import { translate } from 'app/i18n/src/locale';

import pt from 'app/i18n/src/locale/pt';
import fonts from 'app/styles/fonts';
import { useSafeArea } from 'react-native-safe-area-context';

const { height } = Dimensions.get('window');

const BottomSheetUpdateDecision = ({ onClose, doUpdate, doCancel }) => {
  const insets = useSafeArea();

  return (
    <View style={[styles.containerBottomSheet, { paddingBottom: insets.bottom }]}>
      <View style={styles.topContainer}>
        <Text style={styles.title}>{translate(pt.youWish)}</Text>
        <TouchableOpacity onPress={onClose} hitSlop={commons.hitSlop}>
          <Image source={closeImage} style={{ width: 20, height: 20 }} />
        </TouchableOpacity>
      </View>
      <View style={styles.packageContent}>
        <View style={styles.contentContainer} />
        <ButtonBase
          title={translate(pt.reschedule_session)}
          style={styles.buttonStyle}
          onPress={() => {
            doUpdate();
          }}
        />
        <ButtonBase
          title={translate(pt.cancel_session)}
          style={styles.buttonStyleCancel}
          onPress={() => {
            doCancel();
          }}
          backgroundColor={colors.white}
          textStyle={{ color: colors.blackAlpha03 }}
        />
      </View>
    </View>
  );
};

export default BottomSheetUpdateDecision;

const styles = StyleSheet.create({
  containerBottomSheet: {
    borderTopEndRadius: 47,
    borderTopStartRadius: 47,
  },
  topContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    marginTop: 14,
  },
  title: {
    textAlign: 'center',
    flex: 0.8,
    fontFamily: fonts.BOLD,
    fontSize: 18,
  },

  buttonStyle: { width: '100%', height: height / 8 / 2, backgroundColor: colors.aquaMarine },
  buttonStyleCancel: {
    width: '100%',
    height: height / 8 / 2,
    backgroundColor: colors.white,
    borderWidth: 1,
    borderColor: colors.blackAlpha03,
  },

  contentContainer: {
    height: 'auto',
    borderRadius: 10,
    borderWidth: 0.5,
    borderColor: ' rgba(191, 243, 243, 1)',
  },
  packageContent: {
    width: '80%',
    alignSelf: 'center',
    marginTop: height / 40 / 12,
  },
});
