import React from 'react';

import { StyleSheet, Text, Image } from 'react-native';
import fonts from '../styles/fonts';
import colors from '../styles/colors';
import calendar from '../images/iconCalendar.png';
import { calcDuration } from 'app/services/utils';
import pt from 'app/i18n/src/locale/pt';
import { translate } from 'app/i18n/src/locale';
import LinearGradient from 'react-native-linear-gradient';

const CapsuleClockNew = ({ small, style, isWeek, timeIn = 's', duration, noShadow }) => {
  return (
    <LinearGradient
      useAngle={true}
      angle={90}
      colors={[colors.white + '40', colors.white + '26']}
      style={[styles.capsule, style, small && styles.main, { shadowOpacity: noShadow ? 0 : 0.5 }]}>
      <Image source={calendar} style={[small && styles.icon, { tintColor: colors.white }]} />
      <Text style={[styles.capsuleText, small && styles.font12]}>
        {isWeek
          ? translate(pt.weeks, { num: duration })
          : calcDuration(duration, { timeUnit: timeIn })}
      </Text>
    </LinearGradient>
  );
};

export default CapsuleClockNew;

const styles = StyleSheet.create({
  font12: { fontSize: 12 },
  icon: {
    height: 15,
    width: 15,
    color: colors.white,
  },
  main: { width: 83, height: 29 },
  capsuleText: {
    marginLeft: 4,
    fontFamily: fonts.REGULAR,
    fontSize: 10,
    color: colors.white,
    
  },
  capsule: {
    paddingHorizontal: 15,
    flexDirection: 'row',
    alignItems: 'center',
    height: 29,
    borderWidth: 1,
    borderColor: '#DBF5F559',
    borderRadius: 4,
  },
});
