import produce from 'immer';

const INITIAL_STATE = {
  playlistId: '',
};

export default function playlist(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@playlist/SET_ID_PLAYLIST': {
        draft.playlistId = action.payload.playlistId;
        break;
      }
    }
  });
}
