import React, { useEffect, useRef, useState } from 'react';

import {
  View,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  Image,
  Dimensions,
  Linking,
  FlatList,
  ActivityIndicator,
} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import textStyles from 'app/styles/textStyles';
import colors from 'app/styles/colors';
import HeaderWhite from 'app/components/HeaderWhite';
import commons from 'app/styles/commons';
import HeaderTitle from 'app/screens/profile/components/HeaderTitle';
import CardBlog from './components/BlogPost';
import heart from 'app/images/heart.png';
import faved from 'app/images/faved.png';
import HTML from 'react-native-render-html';
import { showAlert, errorControl, sendActionEvent } from 'app/services/utils';
import api from 'app/services/api';
import QueryString from 'qs';
import { useSelector } from 'react-redux';
import WebView from 'react-native-webview';

const InternalBlogScreen = ({ route }) => {

  const { id, blogPost, categoryId } = route?.params;

  const [isFavorited, setIsFavorited] = useState(0);
  const [recommendation, setRecommendation] = useState([]);
  const [postData, setPostData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { profile } = useSelector(state => state.user);

  useEffect(() => {
    getPostData();
  }, []);

  const getPostData = async () => {
    setIsLoading(true);
    try {
      const { data } = await api.get(`blog/posts/get-by-id?post_id=${id}`);
      setPostData(data);
      if (categoryId !== 'favorite') {
        requestPostFavorited(data);
        requestRecommendation(data);
      } else {
        setIsFavorited(true);
      }
    } catch (e) {
      showAlert(errorControl(e));
    }
    setIsLoading(false);
  };

  const favoriteUnfavorite = async () => {
    if (isFavorited === 0) {
      await requestFavoriteBlog();
    }
    if (isFavorited === 1) {
      await requestUnFavoriteBlog();
    }
  };

  const requestRecommendation = async post => {
    try {
      const response = await api.get(`blog/post/recommendation?wp_cat_id=${post.categories}`);
      setRecommendation(response.data);
    } catch (error) {
      showAlert(errorControl(error));
    }
  };

  const requestFavoriteBlog = async () => {
    try {
      await api.post(
        'blog/post/favorite',
        QueryString.stringify({
          wp_post_id: id,
          wp_cat_id: postData.categories.split(',')[0],
        })
      );
      setIsFavorited(1);
    } catch (error) {
      showAlert(errorControl(error));
    }
  };

  const requestUnFavoriteBlog = async () => {
    try {
      await api.delete('blog/post/favorite', {
        params: {
          wp_post_id: postData.id,
        },
      });
      setIsFavorited(0);
    } catch (error) {
      showAlert(errorControl(error));
    }
  };

  const requestPostFavorited = async post => {
    try {
      const response = await api.get(`blog/post/favorited?wp_post_id=${post.id}`);
      setIsFavorited(response.data);
    } catch (error) {
      showAlert(errorControl(error));
    }
  };

  const webRef = useRef();


  const handleNavigationStateChange = (navState) => {
    // The scroll position is available in navState.
    // You can access it using navState.contentOffset.y or other relevant properties.

    console.log(navState);
  };

  const [ webviewH, setWebviewH ] = useState();

  useEffect(() => {
    const interval = setInterval(() => {
      if(isLoading) return;
      const iframe = document.querySelector('iframe')?.contentDocument;
      if(!iframe) return;
      const height = iframe.querySelector('.screenHeight')?.textContent;
      if(height){
        setWebviewH(parseInt(height) + 80);
        clearInterval(interval);
      }

    }, 300);

    return () => clearInterval(interval);

  }, []);

  return (
    <View style={commons.flex}>
      <SafeAreaView style={commons.flex}>
        {isLoading ? (
          <View style={{ alignItems: 'center', justifyContent: 'center', flex: 1 }}>
            <ActivityIndicator size={'large'} color={colors.aquaMarineTwo} />
          </View>
        ) : (
          <>
            <HeaderWhite title={postData.title || postData.post_title}>
              <TouchableOpacity onPress={favoriteUnfavorite}>
                <Image source={isFavorited === 0 ? heart : faved} />
              </TouchableOpacity>
            </HeaderWhite>
            <ScrollView style={styles.scroll}>
              <Image
                source={{
                  uri: postData.image,
                  headers: {
                    Authorization: api.defaults.headers.Authorization,
                  },
                }}
                style={styles.topImage}
              />
              <View style={{...styles.topView, height: webviewH || 'auto'}}>
                <WebView
                  ref={webRef}
                  source={{ html: `
                    <style>
                      @import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,300;0,6..12,400;0,6..12,500;0,6..12,600;0,6..12,700;0,6..12,800;1,6..12,300;1,6..12,400;1,6..12,500;1,6..12,600;1,6..12,700;1,6..12,800&display=swap'); 
                      * { font-family: "Nunito Sans", sans-serif; }
                      body {
                        overflow: hidden;
                      }
                      .screenHeight{
                        display:  none;
                      }
                    </style>
                    ${postData.body_content}
                    <span class="screenHeight"></span>
                    <script>
                    setTimeout(function() { 
                      document.documentElement.querySelector('.screenHeight').textContent = document.documentElement.scrollHeight;
                    },500);
                    const anchors = document.querySelectorAll('a');
                    for(let a of anchors){
                      a.addEventListener('click', e => {
                        e.preventDefault();
  
                        window.open(e.currentTarget.href, '_blank');
                      })
                    }
                    </script>
                    ` }}
                  scalesPageToFit={true}
                  automaticallyAdjustContentInsets={false}
                  allowsInlineMediaPlayback
                  originWhitelist={['*']}
                  mediaPlaybackRequiresUserAction={false}
                  startInLoadingState
                  javaScriptEnabled
                  domStorageEnabled
                  style={{flex: 1}}
                />
                {/* <HTML
                  html={postData.body_content}
                  tagsStyles={{
                    iframe: {
                      opacity: 0.99,
                    },
                  }}
                  baseFontStyle={styles.fontText}
                  imagesMaxWidth={Dimensions.get('window').width - 48}
                  ignoredStyles={['height', 'width']}
                  onLinkPress={(object, href) => {
                    Linking.canOpenURL(href).then(supported => {
                      if (supported) {
                        Linking.openURL(href);
                      }
                    });
                  }}
                /> */}
              </View>
              {categoryId !== 'favorite' && (
                <>
                  <HeaderTitle title="Recomendados" />
                  <FlatList
                    contentContainerStyle={{ backgroundColor: colors.paleGrey }}
                    keyExtractor={item => item.id}
                    renderItem={({ item }) => {
                      return <CardBlog post={item} categoryId={categoryId} />;
                    }}
                    data={recommendation}
                    style={styles.flatList}
                  />
                </>
              )}
            </ScrollView>
          </>
        )}
      </SafeAreaView>
    </View>
  );
};

export default InternalBlogScreen;

const styles = StyleSheet.create({
  centerImage: { height: 178, backgroundColor: 'grey' },
  topImage: { height: 214, backgroundColor: 'grey' },
  firstText: { ...textStyles.regular14, marginTop: 14 },
  topView: {
    paddingVertical: 28,
    borderTopRightRadius: 24,
    zIndex: 100,
    marginTop: -24,
    backgroundColor: colors.paleGrey,
    height: 4388,
    overflow: 'hidden'
  },
  scroll: { height: 'calc(100vh - 56px)', backgroundColor: colors.paleGrey },
  flatList: { backgroundColor: colors.paleGreyThree },
  fontText: { fontSize: 16, lineHeight: 22 },
});
