import React from 'react';
import ContentLoader, { Rect } from 'react-content-loader/native';
import { Dimensions } from 'react-native';

const GymInfoLoader = props => (
  <ContentLoader
    backgroundColor={'#dddddd'}
    width={Dimensions.get('screen').width}
    height={90}
    viewBox={`0 0 ${Dimensions.get('screen').width}px 90x`}>
    <Rect x="0" y="0" rx="3" ry="3" width="110" height="90" />
    <Rect x="15" y="10" rx="4" ry="4" width="70" height="10" />
    <Rect x="15" y="26" rx="4" ry="4" width="30" height="10" />
    <Rect x="15" y="50" rx="4" ry="4" width="80" height="25" />

    <Rect x="120" y="0" rx="3" ry="3" width="110" height="90" />
    <Rect x="135" y="10" rx="4" ry="4" width="70" height="10" />
    <Rect x="135" y="26" rx="4" ry="4" width="30" height="10" />
    <Rect x="135" y="50" rx="4" ry="4" width="80" height="25" />

    <Rect x="240" y="0" rx="3" ry="3" width="110" height="90" />
    <Rect x="255" y="10" rx="4" ry="4" width="70" height="10" />
    <Rect x="255" y="26" rx="4" ry="4" width="30" height="10" />
    <Rect x="255" y="50" rx="4" ry="4" width="80" height="25" />
  </ContentLoader>
);

export default GymInfoLoader;
