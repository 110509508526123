import LoginButtonBase from 'app/components/LoginButtonBase';
import { signUpRequest } from 'app/store/modules/auth/actions';
import React, { useState, useEffect, useCallback } from 'react';
import { StyleSheet, Text, TextInput, Image, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import SecureTextInput from '../../../components/SecureTextInput';
import { translate } from '../../../i18n/src/locale';
import pt from '../../../i18n/src/locale/pt';
import colors from '../../../styles/colors';
import { errorMessage } from '../../../services/utils';
import { PROVIDER } from 'app/services/providers';
import api from 'app/services/api';
import QueryString from 'qs';
import EVENTS from 'app/configs/events';
import { sendActionEvent } from '../../../services/utils';
import fonts from 'app/styles/fonts';
import mailOutlined from 'app/images/mailOutlined.png';
import { useNavigation } from '@react-navigation/native';

const RegisterView = () => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const { loading, signed } = useSelector(state => state.auth);
  const entrance = useCallback(async () => {
    if (signed) {
      try {
        const { data: goCore } = await api.post(
          'user-ui-event/filter',
          QueryString.stringify({
            ui_event_name: EVENTS.ONBOARDING,
            status: 1,
          })
        );
        navigation.navigate(goCore ? 'Início' : 'Onboarding');
      } catch (error) {
        errorMessage(error);
      }
    }
  }, [signed]);

  useEffect(() => {
    entrance();
  }, [entrance, signed]);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = () => {
    const schema = yup.object().shape({
      email: yup
        .string()
        .email(translate(pt.fill_email))
        .max(50, translate(pt.email_to_long))
        .required(translate(pt.fill_email)),
      password: yup.string().min(8, translate(pt.password_min)).required(),
    });

    schema
      .validate({ email, password })
      .then(() => {
        dispatch(signUpRequest({ name: 'APP', email, password, provider: PROVIDER.LARAVEL }));
        sendActionEvent('create_account', { email }, email);
      })
      .catch(error => {
        errorMessage(error.message);
      });
  };

  return (
    <View style={styles.card}>
      <Text style={styles.title}>
        {translate(pt.create_your_account)}
        <Text style={{ color: colors.aquaMarineTwo }}>{' Namu'}</Text>
      </Text>
      <View style={styles.inputContainer}>
        <Image source={mailOutlined} style={styles.inputIcon} />
        <TextInput
          autoCapitalize="none"
          onChangeText={setEmail}
          value={email}
          keyboardType="email-address"
          placeholder={translate(pt.email)}
          style={[styles.input]}
        />
      </View>

      <SecureTextInput value={password} onChangeText={setPassword} />

      <LoginButtonBase
        loading={loading}
        title={translate(pt.create_account)}
        onPress={handleSubmit}
        style={styles.buttonEnter}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  buttonEnter: {
    zIndex: 1,
    width: '100%',
    justifyContent: 'center',
    height: 50,
    marginTop: 30,
  },
  inputContainer: {
    marginTop: 6,
    marginBottom: 12,
    paddingHorizontal: 14,
    height: 45,
    borderRadius: 5,
    backgroundColor: colors.white,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: colors.lightPeriwinkle,
    flexDirection: 'row',
    alignItems: 'center',
  },
  inputIcon: {
    width: 22,
    height: 22,
    resizeMode: 'contain',
  },
  input: {
    marginLeft: 10,
    flex: 1,
    color: colors.black,
    fontFamily: fonts.MEDIUM,
  },
  title: {
    fontFamily: fonts.BOLD,
    fontSize: 18,
    color: '#444242',
    textAlign: 'center',
    marginBottom: 25,
  },
  card: {
    marginHorizontal: 16,
    width: '100%',
    paddingVertical: 24,
    paddingHorizontal: 37,
  },
});

export default RegisterView;
