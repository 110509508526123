import colors from 'app/styles/colors';
import fonts from 'app/styles/fonts';
/* eslint-disable react-native/no-inline-styles */
import React, { useRef, useState } from 'react';
// import { Actions } from 'react-native-router-flux';

import { View, StyleSheet, Alert } from 'react-native';
import ShadowView from '@liuyunjs/react-native-simple-shadow-view';

import { translate } from '../../../../i18n/src/locale';
import pt from '../../../../i18n/src/locale/pt';
import ButtonBaseWhite from '../../../../components/ButtonBaseWhite';
import { useEffect } from 'react';
import apiNode from 'app/services/apiNode';
import moment from 'moment';
import { useSelector } from 'react-redux';
import ProfessionalData from 'app/screens/core/professionalFilterResult/components/ProfessionalData';
// import RBSheet from 'react-native-raw-bottom-sheet';
import BottomSheetUpdateDecision from './BottomSheetUpdateDecision';
import BottomSheetConfirmCancel from 'app/screens/core/professional/components/BottomSheetConfirmCancel';
import BottomSheetUpdateTime from 'app/screens/core/professional/components/BottomSheetUpdateTime';
import BottomSheetConfirmUpdate from 'app/screens/core/professional/components/BottomSheetConfirmUpdate';
import ButtonAttachments from 'app/screens/core/home/components/ButtonAttachments';
import useGlobalAlert from 'app/hooks/useGlobalAlert';
// import { Camera } from 'react-native-vision-camera';
import { useNavigation } from '@react-navigation/native';
import BottomModal from '../../explorer/components/BottomModal';

const NextAttendanceCard = ({ done, onClose, onOpen, item, reload, style }) => {
  const navigation = useNavigation();
  const [ refUpdateDecisionSheet, setRefUpdateDecisionSheet ] = useState(false);
  const [ refCancelSheet, setRefCancelSheet ] = useState(false);
  const [ refUpdateTimeSheet, setRefUpdateTimeSheet ] = useState(false);
  const [ refConfirmUpdateSheet, setRefConfirmUpdateSheet ] = useState(false);

  const [, setFavorite] = useState(false);
  const [, setFavoriteId] = useState();
  const [userPro, setUserPro] = useState([]);
  const [categoryIconUrl, setCategoryIconUrl] = useState('');
  const [, setSessionsMinutes] = useState('');
  const [, setDate] = useState('');
  const { profile } = useSelector(state => state.user);

  const [disableUpdated, setDisableUpdated] = useState(false);

  const [hoursUpdateSession] = useState(12);
  const [hoursEnterSession] = useState(-5);

  const [, setRatings] = useState(null);

  const { setAlert } = useGlobalAlert();

  useEffect(() => {
    if (item.sessionDate && hoursUpdateSession) {
      setDisableUpdated(moment(item.sessionDate).subtract(hoursUpdateSession, 'hours') < moment());
    }
  }, [item, hoursUpdateSession]);

  const disableGetIn = () => {
    if (item.sessionDate && hoursEnterSession) {
      const difference = moment.duration(moment().diff(moment(item.sessionDate))).asMinutes();
      return difference <= hoursEnterSession;
    }
    return false;
  };

  const waitingTimeIsUp = () => {
    if (item.sessionDate) {
      const difference = moment
        .duration(moment().diff(moment(item.sessionDate).add('1', 'minutes')))
        .asMinutes();
      return difference >= 0;
    }
    return false;
  };

  useEffect(() => {
    if (profile && item) {
      getUserProInfos();

      if (item?.sessionFeedbacks?.length > 0) {
        const toUserProRating = item.sessionFeedbacks.find(sf => sf.toUser === false);
        if (toUserProRating) {
          setRatings(toUserProRating.ratingStars);
        }
      }
    }
  }, [item, profile]);

  const getUserProInfos = async () => {
    const { data } = await apiNode.get(`/users-pro/${item.userProId}?userId=${profile.user_id}`);
    const { categories } = data;
    categories ? setCategoryIconUrl(categories[0].professionalCategory.iconUrl) : '';
    setUserPro(data);
    setSessionsMinutes(item.sessionDurationMinutes);
    setFavorite(data.userFavorite.favorited);
    setFavoriteId(data.userFavorite.id);
    setDate(moment(item.sessionDate));
  };

  const joinRoom = async () => {
    // await Camera.requestCameraPermission();
    // await Camera.requestMicrophonePermission();
    console.log('JOINING')
    navigation.navigate('CallService', { sessionId: item.id })
  }

  return (
    <View style={[styles.container, style]}>
      <ShadowView style={[styles.professionalCard]}>
        <View style={styles.containerCard}>
          <ProfessionalData
            list
            userProId={userPro.id}
            schedule={item}
            textColor={colors.white}
            userProRatings={null}
            hideFavoriteButton
            linear={true}
            hideProfessionalNumber
          />
          {moment(item.sessionDate).add(item.sessionDurationMinutes, 'minutes') > moment() && (
            <View>
              <View style={styles.buttonContainer}>
                {!disableUpdated ? (
                  <>
                    <ButtonBaseWhite
                      style={styles.buttonOutlinedStyle}
                      onPress={() => {
                        !disableUpdated === false
                          ? Alert.alert(
                              translate(pt.msgAlert),
                              `${translate(pt.msgChangeHour)} ${hoursUpdateSession} ${translate(
                                pt.msgChangeHourComplete
                              )}`
                            )
                          : setRefUpdateDecisionSheet(true)
                      }}
                      square
                      color={colors.white}
                      title={'Remarcar'}
                    />
                    <ButtonAttachments
                      style={[
                        styles.buttonAttachStyle,
                        { borderWidth: 0, backgroundColor: colors.white },
                      ]}
                      textStyle={{ color: colors.aquaMarineTwo }}
                      userProId={item.userProId}
                    />
                  </>
                ) : (
                  <>
                    <ButtonAttachments
                      style={styles.buttonAttachStyle}
                      textStyle={{ color: colors.white }}
                      userProId={item.userProId}
                    />
                    <ButtonBaseWhite
                      style={styles.buttonStyle}
                      onPress={() =>
                        disableGetIn() === true
                          ? waitingTimeIsUp()
                            ? (console.log('aqui1'),setAlert(
                                translate(pt.orientation_finished),
                                translate(pt.time_waiting_limit_10_minutes)
                              ))
                            : (console.log('aqui2'),setAlert(
                                translate(pt.msgAttention),
                                `${translate(pt.msgAlertEnterRoom)} ${Math.abs(
                                  hoursEnterSession
                                )} ${translate(
                                  userPro.namuStaff
                                    ? pt.msgAlertEnterRoomOrientationComplete
                                    : pt.msgAlertEnterRoomComplete
                                )}`
                              ))
                          : joinRoom()
                      }
                      color={colors.aquaMarineTwo}
                      title={'Entrar na sala'}
                    />
                  </>
                )}
              </View>
            </View>
          )}
        </View>
      </ShadowView>
      <BottomModal open={refUpdateDecisionSheet} onClose={() => setRefUpdateDecisionSheet(false)}>
        <BottomSheetUpdateDecision
          onClose={() => {
            setRefUpdateDecisionSheet(false)
          }}
          doCancel={() => {
            setRefUpdateDecisionSheet(false)
            setTimeout(() => {
              setRefCancelSheet(true);
            }, 500);
          }}
          doUpdate={() => {
            setRefUpdateDecisionSheet(false)
            setTimeout(() => {
              setRefUpdateTimeSheet(true);
            }, 500);
          }}
        />
      </BottomModal>
      <BottomModal open={refCancelSheet} onClose={() => setRefCancelSheet(false)}>
        <BottomSheetConfirmCancel
          userPro={userPro}
          sessionId={item.id}
          onClose={() => {
            setRefCancelSheet(false)
          }}
          reload={reload}
        />
      </BottomModal>
      <BottomModal open={refUpdateTimeSheet} onClose={() => setRefUpdateTimeSheet(false)}>
        <BottomSheetUpdateTime
          userProId={userPro.id}
          onClose={() => {
            setRefUpdateTimeSheet(false)
          }}
          doCancel={() => {
            setRefUpdateTimeSheet(false)
            setTimeout(() => {
              refCancelSheet.current.open();
            }, 500);
          }}
          doUpdate={() => {
            setRefUpdateTimeSheet(false)
            setTimeout(() => {
              setRefConfirmUpdateSheet(true);
            }, 500);
          }}
          sessionId={item.id}
        />
      </BottomModal>
      <BottomModal open={refConfirmUpdateSheet} onClose={() => setRefConfirmUpdateSheet(false)}>
        <BottomSheetConfirmUpdate
          userPro={userPro}
          sessionId={item.id}
          onClose={() => {
            !!reload && reload();
            setRefConfirmUpdateSheet(false);
          }}
          categoryIconUrl={categoryIconUrl}
        />
      </BottomModal>
    </View>
  );
};

export default NextAttendanceCard;

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    shadowColor: colors.black,
    shadowOpacity: 0.1,
    shadowRadius: 5,
    shadowOffset: { width: 0, height: 5 },
  },
  professionalCard: {
    backgroundColor: '#4DCCCD',
    marginTop: 4,
    borderRadius: 15,
    shadowColor: colors.blackTwoAlpha01,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowRadius: 6,
    shadowOpacity: 0.06,
    paddingHorizontal: 20,
  },
  containerCard: {
    paddingBottom: 20,
    marginTop: 10,
  },
  topContentCard: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 16,
    marginVertical: 10,
  },
  topMiddleContent: {
    flexDirection: 'column',
    alignSelf: 'center',
    justifyContent: 'space-between',
    minHeight: 45,
    flex: 1,
  },
  professionalNameStyle: {
    fontSize: 16,
    fontFamily: fonts.MEDIUM,
    color: colors.blackTwo,
    marginStart: 4,
  },
  photoStyle: {
    resizeMode: 'contain',
    height: 71,
    width: 74,
    borderRadius: 50,
  },
  divisor: {
    width: '100%',
    height: 1,
    backgroundColor: colors.greyBlue,
    opacity: 0.2,
    alignSelf: 'center',
  },
  timeAndSpecialtyContainer: {
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'space-around',
    width: '100%',
    alignSelf: 'center',
    marginTop: 16,
  },
  specialtyTextStyle: {
    color: colors.ligthGray,
    fontSize: 10,
    fontFamily: fonts.REGULAR,
  },
  credentialStyle: {
    fontFamily: fonts.BOLD,
    color: colors.aquaMarineTwo,
  },
  locationStyle: {
    color: colors.ligthGray,
    fontFamily: fonts.REGULAR,
    fontSize: 10,
    lineHeight: 18,
  },
  containerTime: {
    flexDirection: 'row',
    backgroundColor: colors.whiteTwo,
    maxHeight: 30,
    borderRadius: 18.15,
    padding: 8,
    alignItems: 'center',
    alignSelf: 'center',
  },
  clockStyle: {
    width: 14.4,
    height: 14.4,
    resizeMode: 'contain',
  },
  categoryAndHourContainer: {
    borderWidth: 1,
    borderRadius: 9,
    padding: 8,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 16,
    shadowColor: colors.shadowBlue,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.18,
    elevation: 40,
  },
  textHourStyle: {
    marginStart: 5,
    fontSize: 15,
    fontFamily: fonts.BOLD,
  },
  categoryBlock: {
    backgroundColor: colors.shadowBlue,
    borderRadius: 50,
    padding: 8,
  },
  categoryImageStyle: {
    width: 23.48,
    height: 22,
    resizeMode: 'contain',
    tintColor: colors.aquaMarineTwo,
  },
  buttonContainer: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    paddingTop: 15,
  },
  buttonStyle: {
    maxWidth: '47%',
    minWidth: '47%',
    paddingHorizontal: 0,
    borderWidth: 0,
    backgroundColor: colors.white,
    borderRadius: 10,
  },
  buttonAttachStyle: {
    maxWidth: '47%',
    minWidth: '47%',
    borderRadius: 10,
    borderColor: colors.white,
    paddingVertical: 0,
    height: 40,
  },
  buttonOutlinedStyle: {
    maxWidth: '47%',
    minWidth: '47%',
    paddingHorizontal: 0,
    borderWidth: 0,
    backgroundColor: colors.transparent,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: colors.white,
  },
  clockTextStyle: {
    fontFamily: fonts.LIGHT,
    color: colors.brownishGreyTwo,
    fontSize: 12,
  },
});
