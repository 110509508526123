import React from 'react';

import { View, StyleSheet, Text, Image } from 'react-native';
import colors from '../../../../styles/colors';
import fonts from '../../../../styles/fonts';
import textStyles from '../../../../styles/textStyles';
import Strong from '../../../../components/Strong';
import ballFoodOpaque from '../../../../images/ballFoodOpaque.png';

const CardFood = ({ kcal, dishesCount, hasRestriction }) => {
  return (
    <View style={styles.container}>
      <Image source={ballFoodOpaque} style={styles.icon} />

      <Text style={textStyles.semi16}>
        <Strong style={styles.title}>{`${kcal.slice(0, -3)} `}</Strong>
        calorias diárias
      </Text>
      <View style={styles.line} />
      <Text style={textStyles.regular16}>
        <Strong>{` ${dishesCount} `}</Strong>
        pratos selecionados
      </Text>
    </View>
  );
};

export default CardFood;

const styles = StyleSheet.create({
  icon: {
    position: 'absolute',

    top: 20,
    right: 20,
  },
  line: {
    marginVertical: 20,
    height: StyleSheet.hairlineWidth,
    backgroundColor: colors.veryLightPink,
  },
  title: {
    fontSize: 22,
    fontFamily: fonts.BOLD,
    color: colors.tomato,
  },
  container: {
    padding: 18,
    borderRadius: 3,
    backgroundColor: colors.white,
  },
});
