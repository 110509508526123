import React, { useEffect, useState } from 'react';
import {
  View,
  StyleSheet,
  Image,
  SafeAreaView,
  ScrollView,
  Text,
  Modal,
  TouchableOpacity,
  RefreshControl,
} from 'react-native';
import { translate } from 'app/i18n/src/locale';
import pt from 'app/i18n/src/locale/pt';
import colors from 'app/styles/colors';

import HeaderAquamarine from 'app/components/HeaderAquamarine';
import EmptyData from 'app/screens/core/professional/components/EmptyData';
import { errorControl, formatMoneyBR } from 'app/services/utils';
import api from 'app/services/api';
import ShadowView from '@liuyunjs/react-native-simple-shadow-view';
import NamuCoin from 'app/images/namu_coin.svg';
import SupportIcon from 'app/images/support_balloon.svg';
import commons from 'app/styles/commons';
import textStyles from 'app/styles/textStyles';
import ButtonShadow from 'app/components/ButtonShadow';
import GetCreditsModal from 'app/screens/core/professional/components/GetCreditsModal';
import { useSafeArea } from 'react-native-safe-area-context';
import HeaderByMonth from 'app/screens/core/mood/components/HeaderByMonth';
import moment from 'moment';
import { useNavigation } from '@react-navigation/native';
import { showAlert } from '../../../../services/utils';

const CreditPurchases = ({
}) => {

  const navigation = useNavigation();

  const insets = useSafeArea();

  const [ loading, setLoading ] = useState(false);
  const [ getCreditsModal, setGetCreditsModal ] = useState(false);
  const [ purchases, setPurchases ] = useState([]);
  const [ currentMonth, setCurrentMonth ] = useState(moment());

  useEffect(()=>{
    getPurchases();
  }, [currentMonth]);



  const getPurchases = async () => {
    setLoading(true);
    try{
      const { data } = await api.get(`/credits/purchases?month=${currentMonth.format('M')}&year=${currentMonth.format('YYYY')}`);
      setPurchases(data);
    }catch(error){
      console.log('PURCHASES', error, errorControl(error));
    }
    setLoading(false);
  }

  const nextMonth = () => {
    setCurrentMonth(moment(currentMonth).add(1, 'month'));
  };

  const previousMonth = () => {
    setCurrentMonth(moment(currentMonth).subtract(1, 'month'));
  };

  const renderPurchases = () => {
    return purchases.map((purchase) => {
      return (
        <ShadowView style={styles.purchaseShadowContainer}>
          <View style={styles.purchaseContainer}>
            <Image source={NamuCoin} style={{marginRight: 12, width: 47, height: 47}}/>
            <View style={{flex: 1}}>
              <View style={commons.flexRowBetween}>
                <Text style={[textStyles.regular12grey, { color: colors.greyBlue}]}>{moment(purchase.created_at).format('D MMM YYYY')}</Text>
                <Text style={[textStyles.regular12grey, { color: colors.greyBlue}]}>{translate(pt.order)} #{purchase.id}</Text>
              </View>
              <View style={[commons.flexRowBetween, commons.alignEnd]}>
                <View>
                  <Text style={[textStyles.medium16, { color: colors.darkPro}]}>{
                    purchase.recurrent ?
                    translate(pt.premium_plan_credit)
                    :
                    (translate(purchase.amount === 1 ? pt.bundle_x_credit : pt.bundle_x_credits, { credits: purchase.amount}))
                    }</Text>
                  <Text style={[textStyles.medium14, { color: colors.grayText}]}>{purchase.expire_at ? 'Válido até ' + moment(purchase.expire_at).format('D [de] MMM [de] YYYY') : formatMoneyBR(purchase.price / 100)}</Text>
                </View>
                <TouchableOpacity onPress={() => navigation.navigate('Suggestion')}>
                  <Image source={SupportIcon} style={{width: 16, height: 16}} />
                </TouchableOpacity>
              </View>
            </View>

          </View>
        </ShadowView>
      );
    });
  }

  return (
    <View
      style={{
        height: '100vh',
        backgroundColor: colors.paleGrey,
      }}>
      <HeaderAquamarine title={translate(pt.credit_purchases)} />
      <ScrollView 
        refreshControl={<RefreshControl refreshing={loading} colors={[colors.aquaMarineTwo]} tintColor={colors.aquaMarineTwo} onRefresh={getPurchases} />}
        contentContainerStyle={{flexGrow: 1}}>
        <View style={styles.container}>

          <View animation="fadeInDown" style={{ marginHorizontal: 20 }}>
            <HeaderByMonth
              month={currentMonth.locale('pt-br')}
              previousMonth={previousMonth}
              nextMonth={nextMonth}
              canFuture={false}
              />
          </View>

          <View style={{ flex: 1 }}>
          {(!loading && purchases.length == 0) && (
            <EmptyData
              style={styles.empty}
              title={'Nenhuma compra de créditos\nrealizada.'}
            />
          )}
          {renderPurchases()}
          </View>

          <ButtonShadow
          onPress={()=>setGetCreditsModal(true)} 
          containerStyle={{marginHorizontal: 30, marginBottom: insets.bottom }} 
          title={translate(pt.get_credits)} />
          
        </View>
      </ScrollView>
      <GetCreditsModal open={getCreditsModal} onClose={() => setGetCreditsModal(false)} />
    </View>
  );
};

export default CreditPurchases;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 16,
    paddingVertical: 16
  },
  empty: {
    //borderWidth: 1,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 8,
    borderBottomWidth: 0.8,
    borderBottomColor: 'rgba(0,0,0,0.1)',
    paddingBottom: 8,
  },
  purchaseShadowContainer: {
    backgroundColor: colors.white,
    borderRadius: 15,
    shadowColor: colors.black,
    shadowOpacity: 0.08,
    shadowRadius: 8,
    shadowOffset: { width: 0, height: 8 },
    marginBottom: 12
  },
  purchaseContainer: {
    borderRadius: 15,
    backgroundColor: colors.white,
    paddingHorizontal: 16,
    paddingVertical: 12,
    flexDirection: 'row',
    alignItems: 'center'
  }
});
