export default [
  {
    id: 1,
    text: 'Masculino',
    translation: 'Masculino',
    value: 'M',
    active: false,
  },
  {
    id: 2,
    text: 'Feminino',
    translation: 'Feminino',
    value: 'F',
    active: false,
  },
  {
    id: 3,
    text: 'Não informado',
    translation: 'Não informado',
    value: 'Não informado',
    active: false,
  },
];
