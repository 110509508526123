import React, { useState, useCallback, useEffect, useRef } from 'react';
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Image,
  ScrollView,
  Platform,
  StatusBar,
} from 'react-native';
import pt from 'app/i18n/src/locale/pt';
import { debounce } from 'lodash';
import { translate } from 'app/i18n/src/locale';
import Spacer from 'app/components/Spacer';
import colors from 'app/styles/colors';
import fonts from 'app/styles/fonts';
import minus from '../../../images/minus.png';
import plus from '../../../images/plus.png';
import { updateProfileSuccess } from 'app/store/modules/user/actions';
import { useSelector, useDispatch } from 'react-redux';
import WaterControl from 'app/screens/core/hydration/components/WaterControl';
import { showAlert, errorControl, getFormattedDate } from 'app/services/utils';
import api from 'app/services/api';
import QueryString from 'qs';
import moment from 'moment';
import { getWaterIntakes } from 'app/services/apiWaterIntakes';
import CalendarWeekSlide from 'app/screens/core/hydration/components/CalendarWeekSlide';
import RBSheet from 'react-native-raw-bottom-sheet';
import WaterReference from 'app/screens/core/hydration/components/WaterReference';

const DiaryInternalScreen = () => {
  const [quantityAddMl, setQuantityAddMl] = useState(1);
  const [quantityRemoveMl, setQuantityRemoveMl] = useState(1);
  const [dayIntake, setDayIntake] = useState(moment());
  const refRBSheetTimer = useRef();
  const dispatch = useDispatch();
  const { profile } = useSelector(state => state.user);

  const requestWaterIntakes = async mls => {
    const { data: user } = await api.get('user');
    try {
      await api.post(
        'water-intake',
        QueryString.stringify({
          ml: mls,
          time_stamp: moment().format('YYYY-MM-DD'),
          date: moment(dayIntake).format('YYYY-MM-DD HH:mm:ss'),
        })
      );

      await addWaterInstakes(mls);
      const water_intakes_result = await getWaterIntakes();
      user.weekly_history = water_intakes_result.weekly_history;
      user.monthly_history = water_intakes_result.monthly_history;

      dispatch(updateProfileSuccess(user));
    } catch (error) {
      showAlert(errorControl(error));
    }
  };

  const addWaterInstakes = async mls => {
    if (Math.sign(mls) > 0) {
      setQuantityAddMl(1);
      dispatch(
        updateProfileSuccess({
          sum_of_the_week: parseFloat(profile.sum_of_the_week) + 100,
          sum_of_the_month: parseFloat(profile.sum_of_the_month) + 100,
        })
      );
    }
    if (Math.sign(mls) < 0) {
      setQuantityRemoveMl(1);
      updateProfileSuccess({
        sum_of_the_week: parseFloat(profile.sum_of_the_week) - 100,
        sum_of_the_month: parseFloat(profile.sum_of_the_month) - 100,
      });
    }
  };

  const formatWaterInstakesValue = waterIntakes => {
    const waterInstakesFormated = parseFloat(waterIntakes / 1000);
    return waterInstakesFormated.toString().replace('.', ',');
  };

  const sendAddMlsRequest = countMl => {
    const totalMls = countMl * 100;
    requestWaterIntakes(totalMls);
  };

  const sendRemoveMlsRequest = countMl => {
    const totalMls = countMl * -100;
    requestWaterIntakes(totalMls);
  };

  const handlerAddMls = useCallback(debounce(sendAddMlsRequest, 1000), [dayIntake]);
  const handlerRemoveMls = useCallback(debounce(sendRemoveMlsRequest, 1000), [dayIntake]);

  const addMls = newSumOfTheDay => {
    setQuantityAddMl(quantityAddMl + 1);
    dispatch(updateProfileSuccess({ sum_of_the_day: newSumOfTheDay }));
    handlerAddMls(quantityAddMl);
  };

  const removeMls = newSumOfTheDay => {
    if (parseFloat(profile.sum_of_the_day) > 0) {
      setQuantityRemoveMl(quantityRemoveMl + 1);
      dispatch(updateProfileSuccess({ sum_of_the_day: newSumOfTheDay }));
      handlerRemoveMls(quantityRemoveMl);
    }
  };

  const callbackFunction = childDate => {
    setDayIntake(childDate);
  };

  const closeReferenceModal = () => {
    refRBSheetTimer.current.close();
  };

  const onClose = () => {
    if (Platform.OS === 'android') {
      StatusBar.setBackgroundColor(colors.transparent);
    }
  };

  const getWater = async () => {
    const { data: user } = await api.get('user');
    const water_intakes_result = await getWaterIntakes(dayIntake);
    user.sum_of_the_day = water_intakes_result.sum_of_the_day[0].ML
      ? water_intakes_result.sum_of_the_day[0].ML
      : 0;
    user.sum_of_the_week = water_intakes_result.sum_of_the_week[0].ml
      ? water_intakes_result.sum_of_the_week[0].ml
      : 0;

    user.sum_of_the_month = water_intakes_result.sum_of_the_month[0].ml
      ? water_intakes_result.sum_of_the_month[0].ml
      : 0;

    user.weekly_history = water_intakes_result.weekly_history;
    user.monthly_history = water_intakes_result.monthly_history;
    dispatch(updateProfileSuccess(user));
  };

  useEffect(() => {
    getWater();
  }, [dayIntake]);

  const getSum = () => {
    const consumed = Math.ceil(formatWaterInstakesValue(profile.sum_of_the_day * 10));
    return `${consumed} copo${consumed !== 1 ? 's' : ''}`;
  };

  return (
    <View style={styles.container}>
      <CalendarWeekSlide parentCallback={callbackFunction} withMonth />
      <ScrollView>
        <Spacer value={47} />
        <View style={styles.containerCenter}>
          <Text style={styles.ingestedQuantity}>{translate(pt.ingested_quantity)}</Text>
          <Spacer value={4} />
        </View>
        <Spacer value={30} />
        <View style={styles.containerControl}>
          <View style={styles.row}>
            <TouchableOpacity
              style={[
                styles.miniButton,
                {
                  backgroundColor:
                    profile.sum_of_the_day <= 0 ? colors.aquaMarineLight : colors.aquaMarineTwo,
                },
              ]}
              onPress={() => removeMls(Number(profile.sum_of_the_day) - 100)}
              disabled={profile.sum_of_the_day <= 0 ? true : false}>
              <Image source={minus} style={{ width: 18, height: 18 }} />
            </TouchableOpacity>
            <Spacer value={30} />
            <View style={{ alignItems: 'center' }}>
              <Text style={styles.mutedDescription}>1 copo = 100ml</Text>
              <Text style={styles.qntWater}>{getSum()}</Text>

              <Text style={styles.mutedDescription}>
                {moment(dayIntake).format('YYYYMMDD') === moment().format('YYYYMMDD')
                  ? translate(pt.water_intake_today)
                  : `${translate(pt.water_intake_date)} ${getFormattedDate(dayIntake)}`}
              </Text>
            </View>
            <Spacer value={30} />
            <TouchableOpacity
              style={styles.miniButton}
              onPress={() => addMls(Number(profile.sum_of_the_day) + 100)}>
              <Image source={plus} style={{ width: 18, height: 18 }} />
            </TouchableOpacity>
          </View>
        </View>
        <Spacer value={50} />
        <WaterControl />
      </ScrollView>
      <RBSheet onClose={onClose} ref={refRBSheetTimer} animationType="none" duration={200}>
        <WaterReference doClose={closeReferenceModal} reference={profile.hidratation_goal} />
      </RBSheet>
    </View>
  );
};

export default DiaryInternalScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.paleGrey,
  },
  containerCenter: {
    alignItems: 'center',
  },
  ingestedQuantity: {
    fontFamily: fonts.MEDIUM,
    fontSize: 16,
    color: colors.charcoalGreyTwo,
  },
  containerControl: {
    alignItems: 'center',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  qntWater: {
    fontFamily: fonts.BOLD,
    fontSize: 26,
    color: colors.charcoalGreyTwo,
  },
  mutedDescription: {
    marginTop: 0,
    fontFamily: fonts.LIGHT,
    fontSize: 12,
    color: colors.blueyGrey,
  },
  miniButton: {
    marginTop: 12,
    width: 33,
    height: 33,
    borderRadius: 3.2,
    backgroundColor: colors.aquaMarineTwo,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 0,
  },
});
