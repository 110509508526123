import React from 'react';

import { View, Text, StyleSheet, Image } from 'react-native';
import textStyles from '../../../../styles/textStyles';
import colors from '../../../../styles/colors';
import { translate } from '../../../../i18n/src/locale';
import pt from '../../../../i18n/src/locale/pt';
import iconCalendar from '../../../../images/iconCalendar.png';
import nounWeight from '../../../../images/nounWeight.png';
import fonts from '../../../../styles/fonts';
import moment from 'moment';

const RowWeight = ({ weight, date }) => (
  <View style={styles.card}>
    <View style={styles.image_holder}>
      <Image source={nounWeight} style={{width: 30, height: 30}}/>
    </View>
    <View style={styles.holder}>
      <Text numberOfLines={2} style={textStyles.semi14}>
        {translate(pt.change_weight)}
        <Text style={{ fontFamily: fonts.BOLD }}>{translate(pt.kg, { num: weight })}</Text>
      </Text>
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <Image
          style={{ width: 13, height: 13, marginRight: 2, tintColor: colors.aquaMarineTwo }}
          source={iconCalendar}
        />
        <Text style={textStyles.regular12grey}>{moment(date).format('DD/MM')}</Text>
      </View>
    </View>
  </View>
);

export default RowWeight;

const styles = StyleSheet.create({
  holder: { flex: 1, marginHorizontal: 12, justifyContent: 'center' },
  image_holder: {
    width: 81,
    height: 91,
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    flexDirection: 'row',
    marginBottom: 14,
    height: 91,
    borderRadius: 3,
    backgroundColor: colors.white,
  },
});
