/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useEffect, useState } from 'react';
import {
  Dimensions,
  ImageBackground,
  ScrollView,
  StyleSheet,
  Text,
  Image,
  ActivityIndicator,
  View,
  FlatList,
  useWindowDimensions,
} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { useSafeArea } from 'react-native-safe-area-context';
import Accordion from '../../../components/Accordion';
import ButtonBase from '../../../components/ButtonBase';
import ChallengeChip from '../../../components/ChallengeChip';
import HeaderOver from '../../../components/HeaderOver';
import { translate } from '../../../i18n/src/locale';
import pt from '../../../i18n/src/locale/pt';
import colors from '../../../styles/colors';
import commons from '../../../styles/commons';
import textStyles from '../../../styles/textStyles';
import HeaderTitle from '../../profile/components/HeaderTitle';
import CircuitHelpModal from 'app/screens/core/explorer/components/CircuitHelpModal';
import { useSelector } from 'react-redux';
import ButtonShadow from 'app/components/ButtonShadow';
import fallback from 'app/images/fallback_fitness2.jpg';
import CongratsModal from 'app/screens/core/explorer/components/CongratsModal';
import CustomIcon from 'app/styles/icon';
import fonts from 'app/styles/fonts';
import { formatDistance, formatMoneyBR } from 'app/services/utils';
import Icon from 'react-native-vector-icons/dist/FontAwesome';
import HeaderTitleNew from 'app/screens/core/home/components/HeaderTitleNew';
import 'moment';
import 'moment/locale/pt-br';
import moment from 'moment';
// import Carousel, { Pagination } from 'react-native-snap-carousel';
import ShadowView from '@liuyunjs/react-native-simple-shadow-view';
import Spacer from 'app/components/Spacer';
import api from 'app/services/api';
import Capsule from 'app/components/Capsule';
import ShadowedView from 'app/components/ShadowedView';
import { TextMask } from 'react-native-masked-text';
import ReserveDayPassModal from 'app/screens/core/gym/components/ReserveDayPassModal';
import exclamacao from 'app/images/exclamacao.png';

import { GymInformations } from './components/GymInformations';
import { GymReviews } from './components/GymReviews';
import { GymReviewsModal } from './GymReviewsModal';
import Swiper from 'react-native-web-swiper';

moment.locale('pt-br');

const { iWidth } = Dimensions.get('window');

const GymDetailScreen = ({ route }) => {

  
  const { id, currentLocation, reserve = false } = route?.params;

  const [scrollY, setScrollY] = useState(0);
  const [gym, setGym] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [galleryImages, setGalleryImages] = useState([]);
  const [activityList, setActivityList] = useState([]);
  const [activeSlide, setActiveSlide] = useState(0);
  const [isOpenGymReviewModal, setIsOpenGymReviewModal] = useState(false);
  const [gymInfoSelected, setGymInfoSelected] = useState('Informações');
  const insets = useSafeArea();
  const { width } = useWindowDimensions();

  const handleScroll = event => {
    setScrollY(event.nativeEvent.contentOffset.y);
  };

  useEffect(() => {
    getGym();
  }, [getGym]);

  const getGym = useCallback(async () => {
    try {
      setIsLoading(true);

      const params = {
        id
      }

      console.log(currentLocation)
      if(currentLocation?.latitude){
        params.lat = currentLocation?.latitude;
        params.lng = currentLocation?.longitude;
      }

      const { data } = await api.get('gyms/get-by-id', {
        params
      });

      setGym(data);
      const main_activity = { ...data.main_activity, type: 'main' };
      const available_activities = data.activities.map(ac => ({ ...ac, type: 'available' }));
      const excluded_activities = data.excluded_activities.map(ac => ({
        ...ac,
        type: 'unavailable',
      }));

      const gallery = data?.images?.gallery;
      const galleryChunks = [];
      for (let i = 0; i < gallery.length; i += 3) {
        galleryChunks.push(gallery.slice(i, i + 3));
      }

      setGalleryImages(galleryChunks);
      setActivityList([main_activity, ...available_activities, ...excluded_activities]);
    } catch (err) {
      console.log('ER', JSON.stringify(err));
    }
    setIsLoading(false);

    if (reserve) {
      setModalOpen(true);
    }
  }, [id]);

  const handleChangeGymInfoSelected = useCallback(value => {
    setGymInfoSelected(value);
  }, []);

  const RenderGallery = ({ item }) => {
    const { width } = useWindowDimensions();
    return (
      <View style={[styles.container, {marginLeft: 20 }]}>
        {item.map((image, i) => {
          const isLast = item.length - 1 === i;
          return (
            <ShadowView
              key={image}
              style={{
                ...styles.shadowContainer,
                width: width / 3 - 20,
                marginRight: isLast ? 0 : 10,
              }}>
              <Image source={{ uri: image }} style={styles.image} />
            </ShadowView>
          );
        })}
      </View>
    );
  };

  const renderActivities = item => {
    const fillColor = item.type == 'available' ? colors.aquaMarineTwo : colors.greyLight2;

    return (
      <View key={item.id} style={styles.activityContainer}>
        <View style={commons.alignCenter}>
          {item.type == 'main' ? (
            <LinearGradient
              useAngle
              angle={45}
              colors={[colors.aquaMarineTwo, colors.aquaMarineLight2]}
              style={[
                styles.activityIconWrapper,
                { borderWidth: item.type == 'unavailable' ? 0 : 1 },
              ]}>
              <Image source={{ uri: item.imageUrl }} style={{tintColor: 'white', resizeMode: 'contain', width: 30, height: 30}} />
              <View
                style={[
                  commons.flexCenter,
                  {
                    position: 'absolute',
                    right: -2,
                    bottom: -2,
                    width: 18,
                    height: 18,
                    borderRadius: 18,
                    backgroundColor: colors.white,
                  },
                ]}>
                <CustomIcon name={'star'} color={colors.aquaMarineTwo} size={12} />
              </View>
            </LinearGradient>
          ) : (
            <View
              style={[
                styles.activityIconWrapper,
                { borderWidth: item.type == 'unavailable' ? 0 : 1 },
              ]}>
              <Image source={{ uri: item.imageUrl }} style={{tintColor: fillColor, resizeMode: 'contain', width: 30, height: 30}} />
              {item.type == 'available' && (
                <LinearGradient
                  useAngle
                  angle={-45}
                  colors={[colors.aquaMarineTitle, colors.aquaMarineLight2]}
                  style={{
                    position: 'absolute',
                    right: -2,
                    bottom: -2,
                    borderRadius: 18,
                    height: 18,
                    width: 18,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Icon name={'check'} color={colors.white} size={8} />
                </LinearGradient>
              )}
            </View>
          )}

          <Text style={{ ...textStyles.semi10grey, marginTop: 10, textAlign: 'center' }}>
            {item.title}
            {item?.type == 'main' && ' (principal)'}
          </Text>

          <GymReviewsModal
            isOpenModal={isOpenGymReviewModal}
            gym={gym}
            onClosed={() => setIsOpenGymReviewModal(false)}
            onPress={() => console.log('oi oi')}
          />
        </View>
      </View>
    );
  };

  return (
    <View style={styles.container}>
      {isLoading ? (
        <View style={{ alignItems: 'center', justifyContent: 'center', flex: 1 }}>
          <ActivityIndicator size={'large'} color={colors.aquaMarineTwo} />
        </View>
      ) : (
        <React.Fragment>
          <HeaderOver title={gym?.title} position={scrollY} maxSize={426} share={false} />

          <ScrollView
            scrollEventThrottle={16}
            onScroll={handleScroll}
            contentContainerStyle={{ flexGrow: 1, paddingBottom: insets.bottom + 48 + 20 }}>
            <View>
              <View style={styles.topImage}>
                <ImageBackground
                  source={{
                    uri: gym?.images?.gallery.length ? gym?.images?.gallery[0] : gym?.images?.logo,
                  }}
                  style={{ height: 260 }}>
                  <View style={styles.topper}>
                    <LinearGradient
                      colors={[colors.transparent, colors.black]}
                      style={styles.linear}
                    />
                    <View style={styles.holderTitle}>
                      <Image source={{ uri: gym?.images?.logo }} style={styles.logo} />
                      <View style={{ flex: 1 }}>
                        <Text style={[textStyles.bold20White, commons.shadow]}>{gym?.title}</Text>
                        <View style={{ flexDirection: 'row', width: '100%', marginTop: 5 }}>
                          <Icon
                            name={'map'}
                            size={16}
                            style={{ marginRight: 5, marginTop: 3 }}
                            color={colors.white}
                          />
                          <Text style={{ ...textStyles.regular14White, flex: 1 }}>
                            {gym?.address?.address}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                </ImageBackground>
              </View>
              <View style={styles.capsuleHolder}>
                {(!!gym?.distance && !!currentLocation?.latitude) && (
                  <View style={styles.capsuleHolderContainer}>
                    <CustomIcon
                      name={'pin_full'}
                      color={colors.aquaMarineTwo}
                      size={16}
                      style={{ marginRight: 2 }}
                    />
                    <Text style={textStyles.medium14}>{formatDistance(gym?.distance)}</Text>
                  </View>
                )}
                <LinearGradient
                  useAngle
                  angle={90}
                  colors={[colors.aquaMarineLight2, colors.aquaMarineTitle]}
                  style={{ ...styles.capsuleHolderContainer, marginLeft: 10 }}>
                  <CustomIcon
                    name={'sun'}
                    color={colors.white}
                    size={16}
                    style={{ marginRight: 2 }}
                  />
                  <Text style={textStyles.medium14White}>
                    {translate(pt.daily_a)}: R$
                    <Text style={{ fontFamily: fonts.BOLD }}>{gym?.daily_price}</Text>
                  </Text>
                </LinearGradient>
              </View>
            </View>

            {!!gym?.description && (
              <Text style={{ ...textStyles.regular14, marginHorizontal: 20, marginBottom: 15 }}>
                {gym.description}
              </Text>
            )}

            {!!gym?.first_daypass_free && (
              <LinearGradient
                useAngle
                angle={90}
                colors={[colors.aquaMarineLight2, colors.aquaMarineTitle]}
                style={styles.firstDaypass}>
                <Text style={textStyles.regular10white}>
                  Primeira diária <Text style={{ fontFamily: fonts.BOLD }}>Grátis</Text>
                </Text>
              </LinearGradient>
            )}

            {galleryImages.length > 0 && (
              <View style={{ width: '100%', marginTop: 10 }}>
                <Swiper
                  loop
                  innerContainerStyle={{width: '100%', height: 140}}
                  controlsProps={{
                    prevTitle: null,
                    nextTitle: null,
                    dotsPos: 'bottom',
                    dotActiveStyle: {
                      backgroundColor: colors.aquaMarineTwo
                    },
                  }}
                >
                {galleryImages.map(item => {
                  return <RenderGallery item={item}/>
                })}
              </Swiper>
                {/* <Carousel
                  data={galleryImages}
                  renderItem={renderGallery}
                  sliderWidth={width}
                  onSnapToItem={index => setActiveSlide(index)}
                  itemWidth={width - 40}
                  loop
                />
                <Pagination
                  dotsLength={galleryImages.length}
                  activeDotIndex={activeSlide}
                  containerStyle={{
                    marginTop: -10,
                    marginBottom: -15,
                  }}
                  dotStyle={{
                    width: 8,
                    height: 8,
                    borderRadius: 5,
                    backgroundColor: 'rgba(0,0,0, 0.4)',
                  }}
                  inactiveDotOpacity={0.4}
                  inactiveDotScale={1}
                /> */}
              </View>
            )}

            <HeaderTitleNew
              textStyle={{ textTransform: 'uppercase', fontFamily: fonts.BOLD }}
              style={{ marginTop: 15, marginBottom: 10 }}
              title={translate(pt.modalities)}
            />

            <ShadowedView
              wrapperStyle={{
                ...commons.flexRow,
                paddingVertical: 0,
                paddingHorizontal: 10,
                borderColor: colors.aquaMarineGrd,
                borderWidth: 1,
              }}
              shadowContainerStyle={styles.activityDailyAvailable}>
              <LinearGradient
                useAngle
                angle={-45}
                colors={[colors.aquaMarineTitle, colors.aquaMarineLight2]}
                style={{
                  borderRadius: 18,
                  height: 18,
                  width: 18,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Icon name={'check'} color={colors.white} size={8} />
              </LinearGradient>
              <Text style={{ ...textStyles.bold10White, marginLeft: 5, color: colors.darkPro2 }}>
                {translate(pt.included_in_day_pass)}
              </Text>
            </ShadowedView>

            {!!activityList.length && (
              <FlatList
                contentContainerStyle={{ paddingHorizontal: 20 }}
                showsHorizontalScrollIndicator={false}
                horizontal
                data={activityList}
                renderItem={({ item }) => renderActivities(item)}
              />
            )}

            {!!gym?.whatsapp && (
              <View style={styles.beforeSchedule}>
                <View style={styles.exclamacao}>
                  <Image
                    source={exclamacao}
                    style={{ width: 9, height: 9, resizeMode: 'contain' }}
                  />
                </View>
                <Text style={{ ...textStyles.bold10grey, flex: 1 }}>
                  Algumas modalidades requerem agendamento prévio. Por favor, entre em contato com a
                  academia através do telefone fornecido abaixo.
                </Text>
              </View>
            )}

            <HeaderTitleNew
              textStyle={{ textTransform: 'uppercase', fontFamily: fonts.BOLD }}
              style={{ marginTop: 20 }}
              title={translate(pt.gym_infos)}
            />

            <View style={styles.infoButtonContainer}>
              <ButtonShadow
                title="Informações"
                containerStyle={{ width: '34%', height: 40 }}
                textStyle={{ fontSize: 12 }}
                backgroundColor={
                  gymInfoSelected === 'Informações' ? colors.aquaMarineTwo : colors.white
                }
                color={gymInfoSelected === 'Informações' ? colors.white : colors.greyBlue}
                onPress={() => handleChangeGymInfoSelected('Informações')}
              />
              {/* <ButtonShadow
                title="Avaliações"
                containerStyle={{ width: '34%', height: 40, marginLeft: 10 }}
                textStyle={{ fontSize: 12 }}
                backgroundColor={
                  gymInfoSelected === 'Avaliações' ? colors.aquaMarineTwo : colors.white
                }
                color={gymInfoSelected === 'Avaliações' ? colors.white : colors.greyBlue}
                onPress={() => handleChangeGymInfoSelected('Avaliações')}
              /> */}
              {/* <ButtonShadow
                title="Mapa"
                containerStyle={{ width: '30%', height: 40 }}
                textStyle={{ fontSize: 12 }}
                backgroundColor={gymInfoSelected === 'Mapa' ? colors.aquaMarineTwo : colors.white}
                color={gymInfoSelected === 'Mapa' ? colors.white : colors.greyBlue}
                onPress={() => handleChangeGymInfoSelected('Mapa')}
              /> */}
            </View>

            {gymInfoSelected === 'Informações' && <GymInformations gym={gym} />}
            {gymInfoSelected === 'Avaliações' && <GymReviews gym={gym} />}
          </ScrollView>
          <View
            style={{
              backgroundColor: 'white',
              position: 'absolute',
              bottom: 0,
              paddingBottom: insets.bottom + 20,
              left: 0,
              right: 0,
            }}>
            <View style={{ paddingHorizontal: 20 }}>
              <ButtonBase
                textStyle={{ color: colors.white }}
                onPress={() => setModalOpen(true)}
                fill
                style={{ height: 50 }}
                title={translate(pt.book_daily)}
              />
            </View>
          </View>
          <View style={{ paddingBottom: 40 }} />
        </React.Fragment>
      )}
      <ReserveDayPassModal open={modalOpen} gym={gym} onClose={() => setModalOpen(false)} />
    </View>
  );
};

export default GymDetailScreen;

const styles = StyleSheet.create({
  add: { flex: 1, backgroundColor: colors.aquaMarineTwo },
  topper: { padding: 14, flex: 1, justifyContent: 'flex-end' },
  holderTitle: { flexDirection: 'row', marginVertical: 10, alignItems: 'flex-end' },
  linear: { position: 'absolute', width: '100%', height: 200, left: 0, bottom: 0 },
  capsuleHolder: {
    flexDirection: 'row',
    marginTop: -10,
    marginBottom: 20,
    justifyContent: 'flex-start',
    marginLeft: 16,
  },
  capsuleHolderContainer: {
    backgroundColor: colors.white,
    flexDirection: 'row',
    alignItems: 'center',
    elevation: 5,
    borderRadius: 50,
    paddingVertical: 4,
    paddingHorizontal: 10,
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 6,
    shadowOpacity: 0.2,
  },

  beforeSchedule: {
    marginTop: 15,
    borderRadius: 10,
    padding: 10,
    borderWidth: 0.5,
    borderColor: colors.apricot2,
    backgroundColor: colors.grayF0,
    marginHorizontal: 20,
    alignItems: 'center',
    flexDirection: 'row',
  },
  exclamacao: {
    borderRadius: 50,
    backgroundColor: colors.apricot,
    width: 19,
    height: 19,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 8,
  },
  firstDaypass: {
    borderRadius: 50,
    paddingVertical: 5,
    paddingHorizontal: 10,
    marginLeft: 20,
    alignSelf: 'flex-start',
    marginBottom: 15,
  },
  activityDailyAvailable: {
    alignSelf: 'flex-start',
    borderRadius: 50,
    height: 26,
    marginBottom: 20,
    marginLeft: 20,
    shadowColor: colors.aquaMarineTwo,
  },
  activityContainer: {
    width: 70,
    marginBottom: 10,
    marginRight: 5,
  },
  activityIconWrapper: {
    width: 56,
    height: 56,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 56,
    backgroundColor: colors.white,
    borderWidth: 1,
    borderColor: colors.aquaMarineGrd,
  },
  infoContainer: {
    backgroundColor: colors.white,
    borderRadius: 10,
    paddingVertical: 10,
    paddingHorizontal: 10,
    marginHorizontal: 20,
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 46,
  },
  infoButtonContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginHorizontal: 20,
    marginBottom: 10,
  },
  amenitiesContainer: {
    backgroundColor: colors.white,
    marginHorizontal: 20,
    marginBottom: 10,
    padding: 10,
    borderRadius: 10,
  },
  dailyText: {
    fontSize: 16,
    fontFamily: fonts.MEDIUM,
    color: colors.aquaMarineTwo,
  },
  infoText: {
    marginLeft: 3,
    fontSize: 16,
    fontFamily: fonts.MEDIUM,
    color: colors.charcoalGreyTwo,
  },
  topImage: {
    height: 260,
    borderBottomRightRadius: 36,
    backgroundColor: 'grey',
    overflow: 'hidden',
  },
  container: {
    flex: 1,
    // height: '100vh',
    backgroundColor: colors.greyF5,
    flexDirection: 'row',
  },
  insideContainerRow: {
    marginTop: 10,
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 0,
  },
  capsuleActivitiesItem: {
    marginTop: 10,
    marginRight: 5,
    borderWidth: 0,
    backgroundColor: colors.paleLilac,
    borderRadius: 40,
    paddingVertical: 5,
    paddingHorizontal: 15,
  },
  logo: {
    marginRight: 16,
    width: 100,
    height: 100,
    borderRadius: 10,
    resizeMode: 'cover',
  },
  seriesExercises: {
    backgroundColor: '#EBECF2',
    paddingVertical: 8,
    borderRadius: 5,
    marginBottom: 25,
    marginTop: 10,
    marginHorizontal: 11,
  },
  shadowContainer: {
    width: iWidth / 3 - 20,
    height: 100,
    backgroundColor: colors.white,
    borderRadius: 15,
    shadowColor: colors.black,
    shadowOpacity: 0.1,
    shadowRadius: 5,
    shadowOffset: { width: 0, height: 5 },
  },
  image: {
    height: '100%',
    width: '100%',
    borderRadius: 10,
    resizeMode: 'cover',
  },
});
