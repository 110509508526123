import PERFORMANCE from 'app/configs/performance';
import CardClassesHorizontal from 'app/screens/profile/components/CardClassesHorizontal';
import apiNode from 'app/services/apiNode';
import api from 'app/services/api';
import { errorControl, sendActionEvent, showAlert } from 'app/services/utils';
import { setNameShare } from 'app/store/modules/category/actions';
import colors from 'app/styles/colors';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { ActivityIndicator, TouchableOpacity, FlatList, StyleSheet, Text, View, ScrollView } from 'react-native';
// import { Actions } from 'react-native-router-flux';
import { useDispatch, useSelector } from 'react-redux';
import { Dimensions } from 'react-native';
import CardCoursesHorizontal from 'app/screens/profile/components/CardCoursesHorizontal';
import TabButton from 'app/components/TabButton';
import { translate } from 'app/i18n/src/locale';
import pt from 'app/i18n/src/locale/pt';
import fonts from 'app/styles/fonts';
import { useNavigation } from '@react-navigation/native';
const wWidth = Dimensions.get('window').width;

const CLASSES = 0;
const COURSES = 1;
const TRAILS = 2;

const categories = [
  { id: 1, name: 'Alimentação' },
  { id: 2, name: 'Corpo' },
  { id: 3, name: 'Mente' },
];

const FavoritesView = ({ style }) => {
  const navigation = useNavigation();
  const [favorites, setFavorites] = useState({ [CLASSES]: [], [TRAILS]: [], [COURSES]: []});
  const [favoritesPages, setFavoritesPages] = useState(3);
  const [entity, setEntity] = useState(CLASSES);
  const [loading, setLoading] = useState(false);
  const { profile } = useSelector(state => state.user);

  const dispatch = useDispatch();
  useEffect(() => {
    requestFavorites();
  }, []);

  const formatDataForRender = (data, isCourse = false) => {
    return data
      .map(item => {
        if (item.class) {
          return item.class;
        } else if (item.trail) {
          return {
            ...item.trail,
            meal_trail: true,
          };
        }
        return item;
      })
      .map(x => {
        return {
          ...x,
          isCourse: isCourse,
        };
      });
  };

  const requestFavorites = useCallback(async () => {
    setLoading(true);
    try {
      const { data: classesData } = await apiNode.get(
        `/user-favorites-classes/get-by-user-id/${profile.user_id}/?limit=20`
      );

      const { data: trailsData } = await apiNode.get(
        `/user-favorite-trails/get-by-user-id/${profile.user_id}/?limit=20`
      );

      const { data: coursesData } = await api.get('/masterclass/favorite');
      const { data: courseCategoriesData } = await api.get('/masterclass/get-categories');

      // eslint-disable-next-line prettier/prettier
      let formattedCoursesData = coursesData.map(x => (
        // eslint-disable-next-line prettier/prettier
        {
          ...x,
          category_id:
            // eslint-disable-next-line  prettier/prettier
          parseInt(
              Object.keys(courseCategoriesData.tree)
                // eslint-disable-next-line prettier/prettier
                .find(k=>Object.keys(courseCategoriesData.tree[k]).some((k2) => Object.keys(courseCategoriesData.categories).find(r=>x.categories.some(d=>courseCategoriesData.categories[r] === d)) in courseCategoriesData.tree[k])),
              10
            ),
        })
      );
      setFavorites({
        [CLASSES]: formatDataForRender(classesData),
        [TRAILS]: formatDataForRender(trailsData),
        [COURSES]: formatDataForRender(formattedCoursesData),
      });
      setFavorites({
        [CLASSES]: formatDataForRender(classesData),
        [TRAILS]: formatDataForRender(trailsData),
        [COURSES]: formatDataForRender(formattedCoursesData),
      });
      setLoading(false);
    } catch (error) {
      showAlert(errorControl(error));
    }
  }, []);

  const openTrailNew = (categoryId, id, type) => {
    if (entity === TRAILS) {
      if (categoryId === 1) {
        navigation.navigate('MealTrail',{ mealTrailId: id });
      } else if (categoryId === 2) {
        navigation.navigate('Trail',{ trailId: id });
      } else if (categoryId === 3) {
        navigation.navigate('MeditationTrail',{ meditationId: id });
      }

      // sendActionEvent('click_at_trail', {
      //   id: id,
      //   email: profile.email,
      // });
    } else {
      if (categoryId === 1) {
        navigation.navigate('MealTrail',{ mealTrailId: id });
        // sendActionEvent('click_at_trail', {
        //   id: id,
        //   email: profile.email,
        // });
      } else if (categoryId === 2) {
        navigation.navigate('LessonActivity',{ lessonId: id });
        // sendActionEvent('click_at_exercise', {
        //   id: id,
        //   email: profile.email,
        // });
      } else if (categoryId === 3) {
        navigation.navigate('LessonMeditation',{ classId: id });
        // sendActionEvent('click_at_exercise', {
        //   id: id,
        //   email: profile.email,
        // });
      }
    }
  };

  const getColorBase = categoryId => {
    if (categoryId === 1) {
      return colors.darkRed;
    }
    if (categoryId === 3) {
      return colors.brightOrange;
    }
    return colors.aquaMarineTwo;
  };

  const getGradientColor = categoryId => {
    if (categoryId === 1) {
      return 'red';
    }
    if (categoryId === 2) {
      return 'green';
    }
    return 'orange';
  };

  const renderFavorites = () => {
    return (
      <FlatList
        data={favorites[entity]}
        onEndReached={() => {
          setFavoritesPages(favoritesPages + 3);
        }}
        contentContainerStyle={{ padding: 20, paddingBottom: 80 }}
        ListHeaderComponent={
          <View style={{ width: '100%' }}>
            <Text style={styles.title}>{translate(pt.favorites)}</Text>
            <View
              horizontal
              showsHorizontalScrollIndicator={false}
              style={styles.favoriteTypesContainer}>
              {favorites[CLASSES].length > 0 && (
                <TabButton
                  active={entity === CLASSES}
                  onPress={() => setEntity(CLASSES)}
                  title={'Aulas'}
                  containerStyle={{ marginLeft: 0 }}
                />
              )}
              {favorites[COURSES].length > 0 && (
                <TabButton
                  active={entity === COURSES}
                  onPress={() => setEntity(COURSES)}
                  title={'Cursos'}
                />
              )}
              {favorites[TRAILS].length > 0 && (
                <TabButton
                  active={entity === TRAILS}
                  onPress={() => setEntity(TRAILS)}
                  title={'Programas'}
                />
              )}
            </View>
          </View>
          }
        renderItem={({ item }) => (
          entity === COURSES ?
          <CardCoursesHorizontal
            type={categories.find(x=>x.id === item.category_id)?.name}
            title={item.post_title}
            image={item.image_path}
            paymentLevel={item.access_granted}
            onPress={()=>item.access_granted === 1 
              ? null//Actions.paywall() 
              : navigation.navigate('MasterclassDetail',{coursepress_class_id: item.id, colorBase: getColorBase(item.category_id)})}
            style={styles.leftCard}
            tag={item.categories[0] === categories.find(x=>x.id === item.category_id).name ? (item?.categories[1] || null) : item?.categories[0]}
            // goals={item.categories.slice(1).map(goal => ({ goal_name: goal }))}
            favorite={true}
            gradientColor={getGradientColor(item.category_id)}
            id={item.id}
          />
          :
          <CardClassesHorizontal
            type={categories.find(x=>x.id === (item?.subcategory?.category?.id || item?.subcategories[0]?.category?.id))?.name}
            title={item.name}
            propType={entity === TRAILS ? 'trailId' : 'classId'}
            image={item.image.path}
            difficulty={item.difficulty}
            duration={item.duration || item.duration_in_weeks}
            durationInWeek={!!item.duration_in_weeks}
            kcal={parseInt(item.calories)}
            plus={null}
            minus={null}
            paymentLevel={item.payment_level}
            onPress={debounce(() => {
              item.payment_level === 1
                ? null //Actions.paywall()
                : openTrailNew(item?.subcategory ? item?.subcategory?.category_id : item?.subcategories[0]?.category?.id, item.id)
                
              dispatch(setNameShare(item.name));
            }, PERFORMANCE.ON_PRESS_DEBOUNCE_TIME)}
            style={styles.leftCard}
            tag={item?.subcategory?.name || item?.subcategories[0]?.name}
            favorite={true}
            gradientColor={getGradientColor(item?.subcategory ? item?.subcategory?.category_id : item?.subcategories[0].category?.id)}
            id={item.id}
          />
        )}
      />
    );
  };

  return (
    <View style={[styles.containerScroll, style]}>
      {loading ? (
        <View style={styles.loading}>
          <ActivityIndicator size="large" color={colors.aquaMarineTwo} />
        </View>
      ) : (
        renderFavorites()
      )}
    </View>
  );
};

export default FavoritesView;

const styles = StyleSheet.create({
  title: {
    fontFamily: fonts.BOLD,
    fontSize: 20,
    color: colors.darkPro,
    marginBottom: 15,
  },
  containerScroll: {
    backgroundColor: colors.greyF5,
    paddingTop: 60,
  },
  leftCard: {
    width: '100%',
  },
  loading: {
    position: 'absolute',
    zIndex: 99,
    width: 150,
    height: 150,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
  favoriteTypesContainer: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    marginBottom: 8,
    width: '100%',
    // height: 40
  },
});
