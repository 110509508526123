import React, { useState } from 'react';

import { View, Text, StyleSheet, Image, TouchableOpacity } from 'react-native';
import textStyles from '../../../../styles/textStyles';
import CapsuleLevel from '../../../../components/CapsuleLevel';
import CapsuleClock from '../../../../components/CapsuleClock';
import colors from '../../../../styles/colors';
import Plus from '../../../../components/Plus';
import Minus from '../../../../components/Minus';
import CapsuleCals from '../../../../components/CapsuleCals';
import Spacer from '../../../../components/Spacer';
import videoLock from '../../../../images/videoLock.png';
import api from 'app/services/api';
import fallback from '../../../../images/fallback_food.jpg';

const RowMeal = ({
  style,
  plus,
  minus,
  checked,
  onPress,
  dishRecipes,
  mealImages,
  paymentLevel,
  name,
  level,
  mealTimeMinutes,
  calories,
  onPressAdd,
}) => {
  const renderMaskPaymentLevel = () => {
    return (
      <View style={styles.containerPaymentLevel}>
        <View style={styles.oval}>
          <Image source={videoLock} />
        </View>
        <View style={styles.mask} />
      </View>
    );
  };

  const renderMealImages = () => {
    return mealImages?.map((mealImage, index) => {
      const [mealImg, setMealImg] = useState({
        uri: mealImage?.path,
        headers: {
          Authorization: api.defaults.headers.Authorization,
        },
      });

      return (
        <View key={index} style={styles.image}>
          {paymentLevel === 1 && renderMaskPaymentLevel()}
          <Image
            source={mealImg}
            onError={(currentTarget) => {
              currentTarget.onError = null;
              setMealImg(fallback);
            }}
            style={styles.image}
          />
        </View>
      );
    });
  };

  const renderImage = () => {
    return dishRecipes?.map(dishRecipe => {
      const [dishImg, setDishImg] = useState({
        uri: dishRecipe?.image?.path,
        headers: {
          Authorization: api.defaults.headers.Authorization,
        },
      });

      return (
        <View style={styles.image}>
          {paymentLevel === 1 && renderMaskPaymentLevel()}
          <Image
            source={dishImg}
            onError={(currentTarget) => {
              currentTarget.onError = null;
              setDishImg(fallback);
            }}
            style={styles.image}
          />
        </View>
      );
    });
  };

  return (
    <TouchableOpacity onPress={onPress} disabled={checked} style={[{ opacity: checked ? 0.5 : 1 }]}>
      {plus && <Plus onPress={onPressAdd} style={styles.top10} />}
      {minus && <Minus onPress={onPressAdd} style={styles.top10} />}

      <View style={[styles.card, style]}>
        <View style={styles.holder}>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            {dishRecipes !== undefined ? renderImage() : renderMealImages()}
          </View>
          <Text
            ellipsizeMode="tail"
            numberOfLines={2}
            style={[textStyles.semi14, styles.top10, styles.nameText]}>
            {name}
          </Text>

          <View style={styles.capsules}>
            <CapsuleLevel difficulty={level} />
            <Spacer value={8} />
            <CapsuleClock duration={mealTimeMinutes} timeIn={'min'} />
            <Spacer value={8} />
            <CapsuleCals kcal={`${Math.floor(calories)} cal`} />
          </View>
          {/* {types && (
            <View style={styles.capsules}>
              <CapsuleVegan tag={tagName} />
            </View>
          )} */}
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default RowMeal;

const styles = StyleSheet.create({
  top10: { marginTop: 10 },
  capsules: {
    maxWidth: 210,
    alignSelf: 'stretch',
    flexDirection: 'row',
    marginTop: 16,
  },
  containerPaymentLevel: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 6,
    width: 69,
    height: 69,
    borderRadius: 2.4,
    marginRight: 8,
  },
  mask: {
    position: 'absolute',
    zIndex: 3,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: 69,
    height: 69,
    opacity: 0.5,
    backgroundColor: colors.white,
  },
  holder: {
    paddingVertical: 10,
    marginLeft: 16,
    paddingRight: 20,
    flex: 1,
    justifyContent: 'center',
  },
  image: {
    width: 69,
    height: 69,
    borderRadius: 2.4,
    marginRight: 8,
    backgroundColor: colors.base,
  },
  oval: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 34 / 2,
    zIndex: 5,
    left: 20,
    width: 34,
    height: 34,
    backgroundColor: colors.greyBlue,
  },
  card: {
    paddingVertical: 8,
    marginBottom: 14,
    borderRadius: 3,
    backgroundColor: colors.white,
  },
  nameText: {
    lineHeight: 19,
    height: 38,
  },
});
