import React, { useEffect, useState } from 'react';
import { StyleSheet, ScrollView, Text, View, Dimensions, FlatList } from 'react-native';
import colors from '../../../styles/colors';
import { translate } from '../../../i18n/src/locale';
import pt from '../../../i18n/src/locale/pt';
import fonts from 'app/styles/fonts';
import textStyles from 'app/styles/textStyles';
import iconList from 'app/assets/iconlist';
import api from 'app/services/api';
import { CustomLineChart2 } from 'app/components/charts/CustomLineChart2';
import AnimatedLottieView from 'lottie-react-native';
import { showMessage } from 'react-native-flash-message';
import { errorControl } from 'app/services/utils';
import CarouselSteps from '../../../components/CarouselSteps';
import DropDownPicker from './components/DropDownPicker';
const { width } = Dimensions.get('window');

const AnalyticsInternal = () => {
  const [periodSelected, setPeriodSelected] = useState(7);

  const [topMood, setTopMood] = useState(null);
  const [topMoods, setTopMoods] = useState(null);
  const [graphX, setGraphX] = useState([]);
  const [graphY, setGraphY] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const [index, setIndex] = useState(0);

  const getStats = async () => {
    setIsLoading(true);
    try {
      const { data } = await api.get(`mood/last-days/${periodSelected}`);
      setTopMood(data.top_moods ? data.top_moods[0] : null);
      setTopMoods(data.top_moods);
      setGraphX(data.stats == null ? new Array(7).fill(0) : data.stats.map(e => e.x * -1));
      setGraphY(data.stats == null ? new Array(7).fill(0) : data.stats.map(e => e.y));
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    } catch (err) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
      showMessage(errorControl(err));
    }
  };

  useEffect(() => {
    getStats();
  }, [periodSelected]);

  const renderMoodItem = mood => {
    return (
      <View style={{ width: '90vw', borderWidth: 1, ...styles.cardContainer }}>
        <Text style={styles.cardTitle}>{translate(pt.mood_reason_relation)}</Text>
        <View style={{ flexDirection: 'row', marginTop: 10 }}>
          <View style={{ flex: 1, paddingRight: 10 }}>
            <Text style={styles.cardDescription}>{mood.text}</Text>
          </View>
          <View style={styles.cardBoxRight}>
            <Text style={[textStyles.regular14, { color: colors.brownGrey }]}>
              {translate(pt.mood)}
            </Text>
            <Text style={{ fontSize: 26 }}>{iconList[mood.icon]}</Text>
            <Text style={styles.reasonMoodText}>{mood.name}</Text>
            <Text style={[textStyles.regular16, { color: colors.brownGrey }]}>
              {mood.percentage}%
            </Text>
          </View>
        </View>
        <View style={{ flexDirection: 'row', marginTop: 10 }}>
          <View style={{ flex: 1, paddingRight: 10 }}>
            <Text style={styles.cardDescription}>{mood.cause.text}</Text>
          </View>
          <View style={styles.cardBoxRight}>
            <Text style={[textStyles.regular14, { color: colors.brownGrey }]}>
              {translate(pt.reason)}
            </Text>
            <Text style={{ fontSize: 26 }}>{iconList[mood.cause.icon]}</Text>
            <Text style={styles.reasonMoodText}>{mood.cause.name}</Text>
            <Text style={[textStyles.regular16, { color: colors.brownGrey }]}>
              {mood.cause.percentage}%
            </Text>
          </View>
        </View>
      </View>
    );
  };

  const handleScroll = (nativeEvent) => {
    const offsetX = nativeEvent.contentOffset.x;
    const index = Math.round(offsetX / width);
    setIndex(index);
  }


  const renderTopMoods = () => {
    return (
      <>
        <FlatList
          keyExtractor={(_, indexKey) => indexKey.toString()}
          showsHorizontalScrollIndicator={false}
          horizontal
          data={topMoods}
          renderItem={({ item }) => renderMoodItem(item)}
          pagingEnabled
          snapToInterval={1}
          onScroll={({ nativeEvent }) => handleScroll(nativeEvent)}
        />

        <View style={{ alignItems: 'center', marginVertical: 10 }}>
          <CarouselSteps index={index} steps={topMoods.length} />
        </View>
      </>
    ) 
  }

  return (
    <View style={{ flex: 1 }}>
      {isLoading ? (
        <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
          <View style={{ width: 150, height: 150 }}>
            <AnimatedLottieView
              source={require('app/lotties/loading.json')}
              autoPlay
              loop={true}
              style={{ width: 150, transform: [{ scale: 1.3 }] }}
            />
          </View>
          <Text style={[textStyles.bold16, { color: colors.aquaMarineTwo, marginBottom: 150 }]}>
            {'Carregando sua análise...'}
          </Text>
        </View>
      ) : (
        <ScrollView style={{ flex: 1 }} contentContainerStyle={{ paddingHorizontal: 0 }}>
          <View style={{ marginVertical: 15, marginHorizontal: 20 }}>
            <Text
              style={{
                fontFamily: fonts.MEDIUM,
                color: colors.brownGrey,
                fontSize: 16,
                marginBottom: 5,
              }}>
              {translate(pt.period)}
            </Text>

            <DropDownPicker
              items={[
                {id: 0, title: '7 dias', value: 7},
                {id: 1, title: '15 dias', value: 15},
                {id: 2, title: '30 dias', value: 30},
              ]}
              selected={periodSelected}
              onSelected={(item) => setPeriodSelected(item.value)}
            />
          </View>

          <View style={styles.chartContainer}>
            <Text style={styles.chartTitle}>{translate(pt.emotional_energy)}</Text>
            <Text style={[textStyles.regular14, { textAlign: 'center' }]}>
              {translate(pt.energetic)}
            </Text>
            <CustomLineChart2 data={graphY} colors={['#44D6D8', '#C1D4F0', '#AC51EB']} />
            <Text style={[textStyles.regular14, { textAlign: 'center' }]}>
              {translate(pt.calm)}
            </Text>
          </View>
          <View style={styles.chartContainer}>
            <Text style={styles.chartTitle}>{translate(pt.emotional_charge)}</Text>
            <Text style={[textStyles.regular14, { textAlign: 'center' }]}>
              {translate(pt.positive)}
            </Text>
            <CustomLineChart2 data={graphX} colors={['#44D6D8', '#C1D4F0', '#f74545']} />
            <Text style={[textStyles.regular14, { textAlign: 'center' }]}>
              {translate(pt.negative)}
            </Text>
          </View>

          {topMood && (
            <>
              <Text
                style={[
                  textStyles.regular14,
                  { color: colors.darkPro2, marginLeft: 20, marginBottom: 10 },
                ]}>
                {translate(pt.period)}: {periodSelected} {translate(pt.days)}
              </Text>
              <View style={styles.cardContainer}>
                <Text style={styles.cardTitle}>{translate(pt.mood)}</Text>
                <View style={{ flexDirection: 'row', marginTop: 10 }}>
                  <View style={{ flex: 1, paddingRight: 10 }}>
                    <Text style={styles.cardDescription}>{topMood.text}</Text>
                  </View>
                  <View style={styles.cardBoxRight}>
                    <Text style={[textStyles.regular14, { color: colors.brownGrey }]}>
                      {translate(pt.predominant)}
                    </Text>
                    <Text style={{ fontSize: 26 }}>{iconList[topMood.icon]}</Text>
                    <Text style={styles.reasonMoodText}>{topMood.name}</Text>
                    <Text style={[textStyles.regular16, { color: colors.brownGrey }]}>
                      {topMood.percentage}%
                    </Text>
                  </View>
                </View>
              </View>
            </>
          )}

          {topMoods && renderTopMoods()}
        </ScrollView>
      )}
    </View>
  );
};

export default AnalyticsInternal;

const styles = StyleSheet.create({
  chartContainer: {
    borderRadius: 8,
    backgroundColor: colors.white,
    padding: 18,
    marginBottom: 15,
    marginHorizontal: 20,
    zIndex: -1,
  },
  chartTitle: {
    fontFamily: fonts.MEDIUM,
    fontSize: 14,
    color: colors.darkPro2,
    marginBottom: 10,
  },
  cardContainer: {
    borderRadius: 8,
    backgroundColor: colors.white,
    paddingVertical: 18,
    paddingHorizontal: 23,
    marginBottom: 15,
    marginHorizontal: 20,
  },
  cardTitle: {
    textAlign: 'left',
    fontFamily: fonts.REGULAR,
    fontSize: 18,
    color: colors.brownGrey,
  },
  cardDescription: {
    marginTop: 5,
    fontFamily: fonts.REGULAR,
    fontSize: 14,
    color: colors.black,
    lineHeight: 17,
  },
  cardBoxRight: {
    alignItems: 'center',
    justifyContent: 'center',
    borderLeftColor: '#EEEDED',
    borderLeftWidth: 1,
    paddingLeft: 10,
    width: 100,
  },
  reasonMoodText: {
    color: colors.darkPro2,
    fontSize: 18,
    fontFamily: fonts.BOLD,
    textAlign: 'center',
  },
});
