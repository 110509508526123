import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Image } from 'react-native';
import Icon from 'react-native-vector-icons/dist/FontAwesome';
import colors from 'app/styles/colors';
import textStyles from 'app/styles/textStyles';
import commons from 'app/styles/commons';
import { translate } from 'app/i18n/src/locale';
import pt from 'app/i18n/src/locale/pt';
import { debounce } from 'lodash';
import PERFORMANCE from 'app/configs/performance';
import LinearGradient from 'react-native-linear-gradient';
const HeaderTitleWithBackground = ({
  title,
  buttonIcon,
  style,
  onPress,
  linkStyle,
  uppercaseLink = false,
  linkText,
  textStyle,
}) => (
  <LinearGradient
    useAngle
    angle={90}
    colors={[colors.white, 'transparent']}
    style={[styles.container, style]}>
    <Text numberOfLines={2} style={[textStyles.semi16, styles.title, textStyle]}>
      {title}
    </Text>
    {onPress && (
      <TouchableOpacity
        onPress={debounce(() => onPress(), PERFORMANCE.ON_PRESS_DEBOUNCE_TIME)}
        hitSlop={commons.hitSlop}
        style={{ flexDirection: 'row', alignItems: 'center' }}>
        <Text
          numberOfLines={1}
          style={[
            textStyles.bold14grey,
            {
              textTransform: uppercaseLink ? 'uppercase' : 'capitalize',
              color: colors.aquaGreenDark,
            },
            linkStyle,
          ]}>
          {linkText ? linkText : translate(pt.show_more)}
        </Text>
        <Icon
          name={'chevron-right'}
          color={colors.aquaGreenDark}
          style={[textStyles.bold14grey, styles.icon, linkStyle]}
        />
      </TouchableOpacity>
    )}
  </LinearGradient>
);
export default HeaderTitleWithBackground;
const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    marginVertical: 20,
    marginHorizontal: 20,
    paddingVertical: 10,
    paddingHorizontal: 20,
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 15,
  },
  title: {
    maxWidth: 310,
    color: colors.slateGrey,
  },
  icon: {
    color: colors.aquaGreenDark,
    marginLeft: 5,
    paddingTop: 4,
  },
});