import React, { useEffect, useState, useRef, useCallback } from 'react';
import {
  Dimensions,
  StyleSheet,
  Text,
  View,
  ActivityIndicator,
  TouchableWithoutFeedback,
  StatusBar,
  Modal
} from 'react-native';
import { sendActionEvent } from 'app/services/utils';
import Spacer from '../../../../components/Spacer';
import { translate } from '../../../../i18n/src/locale';
import pt from '../../../../i18n/src/locale/pt';
import HeaderTitle from '../../../profile/components/HeaderTitle';
import colors from '../../../../styles/colors';
import ModalChangeIngredient from './ModalChangeIngredient';
import textStyles from '../../../../styles/textStyles';
import CapsuleLevel from '../../../../components/CapsuleLevel';
import Chip from '../../../../components/Chips';
import commons from '../../../../styles/commons';
// import AddCalendar from '../../explorer/components/AddCalendar';
import CardIngredientChange from '../../explorer/components/CardIngredientChange';
import CapsuleHour from 'app/components/CapsuleHour';
import Orientation from 'react-native-orientation-locker';
import Video from '@lnormanha/react-native-web-video/build/Video';
import ControlsVideo from 'app/components/ControlsVideo';
import ThumbnailVideo from 'app/components/ThumbnailVideo';
import ProgressBarWithTimer from 'app/components/ProgressBarWithTimer';
import CapsuleYield from 'app/components/CapsuleYield';
import AccordionPreparation from 'app/components/AccordionPreparation';
import InfoPortion from 'app/components/InfoPortion';
import { useSelector } from 'react-redux';
import apiVimeo from 'app/services/apiVimeo';

const MealInternalPlate = ({ dishes, isVisible }) => {
  const [modal, setModal] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);
  const [fullscreen, setFullscreen] = useState(false);
  const [isShowVideo, setIsShowVideo] = useState(false);
  const [isVideoLogin, setIsVideoLogin] = useState(false);
  const { profile } = useSelector(state => state.user);
  const videoRef = useRef([]);
  const [state, setState] = useState({
    play: false,
    currentTime: 0,
    duration: 0,
    showControls: false,
    endVideo: false,
  });

  const openModal = () => {
    setModal(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      await getVideoVimeo(dishes.how_to ? dishes.how_to.path.split('/') : null);
    };
    fetchData();
  }, [getVideoVimeo]);

  //video player functions
  const getVideoVimeo = useCallback(
    async idVimeo => {
      setIsVideoLogin(true);

      if (idVimeo) {
        const videoId = idVimeo[idVimeo.length - 1];
        try {
          const { data } = await apiVimeo.get(`/videos/${videoId}`);
          const video360 = data.download.find(v => v.rendition === '360p');
          setVideoUrl(video360.link);
        } catch (error) {
          console.log('Error', error);
        }
      } else {
        setVideoUrl(null);
      }
      setIsVideoLogin(false);
    },
    [dishes]
  );

  const getPreparationtName = () => {
    if (dishes?.preparation === '' || dishes?.preparation === null) {
      return [];
    }
    const arrayPreparation = dishes?.preparation?.split('. ');
    return arrayPreparation;
  };

  const onLoadEnd = async data => {
    sendActionEvent(
      'start_plate_in_meal_presentation',
      { id: dishes.id, email: profile.email },
      profile.email
    );
    setState(prevState => ({
      ...prevState,
      duration: data.duration,
      currentTime: data.currentTime,
    }));
  };

  const onProgress = async data => {
    setState(prevState => ({
      ...prevState,
      currentTime: data.currentTime,
    }));
  };

  const onEnd = async () => {
    sendActionEvent(
      'end_plate_in_meal_presentation',
      { id: dishes.id, email: profile.email },
      profile.email
    );
    setState({ ...state, play: false });
    videoRef.current.seek(0);
  };

  const handlePlayPause = () => {
    if (state.play) {
      setState({ ...state, play: false, showControls: true });
      return;
    }

    setState({ ...state, play: true, showControls: false });
  };

  const onSeek = data => {
    videoRef.current.seek(data.seekTime);
    setState({ ...state, currentTime: data.seekTime });
  };

  const showControls = () => {
    state.showControls
      ? setState({ ...state, showControls: false })
      : setState({ ...state, showControls: true });
  };

  const handleOrientation = () => {
    Orientation.getOrientation(deviceOrientation => {
      deviceOrientation === 'LANDSCAPE-LEFT' || deviceOrientation === 'LANDSCAPE-RIGHT'
        ? (setFullscreen(true), StatusBar.setHidden(true))
        : (setFullscreen(false), StatusBar.setHidden(false));
    });
  };

  const showComponentVideo = () => {
    setState({ ...state, play: true });
    setIsShowVideo(true);
  };

  useEffect(() => {
    Orientation.addOrientationListener(handleOrientation);

    return () => {
      Orientation.removeOrientationListener(handleOrientation);
    };
  }, []);

  return (
    <View style={{ backgroundColor: colors.paleGrey }}>
      {isVisible && (
        <>
          <View>
            <Spacer />

            <View style={styles.content}>
              <Text style={textStyles.regular14}>{dishes.description}</Text>
            </View>
            <View style={styles.capsuleHolder}>
              <CapsuleHour
                style={styles.capsule}
                timeIn={'min'}
                duration={dishes.preparation_time}
              />
              <CapsuleLevel style={styles.capsule} difficulty={dishes.level} />
              <CapsuleYield style={styles.capsule} yieldTotal={dishes?.yield} />
            </View>

            <View style={styles.content}>
              <View style={styles.insideContainerRow}>
                {dishes?.tags?.map(tag => {
                  return <Chip title={tag.name} />;
                })}
              </View>
            </View>

            {/* <View style={styles.content}> */}
              {/* <View style={commons.flexRowBetween}> */}
                {/* <AddCalendar style={styles.add} typeSchedule="dish" id={dishes.id} /> */}
                {/* <Spacer value={10} />
                <AddPlaylist style={styles.add} /> */}
              {/* </View> */}
            {/* </View> */}
            <Spacer value={10} />

            <HeaderTitle title={translate(pt.nutritional_value)} />
            <View style={styles.content_cards}>
              {dishes?.nutritional_info?.map(nutritional => (
                <View style={styles.smallCard}>
                  <Text style={textStyles.bold14}>{`${nutritional.calc_values.slice(0, -3)} ${
                    nutritional.unit_short_name
                  }`}</Text>
                  <Text numberOfLines={1} style={textStyles.regular11}>
                    {nutritional.type_name}
                  </Text>
                </View>
              ))}
            </View>

            <HeaderTitle title={translate(pt.ingredients).toUpperCase()} />

            <InfoPortion
              InfoPortion={dishes?.portion_meal}
              weightMeal={dishes?.weight_meal}
              originalYield={dishes?.yield}
            />

            <View style={styles.content}>
              {dishes?.ingredients?.map(ingredient => {
                return (
                  <CardIngredientChange
                    name={ingredient.name}
                    grams={ingredient.portion.slice(0, -3)}
                    unit={ingredient.measure_unit}
                    onPress={openModal}
                  />
                );
              })}
            </View>

            {!!videoUrl && (
              <>
                <View style={!fullscreen && styles.content}>
                  {isShowVideo ? (
                    <TouchableWithoutFeedback onPress={showControls}>
                      <View style={!fullscreen && styles.containerVideo}>
                        <Video
                          ref={videoRef}
                          style={fullscreen ? styles.fullscreenVideo : styles.video}
                          source={{
                            uri: videoUrl,
                          }}
                          muted={false}
                          controls={false}
                          resizeMode="contain"
                          onLoad={onLoadEnd}
                          onProgress={onProgress}
                          onEnd={onEnd}
                          paused={!state.play}
                          ignoreSilentSwitch="ignore"
                        />
                        {isVideoLogin && (
                          <ActivityIndicator
                            color={colors.aquaMarine}
                            style={{
                              position: 'absolute',
                              top: 0,
                              bottom: 0,
                              left: 0,
                              zIndex: 111,
                              right: 0,
                            }}
                          />
                        )}
                        {state.showControls && (
                          <View style={styles.controlOverlay}>
                            {/* <TouchableOpacity
                              onPress={handleFullscreen}
                              style={[
                                styles.fullscreenButton,
                                styles.fullscreenControls,
                                // eslint-disable-next-line react-native/no-inline-styles
                                { top: fullscreen ? 20 : 0, right: fullscreen ? 20 : 0 },
                              ]}>
                              {state.fullscreen ? (
                                <Image style={styles.fullscreenOpen} source={fullscreenClose} />
                              ) : (
                                  <Image style={styles.fullscreenClose} source={fullscreenOpen} />
                                )}
                            </TouchableOpacity> */}
                            <ControlsVideo
                              onPlay={handlePlayPause}
                              onPause={handlePlayPause}
                              playing={state.play}
                            />
                            <ProgressBarWithTimer
                              currentTime={state.currentTime}
                              duration={state.duration > 0 ? state.duration : 0}
                              onSlideStart={handlePlayPause}
                              onSlideComplete={handlePlayPause}
                              onSlideCapture={onSeek}
                            />
                          </View>
                        )}
                      </View>
                    </TouchableWithoutFeedback>
                  ) : (
                    !!dishes?.how_to && (
                      <ThumbnailVideo image={dishes.thumbnail.path} onPress={showComponentVideo} />
                    )
                  )}
                </View>
              </>
            )}
            <View style={styles.content}>
              <AccordionPreparation
                title={translate(pt.how_to).toUpperCase()}
                color={colors.strawberry}
                arrayItens={getPreparationtName()}
              />
            </View>
            {/* <ButtonBase
              backgroundColor={colors.orangeyRed}
              fill
              square
              onPress={Actions.shopping_car}
              title={translate(pt.add_to_shop_car)}
            /> */}
          </View>
          <Modal animationInTiming={600} visible={modal}>
            <ModalChangeIngredient
              onClose={() => {
                setModal(false);
              }}
            />
          </Modal>
        </>
      )}
    </View>
  );
};

export default MealInternalPlate;

const styles = StyleSheet.create({
  smallCard: {
    width: '23%',
    marginRight: '2%',
    marginTop: 8,
    height: 53,
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.white,
  },
  content_cards: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    paddingHorizontal: 14,
    marginBottom: 14,
  },
  content: {
    paddingHorizontal: 14,
    marginBottom: 14,
  },
  capsuleHolder: {
    flexDirection: 'row',
    width: 300,
    marginTop: 10,
    marginBottom: 20,
    justifyContent: 'space-between',
    marginLeft: 16,
  },
  capsule: {
    backgroundColor: colors.whiteTwo,
    borderColor: '#eee',
    borderWidth: StyleSheet.hairlineWidth,
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 6,
    shadowOpacity: 0.2,
  },
  insideContainerRow: {
    marginTop: 10,
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 0,
  },
  add: {
    flex: 1,
    paddingHorizontal: 10,
  },
  video: {
    height: Dimensions.get('window').width * (9 / 17),
    backgroundColor: colors.paleGrey,
  },
  // fullscreenVideo: {
  //   height: Dimensions.get('screen').width,
  //   width: Dimensions.get('screen').height,
  //   backgroundColor: 'black',
  // },
  // fullscreenButton: {
  //   flex: 1,
  //   flexDirection: 'row',
  //   alignSelf: 'flex-end',
  //   alignItems: 'center',
  // },
  controlOverlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#00000080',
    justifyContent: 'space-between',
  },
  containerVideo: {
    marginHorizontal: 12,
  },
  // fullscreenControls: {
  //   padding: 5,
  //   position: 'absolute',
  //   zIndex: 2,
  // },
  // fullscreenClose: {
  //   width: 20,
  //   height: 20,
  // },
  // fullscreenOpen: {
  //   width: 20,
  //   height: 20,
  // },
});
