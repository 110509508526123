import React, { useEffect, useState, useRef } from 'react';
import {
  View,
  StyleSheet,
  ScrollView,
  TouchableOpacity,
  Text,
  ActivityIndicator,
  RefreshControl,
  Image,
} from 'react-native';
import colors from '../../../../styles/colors';
import { translate } from '../../../../i18n/src/locale';
import pt from '../../../../i18n/src/locale/pt';
import NextAttendanceCard from '../components/NextAttendanceCard';
import { useDispatch, useSelector } from 'react-redux';
import fonts from 'app/styles/fonts';
import { useSafeArea } from 'react-native-safe-area-context';
import CardInterest from '../../../../components/CardInterest';
import apiNode from 'app/services/apiNode';
import BottomSheetStartNamuAttendance from 'app/screens/core/explorer/components/BottomSheetStartNamuAttendance';
import HistoryIcon from 'app/images/history.svg';
import NamuCircleIcon from 'app/images/namu_circle.svg';
import { errorControl, getProfessionalColorName } from 'app/services/utils';
import commons from 'app/styles/commons';
import PersonalCreditsCard from 'app/screens/core/professional/components/PersonalCreditsCard';
import BusinessCreditsCard from 'app/screens/core/professional/components/BusinessCreditsCard';
import GetCreditsModal from 'app/screens/core/professional/components/GetCreditsModal';
import ButtonShadow from 'app/components/ButtonShadow';
import api from 'app/services/api';
import useGlobalAlert from 'app/hooks/useGlobalAlert';
import ProfessionalTutorialModal from 'app/components/tutorials/ProfessionalTutorialModal';
import ProfessionalTutorialSteps from 'app/components/tutorials/ProfessionalTutorialSteps';
import { setFirstProfessionalEntering } from 'app/store/modules/user/actions';
import { useIsFocused, useNavigation } from '@react-navigation/native';

const OrientationInternal = ({ active, jumpTo, refresh }) => {

  const navigation = useNavigation();

  const safeAreaInsets = useSafeArea();
  const [isLoading, setIsLoading] = useState(false);
  const [interests, setInterests] = useState([]);
  const dispatch = useDispatch();
  const { profile, firstProfessionalEntering, reloadSessions } = useSelector(state => state.user);

  const [getCreditsModal, setGetCreditsModal] = useState(false);

  const solicite = useRef();

  const [namuAttendanceRef, setNamuAttendanceRef] = useState(false);
  const [nextAttendence, setNextAttendance] = useState(null);
  const [namuAttendanceCategory, setNamuAttendanceCategory] = useState(null);

  const [tutorialModal, setTutorialModal] = useState(
    firstProfessionalEntering === undefined ? true : firstProfessionalEntering
  );
  const [startTutorial, setStartTutorial] = useState(false);
  const [tutorialState, setTutorialState] = useState({
    focusCredits: false,
  });
  const [refreshing, setRefreshing] = useState(false);

  const [balance, setBalance] = useState({});
  const [teleDuration, setTeleDuration] = useState(20);

  const { setAlert } = useGlobalAlert();

  const isFocused = useIsFocused();

  const getNextAttendences = async () => {
    try {
      const { data } = await apiNode.get('/users/sessions/next/one');

      if (Object.keys(data).length > 0) {
        setNextAttendance(data);
      } else {
        setNextAttendance(null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCards = async () => {
    try {
      const { data } = await apiNode.get('/professional-categories?tele=true');
      setInterests(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getTeleInfos = async () => {
    try {
      const { data: _balance } = await api.get('balance');
      setBalance(_balance);
      const {
        data: { tele_duration },
      } = await apiNode.get('meta/tele_duration');
      setTeleDuration(tele_duration);
    } catch (error) {
      console.log(errorControl(error));
    }
  };

  const handleTutorial = start => {
    if (start) {
      setTutorialModal(false);
      setStartTutorial(true);
    } else {
      setTutorialModal(false);
    }

    dispatch(setFirstProfessionalEntering(false));
  };

  const handleTutorialStep = stepIndex => {
    if (stepIndex === 1) {
      setTutorialState(prev => ({ ...prev, focusCredits: true }));
    } else {
      setTutorialState(prev => ({ ...prev, focusCredits: false }));
    }
  };

  const handleFinishTutorial = () => {
    setStartTutorial(false);
    setTutorialState({
      focusCredits: false,
    });
  };

  const isAvailable = () => {
    return parseInt(balance?.user + (profile?.plan_meta?.tele_enabled ? balance?.business : 0)) > 0;
  };

  const schedule = item => {
    if (isAvailable()) {
      setNamuAttendanceCategory({ id: item.id, name: item.name, icon: item.iconUrl });
      setTimeout(() => {
        setNamuAttendanceRef(true);
      }, 200);
    } else {
      setAlert(translate(pt.exhausted_credits), translate(pt.exhausted_credits_message));
    }
  };

  const getData = () => {
    setRefreshing(true);

    getNextAttendences();
    getCards();
    getTeleInfos();

    setRefreshing(false);
    setIsLoading(false);
  };

  useEffect(() => {
    if (profile && active && isFocused) {
      setIsLoading(true);
      getData();
    }
  }, [profile, active, isFocused, refresh]);

  useEffect(() => {
    getNextAttendences();
  }, [reloadSessions]);

  return (
    <ScrollView
      scrollEnabled={!tutorialModal && !startTutorial}
      refreshControl={
        <RefreshControl
          refreshing={refreshing}
          colors={[colors.aquaMarineTwo]}
          tintColor={colors.aquaMarineTwo}
          onRefresh={getData}
        />
      }
      contentContainerStyle={{
        flexGrow: 1,
        width: '100%',
        padding: 16,
        paddingBottom: safeAreaInsets.bottom + 80,
      }}>
      {isLoading ? (
        <View style={{ alignItems: 'center', justifyContent: 'center', flex: 1, width: '100%' }}>
          <ActivityIndicator size={'large'} color={colors.aquaMarineTwo} />
        </View>
      ) : (
        <>
          <View style={styles.topActions}>
            <ButtonShadow
              onPress={() => navigation.navigate('Attendance')}
              title={translate(pt.orientation_history_br)}
              containerStyle={{ flex: 1, marginRight: 10 }}
              style={{ height: 'auto', paddingVertical: 8 }}
              backgroundColor={colors.white}
              color={colors.darkPro}
              icon={<Image source={HistoryIcon} style={{ marginRight: 10, width: 31, height: 31 }} />}
            />
            <ButtonShadow
              title={translate(pt.credit_purchases_br)}
              containerStyle={{ flex: 1 }}
              style={{ height: 'auto', paddingVertical: 8 }}
              onPress={() => navigation.navigate('CreditPurchases')}
              backgroundColor={colors.white}
              color={colors.darkPro}
              icon={<Image source={NamuCircleIcon} style={{ marginRight: 10, width: 31, height:31 }} />}
            />
          </View>

          {!!nextAttendence && (
            <>
              <Text style={styles.titleNextAttendence}>{translate(pt.next_orientation)}</Text>

              <NextAttendanceCard
                key={nextAttendence.id}
                onClose={() => {
                  solicite.current.close();
                }}
                onOpen={() => {
                  solicite.current.open();
                }}
                style={{ paddingHorizontal: 0 }}
                item={nextAttendence}
                reload={getData}
              />
            </>
          )}

          <Text style={styles.title}>{translate(pt.credits_for_orientations)}</Text>

          <View style={tutorialState.focusCredits && { zIndex: 2 }}>
            <View style={[commons.flexRowBetween, { marginTop: 16 }]}>
              <PersonalCreditsCard
                credits={balance.user}
                onPress={() => setGetCreditsModal(true)}
              />
              {(!!balance.business || profile?.plan_meta?.tele_enabled) && (
                <>
                  <View style={{ width: 10 }} />
                  <BusinessCreditsCard credits={balance.business} />
                </>
              )}
            </View>
            <Text style={styles.orientationTimeMessage}>
              {translate(pt.orientation_time_message, { time: teleDuration || 20 })}
            </Text>
          </View>

          <Text style={styles.title}>{translate(pt.select_orientation_area)}</Text>

          <View style={styles.containerProfissionalByArea}>
            {interests.map((item, index) => {
              return (
                <TouchableOpacity
                  key={index}
                  onPress={() => {
                    schedule(item);
                  }}>
                  <CardInterest
                    staff
                    item={{ ...item, image_path: item.iconUrl, isCategory: true }}
                    color={getProfessionalColorName(item.id)}
                    style={{ maxWidth: '100%', marginRight: 0 }}
                    angle={90}
                  />
                </TouchableOpacity>
              );
            })}
            <BottomSheetStartNamuAttendance
              sheetRef={namuAttendanceRef}
              category={namuAttendanceCategory}
              onClose={() => {
                setNamuAttendanceRef(false);
              }}
              onFinish={() => {
                navigation.navigate('Attendance', { staff: true });
              }}
            />
          </View>

          {startTutorial && (
            <ProfessionalTutorialSteps
              step={handleTutorialStep}
              onFinish={() => handleFinishTutorial()}
            />
          )}
        </>
      )}

      <GetCreditsModal
        open={getCreditsModal}
        onClose={() => (setGetCreditsModal(false), getData())}
      />
      <ProfessionalTutorialModal open={tutorialModal} onConfirm={handleTutorial} />
    </ScrollView>
  );
};

export default OrientationInternal;

const styles = StyleSheet.create({
  topActions: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  titleNextAttendence: {
    fontFamily: fonts.BOLD,
    fontSize: 18,
    color: colors.blackTwo,
    marginTop: 16,
    marginBottom: 18,
  },
  title: {
    marginTop: 30,
    fontFamily: fonts.BOLD,
    fontSize: 18,
    marginBottom: 2,
  },
  orientationTimeMessage: {
    marginTop: 20,
    fontFamily: fonts.REGULAR,
    fontSize: 16,
    color: colors.grayText,
  },
  containerProfissionalByArea: {
    flex: 1,
    alignSelf: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
});
