import React, { useState, useEffect } from 'react';
import { View, StyleSheet, Text, TouchableOpacity, Image, ActivityIndicator } from 'react-native';
import colors from 'app/styles/colors';
import textStyles from 'app/styles/textStyles';
import { translate } from 'app/i18n/src/locale';
import pt from 'app/i18n/src/locale/pt';
import apiNode from 'app/services/apiNode';
import moment from 'moment';
import ShadowView from '@liuyunjs/react-native-simple-shadow-view';
import fonts from 'app/styles/fonts';

const BottomSheetConfirmUpdate = ({ onClose, sessionId, categoryIconUrl }) => {
  const [session, setSession] = useState(null);

  useEffect(() => {
    loadSession();
  }, []);

  const loadSession = async () => {
    try {
      const { data } = await apiNode.get(`sessions/${sessionId}`);
      setSession(data);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.checkBall}>
        <Image source={require('app/images/checkNewMessage.png')} style={styles.check} />
      </View>
      <View style={styles.header}>
        <Text style={styles.title}>{translate(pt.session_updated)}</Text>
        <TouchableOpacity onPress={onClose} style={{ marginTop: -30 }}>
          <Image source={require('../../../../images/closeGrey.png')} style={{ width: 20, height: 20 }} />
        </TouchableOpacity>
      </View>

      {session ? (
        <ShadowView style={[styles.categoryAndHourContainer, { borderColor: colors.shadowBlue }]}>
          <View style={styles.categoryBlock}>
            <Image
              source={{
                uri: categoryIconUrl,
              }}
              style={styles.categoryImageStyle}
            />
          </View>

          <Text style={styles.textHourStyle}>
            {moment(session.sessionDate).format('ddd, DD, [de] MMM - HH[hrs] mm[min]')}
          </Text>
        </ShadowView>
      ) : (
        <ActivityIndicator animating color={colors.aquaMarine} size={'small'} />
      )}
      <View style={styles.buttonBox}>
        <TouchableOpacity
          style={styles.confirmButton}
          onPress={() => {
            onClose();
          }}>
          <Text style={styles.confirmButtonText}>{translate(pt.seeAtendiments)}</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    justifyContent: 'flex-end',
    borderTopLeftRadius: 50,
    borderTopRightRadius: 50,
    paddingHorizontal: 20,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  title: {
    textAlign: 'center',
    ...textStyles.bold18,
    marginHorizontal: 20,
    marginBottom: 30,
    width: '70%',
  },

  warning: {
    ...textStyles.bold10White,
    fontWeight: '400',
    color: colors.black,
    textAlign: 'center',
    marginHorizontal: 30,
    fontSize: 14,
    marginBottom: 5,
  },
  buttonBox: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  confirmButton: {
    alignSelf: 'center',
    backgroundColor: colors.aquaMarineTwo,
    width: '100%',
    alignItems: 'center',
    borderRadius: 5,
    marginHorizontal: 5,
    justifyContent: 'center',
    marginTop: 20,
    marginBottom: 20,
  },
  confirmButtonText: {
    ...textStyles.bold13,
    paddingVertical: 15,
    color: colors.white,
    fontWeight: '700',
  },
  confirmButtonTextNo: {
    ...textStyles.bold13,
    paddingVertical: 15,
    color: colors.red,
    fontWeight: '700',
  },
  checkBall: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: colors.aquaMarineTwo,

    borderRadius: 100,
    alignItems: 'center',
    alignSelf: 'center',
    padding: 50,
    backgroundColor: colors.white,
    justifyContent: 'center',
    height: 100,
    width: 100,
    marginBottom: 10,

    shadowColor: colors.red,
    shadowOpacity: 0.4,
    shadowRadius: 2,
    shadowOffset: {
      height: 1,
      width: 1,
    },
    elevation: 3,
  },
  check: {
    height: 50,
    width: 50,
  },
  categoryAndHourContainer: {
    borderWidth: 1,
    borderRadius: 9,
    padding: 8,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 16,
    shadowColor: colors.shadowBlue,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.18,
    elevation: 40,
  },
  categoryBlock: {
    backgroundColor: colors.shadowBlue,
    borderRadius: 50,
    padding: 8,
  },
  categoryImageStyle: {
    width: 23.48,
    height: 22,
    resizeMode: 'contain',
    tintColor: colors.aquaMarineTwo,
  },
  textHourStyle: {
    marginStart: 5,
    fontSize: 15,
    fontFamily: fonts.BOLD,
  },
});

export default BottomSheetConfirmUpdate;
