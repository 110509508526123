import React from 'react';
import { Text, StyleSheet, TouchableOpacity } from 'react-native';
import fonts from 'app/styles/fonts';
import colors from 'app/styles/colors';

const ButtonLink = ({ onPress, title, containerStyle, textStyle, underline }) => {
  return (
    <TouchableOpacity onPress={onPress} style={[styles.container, containerStyle]}>
      <Text
        numberOfLines={1}
        style={[styles.text, { textDecorationLine: underline ? 'underline' : null }, textStyle]}>
        {title}
      </Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'transparent',
  },
  text: {
    color: colors.greyBlue,
    fontFamily: fonts.BOLD,
    fontSize: 14,
  },
});

export default ButtonLink;
