import React from 'react';

import { View, Text, StyleSheet, Image, TouchableOpacity } from 'react-native';
import textStyles from '../../../../styles/textStyles';
import colors from '../../../../styles/colors';
import { translate } from '../../../../i18n/src/locale';
import pt from '../../../../i18n/src/locale/pt';
import clock from '../../../../images/clock.png';
import repeatOffline from '../../../../images/repeatOffline.png';
import Plus from '../../../../components/Plus';
import Minus from '../../../../components/Minus';
import Spacer from 'app/components/Spacer';
import MaskLevelPayment from 'app/components/mask-level-payment';

const RowExerciseTurns = ({ style, plus, minus, onPress, payment_level, exercise }) => {
  const renderDurationInSeconds = () => {
    if (exercise.tree.time_in_sec != null && exercise.tree.time_in_sec > 0) {
      return (
        <>
          <Image style={styles.icon} source={clock} />
          <View>
            <Text style={textStyles.regular12grey}>{`${exercise.media.duration} ${translate(
              pt.seconds_exercise
            )}`}</Text>
          </View>
        </>
      );
    }

    return null;
  };

  const renderRepetition = () => {
    if (exercise.tree.repeat > 0) {
      return (
        <>
          <Image source={repeatOffline} />
          <Spacer value={4} />
          <Text style={textStyles.regular12grey}>
            {translate(pt.turns, { num: exercise.tree.repeat })}
          </Text>
          <Spacer value={12} />
        </>
      );
    }

    return null;
  };

  return (
    <TouchableOpacity onPress={onPress}>
      {plus && <Plus style={{ marginTop: 10 }} />}
      {minus && <Minus style={{ marginTop: 10 }} />}

      <View style={[styles.card, style]}>
        {payment_level === 1 && <MaskLevelPayment />}
        <Image source={{ uri: exercise.thumbnail.path }} style={styles.image} />
        <View style={styles.holder}>
          <Text numberOfLines={2} style={textStyles.semi14}>
            {exercise.name || exercise.class_name}
          </Text>
          <View style={styles.holdIcon}>
            {renderRepetition()}
            {renderDurationInSeconds()}
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default RowExerciseTurns;

const styles = StyleSheet.create({
  holdIcon: { flexDirection: 'row', alignItems: 'center' },
  icon: {
    width: 13,
    height: 13,
    marginRight: 4,
  },
  holder: { flex: 1, marginHorizontal: 12, justifyContent: 'center' },
  image: {
    width: 81,
    borderTopLeftRadius: 3,
    borderBottomLeftRadius: 3,
    height: 71,
  },
  card: {
    flexDirection: 'row',
    marginBottom: 15,
    height: 71,
    borderRadius: 3,
    backgroundColor: colors.white,
  },
});
