import colors from 'app/styles/colors';
import React, { useState } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import ShadowView from '@liuyunjs/react-native-simple-shadow-view';
import BusinessCoin from 'app/images/business_coin.svg';
import HelpIcon from 'app/images/business_help.svg';
import fonts from 'app/styles/fonts';
import { translate } from 'app/i18n/src/locale';
import pt from 'app/i18n/src/locale/pt';
import commons from 'app/styles/commons';
import BusinessCreditsHelpModal from 'app/screens/core/professional/orientation/BusinessCreditsHelpModal';
import { useSelector } from 'react-redux';
import { Image } from 'react-native';

const BusinessCreditsCard = ({ credits }) => {
  const [helpModal, setHelpModal] = useState(false);

  const { profile } = useSelector(state => state.user);

  return (
    <>
      <View style={profile?.plan_meta?.tele_balance !== 'pool' && commons.flex}>
        <Text style={styles.label}>{translate(pt.corporative)}</Text>
        <ShadowView style={styles.shadowContainer}>
          <View style={styles.wrapper}>
            <Image source={BusinessCoin} style={{width: 31, height: 31}} />
            <View style={commons.flexRowEnd}>
              <Text style={styles.quantity} numberOfLines={1}>
                {profile?.plan_meta?.tele_balance === 'pool' ? translate(pt.shared) : credits || 0}
              </Text>
              <TouchableOpacity onPress={() => setHelpModal(true)}>
                <Image source={HelpIcon} style={{width: 21, height: 21}} />
              </TouchableOpacity>
            </View>
          </View>
        </ShadowView>
      </View>
      <BusinessCreditsHelpModal open={helpModal} onClose={() => setHelpModal(false)} />
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    // flex: 1
  },
  shadowContainer: {
    backgroundColor: colors.white,
    borderRadius: 40,
    shadowColor: colors.black,
    shadowOpacity: 0.1,
    shadowRadius: 5,
    shadowOffset: { width: 0, height: 5 },
  },
  wrapper: {
    borderRadius: 45,
    padding: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: colors.white,
  },
  label: {
    fontFamily: fonts.MEDIUM,
    fontSize: 14,
    color: colors.darkPro2,
    marginLeft: 4,
    marginBottom: 6,
  },
  quantity: {
    // flex: 1,
    fontFamily: fonts.BOLD,
    color: colors.darkPro,
    fontSize: 16,
    marginHorizontal: 9,
  },
});

export default BusinessCreditsCard;
