import React from 'react';
import { View, Image, Text, StyleSheet } from 'react-native';

const FacebookButton = () => {
  return <View style={styles.container} />;
};

export default FacebookButton;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
