import React, { useEffect, useState, useRef } from 'react';

import { useSelector } from 'react-redux';

import WebView from 'react-native-webview';

const TelemedicineInternal = () => {
  const { profile } = useSelector(state => state.user);
  const [url, setUrl] = useState('');
  const jsCode = `
  window.onbeforeunload = function () {
     return null;
   }
  `;

  const ref = useRef();

  useEffect(() => {
    const searchParams = new URLSearchParams();
    searchParams.append('cpf', profile.cpf);
    searchParams.append('phone', profile.phone_number);
    searchParams.append('birthdate', profile.birthdate);
    searchParams.append('name', profile.name);
    searchParams.append('email', profile.email);
    searchParams.append('company', profile.business_id);
    const finalUrl = 'https://telemedicina.namu.com.br/int/agendamento?' + searchParams.toString();
    setUrl(finalUrl);
  }, []);

  useEffect(() => {
    if(ref.current){
      ref.current.frameRef.setAttribute('allow', 'camera; microphone');
    }
  }, [ref])

  return (
    <>
      <WebView
        ref={ref}
        source={{ uri: url }}
        scalesPageToFit={true}
        automaticallyAdjustContentInsets={false}
        nestedScrollEnabled
        injectedJavaScript={jsCode}
        style={{ marginBottom: 50 }}
        allowsInlineMediaPlayback
        mediaPlaybackRequiresUserAction={false}
        startInLoadingState
        javaScriptEnabled
        domStorageEnabled
      />
    </>
  );
};

export default TelemedicineInternal;
