import React from 'react';

import { View, Text, StyleSheet } from 'react-native';
import colors from 'app/styles/colors';
import fonts from 'app/styles/fonts';

const Chip = ({ title, style }) => (
  <View style={[styles.card, style]}>
    <Text style={styles.title}>{title}</Text>
  </View>
);

export default Chip;

const styles = StyleSheet.create({
  title: {
    fontFamily: fonts.MEDIUM,
    fontSize: 11,
    color: colors.aquaMarineTwo,
  },
  card: {
    marginRight: 5,
    marginBottom: 6,
    borderWidth: 1,
    borderColor: colors.aquaMarineTwo,
    alignItems: 'center',
    height: 24,
    borderRadius: 12,
    paddingHorizontal: 5,
    justifyContent: 'center',
  },
});
