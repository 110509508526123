import React from 'react';

import { StyleSheet, Text, Image } from 'react-native';
import fonts from '../styles/fonts';
import colors from '../styles/colors';
import clock from '../images/clock.png';
import ShadowView from '@liuyunjs/react-native-simple-shadow-view';
import pt from 'app/i18n/src/locale/pt';
import { translate } from 'app/i18n/src/locale';
import { calcDuration } from 'app/services/utils';

const CapsuleClock = ({ small, style, timeIn = 's', isWeek, duration, noShadow }) => {

  return (
    <ShadowView
      style={[styles.capsule, style, small && styles.main, { shadowOpacity: noShadow ? 0 : 0.2 }]}>
      <Image source={clock} style={[small && styles.icon]} />
      <Text style={[styles.capsuleText, small && styles.font12]}>
        {isWeek ? translate(pt.weeks, { num: duration }) : calcDuration(duration, {timeUnit: timeIn})}
      </Text>
    </ShadowView>
  );
};

export default CapsuleClock;

const styles = StyleSheet.create({
  font12: { fontSize: 12 },
  icon: { height: 15, width: 15 },
  main: { width: 70, height: 21 },
  capsuleText: {
    marginLeft: 4,
    fontFamily: fonts.REGULAR,
    fontSize: 14,
    opacity: 0.9,
    color: colors.brownishGreyTwo,
  },
  capsule: {
    paddingLeft: 8,
    paddingRight: 8,
    flexDirection: 'row',
    alignItems: 'center',
    height: 29,
    borderRadius: 18.2,
    backgroundColor: colors.whiteTwo,
  },
});
