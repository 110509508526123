import produce from 'immer';

const INITIAL_STATE = {
  visible: false,
  title: '',
  topContent: null,
  description: '',
  topTitle: null,
  topTag: null,
  buttonOptions: { text: 'Ok', onPress: null, secondaryText: null, secondaryOnPress: null },
  additionalComponent: null,
  inverted: false,
  icon: null,
  iconContainerStyle: {},
};

export default function alert(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@alert/SET_GLOBAL_ALERT_DATA': {
        draft.visible = action.payload.globalAlert.visible;
        draft.title = action.payload.globalAlert.title;
        draft.description = action.payload.globalAlert.description;
        draft.topTag = action.payload.globalAlert.topTag;
        draft.topTitle = action.payload.globalAlert.topTitle;
        draft.buttonOptions = action.payload.globalAlert.buttonOptions;
        draft.additionalComponent = action.payload.globalAlert.additionalComponent;
        draft.inverted = action.payload.globalAlert.inverted;
        draft.icon = action.payload.globalAlert.icon;
        draft.iconContainerStyle = action.payload.globalAlert.iconContainerStyle;
        break;
      }
      case '@alert/CLOSE_GLOBAL_ALERT': {
        draft.visible = INITIAL_STATE.visible;
        draft.title = INITIAL_STATE.title;
        draft.description = INITIAL_STATE.description;
        draft.buttonOptions = INITIAL_STATE.buttonOptions;
        draft.topTag = INITIAL_STATE.topTag;
        draft.topTitle = INITIAL_STATE.topTitle;
        draft.inverted = INITIAL_STATE.inverted;
        draft.icon = INITIAL_STATE.icon;
        draft.iconContainerStyle = INITIAL_STATE.iconContainerStyle;
        break;
      }
      default:
        return state;
    }
  });
}
