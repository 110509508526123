import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Platform, Linking } from 'react-native';

import colors from 'app/styles/colors';
import textStyles from 'app/styles/textStyles';

//Images
import moment from 'moment';
// import RNFS, { stopDownload } from 'react-native-fs';
import Icon from 'react-native-vector-icons/dist/FontAwesome';
// import ProgressCircle from 'react-native-progress-circle';
import fonts from 'app/styles/fonts';
import apiNode from 'app/services/apiNode';

const ReceiveMessage = message => {
  const [documentName, setDocumentName] = useState();
  const [fullDocumentName, setFullDocumentName] = useState();
  const [documentLength, setDocumentLength] = useState();
  const [, setDocumentUri] = useState();
  const [createdAt, setCreatedAt] = useState();

  useEffect(() => {
    console.log(message);
    if (message?.message) {
      const name = message.message.file.name;
      setDocumentName(name.length > 20 ? name.substring(0, 16) + '...' : name);
      setFullDocumentName(name);
      setDocumentLength(bytesToSize(message.message.file.contentLength));
      setCreatedAt(moment(message.message.file.createdAt).format('DD/MM/YYYY HH:mm'));
      setDocumentUri(message.message.file.path);
    }
  }, [message]);

  function bytesToSize(bytes) {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    // eslint-disable-next-line eqeqeq
    if (bytes == 0) {
      return '0 Byte';
    }
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
  }

  const [jobDownloadId] = useState();
  const [isDownloading, setIsDownloading] = useState(false);
  const [percentage] = useState(0);
  const handleDocument = async () => {
    setIsDownloading(true);
    // const localFile =
    //   Platform.OS === 'ios'
    //     ? `${RNFS.DocumentDirectoryPath}/${new Date().getTime()}-${fullDocumentName}`
    //     : `${RNFS.DownloadDirectoryPath}/${new Date().getTime()}-${fullDocumentName}`;
    // console.log('🚀 ~ file: ReceiveMessage.js ~ line 58 ~ localFile', localFile);

    try {
      const fileData = await apiNode.get(`/files/object/chat/${message.message.file.aws_key}`);

      Linking.canOpenURL(fileData.data.url).then(supported => {
        if (supported) {
          Linking.openURL(fileData.data.url);
        } else {
          console.log("Don't know how to open URI: " + fileData.data.url);
        }
      });
    } catch (e) {
      console.log({ e });
    }
    setIsDownloading(false);
  };

  return (
    <View style={styles.container}>
      <TouchableOpacity
        onPress={() => {
          handleDocument();
        }}
        style={styles.receiveBox}>
        <Text style={styles.fileName} numberOfLines={1}>
          {documentName}
        </Text>
        {isDownloading ? (
          <TouchableOpacity
            style={styles.containerCancel}
            onPress={() => {
              stopDownload(jobDownloadId);
            }}>
            {/* <ProgressCircle
              percent={percentage}
              radius={12.5}
              color={colors.blackAlpha60}
              shadowColor={colors.greyEF}
              borderWidth={2}
              bgColor={colors.greyEF}>
              <Text style={styles.cancel}>{'X'}</Text>
            </ProgressCircle> */}
          </TouchableOpacity>
        ) : (
          <Icon name="file" size={25} color={colors.bluePro} />
        )}
      </TouchableOpacity>
      <View style={styles.infoBox}>
        <Text style={styles.textInfos}>{documentLength}</Text>
        <Text style={styles.textInfos}>{createdAt}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    margin: 10,
    marginRight: 120,
    shadowOffset: { width: 2, height: 2 },
    shadowRadius: 8,
    shadowColor: colors.blackAlpha60,
    elevation: 7,
    flexDirection: 'column',
    padding: 5,
    borderRadius: 8,
    backgroundColor: colors.white,
  },
  cancel: {
    textAlign: 'center',
    color: colors.blackAlpha60,
    fontFamily: fonts.BOLD,
    fontSize: 10,
  },
  receiveBox: {
    backgroundColor: colors.grayEF,
    borderRadius: 8,
    padding: 8,
    paddingHorizontal: 15,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  containerCancel: {
    width: 25,
    height: 25,
    borderRadius: 999,
    borderWidth: 1.5,
    borderColor: colors.bluePro,
    alignItems: 'center',
    justifyContent: 'center',
  },
  infoBox: {
    paddingHorizontal: 20,
    paddingVertical: 5,
    justifyContent: 'space-between',
    flexDirection: 'row',
    color: colors.bluePro,
    fontSize: 12,
  },
  textInfos: {
    color: colors.bluePro,
    fontSize: 12,
  },
  fileName: {
    ...textStyles.regular16,
    color: colors.bluePro,
  },
  receiveImage: {
    marginTop: '-10%',
    marginLeft: '-12%',
    width: 46,
    height: 46,
    borderRadius: 50,
  },
  receiveText: {
    fontSize: 14,
    color: colors.white,
    maxWidth: 260,
    paddingHorizontal: 8,
  },
});

export default ReceiveMessage;
