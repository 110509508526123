import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Image } from 'react-native';
import textStyles from 'app/styles/textStyles';
import ButtonBase from 'app/components/ButtonBase';
import colors from 'app/styles/colors';
import commons from 'app/styles/commons';
import closeGrey from 'app/images/closeGrey.png';
import ButtonAlerts from 'app/screens/core/hydration/components/ButtonAlerts';
import Spacer from 'app/components/Spacer';
import { useDispatch, useSelector } from 'react-redux';
import api from 'app/services/api';
import QueryString from 'qs';
import META from 'app/configs/meta';
import { selectOptionAlarm } from 'app/store/modules/water/actions';

const BottomSheetAlerts = ({ doClose, selected }) => {
  const [idAlarmSelected, setIdAlarmSelected] = useState(0);
  const [lastIdSelected, setLastIdSelected] = useState(1);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { alarmsWaterOptions } = useSelector(state => state.water);
  let aux = 0;
  useEffect(() => {
    const result = alarmsWaterOptions.filter(alarmsWaterOption => {
      return alarmsWaterOption.id == selected;
    });
    setLastIdSelected(result[0].id);
    selectItemAlarm(selected);
  }, []);

  const validAlertSelected = () => {
    switch (idAlarmSelected) {
      case 1:
        requestUserWaterNotification(1800);
        break;
      case 2:
        requestUserWaterNotification(3600);
        break;
      case 3:
        requestUserWaterNotification(7200);
        break;
      case 4:
        requestUserWaterNotification(14400);
        break;
      case 5:
        requestUserWaterNotification(28800);
        break;
      default:
        doClose();
        break;
    }
  };

  const selectItemAlarm = id => {
    dispatch(selectOptionAlarm(id));
    setIdAlarmSelected(id);
  };

  const requestUserWaterNotification = async newAlarmsTime => {
    setIsLoading(true);
    try {
      await api.patch(
        'user-metadata',
        QueryString.stringify({
          meta_key: META.USER_WATER_NOTIFICATION,
          meta_value: newAlarmsTime,
        })
      );
      doClose();
    } catch (error) {
      doClose();
    }
    setIsLoading(false);
  };

  const setLastItemAlarmSelected = () => {
    dispatch(selectOptionAlarm(lastIdSelected));
    doClose();
  };

  return (
    <View style={styles.container}>
      <Spacer value={24} />
      <View style={styles.containerHeader}>
        <Text style={textStyles.subtitle17}>Defina a recorrência dos seus alertas</Text>
        <TouchableOpacity onPress={setLastItemAlarmSelected}>
          <Image source={closeGrey} />
        </TouchableOpacity>
      </View>
      <Spacer value={24} />
      {alarmsWaterOptions.map(alarmWaterOption => {
        return (
          <ButtonAlerts
            id={alarmWaterOption.id}
            title={alarmWaterOption.title}
            isSelected={alarmWaterOption.isSelected}
            action={() => {
              selectItemAlarm(alarmWaterOption.id);
            }}
          />
        );
      })}
      <Spacer value={28} />
      <ButtonBase
        onPress={() => {
          validAlertSelected();
        }}
        style={styles.button}
        title="Salvar"
        fill
        square
        loading={isLoading}
      />
    </View>
  );
};

export default BottomSheetAlerts;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.paleGrey,
  },
  containerHeader: {
    ...commons.flexRowBetween,
    paddingHorizontal: 20,
  },
  button: {
    backgroundColor: colors.aquaMarineTwo,
  },
});
