/* eslint-disable react-native/no-inline-styles */

import fitnessCouple from 'app/images/fitnessCouple.png';
import redCorner from 'app/images/redCorner.png';
import womanStretching from 'app/images/womanStretching.png';
import OnboardingView from 'app/screens/intro/components/OnboardingView';
import api from 'app/services/api';
import { updateProfileRequest } from 'app/store/modules/user/actions';
import React, { useEffect, useState } from 'react';
import {
  Dimensions,
  StyleSheet,
  Text,
  TextInput,
  View,
  Image,
  TouchableOpacity,
} from 'react-native';
import ShadowView from '@liuyunjs/react-native-simple-shadow-view';
import { useDispatch, useSelector } from 'react-redux';
import StatusBarHolder from '../../components/StatusBarHolder';
import { translate } from '../../i18n/src/locale';
import pt from '../../i18n/src/locale/pt';
import greenCorner from '../../images/greenCorner.png';
import { sendActionEvent } from '../../services/utils';
import colors from '../../styles/colors';
import commons from '../../styles/commons';
import fonts from '../../styles/fonts';
import textStyles from '../../styles/textStyles';
import WelcomeImg from '../../assets/welcome.png';
import logoPng from '../../assets/logo.png';
import StartImg from '../../assets/start.png';
import MyMultiSelectView from 'app/components/MyMultiSelectView';
import { TextInputMask } from 'react-native-masked-text';
import { updateMeta } from 'app/services/apiMetas';
import META from 'app/configs/meta';
import moment from 'moment';
import genders from 'app/constants/genders';
import { ScrollView } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import useGlobalAlert from 'app/hooks/useGlobalAlert';

const { width } = Dimensions.get('screen');
const cardWidth = (width - 48) / 2;

const OptionSelect = props => {
  return (
    <TouchableOpacity
      onPress={() => props.onPress && props.onPress()}
      style={[
        styles.card_choice,
        {
          marginRight: 0,
          width: 160,
          borderColor: props.active ? colors.aquaMarineTwo : colors.lightPeriwinkle,
          borderWidth: props.active ? 2 : 1,
        },
        props.style,
      ]}>
      <View
        style={{
          ...commons.flex,
          justifyContent: 'center',
          width: props.fill ? cardWidth : null,
          borderRadius: 22.5,
          paddingHorizontal: 14,
        }}>
        <Text
          style={[
            textStyles.regular10white,
            {
              color: props.active ? colors.aquaMarineTwo : colors.blackTwo,
              textAlign: 'center',
            },
          ]}>
          {props.item.text}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

const activeCapsule = props => {
  return (
    <View
      style={[
        styles.card_choice,
        { borderColor: props.color || colors.aquaMarineTwo, borderWidth: 2 },
      ]}>
      <View
        style={{
          ...commons.flex,
          justifyContent: 'center',
          width: props.fill ? cardWidth : null,
          borderRadius: 22.5,
          paddingHorizontal: 14,
        }}>
        <Text style={[textStyles.semiWhite16, { color: props.color || colors.aquaMarineTwo }]}>
          {props.item.text}
        </Text>
      </View>
    </View>
  );
};

const desactiveCapsule = props => {
  return (
    <ShadowView style={styles.card_choice}>
      <View
        style={{
          ...commons.flex,
          justifyContent: 'center',
          width: props.fill ? cardWidth : null,
          borderRadius: 22.5,
          paddingHorizontal: 14,
        }}>
        <Text style={textStyles.semi16}>{props.item.text}</Text>
      </View>
    </ShadowView>
  );
};

const conditionList = [{
  id: 1,
  text: 'Restrição alimentar',
  translation: 'Restrição alimentar',
  value: 'Restrição alimentar',
  active: false,
},{
  id: 2,
  text: 'Restrições físicas',
  translation: 'Restrições físicas',
  value: 'Restrições físicas',
  active: false,
},{
  id: 3,
  text: 'Doença crônica',
  translation: 'Doença crônica',
  value: 'Doença crônica',
  active: false,
}];

const ImageAboveTitle = ({
  src,
  withLogo = true,
  imgCustomStyles = styles.welcomeImg,
  onlyLogo = false,
}) => {
  return onlyLogo ? (
    <View style={styles.logoImgContainer}>
      <Image source={logoPng} width={50} height={50} style={styles.logoImg} />
    </View>
  ) : (
    <View style={styles.ImageAboveTitleContainer}>
      {withLogo && (
        <View style={styles.logoImgContainer}>
          <Image source={logoPng} width={50} height={50} style={styles.logoImg} />
        </View>
      )}
      <Image source={src} style={imgCustomStyles} />
    </View>
  );
};

const BasicInformationFields = ({ onChangeInfoDataFields, infoData }) => {
  const [data] = useState(genders);

  const { birthdate, height, weight, gender } = infoData;
  return (
    <View>
      <Text style={[textStyles.bold16, { marginLeft: 20 }]}>Data de nascimento*</Text>
      <TextInputMask
        type={'custom'}
        placeholder="dd/mm/aaaa"
        keyboardType="numeric"
        value={birthdate}
        style={[styles.input, { alignSelf: 'stretch' }]}
        options={{
          mask: '99/99/9999',
        }}
        onChangeText={value => onChangeInfoDataFields(value, 'birthdate')}
        maxLength={12}
      />
      <Text style={[textStyles.bold16, { marginLeft: 20 }]}>Sexo*</Text>
      <View
        style={{
          flexDirection: 'row',
          paddingHorizontal: 20,
          marginTop: 10,
          width: "100%",
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        {data.map((g, index) => {
          if (index > 1) {
            return null;
          }
          return (
            <OptionSelect
              key={index}
              style={styles.basicGenderOptions}
              active={g.value === gender}
              onPress={() => onChangeInfoDataFields(g.value, 'gender')}
              item={g}
            />
          );
        })}
      </View>
      <View
        style={{
          flexDirection: 'row',
          paddingHorizontal: 20,
          marginTop: 10,
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        {data.map((g, index) => {
          if (index <= 1) {
            return null;
          }
          return (
            <OptionSelect
              key={index}
              style={styles.basicGenderOptions}
              active={g.value === gender}
              onPress={() => onChangeInfoDataFields(g.value, 'gender')}
              item={g}
            />
          );
        })}
      </View>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <View style={{ width: "40%" }}>
          <Text style={[textStyles.bold16, { marginLeft: 20 }]}>Altura*</Text>
          <TextInputMask
            type={'custom'}
            placeholder="cm"
            keyboardType="numeric"
            options={{
              mask: '999',
            }}
            style={[styles.input, { alignSelf: 'stretch', width: "100%" }]}
            value={height}
            onChangeText={value => onChangeInfoDataFields(value, 'height')}
          />
        </View>
        <View style={{ width: "40%" }}>
          <Text style={[textStyles.bold16, { marginRight: 20 }]}>Peso*</Text>
          <TextInputMask
            type={'custom'}
            placeholder="kg"
            keyboardType="numeric"
            options={{
              mask: '999',
            }}
            style={[
              styles.input,
              { alignSelf: 'stretch', width: "100%", marginRight: 20, marginLeft: 0 },
            ]}
            value={weight}
            onChangeText={value => onChangeInfoDataFields(value, 'weight')}
          />
        </View>
      </View>
    </View>
  );
};

const OnboardingScreen = () => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const { profile } = useSelector(state => state.user);
  const status = {
    WELCOME: 0,
    WAITING_NICK: 1,
    THANKS_NAME: 2,
    BASIC_INFORMATION: 3,
    WAITING_GOAL: 4,
    GREAT_GOALS: 5,
    WAITING_INTEREST: 6,
    RESTRICT_MESSAGE: 7,
    WAITING_RESTRICT: 8,
    WAITING_FINISH: 9,
    PHYSICAL_RESTRICTIONS: 10,
    CHRONIC_DESEASE: 11,
    HIRE_PROFESSIONALS: 12,
  };

  const [currentStatus, setStatus] = useState(status.WAITING_NICK);
  const [nick, setNick] = useState('');
  const [infoData, setInfoData] = useState({ birthdate: '', height: '', weight: '', gender: '' });
  const [goals, setGoals] = useState([]);
  const [dietaryRestrictions, setDietaryRestrictions] = useState([]);
  const [interests, setInterests] = useState([]);
  const [physicalRestrictions, setPhysicalRestrictions] = useState([]);
  const [illnesses, setIllnesses] = useState([]);
  const [conditions, setConditions] = useState(conditionList);
  const [pendingConditions, setPendingConditions] = useState();
  const { setAlert } = useGlobalAlert();

  const [others, setOthers] = useState({
    other_disease: '',
  });

  useEffect(() => {
    async function fillContent() {
      try {
        await getGoals();
        await getInterests();
        await getDietaryRestrictions();
        await getIllnesses();
        await getPhysicalRestrictions();
      } catch (error) {}
    }
    fillContent();
  }, []);

  const getGoals = async () => {
    const { data } = await api.get('multi-language/goals/locale');
    setGoals(data.map(item => ({ ...item, text: item.translation, active: false })));
  };

  const getDietaryRestrictions = async () => {
    const { data } = await api.get('multi-language/dietary-restrictions/locale');
    const state = data.map(item => ({
      ...item,
      text: item.name,
      hide: item.dietary_restriction_id == 27,
      default: item.dietary_restriction_id == 27,
    }));
    setDietaryRestrictions([...state, { dietary_restriction_id: 1, name: 'Outras', text: 'Outras' }]);
  };

  const getPhysicalRestrictions = async () => {
    const { data } = await api.get('muscle-group');

    const state = data.map(item => ({
      id: item.id,
      text: item.name,
      value: item.name,
      user_pain_level: 1,
      active: false,
    }));

    setPhysicalRestrictions(state);
  };

  const getIllnesses = async () => {
    const { data } = await api.get('chronic-diseases');

    const state = data.map(item => ({
      ...item,
      text: item.name,
      value: item.name,
      active: false,
    }));

    setIllnesses([
      ...state,
      { translation: 'Outras', value: 'Other', text: 'Outras', active: false },
    ]);
  };

  const getInterests = async () => {
    const { data: data } = await api.get('multi-language/interests/locale');
    setInterests(data.map(item => ({ ...item, text: item.translation })));
  };

  const conditionsSet = () => {
    const statuses = [status.WAITING_RESTRICT, status.PHYSICAL_RESTRICTIONS, status.CHRONIC_DESEASE];
    _pendingConditions = conditions
      .filter(condition => condition.active)
      .map(condition => statuses[condition.id - 1]);
    if (!_pendingConditions.length)
      return submitData();
    setPendingConditions(_pendingConditions);
  };

  useEffect(() => {
    if (pendingConditions !== undefined)
      setStatus(pendingConditions[0]);
  }, [pendingConditions]);

  const nextCondition = () => {
    let _pendingConditions = pendingConditions;
    _pendingConditions.shift();
    if (!_pendingConditions.length)
      return submitData();
    setPendingConditions(_pendingConditions);
  }

  const submitData = () => {
    sendActionEvent('finish_onboarding', { email: profile.email }, profile.email);

    navigation.navigate('Loading', {
      interests: interests.filter(entry => entry.active).map(entry => ({ interest_id: entry.interest_id })),
      goals: goals.filter(entry => entry.active).map(entry => ({ goal_id: entry.goal_id })),
      dietaryRestrictions: dietaryRestrictions.filter(entry => entry.active).map(entry => ({ dietary_restriction_id: entry.dietary_restriction_id })),
      physicalRestrictions: physicalRestrictions.filter(entry => entry.active).map(entry => ({ muscle_restriction_id: entry.id, muscle_pain_id: 1 })),
      illnesses: illnesses.filter(entry => entry.active).map(entry => entry.id),
      others
    });
  };

  const onMultiSelection = (setState) => {
    return (status, index) => {
      setState(state => {
        return state.map((item, itemIndex) => {
          return itemIndex === index ? { ...item, active: status } : item;
        });
      });
    }
  }

  const onChangeInfoDataFields = (value, field) => {
    if (!value) {
      return;
    }
    setInfoData(state => ({ ...state, [field]: value }));
  };

  const onChangeOthersFields = (value, field) => {
    if (!value) {
      return;
    }
    setOthers(state => ({ ...state, [field]: value }));
  };

  const { birthdate, height, weight, gender } = infoData;

  const onPressBasicInformationFields = async () => {
    try {
      await updateMeta(META.USER_WEIGHT, weight);
      await updateMeta(META.USER_HEIGHT, height);
      dispatch(
        updateProfileRequest({
          name: nick,
          weight,
          height,
          birthdate: moment(infoData.birthdate, 'DD/MM/YYYY').toDate(),
          gender: infoData.gender,
        })
      );
      console.log('FOI');
    } catch (error) {
      console.log(error.response.data);
    }
  };

  const hasOtherDietaryRestriction = dietaryRestrictions.find(item => item.active && item.text === 'Outras');
  const hasOtherPhysicalRestriction = physicalRestrictions.find(item => item.active && item.text === 'Outras');
  const hasOtherIllness = illnesses.find(item => item.active && item.text === 'Outras');

  return (
    <View behavior="height" style={{ backgroundColor: colors.paleGrey, width: "100%" }}>
      <StatusBarHolder backgroundColor="transparent" translucent />
      <ScrollView style={[commons.flex, { height: '100dvh', width: "100%"}]} contentContainerStyle={{paddingTop: 24}}>
        {currentStatus === status.WELCOME ? (
          <OnboardingView
            title={translate(pt.welcome)}
            content={translate(pt.to_start2)}
            image={fitnessCouple}
            imageStyles={{ bottom: 0 }}
            backgroundImage={greenCorner}
            backgroundImageStyles={styles.backgroundBottom}
            containerMessageStyles={{
              marginTop: 150,
            }}
            onPress={() => setStatus(status.WAITING_NICK)}
            animation="fadeInUp"
          />
        ) : currentStatus === status.WAITING_NICK ? (
          <OnboardingView
            title={translate(pt.welcome_to_namu)}
            content={translate(pt.how_you_want_to_be_called)}
            ready={nick.length >= 5}
            topComponent={<ImageAboveTitle src={WelcomeImg} imgCustomStyles={styles.welcomeImg} />}
            backgroundImage={greenCorner}
            backgroundImageStyles={styles.backgroundBottom}
            containerMessageStyles={{
              marginTop: 50,
            }}
            onPress={() => {
              if (nick.length < 5) {
                setAlert('Nome', 'Você precisa preencher seu nome com 5 caracteres ou mais. Se necessário, use o sobrenome!');
                return;
              }
              if (nick !== '') {
                dispatch(updateProfileRequest({ name: nick }));
                setStatus(status.THANKS_NAME);
              }
            }}
            children={
              <TextInput
                value={nick}
                onChangeText={setNick}
                placeholder={translate(pt.type_nick)}
                style={[styles.input, {  width: "90%" }]}
              />
            }
          />
        ) : currentStatus === status.THANKS_NAME ? (
          <OnboardingView
            title={`${translate(pt.thanks)}, ${nick}`}
            content={translate(pt.tell_your_goals)}
            topComponent={<ImageAboveTitle src={StartImg} imgCustomStyles={styles.startImg} />}
            imageStyles={{ height: 350, bottom: 0 }}
            backgroundImage={greenCorner}
            backgroundImageStyles={styles.backgroundBottom}
            containerMessageStyles={{
              marginTop: 150,
            }}
            animation="fadeInUp"
            buttonText={translate(pt.lets_go_to)}
            onPress={() => setStatus(status.BASIC_INFORMATION)}
          />
        ) : currentStatus === status.BASIC_INFORMATION ? (
          <OnboardingView
            title={translate(pt.Fill_in_the_fields_with_your_information)}
            ready={birthdate !== '' && height !== '' && weight !== '' && gender !== ''}
            topComponent={<ImageAboveTitle onlyLogo={true} />}
            imageStyles={{ height: 350, bottom: 0 }}
            backgroundImage={greenCorner}
            backgroundImageStyles={styles.backgroundBottom}
            containerMessageStyles={{
              marginTop: 60,
            }}
            children={
              <BasicInformationFields
                onChangeInfoDataFields={onChangeInfoDataFields}
                infoData={infoData}
              />
            }
            onPress={() => {
              onPressBasicInformationFields();
              setStatus(status.WAITING_GOAL);
            }}
          />
        ) : currentStatus === status.WAITING_GOAL ? (
          <OnboardingView
            title={translate(pt.define_your_goals)}
            ready={goals.some(item => item.active)}
            containerMessageStyles={{
              marginTop: 40,
            }}
            backgroundImage={greenCorner}
            backgroundImageStyles={styles.backgroundCornerRight}
            onPress={() => {
              setStatus(status.WAITING_INTEREST);
            }}
            children={
              <MyMultiSelectView
                checkedKey={'active'}
                valueKey={'goals'}
                style={{ marginTop: 30, marginHorizontal: 15 }}
                onSelectionStatusChange={onMultiSelection(setGoals)}
                data={goals}
                desactiveView={desactiveCapsule}
                activeView={activeCapsule}
              />
            }
          />
        ) : currentStatus === status.GREAT_GOALS ? (
          <OnboardingView
            title={translate(pt.great_goals)}
            image={womanStretching}
            imageStyles={{ height: 400, bottom: 0 }}
            animation="fadeInUp"
            backgroundImage={greenCorner}
            backgroundImageStyles={styles.backgroundBottom}
            containerMessageStyles={{
              marginTop: 150,
            }}
            children={
              <View style={{ marginTop: 20, alignItems: 'center' }}>
                <Text style={styles.paragraph}>
                  Caso queria mudar algum, você pode fazer ao longo da sua jornada conosco.
                </Text>
                <Text style={[styles.paragraph, { marginTop: 20 }]}>
                  Me diga, você tem interesse ou já praticou algumas das atividades a seguir?
                </Text>
              </View>
            }
            onPress={() => setStatus(status.WAITING_INTEREST)}
          />
        ) : currentStatus === status.WAITING_INTEREST ? (
          <OnboardingView
            title={translate(pt.activities_of_interest)}
            ready={interests.some(entry => entry.active)}
            containerMessageStyles={{
              marginTop: 40,
            }}
            backgroundImage={greenCorner}
            backgroundImageStyles={styles.backgroundCornerRight}
            onPress={() => setStatus(status.RESTRICT_MESSAGE)}
            children={
              <MyMultiSelectView
                checkedKey={'active'}
                valueKey={'interests'}
                style={{ marginTop: 30, marginHorizontal: 15 }}
                onSelectionStatusChange={onMultiSelection(setInterests)}
                data={interests}
                desactiveView={desactiveCapsule}
                activeView={activeCapsule}
              />
            }
          />
        ) : currentStatus === status.RESTRICT_MESSAGE ? (
          <OnboardingView
            title={translate(pt.have_any_conditions)}
            content={translate(pt.any_conditions_text)}
            backgroundImage={greenCorner}
            backgroundImageStyles={styles.backgroundBottom}
            animation="fadeInUp"
            containerMessageStyles={{
              marginTop: 150,
            }}
            children={
              <MyMultiSelectView
                checkedKey={'active'}
                valueKey={'conditions'}
                style={{ marginTop: 30, marginHorizontal: 15 }}
                onSelectionStatusChange={onMultiSelection(setConditions)}
                data={conditions}
                desactiveView={desactiveCapsule}
                activeView={activeCapsule}
              />
            }
            onPress={conditionsSet}
          />
        ) : currentStatus === status.WAITING_RESTRICT ? (
          <OnboardingView
            title={translate(pt.food_restriction)}
            content={translate(pt.what_foods_can_you_not_consume)}
            containerMessageStyles={{
              marginTop: 40,
            }}
            ready={dietaryRestrictions.some(entry => entry.active)}
            backgroundImage={redCorner}
            buttonColor={colors.darkRed}
            backgroundImageStyles={styles.backgroundCornerRight}
            onPress={nextCondition}
            children={
              <View>
                <MyMultiSelectView
                  checkedKey={'active'}
                  valueKey={'dietaryRestrictions'}
                  onSelectionStatusChange={onMultiSelection(setDietaryRestrictions)}
                  data={dietaryRestrictions}
                  desactiveView={desactiveCapsule}
                  activeView={props => activeCapsule({ ...props, ...{ color: colors.darkRed } })}
                />
                {hasOtherDietaryRestriction && (
                  <TextInput style={[styles.input, { alignSelf: 'stretch' }]} />
                )}
              </View>
            }
          />
        ) : currentStatus === status.PHYSICAL_RESTRICTIONS ? (
          <OnboardingView
            title={translate(pt.what_physical_restrictions)}
            content={translate(pt.muscles_can_you_not)}
            containerMessageStyles={{
              marginTop: 40,
            }}
            ready={physicalRestrictions.some(r => r.active == true)}
            backgroundImage={greenCorner}
            backgroundImageStyles={styles.backgroundCornerRight}
            onPress={nextCondition}
            children={
              <View>
                <MyMultiSelectView
                  checkedKey={'active'}
                  valueKey={'physicalRestrictions'}
                  onSelectionStatusChange={onMultiSelection(setPhysicalRestrictions)}
                  data={physicalRestrictions}
                  desactiveView={desactiveCapsule}
                  activeView={activeCapsule}
                />
                {hasOtherPhysicalRestriction && (
                  <TextInput style={[styles.input, { alignSelf: 'stretch' }]} />
                )}
              </View>
            }
          />
        ) : currentStatus === status.CHRONIC_DESEASE ? (
          <OnboardingView
            title={translate(pt.what_chronic_diseases)}
            content={translate(pt.what_chronic_illnesses_do_you_have)}
            containerMessageStyles={{
              marginTop: 40,
            }}
            ready={illnesses.some(r => r.active == true)}
            backgroundImage={greenCorner}
            backgroundImageStyles={styles.backgroundCornerRight}
            onPress={nextCondition}
            children={
              <View>
                <MyMultiSelectView
                  checkedKey={'active'}
                  valueKey={'illnesses'}
                  onSelectionStatusChange={onMultiSelection(setIllnesses)}
                  data={illnesses}
                  desactiveView={desactiveCapsule}
                  activeView={activeCapsule}
                />
                {hasOtherIllness && (
                  <TextInput
                    style={[styles.input, { alignSelf: 'stretch' }]}
                    onChangeText={value => onChangeOthersFields(value, 'other_disease')}
                  />
                )}
              </View>
            }
          />
        ) : currentStatus === status.HIRE_PROFESSIONALS ? (
          <OnboardingView
            title={translate(pt.hire_professionals)}
            content={translate(pt.hire_professionals_message)}
            image={require('app/images/profissional.png')}
            imageStyles={{ height: 500, bottom: -50 }}
            backgroundImage={greenCorner}
            backgroundImageStyles={styles.backgroundBottom}
            animation="fadeInUp"
            containerMessageStyles={{
              marginTop: 120,
            }}
            onPress={() => {
              sendActionEvent('finish_onboarding', { email: profile.email }, profile.email);
              /*Actions.pop();
              Actions.loading({
                arrInterests,
                arrObjectives,
                arrRestricts,
                others,
              });*/
            }}
          />
        ) : null}
      </ScrollView>
    </View>
  );
};

export default OnboardingScreen;

const styles = StyleSheet.create({
  editButton: {
    color: colors.aquaMarineTwo,
    fontFamily: fonts.BOLD,
    marginVertical: 10,
    marginHorizontal: 4,
  },
  containerView: {
    paddingHorizontal: 20,
  },
  ImageAboveTitleContainer: {
    position: 'relative',
    width: 317,
    height: 307,
  },
  welcomeImg: {
    position: 'absolute',
    width: 317,
    height: 417,
    resizeMode: 'contain',
    alignSelf: 'center',
    right: 8,
  },
  startImg: {
    position: 'absolute',
    width: 317,
    height: 417,
    resizeMode: 'contain',
  },
  logoImgContainer: {
    position: 'absolute',
    zIndex: 40,
    width: 50,
    height: 50,
    alignSelf: 'center',
  },
  logoImg: {
    width: 50,
    height: 50,
    resizeMode: 'contain',
  },
  card_choice: {
    borderWidth: 1,
    borderColor: '#C9CEE7',
    marginRight: 8,
    marginBottom: 10,
    height: 45,
    borderRadius: 22.5,
    backgroundColor: colors.white,
    alignContent: 'center',
    justifyContent: 'center',
  },
  select_goals: {
    fontFamily: fonts.BOLD,
    fontSize: 16,
    marginTop: 25,
    color: colors.slateGrey,
  },
  basicGenderOptions: {
    marginRight: 10,
    width: "30%"
  },
  scroll: { flex: 1 },
  input: {
    ...textStyles.semi16,
    textAlign: 'left',
    marginTop: 10,
    marginBottom: 12,
    marginHorizontal: 20,
    paddingHorizontal: 16,
    height: 45,
    borderRadius: 10,
    backgroundColor: colors.white,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: colors.lightPeriwinkle,
  },
  paragraph: {
    width: 300,
    fontSize: 16,
    fontFamily: fonts.REGULAR,
    textAlign: 'center',
  },
  logo: {
    width: 300,
    alignSelf: 'center',
    marginTop: -30,
  },
  backgroundBottom: {
    position: 'absolute',
    width: width,
    height: 400,
    right: -80,
    bottom: -50,
    resizeMode: 'contain',
  },
  backgroundCornerRight: {
    position: 'absolute',
    bottom: -50,
    right: 0,
    width: 300,
    height: 300,
    resizeMode: 'contain',
  },
});
