import produce from 'immer';

const INITIAL_STATE = {
  idSubCategorySelected: null,
  nameCategorySelected: '',
  typeShareSelected: '',
  nameShareSelected: '',
};

export default function category(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@category/SET_SUB_CATEGORY': {
        draft.idSubCategorySelected = action.payload.item.subCategoryId;
        draft.nameCategorySelected = action.payload.item.nameCategorySelected;
        break;
      }
      case '@category/SET_TYPE_SHARE': {
        draft.typeShareSelected = action.payload.tyeShare;
        break;
      }
      case '@category/SET_NAME_SHARE': {
        draft.nameShareSelected = action.payload.nameShare;
        break;
      }
    }
  });
}
