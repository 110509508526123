import React, { useCallback, useEffect, useState } from 'react';

import { View, StyleSheet, Text, Image, Platform } from 'react-native';
import colors from '../../../styles/colors';
import HeaderGeneric from '../../../components/HeaderGeneric';
import { SafeAreaView } from 'react-native-safe-area-context';
import textStyles from '../../../styles/textStyles';
import { translate } from '../../../i18n/src/locale';
import pt from '../../../i18n/src/locale/pt';
import Spacer from '../../../components/Spacer';
import ShadowView from '@liuyunjs/react-native-simple-shadow-view';
import CapsuleClock from '../../../components/CapsuleClock';
import ballFood from '../../../images/ballFood.png';
import activityBall from '../../../images/activityBall.png';
import meditationBall from '../../../images/meditationBall.png';
import ButtonBase from '../../../components/ButtonBase';
import clockGrayBig from '../../../images/clockGrayBig.png';
import fonts from '../../../styles/fonts';
import Modal from 'react-native-modal';
import ModalRecurrent from './components/ModalRecurrent';
import api from 'app/services/api';
import QueryString from 'qs';
import { showAlert, errorControl, flashInfo, sendActionEvent } from 'app/services/utils';
import { useSelector } from 'react-redux';
import { Picker } from '@react-native-picker/picker';
import { eachQuarterHour } from '../../../services/utils';
import { useNavigation, useRoute } from '@react-navigation/native';

const ChangeHourScreen = () => {
  const wheelPickerData = eachQuarterHour('00:00', '23:45');
  const navigation = useNavigation();
  const { profile } = useSelector(state => state.user);
  const [selectedHour, setSelectedHour] = useState(0);
  const [modal, setModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRecurrent, setIsRecurrent] = useState(false);
  const itemCategory = {
    name: '',
    icon: '',
  };
  const { item } = useRoute().params;

  useEffect(() => {
    requestIsRecurrent();
  }, [requestIsRecurrent]);

  const requestIsRecurrent = useCallback(async () => {
    try {
      const { data } = await api.get('user-schedule/is-recurrent?entry_id=' + item.entry_id);
      setIsRecurrent(data.recurrent);
    } catch (error) {
      showAlert(errorControl(error));
    }
  }, [item]);

  const getCategoryName = item => {
    if (item.meal_id) {
      return 'meal';
    }

    if (item.dish_id) {
      return 'dish';
    }

    if (item.class_id) {
      return 'class';
    }

    if (item.trail_id) {
      return 'trail';
    }

    return '';
  };

  switch (item.category_item) {
    case 1:
      itemCategory.icon = ballFood;
      itemCategory.name = getCategoryName(item);
      break;
    case 2:
      itemCategory.icon = activityBall;
      itemCategory.name = getCategoryName(item);
      break;
    case 3:
      itemCategory.icon = meditationBall;
      itemCategory.name = getCategoryName(item);
      break;
    default:
      itemCategory.icon = null;
      itemCategory.name = getCategoryName(item);
      break;
  }

  const onItemSelected = selectedItem => {
    setSelectedHour(selectedItem);
  };

  const getTitle = () => item.trail_name || item.meal_name || item.class_name || item.dish_name;

  const requestReschedule = async (recurrence = false) => {
    setIsLoading(true);

    const queryString = {
      item_id: item.id,
      hour: `${selectedHour}:00`,
      recurrence: recurrence ? 1 : 0,
    };

    try {
      const { data } = await api.patch(
        'user-schedule/change-hour',
        QueryString.stringify(queryString)
      );
      flashInfo(data);
      setModal(false);
      navigation.goBack();
    } catch (error) {
      showAlert(errorControl(error));
    }

    setIsLoading(false);
  };

  const handleSave = () => {
    if (!isRecurrent) {
      requestReschedule(false);
    } else {
      setModal(true);
    }
  };

  return (
    <SafeAreaView style={styles.container}>
      <HeaderGeneric />
      <View style={styles.subContainer} contentContainerStyle={styles.grow}>
        <View style={styles.flexCenter}>
          <Image source={clockGrayBig} style={{ width: 60, height: 60 }} />
          <Spacer value={10} />
          <Text style={textStyles.bold20}>{translate(pt.change_hour)}</Text>
          <Spacer value={10} />
          <Text style={textStyles.bold18}>{translate(pt.change_hour_content)}</Text>
          <ShadowView style={styles.card}>
            <Text style={styles.item}>{getTitle()}</Text>
            <CapsuleClock small duration={item.duration} />
            <Image style={styles.ball} source={itemCategory.icon} />
          </ShadowView>
            <Picker
              selectedValue={selectedHour}
              style={{ height: 60, width: 200 }}
              itemStyle={{ fontSize: 40 }}
              onValueChange={onItemSelected}>
              {wheelPickerData.map((hour, index) => {
                return <Picker.Item label={hour} value={hour} />;
              })}
            </Picker>
        </View>
        <View style={styles.bottom}>
          <ButtonBase
            onPress={() => handleSave()}
            backgroundColor={colors.aquaMarineTwo}
            title={translate(pt.save)}
            fill
            square
            loading={isLoading}
          />
        </View>

        <Modal animationInTiming={600} isVisible={modal}>
          <ModalRecurrent
            hour={wheelPickerData[selectedHour]}
            onPressRecurrence={() => {
              requestReschedule(true);
              let nameItem = getCategoryName(item);
              let hour = wheelPickerData[selectedHour];
              sendActionEvent(
                'changed_all_hour',
                {
                  type: nameItem,
                  hour: item.start_date.substring(16, 11),
                  to: hour,
                  email: profile.email,
                },
                profile.email
              );
            }}
            onPressNoRecurrence={() => {
              requestReschedule(false);
              let nameItem = getCategoryName(item);
              let hour = wheelPickerData[selectedHour];
              sendActionEvent(
                'changed_hour',
                {
                  type: nameItem,
                  hour: item.start_date.substring(16, 11),
                  to: hour,
                  email: profile.email,
                },
                profile.email
              );
            }}
            onClose={() => {
              setModal(false);
              navigation.goBack();
            }}
          />
        </Modal>
      </View>
    </SafeAreaView>
  );
};

export default ChangeHourScreen;

const styles = StyleSheet.create({
  itemWheelPicker: { fontSize: 36, fontFamily: fonts.BOLD },
  wheelPicker: { width: 300, height: 300 },
  subContainer: { flex: 1 },
  grow: { flexGrow: 1 },
  bottom: {
    flex: 1,
    justifyContent: 'flex-end',
    alignSelf: 'stretch',
    paddingBottom: 0,
  },
  flexCenter: { flex: 1, alignItems: 'center' },
  item: { ...textStyles.semi14, flex: 1 },
  card: {
    paddingHorizontal: 20,
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 20,
    marginHorizontal: 14,
    alignSelf: 'stretch',
    height: 52,
    borderRadius: 4,
    backgroundColor: colors.white,
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 4,
    shadowOpacity: 0.1,
  },
  container: {
    flex: 1,
    backgroundColor: colors.paleGrey,
  },
  ball: {
    marginLeft: 8,
    width: 21,
    height: 21,
  },
});
