/* eslint-disable react-native/no-inline-styles */
import React from 'react';

import { View, StyleSheet, Text } from 'react-native';
import colors from '../../../../styles/colors';
import textStyles from '../../../../styles/textStyles';

import 'moment';
import 'moment/locale/pt-br';
import moment from 'moment-timezone';

const MonthDayPointer = ({ text = 'jun', num = '9', date }) => (
  <View style={[styles.container, { backgroundColor: colors.white }]}>
    <Text style={[textStyles.regular14grey, { color: colors.blackTwo, marginLeft: 14 }]}>
      {moment(date).locale('pt-br').format('MMM')}
    </Text>
    <Text style={[textStyles.bold16, { color: colors.blackTwo, marginLeft: 4 }]}>
      {moment(date).date()}
    </Text>
  </View>
);

export default MonthDayPointer;

const styles = StyleSheet.create({
  container: {
    width: 'auto',
    alignSelf: 'baseline',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: 23,
    paddingRight: 12,
    marginBottom: 14,
    marginTop: 4,
    borderBottomRightRadius: 11.5,
    borderTopRightRadius: 11.5,
  },
});
