import apiNode from 'app/services/apiNode';
import api from 'app/services/api';

const webAlias = {
  Manuchat: 'manuchat',
  BlogPost: 'blog_post',
  ShowMoreClasses: 'show_more_classes',
  LessonActivity: 'lesson_activity',
  LessonMeditation: 'lesson_meditation',
  Trail: 'trail',
  MeditationTrail: 'meditation_trail',
  MealTrail: 'meal_trail',
  ShowMorePrograms: 'show_more_programs',
  MasterclassDetail: 'masterclass_detail',
  PlateDetail: 'plate_detail',
  MealDetail: 'meal_detail',
  Profile: 'profile',
  BirthdateEditing: 'birthdate_editing',
  GenderEditing: 'gender_editing',
  GoalEditing: 'goal_editing',
  Objectives: 'objectives',
  Interests: 'interests',
  ShowGyms: 'show_gyms',
  GymDetail: 'gym_detail'
}

const screens = {
  app_entering: { type: 'screen' },
  menu_home: { type: 'screen' },
  menu_calendar: { type: 'screen' },
  menu_professional: { type: 'screen' },
  menu_gym: { type: 'screen' },
  menu_explorer: { type: 'screen' },
  menu_blog: { type: 'screen' },
  gamification_leaderboard: { type: 'screen' },
  profile: { type: 'screen' },
  goal_editing: { type: 'screen' },
  favorites: { type: 'screen' },
  mood: { type: 'screen' },
  blog: { type: 'screen' },
  all_blog: { type: 'screen' },
  health_scan: { type: 'screen' },
  health_scan_instructions: { type: 'screen' },
  favorite_blog: { type: 'screen' },
  food_blog: { type: 'screen' },
  body_and_mind_blog: { type: 'screen' },
  fun_and_travel_blog: { type: 'screen' },
  aesthetics_blog: { type: 'screen' },
  health_blog: { type: 'screen' },
  philosophy_blog: { type: 'screen' },
  sustainability_blog: { type: 'screen' },
  widgets_control: { type: 'screen' },
  log: { type: 'screen' },
  analytics: { type: 'screen' },
  schedule: { type: 'screen' },
  orientation: { type: 'screen' },
  attendance: { type: 'screen' },
  hydration: { type: 'screen' },
  leaderboard: { type: 'screen' },
  gym_detail: { type: 'screen' },
  show_gyms: { type: 'screen' },
  weekly: { type: 'screen' },
  monthly: { type: 'screen' },
  diary: { type: 'screen' },
  quiz: { type: 'screen' },
  mind_explorer: { type: 'screen' },
  body_explorer: { type: 'screen' },
  food_explorer: { type: 'screen' },
  show_more_programs: { type: 'screen' },
  manuchat: { type: 'screen' },
  trail: { type: 'content', id: 'trailId' },
  meal_trail: { type: 'content', id: 'mealTrailId' },
  meditation_trail: { type: 'content', id: 'meditationId' },
  lesson_activity: { type: 'content', id: 'lessonId' },
  blog_post: { type: 'content', id: 'id' },
  lesson_meditation: { type: 'content', id: 'classId' },
  meal_detail: { type: 'content', id: 'mealId' },
  plate_detail: { type: 'content', id: 'plateId' },
  masterclass_detail: { type: 'content', id: 'coursepress_class_id' },
  exercice_class_screen: { type: 'content', id: 'exerciseId' },
  masterclass_file: { type: 'download', id: 'id' },
  show_more_classes: { type: 'classes_search', id: 'url' },
  'professional-filter-result': { type: 'professional_search', id: 'categoryId' },
};

const alias = {
  _gym: 'menu_gym',
  _professional: 'menu_professional',
};

const verify = ({ routeName, params }) => {
  if (routeName in screens || routeName in alias || routeName in webAlias) {
    const { type, id } = screens[webAlias[routeName] || alias[routeName] || routeName];
    let result = { screen: webAlias[routeName] || alias[routeName] || routeName, type };
    if (type !== 'screen') {
      result = {
        ...result,
        id: params[id],
      };
    }
    return result;
  }
  return false;
};

const HandleActivity = async route => {
  try {
    const result = verify(route);
    if (result !== false) {
      api.post('/activity', result);
      return await apiNode.post('/game-users/save-event', result);
    }
  } catch (error) {
    console.log('error save event:', error);
  }
};

export default HandleActivity;
