export function setCurrentGoal(data) {
  return {
    type: '@gamification/SET_CURRENT_GOAL',
    payload: { data },
  };
}
export function setStartedJourney(value) {
  return {
    type: '@gamification/SET_STARTED_JOURNEY',
    payload: { value },
  };
}

export function setUserData(data) {
  return {
    type: '@gamification/SET_USER_DATA',
    payload: { data },
  };
}

export function setIsShowBoarding(status) {
  return {
    type: '@gamification/SET_IS_SHOW_BOARDING',
    payload: { status },
  };
}
