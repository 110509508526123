import React, { useState, useEffect, useCallback } from 'react';

import { View, StyleSheet, Text, Image, ScrollView } from 'react-native';
import colors from '../../../styles/colors';
import HeaderGeneric from '../../../components/HeaderGeneric';
import { SafeAreaView } from 'react-native-safe-area-context';
import calendarGrayMenu from '../../../images/calendarGrayMenu.png';
import textStyles from '../../../styles/textStyles';
import { translate } from '../../../i18n/src/locale';
import pt from '../../../i18n/src/locale/pt';
import Spacer from '../../../components/Spacer';
import ShadowView from '@liuyunjs/react-native-simple-shadow-view';
import CapsuleClock from '../../../components/CapsuleClock';
import ballFood from '../../../images/ballFood.png';
import activityBall from '../../../images/activityBall.png';
import meditationBall from '../../../images/meditationBall.png';
import ButtonBase from '../../../components/ButtonBase';
import api from 'app/services/api';
import QueryString from 'qs';
import { showAlert, errorControl, sendActionEvent } from 'app/services/utils';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useNavigation, useRoute } from '@react-navigation/native';
import Calendar from 'react-calendar';

const ChangeDateScreen = () => {
  const { profile } = useSelector(state => state.user);
  const [selected, setSelected] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigation = useNavigation();
  const { item } = useRoute().params;

  const _format = 'YYYY-MM-DD';
  const _today = moment().format(_format);

  const onDayPress = day => {
    setSelected(day);
  };

  const getCategoryIcon = () => {
    if (item.category_item === 1) {
      return ballFood;
    }
    if (item.category_item === 2) {
      return activityBall;
    }
    if (item.category_item === 3) {
      return meditationBall;
    }
    return null;
  };

  const getCategoryName = itemCategory => {
    if (itemCategory.meal_id) {
      return 'meal';
    }

    if (itemCategory.dish_id) {
      return 'dish';
    }

    if (itemCategory.class_id) {
      return 'class';
    }

    if (itemCategory.trail_id) {
      return 'trail';
    }

    return '';
  };

  const requestReschedule = async () => {
    const requestData = {
      item_id: item.id,
      change_recurrence: 0,
      date: selected,
    };

    setIsLoading(true);
    try {
      await api.patch('user-schedule/change-date', QueryString.stringify(requestData));
      navigation.goBack();
    } catch (error) {
      showAlert(errorControl(error));
    }
    let name = getCategoryName(item);
    let day = selected;
    sendActionEvent(
      'changed_date',
      {
        type: name,
        day: item.start_date.substring(11, 0),
        to: day,
        email: profile.email,
      },
      profile.email
    );
    setIsLoading(false);
  };

  const getTitle = () => item.trail_name || item.meal_name || item.class_name || item.dish_name;

  useEffect(() => {
    requestScheduleFromMonth(moment());
  }, [requestScheduleFromMonth]);

  const requestScheduleFromMonth = useCallback(
    async month => {
      const startOfMonth = moment(month).startOf('month').format('YYYY-MM-DD');
      const endOfMonth = moment(month).endOf('month').format('YYYY-MM-DD');
      const { data } = await api.get(
        `user-schedule?timestamp_start=${startOfMonth}&timestamp_end=${endOfMonth}`
      );
    },
    [isLoading]
  );

  return (
    <SafeAreaView style={styles.container}>
      <HeaderGeneric />
      <ScrollView contentContainerStyle={styles.grow}>
        <View style={styles.flexCenter}>
          <Image source={calendarGrayMenu} style={{ width: 60, height: 60 }} />
          <Spacer value={10} />
          <Text style={textStyles.bold20}>{translate(pt.change_data)}</Text>
          <Spacer value={10} />
          <Text style={textStyles.bold18}>{translate(pt.change_data_content)}</Text>
          <ShadowView style={styles.card}>
            <Text style={styles.item}>{getTitle()}</Text>
            <CapsuleClock small duration={item.duration} />
            <Image style={styles.ball} source={getCategoryIcon()} />
          </ShadowView>
          <View style={styles.calendarContainer}>
            <Calendar 
              onClickDay={(value) => {
                onDayPress(moment(value).format('YYYY-MM-DD'))
              }}
              locale='pt'
              formatShortWeekday={(_, date) => moment(date).format('dddd')[0]}
              calendarType='hebrew'
              minDate={new Date()}
            />
          </View>
        </View>
        <View style={styles.bottom}>
          {selected && (
            <ButtonBase
              loading={isLoading}
              onPress={requestReschedule}
              backgroundColor={colors.aquaMarineTwo}
              title={translate(pt.save)}
              fill
              square
            />
          )}
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default ChangeDateScreen;

const styles = StyleSheet.create({
  grow: { flexGrow: 1 },
  bottom: {
    flex: 1,
    justifyContent: 'flex-end',
    alignSelf: 'stretch',
    paddingBottom: 0,
  },
  flexCenter: { alignItems: 'center' },
  item: { ...textStyles.semi14, flex: 1 },
  card: {
    paddingHorizontal: 20,
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 20,
    marginHorizontal: 14,
    alignSelf: 'stretch',
    height: 52,
    borderRadius: 4,
    backgroundColor: colors.white,
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 4,
    shadowOpacity: 0.1,
  },
  container: {
    flex: 1,
    backgroundColor: colors.paleGrey,
    paddingBottom: 52,
  },
  ball: {
    marginLeft: 8,
    width: 21,
    height: 21,
  },
  calendarContainer: {
    backgroundColor: colors.white,
    flex: 1,
    alignSelf: 'center',
  }
});
