import { errorControl, showAlert } from 'app/services/utils';
import commons from 'app/styles/commons';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { TouchableOpacity } from 'react-native';
import Icon from 'react-native-vector-icons/dist/FontAwesome';
import { sendActionEvent } from 'app/services/utils';
import { useDispatch, useSelector } from 'react-redux';
import apiNode from 'app/services/apiNode';
import { handleFavorites } from 'app/store/modules/favorites/actions';

const FavoriteButton = ({ isFavorited, propType, itemId }) => {
  const [isFavoritedItem, setIsFavoritedItem] = useState(isFavorited);
  const { profile } = useSelector(state => state.user);

  const getIconFavorite = () => {
    return isFavoritedItem ? 'heart' : 'heart-o';
  };

  const { favorites } = useSelector(state => state.favorites);
  const dispatch = useDispatch();

  useEffect(() => {
    if (favorites && favorites.length) {
      let item = favorites.find(x => x.type === propType && x.id === itemId);
      if (item) {
        setIsFavoritedItem(item.checked);
      }
    }
  }, [dispatch, favorites, isFavoritedItem]);

  const requestAddToFavorites = async () => {
    const url = propType == 'trailId' ? '/user-favorite-trails/' : '/user-favorites-classes/';
    try {
      await apiNode.post(url, {
        [propType]: itemId,
        userId: profile.user_id,
      });

      sendActionEvent(
        'favorites',
        {
          email: profile.email,
          item_type: propType,
          item_id: itemId,
        },
        profile.email
      );
      let fav = { type: propType, id: itemId, checked: true };
      dispatch(handleFavorites(fav));
    } catch (error) {
      showAlert(errorControl(error));
    }
  };

  const requestRemoveFromFavorites = async () => {
    const url = propType === 'trailId' ? '/user-favorite-trails/' : '/user-favorites-classes/';
    try {
      sendActionEvent(
        'favorites',
        {
          email: profile.email,
          item_type: propType,
          item_id: itemId,
        },
        profile.email
      );

      await apiNode.delete(url, {
        data: {
          [propType]: itemId,
          userId: profile.user_id,
        },
      });

      let fav = { type: propType, id: itemId, checked: false };
      dispatch(handleFavorites(fav));
    } catch (error) {
      showAlert(errorControl(error));
    }
  };

  const handleRequest = () => {
    if (isFavoritedItem === false) {
      return requestAddToFavorites();
    }

    return requestRemoveFromFavorites();
  };

  return isFavorited === null ? null : (
    <TouchableOpacity
      onPress={() => {
        handleRequest();
      }}
      style={commons.round}>
      <Icon name={getIconFavorite()} size={18} color="#FFF" />
    </TouchableOpacity>
  );
};

export default FavoriteButton;
