import ControlsVideo from 'app/components/ControlsVideo';
import React from 'react';
import Video from '@lnormanha/react-native-web-video/build/Video';
import { View, StyleSheet, Dimensions, ActivityIndicator } from 'react-native';
import fullscreenClose from '../../images/fullscreenClose.png';
import fullscreenOpen from '../../images/fullscreenOpen.png';
import { TouchableOpacity } from 'react-native';
import { TouchableWithoutFeedback } from 'react-native';
import { Image } from 'react-native';
import ProgressBarWithTimer from 'app/components/ProgressBarWithTimer';
import colors from 'app/styles/colors';

const MealVideo = ({
  showControls,
  fullscreen,
  videoRef,
  videoUrl,
  onLoadEnd,
  onProgress,
  onEnd,
  isVideoLogin,
  handleFullscreen,
  handlePlayPause,
  state,
  onSeek,
}) => {
  return (
    <TouchableWithoutFeedback onPress={showControls}>
      <View style={!fullscreen && styles.containerVideo}>
        <Video
          ref={videoRef}
          style={fullscreen ? styles.fullscreenVideo : styles.video}
          source={{
            uri: videoUrl,
          }}
          muted={true}
          controls={false}
          resizeMode="cover"
          onLoad={onLoadEnd}
          onProgress={onProgress}
          onEnd={onEnd}
          paused={!state.play}
          ignoreSilentSwitch="ignore"
        />
        {isVideoLogin && (
          <ActivityIndicator color={colors.aquaMarine} style={styles.containerIndicator} />
        )}
        {state.showControls && (
          <View style={styles.controlOverlay}>
            <TouchableOpacity
              onPress={handleFullscreen}
              style={[
                styles.fullscreenButton,
                styles.fullscreenControls,
                // eslint-disable-next-line react-native/no-inline-styles
                { top: fullscreen ? 20 : 0, right: fullscreen ? 20 : 0 },
              ]}>
              {fullscreen ? (
                <Image style={styles.fullscreenOpen} source={fullscreenClose} />
              ) : (
                <Image style={styles.fullscreenClose} source={fullscreenOpen} />
              )}
            </TouchableOpacity>
            <ControlsVideo
              onPlay={handlePlayPause}
              onPause={handlePlayPause}
              playing={state.play}
            />
            <ProgressBarWithTimer
              currentTime={state.currentTime}
              duration={state.duration > 0 ? state.duration : 0}
              onSlideStart={handlePlayPause}
              onSlideComplete={handlePlayPause}
              onSlideCapture={onSeek}
            />
          </View>
        )}
      </View>
    </TouchableWithoutFeedback>
  );
};

export default MealVideo;

const styles = StyleSheet.create({
  containerIndicator: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    zIndex: 111,
    right: 0,
  },
  containerVideo: { marginHorizontal: 12 },
  video: {
    height: Dimensions.get('window').width * (9 / 17),
    backgroundColor: colors.paleGrey,
  },
  fullscreenVideo: {
    height: Dimensions.get('screen').width,
    width: Dimensions.get('screen').height,
    backgroundColor: 'black',
  },
  controlOverlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#00000080',
    justifyContent: 'space-between',
  },
  fullscreenButton: {
    flex: 1,
    flexDirection: 'row',
    alignSelf: 'flex-end',
    alignItems: 'center',
  },
  fullscreenControls: {
    padding: 5,
    position: 'absolute',
    zIndex: 2,
  },
  fullscreenClose: { width: 20, height: 20 },
  fullscreenOpen: { width: 20, height: 20 },
});
