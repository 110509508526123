import React from 'react';
import { View, Text, StyleSheet, Image, TouchableOpacity } from 'react-native';
import textStyles from '../../../../styles/textStyles';
import colors from '../../../../styles/colors';
import videoLock from '../../../../images/videoLock.png';
import api from 'app/services/api';
import CheckBox from 'app/components/CheckBox';

const RowMaterial = ({ masterclass_id, onPress, payment_level, material, downloaded = null }) => {
  return (
    <TouchableOpacity
      onPress={!payment_level ? onPress : () => null}
      style={styles.card}>
      {payment_level && (
        <>
          <View style={styles.oval}>
            <Image source={videoLock} />
          </View>
          <View style={styles.mask} />
        </>
      )}
      <Image
        source={{
          uri: material?.material_pic_url,
          headers: {
            Authorization: api.defaults.headers.Authorization,
          },
        }}
        style={styles.image}
      />
      <View style={styles.holder}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          {downloaded !== null && (
            <CheckBox
              style={{ marginRight: 5 }}
              uncheckedIconStyle={{ tintColor: '#c7c7c7' }}
              checked={downloaded}
              size={16}
            />
          )}
          <Text style={textStyles.semi16}>{material?.title_material}</Text>
        </View>
        <Text numberOfLines={3} style={styles.content}>
          {material?.description_material}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

export default RowMaterial;

const styles = StyleSheet.create({
  content: { ...textStyles.regular14, marginTop: 6 },
  holder: { flex: 1, marginHorizontal: 12, paddingVertical: 10, justifyContent: 'center' },
  image: {
    width: 138,
    borderTopLeftRadius: 3,
    borderBottomLeftRadius: 3,
    backgroundColor: colors.base,
  },
  oval: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 34 / 2,
    zIndex: 5,
    top: 71 / 2 - 34 / 2,
    left: 20,
    width: 34,
    height: 34,
    backgroundColor: colors.greyBlue,
  },
  mask: {
    position: 'absolute',
    zIndex: 3,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    minHeight: 71,
    borderRadius: 3,
    opacity: 0.5,
    backgroundColor: colors.white,
  },
  card: {
    minHeight: 160,
    flexDirection: 'row',
    marginBottom: 14,
    borderRadius: 3,
    backgroundColor: colors.white,
  },
});
