import React, { useState, useRef, useEffect } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Image, Platform, StatusBar } from 'react-native';
import textStyles from 'app/styles/textStyles';
import pt from 'app/i18n/src/locale/pt';
import { translate } from 'app/i18n/src/locale';
import rightArrow from '../../../../images/rightArrow.png';
import removeWaterAlarm from '../../../../images/removeWaterAlarm.png';
import addAlarmWater from '../../../../images/addAlarmWater.png';
import commons from 'app/styles/commons';
import colors from 'app/styles/colors';
import Spacer from 'app/components/Spacer';
import RBSheet from 'react-native-raw-bottom-sheet';
import BottomSheetTimer from './BottomSheetTimer';
import BottomSheetAlerts from 'app/screens/core/hydration/components/BottomSheetAlerts';
import { useSelector, useDispatch } from 'react-redux';
import api from 'app/services/api';
import QueryString from 'qs';
import META from 'app/configs/meta';
import moment from 'moment';
import { removeHourCustomAlarm } from 'app/store/modules/water/actions';
import CheckBox from 'app/components/CheckBox';

const CustomHeader = ({ title = false }) => (
  <View style={styles.header}>
    <Text style={[textStyles.semi14, { textTransform: 'uppercase' }]}>{translate(title)}</Text>
  </View>
);

const WaterControl = () => {
  const [, setActionModal] = useState('');
  const [typeModalTimer, setTypeModalTimer] = useState('NORMAL');
  const [reconrences, setReconrences] = useState('A cada duas horas');
  const [id, setId] = useState(1);
  const [isCustom, setIsCustom] = useState(false);
  const refRBSheet = useRef();
  const refRBSheetTimer = useRef();
  const dispatch = useDispatch();
  const { alarmsWaterOptions, customAlarms } = useSelector(state => state.water);
  const { profile } = useSelector(state => state.user);
  const [isEnableNotification, setIsEnableNotification] = useState(false);
  const openModal = action => {
    setActionModal(action);
    refRBSheet.current.open();
    if (Platform.OS === 'android') {
      StatusBar.setBackgroundColor(colors.cornflowerBlue);
    }
  };

  useEffect(() => {
    getOptionAlarmSelected();
  }, []);

  useEffect(() => {
    checkIsCustom();
  }, [alarmsWaterOptions]);

  const checkIsCustom = () => {
    const result = alarmsWaterOptions.some(alarmWaterOption => {
      return alarmWaterOption.title.includes('Personalizado') && alarmWaterOption.isSelected;
    });
    setIsCustom(result);
  };

  const openModalTimer = action => {
    setActionModal(action);
    refRBSheetTimer.current.open();
    if (Platform.OS === 'android') {
      StatusBar.setBackgroundColor(colors.cornflowerBlue);
    }
  };

  const closeModal = async () => {
    refRBSheet.current.close();
    refRBSheetTimer.current.close();
  };

  const onClose = () => {
    if (Platform.OS === 'android') {
      StatusBar.setBackgroundColor(colors.transparent);
    }
    getOptionAlarmSelected();
  };

  const getOptionAlarmSelected = async () => {
    const { data } = await api.post(
      'user-metadata/filter',
      QueryString.stringify({
        meta_key: 'user_water_notification',
      })
    );

    const { data: enabled } = await api.post(
      'user-metadata/filter',
      QueryString.stringify({
        meta_key: META.USER_WATER_ENABLE,
      })
    );

    if (enabled[0]) {
      // eslint-disable-next-line eqeqeq
      setIsEnableNotification(enabled[0].meta_value == '1' ? true : false);
    }

    if (data[0].meta_value === '28800') {
      setReconrences('A cada oito horas');
      setId(5);
    } else if (data[0].meta_value === '14400') {
      setReconrences('A cada quatro horas');
      setId(4);
    } else if (data[0].meta_value === '7200') {
      setReconrences('A cada duas horas');
      setId(3);
    } else if (data[0].meta_value === '3600') {
      setReconrences('A cada uma hora');
      setId(2);
    } else if (data[0].meta_value === '1800') {
      setReconrences('A cada meia hora');
      setId(1);
    }
  };

  const formatDateToHours = () => {
    if (!profile.alarm_clock) {
      return '00:00';
    }
    let date = profile.alarm_clock.split(' ')[0];
    let hour = profile.alarm_clock.split(' ')[1];
    if (hour.length < 8) {
      hour = `${date} 0${hour}`;
      return moment(hour).format('HH:mm');
    }
    return moment(profile.alarm_clock).format('HH:mm');
  };

  const actionTypeAlerts = type => {
    setTypeModalTimer(type);
    openModalTimer();
  };

  const secondsToHours = metaValue => {
    metaValue = Number(metaValue);
    const hours = Math.floor(metaValue / 3600);

    const hoursDisplay = hours > 0 ? hours : '0';
    if (hoursDisplay.toString().length === 1) {
      return `0${hoursDisplay}:00`;
    }
    return `${hoursDisplay}:00`;
  };

  const handleCustomSubmit = async (data, customAlarm) => {
    try {
      await api.put('user-metadata', {
        data: data,
      });
      dispatch(removeHourCustomAlarm(customAlarm));
    } catch (error) {}
  };

  const handleToggleNotification = async () => {
    const enabled = !isEnableNotification;

    setIsEnableNotification(enabled);

    await api.patch(
      'user-metadata',
      QueryString.stringify({
        meta_key: META.USER_WATER_ENABLE,
        meta_value: enabled ? '1' : '0',
      })
    );
  };

  const validRemoveAlarm = customAlarm => {
    const results = customAlarms
      .filter(alarm => {
        return alarm !== customAlarm;
      })
      .map(cutomAlarm => {
        return {
          meta_key: META.USER_WATER_NOTIFICATION,
          meta_value: cutomAlarm,
        };
      });
    handleCustomSubmit(results, customAlarm);
  };

  return (
    <View style={styles.container}>
      <CustomHeader title={pt.consumption_configuration} />
      <View style={styles.containerActions}>
        <TouchableOpacity onPress={handleToggleNotification} style={styles.dailyGoal}>
          <Text style={textStyles.semi14}>{translate(pt.enable_notification)}</Text>
          {isEnableNotification ? <CheckBox checked /> : <CheckBox checked={false} />}
        </TouchableOpacity>
        <TouchableOpacity onPress={openModal} style={styles.dailyGoal}>
          <Text style={textStyles.semi14}>{translate(pt.recurrence_alerts)}</Text>
          <View style={styles.controlQntWaterRow}>
            <Text style={textStyles.bold14}>{reconrences}</Text>
            <Spacer value={4} />
            <Image source={rightArrow} style={{ width: 10, height: 10 }} />
          </View>
        </TouchableOpacity>

        {!isCustom && (
          <TouchableOpacity
            onPress={() => {
              actionTypeAlerts('NORMAL');
            }}
            style={styles.dailyGoal}>
            <Text style={textStyles.semi14}>{translate(pt.first_alert)}</Text>
            <View style={styles.controlQntWaterRow}>
              <Text style={textStyles.bold14}>{formatDateToHours()}</Text>
              <Spacer value={4} />
              <Image source={rightArrow} style={{ width: 10, height: 10 }} />
            </View>
          </TouchableOpacity>
        )}
      </View>
      {isCustom && (
        <View style={{ flex: 1 }}>
          <Spacer value={30} />
          <CustomHeader title={pt.define_alerts} />
          <Spacer value={12} />
          <View style={[styles.rowAlarm]}>
            <View style={[styles.rowAlarm, { flex: 1, flexWrap: 'wrap', marginLeft: 14 }]}>
              {customAlarms.map(customAlarm => {
                return (
                  <View style={{ marginBottom: 14, flexDirection: 'row', alignItems: 'center' }}>
                    <View style={styles.containerTimeAlarm}>
                      <Text>{secondsToHours(customAlarm)}</Text>
                    </View>
                    <Spacer value={9} />
                    <TouchableOpacity
                      onPress={() => {
                        validRemoveAlarm(customAlarm);
                      }}>
                      <Image source={removeWaterAlarm} style={{ width: 20, height: 20 }} />
                    </TouchableOpacity>
                    <Spacer value={14} />
                  </View>
                );
              })}
            </View>
            <TouchableOpacity
              onPress={() => {
                actionTypeAlerts('CUSTOM');
              }}
              style={{ alignItems: 'flex-end', marginRight: 23 }}>
              <Image source={addAlarmWater} style={{ width: 20, height: 20 }} />
            </TouchableOpacity>
          </View>
        </View>
      )}

      <RBSheet onClose={onClose} ref={refRBSheet} height={435} animationType="none" duration={200}>
        <BottomSheetAlerts doClose={closeModal} selected={id} />
      </RBSheet>
      <RBSheet
        onClose={onClose}
        ref={refRBSheetTimer}
        height={375}
        animationType="none"
        duration={200}>
        <BottomSheetTimer doClose={closeModal} type={typeModalTimer} timer={formatDateToHours} />
      </RBSheet>
    </View>
  );
};

export default WaterControl;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  header: {
    height: 40,
    backgroundColor: colors.white,
    paddingHorizontal: 24,
    justifyContent: 'center',
  },
  containerActions: {
    paddingHorizontal: 14,
  },
  dailyGoal: {
    ...commons.flexRowBetween,
    height: 47,
    borderRadius: 3,
    backgroundColor: colors.white,
    marginTop: 10,
    paddingHorizontal: 15,
  },
  controlQntWaterRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  arrowRight: {
    width: 12,
    height: 7,
  },
  containerTimeAlarm: {
    width: 60,
    height: 37,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 3,
    backgroundColor: colors.white,
  },
  rowAlarm: {
    flexDirection: 'row',
  },
});
