import React from 'react';
import { TouchableOpacity, Text, Image, StyleSheet } from 'react-native';
import pt from 'app/i18n/src/locale/pt';
import arrowRightWater2 from '../images/arrowRightWater2.png';
import { translate } from 'app/i18n/src/locale';
import colors from 'app/styles/colors';
import fonts from 'app/styles/fonts';

const ShowMoreButton = ({ onPress, color, style }) => {
  return (
    <TouchableOpacity onPress={() => onPress()} style={{ ...styles.showMoreButton, ...style }}>
      <Text style={{ ...styles.showMoreButtonText, color }}>{translate(pt.show_more)}</Text>
      <Image
        style={{ tintColor: color, width: 20, height: 20, resizeMode: 'contain' }}
        source={arrowRightWater2}
      />
    </TouchableOpacity>
  );
};

export default ShowMoreButton;

const styles = StyleSheet.create({
  showMoreButton: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  showMoreButtonText: {
    color: colors.cornflowerBlue,
    fontFamily: fonts.BOLD,
    fontSize: 14,
    marginRight: 11,
  },
});
