import React from 'react';

import { View, StyleSheet, Text, TouchableOpacity } from 'react-native';
import ButtonBase from '../../../../components/ButtonBase';
import colors from '../../../../styles/colors';
import fonts from '../../../../styles/fonts';
import textStyles from '../../../../styles/textStyles';
import pt from '../../../../i18n/src/locale/pt';
import { translate } from '../../../../i18n/src/locale';

const ModalTrail = ({ onClose }) => (
  <View style={styles.modal}>
    <View style={styles.card}>
      <Text style={textStyles.semi16}>{translate(pt.begin_trail)}</Text>
      <Text style={[textStyles.regular14, { marginTop: 10 }]}>
        Para que você adicione um programa ao carrinho. É necessário iniciá-lo antes.
      </Text>
      <ButtonBase
        onPress={() => {}}
        backgroundColor={colors.orangeyRed}
        fill
        title={translate(pt.begin_trail)}
      />
      <TouchableOpacity onPress={onClose}>
        <Text style={styles.back_button}>Voltar</Text>
      </TouchableOpacity>
    </View>
  </View>
);

export default ModalTrail;

const styles = StyleSheet.create({
  back_button: {
    marginTop: 20,
    alignSelf: 'center',
    fontFamily: fonts.BOLD,
    fontSize: 14,
    color: colors.orangeyRed,
  },
  card: {
    alignSelf: 'stretch',
    marginHorizontal: 30,
    paddingHorizontal: 30,
    paddingVertical: 20,
    borderRadius: 5,
    backgroundColor: colors.white,
  },
  modal: { flex: 1, alignItems: 'center', justifyContent: 'center' },
});
