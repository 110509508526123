/**
 * react-native-multiselect-view
 * Customizable list component with complex content for react native, it works on iOS and Android
 * https://github.com/fukhaos/react-native-multiselect-view
 * Email:fukhaos@gmail.com
 * Profile:https://www.linkedin.com/in/fukhaos/
 */

import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import PropTypes from 'prop-types';
import ListItem from './ListItem';

const MyMultiSelectView = ({
  style,
  checkedKey,
  activeContainerStyle,
  inactiveContainerStyle,
  activeTextStyle,
  inactiveTextStyle,
  activeIcon,
  inactiveIcon,
  onSelectionStatusChange,
  valueKey,
  data,
  activeView,
  desactiveView,
}) => {
  const onTouch = (status, index) => {
    const newData = [...data];
    newData[index].checked = status;
    if (onSelectionStatusChange) {
      onSelectionStatusChange(status, index, data[index]);
    }
  };

  return (
    <View style={{ style, flexDirection: 'row', flexWrap: 'wrap', flex: 1 }}>
      {data &&
        data.map((item, index) => (
          <ListItem
            key={index}
            index={index}
            item={item}
            text={item.value}
            checked={item.active}
            activeView={activeView}
            desactiveView={desactiveView}
            onTouch={onTouch}
            activeContainerStyle={activeContainerStyle}
            inactiveContainerStyle={inactiveContainerStyle}
            activeTextStyle={activeTextStyle}
            inactiveTextStyle={inactiveTextStyle}
            activeIcon={activeIcon}
            inactiveIcon={inactiveIcon}
          />
        ))}
    </View>
  );
};

MyMultiSelectView.propTypes = {
  ...TouchableOpacity.propTypes,
  activeContainerStyle: PropTypes.object,
  inactiveContainerStyle: PropTypes.object,
  activeTextStyle: PropTypes.object,
  inactiveTextStyle: PropTypes.object,
  activeIcon: PropTypes.element,
  inactiveIcon: PropTypes.element,
  onSelectionStatusChange: PropTypes.func,
  valueKey: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object])
  ).isRequired,
};

export default MyMultiSelectView;
