import React, { useState } from 'react';
import { View, Text, ScrollView, StyleSheet } from 'react-native';

import { translate } from 'app/i18n/src/locale';
import pt from 'app/i18n/src/locale/pt';
import colors from 'app/styles/colors';
import textStyles from 'app/styles/textStyles';
import fonts from 'app/styles/fonts';
import { TextInputMask } from 'react-native-masked-text';

import ButtonBase from 'app/components/ButtonBase';

import { updateProfileRequest } from 'app/store/modules/user/actions';

import { useDispatch } from 'react-redux';

import moment from 'moment';
import HeaderAquamarine from 'app/components/HeaderAquamarine';
import { useNavigation } from '@react-navigation/native';

export const BirthdateEditing = ({ route }) => {

  const { profile } = route?.params;
  const navigation = useNavigation();

  const [birthdate, setBirthdate] = useState(
    profile?.birthdate ? moment(profile?.birthdate).format('DD/MM/YYYY') : ''
  );

  const dispatch = useDispatch();

  const onPress = () => {
    try {
      dispatch(
        updateProfileRequest({
          name: profile.name,
          birthdate: moment(birthdate, 'DD/MM/YYYY').toDate(),
        })
      );
      navigation.goBack();
    } catch (error) {
      navigation.goBack();
    }
  };

  const changeBirthdateValue = value => setBirthdate(value);

  return (
    <View style={styles.container}>
      <HeaderAquamarine title={translate(pt.change_date_of_birth)} />
      <ScrollView>
        <View style={styles.contentContainer}>
          <Text style={styles.title}>{translate(pt.birthdate) + '*'}</Text>

          <TextInputMask
            type={'custom'}
            placeholder="dd/mm/aaaa"
            keyboardType="numeric"
            value={birthdate}
            style={[styles.input, { alignSelf: 'stretch' }]}
            options={{
              mask: '99/99/9999',
            }}
            onChangeText={value => changeBirthdateValue(value)}
            maxLength={12}
          />

          <ButtonBase
            backgroundColor={colors.aquaMarineTwo}
            title={translate(pt.change)}
            style={{ width: '100%' }}
            onPress={onPress}
          />
        </View>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    height: '100dvh'
  },
  contentContainer: {
    flex: 1,
    height: 500,
    padding: 20,
    alignItems: 'center',
  },
  title: {
    fontFamily: fonts.BOLD,
    fontSize: 18,
    color: colors.blackTwo,
    alignSelf: 'flex-start',
  },
  input: {
    ...textStyles.semi16,
    textAlign: 'left',
    marginTop: 10,
    marginBottom: 10,
    paddingHorizontal: 16,
    height: 45,
    borderRadius: 10,
    backgroundColor: colors.white,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: colors.lightPeriwinkle,
  },
});
