import { all, put, takeEvery } from 'redux-saga/effects';
import { updateFavoritesSuccess } from './actions';

export function* updateFavorites({ payload }) {
  try {
    yield put(updateFavoritesSuccess(payload.favorites));
  } catch (error) {
    console.error(error);
  }
}

export default all([takeEvery('@favorites/HANDLE_FAVORITES', updateFavorites)]);
