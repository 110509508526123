import React from "react";
import colors from "../../../../styles/colors";

const cameraEnabled = require('app/images/iconCameraEnabled.png');
const cameraDisabled = require('app/images/iconCameraDisabled.png');
const micEnabled = require('app/images/iconMicEnabled.png');
const micDisabled = require('app/images/iconMicDisabled.png');
const phone = require('app/images/iconPhone.png');
const phoneWhite = require('app/images/iconPhoneWhite.png');

const CallControllerBar = ({
  toggleMic,
  toggleCam,
  isMicEnabled,
  isCamEnabled,
  endCall,
  chatButton,
  phoneType,
  callRunning = false,
  switchCamera,
  userProId,
}) => {
  return (
    <div style={styles.bottomBox}>
      <div style={styles.column}>
        <div style={styles.circleAlphaButton} onClick={switchCamera}>
          <img src={require('app/images/iconCircleArrows.png')}/>
        </div>
      </div>
      <div style={styles.column}>
        <div style={{...styles.phoneButton, backgroundColor: phoneType == 'waiting' ? colors.white : colors.orangeyRed}} onClick={endCall}>
          <img src={phoneType == 'waiting' ? phone : phoneWhite} />
        </div>
        <div style={styles.circleAlphaButton} onClick={toggleCam}>
            <img src={isCamEnabled ? cameraEnabled : cameraDisabled} />
        </div>
      </div>
      <div style={styles.column}>
        <div style={styles.circleAlphaButton} onClick={toggleMic}>
          <img src={isMicEnabled ? micEnabled : micDisabled} />
        </div>
      </div>
    </div>
  );
};

const styles = {
  bottomBox: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    position: 'absolute',
    bottom: 0,
    padding: '1rem',
    boxSizing: 'border-box',
    zIndex: 100,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  circleAlphaButton: {
    display: 'flex',
    backgroundColor: 'rgba(245,246,250,0.23)',
    borderRadius: 100,
    width: 42,
    height: 42,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20
  },
  chatButton: {
    backgroundColor: colors.aquaMarineTwo,
    borderRadius: 100,
    padding: 15,
    width: 50,
    height: 50,
  },
  phoneButton: {
    display: 'flex',
    borderRadius: 100,
    width: 50,
    height: 50,
    justifyContent: 'center',
    alignItems: 'center'
  },
};

export default CallControllerBar;
