import React from 'react';
import { Image, StyleSheet, TouchableOpacity, Text } from 'react-native';
import colors from 'app/styles/colors';
import fonts from 'app/styles/fonts';
import ShadowedView from 'app/components/ShadowedView';
import { useNavigation } from '@react-navigation/native';

const CardSquare = ({ data }) => {
  const navigation = useNavigation();
  const {
    provider: { cptId, name, logo },
  } = data;
  return (
    <TouchableOpacity
      activeOpacity={0.6}
      onPress={() => navigation.navigate('GymDetail', { id: cptId, reserve: true })}
      style={styles.container}>
      <ShadowedView
        shadowContainerStyle={{ width: 77, height: 77 }}
        wrapperStyle={{ paddingVertical: 0, paddingHorizontal: 0 }}>
        <Image source={{ uri: logo }} style={styles.image} />
      </ShadowedView>
      <Text style={styles.gymName}>{name}</Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    marginLeft: -5,
  },
  image: {
    borderRadius: 10,
    resizeMode: 'cover',
    width: 77,
    height: 77,
    backgroundColor: colors.grayWeak,
  },
  gymName: {
    color: colors.charcoalGreyTwo,
    fontFamily: fonts.BOLD,
    fontSize: 10,
    width: 90,
    marginTop: 5,
    textAlign: 'center',
  },
});

export default CardSquare;
