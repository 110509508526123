import React from 'react';
import { StyleSheet, Image, TouchableOpacity } from 'react-native';
import colors from 'app/styles/colors';
import videoLock from 'app/images/videoLock2x.png';
import PERFORMANCE from 'app/configs/performance';
import { debounce } from 'lodash';
// import { Actions } from 'react-native-router-flux';

const MaskLevelPaymentNew = ({ containerStyles }) => {
  return (
    <TouchableOpacity style={[styles.containerPaymentLevel, containerStyles]} activeOpacity={0.8} onPress={debounce(()=>null /*Actions.paywall()*/, PERFORMANCE.ON_PRESS_DEBOUNCE_TIME)}>
      <Image source={videoLock} style={styles.image} />
    </TouchableOpacity>
  );
};

export default MaskLevelPaymentNew;

const styles = StyleSheet.create({
  containerPaymentLevel: {
    position: 'absolute',
    left: 0,
    top: 0,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.white + '80',
    zIndex: 10000,
    borderRadius: 10,
  },
  image: {
    resizeMode: 'contain',
    width: 34,
    height: 34,
  },
});
