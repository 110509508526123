import React, { useState } from 'react';

import { View, Text, StyleSheet, Image, TouchableOpacity } from 'react-native';
import textStyles from '../../../../styles/textStyles';
import CapsuleLevel from '../../../../components/CapsuleLevel';
import CapsuleClock from '../../../../components/CapsuleClock';
import colors from '../../../../styles/colors';
import ballFoodOpaque from '../../../../images/ballFoodOpaque.png';
import fallback from '../../../../images/fallback_food.jpg';

const RowPlateInternal = ({ dishes, style, onPress }) => {
  const [thumbnail, setThumbnail] = useState({ uri: dishes.thumbnail.path });
  return (
    <TouchableOpacity onPress={onPress}>
      <View style={[styles.card, style]}>
        <Image
          source={thumbnail}
          style={styles.image}
          onError={({ currentTarget }) => {
            currentTarget.onError = null;
            setThumbnail(fallback);
          }}
        />
        <View style={styles.holder}>
          <Text numberOfLines={2} style={[textStyles.semi14, styles.right0]}>
            {dishes.name}
          </Text>

          <View style={styles.capsules}>
            <CapsuleLevel difficulty={dishes.level} />
            <CapsuleClock duration={dishes.preparation_time} timeIn={'min'} />
            <Image style={styles.ball} source={ballFoodOpaque} />
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default RowPlateInternal;

const styles = StyleSheet.create({
  right0: { marginRight: 0 },
  top10: { marginTop: 10 },
  ball: { width: 29, height: 29 },
  capsules: {
    maxWidth: 210,
    alignSelf: 'stretch',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginTop: 16,
  },
  holder: {
    paddingVertical: 10,
    marginLeft: 12,
    paddingRight: 20,
    flex: 1,
    justifyContent: 'center',
  },
  image: {
    width: 81,
    height: '100%',
    borderTopLeftRadius: 3,
    borderBottomLeftRadius: 3,
    backgroundColor: colors.base,
  },
  card: {
    flexDirection: 'row',
    marginBottom: 14,
    borderRadius: 3,
    backgroundColor: colors.white,
  },
});
