/* eslint-disable react-native/no-inline-styles */
import FavoriteButtonShow from 'app/components/FavoriteButtonShow';
import MaskLevelPaymentNew from 'app/components/MaskLevelPaymentNew';
import TagCard from 'app/components/TagCard';
import api from 'app/services/api';
import PropTypes from 'prop-types';
import React from 'react';
import { ImageBackground, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import ShadowView from '@liuyunjs/react-native-simple-shadow-view';
import colors from '../../../styles/colors';
import fonts from '../../../styles/fonts';

const CardCoursesHorizontal = ({
  type,
  style,
  title,
  image,
  gradientColor = 'none',
  paymentLevel,
  onPress,
  favorite,
  id,
  goals,
  tag,
}) => {
  const getGradientColor = () => {
    if (gradientColor === 'red') {
      return colors.orangeyRed40;
    }
    if (gradientColor === 'green') {
      return colors.aquaMarine40;
    }
    if (gradientColor === 'orange') {
      return colors.pastelOrange40;
    }
    return colors.transparent;
  };
  const getBackgroundColor = () => {
    if (gradientColor === 'red') {
      return colors.darkRed;
    }
    if (gradientColor === 'green') {
      return colors.aquaMarineTwo;
    }
    if (gradientColor === 'orange') {
      return colors.brightOrange;
    }
    return colors.transparent;
  };
  return (
    <TouchableOpacity onPress={onPress} disabled={!onPress}>
      <ShadowView style={[styles.card, style]}>
        <ImageBackground
          source={{
            uri: image,
            headers: {
              Authorization: api.defaults.headers.Authorization,
            },
          }}
          imageStyle={{
            borderTopLeftRadius: 4,
            borderBottomLeftRadius: 4,
          }}
          style={{
            height: 117,
            width: 119,
          }}>
          <LinearGradient colors={[colors.transparent, getGradientColor()]} style={styles.linear} />
          {paymentLevel === 1 && (
            <MaskLevelPaymentNew containerStyles={{ height: 117, width: 119 }} />
          )}
        </ImageBackground>
        <View style={[styles.white]}>
          <View style={styles.wrapper}>
            <Text numberOfLines={2} style={[styles.title, { flex: 1, marginRight: 5 }]}>
              {title}
            </Text>
            <FavoriteButtonShow
              isFavorited={favorite}
              propType="courseId"
              itemId={id}
              color={colors.lightGreyBlue}
            />
          </View>
          {type && (
            <View style={styles.tagWrapper}>
              <View style={[styles.typeWrapper, { backgroundColor: getBackgroundColor() + 'CC' }]}>
                <Text style={styles.types} numberOfLines={1}>
                  {type}
                </Text>
              </View>
              {!!tag && <TagCard style={{ marginLeft: 6 }} title={tag} />}
              {!!goals && goals.length > 0
                ? goals
                    .slice(0, 1)
                    .map(goal => <TagCard style={{ marginLeft: 6 }} title={goal.goal_name} />)
                : null}
            </View>
          )}
        </View>
      </ShadowView>
    </TouchableOpacity>
  );
};

export default CardCoursesHorizontal;

CardCoursesHorizontal.propTypes = {
  onPress: PropTypes.func,
  style: PropTypes.any,
  title: PropTypes.string,
  type: PropTypes.oneOf(['masterclass']),
};

const styles = StyleSheet.create({
  linear: { position: 'absolute', width: 119, height: 117, borderBottomLeftRadius: 4 },
  title: {
    marginBottom: 8,
    fontFamily: fonts.BOLD,
    fontSize: 14,
    lineHeight: 17,
    color: colors.charcoalGreyTwo,
    minHeight: 34,
  },
  typeWrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
    paddingHorizontal: 8,
    paddingVertical: 4,
    alignSelf: 'flex-start',
    borderRadius: 4,
    marginTop: 'auto',
  },
  card: {
    height: 117,
    marginBottom: 20,
    flex: 1,
    backgroundColor: colors.transparent,
    flexDirection: 'row',
    shadowColor: colors.blackTwo,
    shadowOffset: {
      width: 4,
      height: 4,
    },
    shadowOpacity: 0.16,
    borderRadius: 4,
    elevation: 4,
  },
  capsuleWrapper: {
    flexDirection: 'row',
  },
  wrapper: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
    position: 'relative',
  },
  white: {
    paddingVertical: 13,
    paddingHorizontal: 12,
    backgroundColor: colors.white,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    flex: 1,
  },
  maskContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  types: {
    color: colors.white,
    fontSize: 9,
    fontFamily: fonts.BOLD,
  },
  tagWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});
