import React from 'react';
import { LineChart } from 'react-native-svg-charts';
import * as shape from 'd3-shape';
import Svg, { Defs, Line, LinearGradient, Stop, Text } from 'react-native-svg';
import { View } from 'react-native';
import { translate } from 'app/i18n/src/locale';
import pt from 'app/i18n/src/locale/pt';
import fonts from 'app/styles/fonts';

const Gradient = props => {
  const { colors, y, height } = props;
  return (
    <Defs>
      <LinearGradient id={'gradient'} x1={'0%'} y1={0} x2={0} y2={1}>
        <Stop offset="0" stopColor={colors[0]} stopOpacity="1" />
        <Stop offset="0.5" stopColor={colors[1]} stopOpacity="1" />
        <Stop offset="1" stopColor={colors[2]} stopOpacity="1" />
      </LinearGradient>
    </Defs>
  );
};

const uniqueKey = index => {
  return Math.floor(Math.random() * (10000000 - 1 + 1)) + 1 + index;
};

const ChartDecorator = props => {
  const { x, y, combinedData } = props;

  return (
    <>
      {combinedData &&
        combinedData
          .filter((d, i) => i === 0)
          .map((item, index) => {
            return (
              <Svg key={uniqueKey(index)}>
                {item.data.map((value, indexItem) => {
                  return (
                    <>
                      {indexItem === 0 ? (
                        <Text
                          fill="#A3A9B2"
                          fontSize="14"
                          fontFamily={fonts.REGULAR}
                          x={0}
                          y={y(value) + 25}>
                          {translate(pt.days)}
                        </Text>
                      ) : (
                        <Line
                          key={uniqueKey(indexItem)}
                          zIndex={-1}
                          x2={x(indexItem)}
                          x1={x(indexItem)}
                          y2={y(value) - 10}
                          y1={y(value) + 10}
                          stroke={'rgba(72, 84, 101, 0.3)'}
                          strokeWidth={1.5}
                          fill={'white'}
                        />
                      )}
                    </>
                  );
                })}
              </Svg>
            );
          })}
    </>
  );
};

export const CustomLineChart2 = ({ colors, data }) => {
  const composeDataWithBaseLine = valuesArray => {
    return [
      {
        data: new Array(valuesArray.length + 1).fill(0),
        svg: { strokeWidth: 1.5, stroke: '#C8CCD1' },
      },
      {
        data: [0, ...valuesArray],
        svg: { strokeWidth: 3 },
      },
    ];
  };
  const dataWithBaseLine = composeDataWithBaseLine(data);

  return (
    <>
      <View style={{ backgroundColor: 'white', flex: 1 }}>
        <LineChart
          style={{ height: 80, padding: 0, margin: 0 }}
          spacingOuter={0}
          gridMin={-80}
          gridMax={80}
          data={dataWithBaseLine}
          curve={shape.curveNatural}
          contentInset={{ top: 10, bottom: 10 }}
          svg={{
            stroke: 'url(#gradient)',
          }}>
          <Gradient colors={colors} />
          <ChartDecorator combinedData={dataWithBaseLine} />
        </LineChart>
      </View>
    </>
  );
};
