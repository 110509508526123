import React from 'react';

import { View, Image, TouchableOpacity, StyleSheet, Text, StatusBar } from 'react-native';
import { useSafeArea } from 'react-native-safe-area-context';
import colors from '../styles/colors';
import fonts from 'app/styles/fonts';
import chevronLeft from '../images/chevronLeft.png';
import { useNavigation } from '@react-navigation/native';

const HeaderAquaIcon = ({
  title,
  noPadding,
  useGradient,
  gradientColors,
  onPress,
  hideStatusBar,
  textStyle,
  color,
}) => {
  const navigation = useNavigation();
  const safeAreaInsets = useSafeArea();
  return (
    <View>
      {!hideStatusBar && (
        <StatusBar translucent backgroundColor="transparent" barStyle="light-content" />
      )}
      <View
        style={{
          width: '100%',
          backgroundColor: colors.aquaMarineTwo,
        }}>
        <View
          style={{
            paddingTop: noPadding ? 0 : safeAreaInsets.top,
          }}>
          <View style={styles.bar}>
            <TouchableOpacity style={styles.backButton} onPress={onPress ? onPress : navigation.goBack}>
              <Image source={chevronLeft} style={[styles.backImage, { tintColor: colors.white }]} />
            </TouchableOpacity>
            <View style={styles.iconContainer}>
              <Image source={require('app/images/manu.png')} style={styles.icon} />
            </View>
            <View style={styles.textContent}>
              <Text numberOfLines={1} style={[styles.title, textStyle]}>
                {title}
              </Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export default HeaderAquaIcon;

const styles = StyleSheet.create({
  title: {
    color: colors.white,
    fontSize: 18,
    fontWeight: '500',
    width: '75%',
    position: 'relative',
    textAlign: 'left',
    fontFamily: fonts.BOLD,
  },
  bar: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: 50,
    paddingHorizontal: 8,
  },
  textContent: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: 12,
    height: '100%',
  },
  backButton: {
    width: 26,
    height: 26,
    marginLeft: 6,
  },
  backImage: {
    resizeMode: 'contain',
    width: 26,
    height: 26,
  },
  icon: {
    width: 35,
    height: 35,
    borderRadius: 50,
    resizeMode: 'contain',
  },
  iconContainer: {
    marginLeft: 7,
    width: 40,
    height: 40,
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: colors.aquaMarineTwo2,
    borderRadius: 50,
  },
});
