/* eslint-disable react-native/no-inline-styles */
import React from 'react';

import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';

import fonts from '../../../../styles/fonts';
import colors from '../../../../styles/colors';
import moment from 'moment';
import ShadowView from '@liuyunjs/react-native-simple-shadow-view';

const WeekDay = ({ day, actionSelectDay }) => {
  const date = moment(day.day);
  return (
    <TouchableOpacity onPress={actionSelectDay} style={{ padding: 10, overflow: 'hidden' }}>
      <ShadowView
        style={{
          ...styles.card,
          backgroundColor: day.isSelected ? colors.white : null,
          shadowOpacity: day.isSelected ? 0.16 : 0,
        }}>
        <View style={styles.container}>
          <Text
            style={[styles.wd, { color: day.isSelected ? colors.aquaMarine : colors.blueyGrey }]}>
            {date.format('ddd')}
          </Text>
          <Text
            style={[
              styles.day,
              { color: day.isSelected ? colors.aquaMarine : colors.charcoalGreyTwo },
            ]}>
            {date.date()}
          </Text>
        </View>
      </ShadowView>
    </TouchableOpacity>
  );
};

export default WeekDay;

const styles = StyleSheet.create({
  card: {
    paddingTop: 8,
    width: 30,
    height: 60,
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowRadius: 6,
    shadowOpacity: 0.16,
    zIndex: 999,
  },
  day: {
    textAlign: 'center',
    height: 19,
    fontFamily: fonts.BOLD,
    fontSize: 14,
    fontWeight: 'bold',
  },
  wd: {
    textAlign: 'center',
    height: 14,
    fontFamily: fonts.BOLD,
    fontSize: 10,
  },
  container: {
    alignItems: 'center',
    justifyContent: 'center',
  },
});
