import React from 'react';

import { StyleSheet, Text, View } from 'react-native';
import fonts from '../styles/fonts';
import colors from '../styles/colors';

const Capsule = ({ style, children, prefix, text }) => {
  return (
    <View style={[styles.capsule, style]}>
      <View style={{ marginRight: 3 }}>{prefix}</View>
      <Text style={styles.capsuleText}>{text}</Text>
    </View>
  );
};

export default Capsule;

const styles = StyleSheet.create({
  capsuleText: {
    fontFamily: fonts.MEDIUM,
    fontSize: 10,
    color: colors.darkPro,
  },
  capsule: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 18,
    borderRadius: 3,
    borderWidth: 1,
    borderColor: colors.disabledGray,
    paddingHorizontal: 4,
  },
  image: {
    width: 9,
    height: 7,
  },
});
