import React, { useState, useEffect } from 'react';
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  StyleSheet,
  ActivityIndicator,
  ScrollView,
} from 'react-native';
import ButtonBase from '../../../components/ButtonBase';
import colors from '../../../styles/colors';
import commons from '../../../styles/commons';
import { translate } from 'app/i18n/src/locale';
import pt from 'app/i18n/src/locale/pt';
import fonts from 'app/styles/fonts';
import arrowLeft from '../../../images/chevronLeft.png';
import arrowRight from '../../../images/chevronRight.png';
import moment from 'moment';
import apiNode from 'app/services/apiNode';
import { buildURL } from 'app/services/utils';

const HourSelectNamuAttendance = ({
  confirmText = translate(pt.confirm),
  categoryId,
  onConfirm,
  available,
  scrollStyle,
}) => {
  const [hours, setHours] = useState([]);
  const [current, setCurrent] = useState(moment());
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);

  const getFreeTimes = async () => {
    setLoading(true);
    try {
      const formatedDate = moment(current).format('YYYY-MM-DD');
      const { data } = await apiNode.get(
        buildURL({
          base: '/calendar/staff-free-times',
          query: {
            date: formatedDate,
            categoryId,
          },
        })
      );

      setHours([]);
      data.map((free, index) => {
        if (moment(free.startDateTime) > moment()) {
          const startHour = moment(free.startDateTime).format('HH:mm');
          const hourTmp = startHour.replace(':', 'h');
          if (index === 0) {
            setHours([{ index, hour: hourTmp, startDateTime: free.startDateTime }]);
          } else {
            setHours(prev => [
              ...prev,
              { index, hour: hourTmp, startDateTime: free.startDateTime },
            ]);
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (current) {
      getFreeTimes();
    }
  }, [current]);

  return (
    <View style={styles.packageContent}>
      <ScrollView style={[styles.contentContainer, scrollStyle]}>
        <View style={styles.titleAndHourContainer}>
          {moment(current) > moment() ? (
            <TouchableOpacity
              hitSlop={commons.hitSlop}
              onPress={() => {
                setCurrent(moment(current).subtract(1, 'days'));
                setSelected(null);
              }}>
              <Image source={arrowLeft} />
            </TouchableOpacity>
          ) : (
            <View />
          )}
          <Text style={{ fontFamily: fonts.REGULAR, color: colors.greyBlue }}>
            {current ? moment(current).format('ddd - DD  MMMM') : '-'}
          </Text>
          <TouchableOpacity
            hitSlop={commons.hitSlop}
            onPress={() => {
              setCurrent(moment(current).add(1, 'days'));
              setSelected(null);
            }}>
            <Image source={arrowRight} />
          </TouchableOpacity>
        </View>
        <View style={styles.hourItemsContainer}>
          {!loading ? (
            <>
              {hours.length > 0 ? (
                <>
                  {hours.map(item => {
                    if (moment(item.startDateTime) > moment()) {
                      return (
                        <TouchableOpacity
                          onPress={() => {
                            setSelected(item.index);
                          }}
                          key={item.hour}
                          style={[
                            styles.hourItem,
                            {
                              backgroundColor:
                                selected === item.index ? colors.aquaMarineTwo : colors.white,
                            },
                          ]}>
                          <Text
                            style={{
                              fontFamily: fonts.BOLD,
                              padding: 8,
                              color: selected === item.index ? colors.white : colors.aquaMarineTwo,
                            }}>
                            {item.hour}
                          </Text>
                        </TouchableOpacity>
                      );
                    } else {
                      return null;
                    }
                  })}
                </>
              ) : (
                <Text
                  style={{
                    alignSelf: 'center',
                    textAlign: 'center',
                    flex: 1,
                    fontFamily: fonts.BOLD,
                    color: colors.aquaMarineTwo,
                  }}>
                  {translate(pt.without_free_time)}
                </Text>
              )}
            </>
          ) : (
            <ActivityIndicator
              animating
              size={'small'}
              color={colors.aquaMarine}
              style={{ alignSelf: 'center', flex: 1 }}
            />
          )}
        </View>
      </ScrollView>
      <ButtonBase
        disabled={selected == null || !available}
        title={confirmText}
        fill
        style={styles.buttonStyle}
        onPress={() => onConfirm(hours.find(h => h.index === selected).startDateTime)}
      />
    </View>
  );
};

export default HourSelectNamuAttendance;

const styles = StyleSheet.create({
  containerBottomSheet: {
    borderTopEndRadius: 47,
    borderTopStartRadius: 47,
    paddingBottom: 10,
  },
  topContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    marginTop: 14,
  },
  title: {
    textAlign: 'center',
    flex: 0.8,
    fontFamily: fonts.BOLD,
    fontSize: 18,
  },

  buttonStyle: { height: 49 },
  hourItem: {
    borderColor: colors.aquaMarineTwo,
    borderRadius: 3,
    borderWidth: 0.8,
    margin: 4,
    width: '30%',
    alignItems: 'center',
  },

  hourItemsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginStart: 5,
    marginBottom: 12,
    marginTop: 5,
  },
  titleAndHourContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: 8,
    marginTop: 8,
  },
  contentContainer: {
    height: 'auto',
    borderRadius: 10,
    borderWidth: 0.5,
    borderColor: ' rgba(191, 243, 243, 1)',
  },
  packageContent: {
    width: '100%',
    alignSelf: 'center',
  },
});
