import ButtonBase from 'app/components/ButtonBase';
import colors from 'app/styles/colors';
import fonts from 'app/styles/fonts';
import { translate } from 'app/i18n/src/locale';
import React from 'react';
import { Image, StyleSheet, Text, View } from 'react-native';
import pt from 'app/i18n/src/locale/pt';
import { debounce } from 'lodash';
import PERFORMANCE from 'app/configs/performance';
// import { Actions } from 'react-native-router-flux';
import ShadowView from '@liuyunjs/react-native-simple-shadow-view';
import { useSelector } from 'react-redux';
import MaskLevelPaymentNew from 'app/components/MaskLevelPaymentNew';
import { useNavigation } from '@react-navigation/native';

const CardMoodWidget = ({}) => {
  const { profile } = useSelector(state => state.user);

  const navigation = useNavigation();

  return (
    <ShadowView style={styles.shadowContainer}>
      <View style={styles.container}>
        {!profile?.plan_meta?.mood_enabled && (
          <MaskLevelPaymentNew containerStyles={{ height: '100%', width: '100%' }} />
        )}
        <View style={{ paddingVertical: 17 }}>
          <View
            style={{
              width: '55%',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              marginLeft: 23,
            }}>
            <View style={styles.professionalWrapper}>
              <Text style={styles.textProfessional}>{translate(pt.emotional)}</Text>
            </View>
            <View>
              <Text style={styles.textContent}>
                {
                  'Registre seus sentimentos, busque o autoconhecimento e descubra tendências de comportamento.'
                }
              </Text>
            </View>
          </View>
          <ButtonBase
            backgroundColor={colors.aquaMarineTwo}
            style={styles.buttonBase}
            onPress={debounce(()=>{
              navigation.navigate('Mood')
            }, PERFORMANCE)}
            textStyle={{ color: colors.white }}
            title={translate(pt.register_mood)}
        />
          <Image source={require('app/images/mood.png')} style={styles.image} />
        </View>
      </View>
    </ShadowView>
  );
};

const styles = StyleSheet.create({
  shadowContainer: {
    backgroundColor: colors.white,
    borderRadius: 10,
    shadowColor: colors.black,
    marginHorizontal: 20,
    shadowOpacity: 0.1,
    shadowRadius: 5,
    shadowOffset: { width: 0, height: 5 },
  },
  container: {
    flex: 1,
    borderRadius: 10,
    backgroundColor: colors.white,
    overflow: 'hidden',
    position: 'relative',
  },
  image: {
    zIndex: -1,
    height: 200,
    width: 200,
    resizeMode: 'contain',
    right: 0,
    top: 0,
    position: 'absolute',
  },
  buttonBase: {
    height: 40,
    alignSelf: 'flex-end',
    marginRight: 17,
    borderRadius: 10,
    shadowColor: colors.black,
    shadowOpacity: 0.1,
    shadowRadius: 5,
    shadowOffset: { width: 0, height: 5 },
  },
  professionalWrapper: {
    marginVertical: 6,
  },
  textProfessional: {
    fontFamily: fonts.BOLD,
    color: colors.darkPro,
    fontSize: 21,
    lineHeight: 26,
  },
  textContent: {
    fontFamily: fonts.REGULAR,
    fontSize: 13,
    lineHeight: 17,
    color: colors.darkPro,
  },
});

export default CardMoodWidget;
