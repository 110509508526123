import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import colors from 'app/styles/colors';
import fonts from 'app/styles/fonts';
import LinearGradient from 'react-native-linear-gradient';
import { calcDurationNumber } from 'app/services/utils';

const CapsuleTimeLeft = ({ duration, currentTime, style }) => {
  return (
    <LinearGradient
      style={[styles.container, style]}
      angle={64}
      useAngle
      colors={[colors.aquaMarineTwo, colors.aquaMarineTwo, colors.shadowBlue]}>
      <Text style={styles.textCurrentTime}>{calcDurationNumber(currentTime)}/</Text>
      <Text style={styles.textDuration}>{calcDurationNumber(duration)}</Text>
    </LinearGradient>
  );
};

export default CapsuleTimeLeft;

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 18,
    paddingVertical: 12,
    borderRadius: 84,
    width: 112,
    borderWidth: 2,
    borderColor: colors.aquaBlueDark,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textCurrentTime: {
    fontSize: 12,
    fontFamily: fonts.BOLD,
    lineHeight: 16,
    color: colors.white,
  },
  textDuration: {
    fontSize: 12,
    fontFamily: fonts.BOLD,
    lineHeight: 16,
    color: colors.greyBlue,
  },
});
