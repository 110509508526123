import React from 'react';
import checkedIcon from 'app/images/checkCircleChecked.png';
import uncheckedIcon from 'app/images/checkCircleUnchecked.png';
import { Image, TouchableOpacity } from 'react-native';

const CheckBox = ({ onPress, pressable = true, checked, style, size = 28, uncheckedIconStyle }) => {
  return (
    <TouchableOpacity
      disabled={!onPress}
      onPress={() => pressable && onPress && onPress()}
      style={[style]}>
      <Image
        source={checked || pressable == false ? checkedIcon : uncheckedIcon}
        style={[
          { height: size, width: size, resizeMode: 'contain' },
          !pressable && !checked ? { tintColor: '#d9d9d9' } : {},
          !checked && uncheckedIconStyle,
        ]}
      />
    </TouchableOpacity>
  );
};

export default CheckBox;
