import React from 'react';

import { View, Text, StyleSheet } from 'react-native';
import colors from '../styles/colors';
import fonts from '../styles/fonts';

const Chip = ({ title, style }) => (
  <View style={[styles.card, style]}>
    <Text style={styles.title}>{title}</Text>
  </View>
);

export default Chip;

const styles = StyleSheet.create({
  title: {
    fontFamily: fonts.REGULAR,
    fontSize: 12,
    color: colors.brownishGrey,
  },
  card: {
    marginRight: 11,
    marginBottom: 11,
    backgroundColor: colors.paleLilac,
    alignItems: 'center',
    height: 24,
    borderRadius: 12,
    paddingHorizontal: 10,
    justifyContent: 'center',
  },
});
