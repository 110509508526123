import React, { useState } from 'react';
import { View, StyleSheet, FlatList } from 'react-native';
// import { FAB } from 'react-native-paper';
import fonts from 'app/styles/fonts';
import colors from 'app/styles/colors';
import SearchBarNew from 'app/components/SearchBarNew';
import HeaderAquamarine from 'app/components/HeaderAquamarine';
import CardCourses from 'app/screens/core/explorer/components/CardCourses';
import FilterResult from 'app/screens/core/explorer/components/FilterResult';
import { useNavigation } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';
import { useSafeArea } from 'react-native-safe-area-context';
import { FAB } from 'react-native-paper';

const ListCoursesScreen = ({ route }) => {
  const {
    title = 'Todas os cursos',
    categoryId,
    color,
    colorBase,
    data,
    options = {},
    type,
  } = route?.params;

  const dispatch = useDispatch();
  const { profile } = useSelector(state => state.user);
  const [courses, setCourses] = useState(data);
  const [searchQuery, setSearchQuery] = useState('');
  const [courseFilters, setCourseFilters] = useState([]);
  const safeAreaInsets = useSafeArea();


  const navigation = useNavigation();

  const getGradientColor = () => {
    if (categoryId === 1) {
      return [colors.grapefruit, colors.orangeyRed];
    }
    if (categoryId === 3) {
      return [colors.orangeTwo, colors.orangeThree];
    }
    return [colors.aquaMarineTwo, colors.aquaMarineGrd];
  };

  const handleFilter = (filters = []) => {
    const newData = data.filter(d => {
      return (
        (searchQuery.length > 0
          ? d.post_title.toLowerCase().includes(searchQuery.toLowerCase())
          : true) &&
        (filters.length > 0 ? d.categories.some(x => filters.includes(parseInt(x, 10))) : true)
      );
    });
    setCourses(newData);
    setCourseFilters(filters);
  };

  const handleRemoveFilter = filter => {
    const filters = courseFilters.filter(item => item !== filter.id);
    handleFilter(filters);
  };

  return (
    <View style={{ height: '100dvh', backgroundColor: colors.paleGrey }}>
      <HeaderAquamarine
        title={title}
        textStyle={styles.headerText}
        useGradient
        gradientColors={getGradientColor()}
        color={color}
        onPress={navigation.goBack}
      />
      <View style={{ flex: 1, paddingTop: 20 }}>
        <View style={{ paddingHorizontal: 20, paddingBottom: 10 }}>
          <SearchBarNew value={searchQuery} onChange={setSearchQuery} onSearch={handleFilter} />
        </View>
        <View style={{ alignItems: 'center', flex: 1 }}>
          <FlatList
            showsVerticalScrollIndicator={false}
            data={courses}
            keyExtractor={item => item.id}
            numColumns={1}
            style={{ width: '100%', paddingHorizontal: 20, paddingTop: 15 }}
            contentContainerStyle={{ paddingBottom: safeAreaInsets.bottom }}
            horizontal={false}
            ListHeaderComponent={
              !!courseFilters.length && (
                <FilterResult
                  style={{ marginTop: 0, marginBottom: 20 }}
                  onRemove={handleRemoveFilter}
                  filters={courseFilters.map(f => ({ id: f, name: options.categories[f] }))}
                />
              )
            }
            renderItem={({ item }) => {
              return (
                <CardCourses
                  title={item.post_title}
                  goal={item.goal}
                  full
                  id={item.id}
                  paymentLevel={item.access_granted}
                  onPress={() =>
                    item.access_granted === 1
                      ? null //Actions.paywall()
                      : navigation.navigate('MasterclassDetail', {
                          coursepress_class_id: item.id,
                          colorBase: colorBase,
                        })
                  }
                  style={{ marginBottom: 15, marginHorizontal: 0 }}
                  color={colorBase}
                  image={{ uri: item.image_path }}
                />
              );
            }}
          />
        </View>
        <FAB
          style={[styles.fab, { backgroundColor: color }]}
          color={colors.white}
          icon={require('app/images/filter.png')}
          onPress={() => {
            navigation.navigate('FilterCourses', {
              color: color,
              headerColors: getGradientColor(),
              options: options,
              filters: courseFilters,
              onFilter: f => {
                handleFilter(f);
              },
            });
          }}
        />
      </View>
    </View>
  );
};

export default ListCoursesScreen;

const styles = StyleSheet.create({
  headerText: {
    color: colors.white,
    fontSize: 18,
    marginRight: 60,
    position: 'relative',
    textAlign: 'left',
    fontFamily: fonts.BOLD,
  },
  fab: {
    position: 'absolute',
    margin: 16,
    right: 0,
    bottom: 15,
  },
  flexCenter: { flex: 1, alignItems: 'center', justifyContent: 'center' },
});
