import React from 'react';
import { View, Image, Text, StyleSheet } from 'react-native';

import colors from 'app/styles/colors';
import fonts from 'app/styles/fonts';
import textStyles from 'app/styles/textStyles';

import first_place_badge from 'app/assets/gamification/insignia/first_place_badge.png';
import second_place_badge from 'app/assets/gamification/insignia/second_place_badge.png';
import third_place_badge from 'app/assets/gamification/insignia/third_place_badge.png';
import emblema03 from 'app/assets/gamification/insignia/emblema03.png';
import emblema05 from 'app/assets/gamification/insignia/emblema05.png';
import emblema07 from 'app/assets/gamification/insignia/emblema07.png';
import emblema10 from 'app/assets/gamification/insignia/emblema10.png';
import emblema15 from 'app/assets/gamification/insignia/emblema15.png';
import emblema30 from 'app/assets/gamification/insignia/emblema30.png';

const insigniasOBJ = {
  'primeiro colocado': first_place_badge,
  'segundo colocado': second_place_badge,
  'terceiro colocado': third_place_badge,
  '3 dias ativos': emblema03,
  '5 dias ativos': emblema05,
  '7 dias ativos': emblema07,
  '10 dias ativos': emblema10,
  '15 dias ativos': emblema15,
  '30 dias ativos': emblema30,
};

const nameOBJ = {
  'primeiro colocado': 'Divisão ouro',
  'segundo colocado': 'Divisão prata',
  'terceiro colocado': 'Divisão bronze',
  '3 dias ativos': '3 dias seguidos',
  '5 dias ativos': '5 dias seguidos',
  '7 dias ativos': '7 dias seguidos',
  '10 dias ativos': '10 dias seguidos',
  '15 dias ativos': '15 dias seguidos',
  '30 dias ativos': '30 dias seguidos',
};

const isMonthType = name => {
  switch (name) {
    case 'primeiro colocado':
      return true;
    case 'segundo colocado':
      return true;
    case 'terceiro colocado':
      return true;
    default:
      return false;
  }
};

export const GoalChip = ({ goal }) => {
  const { name } = goal;
  const isMonth = isMonthType(name);
  return (
    <View style={styles.goalContainer}>
      <Image source={insigniasOBJ[name]} style={styles.imgStyle} />
      {isMonth ? (
        <View style={styles.goalNameWithMonthContainer}>
          <Text style={styles.monthText}>Mensal</Text>
          <Text style={styles.monthNameText}>{nameOBJ[name]}</Text>
        </View>
      ) : (
        <View style={styles.goalNameContainer}>
          <Text style={textStyles.semi14slateGrey}>{nameOBJ[name]}</Text>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  goalNameContainer: {
    marginLeft: 5,
  },
  goalContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    width: 160,
    marginRight: 5,
    marginBottom: 5,
    backgroundColor: colors.white,
    height: 35,
    borderRadius: 16,
    padding: 5,
    paddingHorizontal: 10,
  },
  goalNameWithMonthContainer: {
    marginLeft: 5,
    display: 'flex',
    flexDirection: 'column',
  },
  monthText: {
    fontFamily: fonts.MEDIUM,
    fontSize: 10,
    color: colors.slateGrey,
    marginTop: -3,
  },
  monthNameText: {
    fontFamily: fonts.MEDIUM,
    fontSize: 14,
    color: colors.slateGrey,
    marginTop: -5,
  },
  imgStyle: {
    resizeMode: 'contain',
    width: 26,
    height: 26,
  },
});
