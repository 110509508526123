import React from 'react';
import { View, Text, StyleSheet, Image, TouchableOpacity } from 'react-native';
import textStyles from '../../../../styles/textStyles';
import colors from '../../../../styles/colors';
import videoLock from '../../../../images/videoLock.png';
import { calcDuration } from 'app/services/utils';
import CheckBox from 'app/components/CheckBox';
import { useNavigation } from '@react-navigation/native';

const RowClassInternal = ({
  masterclass_id,
  showDuration = false,
  checked = null,
  onPress,
  classItem,
  payment_level,
  coverImage,
}) => {

  const navigation = useNavigation();

  function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return (
    <TouchableOpacity
      onPress={!payment_level ? onPress : () => null}
      style={styles.card}>
      {payment_level && (
        <>
          <View style={styles.oval}>
            <Image source={videoLock} style={{width: 14, height: 14}} />
          </View>
          <View style={styles.mask} />
        </>
      )}
      <Image source={{ uri: `${classItem.thumb || coverImage}` }} style={styles.image} />

      <View style={styles.holder}>
        <Text numberOfLines={2} style={textStyles.semi14}>
          {capitalize((classItem.class_title || classItem.title).toString().toLowerCase())}
        </Text>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          {checked !== null && (
            <CheckBox
              style={{ marginTop: 5, marginRight: 5 }}
              pressable={false}
              uncheckedIconStyle={{ tintColor: '#c7c7c7' }}
              checked={checked}
              size={16}
            />
          )}
          {showDuration === true && classItem.duration != null && (
            <View style={{ flexDirection: 'row', marginTop: 5 }}>
              <Image source={require('app/images/clock.png')} style={{width: 15, height: 15}}/>
              <Text style={[textStyles.regular12grey, { marginLeft: 5 }]}>
                {calcDuration(classItem.duration, { timeSuffixExpanded: true })}
              </Text>
            </View>
          )}
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default RowClassInternal;

const styles = StyleSheet.create({
  holder: { flex: 1, marginHorizontal: 12, justifyContent: 'center' },
  image: {
    width: 81,
    borderTopLeftRadius: 3,
    borderBottomLeftRadius: 3,
    resizeMode: 'cover',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.base,
  },
  card: {
    flexDirection: 'row',
    marginBottom: 14,
    minHeight: 71,
    borderRadius: 3,
    backgroundColor: colors.white,
  },
  playContainer: {
    backgroundColor: colors.white,
    borderRadius: 50,
    width: 35,
    height: 35,
    justifyContent: 'center',
    alignItems: 'center',
  },
  playIcon: {
    tintColor: colors.darkPro2,
    width: 15,
    height: 15,
    marginLeft: 4,
    resizeMode: 'contain',
  },
  oval: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 34 / 2,
    zIndex: 5,
    top: 71 / 2 - 34 / 2,
    left: 20,
    width: 34,
    height: 34,
    backgroundColor: colors.greyBlue,
  },
  mask: {
    position: 'absolute',
    zIndex: 3,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    minHeight: 71,
    borderRadius: 3,
    opacity: 0.5,
    backgroundColor: colors.white,
  },
});
