// React and libs
import React, { useState, useEffect, useRef } from 'react';
import {
  View,
  Text,
  StyleSheet,
  TextInput,
  ScrollView,
  Image,
  Dimensions,
  ActivityIndicator,
  TouchableOpacity,
} from 'react-native';
import { TextInputMask } from 'react-native-masked-text';
import api from 'app/services/api';

// i18n
import { translate } from '../../../i18n/src/locale';
import pt from '../../../i18n/src/locale/pt';

// styles
import colors from 'app/styles/colors';

// components
import ButtonBase from 'app/components/ButtonBase';
import HeaderAquamarine from 'app/components/HeaderAquamarine';

// images
import card_banners from '../../../images/card_banners.png';
import textStyles from 'app/styles/textStyles';
import { showAlert, sendActionEvent } from 'app/services/utils';
import { useSelector } from 'react-redux';
import CreditCard from 'app/screens/core/payment/components/CreditCard';
// import ReactNativePickerModule from 'react-native-picker-module';
import Icon from 'react-native-vector-icons/dist/FontAwesome';
// import { showMessage } from 'react-native-flash-message';
import QueryString from 'qs';
import useGlobalAlert from 'app/hooks/useGlobalAlert';
import CustomIcon from 'app/styles/icon';
import { useNavigation } from '@react-navigation/native';
import { Picker } from '@react-native-picker/picker';

const { width, height } = Dimensions.get('screen');

const PaymentScreen = ({ route }) => {

  const {
    business_verification,
    paymentType = 'subscribe',
    product_name = '',
    value,
    product,
    installments = 1,
    invite = true,
    trial_days,
    change,
  } = route?.params;

  const navigation = useNavigation();

  const { profile } = useSelector(state => state.user);
  const [paymentMethod] = useState('credit_card');
  const [type] = useState(paymentType);
  const [completeName, setCompleteName] = useState('');
  const [cardName, setCardName] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [validThru, setValidThru] = useState('');
  const [cardCVV, setCardCVV] = useState('');
  const [document, setDocument] = useState('');
  const [cardPayments, setCardPayments] = useState(1);
  const [discountCode, setDiscountCode] = useState('');
  const [discountCodeIsApplied, setDiscountCodeIsApplied] = useState(false);
  const [calcDiscount, setCalcDiscount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [discountLoading, setDiscountLoading] = useState(false);
  const [discountCodeData, setDiscountCodeData] = useState({});

  const [paymentsOptions, setPaymentOptions] = useState([]);

  const pickerCardPaymentsRef = useRef();

  const { setAlert } = useGlobalAlert();

  useEffect(() => {
    calcOptions();
  }, [calcDiscount]);

  const applyDiscountCode = async () => {
    setDiscountLoading(true);
    try {
      const { data } = await api.get(
        `payment/discount-code?plan_id=${product}&code=${discountCode}`
      );

      const _calcDiscount =
        data.type === 'percentage'
          ? (value * (data.amount / 100)).toFixed(2)
          : data.amount.toFixed(2);

      setDiscountCodeData(data);
      setCalcDiscount(_calcDiscount);
      window.alert('Código de desconto aplicado!');
      // showMessage({
      //   message: 'Sucesso',
      //   description: 'Código de desconto aplicado!',
      //   type: 'success',
      //   color: colors.white,
      // });
      setDiscountCodeIsApplied(true);
      setDiscountLoading(false);
    } catch (error) {
      console.log(error?.response?.data);
      window.alert('Código de desconto inválido ou expirado!');

      // showMessage({
      //   message: 'Erro',
      //   description: 'Código de desconto inválido ou expirado!',
      //   type: 'danger',
      //   color: colors.white,
      // });
      setDiscountCodeIsApplied(false);
      setDiscountLoading(false);
    }
  };

  const removeDiscountCode = () => {
    setCalcDiscount(0);
    setDiscountCode('');
    setDiscountCodeIsApplied(false);
    window.alert('Código de desconto removido!');
    // showMessage({
    //   message: 'Sucesso',
    //   description: 'Código de desconto removido!',
    //   type: 'success',
    //   color: colors.white,
    // });
  };

  const calcOptions = () => {
    let payments_options = [];
    const calc = value - calcDiscount;

    for (let i = 1; i <= installments; i++) {
      payments_options.push({
        // label: `${i}x R$ ${(calc / i).toFixed(2)} ${translate(pt.interest_free)}`,
        label: `${i}x R$ ${(calc / i).toFixed(2)} ${translate(pt.interest_free)}`.replace('.', ','),
        value: i,
      });
    }
    setPaymentOptions(payments_options);
  };

  const purchaseBundle = async () => {
    const data = {
      platform: 'android',
      bundle_id: product,
      cpf: document,
      card_holder_name: cardName,
      card_cvv: cardCVV,
      card_number: cardNumber.replace(/\s/g, ''),
      card_expiration_date: validThru.replace('/', ''),
    };

    setLoading(true);
    try {
      await api.post('credits/purchase', QueryString.stringify(data));
      setAlert(
        translate(pt.purchase_done_successfully),
        translate(pt.your_credits_are_available_for_user),
        {
          inverted: true,
          icon: <CustomIcon name={'check_circle'} size={70} />,
        }
      );
      navigation.goBack();

      navigation.navigate('Professional');
    } catch (error) {
      console.log('ERROR', error);
      showAlert('Falha ao processar o pagamento. Verifique os dados e tente novamente.');
      sendActionEvent('error_payment', { email: profile.email }, profile.email);
    }
    setLoading(false);
  };

  const sendForm = async () => {
    if (paymentMethod !== 'credit_card') {
      if (completeName === '') {
        return showAlert(translate(pt.name_missing));
      }

      if (document === '') {
        return showAlert(translate(pt.document_missing));
      }
    }

    if (type === 'credit_bundle') {
      return await purchaseBundle();
    }

    let params = {
      cpf: document.replace('.', '').replace('.', '').replace('-', ''),
      payment_method: paymentMethod,
      item_type: type,
      item_id: product,
      discount_code: discountCode,
      invite,
    };

    if (paymentMethod === 'credit_card') {
      params.card_holder_name = cardName;
      params.card_cvv = cardCVV;
      params.card_number = cardNumber.replace(/\s/g, '');
      params.card_expiration_date = validThru.replace('/', '');
      params.installments = cardPayments;
    }

    setLoading(true);

    try {
      const response = await api.post('payment/checkout', params);

      const paymentScreenParams = {
        courseName: product_name,
        courseValue: value,
        discountCode: discountCode,
        discountValue: calcDiscount,
        barcode: response.data.boleto?.boleto_barcode || null,
        bill_url: response.data.boleto?.boleto_url || null,
      };

      setLoading(false);

      if (paymentMethod === 'credit_card') {
        navigation.navigate('PaymentCompleteCC', paymentScreenParams);
      } else {
        navigation.navigate('PaymentCompleteBill', paymentScreenParams);
      }
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.message !== undefined) {
        console.log(error.response.data);
        showAlert('Erro ao enviar solicitação: ' + error.response.data.message);
        sendActionEvent('error_payment', { email: profile.email }, profile.email);
      }
    }
  };

  return (
    <View style={styles.container}>
      {loading ? (
        <View style={styles.loading}>
          <ActivityIndicator size="large" color={colors.aquaMarineTwo} />
          <Text style={textStyles.bold14White}>Aguarde...</Text>
        </View>
      ) : null}
      <HeaderAquamarine title={translate(pt.checkout)} />
      <ScrollView style={styles.screen} contentContainerStyle={{ flexGrow: 1 }}>
        {/* <View style={styles.paymentMethodsContainer}>
            <Text style={[styles.inputLabel, { marginBottom: 16 }]}>
              {translate(pt.payment_method)}
            </Text>
            <RadioForm
              radio_props={paymentMethods}
              initial={0}
              buttonColor={colors.aquaMarineTwo}
              selectedButtonColor={colors.aquaMarineTwo}
              formHorizontal={true}
              labelStyle={{ marginRight: 25 }}
              onPress={(value) => {
                setPaymentMethod(value);
              }}
            />
          </View> */}
        <View style={styles.card}>
          {paymentMethod === 'credit_card' ? (
            <View>
              <View style={styles.inputContainer}>
                <Text style={[styles.inputLabel, { marginBottom: 14 }]}>
                  {translate(pt.payment_method)}: {translate(pt.credit_card)}
                </Text>
                {/* <Text style={styles.paymentMethodTitle}>{translate(pt.credit_card)}</Text> */}
                <View style={[styles.row, { marginBottom: 10 }]}>
                  <Text style={styles.text}>{translate(pt.cc_banners)}</Text>
                  <Image style={styles.card_banners} source={card_banners} />
                </View>
              </View>
              <View style={{ marginVertical: 22 }}>
                <CreditCard
                  cardHolder={cardName}
                  CVV={cardCVV}
                  validThru={validThru}
                  cardNumber={cardNumber}
                />
              </View>
              <View style={styles.inputContainer}>
                <Text style={styles.inputLabel}>{translate(pt.name_on_card)}</Text>
                <TextInput
                  autoCapitalize="words"
                  onChangeText={valueOnChange => setCardName(valueOnChange)}
                  value={cardName}
                  keyboardType="default"
                  placeholder={translate(pt.name_on_card)}
                  style={styles.input}
                />
              </View>
              <View style={styles.inputContainer}>
                <Text style={styles.inputLabel}>{translate(pt.card_number)}</Text>
                <TextInputMask
                  type={'credit-card'}
                  autoCapitalize="none"
                  onChangeText={setCardNumber}
                  value={cardNumber}
                  keyboardType="number-pad"
                  placeholder={translate(pt.card_number)}
                  style={styles.input}
                />
              </View>
              <View style={styles.row}>
                <View style={styles.inputContainer}>
                  <Text style={styles.inputLabel}>{translate(pt.valid_thru)}</Text>
                  <TextInputMask
                    type={'custom'}
                    options={{
                      mask: '99/99',
                    }}
                    autoCapitalize="none"
                    onChangeText={setValidThru}
                    value={validThru}
                    keyboardType="number-pad"
                    placeholder={'MM/AA'}
                    style={[styles.input, { marginRight: 25 }]}
                  />
                </View>
                <View style={styles.inputContainer}>
                  <Text style={styles.inputLabel}>{translate(pt.cc_cvv)}</Text>
                  <TextInputMask
                    type={'custom'}
                    options={{
                      mask: '999',
                    }}
                    autoCapitalize="none"
                    onChangeText={setCardCVV}
                    value={cardCVV}
                    keyboardType="number-pad"
                    placeholder={'CVV'}
                    style={styles.input}
                  />
                </View>
              </View>
              <View style={styles.inputContainer}>
                <Text style={styles.inputLabel}>{translate(pt.document)}</Text>
                <TextInputMask
                  type={'cpf'}
                  autoCapitalize="none"
                  onChangeText={setDocument}
                  value={document}
                  keyboardType="number-pad"
                  placeholder={translate(pt.document)}
                  style={styles.input}
                />
              </View>
              <View style={styles.inputContainer}>
                <Text style={styles.inputLabel}>{translate(pt.payments)}</Text>
                <TouchableOpacity
                  // style={[styles.input, styles.row]}
                  >
                  {/* <Text style={textStyles.semi16}>
                    {paymentsOptions.some(item => item.value == cardPayments)
                      ? paymentsOptions.find(item => item.value == cardPayments).label
                      : translate(pt.payments)}
                  </Text> */}
                  <Picker
                    ref={pickerCardPaymentsRef}
                    selectedValue={cardPayments}
                    style={[styles.input, styles.row]}
                    itemStyle={{ fontSize: 40 }}
                    onValueChange={setCardPayments}>
                    {paymentsOptions.map((card, index) => {
                      return <Picker.Item label={card.label} value={card.value} />;
                    })}
                  </Picker>
                  {/* <Icon name="angle-down" size={22} color={colors.aquaMarineTwo} /> */}
                </TouchableOpacity>
                {/* <ReactNativePickerModule
                  pickerRef={pickerCardPaymentsRef}
                  selectedValue={cardPayments}
                  title={translate(pt.payments)}
                  items={paymentsOptions}
                  cancelButton={translate(pt.cancel)}
                  confirmButton={translate(pt.confirm)}
                  onValueChange={setCardPayments}
                /> */}
                {/* <RNPickerSelect
                      onValueChange={setCardPayments}
                      value={cardPayments}
                      useNativeAndroidPickerStyle={false}
                      textInputProps={{ style: { color: '#000000' } }}
                      items={paymentsOptions}
                      Icon={() => {
                        return (
                          <Image
                            style={{ marginTop: 17 }}
                            source={arrowDownBlack}
                            width={12}
                            height={8}
                          />
                        );
                      }}
                    /> */}
              </View>
              {/* <View style={[styles.inputContainer, { flexDirection: 'row', alignItems: 'center' }]}>
                    <CheckBox
                      disabled={false}
                      value={saveCard}
                      onValueChange={(newValue) => setSaveCard(newValue)}
                    />
                    <Text style={styles.checkboxLabel}>{translate(pt.save_card)}</Text>
                  </View> */}
              <View style={[styles.inputContainer, { marginTop: 10 }]}>
                <Text style={styles.title}>{translate(pt.discount_code)}</Text>
                <Text style={[styles.text, { marginBottom: 6 }]}>
                  {translate(pt.if_you_have_discount_code)}
                </Text>
                <TextInput
                  autoCapitalize="words"
                  onChangeText={valueOnChange => setDiscountCode(valueOnChange)}
                  value={discountCode}
                  editable={!discountLoading && !discountCodeIsApplied}
                  selectTextOnFocus={!discountLoading && !discountCodeIsApplied}
                  keyboardType="default"
                  placeholder={translate(pt.voucherDiscount)}
                  style={[styles.input, { marginBottom: 0 }]}
                />
                <ButtonBase
                  onPress={!discountCodeIsApplied ? applyDiscountCode : removeDiscountCode}
                  backgroundColor={!discountCodeIsApplied ? colors.aquaMarineTwo : colors.red}
                  loading={discountLoading}
                  title={translate(!discountCodeIsApplied ? pt.apply : pt.remove)}
                  style={styles.applyDiscountCodeButton}
                />
              </View>
              {discountCodeIsApplied && discountCodeData?.trial_days > 0 && (
                <Text style={styles.textGreen}>{`Com esse cupom o acesso gratuito passa a ser de ${
                  (trial_days || 15) + discountCodeData?.trial_days
                } dias.`}</Text>
              )}
              <View style={styles.divider} />
              <View>
                <Text style={styles.title}>{translate(pt.purchase_summary)}</Text>
                <View style={[styles.row, { marginBottom: 6 }]}>
                  <Text style={styles.summaryText}>{product_name}</Text>
                  <Text style={styles.summaryInfo}>
                    R$ {parseFloat(value).toFixed(2).replace('.', ',')}
                  </Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.summaryText}>{translate(pt.discount_code)}</Text>
                  <Text style={styles.summaryInfo}>
                    - R$ {parseFloat(calcDiscount).toFixed(2).replace('.', ',')}
                  </Text>
                </View>
                <View style={styles.divider} />
                <View style={styles.row}>
                  <Text style={styles.summaryTextTotal}>{translate(pt.total)}</Text>
                  <Text style={styles.summaryInfoTotal}>
                    R${' '}
                    {parseFloat(value - calcDiscount)
                      .toFixed(2)
                      .replace('.', ',')}
                  </Text>
                </View>
              </View>
            </View>
          ) : (
            <View>
              <View style={styles.inputContainer}>
                <Text style={styles.inputLabel}>{translate(pt.payment_method)}</Text>
                <Text style={styles.paymentMethodTitle}>{translate(pt.bill)}</Text>
              </View>
              <View style={styles.inputContainer}>
                <Text style={styles.inputLabel}>{translate(pt.complete_name)}</Text>
                <TextInput
                  autoCapitalize="words"
                  onChangeText={valueOnChange => setCompleteName(valueOnChange)}
                  value={completeName}
                  keyboardType="default"
                  placeholder={translate(pt.complete_name)}
                  style={styles.input}
                />
              </View>
              <View style={styles.inputContainer}>
                <Text style={styles.inputLabel}>{translate(pt.document)}</Text>
                <TextInputMask
                  type={'cpf'}
                  autoCapitalize="none"
                  onChangeText={setDocument}
                  value={document}
                  keyboardType="number-pad"
                  placeholder={translate(pt.document)}
                  style={styles.input}
                />
              </View>
              <View style={[styles.inputContainer, { marginTop: 10 }]}>
                <Text style={styles.title}>{translate(pt.discount_code)}</Text>
                <Text style={[styles.text, { marginBottom: 6 }]}>
                  {translate(pt.if_you_have_discount_code)}
                </Text>
                <TextInput
                  autoCapitalize="words"
                  onChangeText={valueOnChange => setDiscountCode(valueOnChange)}
                  value={discountCode}
                  keyboardType="default"
                  placeholder={translate(pt.voucherDiscount)}
                  style={[styles.input, { marginBottom: 0 }]}
                />
                <ButtonBase
                  onPress={applyDiscountCode}
                  backgroundColor={colors.aquaMarineTwo}
                  title={translate(pt.apply)}
                  style={styles.applyDiscountCodeButton}
                />
              </View>
              <View style={styles.divider} />
              <View>
                <Text style={styles.title}>{translate(pt.purchase_summary)}</Text>
                <View style={[styles.row, { marginBottom: 6 }]}>
                  <Text style={styles.summaryText}>{product_name}</Text>
                  <Text style={styles.summaryInfo}>
                    R$ {parseFloat(value).toFixed(2).replace('.', ',')}
                  </Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.summaryText}>{translate(pt.discount_code)}</Text>
                  <Text style={styles.summaryInfo}>
                    - R$ {parseFloat(calcDiscount).toFixed(2).replace('.', ',')}
                  </Text>
                </View>
                <View style={styles.divider} />
                <View style={styles.row}>
                  <Text style={styles.summaryText}>{translate(pt.total)}</Text>
                  <Text style={styles.summaryInfoTotal}>
                    R${' '}
                    {parseFloat(value - calcDiscount)
                      .toFixed(2)
                      .replace('.', ',')}
                  </Text>
                </View>
              </View>
            </View>
          )}
        </View>
      </ScrollView>
      <View style={styles.concludeButtonContainer}>
        <ButtonBase
          onPress={sendForm}
          backgroundColor={colors.aquaMarine}
          title={translate(pt.conclude)}
          fill
          square
          style={styles.concludeButton}
        />
      </View>
    </View>
  );
};

export default PaymentScreen;

const styles = StyleSheet.create({
  loading: {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    position: 'absolute',
    zIndex: 99,
    width: width,
    height: height,
    justifyContent: 'center',
    alignItems: 'center',
  },
  screen: {
    backgroundColor: colors.whiteTwo,
  },
  card_banners: {
    width: '60%',
    height: 12,
    resizeMode: 'contain',
  },
  paymentMethodsContainer: {
    paddingTop: 22,
    paddingHorizontal: 22,
    paddingBottom: 30,
  },
  paymentMethodTitle: {
    borderWidth: 2,
    borderColor: colors.cornflowerBlue,
    color: colors.cornflowerBlue,
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'center',
    lineHeight: 35,
    borderRadius: 4,
    marginTop: 15,
    marginBottom: 15,
    paddingHorizontal: 14,
  },
  card: {
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    backgroundColor: colors.white,
    paddingTop: 32,
    paddingHorizontal: 22,
    paddingBottom: 32 + 45,
    top: 30,
  },
  inputContainer: {
    flex: 1,
  },
  inputLabel: {
    fontWeight: 'bold',
    fontSize: 15,
    color: colors.blackThree,
  },
  input: {
    marginTop: 9,
    marginBottom: 15,
    paddingHorizontal: 14,
    height: 45,
    borderRadius: 5,
    backgroundColor: colors.white,
    color: colors.black,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: colors.lightPeriwinkle,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  text: {
    fontSize: 12,
    color: colors.blackThree,
  },
  checkboxLabel: {
    fontSize: 12,
    fontWeight: 'bold',
    color: colors.greyBlue,
  },
  applyDiscountCodeButton: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    height: 45,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 4,
  },
  divider: {
    marginVertical: 22,
    height: 1,
    width: '100%',
    backgroundColor: colors.lightPeriwinkle,
  },
  container: {
    flex: 1,
    backgroundColor: colors.paleGrey,
    paddingBottom: 30,
  },
  title: {
    fontWeight: 'bold',
    fontSize: 15,
    color: colors.blackThree,
    marginBottom: 6,
  },
  textGreen: {
    fontWeight: 'bold',
    fontSize: 15,
    color: colors.greenBlue73,
    marginTop: 6,
  },
  summaryText: {
    fontSize: 14,
    fontWeight: '400',
    color: colors.black4,
    maxWidth: Dimensions.get('screen').width - 100,
  },
  summaryInfo: {
    fontSize: 14,
    fontWeight: '700',
    color: colors.greyLight5,
  },
  summaryTextTotal: {
    fontSize: 14,
    fontWeight: '700',
    color: colors.blackThree,
    maxWidth: Dimensions.get('screen').width - 100,
  },
  summaryInfoTotal: {
    fontSize: 14,
    fontWeight: '700',
    color: colors.greyBlue,
  },
  concludeButtonContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    right: 0,
  },
  concludeButton: {
    width: '100%',
    height: 45,
  },
});
