import React, { useState, useRef, useEffect, useCallback } from 'react';

import {
  View,
  StyleSheet,
  Dimensions,
  Text,
  ScrollView,
  SectionList,
  ActivityIndicator,
  Platform,
  TouchableOpacity,
} from 'react-native';
import colors from '../../../styles/colors';
import { useSafeArea } from 'react-native-safe-area-context';
import RowClassOver from '../../profile/components/row/RowClassOver';
import MonthDayPointer from '../explorer/components/MonthDayPointer';
import { translate } from '../../../i18n/src/locale';
import pt from '../../../i18n/src/locale/pt';
import ShadowView from '@liuyunjs/react-native-simple-shadow-view';
import Calendar from 'react-calendar';
import ButtonBase from '../../../components/ButtonBase';
import RowDualPhotos from '../../profile/components/row/RowDualPhotos';
import commons from '../../../styles/commons';
import More from '../../../components/More';
import ovalBottomPurple from '../../../images/ovalBottomPurple.png';
import { Image } from 'react-native-animatable';
import * as Animatable from 'react-native-animatable';
import HeaderMonth from './components/HeaderMonth';
import anim from '../../../styles/anim';
import RBSheet from 'react-native-raw-bottom-sheet';
import BottomSheetAddSchedule from './components/BottomSheetAddSchedule';
import Modal from 'react-native-modal';
import ModalAgenda from './components/ModalAgenda';
import api from 'app/services/api';
import { showAlert, errorControl, sendActionEvent } from 'app/services/utils';
import { getMeta } from 'app/services/apiMetas';
import META from 'app/configs/meta';
import QueryString from 'qs';
import ButtonBaseInvert from 'app/components/ButtonBaseInvert';
import { useSelector } from 'react-redux';
import arrowLeft from '../../../images/arrowRight.png';

import apiNode from 'app/services/apiNode';
import FavoriteProfessionalCardCalendar from 'app/screens/core/professional/components/FavoriteProfessionalCardCalendar';

import 'moment';
import 'moment/locale/pt-br';
import moment from 'moment-timezone';
import './calendar.css';
import { useNavigation, useRoute } from '@react-navigation/native';

const food = { key: 'food', color: colors.tomato };
const activity = { key: 'activity', color: colors.aquaMarine };
const meditation = { key: 'meditation', color: colors.pastelOrange };
const session = { key: 'session', color: colors.greenBlue73 };

const { width } = Dimensions.get('screen');

const CalendarScreen = () => {
  const { profile } = useSelector(state => state.user);
  const [currentMonth, setCurrentMonth] = useState(moment());
  const [categoryId, setCategoryId] = useState('');
  const [markedDates, setMarkedDates] = useState({});
  const [schedules, setSchedules] = useState([]);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const refRBSheet = useRef();
  const [modal, setModal] = useState(false);
  const [isUserAgenda, setIUserAgenda] = useState(true);
  const [selected, setSelected] = useState(null);
  const [selectByDate, setSelectByDate] = useState(false);
  let [selectedDates, setSelectedDates] = useState({});
  const [startDate, setStarteDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const scrollRef = useRef();
  const openedAdd = useRoute().params?.openedAdd;
  const navigation = useNavigation();

  const requestUserSchedule = useCallback(async (firstDayMonth, lastDayMonth, idCategory = '') => {
    setIsLoading(true);

    try {
      const response = await api.get(
        `user-schedule?timestamp_start=${firstDayMonth}
        &timestamp_end=${lastDayMonth}
        &category_id=${idCategory}`
      );

      const sessionData = await apiNode.get(
        `sessions/get-by-month/${profile.user_id}/${firstDayMonth.substring(
          5,
          7
        )}/${firstDayMonth.substring(0, 4)}`
      );

      let sectionList = response.data.reduce((line, data) => {
        const date = moment(data.start_date).format('YYYY-MM-DD');
        if (!line[date]) {
          line[date] = [];
        }
        line[date].push(data);
        return line;
      }, {});

      try {
        sessionData.data.map(s => {
          if (!sectionList[s.sessionDate.substring(0, 10)]) {
            sectionList[s.sessionDate.substring(0, 10)] = [];
          }
          sectionList[s.sessionDate.substring(0, 10)].push(s);
        });
      } catch (er) {
        console.log(er);
      }

      const scheduleData = Object.keys(sectionList).map(send => ({
        title: send,
        data: sectionList[send],
      }));
      generateDotsCalendar(response.data, sessionData.data);
      setSchedules(scheduleData.sort((a, b) => new Date(a.title) - new Date(b.title)));
      if (openedAdd && refRBSheet.current) {
        refRBSheet.current.open();
      }
    } catch (error) {
      console.log({ error });
      showAlert(errorControl(error));
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    getUserAgenda();
  }, [requestUserSchedule]);

  useEffect(() => {
    requestUserSchedule(firstDayMonth, lastDayMonth, categoryId)
  }, []);

  useEffect(() => {
    if (!isUserAgenda) {
      setModal(true);
    }
  }, [isUserAgenda]);

  const _format = 'YYYY-MM-DD';
  const firstDayMonth = moment(currentMonth).startOf('month').format('YYYY-MM-DD');
  const lastDayMonth = moment(currentMonth).endOf('month').add(1, 'day').format('YYYY-MM-DD');

  const getUserAgenda = async () => {
    const userAgenda = await getMeta(META.USER_AGENDA_ACKNOWLEDGE);

    setIUserAgenda(userAgenda[0]?.meta_value);
  };

  const requestUserAgenda = async () => {
    try {
      await api.patch(
        'user-metadata',
        QueryString.stringify({
          meta_key: META.USER_AGENDA_ACKNOWLEDGE,
          meta_value: true,
        })
      );
      setModal(false);
    } catch (error) {}
  };

  useEffect(() => {
    requestUserSchedule(firstDayMonth, lastDayMonth, categoryId);
  }, [currentMonth, categoryId, requestUserSchedule]);

  const nextMonth = () => {
    setSelected(false);
    setCurrentMonth(moment(currentMonth).add(1, 'month'));
  };

  const previousMonth = () => {
    setSelected(false);
    setCurrentMonth(moment(currentMonth).subtract(1, 'month'));
  };

  

  const scrollToCurrentDay = () => {
    let days = 0;
    let items = 0;
    for (let date of schedules) {
      if (date.title === moment().format('YYYY-MM-DD')) {
        break;
      }
      items += date.data.length;
      days++;
    }

    if (schedules.length) {
      scrollRef.current.scrollTo({ animated: true, y: 40 * days + items * 100 });
    }
  };

  const selectCategory = id => {
    if (id === 0) {
      setSelectByDate(true);
    } else {
      setCategoryId(id);
    }
    setOpen(true);
  };

  const enumerateDaysBetweenDates = (start, end) => {
    var now = moment.utc(start).add(1, 'days'),
      dates = [];
    dates.push(moment.utc(start).format(_format));
    while (now.isBefore(end)) {
      dates.push(now.format(_format));
      now.add(1, 'days');
    }
    return dates;
  };

  const onDaySelect = day => {
    const counter = Object.keys(selectedDates).length;
    const _selectedDay = moment(day).format(_format);
    let first = counter === 0;
    let selected = true;
    let betweenDates = {};

    const reset = () => {
      first = true;
      selected = true;
      selectedDates = {};
    };

    if (selectedDates[_selectedDay]) {
      // Already in marked dates, so reverse current marked state
      selected = !selectedDates[_selectedDay].selected;
      first = true;
    }

    if (counter >= 2) {
      //if already two dates set, clear list
      reset();
    } else if (counter === 1) {
      if (!moment(moment(day).toDate().getTime()).add(1, 'days').isAfter(startDate)) {
        //reset to early date
        reset();
      } else {
        //fill date between
        const arr = enumerateDaysBetweenDates(startDate, moment(day).toDate().getTime());

        arr.map(item => {
          betweenDates[item] = {
            selected: true,
            selectedColor: colors.aquaMarineTwo20,
            color: colors.aquaMarineTwo20,
            textColor: colors.blackTwo,
          };
        });
      }
    }

    const updatedSelectedDates = {
      ...selectedDates,
      ...betweenDates,
      ...{
        [_selectedDay]: {
          selected,
          selectedColor: colors.aquaMarineTwo,
          color: colors.aquaMarineTwo,
          textColor: colors.white,
          startingDay: first,
          endingDay: !first,
        },
      },
    };

    //remove not selected item
    if (!selected) {
      delete updatedSelectedDates[_selectedDay];
    }

    if (first) {
      setStarteDate(moment(moment(day).toDate().getTime()).add(1, 'days'));
      setEndDate(moment(moment(day).toDate().getTime()).add(1, 'days'));
    } else {
      setEndDate(moment(moment(day).toDate().getTime()).add(1, 'days'));
    }
  
    // Triggers component to render again, picking up the new state
    setSelectedDates(updatedSelectedDates);
  };

  const filterDates = () => {
    const startDateFormat = moment(startDate).format('YYYY-MM-DD');
    const endDateFormat = moment(endDate).format('YYYY-MM-DD');
    requestUserSchedule(startDateFormat, endDateFormat);
  };

  const onDayPress = day => {
    //setSchedules(schedules.filter(s => s.title === day.dateString));
    if (selected === day) {
      setSelected(false);
    } else {
      setSelected(day);
    }
    //setSelected(day.dateString);
  };

  const generateDotsCalendar = (schedulesData, sessionData) => {
    let sectionList = schedulesData.reduce((line, data) => {
      const date = moment(data.start_date).format('YYYY-MM-DD');
      const category_item = data.category_item.id ? data.category_item.id : data.category_item;
      if (!line[date]) {
        line[date] = { dots: [] };
      }
      if (category_item === 1 && line[date].dots.indexOf(food) === -1) {
        line[date].dots.push(food);
      }
      if (category_item === 2 && line[date].dots.indexOf(activity) === -1) {
        line[date].dots.push(activity);
      }
      if (category_item === 3 && line[date].dots.indexOf(meditation) === -1) {
        line[date].dots.push(meditation);
      }
      return line;
    }, {});

    try {
      sessionData.map(s => {
        if (!sectionList[s.sessionDate.substring(0, 10)]) {
          sectionList[s.sessionDate.substring(0, 10)] = { dots: [session] };
        } else {
          sectionList[s.sessionDate.substring(0, 10)].dots.push(session);
        }
      });
    } catch (er) {
      console.log(er);
    }
    setMarkedDates(sectionList);
  };

  const clearDateFilters = () => {
    setOpen(false);
    requestUserSchedule(firstDayMonth, lastDayMonth);
    setSelectedDates({});
  };

  const Agenda = () => {
    const selectedDatesArray = Object.keys(selectedDates);

    return (
      <>
        <Animatable.View
          animation="fadeInDown"
          style={{ backgroundColor: open ? colors.white : colors.paleGrey }}>
          <TouchableOpacity style={styles.backButton} onPress={navigation.goBack}>
            <Image source={{ uri: arrowLeft }} style={{ width: 22, height: 12, transform: [{ scaleX: -1 }], width: 22, resizeMode: 'contain' }} />
          </TouchableOpacity>
          <HeaderMonth
            open={open}
            onPress={() => setOpen(!open)}
            month={currentMonth.locale('pt-br')}
            previousMonth={previousMonth}
            nextMonth={nextMonth}
            selectCategory={selectCategory}
          />
        </Animatable.View>

        {open && (
          <Animatable.View useNativeDriver animation={selectByDate ? null : anim.calendar}>
            <ShadowView style={styles.calendarHolder}>
              {selectByDate ? (
                <View style={styles.calendarContainer}>
                  <Calendar 
                    showNavigation={false}
                    onClickDay={(value) => onDaySelect(value)}
                    activeStartDate={new Date(moment(currentMonth).format('YYYY'), Number(moment(currentMonth).format('MM')) - 1, 1)}
                    locale='pt'
                    formatShortWeekday={(_, date) => moment(date).format('dddd')[0]}
                    calendarType='hebrew'
                    selectRange
                    tileClassName={({date}) => {
                      if (!selectedDates) return;

                      const datesToRender = Object.keys(selectedDates);
                      const firstDate = datesToRender[0];
                      const endDate = datesToRender[datesToRender.length - 1];

                      if (moment(date).format('YYYY-MM-DD') === firstDate || moment(date).format('YYYY-MM-DD') === endDate) {
                        return 'react-calendar__tile--active'
                      }

                      if (datesToRender.includes(moment(date).format('YYYY-MM-DD'))) {
                        return 'react-calendar__tile--range'
                      }
                    }}
                  />
                  <View style={styles.filterDatesButtonContainer}>
                    <ButtonBaseInvert
                      color={colors.aquaMarineTwo}
                      style={styles.filterDatesButton}
                      onPress={() => {
                        clearDateFilters();
                        sendActionEvent(
                          'click_clean_filter_agenda',
                          { email: profile.email },
                          profile.email
                        );
                      }}
                      title={translate(pt.clean_filter)}
                    />
                    <ButtonBase
                      style={styles.filterDatesButton}
                      onPress={() => {
                        filterDates(
                          selectedDatesArray[0],
                          selectedDatesArray[selectedDatesArray.length - 1]
                        );
                        sendActionEvent(
                          'click_day_filter_agenda',
                          { email: profile.email },
                          profile.email
                        );
                      }}
                      title={translate(pt.filter)}
                    />
                  </View>
                </View>
              ) : (
                <Calendar
                  showNavigation={false}
                  onClickDay={(value) => onDayPress(value)}
                  formatShortWeekday={(_, date) => moment(date).format('dddd')[0]}
                  locale='pt'
                  tileContent={({ date }) => {
                    const sessions = markedDates[moment(date).format('YYYY-MM-DD')];
                    if (!!sessions && sessions?.dots?.length) {
                      const isSelected = moment(date).format('YYYY-MM-DD') === moment(selected).format('YYYY-MM-DD');
                      return (
                        <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                          {sessions?.dots.map(s => 
                            <View 
                              style={{ 
                                width: 4, 
                                height: 4, 
                                backgroundColor: !isSelected ? s.color : '#fff', 
                                marginHorizontal: 0.5, 
                                borderRadius: 50 
                              }} 
                            />
                          )}
                        </View>
                      )
                    }
                  }}
                  calendarType='hebrew'
                  value={selected}
                  activeStartDate={new Date(moment(currentMonth).format('YYYY'), Number(moment(currentMonth).format('MM')) - 1, 1)}
                />
              )}
            </ShadowView>
          </Animatable.View>
        )}
      </>
    );
  };

  const insets = useSafeArea();
  if (isLoading) {
    return (
      <View style={styles.flexCenter}>
        <ActivityIndicator size="large" color={colors.aquaMarineTwo} />
      </View>
    );
  }

  const componentFiltered = item => {
    if (item.sessionDate) {
      return (
        <FavoriteProfessionalCardCalendar
          item={item}
          onCloseModal={() => requestUserSchedule(firstDayMonth, lastDayMonth)}
        />
      );
    }
    if (item.meal_id === null && item.dish_id === null) {
      return (
        <RowClassOver
          item={filterSchedule(item)}
          onCloseModal={() => requestUserSchedule(firstDayMonth, lastDayMonth)}
        />
      );
    }

    return (
      <RowDualPhotos
        item={filterMealDish(item)}
        onCloseModal={() => requestUserSchedule(firstDayMonth, lastDayMonth)}
      />
    );
  };

  const filterSchedule = schedule => {
    let scheduleFiltered = {};
    if (schedule.class_id !== null) {
      scheduleFiltered = {
        ...schedule,
        name: schedule.class_name,
        image: schedule.image_url.path,
        duration: schedule.duration,
      };
      return scheduleFiltered;
    }

    return schedule;
  };

  const filterMealDish = schedule => {
    let mealDishFiltered = {};
    if (schedule.meal_id !== null) {
      mealDishFiltered = {
        ...schedule,
        name: schedule.meal_name,
        images: schedule.meal_images,
        duration: schedule.duration,
      };
      return mealDishFiltered;
    }
    if (schedule.dish_id !== null) {
      const images = [{ path: schedule.image_url.path }];
      mealDishFiltered = {
        ...schedule,
        name: schedule.dish_name,
        images: images,
        duration: schedule.dish_preparation_time,
      };
      return mealDishFiltered;
    }
    return schedule;
  };

  return (
    <>
      <View style={styles.container}>
        <Image
          useNativeDriver
          animation="fadeIn"
          delay={1000}
          duration={2000}
          style={styles.oval}
          source={ovalBottomPurple}
          resizeMode={'stretch'}
        />
        <View style={{ flex: 1, paddingTop: insets.top }}>
          <Agenda />
          <ScrollView
            ref={scrollRef}
            onContentSizeChange={() => {
              scrollToCurrentDay();
            }}
            contentContainerStyle={{ flexGrow: 1 }}>
            <View style={{ flex: 1 }}>
              <SectionList
                ListEmptyComponent={
                  <View style={styles.flexCenter}>
                    <Text>{translate(pt.no_results)}</Text>
                  </View>
                }
                contentContainerStyle={{ paddingBottom: 20 }}
                sections={selected ? schedules.filter(s => s.title === moment(selected).format('YYYY-MM-DD')) : schedules}
                keyExtractor={(item, index) => index}
                renderSectionHeader={({ section }) => <MonthDayPointer date={section.title} />}
                renderItem={({ item }) => {
                  return componentFiltered(item);
                }}
              />
            </View>
          </ScrollView>
          <View style={styles.footer}>
            <More onPress={() => refRBSheet.current.open()} />
          </View>
        </View>
        <Modal animationInTiming={600} isVisible={modal}>
          <ModalAgenda onClose={requestUserAgenda} />
        </Modal>
        <RBSheet ref={refRBSheet}>
          <BottomSheetAddSchedule
            onClose={() => {
              refRBSheet.current.close();
            }}
          />
        </RBSheet>
      </View>  
    </>
  );
};

export default CalendarScreen;

const styles = StyleSheet.create({
  flexCenter: { flex: 1, alignItems: 'center', justifyContent: 'center' },
  footer: {
    ...commons.flexRowBetween,
    position: 'absolute',
    bottom: 15,
    right: 8,
    paddingHorizontal: 14,
    paddingBottom: 8,
    justifyContent: 'flex-end',
  },
  container: {
    flex: 1,
    backgroundColor: colors.paleGrey,
  },
  oval: {
    position: 'absolute',
    opacity: 0.6,
    width: width,
    bottom: 0,
    elevation: -5,
    zIndex: -10,
    // backgroundColor: 'blue',
  },
  calendarHolder: {
    overflow: 'hidden',
    shadowColor: colors.black,

    shadowOffset: {
      width: 0,
      height: 12,
    },
    shadowRadius: 8,
    shadowOpacity: 0.05,
    marginBottom: 20,
  },
  calendar: {
    height: 310,
    borderBottomRightRadius: 30,
    paddingBottom: 30,
    marginBottom: 20,
  },
  filterDatesButtonContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingRight: 20,
  },
  filterDatesButton: {
    marginTop: 0,
    marginLeft: 20,
    paddingHorizontal: 0,
  },
  calendarContainer: {
    backgroundColor: colors.white,
    paddingBottom: 25,
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
  },
  backButton: {
    padding: 5, 
    top: 20, 
    left: 20, 
    position: 'absolute', 
    borderRadius: 50,
    zIndex: 1,
    backgroundColor: '#00000033',
    width: 38,
    height: 22,
    justifyContent: 'center',
    alignItems: 'center',
  }
});
