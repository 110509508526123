import React from 'react';
import { View, Image, StyleSheet, TouchableOpacity, Text, SafeAreaView } from 'react-native';
import Video from '@lnormanha/react-native-web-video/build/Video';
import { Dimensions } from 'react-native';
import { ActivityIndicator } from 'react-native';
import fullscreenClose from '../../images/fullscreenClose.png';
import fullscreenOpen from '../../images/fullscreenOpen.png';
import musicOff from '../../images/musicOff.png';
import musicOn from '../../images/musicOn.png';
import arrowRightWater2 from '../../images/arrowRightWater2.png';
import colors from 'app/styles/colors';
import CapsuleTimeLeft from 'app/components/CapsuleTimeLeft';
import CapsuleLevelNew from 'app/components/CapsuleLevelNew';
import CapsuleTimeToPrepare from 'app/components/CapsuleTimeToPrepare';
import fonts from 'app/styles/fonts';
import LinearGradient from 'react-native-linear-gradient';
// import Balloon from 'react-native-balloon';

const { width: vWidth, height: vHeight } = Dimensions.get('screen');

const ExerciseVideoPlayer = ({
  playerRef,
  muted = false,
  colorBase,
  videoUrl,
  onLoadEnd,
  onProgress,
  playerType,
  onEnd,
  isVideoLogin,
  handleFullscreen,
  handleMuted,
  inRest,
  restDuration,
  controls,
  state,
  handlePressBack,
  difficulty,
  playerControlsComponent,
  classDuration,
}) => (
  <>
    <Video
      ref={ref => {
        playerRef.current.video = ref;
      } }
      source={{
        uri: videoUrl,
      }}
      key={'player-non-muted'}
      muted={muted}
      style={styles.video}
      controls={controls}
      resizeMode="contain"
      onLoad={onLoadEnd}
      progressUpdateInterval={50}
      onProgress={onProgress}
      onEnd={onEnd}
      repeat={state.repeat}
      paused={!state.play}
      ignoreSilentSwitch="ignore" />
    {isVideoLogin && <ActivityIndicator color={colors.white} style={styles.containerIndicator} />}

    <View
      style={state.showControls && state.fullscreen
        ? [styles.containerShowControls]
        : styles.containerOverlayControlSmall}>
      {state.showControls && state.fullscreen && (
        <SafeAreaView
          style={{
            zIndex: 1000,
            position: 'absolute',
            top: 0,
            left: 0,
            flex: 1,
            width: '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <TouchableOpacity
            onPress={handlePressBack}
            style={{
              position: 'absolute',
              zIndex: 100,
              backgroundColor: '#00000033',
              width: 48,
              height: 22,
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 15,
              top: 20,
              left: 20,
            }}>
            <Image
              source={arrowRightWater2}
              style={{
                transform: [{ rotate: '180deg' }],
                width: 22,
                height: 15,
                resizeMode: 'contain',
                tintColor: colors.white,
              }} />
          </TouchableOpacity>
          {playerControlsComponent}
        </SafeAreaView>
      )}
      <View>
        {!state.fullscreen ? (
          <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <TouchableOpacity
              onPress={handlePressBack}
              style={{
                zIndex: 1,
                backgroundColor: '#00000033',
                width: 48,
                height: 22,
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 15,
                marginTop: 15,
                marginLeft: 15,
              }}>
              <Image
                tintColor={colors.white}
                source={arrowRightWater2}
                style={{
                  transform: [{ rotate: '180deg' }],
                  width: 22,
                  height: 15,
                  tintColor: colors.white,
                  resizeMode: 'contain',
                }} />
            </TouchableOpacity>
            {inRest && (
              <LinearGradient
                style={{ width: '100%', position: 'absolute', alignItems: 'flex-end', zIndex: 0 }}
                colors={['#00000055', '#00000000']}>
                <View style={{ position: 'absolute', width: '100%', marginTop: 15 }}>
                  <Text style={{ textAlign: 'center', color: 'white', fontFamily: fonts.BOLD }}>
                    Pré-visualização
                  </Text>
                </View>
                <CapsuleTimeToPrepare
                  duration={restDuration}
                  style={{ marginTop: 20, marginRight: 20 }} />
              </LinearGradient>
            )}
          </View>
        ) : !state.showControls ? (
          inRest ? (
            <LinearGradient
              style={{ width: '100%', position: 'absolute', alignItems: 'flex-start', zIndex: 0 }}
              colors={['#00000055', '#00000000']}>
              <View style={{ position: 'absolute', width: '100%', marginTop: 25 }}>
                <Text
                  style={{
                    textAlign: 'center',
                    color: 'white',
                    fontFamily: fonts.BOLD,
                    fontSize: 20,
                  }}>
                  Pré-visualização
                </Text>
              </View>
              <CapsuleTimeToPrepare
                duration={restDuration}
                currentTime={state.currentTime}
                style={{ marginTop: 20, marginLeft: 20 }} />
            </LinearGradient>
          ) : (
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              {playerType === 'course' && (
                <TouchableOpacity
                  onPress={handlePressBack}
                  style={{
                    zIndex: 1,
                    backgroundColor: '#00000033',
                    width: 48,
                    height: 22,
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 15,
                    marginTop: 15,
                    marginLeft: 15,
                  }}>
                  <Image
                    tintColor={colors.white}
                    source={arrowRightWater2}
                    style={{
                      transform: [{ rotate: '180deg' }],
                      width: 22,
                      height: 15,
                      resizeMode: 'contain',
                      tintColor: colors.white,
                    }} />
                </TouchableOpacity>
              )}
              <CapsuleTimeLeft
                duration={classDuration}
                currentTime={state.currentTime}
                style={{ marginTop: 20, marginHorizontal: 20 }} />
            </View>
          )
        ) : null}

        {state.fullscreen && !state.showControls && difficulty != null && (
          <CapsuleLevelNew difficulty={difficulty} style={[styles.capsuleLevel, { zIndex: 1 }]} />
        )}
      </View>
      {!state.fullscreen && (
        <>
          {!state.hasAudio && inRest && (
            <View style={{ position: 'absolute', bottom: 40 }}>
              { /* <Balloon
                backgroundColor="#fff"
                width={130}
                borderWidth={0}
                borderColor={'white'}
                triangleOffset={'15%'}
                borderRadius={15}
                triangleSize={10}>
                <Text>Vídeo sem áudio</Text>
          </Balloon> */ }
            </View>
          )}
          <TouchableOpacity
            onPress={handleMuted}
            style={[styles.musicOffButton, styles.positionButtonMusicOff]}>
            <Image
              tintColor={state.hasAudio ? colorBase : '#E53636'}
              style={[
                styles.imageMusicOff,
                {
                  opacity: state.hasAudio ? 1 : 0.5,
                  tintColor: state.hasAudio ? colorBase : '#E53636',
                },
              ]}
              source={!state.hasAudio ? musicOff : muted ? musicOff : musicOn} />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={handleFullscreen}
            style={[styles.fullscreenButton, styles.positionButtonFullScreen]}>
            <Image
              tintColor={colorBase}
              style={[styles.imageFullscreen, { tintColor: colorBase }]}
              source={state.fullscreen ? fullscreenClose : fullscreenOpen} />
          </TouchableOpacity>
        </>
      )}
    </View>
  </>
);

export default ExerciseVideoPlayer;

const styles = StyleSheet.create({
  containerShowControls: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: colors.black,
    opacity: 0.5,
  },
  containerOverlayControlSmall: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  containerIndicator: { position: 'absolute', top: 0, bottom: 0, left: 0, zIndex: 100, right: 0 },
  imageFullscreen: { width: 20, height: 20 },
  positionButtonFullScreen: { padding: 5, position: 'absolute', bottom: 10, right: 10, zIndex: 2 },
  positionButtonMusicOff: { padding: 5, position: 'absolute', bottom: 10, left: 10, zIndex: 2 },
  capsuleLevel: {
    position: 'absolute',
    right: 38,
    top: 34,
    backgroundColor: colors.white,
    borderRadius: 14,
  },
  video: {
    // height: ((vWidth < vHeight ? vWidth : vHeight) * 9) / 16,
    // width: vWidth < vHeight ? vWidth : vHeight,
    backgroundColor: 'black',
  },
  fullscreenVideo: {
    height: vWidth < vHeight ? vWidth : vHeight,
    width: vWidth > vHeight ? vWidth : vHeight,
    backgroundColor: 'black',
  },
  controlOverlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    zIndex: 99,
    right: 0,
    backgroundColor: '#000000c4',
    justifyContent: 'space-between',
  },
  fullscreenButton: {
    flex: 1,
    flexDirection: 'row',
    alignSelf: 'flex-end',
    alignItems: 'center',
    paddingRight: 10,
  },
  musicOffButton: {
    flex: 1,
    flexDirection: 'row',
    alignSelf: 'flex-end',
    paddingRight: 10,
  },
  imageMusicOff: {
    width: 20,
    height: 20,
    resizeMode: 'contain',
  },
});
