import React from 'react';
import { View, StyleSheet, Text } from 'react-native';

import SendingMessage from './SendingMessage';
import ReceiveMessage from './ReceiveMessage';
import { useEffect } from 'react';
import { useState } from 'react';

const Messages = prop => {
  const { item } = prop;
  const message = item.item;
  const [percentage, setPercentage] = useState(prop.percentage);

  return (
    <View style={styles.container}>
      {message.sentBy === 'user' ? (
        <SendingMessage
          message={message}
          userProId={message.userProId}
          cancelMessage={prop.cancelMessage}
          onLongPress={prop.onLongPress}
          selectedMessages={prop.selectedMessages}
          percentage={percentage}
        />
      ) : (
        <ReceiveMessage message={message} userId={message.userProId} />
      )}

      {/* <CardInvite /> */}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    //flex: 1,
  },
});

export default Messages;
