import ButtonBase from 'app/components/ButtonBase';
import pt from 'app/i18n/src/locale/pt';
import colors from 'app/styles/colors';
import fonts from 'app/styles/fonts';
import React, { useEffect, useState } from 'react';
import {
  Alert,
  Image,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Modal
} from 'react-native';
import backgroundCustomSearch from 'app/images/backgroundCustomSearch.png';
import AnimatedLottieView from 'lottie-react-native';
import commons from 'app/styles/commons';
import CustomIcon from 'app/styles/icon';
import Icon from 'react-native-vector-icons/dist/FontAwesome';
import textStyles from 'app/styles/textStyles';
import { translate } from 'app/i18n/src/locale';
import { errorControl } from 'app/services/utils';
import ButtonShadow from 'app/components/ButtonShadow';
import AddCreditCardModal from 'app/screens/core/gym/components/AddCreditCardModal';
import SwitchCreditCardModal from 'app/screens/core/gym/components/SwitchCreditCardModal';
import LinearGradient from 'react-native-linear-gradient';
import apiNode from 'app/services/apiNode';
import useGlobalAlert from 'app/hooks/useGlobalAlert';
import cardFlags from 'app/constants/cardFlags';
import { useNavigation } from '@react-navigation/native';

const ReserveDayPassModal = ({ gym, open, onClose }) => {

  const navigation = useNavigation();

  const [loading, setLoading] = useState(true);
  const [addCreditCardModal, setAddCreditCardModal] = useState(false);
  const [switchCardModal, setSwitchCardModal] = useState(false);
  const [openedModal, setOpenedModal] = useState('reserve');
  const [selectedCard, setSelectedCard] = useState();
  const [hasFreeCheckin, setHasFreeCheckin] = useState();
  const [hasCards, setHasCards] = useState(false);
  const { setAlert } = useGlobalAlert();

  useEffect(() => {
    if (open && openedModal == 'reserve') {
      fetchData();
    }
  }, [open, openedModal]);

  const fetchData = async () => {
    setLoading(true);
    await Promise.all([getHasFreeCheckin(), getCards()]);
    setLoading(false);
  };

  const getHasFreeCheckin = async () => {
    try {
      const { data } = await apiNode.get(`namu-pass/${gym.providerId}/has-free-checkin`);
      setHasFreeCheckin(data?.hasFreeCheckin);
    } catch (e) {
      console.log(errorControl(e));
    }
  };

  const getCards = async () => {
    try {
      const { data } = await apiNode.get(`namu-pass/credit-card?rand=${new Date().getTime()}`, {
        headers: {
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: '0',
        },
      });

      if (data?.data?.length) {
        setHasCards(true);
        setSelectedCard(data.data.find(c => c.default));
      }
    } catch (e) {
      console.log('CREDIT ', e.message);
    }
  };

  const checkHasToken = async () => {
    try {
      const { data } = await apiNode.get(`namu-pass/token/${gym.providerId}`);
      return data?.token || false;
    } catch (e) {
      console.log(e);
    }
    return false;
  };

  const handleReserve = async () => {
    setLoading(true);
    const hasToken = await checkHasToken();

    if (hasToken) {
      if (Platform.OS === 'ios') {
        Alert.alert(
          'Check-in não realizado',
          'Você já possui uma reserva em aberto nessa academia. Seu token: ' + hasToken,
          [
            {
              text: 'OK',
            },
          ],
          { cancelable: false }
        );
      } else {
        setAlert('Você já possui uma reserva em aberto nessa academia.', 'Seu token', {
          titleStyle: {
            marginTop: 40,
          },
          hideClose: true,
          topTag: (
            <View
              style={{
                backgroundColor: 'white',
                borderRadius: 110,
                ...commons.flexCenter,
                height: 110,
                width: 110,
              }}>
              <CustomIcon name={'close_circle'} color={colors.darkRed} size={85} />
            </View>
          ),

          additionalComponent: (
            <View
              style={{
                borderColor: colors.darkRed,
                borderWidth: 1,
                borderRadius: 60,
                paddingVertical: 15,
                paddingHorizontal: 25,
                marginTop: 10,
              }}>
              <Text
                style={{
                  ...textStyles.bold20White,
                  color: colors.darkRed,
                  letterSpacing: 8,
                  textAlign: 'center',
                }}>
                {hasToken}
              </Text>
            </View>
          ),
        });
      }
      setLoading(false);
      return;
    }
    setLoading(false);
    onClose();
    navigation.navigate('CheckinConfirmation', { gymId: gym.providerId });
  };

  const renderData = () => {
    return (
      <>
        <View style={{ ...commons.flexRowBetween, marginHorizontal: 24, paddingBottom: 20 }}>
          <View style={commons.flexRow}>
            <CustomIcon name={'weight'} color={colors.aquaMarineTwo} size={20} />
            <Text style={{ ...styles.title, marginLeft: 10 }}>{translate(pt.book_daily)}</Text>
          </View>
          <TouchableOpacity onPress={onClose} hitSlop={commons.hitSlop} style={{ marginRight: 10 }}>
            <CustomIcon
              name={'chevron_right'}
              size={18}
              color={colors.aquaMarineTwo}
              style={{ transform: [{ rotateZ: '90deg' }] }}
            />
          </TouchableOpacity>
        </View>

        <ScrollView style={{ flex: 1 }} contentContainerStyle={{ marginHorizontal: 24 }}>
          <View style={{ ...commons.flexRow, marginTop: 10 }}>
            <Image source={{ uri: gym?.images?.logo }} style={styles.logo} />
            <View>
              <Text style={{ ...textStyles.bold18blackTwo }}>{gym.title}</Text>
              <Text
                style={{ ...textStyles.regular12, color: colors.charcoalGreyTwo, width: 200 }}
                numberOfLines={1}>
                {gym?.address?.address}
              </Text>
            </View>
          </View>

          {hasFreeCheckin ? (
            <LinearGradient
              useAngle
              angle={90}
              colors={[colors.aquaMarineLight2, colors.aquaMarineTitle]}
              style={styles.firstDaypass}>
              <CustomIcon
                name={'sun_filled'}
                size={24}
                color={colors.white}
                style={{ marginRight: 8 }}
              />
              <Text style={textStyles.regular16White}>
                Primeira diária <Text style={{ fontFamily: fonts.BOLD }}>Grátis</Text>
              </Text>
            </LinearGradient>
          ) : (
            <View style={{ ...commons.flexRow, ...styles.dailyPrice }}>
              <CustomIcon name={'sun'} size={24} color={colors.aquaMarineTwo} />
              <Text style={{ ...textStyles.medium16, color: colors.aquaMarineTwo, marginLeft: 8 }}>
                {translate(pt.daily_a)}:{' '}
                <Text style={{ color: colors.grayText, fontFamily: fonts.BOLD }}>
                  R${gym.daily_price}
                </Text>
              </Text>
            </View>
          )}

          {!hasFreeCheckin && (
            <>
              <View style={styles.hr} />
              <View>
                <Text style={{ ...styles.title, marginBottom: 20 }}>
                  {translate(pt.payment_method)}
                </Text>
                {!selectedCard ? (
                  <TouchableOpacity
                    onPress={() =>
                      hasCards ? setOpenedModal('switch') : setOpenedModal('add_card')
                    }
                    style={styles.creditCardButton}>
                    <Icon
                      name={hasCards ? 'credit-card' : 'plus'}
                      size={20}
                      color={colors.aquaMarineTwo}
                    />
                    <Text style={styles.creditCardText}>
                      {translate(hasCards ? pt.select_saved_card : pt.add_credit_card)}
                    </Text>
                  </TouchableOpacity>
                ) : (
                  <View style={{ ...commons.flexRow }}>
                    <View style={{ ...styles.creditCardButton, flex: 1 }}>
                      <Image
                        source={{ uri: cardFlags[selectedCard?.brand] || '' }}
                        style={{ width: 20, height: 18, resizeMode: 'contain' }}
                      />
                      <Text style={styles.creditCardText}>•••• {selectedCard?.lastDigits}</Text>
                    </View>
                    <TouchableOpacity
                      onPress={() => setOpenedModal('switch')}
                      hitSlop={commons.hitSlop}
                      style={{ marginHorizontal: 20 }}>
                      <Text style={{ ...textStyles.bold16, color: colors.black2 }}>Trocar</Text>
                    </TouchableOpacity>
                  </View>
                )}
              </View>
            </>
          )}

          <View style={styles.hr} />

          <View>
            <Text style={{ ...styles.title, marginBottom: 20 }}>
              {translate(pt.purchase_summary)}
            </Text>

            <View style={commons.flexRowBetween}>
              <Text style={{ ...textStyles.regular14, flex: 1, marginRight: 40 }}>
                Diária em {gym.title}
              </Text>
              <Text
                style={{
                  ...textStyles.bold14,
                  color: colors.greyLight3,
                  textDecorationLine: hasFreeCheckin ? 'line-through' : 'none',
                }}>
                R${gym.daily_price}
              </Text>
            </View>
          </View>

          <View style={styles.hr} />

          <View style={commons.flexRowBetween}>
            <Text style={textStyles.bold16}>{translate(pt.total)}</Text>
            <Text style={textStyles.bold14grey}>R${hasFreeCheckin ? 0 : gym.daily_price}</Text>
          </View>
        </ScrollView>
        <ButtonShadow
          disabled={!hasFreeCheckin && !selectedCard}
          onPress={handleReserve}
          backgroundColor={colors.aquaMarineTwo}
          containerStyle={styles.buttonOk}
          title={translate(pt.confirm_checkin)}
        />
      </>
    );
  };

  return (
    <>
      {openedModal === 'reserve' && (
        <Modal
          transparent={true}
          style={styles.container}
          coverScreen
          visible={openedModal === 'reserve' && open}
          backdropOpacity={0.2}
          onBackdropPress={onClose}>
          <View style={styles.content}>
            <View style={styles.wrapper}>
              {loading ? (
                <View style={{ marginTop: 100, width: 120, height: 120, alignSelf: 'center' }}>
                  <AnimatedLottieView
                    source={require('app/lotties/loading.json')}
                    autoPlay
                    loop={true}
                    style={{ width: 120, transform: [{ scale: 1.3 }] }}
                  />
                </View>
              ) : (
                renderData()
              )}
            </View>
            <Image
              source={backgroundCustomSearch}
              style={{
                position: 'absolute',
                opacity: 0.7,
                bottom: 0,
                width: '100%',
                transform: [{ scale: 1.2 }],
                resizeMode: 'contain',
                zIndex: -1,
              }}
            />
          </View>
        </Modal>
      )}
      {openedModal === 'switch' && (
        <SwitchCreditCardModal
          currentCard={selectedCard}
          onSelectCard={item => setSelectedCard(item)}
          open={openedModal === 'switch'}
          openAddCard={() => setOpenedModal('add_card')}
          onClose={() => setOpenedModal('reserve')}
        />
      )}
      {openedModal === 'add_card' && (
        <AddCreditCardModal
          open={openedModal === 'add_card'}
          onClose={() => (getCards(), setOpenedModal('reserve'))}
        />
      )}
    </>
  );
};

export default ReserveDayPassModal;

const styles = StyleSheet.create({
  container: {
    margin: 0,
  },
  wrapper: {
    paddingTop: 38,
    // paddingHorizontal: 24,
    flex: 1,
  },
  firstDaypass: {
    marginTop: 28,
    borderRadius: 50,
    paddingVertical: 10,
    paddingHorizontal: 14,
    flexDirection: 'row',
    alignItems: 'center',
  },
  logo: {
    width: 70,
    height: 70,
    borderRadius: 10,
    resizeMode: 'cover',
    marginRight: 14,
  },
  content: {
    backgroundColor: colors.white,
    marginTop: 'auto',
    height: '90%',
    borderTopLeftRadius: 47,
    borderTopRightRadius: 47,
  },
  textStyle: {
    fontFamily: fonts.REGULAR,
    color: colors.greyLight4,
    fontSize: 14,
    paddingHorizontal: 20,
    textAlign: 'center',
  },
  textHeader: {
    fontFamily: fonts.BOLD,
    fontSize: 20,
    color: colors.aquaMarineTwo,
  },
  title: {
    ...textStyles.medium16,
    color: colors.charcoalGreyTwo,
  },
  dailyPrice: {
    paddingHorizontal: 14,
    paddingVertical: 11,
    marginTop: 28,
    borderColor: colors.aquaMarineTwo,
    borderRadius: 10,
    borderWidth: 1,
  },
  hr: {
    width: '100%',
    height: 1,
    backgroundColor: colors.greyLight,
    marginVertical: 30,
  },
  creditCardButton: {
    padding: 16,
    backgroundColor: colors.greyF5,
    borderRadius: 15,
    flexDirection: 'row',
    alignItems: 'center',
  },
  creditCardText: {
    ...textStyles.bold14,
    marginLeft: 8,
    color: colors.charcoalGreyTwo,
  },
  buttonOk: {
    // width: '85%',
    marginHorizontal: 28,
    height: 50,
    marginTop: 20,
    zIndex: 1,
    marginBottom: 20,
  },
});
