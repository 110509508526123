import ButtonBase from 'app/components/ButtonBase';
import ButtonLink from 'app/components/ButtonLink';
import pt from 'app/i18n/src/locale/pt';
import colors from 'app/styles/colors';
import fonts from 'app/styles/fonts';
import React, { useEffect, useRef } from 'react';
import { Image, StyleSheet, Text, View, Modal as ReactNativeModal } from 'react-native';
import { translate } from 'app/i18n/src/locale';
import AnimatedLottieView from 'lottie-react-native';

const CongratsModal = ({ open, onClose, name, type, onPress, color = colors.aquaMarineTitle }) => {
  const animRef = useRef();

  useEffect(() => {
    if (open) {
      animRef.current.play();
    }
  }, [open]);

  return (
    <ReactNativeModal
      style={styles.container}
      coverScreen
      visible={open}
      onBackdropPress={onClose}>
      <View style={styles.content}>
        <AnimatedLottieView
          ref={animRef}
          source={require('app/lotties/congratulations.json')}
          loop={false}
          style={{
            position: 'absolute',
            width: '100%',
            alignSelf: 'center',
            zIndex: 1,
          }}
        />
        <View style={styles.iconContainer}>
          <Image
            source={require('app/images/trophy.png')}
            style={{ width: 200, height: 200, resizeMode: 'contain' }}
          />
        </View>
        <Text style={[styles.title, { color }]}>{translate(pt.congratulations)}</Text>
        <Text style={[styles.description]}>
          {translate(pt.you_have_completed, { type: translate(pt[type]) })}
        </Text>
        <Text style={[styles.description, { color, fontFamily: fonts.BOLD }]}>{name}</Text>

        <Text style={[styles.description, { marginTop: 10 }]}>
          {translate(pt.good_job_lets_choose_next, {
            type: translate(pt[type]),
            article: type === 'class' ? 'a' : 'o',
          })}
        </Text>
        <ButtonBase
          onPress={() => (onPress && onPress(), onClose())}
          fill
          backgroundColor={color}
          textStyle={{ fontSize: 16, color: colors.white }}
          style={[styles.buttonOk]}
          title={translate(pt.lets_go)}
        />

        <ButtonLink
          containerStyle={styles.secondaryButton}
          onPress={onClose}
          title={translate(pt.not_yet)}
        />
      </View>
    </ReactNativeModal>
  );
};

export default CongratsModal;

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 28,
  },
  content: {
    backgroundColor: colors.white,
    borderRadius: 20,
    // marginHorizontal: 10,
    overflow: 'hidden',
    maxHeight: '90%',
    alignItems: 'center',
    width: '100%',
    padding: 20,
  },
  iconContainer: {
    marginVertical: 15,
    zIndex: -1,
  },
  contentInverted: {
    backgroundColor: colors.aquaMarineTwo,
  },
  textInverted: {
    color: colors.white,
  },
  buttonInverted: {
    color: colors.aquaMarineTwo,
  },
  textStyle: {
    fontFamily: fonts.REGULAR,
    color: colors.greyLight4,
    fontSize: 14,
    paddingHorizontal: 20,
    textAlign: 'center',
  },
  title: {
    fontFamily: fonts.BOLD,
    fontSize: 22,
    color: colors.aquaMarineTitle,
    marginBottom: 10,
    textAlign: 'center',
  },
  description: {
    fontFamily: fonts.REGULAR,
    fontSize: 14,
    color: colors.darkPro2,
    textAlign: 'center',
  },
  buttonOk: {
    // width: 220,
    height: 50,
    zIndex: 1,
  },
  topContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 25,
  },
  continueContainer: {
    width: '100%',
    paddingHorizontal: 28,
  },
  secondaryButton: {
    marginTop: 20,
    marginBottom: 10,
  },
});
