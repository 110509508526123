import React from 'react';

import { StyleSheet, Image } from 'react-native';
import plus2 from '../images/plus2.png';
import colors from '../styles/colors';
import ShadowView from '@liuyunjs/react-native-simple-shadow-view';
import commons from '../styles/commons';
import TouchableScale from 'react-native-touchable-scale';

const More = ({ style, onPress }) => (
  <TouchableScale onPress={onPress}>
    <ShadowView style={[commons.shadow, styles.icon, style]}>
      <Image source={plus2} style={{ width: 18, height: 18 }} />
    </ShadowView>
  </TouchableScale>
);

export default More;

const styles = StyleSheet.create({
  icon: {
    zIndex: 10,
    right: 0,
    top: 0,
    alignItems: 'center',
    justifyContent: 'center',
    width: 35,
    height: 35,
    borderRadius: 3.1,
    backgroundColor: colors.aquaMarineTwo,
  },
});
