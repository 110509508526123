import React from 'react';

import { View, Text, StyleSheet, TouchableOpacity, Image } from 'react-native';
import colors from '../../../styles/colors';
import textStyles from '../../../styles/textStyles';
import commons from '../../../styles/commons';
import { debounce } from 'lodash';
import PERFORMANCE from 'app/configs/performance';

const HeaderTitle = ({ title, buttonIcon, style, onPress }) => (
  <View style={[styles.container, style]}>
    <Text numberOfLines={1} style={[textStyles.bold16grey, { color: colors.slateGrey }]}>
      {title.toUpperCase()}
    </Text>
    {buttonIcon && (
      <TouchableOpacity
        onPress={debounce(() => {
          onPress();
        }, PERFORMANCE.ON_PRESS_DEBOUNCE_TIME)}
        hitSlop={commons.hitSlop}>
        <Image source={buttonIcon} style={{width: 18, height: 18}} />
      </TouchableOpacity>
    )}
  </View>
);

export default HeaderTitle;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    paddingHorizontal: 15,
    height: 40,
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: colors.paleGrey,
  },
});
