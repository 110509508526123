import React, { useState, useRef, useEffect } from 'react';

import { StyleSheet, Text, TouchableWithoutFeedback, View, Image } from 'react-native';
import colors from '../../../../styles/colors';
import textStyles from '../../../../styles/textStyles';
import chevronTop from '../../../../images/chevronTop.png';
// import {  } from 'react-native-animatable';
import pinAccordion from '../../../../images/pinAccordion.png';
import CheckBox from 'app/components/CheckBox';
import commons from 'app/styles/commons';

const ClassesAccordion = props => {
  const chevron = useRef();
  const [open, setOpen] = useState(false);

  const Animations = {
    rotateDown: {
      0: { rotate: '0deg' },
      1: { rotate: '180deg' },
    },
    rotateUp: {
      0: { rotate: '180deg' },
      1: { rotate: '0deg' },
    },
  };

  return (
    <View transition="minHeight" style={[styles.container]}>
      <TouchableWithoutFeedback
        onPress={() => {
          setOpen(!open);
        }}>
        <View style={styles.header}>
          <View style={styles.titleContainer}>
            <CheckBox
              checked={props.counter.every(c => c.completed)}
              style={{ marginHorizontal: 10 }}
              size={20}
              pressable={false}
              uncheckedIconStyle={{ tintColor: '#C7C7C7' }}
            />
            <Text style={textStyles.bold14}>
              {props.title}{' '}
              <Text style={[textStyles.bold14, { color: props.color }]}>
                ({props.counter.filter(c => c.completed).length}/{props.counter.length})
              </Text>
            </Text>
          </View>
          <Image
            animation={open ? Animations.rotateUp : Animations.rotateDown}
            duration={500}
            ref={chevron}
            style={{ tintColor: props.color, marginHorizontal: 10, width: 20, height: 20 }}
            source={chevronTop}
          />
        </View>
      </TouchableWithoutFeedback>
      {open && (
        <View animation="fadeIn" style={styles.content}>
          {props.children}
        </View>
      )}
    </View>
  );
};

export default ClassesAccordion;

const styles = StyleSheet.create({
  content: {
    borderTopWidth: 1,
    paddingTop: 8,
    borderTopColor: colors.veryLightPinkThree,
  },
  // title: { marginLeft: 8 },
  titleContainer: {
    width: '80%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  row: {
    flexDirection: 'row',
    marginBottom: 8,
    paddingHorizontal: 15,
    alignItems: 'center',
  },
  header: {
    flexDirection: 'row',
    paddingVertical: 7,
    minHeight: 50,
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: colors.white,
  },
  container: {
    marginBottom: 8,
    borderRadius: 4,
  },
});
