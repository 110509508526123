import React from 'react';

import { View, Text, TouchableOpacity, Image, StyleSheet } from 'react-native';

import textStyles from '../../../../styles/textStyles';
import { translate } from '../../../../i18n/src/locale';
import pt from '../../../../i18n/src/locale/pt';
import closeGrey from '../../../../images/closeGrey.png';
import colors from '../../../../styles/colors';
import clockMenu from '../../../../images/clockMenu.png';
import calendarGrayMenu from '../../../../images/calendarGrayMenu.png';
import moment from 'moment';
import { useNavigation } from '@react-navigation/native';

const MenuItem = ({ title, icon, onPress }) => (
  <TouchableOpacity style={styles.menu_item} onPress={onPress}>
    <Text style={textStyles.semi14}>{title}</Text>
    <Image source={icon} style={{ width: 24, height: 24 }} />
  </TouchableOpacity>
);

const BottomSheetChangeDate = ({ item, onClose }) => {
  const navigation = useNavigation();

  const getHour = () => {
    return moment(item.start_date).format('HH:mm');
  };

  const getDate = () => {
    return moment(item.start_date).format('DD MMMM YYYY');
  };

  return (
    <View style={styles.bottomSheet}>
      <View style={styles.header}>
        <Text style={textStyles.subtitle}>{translate(pt.reschedule_activity)}</Text>
        <TouchableOpacity onPress={onClose}>
          <Image source={closeGrey} style={{ width: 24, height: 24 }} />
        </TouchableOpacity>
      </View>
      <MenuItem
        title={getHour()}
        icon={clockMenu}
        onPress={() => {
          navigation.navigate('ChangeHour', { item });
          onClose();
        }}
      />
      <MenuItem
        title={getDate()}
        icon={calendarGrayMenu}
        onPress={() => {
          navigation.navigate('ChangeDate', { item });
          onClose();
        }}
      />
    </View>
  );
};

export default BottomSheetChangeDate;

const styles = StyleSheet.create({
  bottomSheet: { height: 160, backgroundColor: colors.white },
  header: {
    height: 60,
    paddingHorizontal: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: colors.paleGrey,
  },
  menu_item: {
    height: 53,
    borderTopWidth: StyleSheet.hairlineWidth,
    borderColor: colors.greyBlue22,
    paddingHorizontal: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: colors.white,
  },
});
