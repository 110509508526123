import React from 'react';
import Svg, { Path } from 'react-native-svg';

const LogoSvg = ({ width = 200, style, color }) => {
  return (
    <Svg
      width={width}
      height={Math.ceil(width / 2.5)}
      style={style}
      viewBox="0 0 1080 345"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <Path
        d="M172.403 86.1836C124.858 86.1836 86.1816 124.86 86.1816 172.405C86.1816 219.949 124.858 258.626 172.403 258.626C219.947 258.626 258.624 219.949 258.624 172.405C258.624 124.86 219.947 86.1836 172.403 86.1836ZM163.758 240.815C130.894 236.716 105.11 209.367 103.545 175.758L163.758 226.283V240.815ZM163.758 203.778L105.632 155.041C107.495 147.887 110.55 141.18 114.425 135.07L163.684 176.429V203.778H163.758ZM163.758 153.923L125.529 121.879C135.813 112.341 149.078 105.857 163.758 103.994V153.923ZM181.047 103.994C195.728 105.857 208.918 112.266 219.277 121.879L181.047 153.923V103.994V103.994ZM181.047 176.429L230.38 135.07C234.33 141.106 237.311 147.887 239.174 155.041L181.047 203.778V176.429ZM181.047 240.815V226.283L241.26 175.758C239.695 209.293 213.911 236.642 181.047 240.815Z"
        fill={color ? color : '#3ED1D2'}
      />
      <Path
        d="M271.219 242.157C272.635 242.977 274.125 243.424 275.69 243.424C278.596 243.424 281.428 241.934 283.068 239.251C295.215 219.13 301.698 196.028 301.698 172.405C301.698 101.088 243.72 43.1108 172.404 43.1108C148.78 43.1108 125.679 49.5197 105.558 61.7411C101.459 64.2004 100.192 69.4914 102.652 73.59C105.111 77.6887 110.402 78.9555 114.501 76.4963C131.939 65.9143 151.985 60.3998 172.478 60.3998C234.256 60.3998 284.558 110.702 284.558 172.48C284.558 192.973 278.969 213.019 268.387 230.457C265.853 234.407 267.195 239.698 271.219 242.157Z"
        fill={color ? color : '#3ED1D2'}
      />
      <Path
        d="M77.3171 97.5846C73.3675 94.9019 68.0019 95.9452 65.3192 99.8948C50.7875 121.357 43.1118 146.396 43.1118 172.404C43.1118 243.721 101.089 301.698 172.406 301.698C198.414 301.698 223.453 294.023 244.915 279.491C248.865 276.808 249.908 271.443 247.225 267.493C244.543 263.543 239.177 262.5 235.228 265.183C216.672 277.777 194.912 284.409 172.406 284.409C110.628 284.409 60.3262 234.182 60.3262 172.329C60.3262 149.824 66.9586 128.064 79.5527 109.508C82.31 105.633 81.2667 100.267 77.3171 97.5846Z"
        fill={color ? color : '#3ED1D2'}
      />
      <Path
        d="M44.8245 275.468C48.6996 272.636 49.5193 267.27 46.762 263.395C1.75121 201.394 8.45812 116.961 62.7096 62.7096C116.961 8.45812 201.394 1.67669 263.395 46.762C267.27 49.5938 272.636 48.6996 275.468 44.8245C278.299 40.9494 277.405 35.5839 273.53 32.7521C240.666 8.90526 200.201 -2.57102 159.513 0.484349C118.377 3.53972 79.7004 21.2758 50.4881 50.4881C21.2758 79.7004 3.53972 118.377 0.48435 159.513C-2.57102 200.201 8.90524 240.666 32.752 273.53C34.466 275.84 37.0743 277.107 39.757 277.107C41.5455 277.107 43.3341 276.585 44.8245 275.468Z"
        fill={color ? color : '#3ED1D2'}
      />
      <Path
        d="M312.058 71.2801C309.226 67.405 303.861 66.5853 299.986 69.3425C296.11 72.1744 295.291 77.5399 298.048 81.415C343.059 143.417 336.352 227.849 282.1 282.101C227.849 336.352 143.416 343.134 81.4148 298.048C77.5397 295.216 72.1742 296.111 69.3424 299.986C66.5106 303.861 67.4048 309.226 71.2799 312.058C100.641 333.371 136.188 344.848 172.48 344.848C176.727 344.848 181.05 344.699 185.297 344.4C226.433 341.345 265.11 323.609 294.322 294.397C323.534 265.184 341.27 226.508 344.326 185.372C347.307 144.609 335.83 104.069 312.058 71.2801Z"
        fill={color ? color : '#3ED1D2'}
      />
      <Path
        d="M734.964 223.584L678.105 110.088C676.465 106.66 672.739 104.499 668.641 104.499H663.722C659.623 104.499 655.897 106.734 654.258 110.088L597.324 223.584H623.779L634.882 200.557H697.182L708.435 223.584H734.964ZM643.974 181.926L665.883 136.617L688.091 181.926H643.974Z"
        fill={color ? color : '#444242'}
      />
      <Path
        d="M1056.9 106.586L1056.82 189.976C1056.82 194.745 1052.95 198.62 1048.18 198.62H1008.16C1003.39 198.62 999.517 194.745 999.517 189.976L999.442 106.586H976.49V192.807C976.49 208.681 989.382 221.573 1005.25 221.573H1051.23C1067.11 221.573 1080 208.681 1080 192.807V106.586H1056.9Z"
        fill={color ? color : '#444242'}
      />
      <Path
        d="M845.854 198.695H853.604C857.553 198.695 860.46 196.459 862.621 193.255L895.708 143.698V221.498H918.661V118.063C918.661 111.728 913.519 106.586 907.185 106.586H898.54C895.559 106.586 892.802 108.3 891.237 110.611L849.803 171.494L808.295 110.611C806.73 108.3 803.973 106.586 800.992 106.586H792.347C786.013 106.586 780.871 111.728 780.871 118.063V221.498H803.824V143.698L836.911 193.255C838.998 196.459 841.904 198.695 845.854 198.695Z"
        fill={color ? color : '#444242'}
      />
      <Path
        d="M528.095 186.992L463.634 110.534C461.771 108.224 459.312 106.51 456.331 106.51H447.612C441.278 106.51 436.136 111.651 436.136 117.986V221.421H459.088V141.013L523.549 217.472C525.412 219.782 527.871 221.496 530.852 221.496H539.497C545.831 221.496 550.973 216.354 550.973 210.019V106.584H528.02V186.992H528.095Z"
        fill={color ? color : '#444242'}
      />
    </Svg>
  );
};

export default LogoSvg;
