import colors from 'app/styles/colors';
import fonts from 'app/styles/fonts';
import React from 'react';

import { View, Text, Image } from 'react-native';
import logo from '../../../../images/mutableLogo.png';

const EmptyData = ({ title, style }) => {
  const text = title ? title : 'Ainda não há atendimentos\nagendados';

  return (
    <View style={[{ flex: 0.5, alignItems: 'center', justifyContent: 'center' }, style]}>
      <Image
        source={logo}
        style={{ height: 46, width: 46, tintColor: colors.grayWeak, marginTop: 10 }}
      />
      <Text
        style={{
          textAlign: 'center',
          color: colors.grayWeak,
          fontFamily: fonts.LIGHT,
          marginTop: 10,
        }}>
        {text}
      </Text>
    </View>
  );
};
export default EmptyData;
