/* eslint-disable react-native/no-inline-styles */
import React from 'react';

import { Text, View, StyleSheet, TouchableHighlight, ActivityIndicator } from 'react-native';
import textStyles from '../styles/textStyles';
import colors from '../styles/colors';
import commons from '../styles/commons';

const adjust = (color, amount) => {
  try {
    return (
      '#' +
      color
        .replace(/^#/, '')
        .replace(/../g, color_ =>
          ('0' + Math.min(255, Math.max(0, parseInt(color_, 16) + amount)).toString(16)).substr(-2)
        )
    );
  } catch (e) {
    return '#FF0000';
  }
};

const LoginButtonBase = ({
  title,
  style,
  textStyle,
  onPress,
  backgroundColor,
  disabled,
  fill,
  loading,
  square,
}) => {
  const color = backgroundColor || colors.aquaMarineTwo;
  const darken = adjust(color, -50);
  return (
    <TouchableHighlight
      disabled={disabled}
      hitSlop={commons.hitSlop}
      underlayColor={darken}
      style={{
        ...styles.buttonEnter,
        backgroundColor: disabled ? colors.brownGrey : color,
        opacity: disabled ? 0.5 : 1,
        alignSelf: 'center',
        borderRadius: 4,
        ...style,
      }}
      onPress={onPress}>
      <View>
        {!loading && <Text style={[textStyles.bold14White, textStyle]}>{title}</Text>}
        {loading && <ActivityIndicator color={colors.white} animating />}
      </View>
    </TouchableHighlight>
  );
};

const styles = StyleSheet.create({
  buttonEnter: {
    zIndex: 1,
    width: '100%',
    justifyContent: 'center',
    height: 50,
    // marginBottom: 20,
  },
});
export default LoginButtonBase;
