import React from 'react';

import { View, StyleSheet, Image, Text } from 'react-native';
import textStyles from '../../../../styles/textStyles';
import api from 'app/services/api';

const RowTeachers = ({ image, name, subCategoryName }) => (
  <View style={styles.container}>
    {image ? (
      <Image
        source={{
          uri: image,
          headers: {
            Authorization: api.defaults.headers.Authorization,
          },
        }}
        style={{
          backgroundColor: 'white',
          width: 40,
          height: 40,
          borderRadius: 20,
          marginRight: 14,
        }}
      />
    ) : (
      <Image
        style={{
          backgroundColor: 'white',
          width: 40,
          height: 40,
          borderRadius: 20,
          marginRight: 14,
        }}
      />
    )}
    <View>
      <Text style={textStyles.semi17}>{name}</Text>
      {subCategoryName && (
        <Text style={textStyles.regular14grey}>Especialista em {subCategoryName}</Text>
      )}
    </View>
  </View>
);

export default RowTeachers;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 12,
  },
});
