import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import Slider from '@react-native-community/slider';
import colors from 'app/styles/colors';

const ProgressBarWithTimer = ({
  currentTime,
  duration,
  onSlideCapture,
  onSlideStart,
  onSlideComplete,
}) => {
  const getMinutesFromSeconds = time => {
    const minutes = time >= 60 ? Math.floor(time / 60) : 0;
    const seconds = Math.floor(time - minutes * 60);

    return `${minutes >= 10 ? minutes : `0${minutes}`}:${seconds >= 10 ? seconds : `0${seconds}`}`;
  };

  const handleOnSlide = time => {
    onSlideCapture({ seekTime: time });
  };

  const position = getMinutesFromSeconds(currentTime);
  const fullDuration = getMinutesFromSeconds(duration);

  return (
    <View style={styles.wrapper}>
      <Text style={[styles.time]}>{position}</Text>
      <View style={styles.containerSlider}>
        <Slider
          value={Math.floor(currentTime)}
          minimumValue={0}
          maximumValue={duration}
          step={1}
          onValueChange={handleOnSlide}
          onSlidingStart={onSlideStart}
          onSlidingComplete={onSlideComplete}
          minimumTrackTintColor={colors.aquaMarine}
          maximumTrackTintColor={colors.aquaMarineFour}
          thumbTintColor={colors.aquaMarine}
        />
      </View>
      <Text style={[styles.time]}>{fullDuration}</Text>
    </View>
  );
};
export default ProgressBarWithTimer;

const styles = StyleSheet.create({
  containerSlider: { flex: 1, marginHorizontal: 18 },
  wrapper: {
    height: 50,
    marginHorizontal: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  time: {
    fontSize: 12,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.white,
  },
  timeRight: {
    fontSize: 16,
    color: '#FFFFFF',
    textAlign: 'right',
    paddingRight: 10,
  },
});
