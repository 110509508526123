import colors from 'app/styles/colors';
import fonts from 'app/styles/fonts';
import React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { Image, Text } from 'react-native';
import ShadowView from '@liuyunjs/react-native-simple-shadow-view';

const ImageButton = ({ image, title, onPress, label, style, imageStyle }) => {
  return (
    <TouchableOpacity onPress={onPress}>
      <ShadowView style={[styles.container, style]}>
        <Image source={image} style={imageStyle} />
        {!!label && <Text style={styles.label}>{label}</Text>}
        {!!title && <Text style={styles.text}>{title}</Text>}
      </ShadowView>
    </TouchableOpacity>
  );
};

export default ImageButton;

const styles = StyleSheet.create({
  container: {
    borderColor: colors.aquaMarineTwo,
    borderRadius: 15,
    backgroundColor: colors.white,
    height: 94,
    width: 102,
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: 15,
    shadowColor: colors.black,
    shadowOpacity: 0.08,
    shadowRadius: 8,
    shadowOffset: { width: 0, height: 8 },
  },
  content: {},
  text: {
    fontFamily: fonts.BOLD,
    color: colors.charcoalGreyTwo,
    fontSize: 10,
    textAlign: 'center'
  },
  label: {
    fontFamily: fonts.BOLD,
    color: '#045555',
    fontSize: 16,
    position: 'absolute',
  },
});
