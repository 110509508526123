/* eslint-disable react-native/no-inline-styles */
import CapsuleClockCard from 'app/components/CapsuleClockCard';
import CapsuleLevelNew from 'app/components/CapsuleLevelNew';
import FavoriteButtonShow from 'app/components/FavoriteButtonShow';
import MaskLevelPaymentNew from 'app/components/MaskLevelPaymentNew';
import Minus from 'app/components/Minus';
import Plus from 'app/components/Plus';
import TagCard from 'app/components/TagCard';
import api from 'app/services/api';
import colors from 'app/styles/colors';
import fonts from 'app/styles/fonts';
import React from 'react';
import { ImageBackground, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import ShadowView from '@liuyunjs/react-native-simple-shadow-view';
const CardProgram = ({
  type,
  style,
  textStyle,
  size,
  title,
  image,
  difficulty,
  plus,
  minus,
  gradientColor = 'none',
  duration,
  paymentLevel,
  onPress,
  onPressAdd,
  favorite,
  tag,
  id,
}) => {
  const getGradientColor = () => {
    if (gradientColor === 'red') {
      return colors.orangeyRed40;
    }
    if (gradientColor === 'green') {
      return '#99CDCE';
    }
    if (gradientColor === 'orange') {
      return colors.pastelOrange40;
    }
    return colors.transparent;
  };
  const getBackgroundColor = () => {
    if (gradientColor === 'red') {
      return colors.darkRed;
    }
    if (gradientColor === 'green') {
      return colors.aquaMarineTwo;
    }
    if (gradientColor === 'orange') {
      return colors.brightOrange;
    }
    return colors.transparent;
  };
  return (
    <TouchableOpacity onPress={onPress} style={{ position: 'relative' }} disabled={!onPress}>
      {plus && <Plus onPress={onPressAdd} style={{ marginTop: 30 }} />}
      {minus && <Minus onPress={onPressAdd} style={{ marginTop: 30 }} />}
      <ShadowView style={[styles.card, style, size ? { width: size, height: size } : {}]}>
        <ImageBackground
          source={{
            uri: image,
            headers: {
              Authorization: api.defaults.headers.Authorization,
            },
          }}
          imageStyle={{
            borderRadius: 15,
          }}
          style={[styles.image, size ? { width: size, height: size } : {}]}>
          {paymentLevel === 1 && (
            <MaskLevelPaymentNew
              containerStyles={[
                { left: 0, height: size ? size : 224, width: size ? size : 250, borderRadius: 15 },
              ]}
            />
          )}
          <View flexDirection="row" alignItems="center" style={{zIndex: 2}}>
            {!!difficulty && (
              <CapsuleLevelNew
                difficulty={difficulty}
                textColor={colors.white}
                style={{
                  alignSelf: 'flex-start',
                  backgroundColor: getBackgroundColor() + 'cc',
                }}
              />
            )}
            <View style={{ position: 'absolute', zIndex: 2, top: 10, right: 10 }}>
              <FavoriteButtonShow
                isFavorited={favorite}
                propType="trailId" // TODO MARCELO: VALIDAR SE REALMENTE É CLASS
                itemId={id}
              />
            </View>
          </View>
          <LinearGradient
            colors={[colors.transparent, colors.transparent, getGradientColor()]}
            style={[styles.linear, size ? { width: size, height: size } : {}]}
          />
          <View style={{ marginTop: 'auto' }}>
            {type && (
              <View style={[styles.typeWrapper, { backgroundColor: getBackgroundColor() + '99' }]}>
                <Text style={styles.types} numberOfLines={1}>
                  {type}
                </Text>
              </View>
            )}
            <Text numberOfLines={2} style={[styles.title, textStyle]}>
              {title}
            </Text>
            <View style={styles.capsuleWrapper}>
              <CapsuleClockCard
                style={{
                  borderWidth: 0,
                }}
                textColor={colors.white}
                duration={duration}
                isWeek={true}
                gradientColors={['#FFFFFF66', '#FFFFFF1A']}
              />
              {!!tag && (
                <TagCard
                  style={{ borderWidth: 0, marginLeft: 6 }}
                  textColor={colors.white}
                  title={tag}
                  gradientColors={['#FFFFFF66', '#FFFFFF1A']}
                />
              )}
            </View>
          </View>
        </ImageBackground>
      </ShadowView>
    </TouchableOpacity>
  );
};

export default CardProgram;

const styles = StyleSheet.create({
  linear: {
    position: 'absolute',
    width: 250,
    height: 224,
    borderBottomLeftRadius: 15,
    borderBottomRightRadius: 15,
    bottom: 0,
    left: 0
  },
  title: {
    marginTop: 8,
    fontFamily: fonts.BOLD,
    fontSize: 16,
    lineHeight: 17,
    color: colors.white,
    minHeight: 34,
  },
  typeWrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
    paddingHorizontal: 8,
    paddingVertical: 4,
    alignSelf: 'flex-start',
    borderRadius: 4,
    marginTop: 'auto',
  },
  card: {
    width: 250,
    height: 224,
    borderRadius: 18,
    backgroundColor: colors.transparent,
    shadowColor: colors.black,
    shadowOpacity: 0.1,
    shadowRadius: 5,
    shadowOffset: { width: 0, height: 5 },
  },
  capsuleWrapper: {
    flexDirection: 'row',
  },
  maskContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  types: {
    color: colors.white,
    fontSize: 9,
    fontFamily: fonts.BOLD,
  },
  image: {
    overflow: 'hidden',
    paddingHorizontal: 11,
    paddingVertical: 9,
    justifyContent: 'flex-start',
    height: 224,
    width: 250,
  },
});
