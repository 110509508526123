import React from 'react';
import { Text, TouchableOpacity, StyleSheet } from 'react-native';
import colors from 'app/styles/colors';
import textStyles from 'app/styles/textStyles';

const ButtonAlerts = ({ id, title, action, isSelected }) => {
  return (
    <TouchableOpacity
      onPress={action}
      style={[
        styles.container,
        { backgroundColor: isSelected ? colors.cornflowerBlue : colors.white },
      ]}>
      <Text style={[textStyles.semi16, { color: isSelected ? colors.white : colors.blackTwo }]}>
        {title}
      </Text>
    </TouchableOpacity>
  );
};

export default ButtonAlerts;

const styles = StyleSheet.create({
  container: {
    height: 45,
    marginBottom: 6,
    borderRadius: 5,
    paddingHorizontal: 15,
    justifyContent: 'center',
    marginHorizontal: 20,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: colors.veryLightPinkFour,
  },
});
