import api from './api';
import QueryString from 'qs';
import moment from 'moment';

const updateWaterIntakes = async (meta_key, meta_value) => {
  await api.get('user-metadata', QueryString.stringify({ meta_key, meta_value }));
};

const getWaterIntakes = async (date = moment()) => {
  const { data } = await api.get(`water-intake?time_stamp=${moment(date).format('YYYY-MM-DD')}`);
  return data;
};

export { getWaterIntakes, updateWaterIntakes };
