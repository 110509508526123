import React from 'react';

import { View, StatusBar, Platform } from 'react-native';

const STATUSBAR_HEIGHT = Platform.OS === 'ios' ? 0 : StatusBar.currentHeight;

const StatusBarHolder = ({ backgroundColor, ...props }) => (
  <View
    style={{
      height: STATUSBAR_HEIGHT,
    }}>
    <StatusBar barStyle="dark-content" translucent backgroundColor={backgroundColor} {...props} />
  </View>
);

export default StatusBarHolder;
