import fonts from 'app/styles/fonts';
import React, { PureComponent } from 'react';
import { View, Animated, TouchableOpacity, Dimensions, Text, StyleSheet } from 'react-native';

const COLORS = {
  backgroundColor: '#D2D2D2',
  shadowColor: '#E8E8E8',
  backgroundColorItem: '#fff',
  colorText: '#000',
};

const { width } = Dimensions.get('window');
export default class AnimatedTab extends PureComponent {
  state = {
    active: 0,
    xTabOne: 0,
    xTabTwo: 0,
    translateX: new Animated.Value(0),
    translateXTabOne: new Animated.Value(0),
    translateXTabTwo: new Animated.Value(width),
    translateY: -1000,
    isLayoutFinished: false,
  };

  componentDidUpdate() {
    const { active } = this.props;
    this.handleSlide(`xTab${active}`, active);
    this.setState({ active: active });
  }

  handleSlide = (type, index) => {
    const { active, translateX, translateXTabOne, translateXTabTwo } = this.state;
    Animated.timing(translateX, {
      toValue: this.state[type] || 0,
      delay: 0,
      duration: 200,
      useNativeDriver: false,
    }).start();
    if (active === 0) {
      Animated.parallel([
        Animated.spring(translateXTabOne, {
          toValue: 0,
          duration: 100,
          useNativeDriver: false,
        }).start(),
        Animated.spring(translateXTabTwo, {
          toValue: width,
          duration: 100,
          useNativeDriver: false,
        }).start(),
      ]);
    } else {
      Animated.parallel([
        Animated.spring(translateXTabOne, {
          toValue: -width,
          useNativeDriver: false,
          duration: 100,
        }).start(),
        Animated.spring(translateXTabTwo, {
          toValue: 0,
          duration: 100,
          useNativeDriver: false,
        }).start(),
      ]);
    }
  };

  render() {
    const {
      tabs = [],
      style,
      styleTitle,
      backgroundColor,
      styleTab,
      activeTextColor,
      textColor,
      onChangeTab,
    } = this.props;

    const { translateX } = this.state;
    return (
      <View
        style={[
          styles.container,
          style,
          {
            backgroundColor: backgroundColor || COLORS.backgroundColor,
            borderColor: textColor,
          },
        ]}>
        <Animated.View
          style={[
            styles.animatedView,
            {
              backgroundColor: tabs[this.state.active].color,
              width: `${100 / tabs.length}%`,
              transform: [
                {
                  translateX,
                },
              ],
            },
          ]}
        />
        {tabs.map((item, index) => (
          <TouchableOpacity
            key={item.title}
            style={[styles.tab, styleTab]}
            onLayout={event =>
              this.setState({
                [`xTab${index}`]: event.nativeEvent.layout.x,
              })
            }
            onPress={() => {
              if (this.state.active === index) {
                return;
              }
              this.setState({ active: index }, () => this.handleSlide(`xTab${index}`, index));
              onChangeTab(index);
            }}>
            <Text
              style={[
                styles.textTitle,
                styleTitle,
                {
                  color: index === this.state.active ? activeTextColor : textColor,
                },
              ]}>
              {item.title}
            </Text>
          </TouchableOpacity>
        ))}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 100,
    height: 41,
    borderWidth: 1,
    paddingRight: 6,
  },
  tab: {
    flex: 1,
    height: 41,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 100,
  },
  textTitle: {
    fontSize: 12,
    fontFamily: fonts.BOLD,
  },
  animatedView: {
    position: 'absolute',
    height: 32,
    top: 3,
    left: 0,
    marginHorizontal: 2,
    borderRadius: 100,
  },
});
