import React from 'react';

import { Text, TouchableOpacity, Image, StyleSheet, View } from 'react-native';
import colors from '../styles/colors';
import textStyles from '../styles/textStyles';
import ShadowView from '@liuyunjs/react-native-simple-shadow-view';

const ButtonShadow = ({
  onPress,
  title,
  fill,
  textStyle,
  disabled,
  backgroundColor = colors.aquaMarineTwo,
  color = colors.white,
  icon,
  style,
  containerStyle,
}) => (
  <TouchableOpacity
    activeOpacity={0.5}
    disabled={disabled}
    onPress={onPress}
    style={[styles.container, { width: fill ? '100%' : 'auto' }, containerStyle]}>
    <ShadowView
      style={[
        styles.shadowContainer,
        { backgroundColor: disabled ? colors.palePurple : backgroundColor },
      ]}>
      <View
        style={[
          styles.content,
          { backgroundColor: disabled ? colors.palePurple : backgroundColor },
          style,
        ]}>
        {icon}
        <Text style={[styles.title, { color }, textStyle]}>{title}</Text>
      </View>
    </ShadowView>
  </TouchableOpacity>
);

export default ButtonShadow;

const styles = StyleSheet.create({
  title: { ...textStyles.bold14 },
  content: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    paddingHorizontal: 22,
    width: '100%',
    borderRadius: 10,
  },
  shadowContainer: {
    borderRadius: 12,
    flex: 1,
    shadowColor: colors.black,
    shadowOpacity: 0.1,
    shadowRadius: 5,
    shadowOffset: { width: 0, height: 5 },
  },
});
