import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  ActivityIndicator,
  TouchableOpacity,
  FlatList,
  StyleSheet,
  Text,
  View,
  ScrollView,
  Switch,
  Animated,
  Image,
  StatusBar,
  TextInput,
} from 'react-native';

import pt from 'app/i18n/src/locale/pt';
import fonts from 'app/styles/fonts';
import colors from 'app/styles/colors';

import chevronLeft from 'app/images/chevronLeft.png';
import nightWhite from 'app/images/nightWhite.png';

import HeaderTitleNew from 'app/screens/core/home/components/HeaderTitleNew';
import ButtonShadow from 'app/components/ButtonShadow';

import api from 'app/services/api';
import { useSafeArea } from 'react-native-safe-area-context';
import { Platform } from 'react-native';
import FilterOption from 'app/screens/core/gym/components/FilterOption';
import ButtonLink from 'app/components/ButtonLink';
import { translate } from 'app/i18n/src/locale';
import LinearGradient from 'react-native-linear-gradient';
import { useNavigation } from '@react-navigation/native';

const Mock_Modality_Options = [
  { title: 'Musculação', img: nightWhite, selected: false },
  { title: 'Funcional', img: nightWhite, selected: false },
  { title: 'Pilates', img: nightWhite, selected: false },
  { title: 'Natação', img: nightWhite, selected: false },
  { title: 'Yoga', img: nightWhite, selected: false },
  { title: 'Lutas', img: nightWhite, selected: false },
  { title: 'Dança', img: nightWhite, selected: false },
  { title: 'Outros', img: nightWhite, selected: false },
];

const Mock_Comodidades_Options = [
  { title: 'Acessibilidade', img: nightWhite, selected: false },
  { title: 'Ar condicionado', img: nightWhite, selected: false },
  { title: 'Armários', img: nightWhite, selected: false },
  { title: 'Bebedouro', img: nightWhite, selected: false },
  { title: 'Espaço para crianças', img: nightWhite, selected: false },
  { title: 'Estacionamento', img: nightWhite, selected: false },
  { title: 'Bicicletário', img: nightWhite, selected: false },
  { title: 'Lanchonete', img: nightWhite, selected: false },
  { title: 'Chuveiro', img: nightWhite, selected: false },
  { title: 'Vestiários', img: nightWhite, selected: false },
  { title: 'Manobrista', img: nightWhite, selected: false },
  { title: 'Wi-fi', img: nightWhite, selected: false },
];

const ShowGymsFilterScreen = ({ route }) => {

  const navigation = useNavigation();
  
  const { onChangeFilters, selectedFilters } = route?.params;


  const [modalityFilterOptions, setModalityFilterOptions] = useState([]);
  const [comodidadesFilterOptions, setComodidadesFilterOptions] = useState([]);
  const [isSwitchFirstCheckIn, setIsSwitchFirstCheckIn] = useState(false);
  const [ interspaceWidth, setInterspaceWidth] = useState();

  const safeAreaInsets = useSafeArea();

  const modalityOptionSelected = modalityFilterOptions.filter(modality => modality.selected);
  const comodidadesOptionSelected = comodidadesFilterOptions.filter(modality => modality.selected);

  useEffect(() => {
    getFilters();
  }, []);

  const getFilters = async () => {
    try {
      const response = await api.get('gyms/get-filters');
      const { data } = response;

      if (data) {
        const { amenities, modalities } = data;

        const selectedModalities = !selectedFilters.modalities ? false : selectedFilters.modalities.split(',');
        const selectedComodidades = !selectedFilters.amenities ? false : selectedFilters.amenities.split(',');

        setIsSwitchFirstCheckIn(!!selectedFilters?.free_checkin);
        setModalityFilterOptions(modalities.map(item => ({ ...item, selected: selectedModalities ? (selectedModalities.includes(item.id.toString())) : false })));
        setComodidadesFilterOptions(amenities.map(item => ({ ...item, selected: selectedComodidades ? (selectedComodidades.includes(item.id.toString())) : false })));
      }
    } catch (error) {
      console.warn(error);
    }
  };

  const handleSelectModality = name => {
    setModalityFilterOptions(state =>
      state.map(item => {
        if (item.title === name) {
          return { ...item, selected: !item.selected };
        }
        return item;
      })
    );
  };

  const handleSelectComodidades = name => {
    setComodidadesFilterOptions(state =>
      state.map(item => {
        if (item.title === name) {
          return { ...item, selected: !item.selected };
        }
        return item;
      })
    );
  };

  const handleSubmit = () => {
    const modalities = modalityOptionSelected.map(filtered => filtered.id).join(',');
    const amenities = comodidadesOptionSelected.map(filtered => filtered.id).join(',');
    onChangeFilters({
      modalities,
      amenities,
      free_checkin: isSwitchFirstCheckIn ? 1 : 0,
    });
    navigation.goBack();
  };

  const handleChangeSwitchFirstCheckin = () => setIsSwitchFirstCheckIn(state => !state);

  const handlePressClear = () => {
    onChangeFilters({
      modalities: '',
      amenities: '',
      free_checkin: null,
    });
    navigation.goBack();
  }

  return (
    <View style={{ marginBottom: safeAreaInsets.bottom, flex: 1 }}>
      <StatusBar backgroundColor={colors.aquaMarineTwo} barStyle="light-content" />
      <View style={{...styles.headerContainer, paddingTop: safeAreaInsets.top + 15}}>
        <TouchableOpacity style={{ marginRight: 15 }} onPress={() => navigation.goBack()}>
          <Image source={chevronLeft} style={{ width: 28, height: 28, tintColor: colors.white }} />
        </TouchableOpacity>
        <Text style={styles.headerTextStyle}>Filtro</Text>
      </View>

      <View style={{flex: 1}}>
        <ScrollView contentContainerStyle={{paddingBottom: 140 + safeAreaInsets.bottom}}>

        <HeaderTitleNew
            textStyle={{ textTransform: 'uppercase', fontFamily: fonts.BOLD }}
            style={{ marginTop: 15, marginBottom: 10 }}
            title={'Aceita primeiro check-in grátis'}
          />

          <View style={{...styles.modalityContainer, justifyContent: 'flex-start'}}>
            <Switch
              trackColor={{ false: colors.grayLight, true: colors.grayLight }}
              thumbColor={isSwitchFirstCheckIn ? colors.aquaMarineTwo2 : '#f4f3f4'}
              ios_backgroundColor={colors.blueyGrey}
              onValueChange={handleChangeSwitchFirstCheckin}
              style={{ transform: [{ scaleX: 1.5 }, { scaleY: 1.5 }], marginLeft: 10 }}
              value={isSwitchFirstCheckIn}
            />
          </View>

          <HeaderTitleNew
            textStyle={{ textTransform: 'uppercase', fontFamily: fonts.BOLD }}
            style={{ marginTop: 15, marginBottom: 10 }}
            title={'Modalidade Principal'}
          />

          <View style={styles.modalityContainer}>
            {modalityFilterOptions.map((option, i) => {
              const isSelected = modalityOptionSelected.find(
                selected => selected.title === option.title
              );
              return <>
                <FilterOption
              title={option.title}
              img={option.imageUrl}
              isSelected={!!isSelected}
              onPress={() => handleSelectModality(option.title)}
              key={option.id}
            /><View onLayout={e => {
              const {width} = e.nativeEvent.layout;
              if(i == 0){
                setInterspaceWidth(width)
              }
            }} style={{flex: 1, maxWidth: interspaceWidth !== null ? interspaceWidth : 10}}/></>
            })}
          </View>

          <HeaderTitleNew
            textStyle={{ textTransform: 'uppercase', fontFamily: fonts.BOLD }}
            style={{ marginTop: 15, marginBottom: 10 }}
            title={'Comodidades'}
          />

          <View style={styles.modalityContainer}>
            {comodidadesFilterOptions.map((option, i) => {
              const isSelected = comodidadesOptionSelected.find(
                selected => selected.title === option.title
              );
              return (<>
                <FilterOption
                  title={option.title}
                  img={option.imageUrl}
                  isSelected={!!isSelected}
                  onPress={() => handleSelectComodidades(option.title)}
                  key={option.id}
                /><View onLayout={e => {
                  const {width} = e.nativeEvent.layout;
                  if(i == 0){
                    setInterspaceWidth(width)
                  }
                }} style={{flex: 1, maxWidth: interspaceWidth !== null ? interspaceWidth : 10}}/></>
              );
            })}
          </View>

        </ScrollView>

      </View>

      <LinearGradient useAngle angle={0} 
        colors={['#f2f2f2', '#f2f2f255']}
        // start={{x: 1, y: 0.5}}
        // end={{x: 0, y: 0.5}}
        locations={[0.75, 1]}
        style={{alignItems: 'center', paddingBottom: 20 + safeAreaInsets.bottom, position: 'absolute', bottom: 0, left: 0, width: '100%'}}>
        <ButtonShadow
          onPress={handleSubmit}
          backgroundColor={colors.aquaMarineTwo}
          containerStyle={styles.buttonOk}
          title={translate(pt.filter)}
        />
        <ButtonLink title={translate(pt.clean_filter)} underline onPress={handlePressClear} />
      </LinearGradient>
    </View>
  );
};

export default ShowGymsFilterScreen;

const styles = StyleSheet.create({
  headerContainer: {
    // flex: 1,
    backgroundColor: colors.aquaMarineTwo,
    paddingVertical: 15,
    paddingHorizontal: 15,
    alignItems: 'center',
    flexDirection: 'row',
    // paddingTop: 35,
  },
  headerTextStyle: {
    fontFamily: fonts.BOLD,
    color: colors.white,
    fontSize: 18,
    marginBottom: 3,
  },
  modalityContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    // justifyContent:'space-evenly',
    paddingHorizontal: 15,
    paddingVertical: 15,
  },
  buttonOk: {
    marginHorizontal: 28,
    height: 50,
    marginTop: 30,
    zIndex: 1,
    marginBottom: 20,
  },
});
