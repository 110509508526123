/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import { Image, ImageBackground, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { debounce } from 'lodash';
import ShadowView from '@liuyunjs/react-native-simple-shadow-view';
import colors from 'app/styles/colors';
import fonts from 'app/styles/fonts';
import textStyles from 'app/styles/textStyles';
import opaqueBook from 'app/images/opaqueBook.png';
import commons from 'app/styles/commons';
import Spacer from 'app/components/Spacer';
import api from 'app/services/api';
import { sendActionEvent } from 'app/services/utils';
import { useSelector } from 'react-redux';
import PERFORMANCE from 'app/configs/performance';
import { useNavigation } from '@react-navigation/native';

const CardBlog = ({ post, categoryId }) => {

  const navigation = useNavigation();

  const { profile } = useSelector(state => state.user);

  const getCategoryPost = () => {
    if (post.type === 'text') {
      return 'Texto';
    }
    if (post.type === 'video') {
      return 'Vídeo';
    }
    if (post.type === 'audio') {
      return 'Áudio';
    }
  };

  return (
    <ShadowView style={styles.card}>
      <TouchableOpacity
        onPress={debounce(() => {
          navigation.navigate('BlogPost', {
            id: post.id || post.wp_post_id,
            blogPost: post,
            categoryId: categoryId,
          });
        }, PERFORMANCE.ON_PRESS_DEBOUNCE_TIME)}>
        <ImageBackground
          source={{
            uri: post.image,
            headers: {
              Authorization: api.defaults.headers.Authorization,
            },
          }}
          imageStyle={{
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
          }}
          style={{
            paddingHorizontal: 14,
            paddingVertical: 20,
            justifyContent: 'space-between',
            height: 200,
            borderRadius: 10,
          }}>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: 75 }}>
            <Image source={opaqueBook} />
          </View>
        </ImageBackground>
        <View
          style={{
            margin: 16,
          }}>
          <Text style={textStyles.bold18Left}>{post.title}</Text>
          {/* <HTML html={post.excerpt} style={styles.content} /> */}
          <Spacer value={10} />
          <View style={commons.flexRowBetween}>
            <View style={styles.capsule}>
              {post.type && <Text style={textStyles.regular14}>{getCategoryPost()}</Text>}
            </View>
            <TouchableOpacity
              onPress={debounce(() => {
                navigation.navigate('BlogPost', {
                  id: post.id || post.wp_post_id,
                  blogPost: post,
                  categoryId: categoryId,
                });
              }, PERFORMANCE.ON_PRESS_DEBOUNCE_TIME)}>
              <Text style={textStyles.createAccount}>Continue Lendo</Text>
            </TouchableOpacity>
          </View>
        </View>
      </TouchableOpacity>
    </ShadowView>
  );
};

export default CardBlog;

const styles = StyleSheet.create({
  capsule: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 59,
    height: 21,
    borderRadius: 18.2,
    backgroundColor: colors.whiteTwo,
  },
  content: {
    marginTop: 8,
    fontSize: 14,
    fontFamily: fonts.REGULAR,
    color: colors.blackTwo,
  },
  card: {
    marginHorizontal: 20,
    borderRadius: 10,
    marginTop: 16,
    marginBottom: 20,
    backgroundColor: colors.white,
    shadowColor: colors.black,
    shadowOffset: {
      width: 1,
      height: -2,
    },
    shadowRadius: 8,
    shadowOpacity: 0.15,
  },
});
