import LottieView from 'lottie-react-native';
import React, { useState, useEffect, useCallback } from 'react';
import { Image, ImageBackground, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
// import { Actions } from 'react-native-router-flux';
import { SafeAreaView } from 'react-native-safe-area-context';
// import TouchableScale from 'react-native-touchable-scale';
import { translate } from '../../../i18n/src/locale';
import pt from '../../../i18n/src/locale/pt';
import backgroundOrange from '../../../images/backgroundOrange.png';
import closeWhite from '../../../images/closeWhite.png';
import play from 'app/images/play2.png';
import pause from 'app/images/pause2.png';
import ripplelight from '../../../lotties/ripplelight.json';
import colors from '../../../styles/colors';
import commons from '../../../styles/commons';
import textStyles from '../../../styles/textStyles';
import Video from '@lnormanha/react-native-web-video/build/Video';
import api from 'app/services/api';
import QueryString from 'qs';
import { showAlert, errorControl } from 'app/services/utils';
import { useNavigation } from '@react-navigation/native';

const MeditationPlayScreen = ({ route }) => {

  const { idClass, onFinish } = route?.params;

  const navigation = useNavigation();

  const [isEndSong, setIsEndSong] = useState(false);
  const [dataExercise, setDataExercise] = useState({});
  const [cTime, setCtime] = useState(0);
  const [stateSong, setStateSong] = useState({
    play: true,
    currentTime: 0,
    showControls: false,
  });

  useEffect(() => {
    requestExercise();
  }, [requestExercise]);

  const requestExercise = useCallback(async () => {
    try {
      const { data } = await api.get(`class/playlist?class_id=${idClass}`);
      setDataExercise(data[0]);
    } catch (error) {
      showAlert(errorControl(error));
    }
  }, [idClass]);

  const requestUserScheduleExercise = async body => {
    try {
      await api.patch('user-schedule/exercise', QueryString.stringify(body));
    } catch (error) {
      showAlert(errorControl(error));
    }
  };

  const handlePlayPause = async () => {
    if (stateSong.play) {
      setStateSong({ ...stateSong, play: false });
      const body = {
        completion_flag: 0,
        duration: Math.floor(cTime),
        trail_id: dataExercise?.trail_id,
        class_id: dataExercise?.id,
      };
      await requestUserScheduleExercise(body);
      return;
    }

    setStateSong({ ...stateSong, play: true });
  };

  function onLoadEnd(data) {
    setStateSong(prevState => ({
      ...prevState,
      currentTime: data.currentTime,
    }));
  }

  function onProgress(data) {
    setCtime(data.currentTime);
    setStateSong(prevState => ({
      ...prevState,
      currentTime: data.currentTime,
    }));
  }

  function getMinutesFromSeconds(time) {
    const minutes = time >= 60 ? Math.floor(time / 60) : 0;
    const seconds = Math.floor(time - minutes * 60);

    return `${minutes >= 10 ? minutes : `0${minutes}`}:${seconds >= 10 ? seconds : `0${seconds}`}`;
  }

  function onEnd() {
    setStateSong({ ...stateSong, play: false });
    const body = {
      completion_flag: 1,
      duration: Math.floor(cTime),
      trail_id: dataExercise?.trail_id,
      class_id: dataExercise?.id,
    };

    requestUserScheduleExercise(body);
    setIsEndSong(true);
    onFinish && onFinish();
    navigation.goBack();
  }

  return (
    <LinearGradient
      colors={[colors.wheat, colors.dustyOrange]}
      useAngle={true}
      angle={45}
      style={styles.container}>
      <ImageBackground style={{ flex: 1 }} source={backgroundOrange}>
        <SafeAreaView style={styles.safe}>
          <View style={[commons.flexRowBetween, { padding: 10 }]}>
            {/* <TouchableScale style={styles.button}>
              <Image source={info} />
            </TouchableScale> */}
            <TouchableOpacity style={[styles.button, { backgroundColor: 'transparent' }]} />
            <Text style={textStyles.bold16White}>Meditação</Text>
            <TouchableOpacity style={styles.button} onPress={navigation.goBack}>
              <Image source={closeWhite} style={{width: 18, height: 18}} />
            </TouchableOpacity>
          </View>

          <Video
            source={{
              uri: dataExercise.media?.path,
            }}
            muted={false}
            repeat={false}
            audioOnly={true}
            volume={1.0}
            onLoad={onLoadEnd}
            onProgress={onProgress}
            onEnd={onEnd}
            paused={!stateSong.play}
            playInBackground={true}
            ignoreSilentSwitch={'ignore'}
          />

          {!isEndSong && (
            <View style={{ flex: 1 }}>
              <View style={{ flex: 1 }}>
                <View style={{ flex: 1, justifyContent: 'center' }}>
                  <View style={styles.holderPlay}>
                    <LottieView source={ripplelight} loop autoPlay />
                    <TouchableOpacity
                      onPress={() => {
                        handlePlayPause();
                      }}
                      style={{position:'absolute',}}
                      hitSlop={commons.hitSlop}>
                      {stateSong.play ? (
                        <Image source={pause} style={[styles.icon, { marginLeft: 0 }]} />
                      ) : (
                        <Image source={play} style={styles.icon} />
                      )}
                    </TouchableOpacity>
                  </View>
                </View>
                {/* <Text style={styles.time}>{getMinutesFromSeconds(stateSong.currentTime)}</Text> */}
                <View style={{ flex: 1, justifyContent: 'center' }}>
                  <View
                    animation="fadeInUp"
                    delay={500}
                    duration={1500}
                    style={[styles.alignCenter]}>
                    <Text style={[textStyles.bold24White, { textAlign: 'center' }]}>
                      {dataExercise?.name}
                    </Text>
                    <Text style={textStyles.semiWhite16}>{dataExercise?.class_name}</Text>
                  </View>
                </View>
              </View>
            </View>
          )}

          {/* {isEndSong && (
            <>
              <View animation="fadeInUp" style={{ padding: 10 }}>
                <Text style={styles.time}>{translate(pt.meditation_end)}</Text>
                <Spacer value={100} />
                <Text style={textStyles.regular24White}>
                  {translate(pt.meditation_end_content)}
                </Text>

                <TouchableScale onPress={Actions.result_meditation({ meditation: true })} style={styles.button_path}>
                  <Image source={path} />
                </TouchableScale>
              </View>
              <Spacer value={50} />
            </>
          )} */}

          {!isEndSong && (
            <View style={styles.base}>
              <View style={styles.alignCenter}>
                <Image
                  source={{
                    uri: dataExercise?.sub_teacher?.user_picture,
                    headers: {
                      Authorization: api.defaults.headers.Authorization,
                    },
                  }}
                  style={styles.userImage}
                />
                <Text style={textStyles.regular14White}>Narrado por:</Text>
                <Text style={textStyles.semi16white}>{dataExercise?.sub_teacher?.name}</Text>
              </View>
              <View style={styles.alignCenter}>
                <Image
                  source={{
                    uri: dataExercise?.main_teacher?.user_picture,
                    headers: {
                      Authorization: api.defaults.headers.Authorization,
                    },
                  }}
                  style={styles.userImage}
                />
                <Text style={textStyles.regular14White}>{translate(pt.author)}</Text>
                <Text style={textStyles.semi16white}>{dataExercise?.main_teacher?.name}</Text>
              </View>
            </View>
          )}
        </SafeAreaView>
      </ImageBackground>
    </LinearGradient>
  );
};

export default MeditationPlayScreen;

const styles = StyleSheet.create({
  button_path: {
    marginTop: 30,
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    width: 53,
    height: 53,
    borderRadius: 4,
    backgroundColor: colors.white,
  },
  alignCenter: { alignItems: 'center' },
  base: {
    padding: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  userImage: {
    width: 38,
    marginBottom: 4,
    height: 38,
    borderRadius: 19,
    backgroundColor: colors.veryLightPinkFour,
  },
  safe: { flex: 1, justifyContent: 'space-between' },
  button: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 34,
    height: 34,
    borderRadius: 34 / 2,
    backgroundColor: '#1d1d1d33',
  },
  time: {
    alignSelf: 'center',
    fontSize: 24,
    fontWeight: '800',
    letterSpacing: 1.13,
    color: colors.whiteTwo,
  },
  icon: { 
    width: 36, 
    height: 36, 
    resizeMode: 'contain', 
    marginLeft: 8,
    // tintColor: colors.white,
  },
  holderPlay: {
    width: 250,
    height: 250,
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    flex: 1,
    backgroundColor: colors.wheat,
  },
});
