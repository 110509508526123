import React from 'react';

import { View, StyleSheet, Text, TouchableOpacity } from 'react-native';
import colors from '../../../../styles/colors';
import textStyles from '../../../../styles/textStyles';
import { translate } from '../../../../i18n/src/locale';
import pt from '../../../../i18n/src/locale/pt';
import commons from '../../../../styles/commons';

const CardIngredientChange = ({ name = 'Item', grams = 0, unit = 'g', onPress }) => (
  <View style={styles.container}>
    <Text style={styles.title}>{`${grams + unit}`}</Text>
    <View style={styles.line} />
    <Text style={[textStyles.semi14, commons.flex]}>{name}</Text>
    {/* <TouchableOpacity onPress={onPress}>
      <Text style={textStyles.createAccount}>{translate(pt.to_change)}</Text>
    </TouchableOpacity> */}
  </View>
);

export default CardIngredientChange;

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 14,
    height: 42,
    flexDirection: 'row',
    marginBottom: 4,
    borderRadius: 4,
    alignItems: 'center',
    backgroundColor: colors.white,
  },
  title: {
    width: 65,
    fontSize: 16,
    fontWeight: 'bold',
    color: colors.greyBlue,
  },
  line: {
    marginRight: 14,
    opacity: 0.2,
    backgroundColor: colors.brownGrey,
    width: StyleSheet.hairlineWidth,
    height: 28,
  },
});
