import React from 'react';

import { View, StyleSheet, Text } from 'react-native';
import textStyles from '../styles/textStyles';
import { translate } from '../i18n/src/locale';
import pt from '../i18n/src/locale/pt';

const ChallengeChip = ({ text }) => (
  <View style={styles.container}>
    <Text style={textStyles.semi14white}>{text || translate(pt.challenge)}</Text>
  </View>
);

export default ChallengeChip;

const styles = StyleSheet.create({
  container: {
    alignSelf: 'baseline',
    paddingHorizontal: 12,
    minWidth: 79,
    height: 29,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 18.2,
    backgroundColor: 'rgba(0,0,0,0.18)',
  },
});
