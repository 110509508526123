import React, { useState, useEffect } from 'react';
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  Dimensions,
  StyleSheet,
  ActivityIndicator,
} from 'react-native';
import ButtonBase from '../../../components/ButtonBase';
import colors from '../../../styles/colors';
import closeImage from '../../../images/closeGrey.png';
import commons from '../../../styles/commons';
import { translate } from 'app/i18n/src/locale';

import pt from 'app/i18n/src/locale/pt';
import fonts from 'app/styles/fonts';

import arrowLeft from '../../../images/chevronLeft.png';
import arrowRight from '../../../images/chevronRight.png';
import moment from 'moment';

import apiNode from 'app/services/apiNode';
import { useSelector } from 'react-redux';
import { showAlert } from 'app/services/utils';

const { height } = Dimensions.get('window');

const BottomSheetInterested = ({ onClose, userProId }) => {
  const [current, setCurrent] = useState(moment());
  const [selectedPeriod, setSelectedPeriod] = useState('');
  const [loading, setLoading] = useState(false);

  const { profile } = useSelector(state => state.user);

  const zeroHour = { millisecond: 0, hour: 0, minute: 0, second: 0 };

  const showInterest = async () => {
    setLoading(true);
    let period;

    switch (selectedPeriod) {
      case translate(pt.morning):
        period = 'morning';
        break;
      case translate(pt.afternoon):
        period = 'afternoon';
        break;
      default:
        period = 'night';
        break;
    }
    try {
      await apiNode.post('/user-interested-user-pro', {
        userProId,
        userId: profile.user_id,
        period,
        date: current.format('YYYY-MM-DD'),
      });

      showAlert(translate(pt.alert_interest));
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
    onClose();
  };
  const PeriodButton = ({ period }) => (
    <TouchableOpacity
      onPress={() => {
        if (period === selectedPeriod) {
          setSelectedPeriod('');
        } else {
          setSelectedPeriod(period);
        }
      }}
      key={period}
      style={[
        styles.hourItem,
        {
          backgroundColor: selectedPeriod === period ? colors.aquaMarineTwo : colors.white,
        },
      ]}>
      <Text
        style={{
          fontFamily: fonts.BOLD,
          padding: 8,
          color: selectedPeriod === period ? colors.white : colors.aquaMarineTwo,
        }}>
        {period}
      </Text>
    </TouchableOpacity>
  );
  return (
    <View contentContainerStyle={styles.containerBottomSheet}>
      <View style={styles.topContainer}>
        <Text style={styles.title}>{translate(pt.period_interest)}</Text>
        <TouchableOpacity onPress={onClose} hitSlop={commons.hitSlop}>
          <Image source={closeImage} />
        </TouchableOpacity>
      </View>
      <View style={styles.packageContent}>
        <View style={styles.contentContainer}>
          <View style={styles.titleAndHourContainer}>
            {moment(current).subtract(1, 'day').set(zeroHour) >= moment().set(zeroHour) ? (
              <TouchableOpacity
                hitSlop={commons.hitSlop}
                onPress={() => {
                  setCurrent(moment(current).subtract(1, 'days'));
                }}>
                <Image source={arrowLeft} />
              </TouchableOpacity>
            ) : (
              <View />
            )}
            <Text style={{ fontFamily: fonts.REGULAR, color: colors.greyBlue }}>
              {current ? moment(current).format('ddd - DD  MMMM') : '-'}
            </Text>
            <TouchableOpacity
              hitSlop={commons.hitSlop}
              onPress={() => {
                setCurrent(moment(current).add(1, 'days'));
              }}>
              <Image source={arrowRight} />
            </TouchableOpacity>
          </View>
          <View style={styles.hourItemsContainer}>
            <PeriodButton period={translate(pt.morning)} />
            <PeriodButton period={translate(pt.afternoon)} />
            <PeriodButton period={translate(pt.night)} />
          </View>
        </View>
        {loading ? (
          <ActivityIndicator
            size={'small'}
            color={colors.aquaMarineTwo}
            style={{ marginTop: 20 }}
          />
        ) : (
          <ButtonBase
            disabled={selectedPeriod === ''}
            title={translate(pt.confirm)}
            style={styles.buttonStyle}
            onPress={() => {
              setLoading(true);
              showInterest();
              //onClose();
            }}
          />
        )}
      </View>
    </View>
  );
};

export default BottomSheetInterested;

const styles = StyleSheet.create({
  containerBottomSheet: {
    borderTopEndRadius: 47,
    borderTopStartRadius: 47,
    paddingBottom: 10,
  },
  topContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    marginTop: 14,
  },
  title: {
    textAlign: 'center',
    flex: 0.8,
    fontFamily: fonts.BOLD,
    fontSize: 18,
  },

  buttonStyle: { width: '100%', height: height / 8 / 2, backgroundColor: colors.aquaMarine },
  hourItem: {
    borderColor: colors.aquaMarineTwo,
    borderRadius: 3,
    borderWidth: 0.8,
    margin: 4,
    width: '30%',
    alignItems: 'center',
  },

  hourItemsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginStart: 5,
    marginBottom: 12,
    marginTop: 5,
  },
  titleAndHourContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: 8,
    marginTop: 8,
  },
  contentContainer: {
    height: 'auto',
    borderRadius: 10,

    borderWidth: 0.5,
    borderColor: ' rgba(191, 243, 243, 1)',
  },
  packageContent: {
    width: '80%',
    alignSelf: 'center',
    marginTop: height / 30,
  },
});
