import React, { useState } from 'react';
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  Dimensions,
  StyleSheet,
  ScrollView,
} from 'react-native';
import ButtonBase from '../../../../components/ButtonBase';
import colors from '../../../../styles/colors';
import closeImage from '../../../../images/closeGrey.png';
import commons from '../../../../styles/commons';
import { translate } from 'app/i18n/src/locale';

import pt from 'app/i18n/src/locale/pt';
import fonts from 'app/styles/fonts';
import SchedulerBox from 'app/components/SchedulerBox';
import apiNode from 'app/services/apiNode';

const { height } = Dimensions.get('window');

const BottomSheetUpdateTime = ({ onClose, doUpdate, doCancel, userProId, sessionId }) => {
  const [selected, setSeleted] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirm = async calendarId => {
    setIsLoading(true);
    try {
      await apiNode.put(`sessions/${sessionId}/update/${calendarId}`);
      doUpdate();
    } catch (e) {
      console.log(e);
    }
    setIsLoading(false);
  };

  return (
    <View style={styles.containerBottomSheet}>
      <View style={styles.topContainer}>
        <Text style={styles.title}>{translate(pt.chooseHour)}</Text>
        <TouchableOpacity onPress={onClose} hitSlop={commons.hitSlop}>
          <Image source={closeImage} />
        </TouchableOpacity>
      </View>

      <ScrollView contentContainerStyle={styles.contentContainer}>
        <SchedulerBox userProId={userProId} onSelected={setSeleted} />
      </ScrollView>

      <View style={styles.packageContent}>
        <ButtonBase
          title={translate(pt.reschedule_session_confirm)}
          style={styles.buttonStyle}
          onPress={() => {
            if (!isLoading) {
              handleConfirm(selected);
            }
          }}
          disabled={selected ? false : true}
          loading={isLoading}
        />
        <ButtonBase
          title={translate(pt.want_cancel_session)}
          style={styles.buttonStyleCancel}
          onPress={() => {
            doCancel();
          }}
          textStyle={{ color: colors.blackAlpha03 }}
          disabled={isLoading}
        />
      </View>
    </View>
  );
};

export default BottomSheetUpdateTime;

const styles = StyleSheet.create({
  containerBottomSheet: {
    borderTopEndRadius: 47,
    borderTopStartRadius: 47,
    paddingHorizontal: 30,
    flex: 1,
  },
  topContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    marginTop: 14,
    marginBottom: 15,
  },
  title: {
    textAlign: 'center',
    flex: 0.8,
    fontFamily: fonts.BOLD,
    fontSize: 18,
  },

  buttonStyle: { width: '100%', height: height / 8 / 2, backgroundColor: colors.aquaMarine },
  buttonStyleCancel: {
    width: '100%',
    height: height / 8 / 2,
    backgroundColor: colors.white,
    borderWidth: 1,
    borderColor: colors.blackAlpha03,
  },

  contentContainer: {
    height: 'auto',
    borderRadius: 10,
    borderWidth: 0.5,
    borderColor: ' rgba(191, 243, 243, 1)',
  },
  packageContent: {
    width: '100%',
    alignSelf: 'center',
    marginTop: 0,
    marginBottom: 26,
    justifyContent: 'flex-end',
  },
  textHourStyle: {
    marginStart: 5,
    fontSize: 15,
    fontFamily: fonts.BOLD,
  },
  categoryBlock: {
    backgroundColor: colors.shadowBlue,
    borderRadius: 50,
    padding: 8,
  },
  categoryImageStyle: {
    width: 23.48,
    height: 22,
    resizeMode: 'contain',
    tintColor: colors.aquaMarineTwo,
  },
});
