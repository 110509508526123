import React, { useState } from 'react';

import { View, StyleSheet, Text, TouchableOpacity } from 'react-native';
import colors from '../styles/colors';
import commons from '../styles/commons';
import textStyles from '../styles/textStyles';

const RowSelect = ({ item, onPress, index, selectColor = colors.pastelOrange }) => {
  return (
    <TouchableOpacity
      onPress={() => {
        onPress(index);
      }}
      style={[
        styles.container,
        { backgroundColor: item?.isSelected ? selectColor : colors.white },
      ]}>
      <View style={commons.flexRowBetween}>
        <Text
          style={[textStyles.semi16, { color: item?.isSelected ? colors.white : colors.blackTwo }]}>
          {item?.name}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

export default RowSelect;

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 15,
    paddingVertical: 14,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: colors.veryLightPinkFour,
    backgroundColor: colors.white,
    marginBottom: 15,
  },
});
