import React, { useEffect, useState } from 'react';

import ShadowView from '@liuyunjs/react-native-simple-shadow-view';
import { View, Image, TouchableOpacity, StyleSheet } from 'react-native';
import chevronLeft from '../images/chevronLeft.png';
// import * as Animatable from 'react-native-animatable';
import colors from '../styles/colors';
// import { Actions } from 'react-native-router-flux';
import { useDispatch, useSelector } from 'react-redux';
import profile_image from 'app/images/profile_image.png';
import api from 'app/services/api';
import { translate } from 'app/i18n/src/locale';
import pt from 'app/i18n/src/locale/pt';
import PERFORMANCE from 'app/configs/performance';
import { debounce } from 'lodash';
// import SideMenuModal from 'app/screens/core/home/components/SideMenuModal';
import commons from 'app/styles/commons';
import FavoritesView from 'app/screens/profile/components/FavoritesView';
// import Icon from 'react-native-vector-icons/FontAwesome';
import { setShowFavorites } from 'app/store/modules/favorites/actions';
import NamuLogo from 'app/images/namu_logo.svg';
import logoHorizontal from 'app/images/logoHorizontal.png';
import CustomIcon from 'app/styles/icon';
import Icon from 'react-native-vector-icons/dist/FontAwesome';
import { useSafeArea } from 'react-native-safe-area-context';
import { useNavigation } from '@react-navigation/native';
const HeaderHomeNew = ({ pass, showGoBack, style, ignoreInset, hideFavorites = false }) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const { showFavorites } = useSelector(state => state.favorites);
  const { profile } = useSelector(state => state.user);
  const [sideMenuOpen, setSideMenuOpen] = useState(false);
  const [businessLogo, setBusinessLogo] = useState({
    aspectRatio: undefined,
    logo: logoHorizontal,
  });

  const safeAreaInsets = useSafeArea();

  const getIconFavorite = () => {
    return showFavorites ? 'heart' : 'heart-o';
  };

  useEffect(() => {
    if (profile?.business_logo) {
      Image.getSize(profile?.business_logo, (w, h) =>
        setBusinessLogo({ logo: { uri: profile?.business_logo }, aspectRatio: w / h })
      );
    }
  }, [profile?.business_logo]);

  return (
    <>
      <View 
      // useNativeDriver animation="fadeInDown" 
      style={{
        position: 'fixed',
        width: '100%',
        top: 0,
        left: 0,
        zIndex: 9
      }}>
        <ShadowView style={[styles.container, style]}>
          <View style={styles.header}>
            {showGoBack ?
            <TouchableOpacity style={styles.backButton} onPress={()=>navigation.canGoBack() && navigation.goBack()}>
              <Image source={chevronLeft} style={styles.backIcon} />
            </TouchableOpacity>
            :
            <View style={styles.logoContainer}>
              <Image
                style={[styles.logo, { aspectRatio: businessLogo.aspectRatio }]}
                source={businessLogo.logo}/>
            </View>}

            {(profile?.business_logo) && (
              !pass ? 
                <Image source={NamuLogo} width={28} height={28} style={{color: colors.aquaMarineTwo}} />
              :  <CustomIcon name={'namu_pass'} size={90} style={{color: colors.aquaMarineTwo}} />)
            }

            <View style={[commons.flexRow, styles.rightActions]}>
              {!hideFavorites && <TouchableOpacity onPress={()=>dispatch(setShowFavorites(!showFavorites))} style={{marginRight: 24}}>
                <Icon
                  name={getIconFavorite()}
                  size={23}
                  color={showFavorites ? colors.frenchRose : colors.darkPro}
                />
              </TouchableOpacity>}
              <TouchableOpacity onPress={()=>
              navigation.navigate('Profile', { refresh: Math.random()})
              // setSideMenuOpen(true)
              }>
                {profile && (
                  <Image
                    style={styles.user}
                    source={
                      profile.user_picture
                        ? {
                            uri: profile.user_picture,
                            headers: {
                              Authorization: api.defaults.headers.Authorization,
                            },
                          }
                        : profile_image
                    }
                  />
                )}
              </TouchableOpacity>
            </View>
          </View>
        </ShadowView>
      </View>
      {/* <SideMenuModal open={sideMenuOpen} onClose={()=>setSideMenuOpen(false)}/> */}
      {showFavorites && (
        <FavoritesView
          style={{
            width: '100%',
            height: '100%',
            zIndex: 5,
            position: 'absolute',
            top: ignoreInset ? 0 : safeAreaInsets.top,
          }}
        />
      )}
    </>
  );
};

export default HeaderHomeNew;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    shadowColor: colors.black,
    shadowOpacity: 0.05,
    shadowRadius: 5,
    shadowOffset: { width: 0, height: 5 },
  },
  backIcon: { width: 28, height: 28, tintColor: colors.white },
  backButton: {
    width: 40,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.aquaMarineTwo,
    borderRadius: 4,
  },
  rightActions: {
    width: 100,
    justifyContent: 'flex-end',
  },
  header: {
    width: '100%',
    height: 60,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 19,
    backgroundColor: colors.white,
    justifyContent: 'space-between',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  logoContainer: {
    width: 100,
    height: 35,
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    width: 100,
    maxHeight: 35,
    justifyContent: 'center',
    alignItems: 'center',
    resizeMode: 'contain',
  },
  userInfo: {
    flexDirection: 'row',
  },
  user: {
    width: 28,
    height: 28,
    borderRadius: 28,
    // backgroundColor: colors.charcoalGrey,
  },
  greetingMessageText: {
    fontSize: 14,
  },
  userNameText: {
    fontSize: 24,
    fontWeight: 'bold',
  },
  configButton: {
    color: colors.aquaMarineTwo,
    fontSize: 15,
    fontWeight: 'normal',
    textDecorationLine: 'underline',
  },
  editIconAvatar: {
    position: 'absolute',
    right: -10,
    width: 22,
    height: 22,
    backgroundColor: colors.aquaMarineTwo,
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
    elevation: 5,
  },
  editIconAvatarImage: {
    width: 12,
    height: 12,
    resizeMode: 'contain',
  },
});
