import React, { useEffect, useState } from 'react';
import {
  View,
  StyleSheet,
  Text,
  FlatList,
  TouchableOpacity,
  ActivityIndicator,
} from 'react-native';
import moment from 'moment';
import { translate } from 'app/i18n/src/locale';
import pt from 'app/i18n/src/locale/pt';
import colors from 'app/styles/colors';
import textStyles from 'app/styles/textStyles';
import apiNode from 'app/services/apiNode';

const SchedulerBox = ({ userProId, onSelected, setSchedulerState }) => {
  const [schedule, setSchedule] = useState(null);
  const [today, setToday] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);
  const [formatedDay, setFormatedDay] = useState(null);
  const [formatedMonth, setFormatedMonth] = useState(null);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    initDate();
  }, []);

  const initDate = async () => {
    const cstDate = await getClosestFreeDay();
    setToday(cstDate);
  };

  const loadFreeDays = async () => {
    setSelectedDay(moment(today).format('D'));
    setFormatedDay(moment(today).format('ddd'));
    setFormatedMonth(moment(today).format('MMM'));
    await getFreeDays();
  };

  useEffect(() => {
    if (today !== null) {
      loadFreeDays();
    }

    if (setSchedulerState) {
      setSchedulerState(prevState => ({ ...prevState, selectedDate: today }));
    }
  }, [today]);

  const columns = 3;

  const getClosestFreeDay = async () => {
    setLoading(true);
    try {
      const { data } = await apiNode.get(`/calendar/get-closest-free?id=${userProId}`);
      setLoading(false);
      if (data?.id) {
        return moment(data?.startDateTime);
      } else {
        return moment();
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      return moment();
    }
  };

  const getFreeDays = async () => {
    setLoading(true);
    try {
      setSchedule([]);
      const formatedDate = moment(today).format('YYYY-MM-DD');
      const { data } = await apiNode.get(
        `/calendar/get-free-by-date/${formatedDate}?id=${userProId}`
      );

      data.map((free, index) => {
        if (moment(free.startDateTime) > moment()) {
          const startHour = moment(free.startDateTime).format('HH:mm');

          setSchedule(prevArray => [
            ...prevArray,
            {
              id: free.id,
              time: startHour.replace(':', 'h'),
              startDateTime: moment(free.startDateTime),
            },
          ]);
        }
      });
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleDecreaseDay = () => {
    setToday(moment(today).subtract(1, 'days'));
  };

  const handleAddDay = () => {
    setToday(moment(today).add(1, 'days'));
  };

  return (
    <View style={styles.container}>
      <View style={styles.panelHeader}>
        {moment(today) >= moment() ? (
          <TouchableOpacity onPress={handleDecreaseDay}>
            <Text style={styles.buttonText}>‹</Text>
          </TouchableOpacity>
        ) : (
          <View />
        )}
        <Text style={[textStyles.semi13, { marginTop: 8 }]}>
          {formatedDay}, {selectedDay} de {formatedMonth}
        </Text>
        <TouchableOpacity onPress={handleAddDay}>
          <Text style={styles.buttonText}>›</Text>
        </TouchableOpacity>
      </View>
      {/* <View style={styles.nextHour}>
        {nextFreeTime ? (
          <Text style={textStyles.bold18}>
            {translate(pt.next_hour)}: {nextFreeTime}
          </Text>
        ) : (
          <Text style={textStyles.bold18}>{translate(pt.without_next_schedule)}</Text>
        )}
      </View> */}
      {!loading ? (
        <>
          {schedule && schedule.length > 0 ? (
            <FlatList
              style={styles.scheduler}
              contentContainerStyle={styles.schedulerContent}
              columnWrapperStyle={styles.schedulerContentColumn}
              data={schedule}
              keyExtractor={item => item.id}
              numColumns={columns}
              renderItem={({ item }) => {
                return (
                  <TouchableOpacity
                    style={{
                      ...styles.schedule,
                      backgroundColor: selected === item.id ? colors.aquaMarineTwo : colors.white,
                    }}
                    onPress={() => {
                      if (setSchedulerState) {
                        setSchedulerState(prevState => ({
                          ...prevState,
                          selectedHour: item.time,
                          calendarId: item.id,
                        }));
                      }
                      if (onSelected) {
                        setSelected(selected === item.id ? null : item.id);
                        const datetime = `${moment(item.startDateTime).format(
                          'YYYY-MM-DD'
                        )} ${item.time.replace('h', ':')}`;
                        onSelected(selected === item.id ? null : item.id, datetime);
                      }
                    }}>
                    <Text
                      style={[
                        textStyles.bold12,
                        styles.time,
                        { color: selected === item.id ? colors.white : colors.aquaMarineTwo },
                      ]}>
                      {item.time}
                    </Text>
                  </TouchableOpacity>
                );
              }}
            />
          ) : (
            <View style={styles.schedulerEmpty}>
              <Text style={textStyles.bold14}>{translate(pt.without_free_time)}</Text>
            </View>
          )}
        </>
      ) : (
        <ActivityIndicator size={'small'} animating color={colors.aquaMarine} />
      )}
    </View>
  );
};

export default SchedulerBox;

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderColor: colors.aquaMarineTwo,
    borderRadius: 4,
    paddingBottom: 20,
    paddingHorizontal: 20,
  },
  wrapper: {
    height: 40,
  },
  slide: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 5,
  },
  buttonText: {
    fontSize: 30,
    color: '#676C88',
  },
  nextHour: {
    borderWidth: 1,
    borderColor: colors.aquaMarineTwo,
    borderRadius: 4,
    marginTop: 8,
    paddingVertical: 10,
  },
  scheduler: {
    marginTop: 15,
    flexWrap: 'wrap',
    width: '100%',
  },
  schedulerContent: {
    width: '100%',
  },
  schedulerContentColumn: {
    //justifyContent: 'space-between',
    //borderWidth: 1,
  },
  schedulerEmpty: {
    marginTop: 18,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  schedule: {
    alignItems: 'center',
    borderWidth: 1,
    borderColor: colors.aquaMarineTwo,
    borderRadius: 4,
    marginBottom: 4,
    paddingVertical: 10,
    paddingHorizontal: 5,
    //flexGrow: 1,
    marginRight: 5,
    width: '32%',
    justifyContent: 'space-between',
  },
  scheduleEmpty: {
    borderWidth: 0,
  },
  time: {
    color: colors.aquaMarineTwo,
  },
  panelHeader: {
    height: 40,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 12,
  },
});
