/* eslint-disable react-native/no-inline-styles */
import React, { Fragment } from 'react';

import { View, StatusBar, Image, TouchableOpacity, StyleSheet, Text } from 'react-native';
import { useSafeArea } from 'react-native-safe-area-context';
import colors from '../styles/colors';
import chevronLeft from '../images/chevronLeft.png';
import LinearGradient from 'react-native-linear-gradient';
import commons from '../styles/commons';
import filterWhiteOff from '../images/filterWhiteOff.png';
import { useNavigation } from '@react-navigation/native';

const HeaderGreenLinear = props => {
  const safeAreaInsets = useSafeArea();

  const navigation = useNavigation();
  return (
    <Fragment>
      <StatusBar translucent backgroundColor="transparent" barStyle="light-content" />
      <LinearGradient
        useAngle={true}
        angle={45}
        colors={[colors.tealish, colors.tiffanyBlue]}
        style={{
          paddingTop: safeAreaInsets.top,
          overflow: 'hidden',
        }}>
        <View style={styles.bar}>
          <TouchableOpacity onPress={props.onPress || navigation.goBack}>
            <Image source={chevronLeft} style={{ tintColor: colors.white, height: 26, width: 26 }} />
          </TouchableOpacity>
          <Text style={styles.title}>{props.title}</Text>
          {props.filter && (
            <TouchableOpacity hitSlop={commons.hitSlop} onPress={props.onRightPress}>
              <Image source={filterWhiteOff} />
            </TouchableOpacity>
          )}
        </View>
        {props.image && (
          <Image style={{ position: 'absolute', alignSelf: 'flex-end' }} source={props.image} />
        )}
      </LinearGradient>
    </Fragment>
  );
};

export default HeaderGreenLinear;

const styles = StyleSheet.create({
  title: { flex: 1, color: colors.white, fontSize: 18, fontWeight: '500', marginLeft: 8 },
  bar: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 50,
    paddingHorizontal: 8,
  },
});
