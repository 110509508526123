import pt from 'app/i18n/src/locale/pt';
import colors from 'app/styles/colors';
import { translate } from 'app/i18n/src/locale';
import React from 'react';
import { AccordionForText } from 'app/components/AccordionForText';
import { StyleSheet, Text, View, ScrollView, TouchableOpacity, Modal as ReactNativeModal } from 'react-native';
import {
  HomeAndYouComponents,
  TeleOrientacao,
  BlogAndPost,
  Exercicio,
  Course,
  DishesAndMeal,
} from './components/AboutComponents';
import CustomIcon from 'app/styles/icon';
import textStyles from 'app/styles/textStyles';
import { useSelector } from 'react-redux';

const modalData = [
  {
    title: 'Home e seus componentes',
    component: () => <HomeAndYouComponents />,
  },
  {
    key: 'orientation',
    title: translate(pt.namu_orientation),
    component: () => <TeleOrientacao />,
  },
  {
    title: 'Blog e Posts',
    component: () => <BlogAndPost />,
  },
  {
    title: 'Exercícios aulas e programas',
    component: () => <Exercicio />,
  },
  {
    title: 'Cursos',
    component: () => <Course />,
  },
  {
    title: 'Pratos e refeições',
    component: () => <DishesAndMeal />,
  },
];

export const GamificationAboutPoints = ({ open, onClose }) => {
  const { profile } = useSelector(state => state.user);

  return (
    <ReactNativeModal transparent={true} visible={open} style={{padding: 20}} onBackdropPress={onClose}>
      <View style={{width: '100%', height: '100%', padding: 20}}>
        <View style={styles.container}>
          <TouchableOpacity activeOpacity={0.7} onPress={onClose} style={styles.close}>
            <CustomIcon name={'close'} size={25} color={colors.black} />
          </TouchableOpacity>
          <ScrollView style={{ flex: 1 }}>
            <View style={styles.content}>
              <View style={styles.header}>
                <Text style={[textStyles.bold18blackTwo, { marginBottom: 5, textAlign: 'center' }]}>
                  Como conseguir a sua pontuação
                </Text>
                <Text style={[textStyles.semi12slateGrey, styles.headerText]}>
                  Explore todo o app durante o período de campanha, aumente sua chances de ganhar e
                  evolua na sua jornada de bem-estar.
                </Text>
              </View>
              {modalData
                .filter(
                  d =>
                    !(profile?.plan_meta?.professional_enabled === false && d?.key === 'orientation')
                )
                .map(data => (
                  <AccordionForText
                    title={data.title}
                    color={colors.white}
                    renderComponent={data.component}
                    item={data.title}
                    type={data.title}
                    key={data.title}
                    id={1}
                    isReverse
                  />
                ))}
            </View>
          </ScrollView>
        </View>
      </View>
    </ReactNativeModal>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
    borderRadius: 10,
  },
  header: {
    paddingTop: 5,
    paddingLeft: 14,
    paddingRight: 14,
    marginBottom: 10,
  },
  headerText: {
    textAlign: 'center',
    paddingHorizontal: 20,
  },
  content: {
    alignItems: 'center',
    marginTop: 'auto',
    height: '100%',
    minHeight: 620,
    padding: 10,
  },
  close: {
    position: 'absolute',
    top: 16,
    right: 16,
    zIndex: 6,
  },
});
