import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Dimensions, SafeAreaView, View, StyleSheet, TouchableOpacity, Text } from 'react-native';
import StatusBarHolder from 'app/components/StatusBarHolder';
import TopFix from 'app/components/TopFix';
import pt from '../../../i18n/src/locale/pt';
import { translate } from '../../../i18n/src/locale';
import colors from 'app/styles/colors';
import OrientationInternal from 'app/screens/core/professional/orientation/OrientationInternal';
import { useGamification } from 'app/hooks/useGamification';
import { sendActionEvent } from '../../../services/utils';
import HandleActivity from '../activities/components/HandleActivity';
import { useSelector } from 'react-redux';
import fonts from '../../../styles/fonts';
import TelemedicineInternal from './orientation/TelemedicineInternal';
import { useRoute } from '@react-navigation/native';

const ProfessionalScreen = () => {
  const { profile } = useSelector(state => state.user);

  const routes = profile?.b2b_user && 
    profile?.plan_meta?.professional_enabled &&
    profile?.plan_meta?.telemedicine_enabled ?
    [
      { key: 'orientation', title: translate(pt.orientation)},
      { key: 'telemedicine', title: translate(pt.telemedicine)}
    ] : [{ key: 'orientation', title: translate(pt.orientation) }];

  const { params } = useRoute();
  const { eventsGenerationByHandleActivity } = useGamification();

  const [activeTab, setActiveTab] = useState(params?.tab ?? 'orientation');
  
  
  const handlePressTab = async (route) => {
    setActiveTab(route.key);

    try {
      sendActionEvent(`category_${route.key}`, {}, profile.email);
      const { data } = await HandleActivity({ routeName: route.key.toString() });
      eventsGenerationByHandleActivity(data);
    } catch (err) {
    }
  }
  
  const renderScene = useCallback(() => {
    if (activeTab === 'orientation') {
      return <OrientationInternal active />;
    }
    
    return <TelemedicineInternal />
  }, [activeTab]);
  
  useEffect(() => {
    if (params?.tab) setActiveTab(params?.tab);
  }, [params]);

  return (
    <SafeAreaView style={[styles.container]}>
      <StatusBarHolder backgroundColor="white" barStyle="dark-content" />
      <View style={styles.tabsContainer}>
        {routes.map(route => {
          return (
            <TouchableOpacity
              style={[
                { marginLeft: 20, height: '100%', justifyContent: 'center' }, 
                activeTab === route.key ? styles.activeTab : styles.inactiveTab
              ]}
              onPress={() => handlePressTab(route)}
            >
              <Text 
                style={[
                  styles.tabTitle, 
                  activeTab === route.key 
                    ? styles.activeTabTitle :
                    styles.inactiveTabTitle
                ]}
              >
                {route.title}
              </Text>
            </TouchableOpacity>
          );
        })}
      </View>
      <View style={{flex: 1}}>
        {renderScene()}
      </View>
    </SafeAreaView>
  );
};

export default ProfessionalScreen;

const styles = StyleSheet.create({
  container: {
    height: '100vh',
    backgroundColor: colors.paleGrey,
    zIndex: -1,
  },
  indicatorStyle: {
    backgroundColor: colors.greyBlue,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    height: 4,
  },
  tabsContainer: {
    flexDirection: 'row',
    backgroundColor: colors.white,
    height: 50,
    alignItems: 'center'
  },
  tabTitle: {
    fontFamily: fonts.BOLD,
    color: colors.black,
    size: 18,
  },
  inactiveTab: {
    borderBottomWidth: 3,
    borderBottomColor: colors.white
  },
  activeTab: {
    borderBottomWidth: 3,
    borderBottomColor: colors.aquaMarineTwo,
  },
  inactiveTabTitle: {
    opacity: 0.4
  },
  activeTabTitle: {
    opacity: 1
  }
});
