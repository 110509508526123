import colors from 'app/styles/colors';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import ShadowView from '@liuyunjs/react-native-simple-shadow-view';

const ShadowedView = props => {
  return (
    <ShadowView style={[styles.shadowContainer, props.shadowContainerStyle]}>
      <View style={[styles.wrapper, props.wrapperStyle]}>{props.children}</View>
    </ShadowView>
  );
};

const styles = StyleSheet.create({
  shadowContainer: {
    backgroundColor: colors.white,
    borderRadius: 10,
    shadowColor: colors.black,
    shadowOpacity: 0.1,
    shadowRadius: 5,
    shadowOffset: { width: 0, height: 5 },
  },
  wrapper: {
    overflow: 'hidden',
    borderRadius: 10,
    paddingHorizontal: 20,
    paddingVertical: 10,
    height: '100%',
    width: '100%',
  },
});

export default ShadowedView;
