import Geolocation from '@react-native-community/geolocation';
import ButtonBase from 'app/components/ButtonBase';
import Capsule from 'app/components/Capsule';
import CardProfessional from 'app/components/CardProfessional';
import HeaderHomeNew from 'app/components/HeaderHomeNew';
import ClassLoader from 'app/components/Loaders/ClassLoader';
import GymInfoLoader from 'app/components/Loaders/GymInfoLoader';
import ScheduledGymLoader from 'app/components/Loaders/ScheduledGymLoader';
import ShadowedView from 'app/components/ShadowedView';
import Spacer from 'app/components/Spacer';
import useGlobalAlert from 'app/hooks/useGlobalAlert';
import { translate } from 'app/i18n/src/locale';
import pt from 'app/i18n/src/locale/pt';
import CardGym from 'app/screens/core/gym/components/CardGym';
import CardSquare from 'app/screens/core/gym/components/CardSquare';
import FavoriteGymsWidget from 'app/screens/core/gym/components/FavoriteGymsWidget';
import FilterOption from 'app/screens/core/gym/components/FilterOption';
import { FreeCheckinWidget } from 'app/screens/core/gym/components/FreeCheckinWidget';
import { RecommendGymWidget } from 'app/screens/core/gym/components/RecommendGymWidget';
import PerformCheckinWidget from 'app/screens/core/gym/components/PerformCheckinWidget';
import HeaderTitleNew from 'app/screens/core/home/components/HeaderTitleNew';
import api from 'app/services/api';
import apiNode from 'app/services/apiNode';
import { formatDistance } from 'app/services/utils';
import colors from 'app/styles/colors';
import commons from 'app/styles/commons';
import fonts from 'app/styles/fonts';
import CustomIcon from 'app/styles/icon';
import textStyles from 'app/styles/textStyles';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Dimensions, Image, Text, TouchableOpacity } from 'react-native';
import { RefreshControl } from 'react-native';
import { SafeAreaView } from 'react-native';
import { FlatList, Linking, Platform, ScrollView, StatusBar, StyleSheet, View } from 'react-native';
// import { PERMISSIONS, check, checkMultiple, requestMultiple } from 'react-native-permissions';
// import { Actions } from 'react-native-router-flux';
import { useSafeArea } from 'react-native-safe-area-context';
// import Icon from 'react-native-vector-icons/FontAwesome';
import { useSelector } from 'react-redux';
import Swiper from 'react-native-web-swiper';
import { useIsFocused, useNavigation } from '@react-navigation/native';

const width = Dimensions.get('screen').width;

const GymScreen = () => {
  const navigation = useNavigation();

  const [gyms, setGyms] = useState([]);
  const [scheduledGyms, setScheduledGyms] = useState([]);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [scheduledLoading, setScheduledLoading] = useState(false);
  const [checkinTokens, setCheckinTokens] = useState([]);
  const [infoLoading, setInfoLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [infos, setInfos] = useState({ checkins: 0, gyms: 0 });
  const [favoriteGyms, setFavoriteGyms] = useState([]);
  const [refreshing, setRefreshing] = useState(false);
  const [modalities, setModalities] = useState([]);
  const { setAlert } = useGlobalAlert();
  const safeAreaInsets = useSafeArea();

  const { profile } = useSelector(state => state.user);

  const isFocused = useIsFocused();

  useEffect(() => {
    // checkLocation();
  }, []);

  useEffect(() => {
    if (isFocused) {
      fetchData();
    }
  }, [isFocused]);

  useEffect(() => {
    fetchFilters();
  }, []);

  const fetchData = () => {
    Promise.all([
      getScheduled(),
      fetchInfos(),
      getGyms(),
      getFavoriteGyms(),
      getPendingCheckinTokens(),
    ]).finally(() => setRefreshing(false));
  };

  const onRefresh = () => {
    setRefreshing(true);
    fetchData();
  };

  const fetchInfos = async () => {
    setInfoLoading(true);
    try {
      const { data } = await apiNode.get('namu-pass/user-stats');
      setInfos({
        checkins: data.quantity_of_checkins,
        gyms: data.quantity_of_gyms,
      });
    } catch (err) {
      console.log(err);
    }
    setInfoLoading(false);
  };

  const fetchFilters = useCallback(async () => {
    try {
      const { data } = await api.get('gyms/get-filters');
      setModalities(data.modalities);
    } catch (err) {
      console.log(err);
    }
  }, []);

  // const checkLocation = () => {
  //   if (Platform.OS === 'android') {
  //     check(PERMISSIONS.ANDROID.ACCESS_FINE_LOCATION).then(status => {
  //       if (status === 'blocked') {
  //         showActiveLocation();
  //       }
  //     });
  //   } else {
  //     check(PERMISSIONS.IOS.LOCATION_WHEN_IN_USE).then(status => {
  //       if (status === 'blocked') {
  //         showActiveLocation();
  //       }
  //     });
  //   }
  // };

  const showActiveLocation = () => {
    setAlert('Ative a localização', 'Para melhor experiência, ative sua localização.', {
      text: 'Ir para configurações',
      onPress: () => Linking.openSettings(),
    });
  };

  const getScheduled = useCallback(async () => {
    setScheduledLoading(true);
    try {
      const { data } = await api.get('/gyms/scheduled');
      setScheduledGyms(data);
    } catch (e) {}
    setScheduledLoading(false);
  }, []);

  const getFavoriteGyms = useCallback(async () => {
    try {
      const { data } = await apiNode.get('namu-pass/checkin-history?limit=10');

      setFavoriteGyms(data?.history || []);
    } catch (error) {
      console.warn(error);
    }
  }, []);

  const getPendingCheckinTokens = useCallback(async () => {
    try {
      const { data } = await apiNode.get('namu-pass/pending-checkin');
      const tokens = data.tokens || [];

      setCheckinTokens(tokens);
    } catch (error) {
      console.warn(error);
    }
  }, []);

  const getGyms = useCallback(async () => {
    try {
      setLoading(true);
      getCurrentLocation(async result => {
        console.log('COORDS', result)
        const { data } = await api.get('gyms', {
          params: {
            limit: 10,
            lat: result?.latitude || '',
            lng: result?.longitude || '',
          },
        });

        setGyms(data.slice(0, 10));

        if (result) {
          setCurrentLocation({
            latitude: result?.latitude,
            longitude: result?.longitude,
          });
        } else {
          setCurrentLocation(null);
        }
        setLoading(false);
      });
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }, []);

  const getCurrentLocation = onDone => {
    Geolocation.getCurrentPosition(
      position => {
        const currentLatitude = parseFloat(JSON.stringify(position.coords.latitude));
        const currentLongitude = parseFloat(JSON.stringify(position.coords.longitude));

        onDone({
          latitude: currentLatitude,
          longitude: currentLongitude,
        });
      },
      error => {
        onDone();
        // showActiveLocation();
      },
      {
        enableHighAccuracy: false,
        timeout: 3000,
        maximumAge: 1000,
      }
    );
  };

  const renderCheckins = ({item}) => {
    const { provider, providerId, token, expireAt } = item;
    const { logo, address, name, cptId } = provider;
    return (
      <View style={{
        width: '100%',
        paddingHorizontal: 20,
        height: 240
      }}>
        <ShadowedView
          shadowContainerStyle={{
            height: 240,
            backgroundColor: colors.aquaMarineTwo,
            marginBottom: 20,
            width: '100%',
          }}
          wrapperStyle={{ paddingHorizontal: 0, paddingVertical: 0 }}>
          <View
            style={{
              height: 70,
              flexDirection: 'row',
              alignItems: 'center',
              backgroundColor: colors.white,
              borderBottomLeftRadius: 10,
              borderBottomRightRadius: 10,
              paddingLeft: 12,
            }}>
            <TouchableOpacity
              activeOpacity={0.6}
              onPress={() => navigation.navigate('GymDetail', { id: cptId, currentLocation })}>
              <Image source={{ uri: logo || '' }} style={styles.checkImage} />
            </TouchableOpacity>

            <View style={{ ...commons.flexRowBetween, flex: 1, marginRight: 15 }}>
              <View style={{ justifyContent: 'center' }}>
                <TouchableOpacity
                  activeOpacity={0.6}
                  onPress={() => navigation.navigate('GymDetail', { id: cptId, currentLocation })}
                  style={commons.flexRow}>
                  <Text style={textStyles.bold18}>{name}</Text>
                </TouchableOpacity>
                <Text
                  style={{ ...textStyles.regular12, width: 180 }}
                  numberOfLines={1}
                  ellipsizeMode="tail">
                  {address}
                </Text>
              </View>
              <TouchableOpacity
                style={{ marginLeft: 10 }}
                onPress={() => navigation.navigate('GymDetail', { id: cptId, currentLocation })}
                activeOpacity={0.6}>
                <CustomIcon name={'chevron_right'} size={16} color={colors.aquaMarineTwo} />
              </TouchableOpacity>
            </View>
          </View>
          <View>
            <Text
              style={{
                ...textStyles.bold12White,
                textAlign: 'center',
                marginVertical: 15,
                marginHorizontal: 35,
              }}>
              {'A academia recebeu sua reserva. Comunique na recepção seu check-in via Namu Pass.'}
            </Text>

            <Text style={{ ...textStyles.regular11white, textAlign: 'center' }}>
              {'Caso solicitado, informe o código'}
            </Text>

            <View
              style={{
                justifyContent: 'center',
                marginVertical: 10,
                alignItems: 'center',
                height: 42,
                marginHorizontal: 18,
                borderRadius: 60,
                backgroundColor: colors.white,
              }}>
              <Text style={{ ...textStyles.bold15, color: colors.aquaMarineTwo, letterSpacing: 6 }}>
                {token.toString().toLocaleUpperCase()}
              </Text>
            </View>

            {expireAt && (
              <Text style={{ ...textStyles.regular11white, textAlign: 'center' }}>
                {`Check-in expira ${moment(expireAt).fromNow()}`}
              </Text>
            )}
          </View>
        </ShadowedView>
      </View>
    );
  };

  const renderFavoriteGyms = () => {
    return (
      <FlatList
        style={styles.squareList}
        horizontal
        contentContainerStyle={{ paddingHorizontal: 20, paddingBottom: 28 }}
        showsHorizontalScrollIndicator={false}
        ItemSeparatorComponent={() => <Spacer value={6} />}
        keyExtractor={item => item.id}
        data={favoriteGyms}
        renderItem={({ item }) => <CardSquare data={item} />}
      />
    );
  };

  return (
    <View style={styles.container}>
      <StatusBar backgroundColor="white" barStyle="dark-content" />
      <View style={{ flex: 1 }}>
        <HeaderHomeNew
          pass
          style={{
            paddingTop: safeAreaInsets.top,
          }}
        />

        <ScrollView
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={onRefresh}
              colors={[colors.aquaMarineTwo]}
              progressBackgroundColor="#F5F5F5"
            />
          }
          contentContainerStyle={{ paddingBottom: safeAreaInsets.bottom + 80, paddingTop: 80 }}>
          <View
            style={{
              flexDirection: 'row',
              marginBottom: 20,
              justifyContent: 'center',
              paddingHorizontal: 20,
            }}>
            {infoLoading ? (
              <GymInfoLoader />
            ) : (
              <>
                <ShadowedView
                  shadowContainerStyle={{ height: 94, flex: 1, backgroundColor: colors.white }}
                  wrapperStyle={{
                    paddingHorizontal: 10,
                    paddingVertical: 10,
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}>
                  <Text style={textStyles.bold14grey}>{translate(pt.performed_check_ins)}</Text>
                  <Text
                    style={{ fontFamily: fonts.BLACK, fontSize: 30, color: colors.aquaMarineTwo }}>
                    {infos.checkins}
                  </Text>
                </ShadowedView>
                <Spacer value={10} />
                <ShadowedView
                  shadowContainerStyle={{ height: 94, flex: 1, backgroundColor: colors.white }}
                  wrapperStyle={{
                    paddingHorizontal: 10,
                    paddingVertical: 10,
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}>
                  <Text style={textStyles.bold14grey}>{translate(pt.visited_gyms)}</Text>
                  <Text
                    style={{ fontFamily: fonts.BLACK, fontSize: 30, color: colors.aquaMarineTwo }}>
                    {infos.gyms}
                  </Text>
                </ShadowedView>
              </>
            )}
          </View>

          <FreeCheckinWidget />

          <View style={{ justifyContent: 'flex-start' }}>
            <HeaderTitleNew
              title={translate(pt.perform_your_checkin)}
              textStyle={styles.titleText}
              style={{ marginTop: 20 }}
            />

            {scheduledLoading ? (
              <ScheduledGymLoader key={Math.random()} />
            ) : checkinTokens.length ? (
              <Swiper
                  loop
                  innerContainerStyle={{width: '100%', height: 280}}
                  controlsProps={{
                    prevTitle: null,
                    nextTitle: null,
                    dotsPos: 'bottom',
                    dotActiveStyle: {
                      backgroundColor: colors.aquaMarineTwo
                    },
                  }}
                >
                {checkinTokens.map(item => {
                  return renderCheckins({item});
                })}
              </Swiper>
            ) : (
              <PerformCheckinWidget coords={currentLocation} />
            )}
          </View>

          <View style={{ justifyContent: 'flex-start' }}>
            <HeaderTitleNew
              title={translate(pt.favorite_gyms)}
              textStyle={styles.titleText}
              style={{ marginTop: 20 }}
            />
            {favoriteGyms.length ? renderFavoriteGyms() : <FavoriteGymsWidget />}
          </View>

          <HeaderTitleNew
            title={translate(currentLocation ? pt.gyms_next_to_you : pt.gyms)}
            textStyle={styles.titleText}
            uppercaseLink
            style={{ marginTop: 20 }}
            onPress={() =>
              navigation.navigate('ShowGyms', {
                coordinates: currentLocation?.lat
                  ? { lat: currentLocation.lat, lng: currentLocation.lng }
                  : null,
              })
            }
          />

          {loading ? (
            <ClassLoader containerHeight={230} onlyOneRow />
          ) : (
            <FlatList
              style={styles.squareList}
              horizontal
              contentContainerStyle={{ paddingHorizontal: 20, paddingBottom: 13 }}
              showsHorizontalScrollIndicator={false}
              ItemSeparatorComponent={() => <Spacer size={10} />}
              keyExtractor={item => item.id}
              data={gyms}
              renderItem={({ item }) => (
                <CardGym data={item} currentLocation={currentLocation} {...item} />
              )}
            />
          )}

          <HeaderTitleNew
            title={translate(pt.recommend_a_gym)}
            textStyle={styles.titleText}
            style={{ marginTop: 20 }}
          />

          <RecommendGymWidget />

          <HeaderTitleNew
            title={translate(pt.per_modality)}
            textStyle={styles.titleText}
            style={{ marginTop: 20 }}
          />

          <FlatList
            horizontal
            showsHorizontalScrollIndicator={false}
            data={modalities}
            contentContainerStyle={{ paddingHorizontal: 20 }}
            keyExtractor={item => item.id}
            renderItem={({ item }) => {
              return (
                <FilterOption
                  title={item.title}
                  img={item.imageUrl}
                  iconColor={colors.aquaMarineTwo}
                  isSelected={false}
                  onPress={() =>
                    navigation.navigate('ShowGyms', {
                      coordinates: currentLocation?.lat
                        ? { lat: currentLocation.lat, lng: currentLocation.lng }
                        : null,
                      preFilters: { modalities: item.id.toString() },
                    })
                  }
                />
              );
            }}
          />

          {profile?.plan_meta?.professional_enabled !== false && (
            <View style={{ marginTop: -13 }}>
              <HeaderTitleNew
                title={translate(pt.need_professional_help)}
                textStyle={styles.titleText}
              />
              <CardProfessional
                onPress={() => navigation.navigate('Professional')}
                only
                style={{ ...commons.defaultHorizontalMargin, marginBottom: 13 }}
              />
            </View>
          )}
        </ScrollView>
      </View>
    </View>
  );
};

export default GymScreen;

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    height: '100vh',
    backgroundColor: colors.paleGrey,
  },
  squareList: {
    width: '100%',
  },
  titleText: { color: colors.greyBlue, fontFamily: fonts.MEDIUM },
  checkImage: {
    width: 45,
    height: 45,
    resizeMode: 'cover',
    borderRadius: 10,
    marginRight: 10,
  },
});
