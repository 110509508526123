import ButtonBase from 'app/components/ButtonBase';
import pt from 'app/i18n/src/locale/pt';
import colors from 'app/styles/colors';
import fonts from 'app/styles/fonts';
import React, { useEffect, useState } from 'react';
import * as RNIap from 'react-native-iap';
import {
  Image,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Modal
} from 'react-native';
import commons from 'app/styles/commons';
import closeImage from 'app/images/closeGrey.png';
import api from 'app/services/api';
import { errorControl, formatMoneyBR, sendActionEvent, showAlert } from 'app/services/utils';
import { translate } from 'app/i18n/src/locale';
import LinearGradient from 'react-native-linear-gradient';
import coin1 from 'app/images/coin1.png';
import coin2 from 'app/images/coin2.png';
import coin3 from 'app/images/coin3.png';
import coin4 from 'app/images/coin4.png';
import coin5 from 'app/images/coin5.png';
import { useSelector } from 'react-redux';
import QueryString from 'qs';
import apiNode from 'app/services/apiNode';
import { useNavigation } from '@react-navigation/native';

const coinImages = [coin1, coin2, coin3, coin4, coin5];

const GetCreditsModal = ({ open, onClose }) => {

  const navigation = useNavigation();

  const [bundles, setBundles] = useState([]);
  const [selectedBundle, setSelectedBundle] = useState();
  const [teleDuration, setTeleDuration] = useState();
  const [loading, setLoading] = useState(false);

  const { profile } = useSelector(state => state.user);

  useEffect(() => {
    getBundles();
    getTeleInfos();
  }, []);

  const getTeleInfos = async () => {
    const {
      data: { tele_duration },
    } = await apiNode.get('meta/tele_duration');
    setTeleDuration(tele_duration);
  };

  const getBundles = async () => {
    try {
      const { data } = await api.get('/credits/bundles');
      setBundles(data);
    } catch (error) {
      console.log(errorControl(error));
    }
  };

  const getCoinImage = index => {
    const icon = coinImages[index >= bundles.length - 1 ? coinImages.length - 1 : index];
    return (
      <Image
        source={icon}
        // eslint-disable-next-line eqeqeq
        style={{ width: index == bundles.length - 1 ? 130 : 90, height: 90, resizeMode: 'contain' }}
      />
    );
  };

  const purchaseBundleiOS = async selectedBundlePurchase => {
    try {
      await RNIap.initConnection();
      await RNIap.clearTransactionIOS();

      await RNIap.getProducts({ skus: [selectedBundlePurchase.appleId] });
      const _purchase = await RNIap.requestPurchase({ sku: selectedBundlePurchase.appleId });

      const data = {
        platform: 'ios',
        bundle_id: selectedBundlePurchase.id,
        apple_receipt: _purchase.transactionReceipt,
        apple_transaction: _purchase.transactionId,
      };

      await api.post('credits/purchase', QueryString.stringify(data));
    } catch (error) {
      console.log('ERROR', error);
      showAlert('Falha ao processar o pagamento. Verifique os dados e tente novamente.');
      sendActionEvent('error_payment', { email: profile.email }, profile.email);
    }
  };

  const purchase = async () => {
    setLoading(true);
    // if (Platform.OS === 'android') {
      onClose();
      navigation.navigate('Payment', {
        paymentType: 'credit_bundle',
        product: selectedBundle.id,
        product_name: selectedBundle?.amount + ' créditos',
        value: selectedBundle?.price / 100,
      });
    // } else {
      // await purchaseBundleiOS(selectedBundle);
      // onClose();
    // }
    setLoading(false);
  };

  const renderBundles = () => {
    return bundles.map((item, index) => {
      const lastOdd = index === bundles.length - 1 && bundles.length % 2 !== 0;
      return (
        <TouchableOpacity
          key={item.id}
          activeOpacity={0.5}
          style={[styles.bundleContainer, lastOdd && { width: '100%' }]}
          onPress={() => setSelectedBundle(item)}>
          <LinearGradient
            style={styles.bundleSelection}
            useAngle={true}
            angle={45}
            colors={
              selectedBundle?.id === item.id
                ? [colors.aquaMarineTwo, colors.aquaMarineLight2]
                : Array(2).fill(colors.white)
            }>
            <View style={styles.bundleWrapper}>
              <LinearGradient
                useAngle={true}
                angle={45}
                colors={[colors.aquaMarineTwo + '33', colors.aquaMarineTwo + '59']}
                style={styles.bundleContent}>
                {item.discount && (
                  <View style={styles.bundleDiscount}>
                    <Text style={styles.bundleDiscountText}>{item.discount}% OFF</Text>
                  </View>
                )}
                {getCoinImage(index)}
                <View style={styles.bundleText}>
                  <Text style={styles.bundleQuantity}>
                    {item.amount} {translate(pt.credits)}
                  </Text>
                  <Text style={styles.bundlePrice}>{formatMoneyBR(item.price / 100)}</Text>
                </View>
              </LinearGradient>
            </View>
          </LinearGradient>
        </TouchableOpacity>
      );
    });
  };

  return (
    <Modal transparent={true} style={styles.container} visible={open} onBackdropPress={onClose}>
      <View style={{paddingHorizontal: 10, width: '100%', height: '100%', justifyContent: 'center'}}>
        <View style={styles.content}>
          <View style={styles.topContainer}>
            <Text style={styles.titleHeader}>{'Adquira créditos'}</Text>
            <TouchableOpacity onPress={onClose} hitSlop={commons.hitSlop}>
              <Image
                source={closeImage}
                style={{ tintColor: colors.black, width: 24, height: 24, resizeMode: 'contain' }}
              />
            </TouchableOpacity>
          </View>
          <ScrollView style={styles.wrapper} contentContainerStyle={styles.scrollWrapper}>
            <Text style={styles.title}>{translate(pt.select_bundle)}</Text>
            <Text style={styles.subtitle}>
              {'Créditos para '}
              <Text style={{ fontFamily: fonts.BOLD }}>{'orientações'}</Text>
              {' até '}
              <Text style={{ fontFamily: fonts.BOLD }}>{'20 min'}</Text>
            </Text>

            <View style={styles.bundlesList}>{renderBundles()}</View>

            <View style={{ marginTop: 15 }}>
              <Text style={[styles.subtitle, { color: colors.grayText }]}>
                {translate(
                  profile?.plan_meta?.tele_enabled
                    ? profile?.plan_meta?.tele_balance === 'user'
                      ? pt.credit_can_be_used_message_for_credit
                      : pt.credit_can_be_used_message_for_pool
                    : pt.credit_can_be_used_message,
                  { minutes: teleDuration || 20 }
                )}
              </Text>
            </View>
          </ScrollView>
          {selectedBundle && (
            <View style={styles.continueContainer}>
              <ButtonBase
                loading={loading}
                onPress={purchase}
                fill
                backgroundColor={colors.aquaMarineTwo}
                style={styles.buttonOk}
                title={translate(pt.continue_purchase)}
              />
            </View>
          )}
        </View>
      </View>
    </Modal>
  );
};

export default GetCreditsModal;

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 10,
    justifyContent: 'center',
  },
  scrollWrapper: {
    paddingBottom: 20,
    paddingHorizontal: 28,
  },
  bundlesList: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 10,
    justifyContent: 'space-between',
  },
  bundleContainer: {
    padding: 2,
    width: '50%',
  },
  bundleWrapper: {
    backgroundColor: colors.white,
    borderRadius: 10,
    overflow: 'hidden',
  },
  bundleContent: {
    width: '100%',
    alignItems: 'center',
    height: 135,
    padding: 6,
    justifyContent: 'space-between',
  },
  bundleSelection: {
    padding: 5,
    borderRadius: 15,
  },
  bundleSelected: {
    backgroundColor: colors.aquaMarineTwo,
  },
  bundleDiscount: {
    position: 'absolute',
    zIndex: 1,
    left: 6,
    top: 8,
    backgroundColor: colors.darkRed,
    paddingHorizontal: 7,
    paddingVertical: 3,
    borderRadius: 10,
  },
  bundleDiscountText: {
    color: colors.white,
    fontFamily: fonts.BOLD,
    fontSize: 8,
  },
  bundleText: {
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    backgroundColor: colors.white,
    borderColor: colors.aquaMarineLight2,
    borderWidth: 1,
    width: '100%',
    alignItems: 'center',
    paddingVertical: 3,
    position: 'absolute',
    bottom: 6,
  },
  bundleQuantity: {
    fontFamily: fonts.BOLD,
    fontSize: 13,
    color: colors.aquaMarineTitle,
  },
  bundlePrice: {
    fontFamily: fonts.MEDIUM,
    fontSize: 13,
    color: colors.darkPro,
  },
  scroll: {},
  content: {
    backgroundColor: colors.white,
    borderRadius: 20,
    // marginHorizontal: 10,
    maxHeight: '90%',
    alignItems: 'center',
    width: '100%',
    paddingBottom: 20,
  },
  textStyle: {
    fontFamily: fonts.REGULAR,
    color: colors.greyLight4,
    fontSize: 14,
    paddingHorizontal: 20,
    textAlign: 'center',
  },
  titleHeader: {
    textAlign: 'center',
    flex: 0.8,
    fontFamily: fonts.BOLD,
    fontSize: 18,
  },
  title: {
    fontFamily: fonts.BOLD,
    fontSize: 16,
    color: colors.aquaMarineTitle,
  },
  subtitle: {
    fontFamily: fonts.REGULAR,
    fontSize: 14,
    color: colors.darkPro2,
  },
  buttonOk: {
    // width: 220,
    height: 50,
    zIndex: 1,
  },
  topContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 25,
    width: '100%',
  },
  continueContainer: {
    width: '100%',
    paddingHorizontal: 28,
  },
});
