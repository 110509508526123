import React, { useState, useEffect, useCallback, useRef } from 'react';
import { View, Dimensions, Text, StyleSheet, ScrollView, Platform, StatusBar } from 'react-native';
import pt from 'app/i18n/src/locale/pt';
import { translate } from 'app/i18n/src/locale';
import LineChartWithTooltips from 'app/components/LineChartWithTooltips';
import Spacer from 'app/components/Spacer';
import colors from 'app/styles/colors';
import moment from 'moment';
import fonts from 'app/styles/fonts';
import WaterControl from 'app/screens/core/hydration/components/WaterControl';
import { useSelector } from 'react-redux';
import WeekSlider from 'app/screens/core/hydration/components/WeekSlider';
import RBSheet from 'react-native-raw-bottom-sheet';
import WaterReference from 'app/screens/core/hydration/components/WaterReference';
import { useNavigation } from '@react-navigation/native';

const WeeklyInternalScreen = () => {
  const [arrayWaterIntakesWeekly, setArrayWaterIntakesWeekly] = useState([]);
  const [chartData, setChartData] = useState([]);
  const refRBSheetTimer = useRef();
  const { profile } = useSelector(state => state.user);
  const [weeksRegister, setWeeksRegister] = useState([]);
  const [weeks, setWeeks] = useState([]);
  const [historyLoaded, setHistoryLoaded] = useState(false);
  const [weekHistory, setWeekHistory] = useState([]);

  const navigation = useNavigation();

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      useCallback(() => {
        getWeeklyHistory();
      }, [getWeeklyHistory])
    });

    return unsubscribe;
  }, [navigation]);

  useEffect(() => {
    const weekly_history = profile.weekly_history;
    setArrayWaterIntakesWeekly(weekly_history);
  }, [profile.weekly_history]);

  useEffect(() => {
    const temp = arrayWaterIntakesWeekly
      ?.map(item => {
        if (weeksRegister[moment(item.data).isoWeekday()]) {
          weeksRegister[moment(item.data).isoWeekday()] = {
            month: moment(item.data).format('DD/MM/YYYY'),
            monthName: moment(item.data).format('ddd').toUpperCase(),
            ...item,
          };
        }
        return {
          month: moment(item.data).format('DD/MM/YYYY'),
          monthName: moment(item.data).format('ddd').toUpperCase(),
          ...item,
        };
      })
      .reduce((accumulator, element) => {
        if (!accumulator.find(el => el.month === element.month)) {
          accumulator.push(element);
        }

        return accumulator;
      }, []);
    setChartData(temp);
  }, [arrayWaterIntakesWeekly]);

  const formatWaterInstakesValue = waterIntakes => {
    if (profile.sum_of_the_week === 0) {
      const waterInstakesFormated = parseFloat(waterIntakes);
      return waterInstakesFormated.toString().replace('.', ',');
    }

    const waterInstakesFormated = parseFloat(waterIntakes / 1000);
    return waterInstakesFormated.toString().replace('.', ',');
  };

  const getWeeklyHistory = useCallback(async () => {
    const weekly_history = profile.weekly_history;
    setArrayWaterIntakesWeekly(weekly_history);
  }, [profile.weekly_history]);

  const closeReferenceModal = () => {
    refRBSheetTimer.current.close();
  };

  const onClose = () => {
    if (Platform.OS === 'android') {
      StatusBar.setBackgroundColor(colors.transparent);
    }
  };

  const weeksArray = () => {
    let helper = [];
    let weeksNum = [];

    for (let i = 0; i < 6 + 1; ++i) {
      helper.push(moment().isoWeekday(i).format('ddd').charAt());
      weeksNum.push({});
    }

    setWeeksRegister(weeksNum);
    setWeeks(helper);
  };

  useEffect(() => {
    weeksArray();
    convertToArray();
  }, [arrayWaterIntakesWeekly]);

  const convertToArray = () => {
    setHistoryLoaded(false);

    let helper = [];
    for (let i = 0; i < weeksRegister.length; ++i) {
      if (weeksRegister[i]?.monthName) {
        helper.push(weeksRegister[i]);
      } else {
        helper.push({
          ml: 0,
          data:
            profile?.daysRange === undefined
              ? moment().format('YYYY/MM/DD')
              : moment(profile?.daysRange[i] ?? 'now').format('YYYY/MM/DD'),
        });
      }
    }
    setWeekHistory(helper);
    setHistoryLoaded(true);
  };

  return (
    <View style={styles.container}>
      <WeekSlider />
      <ScrollView contentContainerStyle={{ paddingBottom: 20 }}>
        <Spacer value={47} />
        <View style={styles.containerCenter}>
          <Text style={styles.ingestedQuantity}>{translate(pt.ingested_quantity)}</Text>
          <Spacer value={4} />
        </View>
        <Spacer value={34} />
        <Text style={styles.qntWater}>{`${formatWaterInstakesValue(
          profile.sum_of_the_week
        )} L`}</Text>
        <Text style={styles.ingestedToday}>ingeridos esta semana</Text>
        <Spacer value={36} />

        {historyLoaded ? (
          <LineChartWithTooltips
            measure="L"
            data={{
              labels: weeks,
              itens: weekHistory,
              datasets: [
                {
                  data: weekHistory.map(item => Number(item?.ml)),
                  color: (opacity = 0) => `rgba(76, 72, 255, ${opacity})`,
                },
              ],
            }}
            width={Dimensions.get('window').width} // from react-native
            height={200}
            bezier
            withShadow={false}
            withInnerLines
            yLabelsOffset={20}
            fromZero
            formatYLabel={item => `${formatWaterInstakesValue(item)} L`}
            chartConfig={{
              backgroundColor: '#e26a00',
              backgroundGradientFrom: colors.paleGrey,
              backgroundGradientTo: colors.paleGrey,

              color: (opacity = 1) => `rgba(148, 150, 157, ${opacity})`,
              labelColor: (opacity = 1) => `rgba(148, 150, 157, ${opacity})`,
            }}
          />
        ) : (
          <View style={{ height: 200 }} />
        )}
        <Spacer value={37} />
        <WaterControl />
      </ScrollView>
      <RBSheet onClose={onClose} ref={refRBSheetTimer} animationType="none" duration={200}>
        <WaterReference doClose={closeReferenceModal} reference={profile.hidratation_goal} />
      </RBSheet>
    </View>
  );
};

export default WeeklyInternalScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.paleGrey,
  },
  containerCenter: {
    alignItems: 'center',
  },
  ingestedQuantity: {
    fontFamily: fonts.MEDIUM,
    fontSize: 16,
    color: colors.charcoalGreyTwo,
  },
  qntWater: {
    fontFamily: fonts.BOLD,
    fontSize: 26,
    textAlign: 'center',
    color: colors.charcoalGreyTwo,
  },
  ingestedToday: {
    fontFamily: fonts.LIGHT,
    marginTop: -6,
    fontSize: 12,
    textAlign: 'center',
    color: colors.blueyGrey,
  },
});
