// React and libs
import React from 'react';
import {
  View,
  Text,
  StyleSheet,
  TextInput,
  ImageBackground,
  ScrollView,
  Image,
  TouchableOpacity,
} from 'react-native';
import Clipboard from '@react-native-clipboard/clipboard';
import { useSafeArea } from 'react-native-safe-area-context';

// i18n
import { translate } from '../../../i18n/src/locale';
import pt from '../../../i18n/src/locale/pt';

// styles
import colors from 'app/styles/colors';
import commons from 'app/styles/commons';

// components
import ButtonBase from 'app/components/ButtonBase';

// images
import backgroundBlue from '../../../images/backgroundBlue.png';
import logo from '../../../images/logoVertical.png';
import closeGrey from '../../../images/closeGrey.png';
import { sendActionEvent } from 'app/services/utils';
import { useSelector } from 'react-redux';
import CardSummary from 'app/screens/core/payment/components/CardSummary';
import CardCongrats from 'app/screens/core/payment/components/CardCongrats';
import { useNavigation } from '@react-navigation/native';

const PaymentCompleteBillScreen = ({ route }) => {

  const navigation = useNavigation();

  const {
    courseName,
    courseValue,
    discountCode,
    discountValue,
    barcode,
    bill_url,
  } = route?.params;

  const safeAreaInsets = useSafeArea();

  const { profile } = useSelector(state => state.user);
  sendActionEvent(
    'payment_bill',
    {
      price: discountValue ? courseValue - discountValue : courseValue,
      email: profile.email,
    },
    profile.email
  );

  const copyBillBarcode = () => {
    return Clipboard.setString(barcode);
  };

  return (
    <View>
      <View
        style={{ left: 16, paddingTop: safeAreaInsets.top + 12, position: 'absolute', zIndex: 9 }}>
        <TouchableOpacity style={{ display: 'flex' }} onPress={() => navigation.navigate('Aulas')}>
          <Image source={closeGrey} />
        </TouchableOpacity>
      </View>
      <ScrollView style={styles.container} contentContainerStyle={{ flexGrow: 1 }}>
        <ImageBackground source={backgroundBlue} style={commons.flex}>
          <View style={styles.content}>
            <Image style={styles.logo} source={logo} />
            <View style={{ textAlign: 'center' }}>
              <CardCongrats
                title={translate(pt.payment_pending2)}
                textTitleStyle={{ fontSize: 21 }}
                content={translate(pt.when_payment_successful)}
                link={bill_url}
              />
              <View style={styles.inputContainer}>
                <Text style={styles.title}>{translate(pt.bill_barcode)}</Text>
                <Text style={[styles.smallText, { marginBottom: 6 }]}>
                  {translate(pt.copy_bill_barcode)}
                </Text>
                <View
                  style={{
                    flexDirection: 'row',
                    width: 275,
                    alignItems: 'center',
                    alignSelf: 'center',
                    marginTop: 20,
                  }}>
                  <TextInput
                    multiline={true}
                    autoCapitalize="words"
                    editable={false}
                    selectTextOnFocus={false}
                    onChangeText={value => setDiscountCode(value)}
                    value={barcode}
                    keyboardType="default"
                    style={styles.input}
                  />
                  <ButtonBase
                    onPress={() => {
                      copyBillBarcode();
                    }}
                    backgroundColor={colors.aquaMarineTwo}
                    title={translate(pt.copy)}
                    style={styles.copyButton}
                  />
                </View>
              </View>
              <View style={styles.divider} />
            </View>
            <CardSummary
              courseName={courseName}
              courseValue={courseValue}
              discountValue={discountValue}
              discountCode={discountCode}
            />
          </View>
        </ImageBackground>
      </ScrollView>
    </View>
  );
};

export default PaymentCompleteBillScreen;

const styles = StyleSheet.create({
  content: {
    paddingTop: 100,
    paddingBottom: 40,
    paddingHorizontal: 30,
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  logo: {
    marginBottom: 25,
  },
  title: {
    color: colors.blackThree,
    fontWeight: 'bold',
    fontSize: 15,
    marginBottom: 25,
    textAlign: 'center',
  },
  textContainer: {
    marginBottom: 25,
  },
  text: {
    color: colors.greyBlue,
    fontWeight: 'normal',
    fontSize: 14,
  },
  textCenter: {
    textAlign: 'center',
  },
  billUrlButton: {
    backgroundColor: 'transparent',
    marginBottom: 60,
  },
  billUrlButtonText: {
    color: '#00AAFF',
    fontSize: 15,
    fontWeight: 'normal',
    textDecorationLine: 'underline',
  },
  inputContainer: {
    backgroundColor: colors.white,
    paddingHorizontal: 18,
    paddingTop: 26,
    paddingBottom: 40,
    width: 297,
    borderRadius: 4,
  },
  title: {
    fontWeight: 'bold',
    fontSize: 15,
    color: colors.blackThree,
    marginBottom: 6,
  },
  smallText: {
    fontSize: 12,
    color: colors.blackThree,
  },
  input: {
    paddingHorizontal: 14,
    height: 67,
    flex: 1,
    lineHeight: 20,
    borderRadius: 5,
    backgroundColor: colors.white,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: colors.lightPeriwinkle,
  },
  copyButton: {
    height: 67,
    minWidth: 97,
    marginTop: 0,
    width: 97,
    paddingHorizontal: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 4,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  divider: {
    marginVertical: 22,
    height: 1,
    width: '100%',
    backgroundColor: colors.lightPeriwinkle,
  },
  title: {
    fontWeight: 'bold',
    fontSize: 15,
    color: colors.blackThree,
    marginBottom: 6,
  },
  summaryText: {
    fontSize: 14,
    fontWeight: 'normal',
    color: colors.blackThree,
  },
  summaryInfo: {
    fontSize: 14,
    fontWeight: 'bold',
    color: colors.greyBlue,
  },
});
