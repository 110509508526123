import { closeGlobalAlert, setGlobalAlertData } from 'app/store/modules/alert/actions';
import { useDispatch } from 'react-redux';

const useGlobalAlert = () => {
  const dispatch = useDispatch();

  const setAlert = (title, description, options = {}) => {
    dispatch(
      setGlobalAlertData({
        visible: true,
        title,
        description,
        buttonOptions: {
          text: options.text || 'Ok',
          onPress: options.onPress || null,
          secondaryText: options.secondaryText || null,
          secondaryOnPress: options.secondaryOnPress || null,
        },
        additionalComponent: options.additionalComponent || null,
        inverted: options.inverted || false,
        icon: options.icon || null,
        topTag: options.topTag || null,
        topTitle: options.topTitle || null,
        iconContainerStyle: options.iconContainerStyle || {},
      })
    );
  };

  const closeAlert = () => {
    dispatch(closeGlobalAlert());
  };

  return {
    setAlert,
    closeAlert,
  };
};

export default useGlobalAlert;
