import axios from 'axios';
import APP from 'app/configs/app';
import { setupCache } from 'axios-cache-adapter';

const cache = setupCache({
  maxAge: 5 * 1000,
  exclude: { query: false },
});

const api = axios.create({
  baseURL: APP.URL_BASE,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'Accept-Language': 'pt-br',
    Accept: 'application/json',
    'App-Version': APP.VERSION_NUMBER,
  },
  adapter: cache.adapter,
});

export default api;
