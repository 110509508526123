export function signInRequest(payload) {
  return {
    type: '@auth/SIGN_IN_REQUEST',
    payload: payload,
  };
}

export function signInSocialRequest(payload) {
  return {
    type: '@auth/SIGN_IN_SOCIAL_REQUEST',
    payload: payload,
  };
}

export function signInSuccess(token, user) {
  return {
    type: '@auth/SIGN_IN_SUCCESS',
    payload: { token, user },
  };
}

export function updateToken(token) {
  return {
    type: '@auth/UPDATE_TOKEN',
    payload: { token },
  };
}

export function signUpRequest(payload) {
  return {
    type: '@auth/SIGN_UP_REQUEST',
    payload: payload,
  };
}

export function signFailure() {
  return {
    type: '@auth/SIGN_FAILURE',
  };
}

export function signOut() {
  return {
    type: '@auth/SIGN_OUT',
  };
}

export function resetAuth() {
  return {
    type: '@auth/RESET'
  }
}
