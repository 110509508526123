import React, { useState, useRef, useEffect } from 'react';

import { StyleSheet, Text, TouchableWithoutFeedback, View, Image } from 'react-native';
import colors from '../styles/colors';
import textStyles from '../styles/textStyles';
import chevronRight from '../images/chevronRight.png';
import pinAccordion from '../images/pinAccordion.png';
import fonts from '../styles/fonts';

const AccordionPreparation = props => {
  const chevron = useRef();
  const [height, setHeight] = useState(45);
  const [open, setOpen] = useState(true);

  const Animations = {
    rotateDown: {
      0: { rotate: '0deg' },
      1: { rotate: '90deg' },
    },
    rotateUp: {
      0: { rotate: '90deg' },
      1: { rotate: '0deg' },
    },
  };

  useEffect(() => {
    setHeight(open ? 150 : 45);
  }, [open]);

  const Row = () => (
    <View style={styles.row}>
      <Image source={pinAccordion} style={{ marginTop: 5, tintColor: props.color, width: 12, height: 12 }} />
      <Text style={[textStyles.regular14, styles.title]} />
    </View>
  );

  const getRow = () => {
    if (props.arrayItens === undefined) {
      return <Row />;
    }
    return props.arrayItens.map(item => {
      return (
        <View style={styles.row}>
          <Image source={pinAccordion} style={{ marginTop: 5, tintColor: props.color, width: 12, height: 12 }} />
          <Text style={[textStyles.regular18, styles.title]}>{item}</Text>
        </View>
      );
    });
  };

  return (
    <View transition="minHeight" style={[styles.container, { minHeight: height }]}>
      <TouchableWithoutFeedback
        onPress={() => {
          setOpen(!open);
        }}>
        <View style={styles.header}>
          <Text style={textStyles.bold16grey}> {props.title}</Text>
          <Image
            animation={open ? Animations.rotateDown : Animations.rotateUp}
            duration={500}
            ref={chevron}
            style={{ tintColor: props.color }}
            source={chevronRight}
          />
        </View>
      </TouchableWithoutFeedback>
      {open && (
        <View animation="fadeIn" style={styles.content}>
          {getRow()}
        </View>
      )}
    </View>
  );
};

export default AccordionPreparation;

const styles = StyleSheet.create({
  content: {
    borderTopWidth: 1,
    paddingTop: 8,
    marginBottom: 8,
    borderTopColor: colors.veryLightPinkThree,
  },
  title: { marginLeft: 5 },
  row: {
    flexDirection: 'row',
    marginBottom: 8,
    paddingHorizontal: 5,
    alignItems: 'flex-start',
  },
  header: {
    paddingTop: 20,
    paddingBottom: 20,
    fontFamily: fonts.BOLD,
    fontSize: 16,
    color: colors.greyBlue,
    flexDirection: 'row',
    height: 40,
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: colors.paleGrey,
  },
  container: {
    marginBottom: 16,
    borderRadius: 4,
    backgroundColor: colors.paleGrey,
  },
});
