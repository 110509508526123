import api from './api';
import QueryString from 'qs';

const updateEvent = async (event, status = 1) => {
  await api.post(
    'user-ui-event',
    QueryString.stringify({
      ui_event_name: event,
      status,
    })
  );
};

const getEvent = async event => {
  const { data } = await api.post(
    'user-ui-event/filter',
    QueryString.stringify({
      ui_event_name: event,
      status: 1,
    })
  );

  return data;
};

export { getEvent, updateEvent };
