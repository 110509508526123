import React, { useState, useRef, useEffect } from 'react';

import {
  View,
  StyleSheet,
  StatusBar,
  Text,
  TouchableOpacity,
  ScrollView,
  Image,
} from 'react-native';

import * as Animatable from 'react-native-animatable';
import { SafeAreaView } from 'react-native-safe-area-context';
import LottieView from 'lottie-react-native';
import logo_anime from '../../lotties/lottie.json';
import textStyles from '../../styles/textStyles';
import colors from '../../styles/colors';
import commons from '../../styles/commons';
import { translate } from '../../i18n/src/locale';
import pt from '../../i18n/src/locale/pt';
import LoginView from './components/LoginView';
import RegisterView from './components/RegisterView'; // REVISAR
import circles from '../../images/circles.png';
import anim from '../../styles/anim';
import RBSheet from 'react-native-raw-bottom-sheet';
import FacebookButton from './components/FacebookButton';
import fonts from 'app/styles/fonts';
import BusinessRegisterView from 'app/screens/login/components/BusinessRegisterView'; // REVISAR
import arrowRightWater2 from '../../images/arrowRightWater2.png';
import { useNavigation, useRoute } from '@react-navigation/native';

const LoginScreen = () => {
  const method = useRoute().params?.method ?? 'login';
  const from = useRoute().params?.from;
  
  const [showLogin, setShowLogin] = useState(method == 'login');
  const logo = useRef(null);
  const circleBG = useRef(null);
  const central = useRef(null);
  const facebookButton = useRef(null);
  const googleButton = useRef(null);
  const navigation = useNavigation();
  
  function handleBackButtonClick() {
    navigation.navigate('CreateAccount', { noAnim: true });
    return true;
  }

  return (
    <View style={[commons.flex, { backgroundColor: colors.paleGrey }]}>
      <StatusBar backgroundColor="transparent" translucent barStyle="dark-content" />

      <SafeAreaView style={commons.flex}>
        <Animatable.View
          delay={1800}
          useNativeDriver
          ref={circleBG}
          style={{ position: 'absolute', zIndex: 10, top: 45, left: 20 }}
          animation={anim.fadeFromTop}>
          <TouchableOpacity
            onPress={handleBackButtonClick}
            style={{
              backgroundColor: '#00000033',
              width: 48,
              height: 22,
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 15,
            }}>
            <Image
              tintColor={colors.white}
              source={arrowRightWater2}
              style={{
                transform: [{ rotate: '180deg' }],
                width: 22,
                height: 11,
                tintColor: colors.white,
                resizeMode: 'contain',
              }}
            />
          </TouchableOpacity>
        </Animatable.View>
        <Animatable.View
          delay={1800}
          useNativeDriver
          ref={circleBG}
          style={[
            styles.animCircle,
            showLogin ? styles.animCircleLogin : styles.animCircleRegister,
          ]}
          animation={anim.fadeIn}>
          <Image source={circles} style={styles.circleBg} />
        </Animatable.View>
        <ScrollView
          bounces={false}
          style={commons.flex}
          contentContainerStyle={styles.scrollContainer}>
          <Animatable.View delay={1000} useNativeDriver animation={anim.fadeIn}>
            <LottieView ref={logo} source={logo_anime} autoPlay loop={false} style={styles.logo} />
          </Animatable.View>

          <Animatable.View
            ref={central}
            delay={1800}
            style={styles.center}
            useNativeDriver
            animation="fadeInUp">
            {method === 'enterprise' ? (
              <BusinessRegisterView personal={from === 'personal'} />
            ) : showLogin ? (
              <LoginView />
            ) : (
              <RegisterView />
            )}

            {method != 'enterprise' && (
              <View style={styles.view_account}>
                <Text style={[textStyles.semi16, { color: '#444242' }]}>
                  {translate(showLogin ? pt.not_account : pt.already_account)}
                </Text>

                <TouchableOpacity
                  hitSlop={{ bottom: 50, top: 50, left: 50, right: 50 }}
                  onPress={() =>
                    navigation.navigate('Login', { method: 'enterprise', from: 'personal' })
                  }>
                  <Text style={styles.button_now}>
                    {translate(showLogin ? pt.signup_now : pt.login_now)}
                  </Text>
                </TouchableOpacity>
              </View>
            )}
          </Animatable.View>
        </ScrollView>
      </SafeAreaView>
      <RBSheet ref={facebookButton}>
        <FacebookButton />
      </RBSheet>
      <RBSheet ref={googleButton}>
        <FacebookButton />
      </RBSheet>
    </View>
  );
};

const styles = StyleSheet.create({
  button_now: {
    fontFamily: fonts.BOLD,
    fontSize: 16,
    color: colors.aquaMarineTwo,
    zIndex: 2,
    marginLeft: 5,
  },
  scrollContainer: {
    flexGrow: 1,
  },
  view_account: {
    width: '100%',
    marginTop: 30,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    bottom: 30,
  },
  center: {
    flex: 1,
    width: '100%',
    paddingTop: 60,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  logo: {
    width: 180,
    alignSelf: 'center',
  },
  animCircle: {
    position: 'absolute',
    width: 500,
    height: 500,
  },
  animCircleLogin: {
    position: 'absolute',
    width: 500,
    height: 500,
    bottom: -100,
    left: -250,
  },
  animCircleRegister: {
    position: 'absolute',
    width: 500,
    height: 500,
    bottom: -100,
    right: -250,
  },
  circleBg: {
    zIndex: -1,
    width: 500,
    height: 500,
  },
});

export default LoginScreen;
