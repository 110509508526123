import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  View,
  Image,
  StyleSheet,
  Text,
  TouchableOpacity,
  SafeAreaView,
  AppState,
} from 'react-native';
import colors from '../styles/colors';

import arrowRightBlue from '../images/arrowRightBlue.png';
import musicOff from '../images/musicOff.png';
import musicOn from '../images/musicOn.png';
import fullscreenClose from '../images/fullscreenClose.png';
import fullscreenOpen from '../images/fullscreenOpen.png';

import { translate } from 'app/i18n/src/locale';
import pt from 'app/i18n/src/locale/pt';
import CapsuleClockCard from 'app/components/CapsuleClockCard';
import CapsuleLevelNew from 'app/components/CapsuleLevelNew';
import api from 'app/services/api';
import { sendActionEvent } from 'app/services/utils';
import fonts from 'app/styles/fonts';
import { TouchableWithoutFeedback } from 'react-native';
import { Dimensions } from 'react-native';
import Orientation from 'react-native-orientation-locker';
import { StatusBar } from 'react-native';
import ProgressBarNew from 'app/components/ProgressBarNew';
import { ActivityIndicator } from 'react-native';
import ExerciseVideoPlayerNew from 'app/components/exercise-video-player-new';
import { useSelector } from 'react-redux';
import LinearGradient from 'react-native-linear-gradient';
import PlayerControl from 'app/screens/core/activities/components/PlayerControl';
import ProgressBarNew2 from 'app/components/ProgressBarNew2';
import { debounce } from 'lodash';
import Sound from 'react-native-sound';
// import prepareYourself from 'app/assets/prepare_yourself.mp3';
// import prepareCountdown from 'app/assets/prepare_countdown.mp3';
import apiVimeo from 'app/services/apiVimeo';
import { useNavigation } from '@react-navigation/native';

const LessonVideoClassScreenNew = ({ route }) => {
  const {
    playerType = 'activity',
    onComplete,
    startFrom = 0,
    colorBase = colors.aquaMarineTwo,
    playlist,
    exercises,
    subcategory,
    onFinish,
  } = route?.params;

  const navigation = useNavigation();
  const [url, setUrl] = useState(null);
  const [playList, setPlayList] = useState([]);
  const [exercisesList, setExercisesList] = useState(exercises);
  const [playListCurrentIndex, setPlayListCurrentIndex] = useState(0);
  const [lastIndex, setLastIndex] = useState(false);
  const [playListTotalLength, setPlayListTotalLength] = useState([]);
  const [completeVideos, setCompleteVideos] = useState(0);
  const { profile } = useSelector(state => state.user);
  const [hasYoga] = useState(false);
  const [isVideoLogin, setIsVideoLogin] = useState(false);
  const [isRest, setIsRest] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [restDuration, setRestDuration] = useState(0);
  const [isContentLoading] = useState(false);
  const [itemPlayListSelected, setItemPlayListSelected] = useState({});
  const [, setRestAndExerciseVideo] = useState([]);

  const playerRef = useRef([]);
  const [countdown, setCountdown] = useState(false);
  const [state, setState] = useState({
    fullscreen: false,
    play: true,
    currentTime: 0,
    duration: 0,
    hasAudio: subcategory === 'Fitness' || subcategory === 'Pilates' ? false : true,
    repeat: false,
    videoDuration: 0,
    showControls: true,
  });

  let prepare_yourself = useRef();
  let prepare_countdown = useRef();

  useEffect(() => {
    Sound.setCategory('Playback', true);

    return () => {
      if (prepare_yourself.current) {
        prepare_yourself.current.release();
      }
      if (prepare_countdown.current) {
        prepare_countdown.current.release();
      }
    };
  }, []);

  const playPrepareYourself = () => {
    prepare_yourself.current = new Sound('app/assets/prepare_countdown.mp3', error => {
      if (error) {
        return;
      }
      prepare_yourself.current.play(() => {
        prepare_yourself.current.release();
      });
    });
  };
  const stopPrepareYourself = () => {
    if (prepare_yourself.current) {
      prepare_yourself.current.stop();
    }
  };
  const playPrepareCountdown = () => {
    setCountdown(true);
    prepare_countdown.current = new Sound('app/assets/prepare_countdown.mp3', error => {
      if (error) {
        return;
      }
      prepare_countdown.current.play(() => {
        prepare_countdown.current.release();
      });
    });
  };
  const stopPrepareCountdown = () => {
    if (prepare_countdown.current) {
      prepare_countdown.current.stop();
    }
  };

  useEffect(() => {
    setExercisesList(exercises);
  }, [exercises]);

  useEffect(() => {
    StatusBar.setHidden(true);
    // if (playerType === 'course') {
    //   Orientation.lockToLandscape();
    //   // Orientation.unlockAllOrientations();
    //   // Orientation.addOrientationListener(handleOrientation);
    //   setState(prev => ({ ...prev, fullscreen: true }));
    // } else {
      Orientation.unlockAllOrientations();
      Orientation.addOrientationListener(handleOrientation);
    // }

    return () => {
      StatusBar.setHidden(false);
      if (playerType !== 'course') {
        Orientation.removeOrientationListener(handleOrientation);
      }
      Orientation.lockToPortrait();
    };
  }, []);

  useEffect(() => {
    const subs = AppState.addEventListener('change', handleAppState);
    return () => {
      subs.remove();
    };
  }, []);

  const handleAppState = nextAppState => {
    if (nextAppState === 'active') {
      setState(prev => ({ ...prev, play: true }));
    } else {
      setState(prev => ({ ...prev, play: false }));
    }
  };

  useEffect(() => {
    const playlistFinal = playlist
      .map(item => {
        if (item.type === 'circuit') {
          let circuitExercises = [];
          for (let i = 0; i < item.series; i++) {
            circuitExercises.push(item.exercises);
          }
          return circuitExercises.flat();
        }
        if (item.series > 1) {
          let exerciseSeries = [];
          for (let i = 0; i < item.series; i++) {
            exerciseSeries.push(item);
          }
          return exerciseSeries.flat();
        }
        return item;
      })
      .flat();
    setPlayList(playlistFinal);
    setPlayListTotalLength(playlistFinal.length);
  }, []);

  useEffect(() => {
    getItemPlayListByIndex(startFrom);
  }, [playList]);

  const handleOrientation = () => {
    Orientation.getOrientation(deviceOrientation => {
      deviceOrientation === 'LANDSCAPE-LEFT' || deviceOrientation === 'LANDSCAPE-RIGHT'
        ? setState(s => ({ ...s, fullscreen: true }))
        : setState(s => ({ ...s, fullscreen: false }));
    });
  };

  const requestVideoNew = async idVimeo => {
    setIsVideoLogin(true);
    try {
      const videoId = idVimeo[idVimeo.length - 1];
      const { data } = await apiVimeo.get('videos/' + videoId);

      const video360 = data.download.find(v => v.rendition === '360p');
      setUrl(video360.link);
      setState(prevState => ({
        ...prevState,
        currentTime: 0,
        duration: data.duration,
      }));

      playerRef.current.video.seek(0, 50);
    } catch (error) {
      alert(error.message);
    }
    setIsVideoLogin(false);
  };

  const showControls = () => {
    state.showControls
      ? setState({ ...state, showControls: false })
      : setState({ ...state, showControls: true });
  };

  const getDurationRest = () => {
    // const hasTypeRest = restAndExerciseVideo.some(restExercise => {
    //   return restExercise.type === 'rest';
    // });
    if (!playList[playListCurrentIndex - 1]) {
      return 10;
    }
    if (playList[playListCurrentIndex - 1].rest_time < 10) {
      return 10;
    }
    return playList[playListCurrentIndex - 1].rest_time;
  };

  useEffect(() => {
    if (playListCurrentIndex === playListTotalLength - 1) {
      setLastIndex(true);
    } else {
      setLastIndex(false);
    }

    if (isRest) {
      if (Math.floor(restDuration) <= 3 && Math.floor(restDuration) > 0 && countdown === false) {
        playPrepareCountdown();
      }

      if (Math.floor(restDuration) <= 0) {
        setIsRest(false);
        setCountdown(false);
        setState(prevState => ({ ...prevState, play: true, repeat: false }));
        setIsMuted(false);
        playerRef.current.video.seek(0, 50);
      }
    }
  }, [playListCurrentIndex, restDuration]);

  let restInterval = useRef();

  const decreaseRest = () => setRestDuration(prev => prev - 1);

  useEffect(() => {
    if (isRest) {
      setRestDuration(getDurationRest());
      restInterval.current = setInterval(decreaseRest, 1000);
    }

    return () => clearInterval(restInterval.current);
  }, [isRest, playListCurrentIndex]);

  const onLoadEnd = data => {
    const restDurationOnLoad = getDurationRest();
    setState(prevState => ({
      ...prevState,
      duration: restDurationOnLoad > 0 ? restDurationOnLoad : data.duration,
      videoDuration: data.duration,
      play: true,
      currentTime: data.currentTime,
    }));
    if (playerType === 'meditation' || playerType === 'course') {
      setIsRest(false);
    } else {
      setIsRest(true);
      setState(prevState => ({ ...prevState, play: true, repeat: true }));
      playPrepareYourself();
      // prepare_yourself.play(()=>{
      //   prepare_yourself.stop();
      // })
      setIsMuted(true);
    }
  };

  const onProgress = async data => {
    const percentage =
      state.videoDuration === 0 ? 0 : (data.currentTime * 100) / state.videoDuration;
    if (
      // eslint-disable-next-line eqeqeq
      !exercisesList.find(e => e.id == playList[playListCurrentIndex].id).completed &&
      playerType === 'course' &&
      percentage > 90
    ) {
      try {
        await api.post('masterclass/complete/' + playList[playListCurrentIndex].id);
        setExercisesList(prev => {
          let _prev = [...prev];
          // eslint-disable-next-line eqeqeq
          const eId = _prev.findIndex(p => p.id == playList[playListCurrentIndex].id);
          _prev[eId].completed = true;
          return _prev;
        });
        onComplete(playList[playListCurrentIndex].id);
      } catch (e) {
        console.log(e);
      }
    }

    setState(prevState => ({
      ...prevState,
      currentTime: data.currentTime,
    }));
  };

  const onEnd = () => {
    if (!isRest) {
      if (playList.length === playListCurrentIndex + 1) {
        sendActionEvent(
          'watch_video_in_class',
          {
            completeVideos: completeVideos,
            lengthClass: playListTotalLength,
            email: profile.email,
          },
          profile.email
        );
        navigation.goBack();
        onFinish && onFinish();
      } else {
        try {
          setCompleteVideos(prev => prev + 1);
          setRestAndExerciseVideo([]);
          getItemPlayListByIndex(playListCurrentIndex + 1);
        } catch (e) {
          console.log(e);
        }
      }
    }
  };

  const handleFullscreen = () => {
    Orientation.getOrientation(o => {
      if (o !== 'PORTRAIT') {
        Orientation.lockToPortrait();
        setTimeout(() => {
          Orientation.unlockAllOrientations();
        }, 2000);
      } else {
        Orientation.lockToLandscapeLeft();
      }
    });
  };

  const handleMute = () => {
    if (state.hasAudio) {
      setIsMuted(prevState => !prevState);
    }
  };

  const handlePlayPause = () => {
    if (state.play) {
      if (prepare_countdown.current) {
        prepare_countdown.current.pause();
      }
      setState({ ...state, play: false, showControls: true });
      clearInterval(restInterval.current);
      return;
    }
    if (prepare_countdown.current) {
      prepare_countdown.current.play(() => prepare_countdown.current.release());
    }
    setState({ ...state, play: true, endVideo: false, showControls: false });
    restInterval.current = setInterval(decreaseRest, 1000);
  };

  const debounceRequestVideo = useCallback(
    debounce(selected => {
      if (!selected) {
        return;
      }
      // requestVideo(exercises.find(x=>x.id === selected.id).media.path.split('/'));
      requestVideoNew(exercises.find(x => x.id === selected.id).media.path.split('/'));
    }, 300),
    []
  );

  const getItemPlayListByIndex = useCallback(
    (indexClicked = 0) => {
      stopPrepareYourself();
      stopPrepareCountdown();
      if (!playList.length) {
        return;
      }
      if (indexClicked <= playList.length - 1) {
        setState({ ...state, play: false, showControls: true });
        setPlayListCurrentIndex(indexClicked);
        const itemPlayList = playList[indexClicked];
        const exercise = exercises.find(x => x.id === itemPlayList.id);
        setItemPlayListSelected(exercise);
        if (indexClicked === playList.length - 1) {
          setLastIndex(true);
        }
        debounceRequestVideo(exercise);
      } else {
        sendActionEvent(
          'watch_video_in_class',
          {
            completeVideos: completeVideos,
            lengthClass: playListTotalLength,
            email: profile.email,
          },
          profile.email
        );
        handlePressBack();
      }
    },
    [playList]
  );

  const getNextItem = () => {
    if (!playList.length || !playList[playListCurrentIndex + 1]) {
      return null;
    }
    const exercise = exercises.find(x => x.id === playList[playListCurrentIndex + 1].id);
    return exercise;
  };

  const variateVideoTime = seconds => {
    const videoDuration = state.videoDuration.duration;
    const newTime = state.currentTime + seconds;

    if (newTime <= 0) {
      playerRef.current.video.seek(0, 50);
    } else if (newTime >= videoDuration) {
      playerRef.current.video.seek(0, 50);
      handlePlayPause();
    } else {
      playerRef.current.video.seek(newTime, 50);
      setState(prevState => ({
        ...prevState,
        currentTime: newTime,
      }));
    }
  };

  const handlePressBack = () => {
    Orientation.lockToPortrait();
    navigation.goBack();
  };

  return (
    <>
      <SafeAreaView
        style={{
          flex: 0,
          backgroundColor: state.fullscreen ? colors.black : colors.darkPro,
        }}
      />
      <View
        style={{
          flex: 1,
          backgroundColor: state.fullscreen ? colors.black : colors.darkPro,
        }}>
        <View
          style={{ flex: 1, backgroundColor: state.fullscreen ? colors.black : colors.paleGrey }}>
          <TouchableWithoutFeedback onPress={showControls}>
            <View>
              <ExerciseVideoPlayerNew
                playerRef={playerRef}
                playerType={playerType}
                videoUrl={url}
                onLoadEnd={onLoadEnd}
                onProgress={onProgress}
                onEnd={onEnd}
                muted={isMuted}
                handleMuted={handleMute}
                colorBase={colorBase}
                isVideoLogin={isVideoLogin}
                handleFullscreen={handleFullscreen}
                handlePlayPause={handlePlayPause}
                controls={false}
                state={state}
                handlePressBack={handlePressBack}
                difficulty={itemPlayListSelected?.difficulty}
                classDuration={state.videoDuration}
                inRest={isRest}
                restDuration={restDuration}
                playerControlsComponent={
                  <>
                    <PlayerControl
                      isPlaying={state.play}
                      buttonsColor={colors.white}
                      containerStyle={{ flex: 0 }}
                      currentIndex={playListCurrentIndex}
                      lastIndex={playList.length}
                      handleAdvanceTime={() => variateVideoTime(15)}
                      handleNext={() => {
                        getItemPlayListByIndex(playListCurrentIndex + 1);
                      }}
                      handleBack={() => {
                        getItemPlayListByIndex(playListCurrentIndex - 1);
                      }}
                      handlePlayPause={handlePlayPause}
                      handleRetreatTime={() => variateVideoTime(-15)}
                    />
                    <Text style={{ fontFamily: fonts.BOLD, fontSize: 18, color: colors.white }}>
                      {itemPlayListSelected?.name}
                    </Text>

                    <ProgressBarNew2
                      currentTime={state.currentTime}
                      duration={state.videoDuration}
                      showTrack={hasYoga}
                      onSlideComplete={current => {
                        playerRef.current.video.seek(current, 50);
                      }}
                      containerStyle={{ position: 'absolute', bottom: 20 }}
                    />
                    <TouchableOpacity
                      onPress={handleMute}
                      style={{ position: 'absolute', bottom: 20, left: 20 }}>
                      <Image
                        style={styles.imageMusicOff}
                        source={!state.hasAudio ? musicOff : isMuted ? musicOff : musicOn}
                      />
                    </TouchableOpacity>
                    {playerType !== 'course' && (
                      <TouchableOpacity
                        onPress={handleFullscreen}
                        style={{ position: 'absolute', bottom: 20, right: 20 }}>
                        <Image
                          style={styles.imageFullscreen}
                          source={state.fullscreen ? fullscreenClose : fullscreenOpen}
                        />
                      </TouchableOpacity>
                    )}
                  </>
                }
              />
            </View>
          </TouchableWithoutFeedback>
          {isContentLoading ? (
            <View
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                flex: 1,
              }}>
              <ActivityIndicator size="large" color={colors.aquaMarine} />
            </View>
          ) : (
            <ProgressBarNew
              currentTime={state.currentTime}
              duration={state.videoDuration}
              colorBase={colorBase}
              onSlidingComplete={v => {
                playerRef.current.video.seek(v, 50);
              }}
              onSlideComplete={current => {
                playerRef.current.video.seek(current, 50);
              }}
            />
          )}
          {!state.fullscreen && !isContentLoading && (
            <View style={{ flex: 1 }}>
              {playerType !== 'course' && (
                <View style={styles.containerVideoInfo}>
                  <View>
                    <Text style={styles.titleVideo}>{itemPlayListSelected?.name}</Text>
                  </View>
                  <View style={styles.containerCapsules}>
                    <CapsuleClockCard
                      duration={
                        // eslint-disable-next-line eqeqeq
                        itemPlayListSelected?.media?.duration == 0
                          ? state.videoDuration
                          : itemPlayListSelected?.media?.duration
                      }
                    />
                    <CapsuleLevelNew
                      style={{ marginLeft: 10 }}
                      difficulty={itemPlayListSelected?.difficulty}
                    />
                  </View>
                </View>
              )}

              {playerType != 'course' && <>
                <View style={styles.containerControl}>
                  <PlayerControl
                    isPlaying={state.play}
                    buttonsColor={colorBase}
                    currentIndex={playListCurrentIndex}
                    lastIndex={playListTotalLength - 1}
                    handleAdvanceTime={() => variateVideoTime(15)}
                    handleNext={() => {
                      getItemPlayListByIndex(playListCurrentIndex + 1);
                    }}
                    handleBack={() => {
                      getItemPlayListByIndex(playListCurrentIndex - 1);
                    }}
                    handlePlayPause={handlePlayPause}
                    handleRetreatTime={() => variateVideoTime(-15)}
                  />
                </View>

                <LinearGradient
                  style={styles.containerNextVideo}
                  angle={98}
                  useAngle
                  colors={!lastIndex ? ['#3C415A', colors.greyBlue] : [colorBase, colorBase]}>
                  <TouchableOpacity
                    onPress={() => {
                      if (!lastIndex) {
                        getItemPlayListByIndex(playListCurrentIndex + 1);
                      } else {
                        onFinish && onFinish();
                        navigation.goBack();
                      }
                    }}
                    style={{
                      justifyContent: 'space-between',
                      flexDirection: 'row',
                      alignItems: 'center',
                      paddingRight: 10,
                    }}>
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}>
                      {!lastIndex ? (
                        <>
                          <Image
                            source={{ uri: getNextItem()?.thumbnail?.path }}
                            style={{
                              zIndex: 15,
                              width: 70,
                              height: 40,
                              resizeMode: 'cover',
                              borderRadius: 2,
                            }}
                          />
                          <View style={{ marginLeft: 10, flex: 1 }}>
                            <Text style={styles.textNextVideo}>{translate(pt.next_video)}</Text>
                            <Text style={styles.textNextVideo} numberOfLines={1} ellipsizeMode="tail">
                              {getNextItem()?.name}
                            </Text>
                          </View>
                        </>
                      ) : (
                        <Text style={styles.textNextVideo}>{translate(pt.finish_class)}</Text>
                      )}
                    </View>
                    <Image source={arrowRightBlue} style={styles.iconArrowRight} />
                  </TouchableOpacity>
                </LinearGradient>
              </>}
            </View>
          )}
        </View>
      </View>
    </>
  );
};

export default LessonVideoClassScreenNew;

const styles = StyleSheet.create({
  containerControl: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-end',
    paddingBottom: 120,
    flex: 1,
  },
  iconArrowRight: {
    height: 16,
    width: 16,
    tintColor: colors.white,
  },
  textNextVideo: {
    fontSize: 14,
    fontFamily: fonts.BOLD,
    color: colors.white,
  },
  containerNextVideo: {
    padding: 23,
    height: 80,
    justifyContent: 'center',
    borderTopRightRadius: 30,
    borderTopLeftRadius: 30,
    width: Dimensions.get('screen').width,
  },
  containerVideoInfo: {
    paddingHorizontal: 24,
    paddingBottom: 45,
    paddingTop: 20,
    justifyContent: 'center',
    borderBottomRightRadius: 30,
    borderBottomLeftRadius: 30,
    backgroundColor: colors.white,
  },
  spaceBetween: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  titleVideo: {
    fontFamily: fonts.BOLD,
    fontSize: 18,
    color: colors.greyBlue,
  },
  containerCapsules: {
    marginTop: 20,
    flexDirection: 'row',
  },
  rowNext: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: 14,
    marginTop: 6,
  },
  infoHolder: {
    backgroundColor: colors.blackAlpha40,
    width: 40,
    borderRadius: 20,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
  },
  chip: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 14,
    width: 96,
    height: 28,
    backgroundColor: colors.blackAlpha40,
  },
  buttonFinish: {},
  fullscreenButton: {
    flex: 1,
    flexDirection: 'row',
    alignSelf: 'flex-end',
    alignItems: 'center',
    paddingRight: 10,
  },
  video: {
    height: Dimensions.get('screen').width * (9 / 16),
    width: Dimensions.get('screen').width,
    backgroundColor: 'black',
  },
  fullscreenVideo: {
    height: Dimensions.get('window').width,
    width: Dimensions.get('window').height,
    backgroundColor: 'black',
  },
  labelChip: {
    fontFamily: fonts.BOLD,
    color: colors.white,
  },
  arrowContainer: {
    backgroundColor: colors.blackAlpha40,
    alignItems: 'center',
    justifyContent: 'center',
    width: 28,
    height: 28,
    borderRadius: 28 / 2,
  },
  controlOverlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    zIndex: 99,
    right: 0,
    backgroundColor: '#000000c4',
    justifyContent: 'space-between',
  },
  add: { flex: 1, flexDirection: 'column', alignItems: 'flex-start' },
  content: { paddingHorizontal: 14, marginBottom: 14 },
  capsuleHolder: {
    flexDirection: 'row',
    width: 170,
    marginBottom: 20,
    justifyContent: 'space-between',
    marginLeft: 16,
  },
  capsule: {
    backgroundColor: colors.whiteTwo,
    borderColor: '#eee',
    borderWidth: StyleSheet.hairlineWidth,
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 6,
    shadowOpacity: 0.2,
  },
  insideContainerRow: {
    marginTop: 10,
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 0,
  },
  imageFullscreen: { width: 20, height: 20, tintColor: colors.white },
  imageMusicOff: {
    width: 25,
    height: 25,
    resizeMode: 'contain',
    tintColor: colors.white,
  },
});
