import React from 'react';

import { View, StyleSheet, Image, Text } from 'react-native';
import colors from '../../../../styles/colors';
import agenda from '../../../../images/agenda.png';
import ButtonBase from '../../../../components/ButtonBase';
import { translate } from '../../../../i18n/src/locale';
import pt from '../../../../i18n/src/locale/pt';
import textStyles from '../../../../styles/textStyles';

const ModalAgenda = ({ onClose }) => {
  return (
    <View style={styles.container}>
      <Image source={agenda} />
      <View style={{ paddingHorizontal: 24 }}>
        <Text style={{ ...textStyles.bold20, textAlign: 'center' }}>
          {translate(pt.your_agenda)}
        </Text>
        <Text style={{ ...textStyles.regular14, textAlign: 'center', marginTop: 10 }}>
          {translate(pt.your_agenda_content)}
        </Text>
      </View>
      <ButtonBase onPress={onClose} title={translate(pt.got)} />
    </View>
  );
};

export default ModalAgenda;

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    width: 322,
    height: 450,
    borderRadius: 10,
    backgroundColor: colors.white,
  },
});
