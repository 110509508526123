import React from 'react';

import { StyleSheet, Text, Image } from 'react-native';
import fonts from '../styles/fonts';
import colors from '../styles/colors';
import level1 from '../images/nivelFCil.png';
import level2 from '../images/level2.png';
import level3 from '../images/nivelAvanAdo.png';
import { translate } from '../i18n/src/locale';
import pt from '../i18n/src/locale/pt';
import ShadowView from '@liuyunjs/react-native-simple-shadow-view';

const CapsuleLevel = ({ style, difficulty }) => {
  const validLevel = () => {
    if (difficulty === 1) {
      return level1;
    }
    if (difficulty === 2) {
      return level2;
    }
    return level3;
  };
  return (
    <ShadowView style={[styles.capsule, style]}>
      <Text style={styles.capsuleText}>{translate(pt.level)}</Text>
      <Image style={styles.image} source={validLevel()} />
    </ShadowView>
  );
};

export default CapsuleLevel;

const styles = StyleSheet.create({
  capsuleText: {
    fontFamily: fonts.REGULAR,
    fontSize: 14,
    opacity: 0.9,
    color: colors.brownishGreyTwo,
  },
  capsule: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 12,
    height: 29,
    borderRadius: 18.5,
    backgroundColor: colors.whiteTwo,
  },
  image: {
    width: 12,
    height: 8,
  }
});
