import React, { useState, useEffect, useRef } from 'react';
import { View, Dimensions, Text, StyleSheet, ScrollView, Platform, StatusBar } from 'react-native';
import pt from 'app/i18n/src/locale/pt';
import { translate } from 'app/i18n/src/locale';
import LineChartWithTooltips from 'app/components/LineChartWithTooltips';
import Spacer from 'app/components/Spacer';
import colors from 'app/styles/colors';
import moment from 'moment';
import fonts from 'app/styles/fonts';
import WaterControl from 'app/screens/core/hydration/components/WaterControl';
import { useSelector } from 'react-redux';
import MonthSlider from 'app/screens/core/hydration/components/MonthSlider';
import RBSheet from 'react-native-raw-bottom-sheet';
import WaterReference from 'app/screens/core/hydration/components/WaterReference';

const MonthlyInternalScreen = () => {
  const [arrayWaterIntakesMonthly, setArrayWaterIntakesMonthly] = useState([]);
  const [chartData, setChartData] = useState([]);
  const { profile } = useSelector(state => state.user);
  const refRBSheetTimer = useRef();

  useEffect(() => {
    getMonthlyHistory();
  }, [profile.monthly_history]);

  const formatWaterInstakesValue = waterIntakes => {
    if (profile.sum_of_the_month === 0) {
      const waterInstakesFormated = parseFloat(waterIntakes);
      return waterInstakesFormated.toString().replace('.', ',');
    }
    const waterInstakesFormated = parseFloat(waterIntakes / 1000);
    return waterInstakesFormated.toString().replace('.', ',');
  };

  const getMonthlyHistory = () => {
    const monthly_history = profile?.monthly_history;
    setArrayWaterIntakesMonthly(monthly_history);
  };

  useEffect(() => {
    const temp = arrayWaterIntakesMonthly?.map(item => ({
      month: moment(item?.data).format('MM/YYYY'),
      monthName: moment(item?.data).format('MMM').toUpperCase(),
      ...item,
    }));

    if (temp) {
      const result = sumWeekResults(temp);
      setChartData(result);
    }
  }, [arrayWaterIntakesMonthly]);

  const sumWeekResults = week =>
    Object.values(
      week.reduce((accumulator, { month, ml, monthName }) => {
        accumulator[month] = accumulator[month] || { month, ml: 0 };
        accumulator[month].monthName = monthName;
        accumulator[month].ml = String(Number(accumulator[month].ml) + Number(ml));
        return accumulator;
      }, {})
    );

  const closeReferenceModal = () => {
    if (Platform.OS === 'android') {
      StatusBar.setBackgroundColor('#4969d8');
    }
    refRBSheetTimer.current.close();
  };

  const onClose = () => {
    if (Platform.OS === 'android') {
      StatusBar.setBackgroundColor(colors.transparent);
    }
  };

  return (
    <View style={styles.container}>
      <MonthSlider />
      <ScrollView contentContainerStyle={{ paddingBottom: 20 }}>
        <Spacer value={47} />
        <View style={styles.containerCenter}>
          <Text style={styles.ingestedQuantity}>{translate(pt.ingested_quantity)}</Text>
          <Spacer value={4} />
        </View>
        <Spacer value={34} />
        <Text style={styles.qntWater}>{`${formatWaterInstakesValue(
          profile.sum_of_the_month
        )} L`}</Text>
        <Text style={styles.ingestedToday}>ingeridos este mês</Text>
        <Spacer value={36} />
        {arrayWaterIntakesMonthly && (
          <LineChartWithTooltips
            measure="L"
            data={{
              labels: chartData.map(item => item.monthName),
              itens: chartData,
              datasets: [
                {
                  data: chartData.map(item => Number(item?.ml)),
                  color: (opacity = 0) => `rgba(76, 72, 255, ${opacity})`,
                },
              ],
            }}
            width={Dimensions.get('window').width} // from react-native
            height={200}
            bezier
            withShadow={false}
            withInnerLines
            yLabelsOffset={20}
            fromZero
            formatYLabel={item => `${formatWaterInstakesValue(item)} L`}
            chartConfig={{
              backgroundColor: '#e26a00',
              backgroundGradientFrom: colors.paleGrey,
              backgroundGradientTo: colors.paleGrey,

              color: (opacity = 1) => `rgba(148, 150, 157, ${opacity})`,
              labelColor: (opacity = 1) => `rgba(148, 150, 157, ${opacity})`,
            }}
          />
        )}
        <Spacer value={37} />
        <WaterControl />
      </ScrollView>
      <RBSheet onClose={onClose} ref={refRBSheetTimer} animationType="none" duration={200}>
        <WaterReference doClose={closeReferenceModal} reference={profile.hidratation_goal} />
      </RBSheet>
    </View>
  );
};

export default MonthlyInternalScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.paleGrey,
  },
  containerCenter: {
    alignItems: 'center',
  },
  ingestedQuantity: {
    fontFamily: fonts.MEDIUM,
    fontSize: 16,
    color: colors.charcoalGreyTwo,
  },
  qntWater: {
    fontFamily: fonts.BOLD,
    fontSize: 26,
    textAlign: 'center',
    color: colors.charcoalGreyTwo,
  },
  ingestedToday: {
    fontFamily: fonts.LIGHT,
    marginTop: -6,
    fontSize: 12,
    textAlign: 'center',
    color: colors.blueyGrey,
  },
});
