import React, { useRef, useState } from 'react';

import { View, StyleSheet, TouchableOpacity, Image } from 'react-native';
import { Menu, MenuItem, MenuDivider } from 'react-native-enhanced-popup-menu';
import MenuItemContent from './MenuItemContent';
import { translate } from '../../../../i18n/src/locale';
import pt from '../../../../i18n/src/locale/pt';

import commons from '../../../../styles/commons';
import infoMenu from '../../../../images/infoMenu.png';
import calendarMenu from '../../../../images/calendarMenu.png';
import garbageMenu from '../../../../images/garbageMenu.png';
import moreVert from '../../../../images/moreVert.png';
import RBSheet from 'react-native-raw-bottom-sheet';
import Modal from 'react-native-modal';
import BottomSheetChangeDate from '../../../core/calendar/components/BottomSheetChangeDate';
import ModalRemove from '../../../core/calendar/components/ModalRemove';
import { useNavigation } from '@react-navigation/native';

const MoreContextMenu = ({ item, type = 2, onClose }) => {
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const menuRef = useRef();
  const buttonRef = useRef();
  const refRBSheet = useRef();
  const navigation = useNavigation();

  const hideMenu = () => menuRef.current.hide();
  const showMenu = () => menuRef.current.show(buttonRef.current);
  const openBottom = () => {
    setOpen(true);
    hideMenu();
  };
  const removeItem = () => {
    hideMenu();
    setTimeout(() => setModal(true), 1000);
  };

  const navigateToDetailsScreen = () => {
    if (item.dish_id) {
      return navigation.navigate('PlateDetail',{ plateId: item.dish_id });
    }

    if (item.meal_id) {
      return navigation.navigate('MealDetail', { mealId: item.meal_id, trailId: item.trail_id || null });
    }

    if (!!item.class_id && type === 2) {
      return navigation.navigate('LessonActivity', { lessonId: item.class_id, trailId: item.trail_id || null });
    }

    if (!!item.class_id && type === 3) {
      return navigation.navigate('LessonMeditation', { classId: item.class_id, trailId: item.trail_id || null });
    }
  };

  return (
    <View style={styles.more}>
      <RBSheet height={200} ref={refRBSheet}>
        <BottomSheetChangeDate
          item={item}
          onClose={() => {
            refRBSheet.current.close();
          }}
        />
      </RBSheet>
      <TouchableOpacity onPress={showMenu} ref={buttonRef} hitSlop={commons.hitSlop}>
        <Image source={moreVert} style={{ width: 18, height: 18, marginLeft: 240 }} />
      </TouchableOpacity>

      <Modal animationInTiming={600} isVisible={modal}>
        <ModalRemove
          item={item}
          onClose={() => {
            setModal(false);
            onClose();
          }}
        />
      </Modal>

      <View style={{ marginRight: 240 }}>
        <Menu
          ref={menuRef}
          style={{ marginRight: 100 }}
          onHidden={() => {
            if (open) {
              setOpen(false);
              refRBSheet.current.open();
            }
          }}>
          <MenuItem
            onPress={() => {
              hideMenu();
              navigateToDetailsScreen();
            }}>
            <MenuItemContent title={translate(pt.see_details)} icon={infoMenu} />
          </MenuItem>
          <MenuDivider />
          <MenuItem onPress={openBottom}>
            <MenuItemContent title={translate(pt.reschedule)} icon={calendarMenu} />
          </MenuItem>
          <MenuDivider />
          <MenuItem onPress={removeItem}>
            <MenuItemContent title={translate(pt.remove)} icon={garbageMenu} />
          </MenuItem>
        </Menu>
      </View>
    </View>
  );
};

export default MoreContextMenu;

const styles = StyleSheet.create({
  more: { position: 'absolute', right: 0, top: 10 },
});
