import React from 'react';

import { View, Text, TextInput, StyleSheet, ScrollView } from 'react-native';
import HeaderGrey from '../../components/HeaderGrey';
import commons from '../../styles/commons';
import { translate } from '../../i18n/src/locale';
import pt from '../../i18n/src/locale/pt';
import colors from '../../styles/colors';
import ButtonBase from '../../components/ButtonBase';
import textStyles from '../../styles/textStyles';
import { useState } from 'react';
import { errorControl, flashInfo } from 'app/services/utils';
import api from 'app/services/api';
import QueryString from 'qs';
import { useNavigation } from '@react-navigation/native';

const SuggestionScreen = () => {
  const [suggestionMessage, setSuggestionMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const navigation = useNavigation();

  const validSuggestionMessage = async () => {
    if (suggestionMessage.trim() === '') {
      flashInfo('Insira uma sugestão');
      return;
    }
    await requestSendSuggestion();
  };

  const requestSendSuggestion = async () => {
    setIsLoading(true);
    try {
      const { data } = await api.post(
        'screens/user-suggestion',
        QueryString.stringify({
          message: suggestionMessage,
        })
      );
      flashInfo(data);
      navigation.goBack();
    } catch (error) {
      showMessage(errorControl(error));
    }
    setIsLoading(false);
  };

  return (
    <View style={styles.screen}>
      <HeaderGrey title={translate(pt.contact_us)} />
      <ScrollView style={commons.flex} contentContainerStyle={styles.scroll}>
        <Text style={[textStyles.subtitle]}>{translate(pt.suggestion_title)}</Text>

        <TextInput
          multiline
          placeholder={translate(pt.suggestion_input)}
          style={styles.input}
          onChangeText={text => setSuggestionMessage(text)}
        />
        <ButtonBase
          loading={isLoading}
          onPress={validSuggestionMessage}
          style={styles.button}
          backgroundColor={colors.aquaMarineTwo}
          title={translate(pt.send)}
        />
      </ScrollView>
    </View>
  );
};

export default SuggestionScreen;

const styles = StyleSheet.create({
  button: { marginTop: 40 },
  scroll: { padding: 22 },
  input: {
    ...commons.input,
    textAlign: 'left',
    height: 100,
    marginTop: 20,
    textAlignVertical: 'top',
    justifyContent: 'flex-start',
    paddingHorizontal: 8,
    alignSelf: 'stretch',
  },
  screen: { height: '100vh', backgroundColor: colors.paleGrey },
});
