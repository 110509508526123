import React from 'react';

import { View, Image, StyleSheet, TouchableOpacity } from 'react-native';
import chevronLeft from '../images/chevronLeft.png';
import colors from '../styles/colors';

import commons from '../styles/commons';
import settings from '../images/settings.png';
import { useSafeArea } from 'react-native-safe-area-context';
import { useNavigation } from '@react-navigation/native';

const HeaderProfile = ({ onPress, onPressSetting }) => {

  const navigation = useNavigation();

  const safeAreaInsets = useSafeArea();
  return (
    <View style={[styles.header, { marginTop: safeAreaInsets.top + 5 }]}>
      <View style={styles.container}>
        <TouchableOpacity hitSlop={commons.hitSlop} onPress={onPress || navigation.goBack}>
          <Image style={styles.arrowLeft} source={chevronLeft} />
        </TouchableOpacity>
        {/* <TouchableOpacity hitSlop={commons.hitSlop} onPress={onPressSetting || Actions.settings}>
          <Image source={settings} />
        </TouchableOpacity> */}
      </View>
    </View>
  );
};

export default HeaderProfile;

const styles = StyleSheet.create({
  header: { paddingHorizontal: 16, zIndex: 10 },
  arrowLeft: { tintColor: colors.blackTwo, marginRight: 16, width: 28, height: 28 },
  container: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
  },
});
