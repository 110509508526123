import React, { useEffect, useRef, useState } from 'react';
import {
  Dimensions,
  StyleSheet,
  Text,
  TextInput,
  View,
  Image,
  TouchableOpacity,
} from 'react-native';

import textStyles from 'app/styles/textStyles';
import commons from 'app/styles/commons';
import colors from 'app/styles/colors';

const { width } = Dimensions.get('screen');
const cardWidth = (width - 48) / 2;

const OptionSelect = props => {
  return (
    <TouchableOpacity
      onPress={() => props.onPress && props.onPress()}
      style={[
        styles.choice,
        {
          marginRight: 0,
          width: 160,
          borderColor: props.active ? colors.aquaMarineTwo : colors.lightPeriwinkle,
          borderWidth: props.active ? 2 : 1,
        },
        props.style,
      ]}>
      <View
        style={{
          ...commons.flex,
          justifyContent: 'center',
          width: props.fill ? cardWidth : null,
          borderRadius: 22.5,
          paddingHorizontal: 14,
        }}>
        <Text
          style={[
            textStyles.regular10white,
            {
              color: props.active ? colors.aquaMarineTwo : colors.blackTwo,
              textAlign: 'center',
            },
          ]}>
          {props.item.text}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  choice: {
    borderWidth: 1,
    borderColor: '#C9CEE7',
    marginRight: 8,
    marginBottom: 10,
    height: 45,
    borderRadius: 22.5,
    backgroundColor: colors.white,
    alignContent: 'center',
    justifyContent: 'center',
  },
});

export default OptionSelect;
