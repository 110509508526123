import api from 'app/services/api';
import colors from 'app/styles/colors';
import textStyles from 'app/styles/textStyles';
import React, { useEffect, useRef, useState } from 'react';
import {
  ActivityIndicator,
  TouchableOpacity,
  FlatList,
  StyleSheet,
  Text,
  View,
  Animated,
  Image,
  StatusBar,
  TextInput,
  Linking,
} from 'react-native';
import { Dimensions } from 'react-native';
import { translate } from 'app/i18n/src/locale';
import pt from 'app/i18n/src/locale/pt';
import fonts from 'app/styles/fonts';
import CardGym from 'app/screens/core/gym/components/CardGym';
import Spacer from 'app/components/Spacer';
import { useSafeArea } from 'react-native-safe-area-context';
import CustomIcon from 'app/styles/icon';
import commons from 'app/styles/commons';
import { GoogleMap, MarkerClustererF, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import Geolocation from '@react-native-community/geolocation';
import afternoon from 'app/images/afternoon.png';
import ShadowedView from 'app/components/ShadowedView';
import NamuPass from 'app/images/namupass.svg';
import chevronLeft from 'app/images/chevronLeft.png';
import ButtonShadow from 'app/components/ButtonShadow';
import HandleActivity from 'app/screens/core/activities/components/HandleActivity';
import { useNavigation } from '@react-navigation/native';
import APP from '../../../configs/app';

const { height } = Dimensions.get('window');

const MAPS_H = 500;

const ShowGymsScreen = ({ route }) => {

  const { coordinates, style, preFilters } = route?.params || {};
  const navigation = useNavigation();

  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [currentLocation, setCurrentLocation] = useState();
  const [gyms, setGyms] = useState([]);
  const [pins, setPins] = useState([]);
  const [selectedGym, setSelectedGym] = useState();
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filters, setFilters] = useState({
    modalities: '',
    amenities: '',
    free_checkin: null,
    ...preFilters,
  });
  const [gpsOn, setGpsOn] = useState(null);

  const safeAreaInsets = useSafeArea();
  const scrollRef = useRef();
  const scrollA = useRef(new Animated.Value(0)).current;

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyCnu0luRbEfIdZs5LiYon_XYmbiWhNAnFs',
  });

  useEffect(() => {
    handleActivityShowGyms();
  }, []);

  useEffect(() => {
    getGyms();
  }, [filters]);

  const handleActivityShowGyms = async () => {
    try {
      await HandleActivity({ routeName: 'show_gyms' });
    } catch (error) {
      console.log(error);
    }
  };

  const getGyms = async () => {
    try {
      setLoading(true);
      scrollA.setValue(0);
      const location = currentLocation || (await new Promise(getCurrentLocation));
      if (location) {
        const data = await fetchData({ location });
        await fetchPins();
        setGyms(data);
        setCurrentLocation(location);
      }
    } catch (err) {
      console.error('Error in getGyms:', err);
    } finally {
      setLoading(false);
    }
  };

  const fetchPins = async () => {
    try {
      const { data } = await api.get('/gyms/all-locations', {
        params: {
          ...filters,
          search: searchText,
        },
      });

      setPins(data);
    } catch (e) {
      console.log('PIN', e.response.data);
    }
  };

  const fetchData = async ({ location, page }) => {
    const lat = location?.latitude || currentLocation?.latitude || '';
    const lng = location?.longitude || currentLocation?.longitude || '';
    const response = await api.get('gyms', {
      params: {
        page,
        limit: 10,
        search: searchText,
        lat: lat,
        lng: lng,
        ...filters,
      },
    });

    if (!page) {
      setPage(1);
    }

    setTotalPages(response.headers['total-pages']);
    return response.data;
  };

  const handleClose = () => {
    setSelectedGym(null);
  };

  const getCurrentLocation = onDone => {
    Geolocation.getCurrentPosition(
      position => {
        const currentLatitude = parseFloat(JSON.stringify(position.coords.latitude));
        const currentLongitude = parseFloat(JSON.stringify(position.coords.longitude));
        setGpsOn(true);
        onDone({
          latitude: currentLatitude,
          longitude: currentLongitude,
        });
      },
      error => {
        console.log(error, coordinates);
        if (coordinates) {
          setGpsOn(true);
          onDone({
            latitude: coordinates.lat,
            longitude: coordinates.lng,
          });
          return;
        }
        setGpsOn(false);
        onDone();
      },
      {
        enableHighAccuracy: false,
        timeout: 4000,
        maximumAge: 1000,
      }
    );
  };

  const handleSearch = () => {
    getGyms();
  };

  const handleSelectPin = async id => {
    try {
      const { data } = await api.get(`/gyms/get-by-id/?id=${id}`);
      setSelectedGym(data);
    } catch (e) {
      console.log('PIN', e.response);
    }
  };

  const handleLoadMore = () => {
    setLoadingMore(true);
    const nextPage = page + 1;
    if (nextPage > totalPages) {
      return;
    }
    loadMore(nextPage);
  };

  const loadMore = async nextPage => {
    try {
      const data = await fetchData({ page: nextPage });
      setGyms([...gyms, ...data]);
      setPage(nextPage);
    } catch (e) {
      console.log('LM', e.response);
    }
    setLoadingMore(false);
  };

  const handleChangeFilters = filters => {
    setPage(1);
    setFilters(filters);
  };

  const focusSearch = () => {
    scrollRef.current.scrollTo({ y: MAPS_H - safeAreaInsets.top - 128, animated: true });
  };

  return (
    <View style={[styles.container, { paddingBottom: safeAreaInsets.bottom }, style]}>
      <StatusBar backgroundColor="white" barStyle="dark-content" />

      <View
        useNativeDriver
        animation="fadeInDown"
        style={{
          position: 'absolute',
          width: '100%',
          paddingHorizontal: 20,
          zIndex: 9,
          flexDirection: 'column',
          marginTop: safeAreaInsets.top + 20,
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}>
        {gpsOn !== null && (
          <TouchableOpacity
            activeOpacity={0.7}
            onPress={() => Linking.openSettings()}
            style={{
              width: '90%',
              height: 30,
              ...commons.flexRowCenter,
              borderRadius: 30,
              backgroundColor: gpsOn ? colors.aquaMarineTwo : colors.darkRed,
            }}>
            <CustomIcon
              name={'paper_arrow'}
              color={colors.white}
              size={14}
              style={{ marginRight: 10 }}
            />
            <Text style={{ fontFamily: fonts.MEDIUM, color: colors.white, fontSize: 10 }}>
              {translate(gpsOn ? pt.gps_location_on : pt.turn_on_gps_to_search_gyms_near_to_you)}
            </Text>
          </TouchableOpacity>
        )}
        <ShadowedView
          shadowContainerStyle={{
            backgroundColor: colors.white,
            width: '100%',
            height: 80,
            marginTop: 10,
          }}
          wrapperStyle={{
            paddingVertical: 18,
            justifyContent: 'space-between',
            ...commons.flexRow,
          }}>
          <View style={{ flexDirection: 'row' }}>
            <TouchableOpacity style={{ marginRight: 15 }} onPress={() => navigation.goBack()}>
              <Image
                source={chevronLeft}
                style={{ width: 28, height: 28, tintColor: colors.aquaMarineTwo }}
              />
            </TouchableOpacity>
            <Image source={NamuPass} style={{width: 92, height: 30}} />
          </View>
          <TouchableOpacity
            activeOpacity={0.7}
            style={{
              width: 40,
              height: 40,
              borderRadius: 40,
              backgroundColor: colors.aquaMarineTwo,
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onPress={() =>
              navigation.navigate('ShowGymsFilter', {
                onChangeFilters: handleChangeFilters,
                selectedFilters: filters,
              })
            }>
            <Image
              source={require('app/images/filter.png')}
              style={{
                width: 20,
                height: 20,
                marginTop: 2,
                resizeMode: 'contain',
                tintColor: colors.white,
              }}
            />
          </TouchableOpacity>
        </ShadowedView>
      </View>

      {loading || !isLoaded ? (
        <View style={styles.loading}>
          <ActivityIndicator size="large" color={colors.aquaMarineTwo} />
        </View>
      ) : (
        <Animated.ScrollView
          style={{ position: 'relative' }}
          onScroll={Animated.event([{ nativeEvent: { contentOffset: { y: scrollA } } }], {
            useNativeDriver: true,
          })}
          contentContainerStyle={{ paddingBottom: 70 }}
          ref={scrollRef}
          showsVerticalScrollIndicator
          scrollEventThrottle={40}>
          <View style={{}}>
            <Animated.View style={styles.mapsContainer(scrollA)}>
              {selectedGym && (
                <TouchableOpacity
                  activeOpacity={0.7}
                  onPress={() => {
                    navigation.navigate('GymDetail', { id: selectedGym?.id, currentLocation });
                  }}
                  style={[
                    styles.gymCardContainer,
                    {
                      marginTop: safeAreaInsets.top + 340,
                    },
                  ]}>
                  <ShadowedView
                    shadowContainerStyle={{ backgroundColor: colors.white, width: '100%' }}
                    wrapperStyle={{
                      paddingHorizontal: 0,
                      justifyContent: 'space-between',
                      position: 'relative',
                      ...commons.flexRow,
                    }}>
                    <TouchableOpacity
                      hitSlop={commons.hitSlop}
                      onPress={handleClose}
                      style={styles.iconClose}>
                      <CustomIcon name={'close'} size={18} color={colors.white} />
                    </TouchableOpacity>
                    <Image
                      source={{ uri: selectedGym?.images?.logo || '' }}
                      style={styles.cardLogo}
                    />
                    <View style={styles.gymCardInfoContainer}>
                      <Text style={{ fontFamily: fonts.BOLD, color: colors.charcoalGreyTwo }}>
                        {selectedGym?.title || ''}
                      </Text>
                      <Text
                        style={{
                          fontFamily: fonts.BOLD,
                          color: colors.charcoalGreyTwo,
                          fontSize: 10,
                        }}>
                        {selectedGym?.address?.address}
                      </Text>
                      <View style={{ flexDirection: 'row', marginTop: 5 }}>
                        <Text style={styles.gymCardMainActivity}>
                          {selectedGym?.main_activity?.title || ''}
                        </Text>
                        <View style={styles.dailyCardContainer}>
                          <Image
                            source={afternoon}
                            style={{
                              width: 12,
                              height: 12,
                              marginTop: 3,
                              marginRight: 2,
                              resizeMode: 'cover',
                              tintColor: colors.aquaMarineTwo,
                            }}
                          />
                          <Text style={styles.dailyCardText}>Diaria: </Text>
                          <Text style={styles.dailyPriceText}>
                            R${selectedGym?.daily_price?.toFixed(2)?.replaceAll('.', ',') || 0}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </ShadowedView>
                </TouchableOpacity>
              )}
              <View style={{ height: '100%', width: '100%' }}>
                <GoogleMap
                  mapContainerStyle={{
                    width: '100%',
                    height: '100%',
                    borderBottomLeftRadius: '30px',
                    borderBottomRightRadius: '30px',
                  }}
                  center={{
                    lat: currentLocation?.latitude || -23.556172,
                    lng: currentLocation?.longitude || -46.6377429,
                  }}
                  zoom={13}
                  options={{
                    disableDefaultUI: true,
                    clickableIcons: false,
                    styles: [
                      {
                        featureType: "poi",
                        stylers: [{ visibility: "off" }],
                      },
                    ],
                  }}
                >
                  {currentLocation && (
                    <MarkerF position={{
                      lat: currentLocation?.latitude,
                      lng: currentLocation?.longitude,
                    }} icon={{
                      path: window.google.maps.SymbolPath.CIRCLE,
                      fillColor: colors.aquaMarineGrd,
                      fillOpacity: 1,
                      scale: 8,
                      strokeColor: '#FFFFFF',
                      strokeWeight: 2,
                    }}
                    />
                  )}
                  <MarkerClustererF
                    options={{
                      styles: [
                        {
                          textColor: 'white',
                          url: require('app/images/pin_cluster.svg'),
                          height: 45,
                          width: 45,
                        },
                        {
                          textColor: 'white',
                          url: require('app/images/pin_cluster_fat.svg'),
                          height: 60,
                          width: 60,
                        }
                      ],
                    }}
                  >
                    {(clusterer) =>
                      pins.map((gym, index) => (
                        <MarkerF
                          key={index}
                          position={{ lat: gym.lat, lng: gym.lng }}
                          onClick={() => handleSelectPin(gym.id)}
                          clusterer={clusterer}
                          icon={require('app/images/pin_full.svg')}
                        />
                      )
                    )}
                  </MarkerClustererF>
                </GoogleMap>
              </View>
            </Animated.View>

            <View
              style={{
                minHeight: height - (160 + safeAreaInsets.top + safeAreaInsets.bottom),
              }}>
              <View style={styles.titleContainer}>
                <View style={styles.searchBar}>
                  <TextInput
                    style={{
                      height: '100%',
                      flex: 1,
                      fontFamily: fonts.REGULAR,
                      fontSize: 12,
                      marginHorizontal: 10,
                      color: colors.darkPro,
                    }}
                    onPressIn={focusSearch}
                    value={searchText}
                    onChangeText={setSearchText}
                    onSubmitEditing={handleSearch}
                    returnKeyType="search"
                    placeholder={translate(pt.type_gym_name)}
                  />
                  <TouchableOpacity
                    onPress={handleSearch}
                    style={{
                      backgroundColor: colors.aquaMarineTwo,
                      width: 33,
                      height: 33,
                      borderRadius: 33,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <CustomIcon name={'search'} size={16} color={colors.white} />
                  </TouchableOpacity>
                </View>
              </View>

              {gyms.length ? (
                <FlatList
                  numColumns={2}
                  style={{ height: 'auto' }}
                  contentContainerStyle={{ paddingHorizontal: 20, paddingBottom: 13 }}
                  showsHorizontalScrollIndicator={false}
                  ItemSeparatorComponent={() => <Spacer size={10} />}
                  data={gyms}
                  ListFooterComponent={
                    page < totalPages && (
                      <View style={{ marginTop: 35, marginBottom: 15, alignItems: 'center' }}>
                        {loadingMore ? (
                          <ActivityIndicator size="small" color={colors.aquaMarineTwo} />
                        ) : (
                          <ButtonShadow
                            onPress={handleLoadMore}
                            containerStyle={{ flex: 1 }}
                            textStyle={{ color: colors.aquaMarineTwo }}
                            backgroundColor={colors.white}
                            title="Carregar mais"
                          />
                        )}
                      </View>
                    )
                  }
                  keyExtractor={({ index }) => Math.random()}
                  renderItem={({ item, index }) => (
                    <View
                      style={[
                        {
                          flex: 1,
                          justifyContent: index % 2 === 0 ? 'flex-start' : 'flex-end',
                          flexDirection: 'row',
                        },
                        index % 2 === 0 ? { paddingRight: 8 } : { paddingLeft: 8 },
                      ]}>
                      <CardGym
                        containerStyle={{ flex: 1 }}
                        style={{ width: '100%' }}
                        currentLocation={currentLocation}
                        {...item}
                      />
                    </View>
                  )}
                />
              ) : (
                <View style={{ ...commons.flexRowCenter, marginTop: 30 }}>
                  <Text style={textStyles.bold12grey}>Nenhuma academia encontrada</Text>
                </View>
              )}
            </View>
          </View>
        </Animated.ScrollView>
      )}
    </View>
  );
};

export default ShowGymsScreen;

const styles = StyleSheet.create({
  title: {
    fontFamily: fonts.BOLD,
    fontSize: 20,
    color: colors.darkPro,
    marginBottom: 15,
  },
  mapsContainer: scrollA => ({
    height: MAPS_H,
    width: '100%',
    overflow: 'hidden',
    backgroundColor: colors.greyEF,
    borderBottomLeftRadius: 30,
    borderBottomRightRadius: 30,
    transform: [
      {
        translateY: scrollA.interpolate({
          inputRange: [-MAPS_H, 0],
          outputRange: [80, 0],
        }),
      },
    ],
  }),
  titleContainer: {
    height: 68,
    marginTop: 20,
    marginBottom: 15,
    borderRadius: 15,
    backgroundColor: colors.white,
    paddingHorizontal: 16,
    marginHorizontal: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  searchBar: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 15,
    backgroundColor: colors.paleGreyThree,
    borderColor: colors.veryLightPinkThree,
    borderWidth: 1,
    paddingHorizontal: 10,
    height: 45,
  },
  container: {
    flex: 1,
    backgroundColor: colors.greyF5,
  },
  loading: {
    position: 'absolute',
    top: 200,
    width: 150,
    height: 150,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
  button: {
    width: 33,
    height: 33,
    backgroundColor: colors.aquaMarineTwo,
    borderRadius: 33,
    justifyContent: 'center',
    alignItems: 'center',
  },
  gymCardContainer: {
    position: 'absolute',
    width: '100%',
    height: 124,
    paddingHorizontal: 20,
    zIndex: 9,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  cardLogo: {
    width: 123,
    height: 123,
    borderRadius: 10,
    resizeMode: 'contain',
  },
  gymCardInfoContainer: {
    flex: 1,
    flexDirection: 'column',
    paddingHorizontal: 10,
  },
  gymCardMainActivity: {
    fontFamily: fonts.BOLD,
    color: colors.brownGrey,
    fontSize: 12,
    borderWidth: 1,
    paddingHorizontal: 5,
    borderColor: '#D7D7D7 ',
    borderRadius: 5,
  },
  dailyCardContainer: {
    flexDirection: 'row',
    marginLeft: 10,
    borderWidth: 1,
    paddingHorizontal: 5,
    borderColor: colors.aquaMarineTwo2 + '59',
    borderRadius: 5,
  },
  dailyCardText: {
    fontFamily: fonts.BOLD,
    color: colors.aquaMarineTwo,
    fontSize: 12,
  },
  dailyPriceText: {
    fontFamily: fonts.BOLD,
    color: colors.grayText,
    fontSize: 12,
  },
  iconClose: {
    position: 'absolute',
    left: 5,
    zIndex: 9,
    top: 5,
    borderRadius: 999,
    backgroundColor: '#00000040',
  },
});
