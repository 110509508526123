import React from 'react';

import { StyleSheet, Text, Image } from 'react-native';
import fonts from '../styles/fonts';
import colors from '../styles/colors';
import ShadowView from '@liuyunjs/react-native-simple-shadow-view';
import yields from '../images/yield.png';

const CapsuleYield = ({ style, yieldTotal }) => {
  const getyieldTotalName = () => {
    if (yieldTotal === 1) {
      return 'Porção';
    }

    return 'Porções';
  };

  return (
    <ShadowView style={[styles.capsule, style]}>
      <Image source={yields} style={{width: 14, height: 14}} />
      <Text style={styles.capsuleText}>
        {`${yieldTotal}`} {getyieldTotalName()}
      </Text>
    </ShadowView>
  );
};

export default CapsuleYield;

const styles = StyleSheet.create({
  capsuleText: {
    marginLeft: 4,
    fontFamily: fonts.REGULAR,
    fontSize: 14,
    opacity: 0.9,
    color: colors.brownishGreyTwo,
  },
  capsule: {
    paddingLeft: 10,
    flexDirection: 'row',
    alignItems: 'center',
    width: 110,
    height: 29,
    borderRadius: 18.2,
    backgroundColor: colors.whiteTwo,
  },
});
