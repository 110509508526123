import React, { useRef, useState } from 'react';

import { View, StyleSheet, Text, TouchableOpacity, Image } from 'react-native';
// import { Menu, MenuItem } from 'react-native-enhanced-popup-menu';
import colors from 'app/styles/colors';
import textStyles from 'app/styles/textStyles';
import { Menu, MenuOption, MenuOptions, MenuProvider, MenuTrigger } from 'react-native-popup-menu';
import commons from '../styles/commons';
import moreVert from 'app/images/moreVert.png';

const OptionsContextMenu = ({ items, buttonColor }) => {
  const [, setOpen] = useState(false);
  const menuRef = useRef();
  const buttonRef = useRef();
  const refRBSheet = useRef();

  const hideMenu = () => menuRef.current.hide();
  const showMenu = () => menuRef.current.show(buttonRef.current);

  const openBottom = () => {
    setOpen(true);
    hideMenu();
  };

  const handlePress = item => {
    setOpen(false);
    hideMenu();
    item.onPress && item.onPress();
  };

  const MenuItemContent = ({ title, icon }) => (
    <View style={styles.containerItem}>
      <Text style={[textStyles.semi14, { marginLeft: 10 }]}>{title}</Text>
      {icon}
    </View>
  );

  const renderItem = (item, i) => {
    return (
      <MenuOption key={i} onSelect={() => handlePress(item)}>
        {/* <Text title={} icon={item.icon} /> */}
        <Text >{item.title}</Text>
      </MenuOption>
    );
  };

  return (
    <View style={styles.container}>
      <MenuProvider>
        <Menu
          onClose={() => {
            if (open) {
              setOpen(false);
              // refRBSheet.current.open();
            }
          }}>
          <MenuTrigger>
            {/* <TouchableOpacity
              // onPress={showMenu}
              // ref={buttonRef}
              style={styles.button}
              hitSlop={commons.hitSlop}>
              <Image
                source={moreVert}
                style={{ ...styles.image, tintColor: buttonColor || colors.charcoalGreyTwo }}
              />
            </TouchableOpacity> */}
          </MenuTrigger>
          <MenuOptions>
            {items.map(renderItem)}
            
          </MenuOptions>
        </Menu>
      </MenuProvider>
    </View>
  );
};

export default OptionsContextMenu;

const styles = StyleSheet.create({
  container: {
    // backgroundColor: 'red',
    zIndex: 2,
    // position: 'absolute'
  },
  containerItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: 150,
    alignItems: 'center',
  },
  image: {
    tintColor: colors.black,
    width: 20,
    height: 20,
    resizeMode: 'contain',
  },
});
