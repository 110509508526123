import React from 'react';

import { Text, View, FlatList, StyleSheet, TouchableOpacity, Linking } from 'react-native';

import Accordion from 'app/components/Accordion';
import CustomIcon from 'app/styles/icon';
import fonts from 'app/styles/fonts';
import Icon from 'react-native-vector-icons/dist/Feather';
import colors from 'app/styles/colors';
import pt from 'app/i18n/src/locale/pt';
import { translate } from 'app/i18n/src/locale';
import commons from 'app/styles/commons';
import textStyles from 'app/styles/textStyles';
import { TextMask } from 'react-native-masked-text';

import 'moment';
import 'moment/locale/pt-br';
import moment from 'moment';
import { Image } from 'react-native';

moment.locale('pt-br');

export const GymInformations = ({ gym }) => {
  return (
    <>
      <View style={{ marginHorizontal: 20, marginTop: 30 }}>
        <Accordion
          open
          style={{ borderRadius: 10, minHeight: 48 }}
          headerStyle={{ paddingHorizontal: 8, height: 48 }}
          customTitle
          title={
            <>
              <CustomIcon
                name={'phone'}
                size={16}
                color={colors.aquaMarineTwo}
                style={{ marginRight: 8 }}
              />
              <Text style={{ ...textStyles.medium16, color: colors.charcoalGreyTwo }}>
                {translate(pt.contact)}
              </Text>
            </>
          }
          color={colors.aquaMarineTwo}
          ignoreDefaultDots
          custom
          content={
            <>
              <TouchableOpacity activeOpacity={0.7} onPress={()=>Linking.openURL(`tel:${gym?.phone.replace(/\D+/g, '')}`)}>
                <Text
                  style={{
                    ...textStyles.medium14,
                    marginLeft: 17,
                    marginBottom: 10,
                    color: colors.slateGrey,
                  }}>
                  Telefone <TextMask value={gym?.phone} type={'cel-phone'} /> <Icon name={'external-link'}/>
                </Text>
              </TouchableOpacity>
              {gym?.whatsapp && (
              <TouchableOpacity activeOpacity={0.7} onPress={()=>Linking.openURL(`tel:${gym?.whatsapp.replace(/\D+/g, '')}`)}>
                <Text
                  style={{
                    ...textStyles.medium14,
                    marginLeft: 17,
                    marginBottom: 15,
                    color: colors.slateGrey,
                  }}>
                  Whatsapp <TextMask value={gym?.whatsapp} type={'cel-phone'} /> <Icon name={'external-link'}/>
                </Text>
              </TouchableOpacity>
              )}
            </>
          }
        />
      </View>

      {!!gym?.amenities && !!gym?.amenities.length && (
        <View style={{ marginHorizontal: 20 }}>
          <Accordion
            open
            style={{ borderRadius: 10, minHeight: 48 }}
            headerStyle={{ paddingHorizontal: 8, height: 48 }}
            customTitle
            title={
              <>
                <Icon
                  name={'wifi'}
                  size={16}
                  color={colors.aquaMarineTwo}
                  style={{ marginRight: 8 }}
                />
                <Text style={{ ...textStyles.medium16, color: colors.charcoalGreyTwo }}>
                  {translate(pt.amenities)}
                </Text>
              </>
            }
            color={colors.aquaMarineTwo}
            ignoreDefaultDots
            custom
            content={
              <FlatList
                numColumns={4}
                style={{ marginHorizontal: 10 }}
                data={gym.amenities}
                renderItem={({ item, index }) => {
                  return (
                    <View style={{ alignItems: 'center', flex: 1, marginVertical: 10 }}>
                      <View style={styles.iconCenter}>
                        <Image
                          source={{ uri: item.imageUrl }}
                          style={{width: 30, height: 30, resizeMode: 'contain', tintColor: colors.aquaMarineTwo}}
                        />
                      </View>

                      <Text
                        style={{
                          ...textStyles.semi10grey,
                          marginTop: 10,
                          textAlign: 'center',
                        }}>
                        {item.title}
                      </Text>
                    </View>
                  );
                }}
              />
            }
          />
        </View>
      )}

      {/* {(gym?.amenities && gym?.amenities.length) && <View style={styles.amenitiesContainer}>
              <View style={commons.flexRow}>
                <Icon name={'wifi'} size={16} color={colors.aquaMarineTwo} style={{marginRight: 5}}/>
                <Text style={{...textStyles.medium16, color: colors.slateGrey}}>{'Comodidades'}</Text>
              </View>
              <View style={{marginTop: 10}}>
                <FlatList
                  numColumns={4}
                  style={{width: '100%'}}
                  data={gym.amenities}
                  renderItem={({item, index}) =><View style={{alignItems: 'center', flex: 1, marginVertical: 10}}>
                  <Image source={{uri: item.imageUrl}} style={{width: 50, height: 50, resizeMode: 'contain'}} />
                  <Text style={{...textStyles.semi10grey, marginTop: 10, textAlign: 'center'}}>{item.title}</Text>
                </View> }
                />
              </View>
            </View>} */}

      {!!gym?.working_hours && (
        <View style={styles.content}>
          <Accordion
            open
            style={{ borderRadius: 10, minHeight: 48 }}
            headerStyle={{ paddingHorizontal: 8, height: 48 }}
            customTitle
            title={
              <>
                <Icon
                  name={'clock'}
                  size={16}
                  color={colors.aquaMarineTwo}
                  style={{ marginRight: 8 }}
                />
                <Text style={{ ...textStyles.medium16, color: colors.slateGrey }}>
                  {translate(pt.working_hours)}
                </Text>
              </>
            }
            color={colors.aquaMarineTwo}
            ignoreDefaultDots
            arrayItens={Object.entries(gym?.working_hours).map(([day, item]) => (
              <View key={day} style={{ flexDirection: 'row' }}>
                <View style={styles.dayCircle}>
                  <Text style={textStyles.bold10White}>
                    {moment().weekday(day).format('dddd')[0].toUpperCase()}
                  </Text>
                </View>
                {item ? (
                  <Text style={{ ...textStyles.medium14, color: colors.slateGrey }}>
                    {'  '}
                    {item.open} - {item.close}
                  </Text>
                ) : (
                  <Text style={{ ...textStyles.medium14, color: colors.slateGrey }}>
                    {'  '}
                    {'Fechado'}
                  </Text>
                )}
              </View>
            ))}
            item={'working_hours'}
            type={'gyms'}
            id={gym?.id}
          />
        </View>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  iconCenter: {
    width: 50,
    height: 50,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 50,
    backgroundColor: '#F5FFFF',
  },
  content: {
    paddingHorizontal: 20,
    marginBottom: 20,
  },
  dayCircle: {
    width: 21,
    height: 21,
    borderRadius: 21,
    backgroundColor: colors.aquaMarineTwo,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
