/* eslint-disable react-native/no-inline-styles */
import ChallengeChip from 'app/components/ChallengeChip';
import pt from 'app/i18n/src/locale/pt';
import api from 'app/services/api';
import { errorControl, sendActionEvent } from 'app/services/utils';
import colors from 'app/styles/colors';
import commons from 'app/styles/commons';
import textStyles from 'app/styles/textStyles';
import React, { useRef } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import {
  View,
  StyleSheet,
  StatusBar,
  ImageBackground,
  Text,
  Dimensions,
  ScrollView,
  ActivityIndicator,
  Modal
} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { useSafeArea } from 'react-native-safe-area-context';
import { translate } from 'app/i18n/src/locale';
import HeaderOver from 'app/components/HeaderOver';
import ModalChangeIngredient from 'app/screens/core/meal/components/ModalChangeIngredient';
import CardIngredientChange from 'app/screens/core/explorer/components/CardIngredientChange';
import FavoriteButton from 'app/components/FavoriteButton';
import CapsuleHour from 'app/components/CapsuleHour';
import AccordionPreparation from 'app/components/AccordionPreparation';
import Spacer from 'app/components/Spacer';
import CapsuleLevel from 'app/components/CapsuleLevel';
import InfoPortion from 'app/components/InfoPortion';
import CapsuleYield from 'app/components/CapsuleYield';
import Chip from 'app/components/Chips';
import Orientation from 'react-native-orientation-locker';
// import AddCalendar from 'app/screens/core/explorer/components/AddCalendar';
import HeaderTitle from 'app/screens/profile/components/HeaderTitle';
import CardRestriction from 'app/screens/core/meal/components/CardRestriction';
import { useSelector } from 'react-redux';
import ThumbnailVideo from 'app/components/ThumbnailVideo';
import MealVideo from 'app/components/meal-video';
import fallback from 'app/images/fallback_food2.jpg';
import apiVimeo from 'app/services/apiVimeo';
import { useNavigation } from '@react-navigation/native';

const { width } = Dimensions.get('window');

const PlateDetails = ({ route }) => {

  const { plateId } = route?.params;
  const navigation = useNavigation();

  const safeAreaInsets = useSafeArea();
  const { typeShareSelected } = useSelector(state => state.category);
  const { profile } = useSelector(state => state.user);
  const [recipe, setRecipe] = useState({});
  const [modal, setModal] = useState(false);
  const [scrollY, setScrollY] = useState(0);
  const [videoUrl, setVideoUrl] = useState(null);
  const [fullscreen, setFullscreen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isShowVideo, setIsShowVideo] = useState(false);
  const [isVideoLogin, setIsVideoLogin] = useState(false);
  const videoRef = useRef([]);
  const [imageTop, setImageTop] = useState(fallback);
  const [state, setState] = useState({
    play: false,
    currentTime: 0,
    duration: 0,
    showControls: false,
    endVideo: false,
  });

  const openModal = () => {
    setModal(true);
  };

  const handleScroll = event => {
    setScrollY(event.nativeEvent.contentOffset.y);
  };

  useEffect(() => {
    requestRecipe();
  }, [requestRecipe]);

  useEffect(() => {
    Orientation.addOrientationListener(handleOrientation);

    return () => {
      Orientation.removeOrientationListener(handleOrientation);
    };
  }, []);

  const requestRecipe = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`recipe/${plateId}`);
      setRecipe(data);
      setImageTop({
        uri: data?.image?.path,
      });
      if (data.how_to) {
        getVideoVimeo(data.how_to.path.split('/'));
      }
      setLoading(false);
    } catch (error) {
      console.log(errorControl(error));
      setLoading(false);
    }
  }, [plateId]);

  const getVideoVimeo = async idVimeo => {
    setIsVideoLogin(true);
    const videoId = idVimeo[idVimeo.length - 1];
    try {
      const { data } = await apiVimeo.get(`/videos/${videoId}`);
      const video360 = data.download.find(v => v.rendition === '360p');
      setVideoUrl(video360.link);
    } catch (error) {
      console.log('Error', error);
    }
    setIsVideoLogin(false);
  };

  const getPreparationtName = () => {
    if (recipe?.preparation === '' || recipe?.preparation === null) {
      return [];
    }
    const arrayPreparation = recipe?.preparation?.split('. ');
    return arrayPreparation;
  };

  const onLoadEnd = async data => {
    sendActionEvent(
      'start_plate_presentation',
      { id: plateId, email: profile.email },
      profile.email
    );
    setState(prevState => ({
      ...prevState,
      duration: data.duration,
      currentTime: data.currentTime,
    }));
  };

  const onProgress = async data => {
    setState(prevState => ({
      ...prevState,
      currentTime: data.currentTime,
    }));
  };

  const onEnd = async () => {
    sendActionEvent('end_plate_presentation', { id: plateId, email: profile.email }, profile.email);
    setState({ ...state, play: false });
    videoRef.current.seek(0);
  };

  const handlePlayPause = () => {
    if (state.play) {
      setState({ ...state, play: false, showControls: true });
      return;
    }

    setState({ ...state, play: true, showControls: false });
  };

  const onSeek = data => {
    videoRef.current.seek(data.seekTime);
    setState({ ...state, currentTime: data.seekTime });
  };

  const showControls = () => {
    state.showControls
      ? setState({ ...state, showControls: false })
      : setState({ ...state, showControls: true });
  };

  const handleOrientation = () => {
    Orientation.getOrientation(deviceOrientation => {
      deviceOrientation === 'LANDSCAPE-LEFT' || deviceOrientation === 'LANDSCAPE-RIGHT'
        ? (setFullscreen(true), StatusBar.setHidden(true))
        : (setFullscreen(false), StatusBar.setHidden(false));
    });
  };

  const handleFullscreen = () => {
    fullscreen ? Orientation.unlockAllOrientations() : Orientation.lockToLandscapeLeft();
  };

  const showComponentVideo = () => {
    setState({ ...state, play: true });
    setIsShowVideo(true);
  };

  return (
    <View style={styles.container}>
      {isLoading ? (
        <View style={{ alignItems: 'center', justifyContent: 'center', flex: 1 }}>
          <ActivityIndicator size="large" color={colors.red} />
        </View>
      ) : (
        <>
          {!fullscreen && (
            <HeaderOver
              color={colors.orangeyRed}
              title={recipe.name}
              position={scrollY}
              maxSize={200}
              type={typeShareSelected}
              share={false}
              shareData={{
                title: recipe?.name,
                screen: 'plate_detail',
                image: recipe?.image?.path,
                description: recipe?.description,
                id: recipe?.id,
              }}
            />
          )}
          <ScrollView onScroll={handleScroll}>
            <View style={{ flexGrow: 1, paddingBottom: safeAreaInsets.bottom }}>
              {!fullscreen && (
                <>
                  <View>
                    <View style={styles.topImage}>
                      <ImageBackground
                        source={imageTop}
                        onError={currentTarget => {
                          currentTarget.onError = null;
                          setImageTop(fallback);
                        }}
                        style={{ height: 426 }}>
                        <View style={styles.topper}>
                          <LinearGradient
                            colors={[colors.transparent, colors.orangeyRed80]}
                            style={styles.linear}
                          />

                          <ChallengeChip text={translate(pt.plates)} />

                          <View style={styles.holderTitle}>
                            <View style={{ flex: 1 }}>
                              <Text style={[textStyles.bold24White]}>
                                {recipe?.name}
                              </Text>
                            </View>

                            {typeof recipe?.favorite !== undefined && (
                              <FavoriteButton
                                isFavorited={recipe?.favorite === 1}
                                propType="dish_recipe_id"
                                itemId={recipe?.id}
                              />
                            )}
                          </View>
                        </View>
                      </ImageBackground>
                    </View>
                    <View style={styles.capsuleHolder}>
                      <CapsuleHour
                        style={styles.capsule}
                        timeIn={'min'}
                        duration={recipe?.preparation_time}
                      />
                      <CapsuleLevel style={styles.capsule} difficulty={recipe?.level} />
                      <CapsuleYield style={styles.capsule} yieldTotal={recipe?.yield} />
                    </View>
                  </View>

                  <View style={styles.content}>
                    <Text style={textStyles.regular14}>{recipe?.description}</Text>
                  </View>

                  <View style={styles.content}>
                    <View style={styles.insideContainerRow}>
                      {recipe?.tags?.map(tag => {
                        return <Chip title={tag.name} />;
                      })}
                    </View>
                  </View>

                  <View style={styles.content}>
                    {recipe?.user_dietary_restrictions?.length > 0 && (
                      <CardRestriction restrictions={recipe?.user_dietary_restrictions} />
                    )}
                  </View>

                  {/* <View style={styles.content}> */}
                    {/* <View style={commons.flexRowBetween}> */}
                      {/* <AddCalendar style={styles.add} typeSchedule="dish" id={plateId} /> */}
                      {/* <Spacer value={10} />
                      <AddPlaylist style={styles.add} typeItem="dish" id={plateId} /> */}
                    {/* </View> */}
                  {/* </View> */}
                  <Spacer value={10} />

                  <HeaderTitle title={translate(pt.nutritional_value)} />
                  <View style={styles.content_cards}>
                    {recipe?.nutritional_info?.map(nutritional => (
                      <View style={styles.smallCard}>
                        <Text style={textStyles.bold14}>{`${nutritional.calc_values.slice(0, -3)} ${
                          nutritional.unit_short_name
                        }`}</Text>
                        <Text numberOfLines={1} style={textStyles.regular11}>
                          {nutritional.type_name}
                        </Text>
                      </View>
                    ))}
                  </View>

                  <HeaderTitle title={translate(pt.ingredients).toUpperCase()} />

                  <InfoPortion
                    InfoPortion={recipe?.portion_meal}
                    weightMeal={recipe?.weight_meal}
                    originalYield={recipe?.yield}
                  />

                  <View style={styles.content}>
                    {recipe?.ingredients?.map(ingredient => {
                      return (
                        <CardIngredientChange
                          name={ingredient.name}
                          grams={ingredient.portion}
                          unit={ingredient.measure_unit}
                          onPress={openModal}
                        />
                      );
                    })}
                  </View>
                </>
              )}

              {isShowVideo ? (
                <MealVideo
                  showControls={showControls}
                  fullscreen={fullscreen}
                  videoRef={videoRef}
                  videoUrl={videoUrl}
                  onLoadEnd={onLoadEnd}
                  onProgress={onProgress}
                  onEnd={onEnd}
                  isVideoLogin={isVideoLogin}
                  handleFullscreen={handleFullscreen}
                  handlePlayPause={handlePlayPause}
                  state={state}
                  onSeek={onSeek}
                />
              ) : (
                !!recipe?.how_to && (
                  <ThumbnailVideo image={recipe?.thumbnail?.path} onPress={showComponentVideo} />
                )
              )}

              {!fullscreen && (
                <>
                  <View style={styles.content}>
                    <AccordionPreparation
                      title={translate(pt.how_to).toUpperCase()}
                      color={colors.strawberry}
                      arrayItens={getPreparationtName()}
                    />
                  </View>
                  {/* <ButtonBase
                    backgroundColor={colors.orangeyRed}
                    fill
                    square
                    onPress={Actions.shopping_car}
                    title={translate(pt.add_to_shop_car)}
                  /> */}
                </>
              )}
            </View>
            <Modal animationInTiming={600} visible={modal}>
              <ModalChangeIngredient
                onClose={() => {
                  setModal(false);
                }}
              />
            </Modal>
          </ScrollView>
        </>
      )}
    </View>
  );
};

export default PlateDetails;

const styles = StyleSheet.create({
  containerVideo: { marginHorizontal: 12 },
  fullscreenControls: {
    padding: 5,
    position: 'absolute',
    zIndex: 2,
  },
  fullscreenClose: { width: 20, height: 20 },
  fullscreenOpen: { width: 20, height: 20 },
  starHolder: { alignItems: 'flex-start' },
  topper: { padding: 14, flex: 1, justifyContent: 'flex-end' },
  holderTitle: { flexDirection: 'row', marginVertical: 10, alignItems: 'center' },
  linear: { position: 'absolute', width: '100%', height: 200, left: 0, bottom: 0 },
  topImage: {
    height: 426,
    borderBottomRightRadius: 36,
    backgroundColor: 'grey',
    overflow: 'hidden',
  },
  round: {
    marginLeft: 12,
    width: 36,
    height: 36,
    borderRadius: 18,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.blackAlpha18,
  },
  container: {
    // flex: 1,
    height: '100dvh',
    backgroundColor: colors.paleGrey,
  },
  smallCard: {
    width: '23%',
    marginRight: '2%',
    marginTop: 8,
    height: 53,
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.white,
  },
  video: {
    height: Dimensions.get('window').width * (9 / 17),
    backgroundColor: colors.paleGrey,
  },
  fullscreenVideo: {
    height: Dimensions.get('screen').width,
    width: Dimensions.get('screen').height,
    backgroundColor: 'black',
  },
  fullscreenButton: {
    flex: 1,
    flexDirection: 'row',
    alignSelf: 'flex-end',
    alignItems: 'center',
  },
  controlOverlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#00000080',
    justifyContent: 'space-between',
  },
  content_cards: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    paddingHorizontal: 14,
    marginBottom: 14,
  },
  content: { paddingHorizontal: 14, marginBottom: 14 },

  capsuleHolder: {
    flexDirection: 'row',
    width: 280,
    marginTop: -10,
    marginBottom: 20,
    justifyContent: 'space-between',
    marginLeft: 16,
  },
  capsule: {
    backgroundColor: colors.whiteTwo,
    borderColor: '#eee',
    borderWidth: StyleSheet.hairlineWidth,
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 6,
    shadowOpacity: 0.2,
  },
  insideContainerRow: {
    marginTop: 10,
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 0,
  },
  add: { flex: 1, paddingHorizontal: 10 },
});
