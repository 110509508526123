import React, { useState, Fragment, useEffect, useCallback } from 'react';
import {
  View,
  Text,
  StyleSheet,
  Image,
  TextInput,
  TouchableWithoutFeedback,
  ScrollView,
  TouchableOpacity,
} from 'react-native';
import HeaderHomeNew from 'app/components/HeaderHomeNew';
// import Icon from 'react-native-vector-icons/FontAwesome';
import chevronLeft from 'app/images/chevronLeft.png';
import chevronRight from 'app/images/chevronRight.png';
import colors from '../../../styles/colors';
import fonts from '../../../styles/fonts';
import textStyles from '../../../styles/textStyles';
import { translate } from '../../../i18n/src/locale';
import pt from '../../../i18n/src/locale/pt';

import CustomIcon from 'app/styles/icon';

import pontos from 'app/assets/gamification/imagens/pontos.png';
import InfoIcon from 'app/images/info.png';
import magnifier from 'app/images/magnifier.png';
import api from 'app/services/apiNode';
import profile_image from 'app/images/profile_image.png';
import { GamificationAboutPoints } from 'app/screens/core/gamification/GamificationAboutPoints';
import { rankingBagdes } from 'app/screens/core/gamification/utils/conts';
import SearchBar from 'app/components/SearchBar';
import Spacer from 'app/components/Spacer';
import ShadowView from '@liuyunjs/react-native-simple-shadow-view';
import commons from 'app/styles/commons';
import { useGamification } from 'app/hooks/useGamification';
import HandleActivity from 'app/screens/core/activities/components/HandleActivity';

const text = {
  this_week: 'Esta Semana',
  last_week: 'Semana passada',
};

const textMonth = {
  this_month: 'Este Mês',
  last_month: 'Mês passado',
};

const distanteTabsObj = [
  { text: 'Semana', value: 'week' },
  { text: 'Mês', value: 'month' },
  { text: 'Total', value: '' },
];

export const getUserRankingBagde = index => {
  if (index > 2) {
    return <UserBadge position={index + 1} />;
  }
  return <UserBadge badge={rankingBagdes[index]} isBadge />;
};

const DistanceTabs = ({ onTabPress }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabPress = (tabIndex, tab) => {
    setActiveTab(tabIndex);
    onTabPress && onTabPress(tab.value, tab);
  };

  return (
    <Fragment>
      {distanteTabsObj.map((tab, index) => (
        <TouchableWithoutFeedback key={index} onPress={() => handleTabPress(index, tab)}>
          <View style={{ flexDirection: 'row', flex: 1 }}>
            <View style={[styles.tab, activeTab === index && styles.activeTab]}>
              <Text style={[styles.tabText, activeTab === index && styles.activeTabText]}>
                {tab.text}
              </Text>
            </View>
            {index !== distanteTabsObj.length - 1 && <Spacer value={10} />}
          </View>
        </TouchableWithoutFeedback>
      ))}
    </Fragment>
  );
};

const UserBadge = ({ isBadge, badge, position, size = 25 }) => {
  if (isBadge) {
    return <Image style={{...styles.img, width: size, height: size}} source={badge} />;
  }
  return <Text style={styles.badgeText}>{position}°</Text>;
};

const RankUserCard = ({ isLast, position, user, filter }) => {
  const cutUserName = name => {
    return name.substring(0, 8) + '.';
  };

  const styleContainer = isLast ? styles.lastUserCardContainer : styles.usarCardContainer;
  const isFiltered = user.name.toLowerCase().includes(filter.toLowerCase());

  if (!isFiltered) {
    return null;
  }
  return (
    <View style={styleContainer}>
      {getUserRankingBagde(position)}
      <Image
        style={{...styles.profileImg, width: 50, height: 50}}
        source={user.user_picture ? { uri: user.user_picture } : profile_image}
      />
      <View style={styles.nameAndPointsContainer}>
        <Text style={textStyles.bold18blackTwo}>{cutUserName(user.name)}</Text>
        <View style={styles.pointsContainer}>
          <Image style={[styles.img, { marginRight: 5, width: 15, height: 15 }]} source={pontos} />
          <Text style={textStyles.semi16slateGrey}>
            {user.points} <Text style={textStyles.semi12brownGrey}>pontos</Text>
          </Text>
        </View>
      </View>
    </View>
  );
};

export const GamificationLeaderboard = () => {
  const [leaderData, setLeaderData] = useState([]);
  const [weekText, setWeekText] = useState(text.this_week);
  const [distance, setDistance] = useState(distanteTabsObj[0]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [filter, setFilter] = useState('');

  const { eventsGenerationByHandleActivity } = useGamification();

  useEffect(() => {
    getLeaderboard('week');
    handleAppState();
  }, []);

  const handleAppState = useCallback(async nextAppState => {
    if (nextAppState === 'active') {
      const { data } = await HandleActivity({ routeName: 'leaderboard' });
      eventsGenerationByHandleActivity(data);
    }
  }, []);

  const getLeaderboard = async (time, tab) => {
    try {
      const { data } = await api.get('/game-users/leaderboard', {
        params: {
          period: time,
        },
      });

      setLeaderData(data);
    } catch (error) {
      console.log('leaderboard error', error);
    }
  };

  const handleChageSearchState = () => setIsSearch(state => !state);

  const handlecloseSearch = () => {
    setFilter('');
    setIsSearch(state => !state);
  };

  const changeWeekOrMonth = value => {
    if (value === 'week') {
      setWeekText(text.this_week);
    }
    if (value === 'month') {
      setWeekText(textMonth.this_month);
    }
  };

  const handleOpenOrCloseModal = () => {
    setIsOpenModal(state => !state);
  };

  const onChangeTime = async time => {
    await getLeaderboard(time);
  };

  const onChangeTimeWithTab = async (time, tab) => {
    setDistance(tab);
    changeWeekOrMonth(tab.value);
    await getLeaderboard(time, tab);
  };

  const getBack = () => {
    if (distance.value === 'month') {
      onChangeTime('lastMonth');
      setWeekText(textMonth.last_month);
    } else {
      onChangeTime('lastWeek');
      setWeekText(text.last_week);
    }
  };

  const getNext = () => {
    if (distance.value === 'month') {
      onChangeTime('month');
      setWeekText(textMonth.this_month);
    } else {
      getLeaderboard('week');
      setWeekText(text.this_week);
    }
  };

  const lastIndex = leaderData ? leaderData.length - 1 : 0;

  return (
    <View
      style={{
        width: '100%',
        height: '100%',
        zIndex: 5,
        position: 'absolute',
        top: 0,
        backgroundColor: colors.paleGrey,
      }}>
      <ScrollView style={{ flex: 1 }}>
        <View style={styles.headerContainer}>
          <View style={isSearch ? styles.titleContainerInput : styles.titleContainer}>
            {isSearch ? (
              <View style={styles.searchContainer}>
                <Image source={magnifier} style={{...styles.searchInputStyle, width: 16, height: 16}} />
                <TextInput
                  onChangeText={value => setFilter(value)}
                  onSubmitEditing={handleChageSearchState}
                  fontSize={16}
                  value={filter}
                  placeholder={'Busque o nome'}
                  placeholderTextColor={colors.greyBlue}
                  style={styles.input}
                />
                <TouchableOpacity
                  activeOpacity={0.7}
                  onPress={handlecloseSearch}
                  style={styles.close}>
                  <CustomIcon name={'close'} size={20} color={colors.black} />
                </TouchableOpacity>
              </View>
            ) : (
              <View style={[commons.flexRowBetween, { width: '100%' }]}>
                <View style={commons.flexRow}>
                  <Text style={textStyles.bold24blackTwo}>{'Top usuários ativos '}</Text>
                  <TouchableWithoutFeedback onPress={handleOpenOrCloseModal}>
                    <Image
                      source={InfoIcon}
                      style={{ marginTop: 5, tintColor: colors.aquaMarineTwo, width: 20, height: 20 }}
                    />
                  </TouchableWithoutFeedback>
                </View>
                <TouchableWithoutFeedback onPress={handleChageSearchState}>
                  <Image source={magnifier} style={styles.searchStyle} width={20} height={20} />
                </TouchableWithoutFeedback>
              </View>
            )}
          </View>
          <View style={styles.buttonDistanceContainer}>
            <DistanceTabs onTabPress={onChangeTimeWithTab} />
          </View>
          {distance.value !== '' && (
            <View style={styles.timeSelectedContainer}>
              <Fragment>
                <TouchableOpacity onPress={getBack}>
                  <View>
                    <Image
                      source={chevronLeft}
                      style={{
                        tintColor: weekText.includes('Est') ? colors.slateGrey : colors.greyLight,
                        width: 20,
                        height: 20
                      }}
                    />
                  </View>
                </TouchableOpacity>

                <Text style={textStyles.semi16slateGrey}>{weekText}</Text>

                <TouchableOpacity onPress={getNext}>
                  <View>
                    <Image
                      source={chevronRight}
                      style={{
                        tintColor: weekText.includes('Est') ? colors.greyLight : colors.slateGrey,
                        width: 20,
                        height: 20
                      }}
                    />
                  </View>
                </TouchableOpacity>
              </Fragment>
            </View>
          )}
        </View>

        {leaderData.length ? (
          <ShadowView style={styles.shadowContainer}>
            <View style={styles.leaderBoardContent}>
              {leaderData.map((item, index) => (
                <RankUserCard
                  key={item.id}
                  user={item}
                  filter={filter}
                  isLast={index === lastIndex}
                  position={index}
                />
              ))}
            </View>
          </ShadowView>
        ) : (
          <View style={{ alignItems: 'center', marginTop: 20, paddingHorizontal: 40 }}>
            <CustomIcon name={'game_point'} size={30} color={colors.greyBlue42} />
            <Text style={[textStyles.bold14grey, textStyles.center]}>
              {'Não há nenhuma pontuação\npara este período.'}
            </Text>
          </View>
        )}
      </ScrollView>
      <GamificationAboutPoints open={isOpenModal} onClose={handleOpenOrCloseModal} />
    </View>
  );
};

const styles = StyleSheet.create({
  headerContainer: {
    display: 'flex',
    // height: 168,
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 20,
    paddingBottom: 10,
  },
  titleContainerInput: {
    position: 'relative',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    paddingBottom: 10,
  },
  searchContainer: {
    backgroundColor: 'white',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: colors.veryLightPinkThree,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 15,
  },
  searchStyle: {
    tintColor: colors.aquaMarineTwo,
  },
  buttonDistanceContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingBottom: 10,
  },
  tab: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 20,
    flex: 1,
    height: 40,
    borderBottomWidth: 2,
    borderBottomColor: 'transparent',
    borderRadius: 10,
    backgroundColor: colors.white,
  },
  tabText: {
    fontSize: 14,
    fontFamily: fonts.MEDIUM,
    color: colors.greyBlue,
  },
  activeTab: {
    backgroundColor: colors.aquaMarineTwo,
  },
  activeTabText: {
    color: colors.white,
  },
  timeSelectedContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 100,
    marginTop: 10,
  },
  shadowContainer: {
    margin: 20,
    marginBottom: 80,
    backgroundColor: 'transparent',
    borderRadius: 12,
    shadowColor: colors.black,
    shadowOpacity: 0.1,
    shadowRadius: 5,
    shadowOffset: { width: 0, height: 5 },
  },
  leaderBoardContent: {
    backgroundColor: colors.white,
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
  },
  usarCardContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 15,
    paddingVertical: 10,
    borderBottomWidth: 2,
    borderBottomColor: colors.softGray,
  },
  lastUserCardContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 15,
    paddingVertical: 10,
  },
  img: {
    resizeMode: 'contain',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  profileImg: {
    resizeMode: 'cover',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 50,
    marginLeft: 10,
    marginRight: 10,
  },
  nameAndPointsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  pointsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  badgeText: {
    fontFamily: fonts.BOLD,
    fontSize: 18,
    width: 30,
    textAlign: 'center',
    color: colors.aquaMarineTwo,
  },
  input: {
    flex: 1,
    height: 40,
    marginHorizontal: 5,
    fontFamily: fonts.REGULAR,
    fontSize: 12,
  },
  searchInputStyle: {
    tintColor: colors.aquaMarineTwo,
  },
  close: {},
});
