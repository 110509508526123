/* eslint-disable react-native/no-inline-styles */
import CapsuleCalsNew from 'app/components/CapsuleCalsNew';
import CapsuleClockCard from 'app/components/CapsuleClockCard';
import CapsuleLevelNew from 'app/components/CapsuleLevelNew';
import FavoriteButtonShow from 'app/components/FavoriteButtonShow';
import MaskLevelPaymentNew from 'app/components/MaskLevelPaymentNew';
import TagCard from 'app/components/TagCard';
import api from 'app/services/api';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { ImageBackground, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import ShadowView from '@liuyunjs/react-native-simple-shadow-view';
import Minus from '../../../components/Minus';
import Plus from '../../../components/Plus';
import colors from '../../../styles/colors';
import fonts from '../../../styles/fonts';
import fallback_food from 'app/images/fallback_food3.jpg';
import fallback_fitness from 'app/images/fallback_fitness.jpg';
import fallback_mind from 'app/images/fallback_mind.jpg';
const CardClassesNew = ({
  type,
  style,
  containerStyle,
  title,
  image,
  difficulty,
  duration = 5,
  durationInWeek,
  kcal,
  plus,
  minus,
  gradientColor = 'none',
  paymentLevel,
  onPress,
  onPressAdd,
  favorite,
  id,
  goals,
  category,
  isAgenda,
}) => {
  const [thumbnail, setThumbnail] = useState({
    uri: image,
    headers: {
      Authorization: api.defaults.headers.Authorization,
    },
  });

  const handleFallbackImage = () => {
    if (gradientColor === 'red') {
      setThumbnail(fallback_food);
    } else if (gradientColor === 'green') {
      setThumbnail(fallback_fitness);
    } else {
      setThumbnail(fallback_mind);
    }
  };

  const getBackgroundColor = () => {
    if (gradientColor === 'red') {
      return colors.darkRed;
    }
    if (gradientColor === 'green') {
      return colors.aquaMarineTwo;
    }
    if (gradientColor === 'orange') {
      return colors.brightOrange;
    }
    return colors.transparent;
  };
  return (
    <TouchableOpacity
      activeOpacity={0.6}
      style={containerStyle}
      onPress={onPress}
      disabled={!onPress}>
      {plus && <Plus onPress={onPressAdd} style={{ marginTop: 30 }} />}
      {minus && <Minus onPress={onPressAdd} style={{ marginTop: 30 }} />}
      {isAgenda && <Plus onPress={onPressAdd} style={{ top: 10, right: 10 }} />}
      {!isAgenda && typeof favorite !== undefined && (
        <View style={{ position: 'absolute', zIndex: 2, top: 10, right: 10 }}>
          <FavoriteButtonShow
            // eslint-disable-next-line eqeqeq
            isTrail={category == 1}
            isFavorited={favorite}
            // eslint-disable-next-line eqeqeq
            propType={category == 1 ? 'trailId' : 'classId'}
            itemId={id}
          />
        </View>
      )}
      {paymentLevel === 1 && (
        <MaskLevelPaymentNew containerStyles={{ width: '100%', height: '100%' }} />
      )}
      <ShadowView style={[styles.card, style]}>
        <ImageBackground
          source={thumbnail}
          imageStyle={{
            borderRadius: 10,
          }}
          onError={currentTarget => {
            currentTarget.onError = null;
            handleFallbackImage();
          }}
          style={{
            paddingHorizontal: 9,
            paddingVertical: 9,
            justifyContent: 'flex-start',
            height: 117,
            width: 163,
          }}>
          {/* <LinearGradient colors={[colors.transparent, getGradientColor()]} style={styles.linear} /> */}
          <View style={[styles.typeWrapper, { backgroundColor: getBackgroundColor() + 'CC' }]}>
            <Text style={styles.types} numberOfLines={1}>
              {type}
            </Text>
          </View>
        </ImageBackground>
        <View style={styles.white}>
          <View style={styles.capsuleWrapper}>
            {!!difficulty && !kcal && (
              <CapsuleLevelNew
                difficulty={difficulty}
                style={{
                  backgroundColor: colors.white,
                  borderColor: '#D7D7D7',
                  borderWidth: 1,
                }}
              />
            )}
            {!!kcal && <CapsuleCalsNew kcal={kcal} />}
            <CapsuleClockCard
              isWeek={durationInWeek}
              style={{
                backgroundColor: colors.white,
                borderColor: '#D7D7D7',
                borderWidth: 1,
              }}
              duration={duration}
            />
          </View>
          <Text numberOfLines={2} style={[styles.title]}>
            {title}
          </Text>
          <View style={{ flexDirection: 'row' }}>
            {goals.length > 0
              ? goals
                  .slice(0, 1)
                  .map(goal => <TagCard style={{ marginTop: 6 }} title={goal.goal_name} />)
              : null}
          </View>
        </View>
      </ShadowView>
    </TouchableOpacity>
  );
};

export default CardClassesNew;

CardClassesNew.propTypes = {
  minus: PropTypes.bool,
  onPress: PropTypes.func,
  plus: PropTypes.bool,
  style: PropTypes.any,
  title: PropTypes.string,
  type: PropTypes.oneOf(['meditation', 'activity', 'meal', 'masterclass']),
};

const styles = StyleSheet.create({
  content: {
    fontSize: 14,
    fontFamily: fonts.REGULAR,
    color: colors.blackTwo,
    marginHorizontal: 16,
    marginTop: 16,
  },
  linear: {
    position: 'absolute',
    width: 163,
    height: 117,
    zIndex: 1,
    borderRadius: 10,
  },
  title: {
    marginTop: 8,
    fontFamily: fonts.BOLD,
    fontSize: 14,
    lineHeight: 17,
    color: colors.charcoalGreyTwo,
    minHeight: 34,
  },
  typeWrapper: {
    maxWidth: '70%',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingHorizontal: 8,
    paddingVertical: 4,
    alignSelf: 'flex-start',
    marginTop: 'auto',
    borderRadius: 4,
  },
  card: {
    width: 163,
    height: 224,
    backgroundColor: colors.white,
    borderRadius: 12,
    shadowColor: 'black',
    shadowOpacity: 0.1,
    shadowRadius: 5,
    shadowOffset: { width: 0, height: 5 },
  },
  capsuleWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  white: {
    paddingVertical: 13,
    paddingHorizontal: 7,
    height: 110,
  },
  maskContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  types: {
    color: colors.white,
    fontSize: 9,
    fontFamily: fonts.BOLD,
  },
});
