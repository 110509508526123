import React, { useState } from 'react';
import { StyleSheet, Text, View, ScrollView, Modal } from 'react-native';
import Spacer from '../../../../components/Spacer';
import { translate } from '../../../../i18n/src/locale';
import pt from '../../../../i18n/src/locale/pt';
import HeaderTitle from '../../../profile/components/HeaderTitle';
import colors from '../../../../styles/colors';
import ModalChangeIngredient from './ModalChangeIngredient';
import textStyles from '../../../../styles/textStyles';
import CapsuleLevel from '../../../../components/CapsuleLevel';
import Chip from '../../../../components/Chips';
import commons from '../../../../styles/commons';
// import AddCalendar from '../../explorer/components/AddCalendar';
import CardIngredientChange from '../../explorer/components/CardIngredientChange';
import RowPlateInternal from './RowPlateInternal';
import CapsuleHour from 'app/components/CapsuleHour';
import CardRestriction from 'app/screens/core/meal/components/CardRestriction';
import { useNavigation } from '@react-navigation/native';
// import { Actions } from 'react-native-router-flux';

const MealInternal = ({ meal, isVisible }) => {

  const navigation = useNavigation();

  const [modal, setModal] = useState(false);

  const openModal = () => {
    setModal(true);
  };

  if (!isVisible) {
    return <View />;
  }

  return (
    <>
      <View style={{ backgroundColor: colors.paleGrey }}>
        <View>
          <Spacer />

          <View style={styles.content}>
            <Text style={textStyles.regular14}>{meal.description}</Text>
          </View>

          <View style={styles.capsuleHolder}>
            <CapsuleHour style={styles.capsule} duration={meal.meal_time_minutes} timeIn={'min'} />
            <CapsuleLevel style={styles.capsule} difficulty={meal.level} />
          </View>

          <View style={styles.content}>
            <View style={styles.insideContainerRow}>
              {meal?.tags?.map(tag => {
                return <Chip title={tag.name} />;
              })}
            </View>
          </View>

          <View style={styles.content}>
            {meal?.user_dietary_restrictions?.length > 0 && (
              <CardRestriction restrictions={meal?.user_dietary_restrictions} />
            )}
          </View>

          {/* <View style={styles.content}>
            <View style={commons.flexRowBetween}>
              <AddCalendar style={styles.add} typeSchedule="meal" id={meal.id} /> */}
              {/* <Spacer value={10} />
              <AddPlaylist style={styles.add} typeItem="meal" id={meal.id} /> */}
            {/* </View>
          </View> */}
          <Spacer value={10} />

          <HeaderTitle title={translate(pt.plates)} />

          <ScrollView horizontal contentContainerStyle={styles.content}>
            {meal?.dishes_recipes?.map(dishesMeal => {
              return (
                <RowPlateInternal
                  dishes={dishesMeal}
                  style={styles.row_meal}
                  onPress={() => navigation.navigate('PlateDetail',{ plateId: dishesMeal.id, mealId: meal.id })}
                />
              );
            })}
          </ScrollView>

          <HeaderTitle title={translate(pt.nutritional_value)} />
          <View style={styles.content_cards}>
            {meal?.nutritional_info?.map(nutritional => (
              <View style={styles.smallCard}>
                <Text style={textStyles.bold14}>{`${nutritional.calc_values.slice(0, -3)} ${
                  nutritional.unit_short_name
                }`}</Text>
                <Text numberOfLines={1} style={textStyles.regular11}>
                  {nutritional.type_name}
                </Text>
              </View>
            ))}
          </View>

          <HeaderTitle title={translate(pt.ingredients).toUpperCase()} />

          <View style={styles.content}>
            {meal?.ingredients?.map(ingredient => {
              return (
                <CardIngredientChange
                  name={ingredient.name}
                  grams={ingredient.portion.slice(0, -3)}
                  unit={ingredient.measure_unit}
                  onPress={openModal}
                />
              );
            })}
          </View>
        </View>
        <Modal animationInTiming={600} visible={modal}>
          <ModalChangeIngredient
            onClose={() => {
              setModal(false);
            }}
          />
        </Modal>
      </View>
      {/* <ButtonBase
        backgroundColor={colors.orangeyRed}
        fill
        square
        onPress={Actions.shopping_car}
        title={translate(pt.add_to_shop_car)}
      /> */}
    </>
  );
};

export default MealInternal;

const styles = StyleSheet.create({
  smallCard: {
    width: '23%',
    marginRight: '2%',
    marginTop: 8,
    height: 53,
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.white,
  },
  content_cards: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    paddingHorizontal: 14,
    marginBottom: 14,
  },
  content: { paddingHorizontal: 14, marginBottom: 14 },
  row_meal: { width: 316, marginRight: 16 },
  capsuleHolder: {
    flexDirection: 'row',
    width: 170,
    marginTop: 10,
    marginBottom: 20,
    justifyContent: 'space-between',
    marginLeft: 16,
  },
  capsule: {
    backgroundColor: colors.whiteTwo,
    borderColor: '#eee',
    borderWidth: StyleSheet.hairlineWidth,
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 6,
    shadowOpacity: 0.2,
  },
  insideContainerRow: {
    marginTop: 10,
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 0,
  },
  add: { flex: 1, paddingHorizontal: 10 },
});
