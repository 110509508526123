/* eslint-disable react-native/no-inline-styles */
import Spacer from 'app/components/Spacer';
import APP from 'app/configs/app';
import picture_user_add from 'app/images/picture_user_add.png';
import api from 'app/services/api';
import { errorControl, showAlert } from 'app/services/utils';
import { updateProfileSuccess } from 'app/store/modules/user/actions';
import commons from 'app/styles/commons';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {
  Image,
  ImageBackground,
  InteractionManager,
  Platform,
  StatusBar,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import ImagePicker from 'react-native-image-crop-picker';
import { useDispatch, useSelector } from 'react-redux';
import HeaderProfile from '../../components/HeaderProfile';
import { translate } from '../../i18n/src/locale';
import pt from '../../i18n/src/locale/pt';
import blueTop from '../../images/blueTop.png';
import colors from '../../styles/colors';
import textStyles from '../../styles/textStyles';
// import ProfileView from './components/ProfileView';
import pontos from 'app/assets/gamification/imagens/pontos.png';
import ShadowView from '@liuyunjs/react-native-simple-shadow-view';
import { getUserRankingBagde } from 'app/screens/core/gamification/GamificationLeaderboard';
import { useSafeArea } from 'react-native-safe-area-context';
import { useNavigation } from '@react-navigation/native';
import ProfileView from './components/ProfileView';

const ProfileScreen = ({ route }) => {

  const { refresh, routeIndex = 0 } = route?.params;
  const navigation = useNavigation();

  const [open, setOpen] = useState(false);
  const [loading] = useState(false);
  const [image, setImage] = useState(picture_user_add);
  const dispatch = useDispatch();
  const { profile: user } = useSelector(state => state.user);
  const { userData } = useSelector(state => state.gamification);

  const safeAreaInsets = useSafeArea();

  useEffect(() => {
    if (user && user.user_picture) {
      setImage({
        uri: user.user_picture,
        headers: {
          Authorization: api.defaults.headers.Authorization,
        },
      });
    }
  }, [user, refresh]);

  React.useState([
    { key: 'profile', title: translate(pt.profile) },
    { key: 'favorites', title: translate(pt.favorites) },
    { key: 'settings', title: translate(pt.configuration) },
  ]);

  const startUpload = async photo => {
    const config = {
      headers: {
        ContentType: 'multipart/form-data',
        Authorization: api.defaults.headers.Authorization,
        GUID: api.defaults.headers.GUID,
      },
      onUploadProgress(progressEvent) {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

        if (percentCompleted === 100) {
        }
      },
    };

    const formData = new FormData();
    formData.append('upload', photo, 'foto.jpg');

    try {
      const {
        data: { path },
      } = await axios.post(`${APP.URL_BASE}file/user-picture`, formData, config);

      dispatch(updateProfileSuccess({ ...user, user_picture: path }));
    } catch (error) {
      showAlert(errorControl(error));
    }
  };

  const getImage = () => {
    ImagePicker.openPicker({
      width: 300,
      height: 300,
      cropperCircleOverlay: true,
      cropping: true,
      cropperToolbarTitle: translate(pt.edit_photo),
      cropperChooseText: translate(pt.choose),
      cropperCancelText: translate(pt.cancel),
    }).then(imagePromise => {
      startUpload({
        uri: Platform.OS === 'ios' ? `file://${imagePromise.path}` : imagePromise.path,
        path: Platform.OS === 'ios' ? `file://${imagePromise.path}` : imagePromise.path,
        type: 'image/jpeg',
        name: 'foto.jpg',
      });
      setImage({
        uri: image.path,
        headers: {
          Authorization: api.defaults.headers.Authorization,
        },
      });
    });
  };

  InteractionManager.runAfterInteractions(() => {
    setOpen(true);
  });

  return (
    <View style={{ flex: 1, backgroundColor: colors.paleGrey }}>
      <StatusBar backgroundColor="transparent" translucent barStyle="dark-content" />
      <ImageBackground
        source={blueTop}
        resizeMode="stretch"
        style={{ height: 220 + safeAreaInsets.top, paddingBottom: 20 }}>
        <HeaderProfile onPress={() => navigation.navigate('Início')} />
        {user && !loading && (
          <View animation="fadeInDown" style={{ ...styles.holderNome }}>
            <View style={commons.flex}>
              <TouchableOpacity onPress={() => navigation.navigate('EditName')}>
                <View style={styles.nameContainer}>
                  <Text style={textStyles.bold20}>{user.name}</Text>
                  {user &&
                    user.b2b_user &&
                    userData?.rank <= 3 &&
                    getUserRankingBagde(userData?.rank - 1)}
                </View>
              </TouchableOpacity>
              <View style={{ width: 170, marginTop: 10 }}>
                {user && user.b2b_user && (
                  <ShadowView style={styles.shadowContainer}>
                    <Image
                      style={[styles.img, { marginRight: 5, marginTop: 2 }]}
                      source={pontos}
                      width={15}
                      height={15}
                    />
                    <Text style={textStyles.bold16grey}>
                      {userData?.points || '0'}{' '}
                      <Text style={textStyles.semi12brownGrey}>{translate(pt.points)}</Text>
                    </Text>
                  </ShadowView>
                )}

                {user.weight > 0 && user.height > 0 && (
                  <View style={styles.heightWeight}>
                    <TouchableOpacity onPress={() => navigation.navigate('AddWeight')}>
                      <View
                        style={{
                          width: '100%',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}>
                        <ShadowView
                          style={{ ...styles.shadowContainer, flex: 1, paddingHorizontal: 15 }}>
                          <Text style={{ ...textStyles.bold14grey }}>{`${user.weight}kg`}</Text>
                        </ShadowView>
                        <Spacer value={10} />
                        <ShadowView
                          style={{ ...styles.shadowContainer, flex: 1, paddingHorizontal: 15 }}>
                          <Text style={{ ...textStyles.bold14grey }}>{`${(
                            user.height / 100
                          ).toFixed(2)}m`}</Text>
                        </ShadowView>
                      </View>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => navigation.navigate('Historic')}>
                      <Text
                        style={{
                          ...textStyles.createAccount,
                          color: colors.aquaMarineTwo,
                          marginTop: 8,
                        }}>
                        {`${translate(pt.see_history)}`}
                      </Text>
                    </TouchableOpacity>
                  </View>
                )}
              </View>
              {!user.weight && !user.height && (
                <TouchableOpacity onPress={() => navigation.navigate('AddWeight')}>
                  <Text style={{ ...styles.addWeight, marginTop: 8 }}>
                    {translate(pt.add_weight)}
                  </Text>
                </TouchableOpacity>
              )}
            </View>

            <TouchableOpacity onPress={getImage} style={styles.whiteCircle}>
              <Image
                source={image}
                style={{ width: 100, height: 100, backgroundColor: 'white', borderRadius: 50 }}
              />
            </TouchableOpacity>
          </View>
        )}
      </ImageBackground>

      <ProfileView />

      {/* <TabView
        lazy
        renderTabBar={props =>
          RenderTabBar({
            ...props,
            email: user.email,
          })
        }
        navigationState={{ index, routes }}
        renderScene={renderScene}
        onIndexChange={setIndex}
        initialLayout={initialLayout}
      /> */}
    </View>
  );
};

export default ProfileScreen;

const styles = StyleSheet.create({
  whiteCircle: {
    width: 108,
    height: 108,
    marginRight: 28,
    marginTop: -28,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: 54,
  },

  nameContainer: {
    flexDirection: 'row',
  },

  shadowContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 10,
    padding: 5,
    backgroundColor: colors.white,
    shadowColor: colors.black,
    shadowOpacity: 0.1,
    shadowRadius: 5,
    shadowOffset: { width: 0, height: 5 },
  },

  pointsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colors.white,
    borderRadius: 10,
  },

  img: {
    resizeMode: 'contain',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  holderNome: {
    marginTop: 0,
    marginLeft: 28,
    alignItems: 'center',
    flex: 1,
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  heightWeight: {
    marginTop: 10,
  },
  addWeight: {
    color: colors.aquaMarineTwo,
    fontWeight: '700',
    fontSize: 14,
  },
});
