import React from 'react';

import { View, Text, StyleSheet, Image, TouchableOpacity } from 'react-native';
import textStyles from '../../../../styles/textStyles';
import CapsuleLevel from '../../../../components/CapsuleLevel';
import CapsuleClock from '../../../../components/CapsuleClock';
import colors from '../../../../styles/colors';
import activityBall from '../../../../images/activityBall.png';
import meditationBall from '../../../../images/meditationBall.png';
import Plus from '../../../../components/Plus';
import Minus from '../../../../components/Minus';
import api from 'app/services/api';
import MaskLevelPayment from 'app/components/mask-level-payment';

const RowClass = ({
  style,
  image,
  name,
  difficulty,
  duration,
  category = 2,
  plus,
  minus,
  checked,
  onPress,
  paymentLevel,
  onPressAdd,
}) => (
  <TouchableOpacity onPress={onPress} disabled={checked} style={[{ opacity: checked ? 0.5 : 1 }]}>
    {plus && <Plus onPress={onPressAdd} style={styles.top10} />}
    {minus && <Minus onPress={onPressAdd} style={styles.top10} />}

    <View style={[styles.card, style]}>
      {paymentLevel === 1 && <MaskLevelPayment />}
      <Image
        source={{
          uri: image,
          headers: {
            Authorization: api.defaults.headers.Authorization,
          },
        }}
        style={styles.image}
      />
      <View style={styles.holder}>
        <Text numberOfLines={2} style={[textStyles.semi14, styles.right0]}>
          {name}
        </Text>

        <View style={styles.capsules}>
          <CapsuleLevel difficulty={difficulty} />
          <CapsuleClock duration={duration} />
          <Image style={styles.ball} source={category === 2 ? activityBall : meditationBall} />
        </View>
      </View>
    </View>
  </TouchableOpacity>
);

export default RowClass;

const styles = StyleSheet.create({
  right0: { marginRight: 0 },
  top10: { marginTop: 10 },
  ball: { width: 29, height: 29 },
  capsules: {
    maxWidth: 210,
    alignSelf: 'stretch',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginTop: 16,
  },
  holder: {
    paddingVertical: 10,
    marginLeft: 12,
    paddingRight: 20,
    flex: 1,
    justifyContent: 'center',
  },
  image: {
    width: 81,
    borderTopLeftRadius: 3,
    borderBottomLeftRadius: 3,
    backgroundColor: colors.base,
  },
  card: {
    flexDirection: 'row',
    marginBottom: 14,
    borderRadius: 3,
    backgroundColor: colors.white,
  },
});
