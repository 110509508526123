export function updateProfileRequest(data) {
  return {
    type: '@user/UPDATE_PROFILE_REQUEST',
    payload: { data },
  };
}

export function updateProfileSuccess(profile) {
  return {
    type: '@user/UPDATE_PROFILE_SUCCESS',
    payload: { profile },
  };
}

export function updateReviewStatus(payload) {
  return {
    type: '@user/UPDATE_REVIEW_STATUS',
    payload: payload,
  };
}

export function setWidgets(widgets) {
  return {
    type: '@user/SET_WIDGETS',
    payload: { widgets },
  };
}

export function updateProfileFailure(error) {
  return {
    type: '@user/UPDATE_PROFILE_FAILURE',
    payload: { error },
  };
}
export function hideUserError() {
  return {
    type: '@user/HIDE_ERROR',
  };
}
export function setMyHome(home) {
  return {
    type: '@user/SET_MY_HOME',
    payload: { home },
  };
}

export function setDayHome(day) {
  return {
    type: '@user/SET_DAY_HOME',
    payload: { day },
  };
}

export function reloadUser(user) {
  return {
    type: '@auth/SIGN_IN_SUCCESS',
    payload: { user },
  };
}

export function setIosSynced(synced) {
  return {
    type: '@user/SET_IOS_SYNCED',
    payload: { synced },
  };
}

export function handleFavorites(favorites) {
  return {
    type: '@user/HANDLE_FAVORITES',
    payload: { favorites },
  };
}

export function setCalendar(calendar_id) {
  return {
    type: '@user/SET_CALENDAR',
    payload: { calendar_id },
  };
}

export function setModalNotInternetVisible(modalNotInternetVisible) {
  return {
    type: '@user/SET_MODAL_NOT_INTERNET_VISIBLE',
    payload: { modalNotInternetVisible },
  };
}

export function doReloadFavorites() {
  return {
    type: '@user/DO_RELOAD_FAVORITES',
    payload: {},
  };
}

export function setTabProfessional(value) {
  return {
    type: '@user/SET_TAB_PROFESSIONAL',
    payload: { value },
  };
}

export function setFirstProfessionalEntering(value) {
  return {
    type: '@user/SET_FIRST_PROFESSIONAL_ENTERING',
    payload: { value },
  };
}

export function doReloadSessions() {
  return {
    type: '@user/DO_RELOAD_SESSIONS',
    payload: {},
  };
}
