import React, { useEffect, useRef, useState } from 'react';
import { View, Text, StyleSheet, Image, TouchableOpacity, StatusBar } from 'react-native';
import { useSafeArea } from 'react-native-safe-area-context';
import Icon from 'react-native-vector-icons/dist/Feather';
import IconAwesome from 'react-native-vector-icons/dist/FontAwesome';
import parceiro from '../../../../images/icon_parceiro_borderless.png';

import colors from 'app/styles/colors';
import textStyles from 'app/styles/textStyles';

import noPhotoImage from '../../../../images/no-photo.jpg';
import fonts from 'app/styles/fonts';
import { normalize } from 'app/services/utils';
import { useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';

const ChatHeader = props => {
  const navigation = useNavigation();

  const { profile } = useSelector(state => state.user);
  return (
    <View>
      <StatusBar backgroundColor={colors.aquaMarineTwo} barStyle={'light-content'} />
      <View style={styles.bar}>
        {!props.showBackButton ? (
          <View style={styles.backButton} />
        ) : (
          <TouchableOpacity style={styles.backButton} onPress={props.onPress || (() => navigation.goBack())}>
            <Icon name="chevron-left" size={30} color={colors.white} />
          </TouchableOpacity>
        )}
        {props.selectedMessages.length === 0 ? (
          <View style={styles.containerProfisional}>
            {props.userProfile?.isPartner && profile.b2b_user && (
              <Image style={styles.imgCorp} source={parceiro} />
            )}
            <Image
              style={styles.image}
              source={
                props.userProfile?.profileImageUrl
                  ? { uri: props.userProfile?.profileImageUrl }
                  : noPhotoImage
              }
            />

            <Text style={[textStyles.semi14, styles.name]}>{props.userProfile?.name}</Text>
          </View>
        ) : (
          <View style={styles.containerSelected}>
            <Text style={styles.selectedText}>
              {`${props.selectedMessages.length} selecionado${
                props.selectedMessages.length > 1 ? 's' : ''
              }`}
            </Text>
            <TouchableOpacity style={{ marginRight: 20 }} onPress={props.deleteMessages}>
              <IconAwesome name="trash" size={20} color={colors.white} />
            </TouchableOpacity>
          </View>
        )}
        {/* <TouchableOpacity style={styles.optionsButton} onPress={showMenu} ref={buttonRef}>
          <Icon name="more-vert" size={30} color={colors.white} />
        </TouchableOpacity> */}
      </View>

      {/* <Menu ref={menuRef} style={styles.menu}>
        <MenuItem onPress={() => refRBSheet.current.open()}>
          <Text style={styles.menuText}>{translate(pt.reportAbuse)}</Text>
        </MenuItem>
        <MenuDivider />
        <MenuItem onPress={() => {}}>
          <Text style={styles.menuText}>{translate(pt.silenceUser)}</Text>
        </MenuItem>
        <MenuDivider />
      </Menu> */}

      {/* <RBSheet
        ref={refRBSheet}
        height={600}
        closeOnDragDown={true}
        closeOnPressMask={false}
        customStyles={{
          wrapper: {
            backgroundColor: colors.blackAlpha18,
          },
          container: {
            borderTopRightRadius: 50,
            borderTopLeftRadius: 50,
          },
          draggableIcon: {
            backgroundColor: colors.black,
          },
        }}>
        <Report />
      </RBSheet> */}
    </View>
  );
};

const styles = StyleSheet.create({
  bar: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: colors.aquaMarineTwo,
    padding: 30,
  },
  selectedText: {
    fontFamily: fonts.BOLD,
    fontSize: 18,
    color: colors.white,
  },
  backButton: {
    marginLeft: -20,
    marginRight: 15,
    marginTop: -10,
    height: 30,
    width: 30,
  },
  containerSelected: {
    flex: 1,
    height: 30,
    marginTop: -5,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  optionsButton: {
    marginRight: -25,
    marginLeft: 15,
    marginTop: -10,
    height: 30,
    width: 30,
  },
  containerProfisional: {
    flex: 1,
    height: 30,
    marginTop: -5,
    flexDirection: 'row',
    alignItems: 'center',
  },
  name: {
    color: colors.white,
    marginLeft: 20,
    fontSize: 17,
    fontWeight: '400',
    top: -5,
  },
  image: {
    top: -5,
    height: 34,
    width: 34,
    borderRadius: 20,
  },
  menuText: {
    paddingHorizontal: 10,
  },
  menu: {
    marginTop: -10,
    marginLeft: 200,
  },
  imgCorp: {
    position: 'absolute',
    top: normalize(-8),
    left: normalize(-4),
    zIndex: 1000,
    height: normalize(17),
    width: normalize(17),
  },
});

export default ChatHeader;
