import commons from 'app/styles/commons';
import fonts from 'app/styles/fonts';
import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Image, Alert, Linking } from 'react-native';
import closeImage from '../../../../images/closeGrey.png';
import { translate } from '../../../../i18n/src/locale';
import pt from '../../../../i18n/src/locale/pt';
import ButtonBase from 'app/components/ButtonBase';
import colors from 'app/styles/colors';
import api from 'app/services/api';
import * as RNIap from 'react-native-iap';
import ButtonLink from 'app/components/ButtonLink';
import { updateProfileSuccess } from 'app/store/modules/user/actions';
import { useDispatch } from 'react-redux';
import { useNavigation } from '@react-navigation/native';

const BottomSheetConfirmiOS = ({ onClose, planValue, planPrice, invite, sku = 'plano_mensal' }) => {
  const [planId] = useState(sku);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const callIAP = async () => {
    setIsLoading(true);

    if (planId === 'plano_mensal') {
      await RNIap.getSubscriptions({ skus: ['plano_mensal'] });
    }
    try {
      await RNIap.requestSubscription({ sku: planId }, false);
    } catch (e) {
      console.log('ERRO', JSON.stringify(e, null, 2));
      if (e?.code !== 'E_USER_CANCELLED') {
        Alert.alert('Namu', pt.apple_subscribe_error);
      }
    }
    setIsLoading(false);
  };

  const validatePurchase = async receipt => {
    try {
      const { data } = await api.post('payment/ios/sync', {
        receipt: receipt,
      });

      reloadUsers();

      if (data.isValid) {
        onClose();

        navigation.navigate('PaymentCompleteiOS', {
          courseName: 'Plano Mensal',
          courseValue: plan.amount
        })
      }
    } catch (error) {
      console.log('SYNC ERR', JSON.stringify(error.response.data, null, 2));
    }
  };

  const callRedemptionIAP = async () => {
    Linking.openURL('https://apps.apple.com/redeem?ctx=offercodes&id=1544610300');
  };

  const reloadUsers = async () => {
    const { data: user } = await api.get('user');

    if (user) {
      dispatch(updateProfileSuccess(user));
    }
  };

  useEffect(() => {
    (async () => await RNIap.initConnection())();
    const purchaseUpdateSub = RNIap.purchaseUpdatedListener(async purchase => {
      try {
        validatePurchase(purchase.transactionReceipt);
      } catch (e) {
        console.log('TRANSACTION ERROR', JSON.stringify(e.response.data, null, 2));
      }
    });

    const purchaseErrorSub = RNIap.purchaseErrorListener(error => {
      console.log('ERROR', error);
    });

    return async () => {
      purchaseUpdateSub.remove();
      purchaseErrorSub.remove();
      await RNIap.endConnection();
    };
  }, []);

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Text style={styles.title}>{translate(pt.payment_confirmation)}</Text>
        <TouchableOpacity onPress={onClose} hitSlop={commons.hitSlop}>
          <Image source={closeImage} />
        </TouchableOpacity>
      </View>
      <View>
        <Text style={styles.title}>{translate(pt.purchase_summary)}</Text>
        <View style={[styles.row, { marginBottom: 6 }]}>
          <Text style={styles.summaryText}>Plano</Text>
          <Text style={styles.summaryInfo}>{planPrice || 'R$ 0,00'}</Text>
        </View>
        <View style={styles.divider} />
        <View style={styles.row}>
          <Text style={styles.summaryText}>{translate(pt.total)}</Text>
          <Text style={styles.summaryInfoTotal}>{planPrice || 'R$ 0,00'}</Text>
        </View>
      </View>
      <ButtonBase
        title={translate(pt.conclude)}
        loading={isLoading}
        onPress={callIAP}
        fill
        square
      />
      <ButtonLink
        title={translate(pt.i_have_a_discount_code)}
        onPress={callRedemptionIAP}
        containerStyle={{ width: '100%', alignItems: 'center', paddingVertical: 20 }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderTopEndRadius: 47,
    borderTopStartRadius: 47,
    paddingBottom: 10,
    padding: 20,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 5,
    marginBottom: 15,
  },
  title: {
    textAlign: 'center',
    fontFamily: fonts.BOLD,
    fontSize: 18,
  },
  offerInputRow: {
    display: 'flex',
    flexDirection: 'row',
    height: 47,
  },
  input: {
    height: 47,
    paddingHorizontal: 14,
    color: colors.black,
    borderColor: colors.lightPeriwinkle,
    borderWidth: 1,
    flex: 1,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 0,
  },
  applyOfferBtn: {
    height: 47,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 4,
    marginTop: 0,
  },

  loading: {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    position: 'absolute',
    zIndex: 99,
    //width: width,
    //height: height,
    justifyContent: 'center',
    alignItems: 'center',
  },
  screen: {
    backgroundColor: colors.whiteTwo,
  },
  paymentMethodsContainer: {
    paddingTop: 22,
    paddingHorizontal: 22,
    paddingBottom: 30,
  },
  paymentMethodTitle: {
    borderWidth: 2,
    borderColor: colors.cornflowerBlue,
    color: colors.cornflowerBlue,
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'center',
    lineHeight: 35,
    borderRadius: 4,
    marginTop: 15,
    marginBottom: 15,
    paddingHorizontal: 14,
  },
  card: {
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    backgroundColor: colors.white,
    paddingTop: 32,
    paddingHorizontal: 22,
    paddingBottom: 32 + 45,
    top: 30,
  },
  inputContainer: {
    flex: 1,
  },
  inputLabel: {
    fontWeight: 'bold',
    fontSize: 15,
    color: colors.blackThree,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  text: {
    fontSize: 12,
    color: colors.blackThree,
  },
  checkboxLabel: {
    fontSize: 12,
    fontWeight: 'bold',
    color: colors.greyBlue,
  },
  divider: {
    marginVertical: 22,
    height: 1,
    width: '100%',
    backgroundColor: colors.lightPeriwinkle,
  },
  title: {
    fontWeight: 'bold',
    fontSize: 15,
    color: colors.blackThree,
    marginBottom: 6,
  },
  summaryText: {
    fontSize: 14,
    fontWeight: '400',
    color: colors.black4,
    //maxWidth: Dimensions.get('screen').width - 100,
  },
  summaryInfo: {
    fontSize: 14,
    fontWeight: '700',
    color: colors.greyLight5,
  },
  summaryTextTotal: {
    fontSize: 14,
    fontWeight: '700',
    color: colors.blackThree,
    //maxWidth: DImensions.get('screen').width - 100,
  },
  summaryInfoTotal: {
    fontSize: 14,
    fontWeight: '700',
    color: colors.greyBlue,
  },
  concludeButtonContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
  concludeButton: {
    //width: Dimensions.get('screen').width,
    height: 45,
  },
});

export default BottomSheetConfirmiOS;
