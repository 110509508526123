import produce from 'immer';
import { translate } from 'app/i18n/src/locale';
import pt from 'app/i18n/src/locale/pt';

const INITIAL_STATE = {
  alarmsWaterOptions: [
    {
      id: 1,
      title: translate(pt.every_half_hour),
      isSelected: false,
    },
    {
      id: 2,
      title: translate(pt.every_hour),
      isSelected: false,
    },
    {
      id: 3,
      title: translate(pt.every_two_hours),
      isSelected: true,
    },
    {
      id: 4,
      title: translate(pt.every_four_hours),
      isSelected: false,
    },
    {
      id: 5,
      title: translate(pt.every_eight_hours),
      isSelected: false,
    },
    // {
    //   id: 4,
    //   title: 'Personalizado',
    //   isSelected: false,
    // },
  ],
  customAlarms: [],
};

export default function water(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@water/SELECT_OPTION_ALARM': {
        draft.alarmsWaterOptions = draft.alarmsWaterOptions
          .map(itemAlamWater =>
            itemAlamWater.isSelected === true
              ? { ...itemAlamWater, isSelected: false }
              : itemAlamWater
          )
          .map(itemAlamWaterSelect =>
            itemAlamWaterSelect.id === action.payload.id
              ? { ...itemAlamWaterSelect, isSelected: !itemAlamWaterSelect.isSelected }
              : itemAlamWaterSelect
          );
        break;
      }
      case '@water/ADD_ALL_ALARMS': {
        draft.customAlarms = action.payload.alarms;
        break;
      }
      case '@water/ADD_CUSTOM_ALARM': {
        draft.customAlarms = draft.customAlarms.concat(action.payload.alarm);
        break;
      }
      case '@water/REMOVE_CUSTOM_ALARM': {
        draft.customAlarms.splice(
          draft.customAlarms.findIndex(alarm => alarm === action.payload.alarm),
          1
        );
        break;
      }
    }
  });
}
