import axios from 'axios';
import APP from 'app/configs/app';
import moment from 'moment';
import { setupCache } from 'axios-cache-adapter';

const cache = setupCache({
  maxAge: 8 * 1000,
  exclude: { query: false },
});

const apiNode = axios.create({
  baseURL: APP.URL_BASE_NODE,
  headers: {
    'Content-Type': 'application/json',
    'Accept-Language': 'pt-br',
    Accept: 'application/json',
    'App-Version': APP.VERSION_NUMBER,
    'x-api-key': APP.API_KEY_NODE,
    'ngrok-skip-browser-warning': 1,
    timezone: moment().format('Z'),
    platform: 'web'
  },
  adapter: cache.adapter,
});

export default apiNode;
