export function setCall(uid, channelId) {
  return {
    type: '@call/SET_CALL',
    payload: { uid, channelId },
  };
}

export function unsetCall() {
  return {
    type: '@call/UNSET_CALL',
    payload: {},
  };
}

export function enableVideoCallOnTheTop() {
  return {
    type: '@call/ENABLE_VIDEO_CALL_ON_THE_TOP',
    payload: {},
  };
}

export function disableVideoCallOnTheTop() {
  return {
    type: '@call/DISABLE_VIDEO_CALL_ON_THE_TOP',
    payload: {},
  };
}
