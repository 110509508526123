import colors from 'app/styles/colors';
import React, { useEffect, useState } from 'react';
import { Image, StatusBar, StyleSheet, TouchableOpacity, View } from 'react-native';
import { useSelector } from 'react-redux';
import Icon from 'react-native-vector-icons/FontAwesome';
import profile_image from 'app/images/profile_image.png';
import api from 'app/services/api';
import SideMenuModal from 'app/screens/core/home/components/SideMenuModal';
import FavoritesView from 'app/screens/profile/components/FavoritesView';
import { useSafeArea } from 'react-native-safe-area-context';
import SearchBarNew from 'app/components/SearchBarNew';
import ShadowView from '@liuyunjs/react-native-simple-shadow-view';

const HeaderBlock = ({ children }) => {
  return <View style={styles.headerBlock}>{children}</View>;
};
export const MainHeader = ({
  Logo,
  children,
  noRightTray,
  searchBar,
  query,
  setQuery,
  handleSearch,
}) => {
  const safeAreaInsets = useSafeArea();
  const [isFavoritesOpen, setFavoritesOpen] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const { profile } = useSelector(state => state.user);
  const [businessLogo, setBusinessLogo] = useState({
    aspectRatio: null,
    logo: null,
  });

  useEffect(() => {
    if (profile?.business_logo) {
      Image.getSize(profile?.business_logo, (w, h) =>
        setBusinessLogo({ logo: { uri: profile?.business_logo }, aspectRatio: w / h })
      );
    }
  }, [profile?.business_logo]);

  return (
    <>
      <ShadowView style={styles.shadowContainer}>
        <View style={[styles.headerContainer, { paddingTop: safeAreaInsets.top }]}>
          <StatusBar translucent backgroundColor="transparent" barStyle="dark-content" />
          <View style={styles.headerContent}>
            <View style={[styles.headerBlock, { justifyContent: 'flex-start' }]}>
              <Image
                style={[styles.logo, { aspectRatio: businessLogo.aspectRatio }]}
                source={businessLogo.logo}
              />
            </View>
            <HeaderBlock>
              <Logo />
            </HeaderBlock>
            <View style={[styles.headerBlock, { justifyContent: 'flex-end' }]}>
              {!noRightTray && !searchBar && (
                <>
                  <TouchableOpacity
                    onPress={() => setFavoritesOpen(!isFavoritesOpen)}
                    style={{ marginRight: 24 }}>
                    <Icon
                      name={isFavoritesOpen ? 'heart' : 'heart-o'}
                      size={23}
                      color={isFavoritesOpen ? colors.frenchRose : colors.darkPro}
                    />
                  </TouchableOpacity>
                  {profile && (
                    <TouchableOpacity onPress={() => setMenuOpen(true)}>
                      <Image
                        style={styles.user}
                        source={
                          profile.user_picture
                            ? {
                                uri: profile.user_picture,
                                headers: {
                                  Authorization: api.defaults.headers.Authorization,
                                },
                              }
                            : profile_image
                        }
                      />
                    </TouchableOpacity>
                  )}
                </>
              )}
              {searchBar && (
                <View style={{ width: '100%' }}>
                  <SearchBarNew value={query} onChange={setQuery} onSearch={handleSearch} />
                </View>
              )}
            </View>
          </View>
        </View>
        {children}
      </ShadowView>
      <SideMenuModal open={isMenuOpen} onClose={() => setMenuOpen(false)} />
      {isFavoritesOpen && (
        <FavoritesView
          style={[
            styles.favoritePanel,
            {
              top: safeAreaInsets.top,
            },
          ]}
        />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  shadowContainer: {
    backgroundColor: colors.white,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    shadowColor: colors.black,
    shadowOpacity: 0.05,
    shadowRadius: 5,
    shadowOffset: { width: 0, height: 5 },
    zIndex: 9,
  },
  headerBlock: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  headerContainer: {
    backgroundColor: colors.white,
    paddingHorizontal: 20,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  headerContent: {
    width: '100%',
    height: 60,
    flexDirection: 'row',
    alignItems: 'center',
  },

  logo: {
    width: 100,
    maxHeight: 35,
    justifyContent: 'center',
    alignItems: 'center',
    resizeMode: 'contain',
  },
  user: {
    width: 28,
    height: 28,
    borderRadius: 28,
  },
  favoritePanel: {
    width: '100%',
    height: '100%',
    zIndex: 5,
    position: 'absolute',
  },
});
