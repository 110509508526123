import React, { useCallback, useEffect, useState } from 'react';

import { View, Text, StyleSheet, ActivityIndicator } from 'react-native';
import colors from '../../../../styles/colors';
import ButtonBase from '../../../../components/ButtonBase';
import ButtonBaseInvert from '../../../../components/ButtonBaseInvert';
import textStyles from '../../../../styles/textStyles';
import Spacer from '../../../../components/Spacer';
import { showAlert, errorControl, sendActionEvent } from 'app/services/utils';
import api from 'app/services/api';
import { useSelector } from 'react-redux';

const ModalRemove = ({ item, onClose }) => {
  const close = () => {
    onClose();
  };

  const { profile } = useSelector(state => state.user);

  const [isRecurrent, setIsRecurrent] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    requestIsRecurrent();
  }, [requestIsRecurrent]);

  const requestIsRecurrent = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await api.get('user-schedule/is-recurrent?entry_id=' + item.entry_id);
      setIsRecurrent(data.recurrent);
    } catch (error) {
      showAlert(errorControl(error));
    }
    setIsLoading(false);
  }, [item]);

  const deletePress = async () => {
    try {
      await api.delete('user-schedule', {
        params: {
          item_id: item.id,
          recurrence: 0,
        },
      });
      sendActionEvent(
        'remove_item_agenda',
        { name: item.name, email: profile.email },
        profile.email
      );
    } catch (error) {
      showAlert(errorControl(error));
    }

    close();
  };

  const deleteRecurrentPress = async () => {
    try {
      await api.delete('user-schedule', {
        params: {
          item_id: item.id,
          recurrence: 1,
        },
      });

      sendActionEvent(
        'remove_all_itens_agenda',
        { name: item.name, email: profile.email },
        profile.email
      );
      // flashInfo(data);
    } catch (error) {
      showAlert(errorControl(error));
    }

    close();
  };

  return (
    <View style={styles.container}>
      <Text style={[textStyles.semi16, { textAlign: 'center' }]}>
        {'Deseja realmente\nremover a atividade:'}
      </Text>

      <View style={styles.capsule}>
        <Text style={[textStyles.semi16, { textAlign: 'center' }]}>{item.name}</Text>
      </View>
      {isLoading && <ActivityIndicator color={colors.white} animating />}
      {isRecurrent ? (
        <>
          <ButtonBase
            onPress={deletePress}
            backgroundColor={colors.orangeyRed}
            style={styles.button}
            title="Somente essa"
          />
          <ButtonBaseInvert
            onPress={deleteRecurrentPress}
            color={colors.orangeyRed}
            style={styles.button}
            title="Todas as recorrências"
          />
          <ButtonBaseInvert
            onPress={close}
            color={colors.aquaMarine}
            style={styles.button}
            title="Voltar e manter"
          />
        </>
      ) : (
        <>
          <ButtonBase
            onPress={deletePress}
            backgroundColor={colors.orangeyRed}
            style={styles.button}
            title="Remover"
          />
          <ButtonBaseInvert
            onPress={close}
            color={colors.aquaMarine}
            style={styles.button}
            title="Voltar e manter"
          />
        </>
      )}
      <Spacer />
    </View>
  );
};

export default ModalRemove;

const styles = StyleSheet.create({
  capsule: {
    width: 246,
    height: 69,
    borderRadius: 34.5,
    marginVertical: 10,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.paleGrey,
  },
  button: {
    width: 184,
    paddingHorizontal: 0,
  },
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 24,
    width: 285,
    height: 363,
    borderRadius: 4,
    backgroundColor: colors.white,
    alignSelf: 'center',
  },
});
