import { Dimensions } from 'react-native';

const { width, height } = Dimensions.get('screen');
const anim = {
  fadeIn: {
    from: {
      translateY: Math.floor(height / 2 - 100),
    },
    to: {
      translateY: 0,
    },
  },
  fadeFromTop: {
    from: {
      translateY: -150,
    },
    to: {
      translateY: 0,
    },
  },

  drawerRight: {
    from: {
      translateX: width,
    },
    to: {
      translateX: 0,
    },
  },

  flippingAnimation: {
    0: {
      perspective: 600,
      rotateY: '90deg',
    },
    1: {
      perspective: 600,
      rotateY: '0deg',
    },
  },
  calendar: {
    0: {
      translateY: -330,
    },
    1: {
      translateY: 0,
    },
  },
};

export default anim;
