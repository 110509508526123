import colors from 'app/styles/colors';
import fonts from 'app/styles/fonts';
import React from 'react';
import { Dimensions, StyleSheet, Text, TouchableOpacity } from 'react-native';
import ShadowView from '@liuyunjs/react-native-simple-shadow-view';
import useWindowDimensions from '../hooks/useWindowDimensions';


const TabButton = ({ onPress, active, style, containerStyle, title }) => {

  const { width } = useWindowDimensions();

  return (
    <TouchableOpacity onPress={onPress} style={[styles.container, containerStyle]}>
      <ShadowView
        style={[
          styles.tabButton,
          { backgroundColor: active ? colors.aquaMarineTwo : colors.white,
          width: width / 3 - 22 },
          style,
        ]}>
        <Text
          style={{
            fontFamily: fonts.BOLD,
            fontSize: 12,
            color: active ? colors.white : colors.darkPro,
          }}>
          {title}
        </Text>
      </ShadowView>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    marginBottom: 9,
    marginLeft: 5,
  },
  tabButton: {
    backgroundColor: 'white',
    alignItems: 'center',
    justifyContent: 'center',
    height: 45,
    borderRadius: 10,
    shadowColor: 'black',
    shadowOpacity: 0.1,
    shadowRadius: 5,
    shadowOffset: { width: 0, height: 5 },
  },
});

export default TabButton;
