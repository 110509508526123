import React, { useRef } from 'react';
import { View, StyleSheet, FlatList, ImageBackground } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import colors from 'app/styles/colors';
import commons from 'app/styles/commons';

import ChatHeader from './components/ChatHeader';
import Messages from './components/Messages';
import MessageBar from './components/MessageBar';
import { useEffect } from 'react';

import { useState } from 'react';
import DocumentPicker from 'react-native-document-picker';
import { useSelector, useDispatch } from 'react-redux';
import { showAlert } from 'app/services/utils';
import { translate } from 'app/i18n/src/locale';
import pt from 'app/i18n/src/locale/pt';
import moment from 'moment';

import apiNode from 'app/services/apiNode';
import { disableVideoCallOnTheTop } from 'app/store/modules/call/actions';
import { useNavigation, useRoute } from '@react-navigation/native';

const background = require('app/images/backgroundNotes.png');

const ChatAttachmentsScreen = ({ route }) => {

  const navigation = useNavigation();

  const [userProId, setUserProId] = useState([]);
  const [messages, setMessages] = useState([]);
  const [chatData, setChatData] = useState({ id: null, userId: null });
  const [isSending, setIsSending] = useState(false);
  const [userProfile, setUserProfile] = useState();

  const dispatch = useDispatch();
  const call = useSelector(state => state.call);

  useEffect(() => {
    if (chatData?.id) {
      getMessages();
      getChatInfos();
    }
  }, [chatData]);

  useEffect(() => {
    createChat();
  }, []);

  const { profile } = useSelector(state => state.user);

  const getChatInfos = async () => {
    const { data } = await apiNode.get(`/chats/${chatData.id}`);
    console.log('🚀 ~ file: ChatAttachmentsScreen.js ~ line 49 ~ getChatInfos ~ data', data);
    setUserProfile(data.userPro);
  };

  const createChat = async () => {
    try {
      const { data } = await apiNode.post('/chats', {
        userProId: route?.params?.userProId,
        userId: profile.user_id,
      });
      setUserProId(data.userProId);
      setChatData(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getMessages = async () => {
    try {
      const { data } = await apiNode.get(`chat-messages/chat/${chatData.id}`);
      setMessages(data.reverse());
      updateMessages();
    } catch (error) {
      console.log(error);
    }
  };

  const updateMessages = async () => {
    try {
      await apiNode.post('chat-messages/update-messages', {
        sentBy: 'pro',
        chatId: chatData.id,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const AddMessages = async message => {
    setMessages(prevState => [message, ...prevState]);
  };

  const [percentage, setPercentage] = useState(0);
  const [userCanceled, setUserCanceled] = useState(false);

  const deleteCanceledMessage = () => {
    setTimeout(async () => {
      const { data } = await apiNode.get(`chat-messages/chat/${chatData.id}`);
      await apiNode.delete(`chat-messages/${data[data.length - 1].id}`);
    }, 500);
  };

  const routeHook = useRoute();

  const getNewMessages = async () => {
    if (routeHook.name === 'ChatAttachments') {
      const { data } = await apiNode.get(`chat-messages/chat/${chatData.id}`);

      if (!isSending && data.length > messages.length) {
        setMessages(data.reverse());
      }
      updateMessages();
    }
  };
  const [time, setTime] = useState(0);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      getNewMessages();
      setTime(timer + 1);
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, [time]);

  var uploadProgress = progressEvent => {
    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    // dispatch(setPercentage(percentCompleted));
    setPercentage(percentCompleted);
  };

  const selectDocument = async () => {
    try {
      setIsSending(true);
      const res = await DocumentPicker.pick({
        allowMultiSelection: false,
        type: [
          DocumentPicker.types.pdf,
          DocumentPicker.types.doc,
          DocumentPicker.types.docx,
          DocumentPicker.types.ppt,
          DocumentPicker.types.pptx,
          DocumentPicker.types.xls,
          DocumentPicker.types.xlsx,
          DocumentPicker.types.images,
        ],
      });

      if (res[0].size > 8388608) {
        showAlert(translate(pt.size_exceeds));
        setIsSending(false);
        return;
      }

      await AddMessages({
        isSending: true,
        name: res[0].name,
        size: res[0].size,
        date: moment(),
        sentBy: 'user',
        percentage: 0,
      });

      const formData = new FormData();
      formData.append('file', {
        uri: res[0].uri,
        type: res[0].type,
        name: res[0].name,
        size: res[0].size,
        percentage: 0,
      });
      formData.append('chatId', chatData.id);
      formData.append('sentBy', 'user');
      formData.append('userProId', chatData.userProId);
      formData.append('userId', profile.user_id);

      const response = await apiNode.post('chat-messages/chat-file', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: uploadProgress,
      });

      if (userCanceled) {
        deleteCanceledMessage();
      } else {
        setMessages(prevState => prevState.map((m, index) => (index === 0 ? response.data : m)));
      }
    } catch (error) {
      console.log(error);
      getMessages();
      showAlert(pt.error_while_upload);
    }
    setIsSending(false);
  };

  const cancelMessage = id => {
    setUserCanceled(true);
    setMessages(prevState => prevState.filter(message => message.id !== id));
  };

  const [selectedMessages, setSelectedMessages] = useState([]);

  const flatListRef = useRef();

  useEffect(() => {
    flatListRef.current.scrollToOffset({ animated: true, offset: 0 });
  }, [messages]);

  const deleteMessages = async () => {
    const promises = selectedMessages.map(
      async message => await apiNode.delete(`/chat-messages/${message.id}`)
    );
    promises.push(getMessages);

    await Promise.all(promises);

    setMessages(prevState =>
      prevState.filter(message => {
        let includes = false;
        selectedMessages.forEach(m => {
          if (m.id === message.id) {
            includes = true;
          }
        });
        return !includes;
      })
    );
    setSelectedMessages([]);
  };

  const onLongPress = (message, selected) => {
    if (selected) {
      setSelectedMessages(prevState => [...prevState, message]);
    } else {
      setSelectedMessages(selectedMessages.filter(mes => mes.id !== message.id));
    }
  };

  return (
    <View style={styles.container}>
      <SafeAreaView style={commons.flex}>
        <ImageBackground source={background} imageStyle={styles.background} style={{ flex: 1 }}>
          <ChatHeader
            userProfile={userProfile}
            onPress={() => {
              dispatch(disableVideoCallOnTheTop());
              navigation.goBack();
            }}
            deleteMessages={deleteMessages}
            selectedMessages={selectedMessages}
            showBackButton={!call.onTheTop}
          />
          <FlatList
            data={messages}
            renderItem={item => (
              <Messages
                item={item}
                userProId={userProId}
                cancelMessage={cancelMessage}
                onLongPress={onLongPress}
                selectedMessages={selectedMessages}
                percentage={percentage}
              />
            )}
            ref={flatListRef}
            inverted={true}
          />
          <MessageBar
            chatId={chatData.id}
            userId={profile}
            userProId={profile}
            selectDocument={selectDocument}
            isSending={isSending}
          />
        </ImageBackground>
      </SafeAreaView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.paleGrey,
    flex: 1,
  },
  messageBox: {
    //flex: 1,
    borderWidth: 1,
  },
  background: {
    width: 300,
    height: 500,
    marginLeft: 0,
    marginTop: '-16%',
  },
});

export default ChatAttachmentsScreen;
