/* eslint-disable react-native/no-inline-styles */
import React, { useState, useEffect } from 'react';

import { View, StyleSheet, Text, ScrollView } from 'react-native';

import pt from '../../i18n/src/locale/pt';
import { translate } from '../../i18n/src/locale';
import textStyles from '../../styles/textStyles';
import colors from '../../styles/colors';
import ButtonBase from '../../components/ButtonBase';
import { useSafeArea } from 'react-native-safe-area-context';
import commons from '../../styles/commons';
import HeaderAquamarine from '../../components/HeaderAquamarine';
import api from 'app/services/api';
import { sendActionEvent } from '../../services/utils';
import { ActivityIndicator } from 'react-native';
import { useSelector } from 'react-redux';
import RowItemSelect from 'app/screens/core/home/components/RowItemSelect';
import { useNavigation } from '@react-navigation/native';

const InterestsScreen = ({ route }) => {

  const { userInterests } = route?.params;

  const navigation = useNavigation();

  const [isLoading, setIsLoading] = useState(false);
  const [interests, setInterests] = useState([]);
  const { profile } = useSelector(state => state.user);
  const insetsSafeArea = useSafeArea();
  const numSelected = interests.filter(m => m.isSelected).length;

  useEffect(() => {
    getInterests();
  }, []);

  const getInterests = async () => {
    try {
      const { data: data } = await api.get('multi-language/interests/locale');
      setInterests(
        data.map(item => ({
          ...item,
          name: item.translation,
          // eslint-disable-next-line eqeqeq
          isSelected: !!userInterests.find(e => e.id == item.id),
        }))
      );
    } catch (error) {}
  };

  const selectInterest = interestId => {
    const newInterests = interests.map(interest => {
      // eslint-disable-next-line eqeqeq
      return interest.id == interestId
        ? {
            ...interest,
            isSelected: !interest.isSelected,
          }
        : interest;
    });
    setInterests(newInterests);
    sendActionEvent('profile_interest_update', { email: profile.email }, profile.email);
  };

  const saveInterests = async () => {
    setIsLoading(true);
    try {
      await api.post('user-interests', { data: getSelectedInterests() });
      navigation.goBack();
      // setTimeout(() => Actions.refresh({ refresh: Math.random() }));
    } catch (error) {
      console.log(error, null, 2);
      showAlert(errorControl(error));
    }
    setIsLoading(false);
  };

  const getSelectedInterests = () => {
    return interests
      .filter(e => e.isSelected)
      .map(e => ({
        id: e.id,
        interest_id: e.interest_id,
      }));
  };

  return (
    <View style={styles.container}>
      <HeaderAquamarine title={translate(pt.interests)} />
      {isLoading ? (
        <ActivityIndicator style={{ marginTop: 32 }} color={colors.aquaMarineTwo} />
      ) : (
        <View
          style={{
            flex: 1,
            paddingBottom: insetsSafeArea.bottom,
          }}>
          <ScrollView
            style={[commons.flex]}
            contentContainerStyle={{ justifyContent: 'center', flexGrow: 1, paddingTop: 20 }}>
            <View style={{ paddingHorizontal: 22 }}>
              <Text style={[textStyles.bold18, { textAlign: 'left' }]}>
                {translate(pt.select_interests)}
              </Text>
              <Text
                style={[
                  textStyles.regular16,
                  { textAlign: 'left', marginTop: 5, marginBottom: 20 },
                ]}>
                {translate(pt.about_interests_content)}
              </Text>

              {interests.map((item, index) => (
                <RowItemSelect
                  title={item?.name}
                  isSelected={item?.isSelected}
                  onPress={() => {
                    selectInterest(item.id);
                  }}
                  isEditable={true}
                />
              ))}
            </View>
          </ScrollView>
          <View style={{ justifyContent: 'center', padding: 22 }}>
            {numSelected > 0 && (
              <ButtonBase
                onPress={() => {
                  saveInterests();
                }}
                style={{ marginTop: 0, height: 49 }}
                fill
                loading={isLoading}
                title={translate(pt.continue)}
              />
            )}
          </View>
        </View>
      )}
    </View>
  );
};

export default InterestsScreen;

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    height: '100dvh',
    backgroundColor: colors.paleGrey,
  },
});
