import React from 'react';

import { View, Text, StyleSheet, Image, TouchableOpacity } from 'react-native';
import textStyles from '../../../../styles/textStyles';
import CapsuleClock from '../../../../components/CapsuleClock';
import colors from '../../../../styles/colors';
import activityBall from '../../../../images/activityBall.png';
import meditationBall from '../../../../images/meditationBall.png';
import ballFood from '../../../../images/ballFood.png';
import ShadowView from '@liuyunjs/react-native-simple-shadow-view';
import CapsuleDayNight from '../../../../components/CapsuleDayNight';
import MoreContextMenu from './MoreContextMenu';
import api from 'app/services/api';
import { useNavigation } from '@react-navigation/native';

const RowClassOver = ({ style, checked, item, onCloseModal }) => {
  const navigation = useNavigation();

  const getCategoryIcon = () => {
    if (item.category_item === 1) {
      return ballFood;
    }
    if (item.category_item === 2) {
      return activityBall;
    }
    if (item.category_item === 3) {
      return meditationBall;
    }
    return null;
  };

  const onPress = () => {
    if (item.class_payment_level === 1) {
      navigation.navigate('Paywall')
    } else {
      if (item.dish_id) {
        return navigation.navigate('PlateDetail', { plateId: item.dish_id })
      }
      
      if (item.meal_id) {
        return navigation.navigate('MealDetail', { mealId: item.meal_id, trailId: item.trail_id || null })
      }
      
      if (
        (!!item.class_id && item.category_item.id === 2) ||
        (item?.category_item === 2 && item?.class_id)
      ) {
        return navigation.navigate('LessonActivity', { lessonId: item.class_id, trailId: item.trail_id || null })
      }
      
      if (
        (!!item.class_id && item.category_item.id === 3) ||
        (item?.category_item === 3 && item?.class_id)
      ) {
        return navigation.navigate('LessonMeditation', { classId: item.class_id, trailId: item.trail_id || null })
      }
    }
  };

  return (
    <TouchableOpacity onPress={onPress} disabled={checked} style={{ opacity: checked ? 0.5 : 1, height: 100 }}>
      <ShadowView style={[styles.card, style]}>
        <View style={styles.imageContainer}>
          <Image
            source={{
              uri: item.image,
              headers: {
                Authorization: api.defaults.headers.Authorization,
              },
            }}
            style={styles.image}
          />
        </View>
        <View style={styles.holder}>
          <Text numberOfLines={2} style={[textStyles.semi14, { marginRight: 0 }]}>
            {item.name}
          </Text>

          <MoreContextMenu item={item} type={item.category_item} onClose={onCloseModal} />

          <View style={styles.capsules}>
            <CapsuleDayNight small period={item.day_period_item} time={item.start_date} />
            <CapsuleClock small duration={item.duration} noShadow={true} />
            <Image style={styles.ball} source={getCategoryIcon()} />
          </View>
        </View>
      </ShadowView>
    </TouchableOpacity>
  );
};

export default RowClassOver;

const styles = StyleSheet.create({
  ball: { width: 21, height: 21 },
  capsules: {
    maxWidth: 180,
    alignSelf: 'stretch',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginTop: 16,
  },
  holder: {
    paddingVertical: 10,
    marginLeft: 126,
    paddingRight: 20,
    flex: 1,
    justifyContent: 'center',
  },
  imageContainer: {
    position: 'absolute',
    width: 104,
    height: 73,
    left: 8,
    top: -6,
    borderRadius: 6,
    backgroundColor: colors.base,
  },
  image: {
    width: 104,
    height: 73,
    resizeMode: 'cover',
    borderRadius: 3,
  },
  card: {
    flexDirection: 'row',
    marginBottom: 16,
    borderRadius: 2,
    backgroundColor: colors.white,
    shadowColor: colors.black,
    marginHorizontal: 14,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 4,
    shadowOpacity: 0.1,
  },
});
