import React from 'react';

import { StyleSheet, Text } from 'react-native';
import fonts from '../styles/fonts';
import colors from '../styles/colors';
import ShadowView from '@liuyunjs/react-native-simple-shadow-view';

const CapsuleCategory = ({ style, category }) => {
  return (
    <ShadowView style={[styles.capsule, style]}>
      <Text numberOfLines={1} style={styles.capsuleText}>
        {category}
      </Text>
    </ShadowView>
  );
};

export default CapsuleCategory;

const styles = StyleSheet.create({
  capsuleText: {
    marginLeft: 4,
    fontFamily: fonts.REGULAR,
    fontSize: 12,
    color: colors.white,
  },
  capsule: {
    paddingHorizontal: 15,
    flexDirection: 'row',
    alignItems: 'center',
    height: 29,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: '#DBF5F559',
    backgroundColor: colors.black6,
    opacity: 0.6,
  },
});
