import React, { useEffect } from 'react';
import {
  View,
  Text,
  StyleSheet,
  ImageBackground,
  Dimensions,
  ScrollView,
  Image,
  TouchableOpacity,
} from 'react-native';
import { useSafeArea } from 'react-native-safe-area-context';
import LinearGradient from 'react-native-linear-gradient';
import { translate } from '../../../i18n/src/locale';
import pt from '../../../i18n/src/locale/pt';
import colors from 'app/styles/colors';
import commons from 'app/styles/commons';
import backgroundBlue from '../../../images/backgroundBlue.png';
import congratWoman from '../../../images/congratWoman.png';
import closeGrey from '../../../images/closeGrey.png';
import { sendActionEvent } from 'app/services/utils';
import { useSelector } from 'react-redux';
import fonts from 'app/styles/fonts';
import CardSummary from 'app/screens/core/payment/components/CardSummary';
import { CommonActions, useNavigation } from '@react-navigation/native';

const PaymentCompleteBillScreen = ({ route }) => {

  const navigation = useNavigation();

  const { courseName, courseValue, discountCode, discountValue } = route?.params;

  const safeAreaInsets = useSafeArea();

  const { profile } = useSelector(state => state.user);
  sendActionEvent(
    'payment_cc',
    {
      price: discountValue ? courseValue - discountValue : courseValue,
      email: profile.email,
    },
    profile.email
  );

  const resetApp = () => navigation.dispatch(CommonActions.reset({ index: 0, routes: [{name: 'Início'}]}));

  return (
    <View style={{ backgroundColor: '#F5F6FA' }}>
      <View
        style={{ left: 16, paddingTop: safeAreaInsets.top + 12, position: 'absolute', zIndex: 9 }}>
        <TouchableOpacity style={{ display: 'flex' }} onPress={resetApp}>
          <Image source={closeGrey} />
        </TouchableOpacity>
      </View>
      <ScrollView style={styles.container} contentContainerStyle={{ flexGrow: 1 }}>
        <ImageBackground
          source={backgroundBlue}
          style={[commons.flex, { height: '100%' }]}
          imageStyle={{ opacity: 0.5 }}>
          <View style={styles.content}>
            <View>
              <LinearGradient
                style={styles.textContainer}
                angle={65}
                useAngle
                colors={[colors.aquaMarineTwo, colors.aquaMarineGrd]}>
                <View style={styles.imageWomanWrapper}>
                  <Image source={congratWoman} />
                </View>
                <View style={{ position: 'absolute', bottom: 25, right: 0, left: 0 }}>
                  <Text style={[styles.textCongratulations, styles.textCenter]}>
                    {translate(pt.congratulations)}
                  </Text>
                  <Text style={[styles.textMessage, styles.textCenter]}>
                    {translate(pt.signature_success)}
                  </Text>
                </View>
              </LinearGradient>
              <View>
                <View style={styles.divider} />
                <CardSummary
                  courseName={courseName}
                  courseValue={courseValue}
                  discountValue={discountValue}
                  discountCode={discountCode}
                />
              </View>
            </View>
          </View>
        </ImageBackground>
      </ScrollView>
    </View>
  );
};

export default PaymentCompleteBillScreen;

const styles = StyleSheet.create({
  textCongratulations: {
    fontFamily: fonts.BOLD,
    fontSize: 24,
    color: colors.white,
    lineHeight: 32,
  },
  textMessage: {
    fontFamily: fonts.REGULAR,
    fontSize: 16,
    color: colors.white,
    lineHeight: 22,
  },
  containerSummary: {
    backgroundColor: colors.white,
    paddingHorizontal: 18,
    paddingTop: 26,
    paddingBottom: 40,
    width: 297,
    borderRadius: 4,
  },
  container: { height: '100%' },
  content: {
    paddingTop: 200,
    paddingBottom: 40,
    paddingHorizontal: 30,
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  imageWomanWrapper: {
    position: 'absolute',
    top: -130,
    right: 0,
    left: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    color: colors.blackThree,
    fontFamily: fonts.BOLD,
    fontSize: 21,
    marginBottom: 24,
  },
  textContainer: {
    marginBottom: 25,
    backgroundColor: colors.aquaMarineTwo,
    height: 178,
    width: 300,
    borderRadius: 12,
  },
  text: {
    color: colors.greyBlue,
    fontWeight: 'normal',
    fontSize: 14,
  },
  textCenter: {
    textAlign: 'center',
  },
  billUrlButton: {
    backgroundColor: 'transparent',
    marginBottom: 60,
  },
  billUrlButtonText: {
    color: '#00AAFF',
    fontSize: 15,
    fontWeight: 'normal',
    textDecorationLine: 'underline',
  },
  inputContainer: {
    marginTop: 10,
  },
  smallText: {
    fontSize: 12,
    color: colors.blackThree,
  },
  input: {
    marginTop: 9,
    marginBottom: 0,
    paddingHorizontal: 14,
    height: 90,
    width: Dimensions.get('screen').width / 2,
    lineHeight: 20,
    borderRadius: 5,
    backgroundColor: colors.white,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: colors.lightPeriwinkle,
  },
  CopyButton: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    height: 90,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 4,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  divider: {
    marginVertical: 22,
    height: 1,
    width: '100%',
    backgroundColor: colors.lightPeriwinkle,
  },
  summaryText: {
    fontSize: 14,
    fontFamily: fonts.REGULAR,
    lineHeight: 19,
    color: colors.blackThree,
  },
  summaryInfo: {
    fontSize: 14,
    fontFamily: fonts.BOLD,
    color: colors.greyBlue,
  },
});
