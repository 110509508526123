import React from 'react';

import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import colors from '../../../../styles/colors';
import ButtonBase from '../../../../components/ButtonBase';
import ButtonBaseInvert from '../../../../components/ButtonBaseInvert';
import textStyles from '../../../../styles/textStyles';
import fonts from '../../../../styles/fonts';
import Spacer from '../../../../components/Spacer';

const ModalRecurrent = ({ onClose, onPressRecurrence, onPressNoRecurrence, hour }) => {
  const close = () => {
    onClose();
  };

  return (
    <View style={styles.container}>
      <Text style={textStyles.semi16}>Horário alterado</Text>
      <Spacer value={10} />
      <View style={styles.capsule}>
        <Text style={textStyles.bold18}>{hour}</Text>
      </View>

      <Text style={{ ...textStyles.regular14, textAlign: 'center' }}>
        Essa é uma tarefa recorrente, deseja alterar o horário de todas as seguintes?{' '}
      </Text>
      <ButtonBase
        // onPress={() => {
        //   handleonPressRecurrence();
        //   console.log('Todos os itens');
        // }}
        onPress={onPressRecurrence}
        backgroundColor={colors.aquaMarineTwo}
        style={styles.button}
        title="Alterar Todas"
      />
      <ButtonBaseInvert
        onPress={onPressNoRecurrence}
        color={colors.aquaMarineTwo}
        style={styles.button}
        title="Alterar apenas essa"
      />
      <Spacer />
      <TouchableOpacity onPress={close}>
        <Text style={{ fontFamily: fonts.BOLD, fontSize: 14, color: colors.tomato }}>Cancelar</Text>
      </TouchableOpacity>
    </View>
  );
};

export default ModalRecurrent;

const styles = StyleSheet.create({
  capsule: {
    width: 108,
    marginVertical: 10,
    alignItems: 'center',
    justifyContent: 'center',
    height: 38,
    borderRadius: 19,
    backgroundColor: colors.paleGrey,
  },
  button: {
    width: 184,
    paddingHorizontal: 0,
  },
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 24,
    width: 285,
    height: 363,
    borderRadius: 4,
    backgroundColor: colors.white,
    alignSelf: 'center',
  },
});
