import React from 'react';
import { View, StyleSheet, TouchableOpacity, Image } from 'react-native';

import videoPlay from '../images/videoPlay.png';
import pause from 'app/images/pause.png';

export default function ControlsVideo({ playing, onPlay, onPause }) {
  return (
    <View style={styles.wrapper}>
      <TouchableOpacity style={styles.touchable} onPress={playing ? onPause : onPlay}>
        {playing ? (
          <Image style={styles.imageControls} source={pause} />
        ) : (
          <Image source={videoPlay} />
        )}
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  imageControls: { height: 40, width: 40 },
  wrapper: {
    marginTop: 0,
    paddingHorizontal: 5,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    flex: 1,
  },
  touchable: {
    padding: 5,
  },
});
