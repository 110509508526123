import APP from 'app/configs/app';
import * as Sentry from '@sentry/browser';
import { withProfiler } from '@sentry/react';
import { ExtraErrorData, CaptureConsole, ContextLines } from '@sentry/integrations';
Sentry.init({
  dsn: APP.SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing(),
    new Sentry.Replay(),
    new Sentry.BrowserProfilingIntegration(),
    new ExtraErrorData({ depth: 10 }),
    new CaptureConsole({ levels: APP.SENTRY_CONSOLE_LEVELS }),
    new ContextLines(),
  ],
  replaysSessionSampleRate: APP.SENTRY_REPLAY_SAMPLE_RATE,
  replaysOnErrorSampleRate: 1.0,
  tracesSampleRate: APP.SENTRY_ERRORS_SAMPLE_RATE,
  tracePropagationTargets: ['localhost', /https:\/\/webview\.namu\.com\.br/],
});

import { AppRegistry } from 'react-native';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { store, persistor } from 'app/store';
import React from 'react';
import WebRoutes from 'app/webRoutes';
import fontAwesome from 'react-native-vector-icons/Fonts/FontAwesome.ttf';
import fontAwesome5 from 'react-native-vector-icons/Fonts/FontAwesome5_Regular.ttf';
import feather from 'react-native-vector-icons/Fonts/Feather.ttf';
import GlobalAlert from '../src/components/GlobalAlert';

const appName = 'Namu';

const iconFontStyles = `
@font-face {
  src: url(${fontAwesome});
  font-family: FontAwesome;
}
@font-face {
  src: url(${fontAwesome5});
  font-family: FontAwesome5;
}
@font-face {
  src: url(${feather});
  font-family: Feather;
}
`;

const style = document.createElement('style');
style.type = 'text/css';
if (style.styleSheet) {
  style.styleSheet.cssText = iconFontStyles;
} else {
  style.appendChild(document.createTextNode(iconFontStyles));
}

document.head.appendChild(style);

const AppToWrapp = () => (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <SafeAreaProvider style={{height: '100dvh'}}>
        <WebRoutes />
        <GlobalAlert/>
      </SafeAreaProvider>
    </PersistGate>
  </Provider>
);

export const App = withProfiler(AppToWrapp);

AppRegistry.registerComponent(appName, () => App);
AppRegistry.runApplication(appName, {
  initialProps: {},
  rootTag: document.getElementById('app-root'),
});
