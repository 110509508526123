import colors from 'app/styles/colors';
import fonts from 'app/styles/fonts';
import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

const TagCard = ({ title, style, textColor, gradientColors }) => {
  if (gradientColors) {
    return (
      <View style={styles.containerGradient}>
        <Text style={[styles.text, { color: textColor ?? colors.brownGrey }]}>{title}</Text>
      </View>
    );
  } else {
    return (
      <View style={[styles.container, style]}>
        <Text style={[styles.text, { color: textColor ?? colors.brownGrey }]}>{title}</Text>
      </View>
    );
  }
};

export default TagCard;

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 8,
    paddingVertical: 3,
    borderWidth: 1,
    borderColor: colors.tagGrey,
    borderRadius: 3,
    color: colors.blackTwo,
    alignSelf: 'flex-start',
  },
  text: {
    fontSize: 9,
    fontFamily: fonts.REGULAR,
    lineHeight: 12,
  },
  containerGradient: {
    borderColor: '#DBF5F559',
    borderWidth: 1,
    borderRadius: 4,
    overflow: 'hidden',
  },
  gradient: {
    paddingHorizontal: 8,
    paddingVertical: 2,
  },
});
