import ButtonBase from 'app/components/ButtonBase';
import colors from 'app/styles/colors';
import fonts from 'app/styles/fonts';
import React, { Fragment, useEffect, useState } from 'react';
import { Image, StyleSheet, Text, TouchableOpacity, View, Modal } from 'react-native';
import { useSelector } from 'react-redux';
import CustomIcon from 'app/styles/icon';
import commons from 'app/styles/commons';

const GlobalAlert = ({}) => {
  const newAlert = useSelector(state => state.alert);

  const [alerts, setAlerts] = useState([]);

  const [topTagHeight, setTopTagHeight] = useState(0);

  useEffect(() => {
    console.log('AQUI 2', newAlert)

    if (newAlert.visible) {
      setAlerts(prev => [newAlert, ...prev]);
    }
  }, [newAlert]);

  useEffect(() => {
    if (alerts.length && alerts.every(a => a.visible === false)) {
      setAlerts([]);
    }
  }, [alerts]);

  const closeAlert = index => {
    setAlerts(prev => {
      const _new = prev.filter((_, i) => i !== index);
      return _new;
    });
  };

  const renderNewAlert = (globalAlert, i) => {
    return (
      <Modal
        key={i}
        style={styles.container}
        backdropOpacity={alerts.findIndex(a => a?.visible) === i ? 0.3 : 0.05}
        coverScreen
        transparent={true}
        visible={!!globalAlert?.visible}
        onBackdropPress={() => closeAlert(i)}>
          <View style={{width: '100%', height: '100%', ...commons.flexCenter, paddingHorizontal: 20}}>
            <View
              style={[
                styles.content,
                !!globalAlert?.inverted && styles.contentInverted,
                globalAlert?.topTag && { overflow: 'visible' },
              ]}>
              <TouchableOpacity activeOpacity={0.7} onPress={() => closeAlert(i)} style={styles.close}>
                <CustomIcon name={'close'} size={25} color={colors.black} />
              </TouchableOpacity>

              {globalAlert?.topTag && (
                <View
                  style={styles.topTag(topTagHeight)}
                  onLayout={e => setTopTagHeight(e.nativeEvent.layout.height)}>
                  {globalAlert?.topTag}
                </View>
              )}

              {globalAlert?.topTitle && (
                <Text style={[styles.title, !!globalAlert.inverted && styles.textInverted]}>
                  {globalAlert.topTitle}
                </Text>
              )}

              {globalAlert.icon && (
                <View style={[styles.iconContainer, globalAlert?.iconContainerStyle]}>
                  {globalAlert.icon}
                </View>
              )}
              {!!globalAlert?.title && (
                <Text style={[styles.title, !!globalAlert?.inverted && styles.textInverted]}>
                  {globalAlert?.title}
                </Text>
              )}
              {!!globalAlert?.description && (
                <Text style={[styles.description, !!globalAlert?.inverted && styles.textInverted]}>
                  {globalAlert.description.replace(/\\n/gi, '\n')}
                </Text>
              )}
              {globalAlert?.additionalComponent}
              <ButtonBase
                onPress={() => (
                  closeAlert(i),
                  globalAlert?.buttonOptions?.onPress && globalAlert?.buttonOptions?.onPress()
                )}
                fill
                backgroundColor={!globalAlert.inverted ? colors.aquaMarineTwo : colors.white}
                textStyle={{
                  fontSize: 14.5,
                  color: !globalAlert.inverted ? colors.white : colors.aquaMarineTitle,
                }}
                style={[styles.buttonOk]}
                title={globalAlert?.buttonOptions?.text}
              />

              {!!globalAlert?.buttonOptions?.secondaryText && (
                <ButtonBase
                  onPress={() => (
                    globalAlert?.buttonOptions?.secondaryOnPress &&
                      globalAlert?.buttonOptions?.secondaryOnPress(),
                    closeAlert(i)
                  )}
                  fill
                  backgroundColor={colors.transparent}
                  textStyle={{
                    fontSize: 16,
                    color: !globalAlert.inverted ? colors.darkPro2 : colors.white,
                  }}
                  style={{ height: 20, marginBottom: 10 }}
                  title={globalAlert?.buttonOptions?.secondaryText}
                />
              )}

              {!!globalAlert.inverted && (
                <Image
                  source={require('app/images/backgroundCustomSearch.png')}
                  style={{
                    position: 'absolute',
                    tintColor: colors.white,
                    bottom: -50,
                    width: '130%',
                    height: 'auto',
                    aspectRatio: 375 / 229,
                    resizeMode: 'contain',
                    zIndex: -1,
                  }}
                />
              )}
            </View>
          </View>
      </Modal>
    );
  };

  return <Fragment>{alerts.map((a, i) => renderNewAlert(a, i))}</Fragment>;
};

export default GlobalAlert;

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    backgroundColor: colors.white,
    borderRadius: 20,
    overflow: 'hidden',
    maxHeight: '90%',
    alignItems: 'center',
    width: '100%',
    padding: 20,
    elevation: 5,
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 6,
    shadowOpacity: 0.3,
    alignItems: 'center',
    justifyContent: 'center',
  },
  close: {
    position: 'absolute',
    top: 16,
    right: 16,
  },
  topTag: topTagHeight => ({
    position: 'absolute',
    transform: [{ translateY: -topTagHeight / 2 }],
  }),
  iconContainer: {
    marginVertical: 15,
  },
  contentInverted: {
    backgroundColor: colors.aquaMarineTwo,
  },
  textInverted: {
    color: colors.white,
  },
  buttonInverted: {
    color: colors.aquaMarineTwo,
  },
  textStyle: {
    fontFamily: fonts.REGULAR,
    color: colors.greyLight4,
    fontSize: 14,
    paddingHorizontal: 20,
    textAlign: 'center',
  },
  title: {
    fontFamily: fonts.BOLD,
    fontSize: 18,
    color: colors.darkPro,
    marginBottom: 10,
    textAlign: 'center',
  },
  description: {
    fontFamily: fonts.REGULAR,
    fontSize: 14,
    color: colors.darkPro2,
    textAlign: 'center',
  },
  buttonOk: {
    height: 50,
    zIndex: 1,
  },
  topContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 25,
  },
  continueContainer: {
    width: '100%',
    paddingHorizontal: 28,
  },
});
