import React, { useState } from 'react';
import { View, StyleSheet, TouchableOpacity, Text } from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome';
import Feather from 'react-native-vector-icons/Feather';
import colors from 'app/styles/colors';
import textStyles from 'app/styles/textStyles';

const DropDownPicker = ({ items, selected, onSelected = () => {} }) => {
  const [showItems, setShowItems] = useState(false);
  const [selectedItem, setSelectedItem] = useState(items.find(item => item.value === selected));

  const handleShowItems = () => {
    setShowItems(!showItems);
  };

  const handleSelectItem = item => {
    setShowItems(false);
    setSelectedItem(item);
    onSelected(item);
  };

  return (
    <View style={styles.container}>
      <View style={styles.buttonMaster}>
        <TouchableOpacity
          style={styles.buttonMasterTouchable}
          onPress={() => {
            handleShowItems();
          }}>
          <Text style={textStyles.bold15}>
            {items.find(item => item.value === selected).title}
          </Text>
          <Icon name={showItems ? 'chevron-up' : 'chevron-down'} color={colors.black} />
        </TouchableOpacity>
        {showItems
          ? items.map(item => {
              return (
                <View style={styles.hiddenButtons} key={item.id}>
                  <TouchableOpacity
                    style={styles.hiddenButtonsTouchable}
                    onPress={() => {
                      handleSelectItem(item);
                    }}>
                    <Text style={textStyles.regular14}>{item.title}</Text>
                    {selectedItem.title === item.title && (
                      <Feather
                        name="check"
                        size={20}
                        color={colors.black}
                      />
                    )}
                  </TouchableOpacity>
                </View>
              );
            })
          : null}
      </View>
    </View>
  );
};

export default DropDownPicker;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    width: '100%',
  },
  buttonMaster: {
    backgroundColor: colors.white,
    paddingHorizontal: 12,
    paddingTop: 12,
    borderRadius: 6,
    borderWidth: 1.5,
    borderColor: colors.lightPeriwinkle,
    marginVertical: 8,
  },
  buttonMasterTouchable: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 12,
  },
  hiddenButtons: {
    paddingVertical: 12,
    paddingHorizontal: 6,
  },
  hiddenButtonsTouchable: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});
