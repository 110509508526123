import React from 'react';

import { View, StyleSheet, Text, Image } from 'react-native';
import fonts from '../styles/fonts';
import colors from '../styles/colors';
import afternoon from '../images/afternoon.png';
import day from '../images/day.png';
import nightWhite from '../images/nightWhite.png';
import moment from 'moment';

const CapsuleDayNight = ({ small, period, time }) => {
  const getIconPeriod = () => {
    if (period === 'morning') {
      return day;
    }
    if (period === 'afternoon') {
      return afternoon;
    }
    return nightWhite;
  };

  return (
    <View style={[styles.capsule, small && { width: 67, height: 21 }]}>
      <Image source={getIconPeriod()} style={[small && { height: 15, width: 15 }]} />
      <Text style={[styles.capsuleText, small && { fontSize: 12 }]}>
        {moment(time).format('HH:mm')}
      </Text>
    </View>
  );
};

export default CapsuleDayNight;

const styles = StyleSheet.create({
  capsuleText: {
    marginLeft: 4,
    fontFamily: fonts.REGULAR,
    fontSize: 14,
    opacity: 0.9,
    color: colors.brownishGreyTwo,
  },
  capsule: {
    paddingLeft: 8,
    flexDirection: 'row',
    alignItems: 'center',
    width: 79,
    height: 29,
    borderRadius: 18.2,
    backgroundColor: colors.whiteTwo,
  },
});
