import React from 'react';
import { View, StyleSheet, Text, Dimensions } from 'react-native';
import Slider from '@react-native-community/slider';
import colors from 'app/styles/colors';
import { calcDurationNumber } from 'app/services/utils';
import fonts from 'app/styles/fonts';

const ProgressBarNew = ({
  currentTime,
  colorBase = colors.aquaMarineTwo,
  duration,
  onValueChange,
  onSlideStart,
  onSlideComplete,
  showTrack,
}) => {
  return (
    <View style={styles.wrapper}>
      <Slider
        style={styles.slider}
        value={Math.floor(currentTime)}
        minimumValue={0}
        step={0}
        maximumValue={duration}
        onSlidingStart={onSlideStart}
        onValueChange={onValueChange}
        onSlidingComplete={onSlideComplete}
        minimumTrackTintColor={colorBase}
        maximumTrackTintColor={colors.transparent}
        thumbImage={''}
        thumbTintColor={showTrack ? colorBase : colors.transparent}
      />
      <View style={styles.flexRow}>
        <Text style={[styles.textTime, { color: colors.white }]}>
          {calcDurationNumber(currentTime)} /
        </Text>
        <Text style={styles.textTime}> {calcDurationNumber(duration)}</Text>
      </View>
    </View>
  );
};
export default ProgressBarNew;

const styles = StyleSheet.create({
  textTime: {
    fontFamily: fonts.BOLD,
    fontSize: 12,
    color: colors.white,
  },
  flexRow: {
    flexDirection: 'row',
    marginRight: 16,
  },
  subContainer: {
    flex: 1,
    width: Dimensions.get('screen').width + 20,
    marginHorizontal: 18,
    zIndex: 999,
    flexDirection: 'row',
  },
  container: {
    // flex: 1,
    // marginHorizontal: 18,
    // position: 'absolute',
    // zIndex: 1,
    // top: 0,
    // left: 0,
    // right: 0,
    // bottom: 0,
  },
  slider: {
    position: 'absolute',
    width: Dimensions.get('screen').width + 32,
    height: 10,
    left: -16,
    margin: 0,
    padding: 0,
    transform: [{ scaleY: 10 }],
  },
  wrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    overflow: 'hidden',
    justifyContent: 'flex-end',
    backgroundColor: colors.greyBlue,
  },
  time: {
    fontSize: 12,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.white,
  },
  timeRight: {
    fontSize: 16,
    color: '#FFFFFF',
    textAlign: 'right',
    paddingRight: 10,
  },
});
