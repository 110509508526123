import { all, takeLatest, call, put } from 'redux-saga/effects';
import qs from 'qs';
import api from 'app/services/api';
import apiNode from 'app/services/apiNode';

import { signInSuccess, signFailure } from './actions';

import apiVimeo from 'app/services/apiVimeo';
import { setIsShowBoarding } from 'app/store/modules/gamification/actions';
import { reloadUser } from '../user/actions';
import AsyncStorage from '@react-native-async-storage/async-storage';

export function* signIn({ payload }) {
  try {
    const { email, password } = payload;

    //login profile
    const response = yield call(() =>
      api.post('/login', qs.stringify({ email }), {
        headers: { password: password },
      })
    );

    const { created_token: token, user } = response.data;

    if (!token) {
      yield put(signFailure());
    }

    api.defaults.headers.Authorization = `Bearer ${token}`;
    api.defaults.headers.GUID = user.user_id;
    apiNode.defaults.headers['wellness-token'] = token;

    const vimeo_access_token = yield call(getVimeoToken);
    apiVimeo.defaults.headers.Authorization = `Bearer ${vimeo_access_token}`;

    yield put(signInSuccess(token, user));
  } catch (error) {
    console.log(error.response.data);
    yield put(signFailure());
  }
}

export function* signInSocial({ payload }) {
  try {
    const { id, token: faceToken, provider } = payload;

    const response = yield call(() =>
      api.post(
        '/oauth/token',
        qs.stringify({
          grant_type: 'social',
          client_id: id,
          provider: provider,
          access_token: faceToken,
        })
      )
    );
    const { created_token: token, user } = response.data;

    api.defaults.headers.Authorization = `Bearer ${token}`;
    api.defaults.headers.GUID = user.user_id;
    apiNode.defaults.headers['wellness-token'] = token;

    yield put(signInSuccess(token, user));
  } catch (error) {
    // showAlert(errorControl(error));
    yield put(signFailure());
  }
}

export function* signUp({ payload }) {
  try {
    const { email, password, cpf, phone, isBusiness, provider } = payload;

    //register profile
    let response;
    if (isBusiness) {
      response = yield call(() =>
        api.post(
          '/employee',
          qs.stringify({ name: 'App', user_picture: '', email, cpf, phone, provider }),
          { headers: { password: password } }
        )
      );
    } else {
      response = yield call(() =>
        api.post('/user', qs.stringify({ name: 'App', user_picture: '', email, provider }), {
          headers: { password: password },
        })
      );
    }

    const { created_token: token, user } = response.data;

    api.defaults.headers.Authorization = `Bearer ${token}`;
    api.defaults.headers.GUID = user.user_id;
    apiNode.defaults.headers['wellness-token'] = token;

    const vimeo_access_token = yield call(getVimeoToken);
    apiVimeo.defaults.headers.Authorization = `Bearer ${vimeo_access_token}`;
    //AppEventsLogger.logEvent('Nova conta criada');

    yield put(signInSuccess(token, user));
  } catch (error) {
    yield put(signFailure());
  }
}

function* getVimeoToken() {
  const {
    data: { value: vimeo_access_token },
  } = yield call(() => {
    return apiNode.get('/meta/vimeo_token');
  });
  return vimeo_access_token;
}

export function* setToken({ payload }) {
  if (!payload) {
    return;
  }
  try {
    let token = payload?.auth?.token;
    let user_id = payload?.user?.profile?.user_id;
    
    let user;
    if(!token || !user_id) {
      token = payload?.token;
      user = yield call(getUserProfile(api, token));
      user_id = user.id;
    }

    if (token && user_id) {
      api.defaults.headers.Authorization = `Bearer ${token}`;
      api.defaults.headers.GUID = user_id;
      apiNode.defaults.headers['wellness-token'] = token;

      const _user = {user_id, ...user};
      yield put(reloadUser(_user));
      yield put(signInSuccess(token, _user))

      const vimeo_access_token = yield call(getVimeoToken);
      apiVimeo.defaults.headers.Authorization = `Bearer ${vimeo_access_token}`;
    }

    yield put(setIsShowBoarding(false));
  } catch (error) {
    yield put(signFailure())
  }
}

export function* signInToken({ payload }) {
  const _user = yield call(getUserProfile(api, payload.token));
  yield put(signInSuccess(payload.token, {..._user}));

  // yield call(setToken);
}

function getUserProfile (api, token) {
  return async () => {
    try{
      const { data } = await api.get('/user', {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      });
      return data;
    }catch(e){
      console.log('ERR1', e)
    }
  }
}

export function resetAuth() {
  AsyncStorage.clear();
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/SIGN_IN_SOCIAL_REQUEST', signInSocial),
  takeLatest('@auth/SIGN_UP_REQUEST', signUp),
  takeLatest('@auth/UPDATE_TOKEN', setToken),
  takeLatest('@auth/RESET', resetAuth),
]);
