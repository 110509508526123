import colors from 'app/styles/colors';
import textStyles from 'app/styles/textStyles';
import React from 'react';
import { Image, StyleSheet, Text, View } from 'react-native';

const TopInsignia = ({ source, text = ' ' }) => {
  return (
    <View style={styles.topGetPointsContainer}>
      <Image source={source} style={styles.mainImgStyle} width={26} height={26} />
      <Text style={[textStyles.medium16, { color: colors.aquaMarineTwo2, marginLeft: 5 }]}>
        {text}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  topGetPointsContainer: {
    paddingHorizontal: 8,
    height: 40,
    backgroundColor: colors.white,
    borderRadius: 50,
    borderWidth: 1,
    borderColor: colors.aquaMarineTwo2,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mainImgStyle: {
    height: 300,
    width: 200,
    resizeMode: 'contain',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default TopInsignia;
