import React from 'react';

import { View, StatusBar, Image, Text, StyleSheet, TouchableOpacity } from 'react-native';
import TopFix from './TopFix';
import textStyles from '../styles/textStyles';
import chevronLeft from '../images/chevronLeft.png';
import colors from '../styles/colors';

import commons from '../styles/commons';
import { useNavigation } from '@react-navigation/native';

const HeaderGeneric = ({ title, onPress, style, styleIcon }) => {

  const navigation = useNavigation();

  return <View>
    <View style={[styles.container, style]}>
      <TouchableOpacity hitSlop={commons.hitSlop} onPress={onPress || navigation.goBack}>
        <Image style={[styles.icon, styleIcon]} source={chevronLeft} />
      </TouchableOpacity>
      <Text style={textStyles.semi16}>{title}</Text>
    </View>
  </View>
}


export default HeaderGeneric;

const styles = StyleSheet.create({
  icon: { tintColor: colors.blackTwo, marginRight: 16, width: 24, height: 24 },
  container: {
    height: 56,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 20,
  },
});
