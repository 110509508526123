import ButtonBase from 'app/components/ButtonBase';
import colors from 'app/styles/colors';
import fonts from 'app/styles/fonts';
import React, { useEffect, useState } from 'react';
import { Dimensions, StyleSheet, Text, View } from 'react-native';
import { useSelector } from 'react-redux';

const { width } = Dimensions.get('screen');

const Steps = {
  MODALITIES: 0,
  ORIENTATION: 1,
  LETS_GO: 2,
};

const ProfessionalTutorialSteps = ({ step, onFinish }) => {
  const [currentStep, setCurrentStep] = useState(Steps.ORIENTATION);
  const { profile } = useSelector(state => state.user);

  const StepOps = {
    [Steps.MODALITIES]: {
      title: 'Modalidades',
      message:
        'Nós temos duas modalidades de agendamentos com profissionais, a orientação e o atendimento.',
      confirm: 'Ok',
    },
    [Steps.ORIENTATION]: {
      title: 'Orientação',
      message: `As orientações servem para tirar dúvidas rápidas com os profissionais da Namu, por isso as sessões tem no máximo 20 minutos.\nPara realizar uma orientação você precisa ter créditos em sua conta, que podem ser adquiridos através de compras${
        profile?.plan_meta?.tele_enabled ? ' ou disponibilizados por sua empresa' : ''
      }.`,
      confirm: 'Entendi',
    },
    [Steps.LETS_GO]: {
      title: 'Vamos começar',
      message: 'Agora que você já conhece as orientações, que tal fazer seu primeiro agendamento?',
      confirm: 'Vamos lá!',
    },
  };

  useEffect(() => {
    step(currentStep);
  }, [currentStep]);

  const nextStep = () => {
    if (currentStep !== Steps.LETS_GO) {
      setCurrentStep(prev => prev + 1);
    } else {
      onFinish();
    }
  };

  const renderCard = () => {
    return (
      <View key={currentStep} useNativeDriver animation="fadeInUp">
        <View style={styles.cardContainer}>
          <Text style={styles.cardTitle}>{StepOps[currentStep].title}</Text>
          <Text style={styles.cardMessage}>{StepOps[currentStep].message}</Text>
          <ButtonBase
            onPress={() => nextStep()}
            fill
            backgroundColor={colors.aquaMarineTwo}
            textStyle={{ fontSize: 16 }}
            style={styles.cardButton}
            title={StepOps[currentStep].confirm}
          />
        </View>
      </View>
    );
  };

  return (
    <View style={styles.container}>
      {/* <Text>dfasdf\</Text> */}

      {renderCard()}
    </View>
  );
};

export default ProfessionalTutorialSteps;

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100vh',
    backgroundColor: '#00000059',
    zIndex: 1,
    justifyContent: 'flex-end',
  },

  cardContainer: {
    marginHorizontal: 10,
    backgroundColor: colors.white,
    borderRadius: 20,
    paddingVertical: 15,
    paddingHorizontal: 20,
    // position: 'absolute',
    marginBottom: 60,
  },
  cardTitle: {
    fontFamily: fonts.BOLD,
    fontSize: 18,
    color: colors.darkPro,
    marginVertical: 15,
    textAlign: 'center',
  },
  cardMessage: {
    fontFamily: fonts.REGULAR,
    fontSize: 14,
    marginTop: 5,
    color: colors.darkPro2,
    textAlign: 'center',
  },
  cardButton: {
    // width: 220,
    height: 50,
    zIndex: 1,
  },
});
