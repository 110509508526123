import React, { useState } from 'react';
import { View, StyleSheet, Text, TouchableOpacity, Image, ActivityIndicator } from 'react-native';

import colors from 'app/styles/colors';
import textStyles from 'app/styles/textStyles';
import { translate } from 'app/i18n/src/locale';
import pt from 'app/i18n/src/locale/pt';
import apiNode from 'app/services/apiNode';
import { useSafeArea } from 'react-native-safe-area-context';

const BottomSheetConfirmCancel = ({ onClose, userPro, sessionId, reload }) => {
  const [isLoading, setIsLoading] = useState(false);

  const insets = useSafeArea();

  const handleConfirm = async () => {
    setIsLoading(true);
    try {
      await apiNode.delete(`sessions/cancel/${sessionId}`);
      !!reload && reload();
      onClose();
    } catch (e) {
      console.log(e.response);
    }
    setIsLoading(false);
  };

  return (
    <View style={[styles.container, { paddingBottom: insets.bottom }]}>
      <View style={styles.checkBall}>
        <Image source={require('app/images/exclamacao.png')} style={styles.check} />
      </View>
      <View style={styles.header}>
        <Text style={styles.title}>{translate(pt.wouldLikeCofirmSessionCancel)}</Text>
        <TouchableOpacity onPress={onClose} style={{ marginTop: -30 }}>
          <Image source={require('../../../../images/closeGrey.png')} style={{ width: 15, height: 15 }} />
        </TouchableOpacity>
      </View>

      {!userPro.namuStaff && <Text style={styles.warning}>{translate(pt.cancelConfirmText)}</Text>}

      <View style={styles.buttonBox}>
        {!isLoading ? (
          <>
            <TouchableOpacity
              style={styles.confirmButton}
              onPress={() => {
                handleConfirm();
              }}>
              <Text style={styles.confirmButtonText}>{translate(pt.yes)}</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.confirmButtonNo}
              disabled={isLoading}
              onPress={() => {
                onClose();
              }}>
              <Text style={styles.confirmButtonTextNo}>{translate(pt.no)}</Text>
            </TouchableOpacity>
          </>
        ) : (
          <ActivityIndicator
            style={{ marginTop: 20, marginBottom: 20 }}
            size="large"
            color={colors.aquaMarineTwo}
          />
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    justifyContent: 'flex-end',
    width: '100%',
    bottom: 0,
    borderTopLeftRadius: 50,
    borderTopRightRadius: 50,
    paddingHorizontal: 20,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  title: {
    textAlign: 'center',
    ...textStyles.bold18,
    marginHorizontal: 20,
    marginBottom: 30,
    width: '70%',
  },

  warning: {
    ...textStyles.bold10White,
    fontWeight: '400',
    color: colors.black,
    textAlign: 'center',
    marginHorizontal: 30,
    fontSize: 14,
    marginBottom: 5,
  },
  buttonBox: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  confirmButton: {
    alignSelf: 'center',
    backgroundColor: colors.aquaMarineTwo,
    width: '47%',
    alignItems: 'center',
    borderRadius: 5,
    marginHorizontal: 5,
    justifyContent: 'center',
    marginTop: 20,
    marginBottom: 20,
  },
  confirmButtonNo: {
    alignSelf: 'center',
    backgroundColor: colors.white,
    width: '47%',
    alignItems: 'center',
    borderRadius: 5,
    marginHorizontal: 5,
    justifyContent: 'center',
    marginTop: 20,
    marginBottom: 20,
    borderColor: colors.red,
    borderWidth: 1,
  },
  confirmButtonText: {
    ...textStyles.bold13,
    paddingVertical: 15,
    color: colors.white,
    fontWeight: '700',
  },
  confirmButtonTextNo: {
    ...textStyles.bold13,
    paddingVertical: 15,
    color: colors.red,
    fontWeight: '700',
  },
  checkBall: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: colors.red,
    borderRadius: 100,
    alignItems: 'center',
    alignSelf: 'center',
    padding: 50,
    backgroundColor: colors.red,
    justifyContent: 'center',
    height: 100,
    width: 100,
    marginBottom: 10,
    marginTop: -50,
    shadowColor: colors.red,
    shadowOpacity: 0.4,
    shadowRadius: 2,
    shadowOffset: {
      height: 1,
      width: 1,
    },
    elevation: 3,
  },
  check: {
    height: 20,
    width: 20,
  },
});

export default BottomSheetConfirmCancel;
