/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import { useEffect, useState } from 'react';
import { View, Text, Image, StyleSheet, TouchableOpacity, Alert } from 'react-native';
import moment from 'moment';
import colors from '../../../../styles/colors';
import iconClock from '../../../../images/clock.png';
import iconDay from '../../../../images/day.png';
import iconMorning from '../../../../images/morningOrange.png';
import iconDayWhite from '../../../../images/dayWhiteCopy.png';
import iconNight from '../../../../images/nightWhite.png';

import ShadowView from '@liuyunjs/react-native-simple-shadow-view';
import fonts from 'app/styles/fonts';
import ProfessionalData2 from 'app/screens/core/professionalFilterResult/components/ProfessionalData2';
import textStyles from 'app/styles/textStyles';
import { RFValue } from 'app/services/utils';
import { translate } from 'app/i18n/src/locale';
import pt from 'app/i18n/src/locale/pt';
import { useNavigation } from '@react-navigation/native';

const FavoriteProfessionalCardCalendar = ({ item, onCloseModal }) => {
  const navigation = useNavigation();

  const [userPro, setUserPro] = useState(null);
  function generateIcon() {
    var currentHour = moment(item.sessionDate).format('HH');

    if (currentHour >= 3 && currentHour < 12) {
      return iconMorning;
    } else if (currentHour >= 0 && currentHour < 3) {
      return iconNight;
    } else if (currentHour >= 12 && currentHour < 15) {
      return iconDayWhite;
    } else if (currentHour >= 15 && currentHour < 20) {
      return iconDay;
    } else if (currentHour >= 20) {
      return iconNight;
    }
    // else {
    //   return iconDayWhite;
    // }
  }

  const getRating = () => {
    if (item?.sessionFeedbacks?.length > 0) {
      const toUserProRating = item.sessionFeedbacks.find(sf => sf.toUser === false);
      if (toUserProRating) {
        return Number(toUserProRating.ratingStars);
      }
    }
    return null;
  };

  const [ratings, setRatings] = useState(getRating);

  function checkDate() {
    const sessiondate = new Date(item.sessionDate);

    const toDay = new Date();

    if (sessiondate.setHours(0, 0, 0, 0) === toDay.setHours(0, 0, 0, 0)) {
      const mili = moment(item.sessionDate).diff(moment());
      const dur = moment.duration(mili, 'milliseconds').subtract(1, 'seconds');
      if (dur.minutes() <= 5) {
        navigation.navigate('Profissionais', { tab: 'Profissionais' })
      } else {
        Alert.alert(translate(pt.msgAlert), translate(pt.feedUnavailable));
      }
    } else {
      Alert.alert(translate(pt.msgAlert), translate(pt.feedUnavailable));
    }
  }

  const check = () => {
    if (item.status === 'open') {
      checkDate();
    }

    if (item.status === 'done' && ratings === null) {
      navigation.navigate('CallServiceFeedback', { sessionId: item.id });
    }
  };

  // useEffect(() => {
  //   console.log('status', item.status);
  // }, []);

  return (
    <TouchableOpacity
      disabled={item.status === 'canceled' || item.status === 'lost'}
      onPress={check}
      style={{ opacity: item.status === 'canceled' || item.status === 'lost' ? 0.5 : 1 }}>
      <ShadowView style={styles.professionalCard}>
        {/* <MoreContextMenu item={item} type={item.category_item} onClose={onCloseModal} /> */}
        <View style={styles.topContentCard}>
          <ProfessionalData2
            setUser={setUserPro}
            userProId={item.userProId}
            userProRatings={ratings}
          />
        </View>

        <View style={styles.timeAndSpecialtyContainer}>
          {userPro && (
            <View>
              <View
                style={{
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                }}>
                <Text numberOfLines={1} style={styles.category}>
                  {`${userPro.categories[0].professionalCategory.name} Especialista em ${
                    userPro.specialities[0] ? userPro.specialities[0]?.speciality.name : ''
                  }`}
                </Text>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                }}>
                <Text numberOfLines={1} style={[textStyles.bold12, styles.blueText]}>
                  {`${userPro.categories[0].professionalCategory.registryName} ${userPro.professionalNumber}`}
                </Text>
                <Text> | </Text>
                <View>
                  <Text numberOfLines={1} style={styles.cityUf}>
                    {`${userPro.city ?? ''}`}
                    {`${userPro.state ? '/' + userPro.state : ''}`}
                  </Text>
                </View>
              </View>
            </View>
          )}
          <View style={styles.containerTimeAndSchedule}>
            <View style={styles.containerTime}>
              <Image source={iconClock} style={styles.clockStyle} />
              <Text style={styles.clockTextStyle}> {item.sessionDurationMinutes}</Text>
              <Text style={styles.clockTextStyle}> min</Text>
            </View>
            <View style={{ ...styles.containerTime, marginTop: 4 }}>
              <Image source={generateIcon()} style={styles.clockStyle} />
              <Text style={{ ...styles.clockTextStyle, marginLeft: 3, fontFamily: fonts.MEDIUM }}>
                {moment(item.sessionDate).format('HH:mm')}
              </Text>
            </View>
          </View>
        </View>
      </ShadowView>
    </TouchableOpacity>
  );
};

export default FavoriteProfessionalCardCalendar;

const styles = StyleSheet.create({
  containerTimeAndSchedule: {},
  professionalCard: {
    height: 'auto',
    //width: '90%',
    //padding: 16,
    justifyContent: 'flex-start',
    marginHorizontal: 15,
    marginBottom: 15,
    paddingHorizontal: 18,
    borderRadius: 6,
    //paddingVertical: 24,
    backgroundColor: colors.white,
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowRadius: 4,
    shadowOpacity: 0.08,
  },
  buttonContainer: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignSelf: 'center',
  },
  blueText: {
    color: colors.aquaMarineTwo,
    maxWidth: RFValue(90),
  },
  buttonStyle: {
    borderWidth: 1,
    maxWidth: '45%',
    minWidth: '45%',
    paddingHorizontal: 0,
    alignItems: 'center',
  },
  containerTime: {
    flexDirection: 'row',
    backgroundColor: colors.whiteTwo,
    borderRadius: 18.15,
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingHorizontal: 5,
    paddingVertical: 5,
  },
  category: {
    color: colors.grey09,
    fontFamily: fonts.MEDIUM,
    fontSize: RFValue(11),
    width: RFValue(220),
  },
  cityUf: {
    color: colors.grey09,
    fontFamily: fonts.MEDIUM,
    fontSize: RFValue(11),
    width: RFValue(100),
  },
  clockStyle: {
    width: 14.4,
    height: 14.4,
    resizeMode: 'contain',
  },
  timeAndSpecialtyContainer: {
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'space-between',
    width: '100%',
    alignSelf: 'center',
    marginBottom: 15,
    paddingHorizontal: RFValue(5),
  },
  specialtyTextStyle: {
    color: colors.ligthGray,
    fontSize: 10,
    fontFamily: fonts.REGULAR,
  },
  credentialStyle: {
    fontFamily: fonts.BOLD,
    color: colors.aquaMarineTwo,
  },
  locationStyle: {
    color: colors.ligthGray,
    fontFamily: fonts.REGULAR,
    fontSize: 10,
    lineHeight: 18,
  },
  topContentCard: {
    width: '100%',
    //flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 16,
    marginVertical: 10,
    //borderWidth: 1,
  },
  topMiddleContent: {
    flexDirection: 'column',
    alignSelf: 'center',
    minHeight: 45,
    justifyContent: 'space-between',
    flex: 1,
    marginLeft: 5,
  },
  professionalNameStyle: {
    fontSize: 16,
    fontFamily: fonts.MEDIUM,
    color: colors.blackTwo,
  },
  photoStyle: {
    resizeMode: 'cover',
    height: 56,
    width: 56,
    borderRadius: 150,
  },
  clockTextStyle: {
    fontFamily: fonts.REGULAR,
    color: colors.brownishGreyTwo,
    fontSize: 12,
  },
  divisor: {
    width: '100%',
    height: 1,
    backgroundColor: colors.greyBlue,
    opacity: 0.2,
  },
});
