import React from 'react';

import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import colors from 'app/styles/colors';
import textStyles from 'app/styles/textStyles';
import commons from 'app/styles/commons';
import { translate } from 'app/i18n/src/locale';
import pt from 'app/i18n/src/locale/pt';
import { debounce } from 'lodash';
import PERFORMANCE from 'app/configs/performance';

const HeaderTitleNew = ({
  title,
  buttonIcon,
  style,
  onPress,
  linkStyle,
  uppercaseLink = false,
  linkText,
  textStyle,
}) => (
  <View style={[styles.container, style]}>
    <Text numberOfLines={2} style={[textStyles.semi16, styles.title, textStyle]}>
      {title}
    </Text>
    {onPress && (
      <TouchableOpacity
        onPress={debounce(() => onPress(), PERFORMANCE.ON_PRESS_DEBOUNCE_TIME)}
        hitSlop={commons.hitSlop}>
        <Text
          numberOfLines={1}
          style={[
            textStyles.bold14grey,
            {
              textTransform: uppercaseLink ? 'uppercase' : 'capitalize',
              color: colors.aquaGreenDark,
            },
            linkStyle,
          ]}>
          {linkText ? linkText : translate(pt.show_more)}
        </Text>
      </TouchableOpacity>
    )}
  </View>
);

export default HeaderTitleNew;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    marginTop: 35,
    marginVertical: 20,
    paddingHorizontal: 20,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    maxWidth: 310,
    color: colors.slateGrey,
  },
});
