// fonts
const fonts = {
  LIGHT: 'NunitoSans-Light',
  MEDIUM: 'NunitoSans-SemiBold',
  REGULAR: 'NunitoSans-Regular',
  BOLD: 'NunitoSans-Bold',
  BLACK: 'NunitoSans-Black',
};

export default fonts;
