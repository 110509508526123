import React from 'react';
import { View, StyleSheet, Text, TouchableOpacity, Image } from 'react-native';
import colors from 'app/styles/colors';
import closeGrey from 'app/images/closeGrey.png';
import Spacer from 'app/components/Spacer';
import commons from 'app/styles/commons';
import textStyles from 'app/styles/textStyles';
import { translate } from 'app/i18n/src/locale';
import pt from 'app/i18n/src/locale/pt';

const WaterReference = ({ doClose, reference }) => {
  return (
    <View style={styles.container}>
      <Spacer value={24} />
      <View style={styles.containerHeader}>
        <Text style={textStyles.subtitle17}>{translate(pt.reference_values)}</Text>
        <TouchableOpacity onPress={doClose}>
          <Image source={closeGrey} />
        </TouchableOpacity>
      </View>
      <Spacer value={24} />
      <View style={styles.flexCenter}>
        <Text style={textStyles.bold18Left}>Seu valor de referência é: </Text>
        <Text style={textStyles.bold18Left}>{`${reference}L`}</Text>
      </View>
    </View>
  );
};

export default WaterReference;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.paleGrey,
    flex: 1,
  },
  containerHeader: {
    ...commons.flexRowBetween,
    paddingHorizontal: 20,
  },
  flexCenter: {
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'center',
  },
});
