
import React from 'react';
import { View, Text, StyleSheet, Image, NativeModules, Platform, TouchableOpacity } from 'react-native';
import ShadowView from '@liuyunjs/react-native-simple-shadow-view';
import ButtonBaseIcon from 'app/components/ButtonBaseIcon';
import Icon from 'react-native-vector-icons/dist/FontAwesome';
import { translate } from 'app/i18n/src/locale';
import pt from 'app/i18n/src/locale/pt';
import fonts from 'app/styles/fonts';
import colors from 'app/styles/colors';
import { useNavigation } from '@react-navigation/native';

export const FreeCheckinWidget = ({ }) => {
  const navigation = useNavigation();

  return (
    <ShadowView style={styles.shadowContainer}>
      <TouchableOpacity 
        onPress={()=>navigation.navigate('ShowGyms', { preFilters: { free_checkin: 1}})}
        style={styles.wrapper}>
        <View
          style={styles.container}>
          <Image source={require('app/images/man_weight.png')} style={styles.image} />

          <View style={{ flex: 1, alignItems: 'flex-start' }}>
            <View style={{ width: '60%' }}>
              <Text style={styles.textTitle}>{translate(pt.checkin)} <Text style={{color: colors.aquaMarineTwo}}>{translate(pt.free).toUpperCase()}</Text></Text>
            </View>
            <View style={{ width: '60%' }}>
              <Text style={styles.textContent}>{translate(pt.see_gyms_with_free_checkin)}</Text>
            </View>
          </View>
          <ButtonBaseIcon
            useIcon
            backgroundColor={colors.aquaMarineTwo}
            icon={<Icon name={'chevron-right'} color={colors.white} />}
            onPress={()=>navigation.navigate('ShowGyms', { preFilters: { free_checkin: 1}})}
            style={styles.buttonBase}
          />
        </View>
      </TouchableOpacity>
    </ShadowView>
  );
};

const styles = StyleSheet.create({
  shadowContainer: {
    marginHorizontal: 20,
    backgroundColor: colors.white,
    borderRadius: 10,
    shadowColor: colors.black,
    shadowOpacity: 0.1,
    shadowRadius: 5,
    shadowOffset: { width: 0, height: 5 },
  },
  wrapper: {
    overflow: 'hidden',
    borderRadius: 10,
  },
  container: {
    flex: 1,
    padding: 20,
    borderRadius: 10,
    height: 138.54,
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: colors.white,
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
  },
  buttonBase: {
    height: 40,
    width: 40,
    position: 'absolute',
    right: 20,
    // bottom: -55,
    borderRadius: 10,
    shadowColor: colors.black,
    shadowOpacity: 0.2,
    shadowRadius: 5,
    shadowOffset: { width: 0, height: 5 },
  },
  image: {
    aspectRatio: 1.19,
    width: 150,
    height: "100%",
    resizeMode: 'contain',
    position: 'absolute',
    right: 8,
    bottom: 0,
  },
  textTitle: {
    fontFamily: fonts.BOLD,
    fontSize: 16,
    marginTop: 10,
    color: colors.blackTwo,
  },
  textContent: {
    fontFamily: fonts.MEDIUM,
    fontSize: 13,
    lineHeight: 17,
    marginTop: 10,
    color: colors.greyBlue,
  },
});
