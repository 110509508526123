/* eslint-disable react-native/no-inline-styles */
import React from 'react';

import { Text, View, StyleSheet, TouchableHighlight, ActivityIndicator, Image } from 'react-native';
import textStyles from '../styles/textStyles';
import colors from '../styles/colors';
import commons from '../styles/commons';
import PERFORMANCE from 'app/configs/performance';
import { debounce } from 'lodash';
const adjust = (color, amount) => {
  try {
    return (
      '#' +
      color
        .replace(/^#/, '')
        .replace(/../g, color_ =>
          ('0' + Math.min(255, Math.max(0, parseInt(color_, 16) + amount)).toString(16)).substr(-2)
        )
    );
  } catch (e) {
    return '#FF0000';
  }
};

const ButtonBase = ({
  title,
  style,
  icon,
  useIcon,
  iconTintColor,
  iconStyle,
  textStyle,
  onPress,
  backgroundColor,
  disabledBackgroundColor = colors.brownGrey,
  disabled,
  disabledStyle,
  disabledTextStyle,
  fill,
  loading,
  square,
}) => {
  const color = backgroundColor || colors.aquaMarineTwo;
  const darken = adjust(color, -50);
  return (
    <TouchableHighlight
      disabled={disabled}
      hitSlop={commons.hitSlop}
      underlayColor={darken}
      style={[
        {
          ...styles.buttonEnter,
          backgroundColor: disabled ? disabledBackgroundColor : color,
          opacity: disabled ? 0.5 : 1,
          alignSelf: fill ? 'stretch' : 'center',
          height: square ? 50 : 40,
          borderRadius: square ? 0 : 10,
        },
        style,
        disabled && disabledStyle,
      ]}
      onPress={debounce(() => onPress(), PERFORMANCE.ON_PRESS_DEBOUNCE_TIME)}>
      <View style={styles.content}>
        {!loading && (
          <>
            {icon &&
              (useIcon ? (
                <View style={{ marginRight: 10 }}>{icon}</View>
              ) : (
                <Image
                  source={icon}
                  style={{
                    tintColor: iconTintColor,
                    width: 20,
                    height: 20,
                    marginRight: 10,
                    resizeMode: 'contain',
                    ...iconStyle,
                  }}
                />
              ))}
            <Text style={[textStyles.bold14White, textStyle, disabled && disabledTextStyle]}>
              {title}
            </Text>
          </>
        )}
        {loading && <ActivityIndicator color={colors.white} animating />}
      </View>
    </TouchableHighlight>
  );
};

const styles = StyleSheet.create({
  buttonEnter: {
    marginTop: 20,
    paddingHorizontal: 48,
    minWidth: 154,
    justifyContent: 'center',
    height: 39,
  },
  content: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
export default ButtonBase;
