import ButtonBase from 'app/components/ButtonBase';
import colors from 'app/styles/colors';
import fonts from 'app/styles/fonts';
import { translate } from 'app/i18n/src/locale';
import React from 'react';
import { Image, StyleSheet, Text, View } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import pt from 'app/i18n/src/locale/pt';
import ShadowView from '@liuyunjs/react-native-simple-shadow-view';
import ButtonBaseIcon from 'app/components/ButtonBaseIcon';
import CustomIcon from 'app/styles/icon';

const CardProfessional = ({ onPress, attendance, style }) => {
  return (
    <ShadowView style={[styles.containerShadow, style]}>
      <LinearGradient
        useAngle
        angle={135}
        colors={
          attendance
            ? [colors.white, colors.white]
            : ['#3FD1D2', '#3FD1D2', '#3FD1D2', '#4DCCCD', '#4DCCCD']
        }
        style={styles.container}>
        <View style={{ flex: 1, marginHorizontal: 14 }}>
          <View style={{ marginBottom: 4 }}>
            <Text
              style={[
                styles.textProfessional,
                { color: attendance ? colors.darkPro : colors.white },
              ]}>
              {translate(attendance ? pt.telemedicine : pt.namu_orientation)}
            </Text>
          </View>
          <View>
            <Text
              style={[styles.textContent, { color: attendance ? colors.darkPro : colors.white }]}>
              {translate(
                attendance
                  ? pt.realize_medical_consultations
                  : pt.receive_up_to_min_teleorientation
              )}
            </Text>
          </View>
        </View>
        <Image
          source={
            attendance
              ? require('app/images/teleattendance_card.png')
              : require('app/images/teleorientation_card.png')
          }
          style={[styles.imageFull, !attendance && { transform: [{ scaleX: -1 }] }]}
        />
        <ButtonBaseIcon
          useIcon
          backgroundColor={attendance ? colors.aquaMarineTwo : colors.white}
          onPress={onPress}
          icon={
            <CustomIcon name={'chevron_right'} color={attendance ? colors.white : colors.aquaMarineTwo}/>
          }
          style={styles.buttonBase}
        />
      </LinearGradient>
    </ShadowView>
  );
};

const styles = StyleSheet.create({
  containerShadow: {
    flex: 1,
    height: 105,
    borderRadius: 18,
    backgroundColor: colors.white,
    shadowColor: '#1C6363',
    shadowOpacity: 0.2,
    shadowRadius: 5,
    shadowOffset: { width: 0, height: 5 },
  },
  container: {
    flex: 1,
    height: '100%',
    borderRadius: 15,
    paddingTop: 18,
    flexDirection: 'row',
    justifyContent: 'space-between',
    overflow: 'hidden',
  },
  imageFull: {
    width: 130,
    height: 130,
    position: 'absolute',
    right: 35,
    bottom: -10,
    alignSelf: 'flex-end',
    resizeMode: 'contain',
  },
  buttonBase: {
    position: 'absolute',
    zIndex: 200,
    height: 40,
    width: 40,
    right: 17,
    bottom: 17,
    borderRadius: 10,
  },
  textProfessional: {
    fontFamily: fonts.BOLD,
    color: colors.white,
    fontSize: 21,
    lineHeight: 26,
  },
  textContent: {
    fontFamily: fonts.REGULAR,
    fontSize: 11,
    color: colors.white,
    maxWidth: '50%'
  },
});

export default CardProfessional;
