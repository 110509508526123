import React, { useCallback, useEffect, useState } from 'react';

import {
  View,
  Text,
  StyleSheet,
  ImageBackground,
  ScrollView,
  Dimensions,
  ActivityIndicator,
  Modal,
} from 'react-native';

import colors from '../../../styles/colors';
import ChallengeChip from '../../../components/ChallengeChip';
import textStyles from '../../../styles/textStyles';
import commons from '../../../styles/commons';
import LinearGradient from 'react-native-linear-gradient';
import CapsuleLevel from '../../../components/CapsuleLevel';
import CapsuleBeverage from '../../../components/CapsuleBeverage';
import CapsuleLaunchOrDinner from '../../../components/CapsuleLaunchOrDinner';
import HeaderTitle from '../../profile/components/HeaderTitle';
import Chip from '../../../components/Chips';
import { translate } from '../../../i18n/src/locale';
import pt from '../../../i18n/src/locale/pt';
import Spacer from '../../../components/Spacer';
import CardFood from './components/CardFood';
import ButtonBase from '../../../components/ButtonBase';
import { useSafeArea } from 'react-native-safe-area-context';
import HeaderOver from '../../../components/HeaderOver';
import CardRestriction from '../meal/components/CardRestriction';
import RowMeal from '../meal/components/RowMeal';
import CapsuleBreakfast from '../../../components/CapsuleBreakfast';
import CapsuleLaunch from '../../../components/CapsuleLaunch';
import CapsuleDinner from '../../../components/CapsuleDinner';
import ModalTrail from '../meal/components/ModalTrail';
import FavoriteButton from 'app/components/FavoriteButton';
import { errorControl, showAlert, flashInfo, sendActionEvent } from 'app/services/utils';
import api from 'app/services/api';
import { useDispatch, useSelector } from 'react-redux';
import { setTrailSelected } from 'app/store/modules/trail/actions';
import apiNode from 'app/services/apiNode';
import fallback from 'app/images/fallback_food2.jpg';
import CapsuleCalendar from 'app/components/CapsuleCalendar';
import { useNavigation } from '@react-navigation/native';

const { width } = Dimensions.get('window');

const MealTrailScreen = ({ route }) => {
  const safeAreaInsets = useSafeArea();

  const { mealTrailId } = route?.params;
  const navigation = useNavigation();

  const dispatch = useDispatch();
  const { profile } = useSelector(state => state.user);
  const [isLoading, setIsLoading] = useState(true);
  const [started, setStarted] = useState(false);
  const [scrollY, setScrollY] = useState(0);
  const [modal, setModal] = useState(false);
  const [trailMeal, setTrailMeal] = useState({});
  const [meals, setMeals] = useState([]);
  const [imageTop, setImageTop] = useState(fallback);

  useEffect(() => {
    requestTrailMeal();
  }, [requestTrailMeal]);

  const handleStart = () => {
    if (started === false) {
      dispatch(
        setTrailSelected({
          id: trailMeal?.id,
          subcategory: {
            name: trailMeal?.category?.name,
            category_id: trailMeal?.category?.id,
          },
          duration: trailMeal?.duration,
          name: trailMeal?.name,
          image: { path: trailMeal?.image.path },
          difficulty: trailMeal?.difficulty,
          calories: trailMeal?.calories,
          payment_level: trailMeal?.payment_level,
          meal_trail: true,
          goals: trailMeal?.tags.map(t => ({ goal_name: t.name })),
          favorite: trailMeal?.favorite,
        })
      );
      sendActionEvent(
        'meal_trail_begin',
        { id: trailMeal.id, email: profile.email },
        profile.email
      );
      return Actions.start_calendar({ isMeal: true, categoryId: 1 });
    }

    return cancelTrail(trailMeal.item_id);
  };

  const cancelTrail = async itemId => {
    try {
      await api.delete('user-schedule', {
        params: {
          item_id: itemId,
          recurrence: 1,
        },
      });

      setStarted(false);

      flashInfo('O programa foi removido da sua agenda com sucesso');
      Actions.explorer();
    } catch (error) {
      showAlert(errorControl(error));
    }
  };

  const setTrailStarted = isTrailStarted => {
    if (isTrailStarted) {
      setStarted(true);
      return;
    }

    setStarted(false);
  };

  const requestTrailMeal = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await api.get(`meals/trail?trail_id=${mealTrailId}`);
      const favdata = await apiNode.get(`trails/favorite/${mealTrailId}`);
      const data = {
        ...res.data,
        ...favdata.data,
      };

      setTrailMeal(data);
      setTrailStarted(!!data.item_id);

      setImageTop({ uri: data?.image?.path });

      const newMeals = Object.keys(data.meals).map(send => ({
        title: send,
        data: data.meals[send],
      }));

      setMeals(newMeals);
    } catch (error) {
      showAlert(errorControl(error));
    }
    setIsLoading(false);
  }, [mealTrailId]);

  const handleScroll = event => {
    setScrollY(event.nativeEvent.contentOffset.y);
  };

  const hasPaymentLevel = () => {
    if (trailMeal.trail_payment_level === 1) {
      return true;
    }
    return false;
  };

  const getHeaderType = idTime => {
    if (idTime === 1) {
      return <CapsuleLaunch />;
    }
    if (idTime === 2) {
      return <CapsuleLaunchOrDinner />;
    }
    if (idTime === 3) {
      return <CapsuleBeverage />;
    }
    if (idTime === 4) {
      return <CapsuleBreakfast />;
    }
    if (idTime === 5) {
      return <CapsuleBreakfast />;
    }
    if (idTime === 6) {
      return <CapsuleDinner />;
    }
    return <CapsuleLaunchOrDinner />;
  };

  return (
    <>
      {isLoading ? (
        <View style={{ alignItems: 'center', justifyContent: 'center', flex: 1 }}>
          <ActivityIndicator size={'large'} color={colors.orangeyRed} />
        </View>
      ) : (
        <View style={styles.container}>
          <HeaderOver
            color={colors.orangeyRed}
            title={trailMeal.name}
            position={scrollY}
            maxSize={426}
            share={false}
            shareData={{
              title: trailMeal?.name,
              screen: 'meal_trail',
              image: trailMeal?.image?.path,
              description: trailMeal?.description,
              id: trailMeal?.id,
            }}
          />

          <ScrollView
            scrollEventThrottle={16}
            onScroll={handleScroll}
            contentContainerStyle={[styles.containerScroll]}>
            <View>
              <View style={styles.topImage}>
                <ImageBackground
                  source={imageTop}
                  onError={currentTarget => {
                    currentTarget.onError = null;
                    setImageTop(fallback);
                  }}
                  style={styles.imageBackground}>
                  <View style={styles.topper}>
                    <LinearGradient
                      colors={[colors.transparent, colors.orangeyRed80]}
                      style={styles.linear}
                    />

                    <ChallengeChip text={translate(pt.trail)} />

                    <View style={styles.holderTitle}>
                      <View style={{ flex: 1 }}>
                        <Text style={[textStyles.bold24White]}>
                          {trailMeal.name}
                        </Text>
                      </View>
                      <FavoriteButton
                        isFavorited={trailMeal?.favorite}
                        propType="trailId"
                        itemId={trailMeal?.id}
                      />
                    </View>
                  </View>
                </ImageBackground>
              </View>
              <View style={styles.capsuleHolder}>
                {/* <CapsuleHour style={styles.capsule} timeIn={'Semanas'} duration={trailMeal.duration} /> */}
                <CapsuleCalendar style={styles.capsule} duration={trailMeal.duration} />
                <CapsuleLevel
                  style={{ ...styles.capsule, marginLeft: 10 }}
                  difficulty={trailMeal.difficulty}
                />
              </View>
            </View>

            <View style={styles.content}>
              <Text style={textStyles.regular14}>{trailMeal.description}</Text>
            </View>

            <View style={styles.content}>
              <View style={styles.insideContainerRow}>
                {trailMeal?.tags?.map(tag => {
                  return <Chip title={tag.name} />;
                })}
              </View>

              {/* {!!trailMeal?.forum_topic && (
                <View>
                  <HeaderTitle
                    style={(textStyles.bold16grey, { padding: 0 }, { marginTop: 10 })}
                    title={translate(pt.groups)}
                  />
                  <RowTread
                    id={trailMeal?.forum_topic.id}
                    title={trailMeal?.forum_topic.title}
                    numPosts={trailMeal?.forum_topic.posts_num}
                    lastPost={trailMeal?.forum_topic.last_post}
                    onPress={() => handleForum()}
                  />
                </View>
              )} */}
            </View>

            <View style={styles.content}>
              <View style={commons.flexRowBetween}>
                {/* <AddShop
                  onPress={() => {
                    setModal(true);
                  }}
                  style={styles.add}
                />
                <Spacer value={10} /> */}
                {/* <AddPlaylist
                  paymentLevel={trailMeal.trail_payment_level}
                  style={styles.add}
                  typeItem="trail"
                  id={trailMeal.id}
                /> */}
              </View>
              {trailMeal?.user_dietary_restrictions?.length > 0 && (
                <CardRestriction restrictions={trailMeal?.user_dietary_restrictions} />
              )}
            </View>
            <Spacer value={10} />

            <HeaderTitle title={translate(pt.nutritional_value_average)} />
            <View style={styles.content_cards}>
              {trailMeal?.nutritional_info?.map(nutritional => (
                <View style={styles.smallCard}>
                  <Text style={textStyles.bold14}>{`${nutritional.calc_values.slice(0, -3)} ${
                    nutritional.unit_short_name
                  }`}</Text>
                  <Text numberOfLines={1} style={textStyles.regular11}>
                    {nutritional.type_name}
                  </Text>
                </View>
              ))}
            </View>

            <HeaderTitle title={translate(pt.resume_food)} />
            <View style={styles.content}>
              <CardFood
                kcal={trailMeal?.daily_calories}
                dishesCount={trailMeal?.dishes_count}
                hasRestriction={trailMeal?.user_dietary_restrictions?.length > 0}
              />
            </View>
            {meals.map(meal => {
              return (
                <>
                  <HeaderTitle title={translate(pt.day, { day: meal.data[0].day })} />
                  <ScrollView horizontal contentContainerStyle={styles.content}>
                    {meal.data.map(item => {
                      return (
                        <View>
                          {getHeaderType(item?.time?.id)}
                          <RowMeal
                            onPress={
                              hasPaymentLevel()
                                ? null//Actions.paywall
                                : () =>
                                    navigation.navigate('MealDetail', { mealId: item.id, trailId: trailMeal.id })
                            }
                            dishRecipes={item?.dishes_recipes}
                            paymentLevel={trailMeal.trail_payment_level}
                            name={item?.name}
                            level={item?.level}
                            mealTimeMinutes={item?.meal_time_minutes}
                            calories={item?.calories}
                            time={item?.time.name}
                            tagName={''}
                            style={styles.row_meal}
                          />
                        </View>
                      );
                    })}
                  </ScrollView>
                </>
              );
            })}
          </ScrollView>
          {/* <View style={{ backgroundColor: 'white', paddingBottom: safeAreaInsets.bottom + 20 }}>
            <View style={{ paddingHorizontal: 20 }}>
              <ButtonBase
                onPress={hasPaymentLevel() ? Actions.paywall : handleStart}
                backgroundColor={started ? colors.white : colors.orangeyRed}
                textStyle={{ color: started ? colors.coralThree : colors.white }}
                style={{ height: 49 }}
                fill
                title={translate(started ? pt.cancel_meal : pt.begin_meal)}
              />
            </View>
          </View> */}

          <Modal animationInTiming={600} visible={modal}>
            <ModalTrail
              onClose={() => {
                setModal(false);
              }}
            />
          </Modal>
        </View>
      )}
    </>
  );
};

export default MealTrailScreen;

const styles = StyleSheet.create({
  imageBackground: { height: 426 },
  containerScroll: { flexGrow: 1 },
  row_meal: { width: 316, marginRight: 16 },
  smallCard: {
    width: '23%',
    marginRight: '2%',
    marginTop: 8,
    height: 53,
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.white,
  },
  content_cards: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    paddingHorizontal: 14,
    marginBottom: 14,
  },
  topper: { padding: 14, flex: 1, justifyContent: 'flex-end' },
  content: { paddingHorizontal: 14, marginBottom: 14 },

  holderTitle: { flexDirection: 'row', marginVertical: 10, alignItems: 'center' },
  linear: { position: 'absolute', width: '100%', height: 200, left: 0, bottom: 0 },
  capsuleHolder: {
    flexDirection: 'row',
    marginTop: -10,
    marginBottom: 20,
    justifyContent: 'flex-start',
    marginLeft: 16,
  },
  capsule: {
    backgroundColor: colors.whiteTwo,
    borderColor: '#eee',
    borderWidth: StyleSheet.hairlineWidth,
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 6,
    shadowOpacity: 0.2,
  },
  topImage: {
    height: 426,
    borderBottomRightRadius: 36,
    backgroundColor: 'grey',
    overflow: 'hidden',
  },
  round: {
    marginLeft: 12,
    width: 36,
    height: 36,
    borderRadius: 18,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.blackAlpha18,
  },
  container: {
    // flex: 1,
    height: '100dvh',
    backgroundColor: colors.paleGrey,
  },
  insideContainerRow: {
    marginTop: 10,
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 0,
  },
  add: { flex: 1, flexDirection: 'column', alignItems: 'flex-start', paddingHorizontal: 10 },
});
