import React from 'react';

import { View, Text, Image, StyleSheet } from 'react-native';
import textStyles from 'app/styles/textStyles';

const MenuItemContent = ({ title, icon, iconColor }) => (
  <View style={styles.container}>
    <Image source={icon} style={{ tintColor: iconColor, ...styles.icon }} />
    <Text style={[textStyles.semi14, { marginLeft: 10 }]}>{title}</Text>
  </View>
);

export default MenuItemContent;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    width: 120,
    alignItems: 'center',
  },
  icon: {
    resizeMode: 'contain',
    width: 16,
    height: 16
  }
});
