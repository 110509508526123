import React from 'react';
import ContentLoader, { Rect } from 'react-content-loader/native';
import { Dimensions } from 'react-native';

const ScheduledGymLoader = props => (
  <ContentLoader
    backgroundColor={'#dddddd'}
    width={Dimensions.get('screen').width}
    height={176}
    viewBox={`0 0 ${Dimensions.get('screen').width}px 176x`}>
    <Rect x="20" y="0" rx="10" ry="10" width={Dimensions.get('screen').width - 40} height="176" />
    <Rect x="40" y="20" rx="10" ry="10" width="80" height="80" />
    <Rect x="130" y="20" rx="4" ry="4" width="70" height="20" />
    <Rect x="205" y="20" rx="4" ry="4" width="70" height="20" />
    <Rect x="130" y="50" rx="4" ry="4" width="130" height="25" />
    <Rect x="130" y="85" rx="4" ry="4" width="150" height="18" />
    <Rect x="40" y="120" rx="8" ry="8" width={Dimensions.get('screen').width - 80} height="40" />
  </ContentLoader>
);

export default ScheduledGymLoader;
