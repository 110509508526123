import React from 'react';
import ContentLoader, { Rect } from 'react-content-loader/native';
import { Dimensions } from 'react-native';

const CourseLoader = props => (
  <ContentLoader
    backgroundColor={'#dddddd'}
    style={{ margin: 16 }}
    width={Dimensions.get('screen').width}
    height={320}
    viewBox={`0 0 ${Dimensions.get('screen').width}px 320x`}>
    <Rect x="0" y="0" rx="4" ry="4" width="130" height="20" />

    <Rect x="0" y="30" rx="3" ry="3" width="280" height="280" />
    <Rect x="20" y="50" rx="2" ry="2" width="70" height="15" />
    <Rect x="55" y="220" rx="4" ry="4" width="170" height="15" />
    <Rect x="70" y="260" rx="4" ry="4" width="140" height="15" />

    <Rect x="300" y="30" rx="3" ry="3" width="280" height="280" />
    <Rect x="320" y="50" rx="2" ry="2" width="70" height="15" />
    <Rect x="355" y="220" rx="4" ry="4" width="170" height="15" />
    <Rect x="370" y="260" rx="4" ry="4" width="140" height="15" />
  </ContentLoader>
);

export default CourseLoader;
