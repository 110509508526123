import React from 'react';
import ContentLoader, { Rect } from 'react-content-loader/native';
import { Dimensions } from 'react-native';

const ClassLoader = props => (
  <ContentLoader
    backgroundColor={'#dddddd'}
    style={{ margin: 16 }}
    width={Dimensions.get('screen').width}
    height={props.containerHeight || 500}
    viewBox={`0 0 ${Dimensions.get('screen').width}px 500px`}>
    <Rect x="0" y="0" rx="4" ry="4" width="130" height="20" />

    <Rect x="0" y="30" rx="3" ry="3" width="160" height="130" />
    <Rect x="0" y="170" rx="2" ry="2" width="70" height="15" />
    <Rect x="90" y="170" rx="2" ry="2" width="70" height="15" />
    <Rect x="0" y="195" rx="4" ry="4" width="140" height="15" />

    <Rect x="180" y="30" rx="3" ry="3" width="160" height="130" />
    <Rect x="180" y="170" rx="2" ry="2" width="70" height="15" />
    <Rect x="270" y="170" rx="2" ry="2" width="70" height="15" />
    <Rect x="180" y="195" rx="4" ry="4" width="140" height="15" />

    <Rect x="360" y="30" rx="3" ry="3" width="160" height="130" />
    <Rect x="360" y="170" rx="2" ry="2" width="70" height="15" />
    <Rect x="450" y="170" rx="2" ry="2" width="70" height="15" />
    <Rect x="360" y="195" rx="4" ry="4" width="140" height="15" />

    {!props.onlyOneRow && (
      <>
        <Rect x="0" y="250" rx="4" ry="4" width="130" height="20" />

        <Rect x="0" y="280" rx="3" ry="3" width="160" height="130" />
        <Rect x="0" y="420" rx="2" ry="2" width="70" height="15" />
        <Rect x="90" y="420" rx="2" ry="2" width="70" height="15" />
        <Rect x="0" y="445" rx="4" ry="4" width="140" height="15" />

        <Rect x="180" y="280" rx="3" ry="3" width="160" height="130" />
        <Rect x="180" y="420" rx="2" ry="2" width="70" height="15" />
        <Rect x="270" y="420" rx="2" ry="2" width="70" height="15" />
        <Rect x="180" y="445" rx="4" ry="4" width="140" height="15" />

        <Rect x="360" y="280" rx="3" ry="3" width="160" height="130" />
        <Rect x="360" y="420" rx="2" ry="2" width="70" height="15" />
        <Rect x="450" y="420" rx="2" ry="2" width="70" height="15" />
        <Rect x="360" y="445" rx="4" ry="4" width="140" height="15" />
      </>
    )}
  </ContentLoader>
);

export default ClassLoader;
