/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useState, useRef } from 'react';

import {
  View,
  Text,
  StyleSheet,
  ScrollView,
  ImageBackground,
  Dimensions,
  StatusBar,
  ActivityIndicator,
  Image,
  TouchableOpacity,
} from 'react-native';

import colors from '../../../styles/colors';
import ChallengeChip from '../../../components/ChallengeChip';
import textStyles from '../../../styles/textStyles';
import commons from '../../../styles/commons';
import Icon from 'react-native-vector-icons/dist/FontAwesome';
import LinearGradient from 'react-native-linear-gradient';
import { translate } from '../../../i18n/src/locale';
import pt from '../../../i18n/src/locale/pt';
import { useSafeArea } from 'react-native-safe-area-context';
import MealInternalPlate from '../meal/components/MealInternalPlate';
// import {TabView} from 'react-native-web-tab-view';
// import RenderTabBar from '../../../components/RenderTabBar';
import MealInternal from '../meal/components/MealInternal';
import { useEffect } from 'react';
import api from 'app/services/api';
import { errorControl } from 'app/services/utils';
import FavoriteButton from 'app/components/FavoriteButton';
// import AppIntroSlider from 'react-native-app-intro-slider';
import HeaderOver from 'app/components/HeaderOver';
import { useSelector } from 'react-redux';
import fallback from 'app/images/fallback_food2.jpg';
import { useNavigation } from '@react-navigation/native';
import fonts from '../../../styles/fonts';
const { width } = Dimensions.get('window');

const MealDetailScreen = ({ route }) => {

  const { mealId, trailId } = route?.params;
  const navigation = useNavigation();

  const safeAreaInsets = useSafeArea();
  const scrollViewRef = useRef();
  const initialLayout = { height: 0, width: Dimensions.get('window').width };
  const { isHowToLandScape } = useSelector(state => state.meal);
  const { profile } = useSelector(state => state.user);
  const [index, setIndex] = useState(0);
  const [scrollY, setScrollY] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [meal, setMeal] = useState({});
  const [routes, setRoutes] = useState([{ key: 0, title: translate(pt.meal) }]);
  const [imageTop, setImageTop] = useState(fallback);
  const [ activeTab, setActiveTab] = useState(0)

  useEffect(() => {
    requestRecipe();
  }, [requestRecipe]);

  const handleScroll = event => {
    setScrollY(event.nativeEvent.contentOffset.y);
  };

  const requestRecipe = useCallback(async () => {
    setIsLoading(true);
    try {
      if (trailId) {
        const { data } = await api.get(`meals/${mealId}?trail_id=${trailId}`);
        setMeal(data);
        setImageTop({ uri: data?.image?.path });
        setMealsPictures(
          data.dishes_recipes.map(dish => {
            return dish.image.path ? { uri: dish.image.path } : null;
          })
        );
      } else {
        const { data } = await api.get(`meals/${mealId}`);
        setMeal(data);
        setImageTop({ uri: data?.image?.path });
        setMealsPictures(
          data.dishes_recipes.map(dish => {
            return dish.image.path ? { uri: dish.image.path } : null;
          })
        );
      }
    } catch (error) {
      console.log(errorControl(error));
    }
    setIsLoading(false);
  }, [mealId, trailId]);

  useEffect(() => {
    if (meal?.dishes_recipes?.length > 0) {
      const newData = meal?.dishes_recipes?.map((_, indexDishes) => {
        return { key: indexDishes + 1, title: `Prato ${indexDishes + 1}` };
      });
      setRoutes([...routes, ...newData]);
    }
  }, [meal]);

  const renderScene = () => {
    if (activeTab === 0) {
      return <MealInternal meal={meal} isVisible />;
    }
    if (activeTab > 0) {
      return (
        <MealInternalPlate
          dishes={meal?.dishes_recipes[activeTab - 1]}
          isVisible
          // isVisible={index === activeTab}
        />
      );
    }
  };

  const _renderItem = ({ item, index: indexRender }) => {
    return (
      <ImageBackground
        resizeMode="cover"
        source={item}
        onError={c => {
          c.onError = null;
          let updatedPic = mealsPictures.map((x, i) => (i === indexRender ? fallback : x));
          setMealsPictures(updatedPic);
        }}
        style={{ width: '100%', height: 426 }}
      />
    );
  };

  const _renderNextButton = () => {
    return (
      <View style={styles.button}>
        <Icon name="angle-right" size={50} color={colors.orangeyRed} />
      </View>
    );
  };

  const _renderPrevButton = () => {
    return (
      <View style={styles.button}>
        <Icon name="angle-left" size={50} color={colors.orangeyRed} />
      </View>
    );
  };

  const [mealsPictures, setMealsPictures] = useState([]);

  const scrollToDown = () => {
    if (isHowToLandScape) {
      scrollViewRef.current.scrollToEnd({ animated: true });
    }
  };

  return (
    <>
      {isLoading ? (
        <View style={{ alignItems: 'center', justifyContent: 'center', flex: 1 }}>
          <ActivityIndicator size={'large'} color={colors.orangeyRed} />
        </View>
      ) : (
        <View style={styles.container}>
          {!isHowToLandScape && (
            <HeaderOver
              color={colors.orangeyRed}
              title={meal.name}
              position={scrollY}
              maxSize={426}
              share={false}
              shareData={{
                title: meal?.name,
                screen: 'meal_detail',
                image: mealsPictures[0]?.uri,
                description: meal?.description,
                id: meal?.id,
              }}
            />
          )}
          <StatusBar barStyle="light-content" />
          <ScrollView
            scrollEventThrottle={16}
            onScroll={handleScroll}
            ref={scrollViewRef}
            scrollEnabled={!isHowToLandScape}
            onContentSizeChange={() => scrollToDown()}
            contentContainerStyle={{ paddingBottom: safeAreaInsets.bottom }}>
            <View style={{ flexGrow: 1, paddingBottom: safeAreaInsets.bottom }}>
              <View style={{ backgroundColor: 'white' }}>
                <View style={styles.topImage}>
                  <ImageBackground
                    source={imageTop}
                    onError={currentTarget => {
                      currentTarget.onError = null;
                      setImageTop(fallback);
                    }}
                    style={{ height: 426 }}>
                      <Image
                        source={{uri: mealsPictures[0].uri}}
                        style={styles.slideContainer}
                      />
                    {/* <AppIntroSlider
                      style={styles.slideContainer}
                      dotStyle={{ width: 0, height: 0 }}
                      activeDotStyle={{ width: 0, height: 0 }}
                      renderItem={_renderItem}
                      slides={mealsPictures}
                      showSkipButton={false}
                      showPrevButton={true}
                      showNextButton={true}
                      renderNextButton={_renderNextButton}
                      renderPrevButton={_renderPrevButton}
                      showDoneButton={false}
                      buttonTextStyle={textStyles.createAccount}
                    /> */}
                    <View style={styles.topper}>
                      <LinearGradient
                        colors={[colors.transparent, colors.orangeyRed80]}
                        style={styles.linear}
                      />

                      <ChallengeChip text={translate(pt.meal)} />

                      <View style={styles.holderTitle}>
                        <View style={{ flex: 1 }}>
                          <Text style={[textStyles.bold24White]}>
                            {index !== 0 ? meal?.dishes_recipes[index - 1].name : meal?.name}
                          </Text>
                        </View>
                        <FavoriteButton
                          isFavorited={meal?.favorite === 1}
                          propType="meal_id"
                          itemId={meal?.id}
                        />
                      </View>
                    </View>
                  </ImageBackground>
                </View>
              </View>
              {/* <TabView
                lazy
                swipeEnabled={false}
                renderTabBar={props =>
                  RenderTabBar({
                    ...props,
                    email: profile.email,
                  })
                }
                navigationState={{ index, routes }}
                renderScene={renderScene}
                onIndexChange={setIndex}
                initialLayout={initialLayout}
              /> */}
              <View style={[styles.menuContainer, { paddingTop: safeAreaInsets.top + 7 }]}>
                {routes.map(r => {
                  return (
                    <TouchableOpacity
                      onPress={() => setActiveTab(r.key)}
                      style={[{ marginLeft: 30 }, activeTab === r.key && styles.tabSelected]}>
                      <Text style={[styles.menuText, activeTab === r.key && styles.textTabSelected]}>{r.title}</Text>
                    </TouchableOpacity>
                  );
                })}
              </View>
              <View style={styles.contentContainer}>
                {renderScene()}
              </View>
            </View>
          </ScrollView>
        </View>
      )}
    </>
  );
};

export default MealDetailScreen;

const styles = StyleSheet.create({
  starHolder: { alignItems: 'flex-start' },
  topper: { padding: 14, flex: 1, justifyContent: 'flex-end' },

  holderTitle: { flexDirection: 'row', marginVertical: 10, alignItems: 'center' },
  linear: { position: 'absolute', width: '100%', height: 200, left: 0, bottom: 0 },
  topImage: {
    height: 426,
    borderBottomRightRadius: 36,
    backgroundColor: 'grey',
    overflow: 'hidden',
  },
  round: {
    marginLeft: 12,
    width: 36,
    height: 36,
    borderRadius: 18,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.blackAlpha18,
  },
  container: {
    // flex: 1,
    height: '100%',
    backgroundColor: colors.paleGrey,
  },
  slideContainer: {
    height: 426,
    width: '100%',
    position: 'absolute',
    right: 0,
    left: 0,
    top: 0,
  },
  button: {
    width: 38,
    height: 38,
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: colors.white,
    width: width,
  },
  menuText: {
    marginTop: 5,
    height: 35,
    fontFamily: fonts.REGULAR,
    fontSize: 18,
    color: colors.ligthGray,
    borderBottomWidth: 4,
    borderTopLeftRadius: 2,
    borderTopRightRadius: 2,
    borderBottomColor: 'transparent',
    // paddingBottom: 12,
  },
  tabSelected: {
    borderBottomColor: colors.greyBlue,
    borderBottomWidth: 4,
  },
  textTabSelected: {
    color: colors.black,
    fontFamily: fonts.BOLD,
  },
  contentContainer: {
    flex: 1,
  },
});
