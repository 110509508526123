import AnimatedTab from 'app/components/AnimatedTab';
import SearchBarNew from 'app/components/SearchBarNew';
import pt from 'app/i18n/src/locale/pt';
import FilterResult from 'app/screens/core/explorer/components/FilterResult';
import ButtonBase from 'app/components/ButtonBase';
import fonts from 'app/styles/fonts';
import api from 'app/services/api';
import {
  buildURL,
  errorControl,
  explodeQuery,
  sendActionEvent,
  showAlert,
} from 'app/services/utils';
import colors from 'app/styles/colors';
import React, { useCallback, useEffect, useRef } from 'react';
import { useState } from 'react';
import {
  ScrollView,
  StyleSheet,
  Text,
  View,
  FlatList,
  Dimensions,
  StatusBar,
  TouchableOpacity,
  Image,
  Platform,
} from 'react-native';
import { FAB } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';
import CardClassesNew from 'app/screens/profile/components/CardClassesNew';
import HeaderTitleNew from 'app/screens/core/home/components/HeaderTitleNew';
import HeaderTitleWithBackground from 'app/screens/core/home/components/HeaderTitleWithBackground';
import apiNode from 'app/services/apiNode';
import {
  setClasses,
  fetchClasses,
  setFilters,
  setExplorerTabIndex,
  setLoadingClassesToShow,
} from 'app/store/modules/classes/actions';
import { debounce } from 'lodash';
import PERFORMANCE from 'app/configs/performance';
import { setNameShare } from 'app/store/modules/category/actions';
import { translate } from 'app/i18n/src/locale';
import CardClassesLast from 'app/screens/core/explorer/components/CardClassesLast';
import { generateQueryParamsForFilter } from 'app/store/modules/classes/sagas';
import RBSheet from 'react-native-raw-bottom-sheet';
import BottomSheetAddAgenda from 'app/screens/core/explorer/components/BottomSheetAddAgenda';
import CardCourses from 'app/screens/core/explorer/components/CardCourses';
import CardProgram from 'app/components/CardProgram';
import arrowRightWater2 from 'app/images/arrowRightWater2.png';
import { useSafeArea } from 'react-native-safe-area-context';
import ClassLoader from 'app/components/Loaders/ClassLoader';
import CourseLoader from 'app/components/Loaders/CourseLoader';
import HandleActivity from 'app/screens/core/activities/components/HandleActivity';
import { useGamification } from 'app/hooks/useGamification';
import { fetchPrograms } from 'app/store/modules/programs/action';
import body_more from 'app/images/body_more.png';
import mind_more from 'app/images/mind_more.png';
import fallback_food from 'app/images/fallback_food.jpg';
import { MainHeader } from 'app/components/MainHeader';
import NamuLogo from 'app/images/namu_logo.svg';
import { useNavigation, useRoute } from '@react-navigation/native';
import ShadowView from '@liuyunjs/react-native-simple-shadow-view';

const { height } = Dimensions.get('screen');
const categoryIds = {
  FOOD: 1,
  BODY: 2,
  MIND: 3,
};

const categoryNames = {
  FOOD: 'Alimentação',
  BODY: 'Corpo',
  MIND: 'Mente',
};

const options = [
  {
    id: categoryIds.FOOD,
    title: pt.feeding,
    color: colors.grapefruit,
    colors: [colors.orangeyRed, colors.grapefruit],
    location: [0.022, 0.69],
    angle: 305.18,
    borderColor: colors.redUltraLight,
    name: categoryNames.FOOD,
    fallback: fallback_food,
    key: 'food_explorer',
  },
  {
    id: categoryIds.BODY,
    title: pt.activities,
    color: colors.aquaMarineTwo,
    colors: [colors.aquaMarineTwo, colors.aquaMarineTwo, colors.aquaMarineGrd],
    location: [1.1, 1.1, 0.494],
    angle: 64.16,
    borderColor: colors.aquaBlueDark,
    name: categoryNames.BODY,
    fallback: body_more,
    key: 'body_explorer',
  },
  {
    id: categoryIds.MIND,
    title: pt.meditation,
    color: colors.pastelOrange2,
    colors: [colors.pastelOrange, colors.apricot],
    angle: 309.69,
    location: [0, 0.842],
    borderColor: colors.orangeUltraLight,
    name: categoryNames.MIND,
    fallback: mind_more,
    key: 'mind_explorer',
  },
];

const bodyOptionIndex = 1;
const SearchScreenNew = ({ route }) => {
  const dispatch = useDispatch();
  const {
    bodyClasses,
    mindClasses,
    foodClasses,
    bodyFilters,
    mindFilters,
    foodFilters,
    explorerTabIndex,
    loadingClassesToShow,
  } = useSelector(state => state.classes);
  const navigation = useNavigation();
  const isCalendar = route?.params?.isCalendar;
  const categoryId = route?.params?.categoryId;

  const programsRedux = useSelector(state => state.programs);

  const programs = programsRedux?.programs?.data;
  const programsLoad = programsRedux?.programsLoad;

  const { profile } = useSelector(state => state.user);
  const [isNutritionMode, setNutritionMode] = useState(profile?.plan_meta?.nutrition_mode);
  const [searchQuery, setSearchQuery] = useState('');
  const [subCategories, setSubCategories] = useState([]);
  const [filteredSubCategories, setFilteredSubCategories] = useState([]);
  const [subcategoriesFilter, setSubcategoriesFilter] = useState([]);
  const [currentCategory, setCurrentCategory] = useState(
    isNutritionMode ? 0 : categoryId ? categoryId - 1 : bodyOptionIndex
  );
  const [currentCategoryClasses, setCurrentCategoryClasses] = useState({});
  const [currentCategoryFilters, setCurrentCategoryFilters] = useState({});
  const [initialFilters, setInitialFilters] = useState({});
  const [filterQueryParams, setFilterQueryParams] = useState('');
  const [idSelected, setIdSelected] = useState(0);
  const [courses, setCourses] = useState([]);
  const [categoryCourses, setCategoryCourses] = useState([]);
  const [courseCategories, setCourseCategories] = useState({});
  const [courseCategoriesIds, setCourseCategoriesIds] = useState({});

  const [loadingCourses, setLoadingCourses] = useState(false);

  const [sipatId, setSipatId] = useState(null);

  const { eventsGenerationByHandleActivity } = useGamification();

  const refRBSheet = useRef();

  const safeAreaInsets = useSafeArea();

  const isRenderProgram = () => {
    return currentCategory !== 0 ? true : false;
  };

  useEffect(() => {
    if (!categoryId && !isNutritionMode) {
      setCurrentCategory(explorerTabIndex);
    }
    setSubcategoriesFilter([]);
  }, []);

  useEffect(() => {
    requestInitialFilters();
  }, [requestInitialFilters, currentCategory]);

  useEffect(() => {
    if (idSelected) {
      refRBSheet.current.open();
    }
  }, [idSelected]);

  const requestGetSubCategories = useCallback(async () => {
    try {
      const { data } = await api.get('categories/subcategories');
      setSubCategories(data);
    } catch (error) {
      showAlert(errorControl(error));
    }
  }, []);

  const requestCourses = useCallback(async () => {
    try {
      setLoadingCourses(true);
      const { data } = await api.get('masterclass/get-courses');
      setCourses(data);
      setLoadingCourses(false);
    } catch (e) {
      showAlert(errorControl(e));
    }
  }, []);

  const requestCategories = useCallback(async () => {
    try {
      const { data } = await api.get('masterclass/get-categories');
      setCourseCategories(data);
    } catch (e) {
      showAlert(errorControl(e));
    }
  });

  const requestGetSipatCourseId = useCallback(async () => {
    try {
      const { data } = await apiNode.get('meta/sipat_id');
      setSipatId(data.value);
    } catch (e) {
      console.log(errorControl(e));
    }
  }, []);

  const getPrograms = queryParams => {
    if (isRenderProgram()) {
      dispatch(fetchPrograms(currentCategory + 1, searchQuery, queryParams));
    }
  };

  useEffect(() => {
    if (profile?.plan_meta?.sipat_enabled) {
      requestGetSipatCourseId();
    }
  }, []);

  useEffect(() => {
    if (!Object.keys(courseCategories).length || !courses.length) {
      return;
    }
    setCourseCategoriesIds(courseCategories.tree[options[currentCategory].id]);
    const categories = courseCategories.tree[options[currentCategory].id];
    setCategoryCourses(
      courses
        .filter(course => course.categories.some(category => category in categories))
        .map(course => {
          let goal = '';
          if (course.categories[0] && courseCategories.categories[course.categories[0]]) {
            if (course.categories.length > 1) {
              if (
                course.categories[0] == 6 ||
                course.categories[0] == 7 ||
                course.categories[0] == 252
              ) {
                goal = courseCategories.categories[course.categories[1]];
                return { ...course, goal };
              }
            }
            goal = courseCategories.categories[course.categories[0]];
          }
          return { ...course, goal };
        })
    );
  }, [currentCategory, courseCategories, courses]);

  const handleRemoveFilter = filter => {
    let removedFilter = currentCategoryFilters.filter(el => el !== filter);
    setCurrentCategoryFilters(removedFilter);
    handleConfirmCategories(removedFilter);
  };

  useEffect(() => {
    requestCategories();
    requestCourses();
    requestGetSubCategories();
  }, [requestGetSubCategories, requestCourses]);

  useEffect(() => {
    if (subCategories.length) {
      setFilteredSubCategories(
        subCategories.filter(el => el.category_id === options[currentCategory].id)
      );
    }
  }, [currentCategory, subCategories]);

  const reloadClasses = () => {
    dispatch(fetchClasses(options[currentCategory].id, profile.user_id));
  };

  useEffect(() => {
    setSubcategoriesFilter([]);
    reloadClasses();
    const selected = options.find((option, index) => index === currentCategory);
    handleActivityFunction(selected);
    getPrograms();
  }, [currentCategory]);

  useEffect(() => {
    const selectedFilter =
      options[currentCategory].id === categoryIds.BODY
        ? bodyFilters
        : options[currentCategory].id === categoryIds.MIND
        ? mindFilters
        : foodFilters;

    const formattedFilters = Object.entries(selectedFilter)
      .map(([type, item]) => {
        return item.map(x => ({ ...x, type }));
      })
      .flat()
      .filter(x => x.active == true);

    setCurrentCategoryFilters(formattedFilters);

    let queryParams = generateQueryParamsForFilter(selectedFilter);
    setFilterQueryParams(queryParams);
    if (options[currentCategory].id === categoryIds.FOOD) {
      setCurrentCategoryClasses(foodClasses);
    } else if (options[currentCategory].id === categoryIds.MIND) {
      setCurrentCategoryClasses(mindClasses);
    } else if (options[currentCategory].id === categoryIds.BODY) {
      setCurrentCategoryClasses(bodyClasses);
    }
    const queryParamsToPrograms = generateQueryParamsForFilter(selectedFilter, 'programs');
    getPrograms(queryParamsToPrograms);
  }, [bodyClasses, foodClasses, mindClasses]);

  const handleActivityFunction = async selected => {
    try {
      const { data } = await HandleActivity({ routeName: selected.key });

      eventsGenerationByHandleActivity(data);
    } catch (error) {}
  };

  const getGradientColor = categoryId => {
    if (categoryId === categoryIds.FOOD) {
      return 'red';
    }
    if (categoryId === categoryIds.BODY) {
      return 'green';
    }
    return 'orange';
  };

  const renderCardPrograms = data => {
    return (
      <FlatList
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={styles.flatSpace}
        style={{ marginBottom: -20 }}
        data={data.slice(0, 4)}
        horizontal
        keyExtractor={item => item.id}
        renderItem={({ item }) => {
          return (
            <CardProgram
              type={item.subcategory_name}
              title={item.name}
              image={item.image_path}
              difficulty={item.difficulty}
              duration={item.duration_in_weeks} // in weeks
              kcal={parseInt(item.calories)}
              plus={null}
              minus={null}
              paymentLevel={item.payment_level || item.trail_payment_level}
              onPress={debounce(() => {
                item.payment_level === 1 || item.trail_payment_level === 1
                ? navigation.navigate('Paywall')
                  : openTrailNew(item.category_id, item.id, null, 'activities');
                dispatch(setNameShare(item.name));
              }, PERFORMANCE.ON_PRESS_DEBOUNCE_TIME)}
              style={styles.leftCard}
              gradientColor={getGradientColor(item.category_id)}
              // goals={item?.goals ? item?.goals : []}
              favorite={item.favorite}
              id={item.id}
            />
          );
        }}
        ListFooterComponent={
          <CardClassesLast
            color={options[currentCategory].color}
            title={'Todos os programas'}
            image={options[currentCategory].fallback}
            style={{ marginHorizontal: 16, width: 250, height: 224, borderRadius: 18 }}
            onPress={() => {
              navigation.navigate('ShowMorePrograms', { category: currentCategory + 1 });
            }}
          />
        }
      />
    );
  };

  const getHeaderGradientColor = () => {
    if (options[currentCategory].id === 1) {
      return [colors.grapefruit, colors.orangeyRed];
    }
    if (options[currentCategory].id === 3) {
      return [colors.orangeTwo, colors.orangeThree];
    }
    return [colors.aquaMarineTwo, colors.aquaMarineGrd];
  };

  const getColorBase = categoryId => {
    if (categoryId === categoryIds.FOOD) {
      return colors.darkRed;
    }
    if (categoryId === categoryIds.BODY) {
      return colors.aquaMarineTwo;
    }
    return colors.brightOrange;
  };

  const openTrailNew = (categoryId, id, subcategory, type) => {
    if (type == 'activities') {
      if (categoryId === 1) {
        navigation.navigate('MealTrail', { mealTrailId: id });
      } else if (categoryId === 2) {
        navigation.navigate('Trail', { trailId: id })
      } else if (categoryId === 3) {
        navigation.navigate('MeditationTrail', { meditationId: id });
      }

      sendActionEvent('click_at_trail', {
        id: id,
        email: profile.email,
      });
    } else {
      if (categoryId === 1) {
        navigation.navigate('PlateDetail', { plateId: id });
        sendActionEvent('click_at_trail', {
          id: id,
          email: profile.email,
        });
      } else if (categoryId === 2) {
        navigation.navigate('LessonActivity', { lessonId: id, subcategory });
        sendActionEvent('click_at_exercise', {
          id: id,
          email: profile.email,
        });
      } else if (categoryId === 3) {
        navigation.navigate('LessonMeditation', { classId: id });
        sendActionEvent('click_at_meditation', {
          id: id,
          email: profile.email,
        });
      }
    }
  };

  const handleConfirmCategories = categories => {
    let finalFilters = {};
    for (let sf of Object.keys(initialFilters)) {
      finalFilters = {
        ...finalFilters,
        ...{
          [sf]: initialFilters[sf].map(x => {
            if (categories.some(c => c.name === x.name)) {
              return { ...x, active: true, checked: true };
            }
            return { ...x, active: false, checked: false };
          }),
        },
      };
    }
    dispatch(setFilters({ ...finalFilters }, options[currentCategory].id));
    reloadClasses();
  };

  const handleShowMore = url => {
    dispatch(setExplorerTabIndex(currentCategory));
    navigation.navigate('ShowMoreClasses', {
      color: options[currentCategory].color,
      categoryId: options[currentCategory].id,
      filters: currentCategoryFilters,
      url,
      isCalendar,
      onPressAdd: addToCalendar
    })
  };

  const handleShowMoreCourses = () => {
    dispatch(setExplorerTabIndex(currentCategory));
    navigation.navigate('ShowMoreCourses', {
      color: options[currentCategory].color,
      categoryId: options[currentCategory].id,
      data: categoryCourses,
      options: { courseCategoryIds: courseCategoriesIds, categories: courseCategories.categories },
      colorBase: getColorBase(options[currentCategory].id),
      type: 6,
      title: 'Todos os cursos',
    });
  };

  const showMoreSectionUrl = subcategory => {
    const subcategoryParamRegex = /subcategories=(.*?)&/g;
    let queryParams = filterQueryParams.replace(subcategoryParamRegex, (match, p1) => {
      return match.replace(p1, subcategory);
    });

    if (!queryParams) {
      queryParams = `subcategory=${subcategory}`;
    }

    const currentCategoryName = options[currentCategory].name;

    const url = `/classes/?limit=1000&category=${currentCategoryName}&${queryParams}`;
    return url;
  };

  const requestInitialFilters = useCallback(async () => {
    try {
      const { data } = await apiNode.get(
        `/classe-filters/?categoryId=${options[currentCategory].id}&filterId=`
      );

      if (options[currentCategory].id !== categoryIds.FOOD && !!data.ingredients) {
        delete data.ingredients;
      }
      if (data.filter) {
        delete data.filter;
      }

      setInitialFilters(data);
    } catch (error) {
      showAlert(errorControl(error));
    }
  }, [currentCategory]);

  const handleSearch = async () => {
    if (searchQuery.trim().length) {
      dispatch(setLoadingClassesToShow(true));
      const { data } = await apiNode.get(
        buildURL({
          base: '/classes',
          query: {
            limit: 50,
            userId: profile.user_id,
            category: options[currentCategory].name,
            like: searchQuery,
            groupBy: 'subcategory',
            ...explodeQuery(filterQueryParams),
          },
        })
      );
      getPrograms();
      dispatch(setClasses(data, options[currentCategory].id));
      dispatch(setLoadingClassesToShow(false));
    } else {
      dispatch(fetchClasses(explorerTabIndex + 1, profile.user_id));
    }
  };

  const addToCalendar = item => {
    if (item.payment_level === 1) {
      navigation.navigate('Paywall');
      return;
    }
    setIdSelected(item.id);
  };

  const handleChangeTab = categoryIndex => {
    dispatch(setLoadingClassesToShow(true));
    setCurrentCategory(categoryIndex);
    dispatch(setExplorerTabIndex(categoryIndex));
  };

  const renderItem = item => {
    return (
      <View>
        <CardClassesNew
          type={item.subcategory?.name}
          title={item.name}
          image={item.image.path}
          difficulty={item.difficulty}
          duration={item.duration}
          kcal={parseInt(item.calories)}
          plus={null}
          minus={null}
          paymentLevel={item.payment_level}
          onPress={debounce(() => {
            item.payment_level === 1
              ? navigation.navigate('Paywall')
              : openTrailNew(item.subcategory?.category_id, item.id, item.subcategory?.name);
            dispatch(setNameShare(item.name));
          }, PERFORMANCE.ON_PRESS_DEBOUNCE_TIME)}
          containerStyle={styles.leftCard}
          category={item.subcategory?.category_id}
          gradientColor={getGradientColor(item.subcategory?.category_id)}
          goals={item?.goals ? item?.goals : []}
          id={item.id}
          favorite={item.favorite}
          isAgenda={isCalendar}
          onPressAdd={() => addToCalendar(item)}
        />
      </View>
    );
  };

  const getFilteredsCourses = () => {
    return categoryCourses
      .filter(courses => {
        return searchQuery.length > 0
          ? courses.post_title.toLowerCase().includes(searchQuery.toLowerCase())
          : true;
      })
      .slice(0, 5);
  };

  const renderTabView = () => {
    return (
      <View style={{ paddingBottom: safeAreaInsets.bottom + 52, marginTop: 20 }}>
        {!!currentCategoryFilters.length && !loadingClassesToShow && (
          <FilterResult filters={currentCategoryFilters} onRemove={handleRemoveFilter} />
        )}
        {!isCalendar && (
          <View>
            {profile?.plan_meta?.sipat_enabled &&
              (profile?.plan_meta?.sipat_visibility === 0 ||
                profile?.plan_meta?.sipat_visibility === 2) &&
              !currentCategoryFilters.length && (
                <TouchableOpacity
                  activeOpacity={0.7}
                  onPress={debounce(() => {
                    navigation.navigate('MasterclassDetail', {
                      coursepress_class_id: sipatId,
                      showDuration: true,
                      isSipat: true,
                      colorBase: getColorBase(options[currentCategory].id),
                    })
                  }, PERFORMANCE.ON_PRESS_DEBOUNCE_TIME)}>
                  <ShadowView style={styles.shadowContainer}>
                    <View style={styles.container}>
                      <View style={{ alignItems: 'flex-start', width: '60%', marginLeft: 23 }}>
                        <View style={styles.sipatWrapper}>
                          <Text style={styles.textProfessional}>{translate(pt.sipat_namu)}</Text>
                        </View>
                        <View>
                          <Text style={styles.textContent} numberOfLines={4}>
                            {translate(pt.sipat_widget_message)}
                          </Text>
                        </View>
                      </View>
                      <ButtonBase
                        onPress={() =>
                          navigation.navigate('MasterclassDetail', {
                            coursepress_class_id: sipatId,
                            showDuration: true,
                            isSipat: true,
                            colorBase: getColorBase(options[currentCategory].id),
                          })
                        }
                        style={styles.buttonBase}
                        textStyle={{ color: colors.white }}
                        title={translate(pt.access_course)}
                      />
                      <Image
                        source={require('app/images/sipat_widget.png')}
                        style={styles.sipatImage}
                      />
                    </View>
                  </ShadowView>
                </TouchableOpacity>
              )}
            {!programsLoad && isRenderProgram() ? (
              <View>
                {programs?.length > 0 && (
                  <HeaderTitleWithBackground
                    title={translate(pt.trails)}
                    linkText={translate(pt.see_all2)}
                    linkStyle={{
                      color: options[currentCategory].color,
                    }}
                    uppercaseLink
                    onPress={() => navigation.navigate('ShowMorePrograms', { category: currentCategory + 1 })}
                  />
                )}
                {programs?.length > 0 && (
                  <>
                    {renderCardPrograms(programs, {
                      urlToFetch: '/trails/filter',
                      color: colors.aquaMarineTwo,
                      title: translate(pt.fitness_suggestions),
                    })}
                  </>
                )}
              </View>
            ) : isRenderProgram() ? (
              <CourseLoader />
            ) : null}

            {loadingCourses ? (
              <CourseLoader />
            ) : (
              getFilteredsCourses()?.length > 0 && (
                <>
                  <HeaderTitleWithBackground
                    title={translate(pt.courses_title)}
                    style={{ paddingHorizontal: 19 }}
                    onPress={() => {
                      handleShowMoreCourses();
                    }}
                    linkStyle={{
                      color: options[currentCategory].color,
                    }}
                    uppercaseLink
                    linkText={translate(pt.see_all2)}
                  />
                  <FlatList
                    showsHorizontalScrollIndicator={false}
                    contentContainerStyle={styles.flatSpace}
                    data={getFilteredsCourses()}
                    horizontal
                    keyExtractor={item => item.id}
                    ListFooterComponent={
                      <CardClassesLast
                        color={options[currentCategory].color}
                        title={'Todos os Cursos'}
                        image={options[currentCategory].fallback}
                        style={{ marginHorizontal: 16, height: 220, borderRadius: 18, width: 280 }}
                        onPress={() => {
                          handleShowMoreCourses();
                        }}
                      />
                    }
                    renderItem={({ item }) => (
                      <CardCourses
                        title={item.post_title}
                        style={{ marginLeft: 20 }}
                        goal={item.goal}
                        id={item.id}
                        paymentLevel={item.access_granted}
                        onPress={() =>
                          item.access_granted === 1
                            ? navigation.navigate('Paywall')
                            : navigation.navigate('MasterclassDetail', {
                              coursepress_class_id: item.id,
                              colorBase: getColorBase(options[currentCategory].id),
                            })
                        }
                        color={getColorBase(options[currentCategory].id)}
                        image={{ uri: item.image_path }}
                      />
                    )}
                  />
                </>
              )
            )}
          </View>
        )}

        {loadingClassesToShow ? (
          <ClassLoader />
        ) : (
          <View>
            <HeaderTitleWithBackground
              title={translate(pt.classes)}
              linkStyle={{
                color: options[currentCategory].color,
              }}
            />
            {Object.entries(currentCategoryClasses)
              .sort((a, b) => (a[0] > b[0] ? 1 : -1))
              .filter(([subcategory]) => {
                let activeSubcategories = subcategoriesFilter.map(item => item.name);
                if (activeSubcategories.length === 0) {
                  return true;
                } else {
                  return activeSubcategories.includes(subcategory);
                }
              })
              .map(([subcategory, classes], index) => (
                <View key={subcategory + index}>
                  <HeaderTitleNew
                    title={subcategory}
                    style={{ paddingHorizontal: 19, marginTop: 0 }}
                    onPress={() => {
                      const urlToFetch = showMoreSectionUrl(subcategory);
                      handleShowMore(urlToFetch);
                    }}
                    linkStyle={{
                      textDecorationLine: 'underline',
                      color: options[currentCategory].color,
                    }}
                    uppercaseLink
                    linkText={translate(pt.see_all2)}
                  />
                  <FlatList
                    showsHorizontalScrollIndicator={false}
                    contentContainerStyle={styles.flatSpace}
                    data={classes}
                    horizontal
                    keyExtractor={item => item.id}
                    ListFooterComponent={
                      <CardClassesLast
                        color={options[currentCategory].color}
                        title={subcategory}
                        image={options[currentCategory].fallback}
                        style={{ marginHorizontal: 16 }}
                        onPress={() => {
                          const urlToFetch = showMoreSectionUrl(subcategory);
                          handleShowMore(urlToFetch);
                        }}
                      />
                    }
                    renderItem={({ item }) => renderItem(item)}
                  />
                </View>
              ))}
          </View>
        )}
      </View>
    );
  };

  return (
    <>
      <MainHeader
        Logo={() => (
          <>
            <Image src={NamuLogo} style={{ width: 28, height: 28, color: colors.aquaMarineTwo }} resizeMethod='contain' />
          </>
        )}
        noRightTray
        searchBar
        query={searchQuery}
        setQuery={setSearchQuery}
        handleSearch={handleSearch}
      />
      <View style={{ flex: 1, position: 'relative' }}>
        {isCalendar && (
          <View
            style={{
              position: 'absolute',
              top: safeAreaInsets.top + 10,
              marginLeft: 15,
              zIndex: 9999,
            }}>
            <TouchableOpacity
              onPress={navigation.goBack}
              style={{
                zIndex: 10,
                backgroundColor: '#00000033',
                width: 48,
                height: 22,
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 15,
              }}>
              <Image
                source={arrowRightWater2}
                style={{
                  tintColor: colors.white,
                  transform: [{ rotate: '180deg' }],
                  width: 22,
                  height: 19,
                  resizeMode: 'contain',
                }}
              />
            </TouchableOpacity>
          </View>
        )}
        <ScrollView>
          <View style={styles.header}>
            <View style={styles.tabContainer}>
              {!isNutritionMode ? (
                <AnimatedTab
                  tabs={options}
                  backgroundColor={colors.white}
                  textColor={colors.greyBlue}
                  activeTextColor={colors.white}
                  onChangeTab={handleChangeTab}
                  active={currentCategory}
                />
              ) : (
                <View style={styles.categoryBar}>
                  <Text style={styles.categoryName}>Alimentação</Text>
                </View>
              )}
            </View>
          </View>
          {renderTabView()}
        </ScrollView>
        <FAB
          style={[styles.fab, { backgroundColor: options[currentCategory].color }]}
          color={colors.white}
          icon={require('app/images/filter.png')}
          onPress={() => {
            navigation.navigate('Filter', {
              color: options[currentCategory].color,
              headerColors: getHeaderGradientColor(),
              categoryId: options[currentCategory].id
            })
            dispatch(setExplorerTabIndex(currentCategory));
          }}
        />
        <RBSheet ref={refRBSheet} height={Math.min(height, 650)}>
          <BottomSheetAddAgenda
            typeSchedule={options[currentCategory].id !== categoryIds.FOOD ? 'class' : 'dish'}
            id={idSelected}
            onClose={() => {
              StatusBar.setHidden(false);
              refRBSheet.current.close();
            }}
          />
        </RBSheet>
      </View>
    </>
  );
};

export default SearchScreenNew;
const styles = StyleSheet.create({
  header: {
    backgroundColor: colors.white,
    paddingHorizontal: 14,
    paddingBottom: 20,
    borderBottomLeftRadius: 15,
    borderBottomRightRadius: 15,
  },
  tabContainer: {
    marginTop: 27,
  },
  fab: {
    position: 'absolute',
    margin: 16,
    right: 0,
    bottom: Platform.OS == 'android' ? 52 : 85,
  },
  flatSpace: {
    paddingHorizontal: 0,
    paddingBottom: 20,
    paddingRight: 20,
  },
  leftCard: { marginLeft: 20 },
  shadowContainer: {
    borderRadius: 12,
    marginHorizontal: 20,
    backgroundColor: colors.white,
    shadowColor: colors.black,
    shadowOpacity: 0.1,
    shadowRadius: 5,
    shadowOffset: { width: 0, height: 5 },
  },
  container: {
    flex: 1,
    borderRadius: 10,
    paddingVertical: 17,
    backgroundColor: colors.white,
    overflow: 'hidden',
  },
  sipatImage: {
    position: 'absolute',
    zIndex: -1,
    right: 0,
    bottom: 0,
    height: 170,
    width: 170,
    resizeMode: 'contain',
  },
  buttonBase: {
    height: 40,
    marginRight: 17,
    borderRadius: 10,
    alignSelf: 'flex-end',
    shadowColor: colors.black,
    shadowOpacity: 0.1,
    shadowRadius: 5,
    shadowOffset: { width: 0, height: 5 },
  },
  sipatWrapper: {
    marginVertical: 6,
  },
  textProfessional: {
    fontFamily: fonts.BOLD,
    color: colors.darkPro,
    fontSize: 21,
    lineHeight: 26,
  },
  textContent: {
    fontFamily: fonts.REGULAR,
    fontSize: 13,
    color: colors.darkPro,
  },
  categoryBar: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 100,
    height: 41,
    borderWidth: 1,
    paddingRight: 6,
    borderColor: colors.redUltraLight,
    backgroundColor: colors.grapefruit,
  },
  categoryName: {
    fontSize: 12,
    fontFamily: fonts.BOLD,
    color: colors.white,
  },
});
