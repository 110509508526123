/* eslint-disable react-native/no-inline-styles */
import React from 'react';

import { Text, Dimensions, View, StyleSheet, Image, useWindowDimensions, ImageBackground } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import colors from '../styles/colors';
import ScaleImage from 'app/components/ScaleImage';
import api from 'app/services/api';
import fonts from 'app/styles/fonts';
import NamuLogo from 'app/images/namu_logo.svg';

// const { width } = Dimensions.get('window');

const CardInterest = ({ item, style, color, staff }) => {
  const {width} = useWindowDimensions();
  const cardWidth = () => (width - 68) / 2;
  var colorsGradient = [colors.greyBlue, colors.greyBlue];

  if (item.id == 6) {
    colorsGradient = [colors.tealish, colors.aquaMarine];
  } else if (item.id == 1) {
    colorsGradient = [colors.grapefruit, colors.grapefruitTwo];
  } else if (item.id == 2) {
    colorsGradient = [colors.apricot, colors.apricot];
  } else if (item.id == 3) {
    colorsGradient = [colors.aquaMarineTwo, colors.aquaMarineLight];
  }else if (item.id == 7) {
    colorsGradient = [colors.rosyPink, colors.pinkSherbet];
  }else if (item.id == 8) {
    colorsGradient = [colors.brown, colors.brownLight];
  }else if (item.id == 9) {
    colorsGradient = [colors.dustyOrange, colors.peachOrange];
  }else if (item.id == 10) {
    colorsGradient = [colors.violet, colors.violetTwo];
  }else if (item.id == 11) {
    colorsGradient = ['transparent', 'transparent'];
  }

  const BgRender = ({ children, ...props }) => {
    if(item.id == 11) {
      return <ImageBackground source={require('app/images/bg_lgbt.png')} {...props}>{children}</ImageBackground>
    }

    return <LinearGradient {...props}>{children}</LinearGradient>
  }

  return (
    <BgRender
      useAngle={true}
      angle={45}
      colors={colorsGradient}
      style={{
        opacity: 1,
        width: cardWidth(),
        marginRight: 16,
        marginTop: 16,
        height: cardWidth() - 5,
        borderRadius: 10,
        overflow: 'hidden',
        ...style,
      }}>
      {staff && (
        <Image source={NamuLogo}
        style={{
            width: cardWidth(),
            height: cardWidth(),
            position: 'absolute',
            right: -(cardWidth() / 2),
            opacity: 0.2,
            color: colors.white,
          }}
        />
      )}
      <View style={{ marginTop: 30, marginHorizontal: 22 }}>
        {item.isCategory ? (
          <Image
            source={{ uri: item.image_path }}
            animation="fadeIn"
            style={{
              width: 50,
              height: 50,
              resizeMode: 'contain',
            }}
          />
        ) : (
          <ScaleImage
            scale={1.45}
            source={{
              uri: item.image_path,
              headers: {
                Authorization: api.defaults.headers.Authorization,
              },
            }}
            resizeMode="contain"
            style={{
              position: 'absolute',
              right: 0,
              borderTopRightRadius: 12,
              top: item.isBottom ? null : 0,
              bottom: item.isBottom ? 0 : null,
              left: item.isLeft ? 0 : null,
            }}
          />
        )}
        <Text style={[styles.title]}>{item.name || item.coursepress_cat_name}</Text>
      </View>
    </BgRender>
  );
};

const styles = StyleSheet.create({
  title: {
    fontFamily: fonts.BOLD,
    fontSize: 18,
    color: colors.white,
    marginTop: 12,
  },
});

export default CardInterest;
