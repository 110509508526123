import React, { useState } from 'react';

import { View, TextInput, StyleSheet, Image, TouchableOpacity } from 'react-native';
import { translate } from '../i18n/src/locale';
import pt from '../i18n/src/locale/pt';
import colors from '../styles/colors';
import eyeOpen from '../images/eyeOpen.png';
import eyeClose from '../images/eyeClose.png';
import passLock from '../images/passLock.png';
import fonts from 'app/styles/fonts';

const SecureTextInput = ({ value, placeholder, onChangeText, refInside }) => {
  const [visibility, setVisibility] = useState(true);
  return (
    <View style={styles.container}>
      <Image source={passLock} style={styles.inputIcon} />
      <TextInput
        ref={refInside}
        value={value}
        onChangeText={onChangeText}
        secureTextEntry={visibility}
        autoCapitalize="none"
        placeholder={placeholder || translate(pt.password)}
        style={styles.input}
      />
      <TouchableOpacity
        activeOpacity={0.8}
        style={styles.visibilityBtn}
        onPress={() => {
          setVisibility(!visibility);
        }}>
        <Image style={{ width: 18, height: 12 }} source={visibility ? eyeClose : eyeOpen} />
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: 6,
    paddingLeft: 14,
    paddingRight: 40,
    height: 45,
    borderRadius: 5,
    backgroundColor: colors.white,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: colors.lightPeriwinkle,
    flexDirection: 'row',
    alignItems: 'center',
  },
  inputIcon: {
    width: 22,
    height: 20,
    resizeMode: 'contain',
  },
  input: {
    marginLeft: 10,
    flex: 1,
    color: colors.black,
    fontFamily: fonts.MEDIUM,
  },
  visibilityBtn: {
    marginTop: 6,
    position: 'absolute',
    right: 5,
    alignItems: 'center',
    justifyContent: 'center',
    height: 45,
    width: 35,
    padding: 5,
  },
});

export default SecureTextInput;
