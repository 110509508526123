import React, { useState } from 'react';
import { View, StyleSheet, Text, Image, TouchableOpacity, ActivityIndicator } from 'react-native';

import Stars from 'app/components/Stars';
import Icon from 'react-native-vector-icons/dist/FontAwesome';

import colors from 'app/styles/colors';
import textStyles from 'app/styles/textStyles';

import noPhotoImage from '../../../../images/no-photo.jpg';
import parceiro from '../../../../images/icon_parceiro_borderless.png';
import profissionalNamu from '../../../../images/icon_business.png';

import { useSelector, useDispatch } from 'react-redux';
import apiNode from 'app/services/apiNode';
import { useEffect } from 'react';
import { doReloadFavorites } from 'app/store/modules/user/actions';
import CapsuleHour from 'app/components/CapsuleHour';
import { translate } from 'app/i18n/src/locale';
import pt from 'app/i18n/src/locale/pt';
import moment from 'moment';
import flagPt from '../../../../images/bandeira.png';
import flagEn from '../../../../images/usFlag.png';
import flagSp from '../../../../images/esFlag.png';
import { normalize, toCurrencyFormat } from 'app/services/utils';
import LinearGradient from 'react-native-linear-gradient';
import IconCorp from 'app/screens/core/professionalFilterResult/components/IconCorp';
import clock from 'app/images/clock.png';
import calendar from 'app/images/iconCalendar.png';
import commons from 'app/styles/commons';
import { useNavigation } from '@react-navigation/native';

const ProfessionalData = ({
  userProId,
  userProItem,
  registryName,
  durationMinutes,
  showAddionalData = false,
  hideLinearStaff,
  linear = true,
  hideProfessionalNumber,
  hideFavoriteButton,
  userProRatings = true,
  schedule,
  staff,
  textColor,
  list,
}) => {
  const dispatch = useDispatch();

  const navigation = useNavigation();

  const [favorite, setFavorite] = useState(false);
  const [favoriteId, setFavoriteId] = useState();
  const [ratings, setRatings] = useState(null);
  const [discountPartner, setDiscountPartner] = useState(0);
  const { profile, reloadFavorites } = useSelector(state => state.user);
  const [favLoading, setFavLoading] = useState(false);
  const [userPro, setUserPro] = useState(null);

  const getDiscounts = async id => {
    try {
      const { data } = await apiNode.get(`/partner-discount/by-user-pro/${id}`);

      setDiscountPartner(data.discount);
    } catch (error) {
      console.log('getDiscounts error', userProId);
    }
  };

  const percentCustomerCalc = () => {
    const percent = discountPartner * 0.01;

    const value = Number(userPro.sessionHourPrice) * percent;

    return value;
  };

  const userInfo = async () => {
    setFavLoading(true);
    try {
      let data;
      if (userProItem) {
        data = userProItem;
      } else {
        data = (
          await apiNode.get(`users-pro/${userProId}?userId=${profile.user_id}&${reloadFavorites}`)
        ).data;
      }

      setUserPro(data);
      setFavorite(data?.userFavorite?.favorited ? true : false);
      setFavoriteId(data?.userFavorite?.favorited ? data?.userFavorite?.id : null);
      getDiscounts(data?.id);
      if (userProRatings === true) {
        setRatings(data.ratingStars);
      } else if (userProRatings !== null && typeof userProRatings === 'number') {
        setRatings(userProRatings);
      } else {
        setRatings(null);
      }
    } catch (error) {
      console.log('UserInfo error: ', error);
    }
    setFavLoading(false);
  };

  useEffect(() => {
    if ((!!userProId || !!userProItem) && !!profile) {
      userInfo();
    }
  }, [userProId, userProItem, profile, reloadFavorites, userProRatings]);

  const toggleHeart = async () => {
    setFavLoading(true);
    try {
      if (!favorite) {
        const sendObject = {
          userId: profile.user_id,
          userProId: userProId,
        };

        const { data } = await apiNode.post('/user-favorites-user-pro', sendObject);
        setFavorite(true);
        setFavoriteId(data.id);
      } else {
        try {
          await apiNode.delete(`/user-favorites-user-pro/${favoriteId}`);
          setFavorite(false);
          setFavoriteId(null);
        } catch (error) {
          console.log('DELETE FAV: ', error.response);
        }
      }

      dispatch(doReloadFavorites());
    } catch (error) {
      console.log(error);
    }
    //setFavLoading(false);
  };

  function getFlagIcon(language) {
    if (language === 'Português') {
      return flagPt;
    } else if (language === 'Inglês') {
      return flagEn;
    } else if (language === 'Espanhol') {
      return flagSp;
    }
  }

  function renderDataDesc() {
    return (
      <View
        style={[
          {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          },
          !linear ? { paddingVertical: 10 } : { marginTop: -5 },
        ]}>
        <View style={{ justifyContent: 'center' }}>
          <View
            style={{
              flexDirection: 'row',
              flexWrap: 'wrap',
              alignItems: 'center',
            }}>
            <Text
              style={[
                textStyles.regular12grey,
                styles.category,
                { color: linear ? colors.greyBlue : colors.ligthGray },
                textColor && { color: textColor },
              ]}>
              {userPro.categories[0].professionalCategory.name}
              {!hideProfessionalNumber && (
                <Text style={[textStyles.bold12, styles.blueText, { marginLeft: 3 }]}>
                  {` | ${
                    registryName
                      ? registryName
                      : userPro.categories[0].professionalCategory.registryName
                  } ${userPro.professionalNumber}`}
                </Text>
              )}
              {staff && <Text>{' - Orientação Namu'}</Text>}
            </Text>
          </View>

          {!schedule && userPro?.city && (
            <View>
              <Text
                style={[
                  textStyles.regular12grey,
                  { color: linear ? colors.greyBlue : colors.ligthGray },
                ]}>
                {`${userPro.city ?? ''}`}
                {`${userPro.state ? '/' + userPro.state : ''}`}
              </Text>
            </View>
          )}

          {schedule && (
            <View style={styles.scheduleBox}>
              <Text style={[textStyles.regular12grey, { color: colors.white }]}>
                <Image
                  source={calendar}
                  style={{ width: 12, height: 12, resizeMode: 'contain', tintColor: colors.white }}
                />{' '}
                {moment(schedule.sessionDate).isSame(moment(), 'day')
                  ? translate(pt.today)
                  : moment(schedule.sessionDate).format('DD/MM/YYYY')}
              </Text>
              <Text style={[textStyles.regular12grey, { marginLeft: 10, color: colors.white }]}>
                <Image
                  source={clock}
                  style={{
                    marginRight: 5,
                    width: 12,
                    height: 12,
                    resizeMode: 'contain',
                    tintColor: colors.white,
                  }}
                />
                {moment(schedule.sessionDate).format('HH:mm')} -{' '}
                {moment(schedule.sessionDate)
                  .add(schedule.sessionDurationMinutes, 'minutes')
                  .format('HH:mm')}
              </Text>
            </View>
          )}
        </View>
        {!linear && (
          <CapsuleHour
            timeIn={'min'}
            duration={userPro.sessionDurationMinutes}
            style={styles.capsule}
          />
        )}
      </View>
    );
  }

  return (
    <>
      {userPro ? (
        <>
          <View style={[styles.professionalContainer, schedule && { borderBottomWidth: 0 }]}>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <TouchableOpacity
                style={styles.imageContainer}
                onPress={() => {
                  navigation.navigate('ProfessionalProfile', { userProId: userProId || userProItem.id });
                }}>
                {list && userPro.isPartner && profile.b2b_user && (
                  <Image style={styles.imgCorp} source={parceiro} />
                )}
                {list && userPro.namuStaff && (
                  <Image style={styles.imgCorp} source={profissionalNamu} />
                )}
                <Image
                  source={userPro.profileImageUrl ? { uri: userPro.profileImageUrl } : noPhotoImage}
                  style={styles.photoStyle}
                />
              </TouchableOpacity>
              <View style={styles.infosContainer}>
                <View style={styles.header}>
                  {staff && !hideLinearStaff && (
                    <LinearGradient
                      style={{
                        borderRadius: 50,
                        paddingVertical: 4,
                        paddingHorizontal: 7,
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                      colors={['#3ED1D2', '#7CF3EC']}
                      useAngle
                      angle={90}>
                      <Image
                        source={require('app/images/logoBig.png')}
                        style={{ tintColor: colors.white, height: 12, width: 12 }}
                      />
                      <Text style={[textStyles.bold12White, { fontSize: 10, marginLeft: 3 }]}>
                        {translate(pt.namu_professional)}
                      </Text>
                    </LinearGradient>
                  )}
                  <View style={styles.nameContainer}>
                    {!list && !!userPro.isPartner && !!profile.b2b_user && !staff && (
                      <IconCorp
                        style={{
                          width: normalize(113),
                          paddingHorizontal: 8,
                          marginBottom: 6,
                        }}
                        userType="partner"
                      />
                    )}
                    <Text style={[textStyles.bold16, textColor && { color: textColor }]}>
                      {userPro.name}
                    </Text>
                  </View>
                  {!hideFavoriteButton && (
                    <View style={commons.flexCenter}>
                      {!favLoading ? (
                        <TouchableOpacity onPress={toggleHeart} disabled={favLoading}>
                          <Icon
                            style={{ height: 21.57, width: 23.53 }}
                            name={favorite ? 'heart' : 'heart-o'}
                            size={22}
                            color={favorite ? colors.frenchRose : colors.darkPro}
                          />
                        </TouchableOpacity>
                      ) : (
                        <ActivityIndicator
                          size={'small'}
                          animating
                          color={schedule ? colors.white : colors.aquaMarineTwo}
                        />
                      )}
                    </View>
                  )}
                </View>
                <View style={[styles.starsContainer, { marginTop: linear ? 3 : 5 }]}>
                  {ratings !== null && (
                    <>
                      <Stars
                        disabled={true}
                        default={ratings}
                        count={5}
                        fullStar={
                          <Icon name={'star'} size={20} color={'#FFA000'} style={styles.stars} />
                        }
                        emptyStar={
                          <Icon name={'star-o'} size={20} color={'#FFA000'} style={styles.stars} />
                        }
                      />
                      <Text style={[textStyles.regular11, styles.minorText]}>
                        {userProRatings === true ? `(${userPro.ratingCount})` : null}
                      </Text>
                    </>
                  )}
                </View>
                {linear && renderDataDesc()}
              </View>
            </View>
            {!linear && renderDataDesc()}
          </View>
          {showAddionalData && (
            <View style={styles.showAddionalData}>
              <View style={styles.languageContainer}>
                <Text style={textStyles.bold14}>{translate(pt.languages)}</Text>
                <View style={styles.flags}>
                  {userPro.languages.map(lang => (
                    <Image
                      key={lang}
                      source={getFlagIcon(lang.language.name)}
                      style={{ height: 20, width: 20, borderRadius: 50, marginRight: 5 }}
                    />
                  ))}
                </View>
                <View style={styles.timerContainer}>
                  <CapsuleHour
                    timeIn={'min'}
                    duration={userPro.sessionDurationMinutes}
                    style={styles.capsule}
                  />
                </View>
              </View>
              <View style={styles.valuesContainer}>
                <Text
                  style={
                    userPro?.isPartner && !!profile.b2b_user
                      ? textStyles.regular14grey
                      : textStyles.bold14
                  }>
                  {translate(pt.service_value)}
                </Text>
                <Text
                  style={[
                    textStyles.bold16grey,
                    userPro?.isPartner &&
                      !!profile.b2b_user && {
                        textDecorationStyle: 'solid',
                        textDecorationLine: 'line-through',
                      },
                  ]}>
                  {toCurrencyFormat(userPro.sessionHourPrice, true)}
                </Text>
              </View>
              {userPro?.isPartner && profile.b2b_user && (
                <View style={[styles.valuesContainer, { marginTop: normalize(-10) }]}>
                  <Text style={[textStyles.bold14, { color: colors.blueD8 }]}>
                    {translate(pt.valueForNamuBusiness)}
                  </Text>
                  {discountPartner ? (
                    <Text style={[textStyles.bold16grey, { color: colors.blueD8 }]}>
                      {translate(pt.prefix)}{' '}
                      {toCurrencyFormat(userPro.sessionHourPrice - percentCustomerCalc())}
                    </Text>
                  ) : (
                    <ActivityIndicator
                      size="small"
                      color={schedule ? colors.white : colors.aquaMarineTwo}
                    />
                  )}
                </View>
              )}
            </View>
          )}
        </>
      ) : (
        <ActivityIndicator
          animating
          size={'small'}
          color={schedule ? colors.white : colors.aquaMarineTwo}
          style={{ marginTop: 15, alignSelf: 'center' }}
        />
      )}
    </>
  );
};

export default ProfessionalData;

const styles = StyleSheet.create({
  professionalContainer: {
    marginTop: 10,
    borderBottomWidth: 0.8,
    borderBottomColor: 'rgba(0,0,0,0.1)',
    // paddingBottom: 12,
    //borderWidth: 2,
  },
  imgCorp: {
    position: 'absolute',
    top: normalize(0),
    left: normalize(0),
    zIndex: 10,
    resizeMode: 'contain',
    height: normalize(25),
    width: normalize(25),
  },
  infosContainer: {
    //borderWidth: 1,
    //width: '100%',
    marginHorizontal: 8,
    flex: 1,
  },
  imageContainer: {},
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  nameContainer: {
    width: '85%',
  },
  starsContainer: {
    maxWidth: 115,
    marginVertical: 3,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  blueText: {
    color: colors.aquaMarineTwo,
  },
  description: {
    color: colors.greyBlue,
    maxWidth: 180,
    minHeight: 5,
    backgroundColor: colors.red,
  },
  category: {
    color: colors.greyBlue,
    maxWidth: 180,
  },
  onlineIndicator: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    paddingHorizontal: 2.5,
    paddingVertical: 2.5,
  },
  photoStyle: {
    width: normalize(65),
    height: normalize(65),
    resizeMode: 'cover',
    borderRadius: 100,
  },
  scheduleBox: {
    flexDirection: 'row',
    backgroundColor: '#41A8A9',
    borderRadius: 20,
    paddingVertical: 3,
    paddingHorizontal: 10,
    marginTop: 5,
  },

  showAddionalData: {
    marginTop: 10,
    //flexDirection: 'row',
    //justifyContent: 'center',
    alignItems: 'center',
    borderBottomWidth: 0.8,
    borderBottomColor: 'rgba(0,0,0,0.1)',
    paddingBottom: 0,
  },

  languageContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    //borderWidth: 1,
    width: '100%',
  },
  flags: {
    flex: 1,
    flexDirection: 'row',
    //justifyContent: 'space-around',
    marginLeft: 10,
  },
  capsule: {
    backgroundColor: '#f9f9f9',
  },
  valuesContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 8,
    //borderWidth: 1,
    //borderBottomColor: 'rgba(0,0,0,0.1)',
    paddingBottom: 16,
    paddingRight: 5,
    width: '100%',
  },
  valuesHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 14,
    //borderWidth: 1,
  },
});
