import { combineReducers } from 'redux';
import auth from './auth/reducer';
import user from './user/reducer';
import alert from './alert/reducer';
import water from './water/reducer';
import meal from './meal/reducer';
import category from './category/reducer';
import playlist from './playlist/reducer';
import trail from './trail/reducer';
import chat from './chat/reducer';
import call from './call/reducer';
import classes from './classes/reducer';
import favorites from './favorites/reducer';
import gamification from './gamification/reducer';
import programs from './programs/reducer';

export default combineReducers({
  auth,
  alert,
  user,
  water,
  meal,
  category,
  playlist,
  trail,
  chat,
  call,
  classes,
  favorites,
  gamification,
  programs
});
