import api from 'app/services/api';
import { all, call, put, select, takeLatest, takeEvery } from 'redux-saga/effects';
import { errorControl, showAlert } from 'app/services/utils';
import { setPrograms } from './action';
export const getPrograms = async (category, filters, queryParams) => {
  const { data } = await api(`trails/filter?${queryParams}&categories=${category}&like=${filters}`);
  return data;
};
export function* requestProgramsBySagas({ payload }) {
  try {
    const { category, filters, queryParams } = payload;
    const programs = yield call(getPrograms, category, filters, queryParams);
    yield put(setPrograms(programs));
  } catch (error) {
    console.log(error);
    showAlert(errorControl(error));
  }
}
export default all([takeEvery('@programs/FETCH_PROGRAMS', requestProgramsBySagas)]);