import produce from 'immer';

const INITIAL_STATE = {
  bodyClasses: {},
  mindClasses: {},
  foodClasses: {},
  bodyFilters: {},
  mindFilters: {},
  foodFilters: {},
  classesToShow: [],
  filterQueryParams: '',
  explorerTabIndex: 1,
  loadingClassesToShow: false,
};

export default function classes(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@classes/SET_BODY_CLASSES': {
        draft.bodyClasses = action.payload.classes;
        break;
      }
      case '@classes/SET_MIND_CLASSES': {
        draft.mindClasses = action.payload.classes;
        break;
      }
      case '@classes/SET_FOOD_CLASSES': {
        draft.foodClasses = action.payload.classes;
        break;
      }
      case '@classes/SET_CLASSES_TO_SHOW': {
        draft.classesToShow = action.payload.classes;
        break;
      }
      case '@classes/SET_BODY_FILTERS': {
        draft.bodyFilters = action.payload.filters;
        break;
      }
      case '@classes/SET_MIND_FILTERS': {
        draft.mindFilters = action.payload.filters;
        break;
      }
      case '@classes/SET_FOOD_FILTERS': {
        draft.foodFilters = action.payload.filters;
        break;
      }
      case '@classes/SET_FILTER_QUERY_PARAMS': {
        draft.queryParams = action.payload.queryParams;
        break;
      }
      case '@classes/FETCH_CLASSES': {
        break;
      }
      case '@classes/FETCH_CLASSES_BY_URL': {
        break;
      }
      case '@classes/FETCH_CLASSES_TO_SHOW_BY_URL': {
        break;
      }
      case '@classes/SET_EXPLORER_TAB_INDEX': {
        draft.explorerTabIndex = action.payload.tabIndex;
        break;
      }
      case '@classes/SET_LOADING_CLASSES_TO_SHOW': {
        draft.loadingClassesToShow = action.payload.loading;
        break;
      }
    }
  });
}
