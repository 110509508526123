import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import fonts from 'app/styles/fonts';
import colors from 'app/styles/colors';
import { translate } from 'app/i18n/src/locale';
import pt from 'app/i18n/src/locale/pt';

const CardSummary = ({ courseName, courseValue, discountValue, discountCode }) => {
  return (
    <View style={styles.containerSummary}>
      <Text style={styles.title}>{translate(pt.purchase_summary)}</Text>
      <View style={[styles.row, { marginBottom: 6 }]}>
        <Text style={styles.summaryText}>{courseName}</Text>
        <Text style={[styles.summaryInfo, { opacity: 0.6 }]}>R$ {courseValue}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.summaryText}>{discountCode}</Text>
        <Text style={[styles.summaryInfo, { opacity: 0.6 }]}>- R$ {discountValue || '0'}</Text>
      </View>
      <View style={styles.divider} />
      <View style={styles.row}>
        <Text style={[styles.summaryText, { fontWeight: 'bold' }]}>{translate(pt.total)}</Text>
        <Text style={styles.summaryInfo}>
          R$ {(discountValue ? courseValue - discountValue : courseValue).toFixed(2)}
        </Text>
      </View>
    </View>
  );
};

export default CardSummary;

const styles = StyleSheet.create({
  containerSummary: {
    backgroundColor: colors.white,
    paddingHorizontal: 18,
    paddingTop: 26,
    paddingBottom: 40,
    width: 297,
    borderRadius: 4,
  },
  title: {
    color: colors.blackThree,
    fontFamily: fonts.BOLD,
    fontSize: 21,
    marginBottom: 24,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  summaryText: {
    fontSize: 14,
    fontFamily: fonts.REGULAR,
    lineHeight: 19,
    color: colors.blackThree,
  },
  summaryInfo: {
    fontSize: 14,
    fontFamily: fonts.BOLD,
    color: colors.greyBlue,
  },
  divider: {
    marginVertical: 22,
    height: 1,
    width: '100%',
    backgroundColor: colors.lightPeriwinkle,
  },
});
