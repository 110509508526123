import iconList from 'app/assets/iconlist';
import MoodOptionsContextMenu from 'app/screens/core/mood/components/MoodOptionsContextMenu';
import colors from 'app/styles/colors';
import fonts from 'app/styles/fonts';
import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

const RowMood = ({ mood, onDelete }) => {
  return (
    <View style={styles.container}>
      <View style={styles.detailContainer}>
        <Text style={styles.icon}>{iconList[mood.icon]}</Text>
        <View style={{ marginLeft: 16 }}>
          <View style={styles.row}>
            <Text style={styles.title}>{mood.name}</Text>
            <Text style={[styles.description, { marginLeft: 5 }]}>{mood.time}</Text>
          </View>
          <Text style={styles.description}>
            Motivo: {iconList[mood.reason_icon] + ' ' + mood.reason_name}
          </Text>
        </View>
      </View>
      <MoodOptionsContextMenu item={mood} onDelete={onDelete} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginVertical: 3,
  },
  detailContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    fontSize: 26,
  },
  title: {
    fontFamily: fonts.BOLD,
    fontSize: 20,
    color: colors.darkPro2,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  description: {
    fontFamily: fonts.REGULAR,
    fontSize: 12,
    color: colors.grey75,
  },
});

export default RowMood;
