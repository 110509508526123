import produce from 'immer';
import moment from 'moment';

const INITIAL_STATE = {
  profile: null,
  token: null,
  home: {},
  favorites: [],
  day: '',
  calendar_id: null,
  modalNotInternetVisible: false,
  reloadFavorites: moment(),
  tabProfessional: 'orientation',
  firstProfessionalEntering: true,
  reloadSessions: moment(),
  review: {},
  widgets: [],
  ios_synced: false,
};

export default function user(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@auth/SIGN_IN_SUCCESS': {
        draft.loading = false;
        draft.profile = action.payload.user;
        break;
      }
      case '@user/UPDATE_PROFILE_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@user/SET_PROFILE': {
        draft.profile = action.payload.user;
        draft.profile.user_id = action.payload.user.id;
        break;
      }
      case '@user/HIDE_ERROR': {
        draft.error = null;
        draft.loading = false;
        break;
      }
      case '@user/UPDATE_PROFILE_FAILURE': {
        draft.error = action.payload.error;
        draft.loading = false;
        break;
      }
      case '@user/UPDATE_REVIEW_STATUS': {
        draft.review = {
          entering_days: action.payload.entering_days,
          shown: !!action.payload.shown,
        };
        break;
      }
      case '@user/SET_PROFILE': {
        draft.profile = action.payload.profile;
        break;
      }
      case '@user/UPDATE_PROFILE_SUCCESS': {
        draft.loading = false;
        draft.profile = { ...draft.profile, ...action.payload.profile };
        break;
      }
      case '@auth/SIGN_OUT': {
        draft.profile = null;
        draft.token = null;
        break;
      }
      case '@user/SET_MY_HOME': {
        draft.home = action.payload.home;
        break;
      }
      case '@user/HANDLE_FAVORITES': {
        draft.favorites = action.payload.favorites;
        break;
      }
      case '@user/SET_DAY_HOME': {
        draft.day = action.payload.day;
        break;
      }
      case '@user/SET_IOS_SYNCED': {
        draft.ios_synced = action.payload.synced;
        break;
      }
      case '@user/SET_CALENDAR': {
        draft.calendar_id = action.payload.calendar_id;
        break;
      }
      case '@user/SET_MODAL_NOT_INTERNET_VISIBLE': {
        draft.modalNotInternetVisible = action.payload.modalNotInternetVisible;
        break;
      }
      case '@user/DO_RELOAD_FAVORITES': {
        draft.reloadFavorites = moment().add(10000, 'milliseconds');
        break;
      }
      case '@user/SET_TAB_PROFESSIONAL': {
        draft.tabProfessional = action.payload.value;
        break;
      }
      case '@user/SET_FIRST_PROFESSIONAL_ENTERING': {
        draft.firstProfessionalEntering = action.payload.value;
        break;
      }
      case '@user/DO_RELOAD_SESSIONS': {
        draft.reloadSessions = moment().add(10000, 'milliseconds');
        break;
      }
      case '@user/SET_WIDGETS': {
        draft.widgets = action.payload.widgets;
        break;
      }
      default:
        return state;
    }
  });
}
