import React, { useState } from 'react';

import { View, TextInput, StyleSheet, Text } from 'react-native';
import colors from '../styles/colors';
import { translate } from 'app/i18n/src/locale';
import pt from 'app/i18n/src/locale/pt';
import fonts from 'app/styles/fonts';

const TextArea = props => {
  const [value, setValue] = useState(props.value ?? '');
  const remainingChars = () => {
    const maxChars = props.maxLength ?? 1000;
    return maxChars - value.length;
  };

  return (
    <View style={styles.answersBox}>
      <TextInput
        style={styles.textInput}
        name={'title'}
        placeholder={props.placeholder ?? 'Placeholder'}
        value={props.value ? props.value : value}
        onChangeText={text => {
          if (props.setValue) {
            props.setValue(text);
          }
          setValue(text);
        }}
        multiline
        textAlignVertical={'top'}
      />
      <Text style={styles.textCount}>{`${remainingChars()} ${translate(pt.remaining_chars)}`}</Text>
    </View>
  );
};

export default TextArea;

const styles = StyleSheet.create({
  answersBox: {
    backgroundColor: colors.white,
    borderRadius: 4,
    paddingHorizontal: 15,
    paddingVertical: 10,
  },
  textInput: {
    height: 154,
    fontSize: 14,
    color: colors.greyLight2,
    fontFamily: fonts.REGULAR,
    fontWeight: '400',
  },
  textCount: {
    alignSelf: 'flex-end',
    fontSize: 9,
    color: colors.greyLight,
    fontFamily: fonts.REGULAR,
    fontWeight: '400',
  },
});
