import LoginButtonBase from 'app/components/LoginButtonBase';
import React, { useState } from 'react';
import {
  StyleSheet,
  Text,
  TextInput,
  Image,
  View,
  TouchableOpacity,
  ScrollView,
} from 'react-native';
import * as yup from 'yup';
import { translate } from '../../../i18n/src/locale';
import pt from '../../../i18n/src/locale/pt';
import colors from '../../../styles/colors';
import textStyles from '../../../styles/textStyles';
import { errorMessage } from '../../../services/utils';
import fonts from 'app/styles/fonts';
import mailOutlined from 'app/images/mailOutlined.png';
import iconPhone from 'app/images/iconPhone.png';
import document from 'app/images/document.png';
import { TextInputMask } from 'react-native-masked-text';
import SecureTextInput from 'app/components/SecureTextInput';
import commons from 'app/styles/commons';
import { useNavigation } from '@react-navigation/native';

const BusinessRegisterView = ({ personal }) => {
  const [cpf, setCpf] = useState('');
  const [email, setEmail] = useState('');
  const [cellphone, setCellphone] = useState('');
  const [password, setPassword] = useState('');

  const [alreadyExists, setAlreadyExists] = useState(false);

  const navigation = useNavigation();

  const handleSubmit = () => {
    const schema = yup.object().shape({
      email: yup
        .string()
        .email(translate(pt.fill_email))
        .max(50, translate(pt.email_to_long))
        .required(translate(pt.fill_email)),
      cpf: yup.string().required(translate(pt.fill_cpf)),
      cellphone: yup.string().required(translate(pt.fill_celular)),
      password: yup.string().required(translate(pt.fill_pass)),
    });

    schema
      .validate({ email, cpf, cellphone, password })
      .then(() => {
        navigation.navigate('BusinessVerification', {
          data: {
            cpf: cpf.replace(/\D+/g, ''),
            email,
            phone: cellphone.replace(/\D+/g, ''),
            password
          },
          onExists: () => {
            navigation.goBack();
            setAlreadyExists(true);
          }
        })
      })
      .catch(error => {
        errorMessage(error.message);
      });
  };

  return (
    <ScrollView style={styles.card}>
      <Text style={styles.title}>{'Boas-vindas!'}</Text>
      <Text style={styles.subTitle}>{translate(pt.create_namu_account)}</Text>
      <Text style={styles.description}>
        {personal
          ? translate(pt.personal_enterprise_register_message)
          : translate(pt.enterprise_register_message)}
      </Text>
      <View style={styles.inputContainer}>
        <Image source={document} style={styles.inputIcon} />
        <TextInputMask
          type="cpf"
          autoCapitalize="none"
          onChangeText={setCpf}
          value={cpf}
          keyboardType="number-pad"
          placeholder={'CPF'}
          style={[styles.input]}
        />
      </View>
      <View style={styles.inputContainer}>
        <Image source={mailOutlined} style={styles.inputIcon} />
        <TextInput
          autoCapitalize="none"
          onChangeText={setEmail}
          value={email}
          keyboardType="email-address"
          placeholder={translate(pt.email)}
          style={[styles.input]}
        />
      </View>
      <View style={styles.inputContainer}>
        <Image source={iconPhone} style={styles.inputIcon} />
        <TextInputMask
          type={'cel-phone'}
          autoCapitalize="none"
          onChangeText={setCellphone}
          value={cellphone}
          keyboardType="number-pad"
          placeholder={translate(pt.cellphone)}
          style={[styles.input]}
        />
      </View>

      <SecureTextInput value={password} onChangeText={setPassword} style={[styles.input]} />

      <LoginButtonBase
        title={translate(pt.continue)}
        onPress={handleSubmit}
        style={styles.buttonEnter}
      />

      {alreadyExists && (
        <Text style={styles.alreadyExists}>
          {translate(pt.already_exists_enterprise_account)}
          <Text
            style={{ color: colors.aquaMarineTwo, fontFamily: fonts.MEDIUM }}
            onPress={() => navigation.navigate('Login', { method: 'login' })}>
            {' clique aqui e entre agora.'}
          </Text>
        </Text>
      )}

      <View style={styles.view_account}>
        <Text style={[textStyles.semi16, { color: '#444242' }]}>
          {translate(pt.already_account)}
        </Text>

        <TouchableOpacity
          hitSlop={commons.hitSlop}
          onPress={() => {
            navigation.navigate('Login', {method: 'login'});
          }}>
          <Text style={styles.button_now}>{translate(pt.login_now)}</Text>
        </TouchableOpacity>
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  buttonEnter: {
    zIndex: 1,
    width: '100%',
    justifyContent: 'center',
    height: 50,
    marginTop: 26,
  },
  alreadyExists: {
    marginTop: 20,
    fontFamily: fonts.REGULAR,
    color: colors.red,
    textAlign: 'center',
  },
  inputContainer: {
    marginTop: 6,
    marginBottom: 12,
    paddingHorizontal: 14,
    height: 45,
    borderRadius: 5,
    backgroundColor: colors.white,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: colors.lightPeriwinkle,
    flexDirection: 'row',
    alignItems: 'center',
  },
  inputIcon: {
    width: 22,
    height: 22,
    resizeMode: 'contain',
  },
  input: {
    marginLeft: 10,
    flex: 1,
    color: colors.black,
    fontFamily: fonts.MEDIUM,
  },
  title: {
    fontFamily: fonts.BOLD,
    fontSize: 25,
    color: '#444242',
    textAlign: 'center',

    marginBottom: 15,
  },
  subTitle: {
    fontFamily: fonts.BOLD,
    fontSize: 18,
    color: '#5B607B',
    textAlign: 'center',
    marginBottom: 5,
  },
  description: {
    fontFamily: fonts.REGULAR,
    fontSize: 14,
    color: colors.grayText,
    textAlign: 'center',
    marginBottom: 25,
  },
  card: {
    marginHorizontal: 16,
    width: '100%',
    paddingVertical: 24,
    paddingHorizontal: 37,
  },
  view_account: {
    width: '100%',
    marginTop: 30,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  button_now: {
    fontFamily: fonts.BOLD,
    fontSize: 16,
    color: colors.aquaMarineTwo,
    zIndex: 2,
    marginLeft: 5,
  },
});

export default BusinessRegisterView;
