import React, { useState, useEffect } from 'react';
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  Dimensions,
  StyleSheet,
  ActivityIndicator,
} from 'react-native';
import ButtonBase from '../../../components/ButtonBase';
import colors from '../../../styles/colors';
import closeImage from '../../../images/closeGrey.png';
import commons from '../../../styles/commons';
import { translate } from 'app/i18n/src/locale';

import pt from 'app/i18n/src/locale/pt';
import fonts from 'app/styles/fonts';

import arrowLeft from '../../../images/chevronLeft.png';
import arrowRight from '../../../images/chevronRight.png';
import moment from 'moment';

import apiNode from 'app/services/apiNode';
import { useNavigation } from '@react-navigation/native';

const { height } = Dimensions.get('window');

const BottomSheetScheduling = ({
  onClose,
  hourSetter,
  hourSelected,
  userProId,
  selectedCalendarId = null,
  setFreeHoursItensQtd,
}) => {
  const [hours, setHours] = useState([]);
  const [current, setCurrent] = useState(null);
  const [calendarId, setCalendarId] = useState(selectedCalendarId);
  const [loading, setLoading] = useState(false);
  const [, setCalendar] = useState(false);
  const navigation = useNavigation();

  const getFreeDays = async () => {
    setLoading(true);
    try {
      const formatedDate = moment(current).format('YYYY-MM-DD');
      const { data } = await apiNode.get(
        `/calendar/get-free-by-date/${formatedDate}?id=${userProId}`
      );

      setHours([]);
      setFreeHoursItensQtd(prev => 0);
      data.map((free, index) => {
        if (moment(free.startDateTime) > moment()) {
          setFreeHoursItensQtd(prev => prev + 1);
          const startHour = moment(free.startDateTime).format('HH:mm');
          const hourTmp = startHour.replace(':', 'h');
          if (index === 0) {
            setHours([{ hour: hourTmp, id: free.id, startDateTime: moment(free.startDateTime) }]);
          } else {
            setHours(prev => [
              ...prev,
              { hour: hourTmp, id: free.id, startDateTime: moment(free.startDateTime) },
            ]);
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const [initial, setInitial] = useState(true);

  useEffect(() => {
    if (current) {
      if (selectedCalendarId != null && initial) {
        setInitial(false);
      } else {
        hourSetter(null);
      }

      getFreeDays();
    }
  }, [current]);

  useEffect(() => {
    getClosestFree();
  }, []);

  const getClosestFree = async () => {
    setLoading(true);
    try {
      const { data } = await apiNode.get(`/calendar/get-closest-free?id=${userProId}`);
      setCalendar(true);
      if (data?.id) {
        setCurrent(moment(data?.startDateTime));
      } else {
        setCurrent(moment());
      }
    } catch (error) {
      setCalendar(false);
      setCurrent(moment());
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <View contentContainerStyle={styles.containerBottomSheet}>
      <View style={styles.topContainer}>
        <Text style={styles.title}>{translate(pt.choose_hour)}</Text>
        <TouchableOpacity onPress={onClose} hitSlop={commons.hitSlop}>
          <Image source={closeImage} />
        </TouchableOpacity>
      </View>
      <View style={styles.packageContent}>
        <View style={styles.contentContainer}>
          <View style={styles.titleAndHourContainer}>
            {moment(current).subtract(1, 'day') > moment() ? (
              <TouchableOpacity
                hitSlop={commons.hitSlop}
                onPress={() => {
                  setCurrent(moment(current).subtract(1, 'days'));
                }}>
                <Image source={arrowLeft} />
              </TouchableOpacity>
            ) : (
              <View />
            )}
            <Text style={{ fontFamily: fonts.REGULAR, color: colors.greyBlue }}>
              {current ? moment(current).format('ddd - DD  MMMM') : '-'}
            </Text>
            <TouchableOpacity
              hitSlop={commons.hitSlop}
              onPress={() => {
                setCurrent(moment(current).add(1, 'days'));
              }}>
              <Image source={arrowRight} />
            </TouchableOpacity>
          </View>
          <View style={styles.hourItemsContainer}>
            {!loading ? (
              <>
                {hours.length > 0 ? (
                  <>
                    {hours.map(item => {
                      if (moment(item.startDateTime) > moment()) {
                        return (
                          <TouchableOpacity
                            onPress={() => {
                              if (!hourSelected) {
                                hourSetter(item.hour);
                                setCalendarId(item.id);
                              } else {
                                hourSetter(null);
                                setCalendarId(null);
                              }
                            }}
                            key={item.hour}
                            style={[
                              styles.hourItem,
                              {
                                backgroundColor:
                                  hourSelected === item.hour ? colors.aquaMarineTwo : colors.white,
                              },
                            ]}>
                            <Text
                              style={{
                                fontFamily: fonts.BOLD,
                                padding: 8,
                                color:
                                  hourSelected === item.hour ? colors.white : colors.aquaMarineTwo,
                              }}>
                              {item.hour}
                            </Text>
                          </TouchableOpacity>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </>
                ) : (
                  <Text
                    style={{
                      alignSelf: 'center',
                      textAlign: 'center',
                      flex: 1,
                      fontFamily: fonts.BOLD,
                      color: colors.aquaMarineTwo,
                    }}>
                    {translate(pt.without_free_time)}
                  </Text>
                )}
              </>
            ) : (
              <ActivityIndicator
                animating
                size={'small'}
                color={colors.aquaMarine}
                style={{ alignSelf: 'center', flex: 1 }}
              />
            )}
          </View>
        </View>
        <ButtonBase
          disabled={!hourSelected}
          title={translate(pt.confirm)}
          style={styles.buttonStyle}
          onPress={() => {
            setLoading(true);
            onClose();
            setTimeout(() => {
              navigation.navigate('SummaryScheduling', {
                userProId,
                calendarId,
                datetimeSession: `${moment(current).format('YYYY-MM-DD')} ${hourSelected.replace(
                  'h',
                  ':'
                )}`
              });
            }, 500);
          }}
        />
      </View>
    </View>
  );
};

export default BottomSheetScheduling;

const styles = StyleSheet.create({
  containerBottomSheet: {
    borderTopEndRadius: 47,
    borderTopStartRadius: 47,
    paddingBottom: 10,
  },
  topContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    marginTop: 14,
  },
  title: {
    textAlign: 'center',
    flex: 0.8,
    fontFamily: fonts.BOLD,
    fontSize: 18,
  },

  buttonStyle: { width: '100%', height: height / 8 / 2, backgroundColor: colors.aquaMarine },
  hourItem: {
    borderColor: colors.aquaMarineTwo,
    borderRadius: 3,
    borderWidth: 0.8,
    margin: 4,
    width: '30%',
    alignItems: 'center',
  },

  hourItemsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginStart: 5,
    marginBottom: 12,
    marginTop: 5,
  },
  titleAndHourContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: 8,
    marginTop: 8,
  },
  contentContainer: {
    height: 'auto',
    borderRadius: 10,

    borderWidth: 0.5,
    borderColor: ' rgba(191, 243, 243, 1)',
  },
  packageContent: {
    width: '80%',
    alignSelf: 'center',
    marginTop: height / 30,
  },
});
