export function setSubCategory(item) {
  return {
    type: '@category/SET_SUB_CATEGORY',
    payload: { item },
  };
}

export function setTypeShare(tyeShare) {
  return {
    type: '@category/SET_TYPE_SHARE',
    payload: { tyeShare },
  };
}

export function setNameShare(nameShare) {
  return {
    type: '@category/SET_NAME_SHARE',
    payload: { nameShare },
  };
}
