import React, { Fragment, useState } from 'react';

import { Image, SafeAreaView, StyleSheet, View } from 'react-native';

import StatusBarHolder from 'app/components/StatusBarHolder';
import pt from '../../../i18n/src/locale/pt';

import { translate } from '../../../i18n/src/locale';

import colors from 'app/styles/colors';

import LogInternal from './LogInternal';
import AnalyticsInternal from 'app/screens/core/mood/AnalyticsInternal';
import { useGamification } from 'app/hooks/useGamification';
import { Text } from 'react-native';
import { TouchableOpacity } from 'react-native';
import HandleActivity from '../activities/components/HandleActivity';
import { sendActionEvent } from '../../../services/utils';
import { useSelector } from 'react-redux';
import fonts from '../../../styles/fonts';
import { useNavigation } from '@react-navigation/native';

const MoodScreen = ({ tab = 'log' }) => {
  const [routes] = useState([
    { key: 'log', title: translate(pt.log), color: colors.aquaMarineTwo },
    { key: 'analytics', title: translate(pt.analytics), color: colors.aquaMarineTwo },
  ]);
  const [activeTab, setActiveTab] = useState('log');

  const { profile } = useSelector(state => state.user);
  const { eventsGenerationByHandleActivity } = useGamification();
  const navigation = useNavigation();

  const handlePressTab = async (route) => {
    try {
      sendActionEvent(`category_${route.key}`, {}, profile.email);
      const data = await HandleActivity({ routeName: route.key });
      eventsGenerationByHandleActivity(data);
      setActiveTab(route.key)
    } catch (err) {}
  }

  const renderScene = () => {
    if (activeTab === 'log') {
      return <LogInternal  />;
    }

    return (
      <AnalyticsInternal />
    );
  };

  return (
    <Fragment>
      <SafeAreaView style={{ flex: 0, backgroundColor: 'white' }} />
      <SafeAreaView style={styles.container}>
        <StatusBarHolder backgroundColor="white" barStyle="dark-content" />

        <View style={styles.tabsContainer}>
          <TouchableOpacity onPress={() => navigation.goBack()}>
            <Image 
              source={require('app/images/chevronLeft.png')} 
              style={{ tintColor: colors.aquaMarineTwo, width: 28, height: 28, marginLeft: 8 }} 
            />
          </TouchableOpacity>
          {routes.map(route => {
            return (
              <TouchableOpacity
                style={[
                  { marginLeft: 20, height: '100%', justifyContent: 'center' }, 
                  activeTab === route.key ? styles.activeTab : styles.inactiveTab
                ]}
                onPress={() => handlePressTab(route)}
              >
                <Text 
                  style={[
                    styles.tabTitle, 
                    activeTab === route.key 
                      ? styles.activeTabTitle :
                      styles.inactiveTabTitle
                  ]}>
                    {route.title}
                  </Text>
              </TouchableOpacity>
            );
          })}
        </View>
        {renderScene()}
        <Image source={require('app/images/circles.png')} style={styles.circleBg} />
      </SafeAreaView>
    </Fragment>
  );
};

export default MoodScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.paleGrey,
  },
  indicatorStyle: {
    backgroundColor: colors.greyBlue,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    height: 4,
  },
  circleBg: {
    position: 'absolute',
    zIndex: -1,
    width: 500,
    height: 500,
    resizeMode: 'contain',
    bottom: -180,
    right: -220,
  },
  tabsContainer: {
    flexDirection: 'row',
    backgroundColor: colors.white,
    height: 50,
    alignItems: 'center'
  },
  tabTitle: {
    fontFamily: fonts.BOLD,
    color: colors.black,
    size: 18,
  },
  inactiveTab: {
    borderBottomWidth: 3,
    borderBottomColor: colors.white
  },
  activeTab: {
    borderBottomWidth: 3,
    borderBottomColor: colors.aquaMarineTwo,
  },
  inactiveTabTitle: {
    opacity: 0.4
  },
  activeTabTitle: {
    opacity: 1
  }
});
