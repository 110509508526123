import React, { useCallback, useEffect, useState } from 'react';
import {
  Image,
  ImageBackground,
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
  ActivityIndicator,
} from 'react-native';
// import { Actions } from 'react-native-router-flux';
import Stars from 'app/components/Stars';
import Icon from 'react-native-vector-icons/dist/FontAwesome';

//Traduction
import { translate } from '../../../i18n/src/locale';
import pt from 'app/i18n/src/locale/pt';
import { SafeAreaView } from 'react-native-safe-area-context';
import colors from 'app/styles/colors';
import fonts from 'app/styles/fonts';
import TextArea from 'app/components/TextArea';
import apiNode from 'app/services/apiNode';
import { normalize, sendSessionHistoryEvent } from 'app/services/utils';
import { doReloadSessions } from 'app/store/modules/user/actions';
import { useDispatch } from 'react-redux';

import noPhotoImage from 'app/images/no-photo.jpg';
import IconCorp from 'app/screens/core/professionalFilterResult/components/IconCorp';
import useGlobalAlert from 'app/hooks/useGlobalAlert';
import { CommonActions, StackActions, useNavigation } from '@react-navigation/native';

const CallServiceFeedbackScreen = ({ route }) => {

  const { sessionId } = route?.params;

  const navigation = useNavigation();

  const dispatch = useDispatch();

  const [sessionData, setSessionData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [categoriesQuality, setCategoriesQuality] = useState([]);
  const [comment, setComment] = useState('');
  const [ratingStars, setRatingStars] = useState(0);
  const { setAlert } = useGlobalAlert();

  const setSelectedCategory = (item, index) => {
    var tmpCategories = [];
    categoriesQuality.map((tag, i) => {
      if (i === index) {
        tag.selected = !tag.selected;
      }
      tmpCategories.push(tag);
    });
    setCategoriesQuality(tmpCategories);
  };

  const getCategories = useCallback(async () => {
    const { data } = await apiNode.get('feedback-categories/get-to-user-pro');
    let aux = [];

    data.map(category => {
      aux.push({ ...category, selected: false });
    });
    setCategoriesQuality(aux);
  }, [sessionId]);

  const getData = useCallback(async () => {
    const session = await apiNode.get(`sessions/${sessionId}`).catch(er => {
      console.log({ er });
    });

    setSessionData(session.data);
    setRatingStars(session.data.userPro.ratingStars);
  }, [sessionId]);

  const handleSubmit = async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    try {
      let feedbackCategories = [];
      categoriesQuality.map(category => {
        if (category.selected) {
          feedbackCategories.push({ id: category.id });
        }
      });
      await apiNode.post('session-feedbacks/to-user-pro', {
        sessionId: sessionId,
        text: comment,
        ratingStars,
        feedbackCategories,
      });
      sendSessionHistoryEvent(sessionData.userId, sessionId, 10);
      dispatch(doReloadSessions());
      setAlert('Feedback enviado', translate(pt.concluded_feedback), {
        onPress: () => navigation.dispatch(StackActions.pop(2)),
      });
    } catch (error) {
      console.log(error.response);
      setAlert('Falha ao enviar', translate(pt.error_feedback), {
        onPress: () => navigation.dispatch(StackActions.pop(2)),
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getCategories();
    getData();
  }, [sessionId, getCategories, getData]);

  return (
    <SafeAreaView style={styles.safe}>
      <ScrollView>
        <ImageBackground source={require('app/images/topBackLightGreen.png')} style={styles.topBox}>
          <View style={styles.imageBox}>
            <Image source={require('app/images/logoBigger.png')} style={styles.profileImageBack} />
            <Image
              source={
                sessionData?.userPro?.profileImageUrl
                  ? { uri: sessionData?.userPro?.profileImageUrl }
                  : noPhotoImage
              }
              style={styles.profileImage}
            />
            <Text style={styles.profileName}>{sessionData?.userPro?.name}</Text>
            {sessionData?.userPro?.isPartner && (
              <IconCorp
                style={{
                  width: normalize(113),
                  paddingHorizontal: 8,
                  marginTop: 6,
                }}
                userType="partner"
              />
            )}
          </View>
          {/* <TouchableOpacity style={styles.closeButtonBox} onPress={Actions.pop}>
            <Image source={require('app/images/closeBlack.png')} style={styles.closeButton} />
          </TouchableOpacity> */}
        </ImageBackground>

        <View style={styles.bottomBox}>
          <View style={styles.rateBox}>
            <Stars
              default={ratingStars}
              update={val => setRatingStars(val)}
              count={5}
              spacing={8}
              starSize={10}
              fullStar={<Icon name={'star'} size={30} color={colors.yellowOrange} />}
              emptyStar={<Icon name={'star-o'} size={30} color={colors.yellowOrange} />}
            />
          </View>

          <View style={{ width: '100%', paddingHorizontal: 25 }}>
            <View style={styles.divisor} />
          </View>

          <View style={styles.tagBox}>
            <View style={styles.tagsBox}>
              {categoriesQuality.map((item, index) => (
                <TagButton
                  item={item}
                  index={index}
                  onPress={() => {
                    setSelectedCategory(item, index);
                  }}
                />
              ))}
            </View>
          </View>

          <View style={styles.textareaBox}>
            <TextArea
              placeholder={translate(pt.write_comment_about_professional)}
              value={comment}
              setValue={setComment}
            />
          </View>

          <View style={styles.buttonsBox}>
            <TouchableOpacity
              onPress={handleSubmit}
              style={styles.actionButton}
              disabled={isLoading}>
              {!isLoading ? (
                <Text style={styles.actionButtonText}>{translate(pt.send)}</Text>
              ) : (
                <ActivityIndicator size={'small'} animating color={colors.white} />
              )}
            </TouchableOpacity>
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const TagButton = ({ item, index, onPress }) => {
  return (
    <TouchableOpacity
      onPress={onPress}
      style={[styles.tagButton, item.selected ? styles.actionButtonSelected : {}]}
      key={item.id}>
      <Text style={[styles.tagButtonText, item.selected ? styles.actionButtonTextSelected : {}]}>
        {item.name}
      </Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  safe: {
    // flex: 1,
    height: '100vh'
  },
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    //padding: 20,
  },

  topBox: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    paddingTop: 15,
    paddingBottom: 25,
  },

  imageBox: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  profileImageBack: {
    alignItems: 'center',
    marginTop: 30,
  },
  profileImage: {
    borderRadius: 100,
    height: 75,
    width: 75,
  },
  profileName: {
    marginTop: 30,
    fontSize: 14,
    color: colors.greyBlue,
    fontFamily: fonts.REGULAR,
    fontWeight: '700',
  },
  closeButtonBox: {
    flex: 1,
    marginRight: 20,
    alignItems: 'flex-end',
  },

  bottomBox: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 15,
    width: '100%',
  },

  divisor: {
    backgroundColor: colors.aquaMarineTwo,
    height: 1,
    width: '100%',
    marginVertical: 20,
  },

  tagBox: {
    paddingHorizontal: 20,
    width: '100%',
    marginBottom: 20,
  },

  tagsBox: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },

  tagButton: {
    borderColor: colors.aquaMarineTwo,
    borderRadius: 4,
    borderWidth: 1,
    paddingHorizontal: 20,
    paddingVertical: 10,
    justifyContent: 'center',
    height: 50,
    alignItems: 'center',
    marginHorizontal: 5,
    marginVertical: 5,
    flexGrow: 1,
  },
  tagButtonText: {
    fontSize: 12,
    color: colors.aquaMarineTwo,
    fontFamily: fonts.REGULAR,
    fontWeight: '700',
  },

  textareaBox: {
    //flex: 1,
    width: '100%',
    paddingHorizontal: 20,
    //borderWidth: 1,
  },

  buttonsBox: {
    width: '100%',
    paddingHorizontal: 25,
  },
  actionButton: {
    backgroundColor: colors.aquaMarine,
    borderRadius: 4,
    paddingHorizontal: 20,
    paddingVertical: 10,
    justifyContent: 'center',
    height: 50,
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 10,
    width: '100%',
  },
  actionButtonSelected: {
    backgroundColor: colors.aquaMarineTwo,
  },
  actionButtonText: {
    fontSize: 14,
    color: colors.white,
    fontFamily: fonts.REGULAR,
    fontWeight: '700',
  },
  actionButtonTextSelected: {
    fontSize: 14,
    color: colors.white,
    fontFamily: fonts.REGULAR,
    fontWeight: '700',
  },
});

export default CallServiceFeedbackScreen;
