import React from 'react';
import { TouchableOpacity } from 'react-native';

import { View, StyleSheet, Image } from 'react-native';
import plus2 from '../images/plus2.png';
import colors from '../styles/colors';

const Plus = ({ style, onPress }) => (
  <TouchableOpacity onPress={onPress} style={[styles.icon, style]}>
    <Image source={plus2} style={{ width: 16, height: 16 }} />
  </TouchableOpacity>
);

export default Plus;

const styles = StyleSheet.create({
  icon: {
    zIndex: 15,
    right: 0,
    top: 0,
    alignItems: 'center',
    justifyContent: 'center',
    width: 27,
    position: 'absolute',
    height: 27,
    borderRadius: 3.1,
    backgroundColor: colors.aquaMarineTwo,
  },
});
