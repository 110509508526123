import { imagesForModal, insigniasOBJ, textsForButton } from 'app/constants/gamefication';
import useGlobalAlert from 'app/hooks/useGlobalAlert';
import { GoalPoints } from 'app/screens/core/gamification/GoalEditing';
import BoxDescription from 'app/screens/core/gamification/components/BoxDescription';
import TopInsignia from 'app/screens/core/gamification/components/TopInsignia';
import { newMetas } from 'app/screens/core/gamification/utils/conts';
import {
  setCurrentGoal,
  setStartedJourney,
  setIsShowBoarding,
  setUserData,
} from 'app/store/modules/gamification/actions';
import colors from 'app/styles/colors';
import textStyles from 'app/styles/textStyles';
import React from 'react';
import { Image, Text, View } from 'react-native';
import { useDispatch } from 'react-redux';

const stayActive = {
  mainImg: 'points',
  title: 'Siga ativo e ganhe pontos!',
  buttonText: 'points',
  contentText:
    'Você receberá pontos ao concluir metas e realizar atividades no APP. Mantenha-se ativo para não perder sua posição na classificação!',
};

const journeyHasBegun = {
  mainImg: 'img1',
  title: 'Sua jornada já começou!',
  modalName: 'journey',
  contentText:
    'Hoje você começa sua jornada rumo a uma vida mais saudável! O que te coloca à frente de 47% da população brasileira sedentária!',
  contentTextSecond: '',
  extraText: 'Aqui uma recompensa por seu começo:',
  extraComponent: (
    <View style={{ alignItems: 'center', marginTop: 10 }}>
      <GoalPoints
        total_points={5}
        style={{ paddingVertical: 6 }}
        iconSize={18}
        textStyle={textStyles.medium14White}
      />
    </View>
  ),
  buttonText: 'letsgo',
  action: 'modal',
  actionData: stayActive,
};

const setGoalModalData = {
  mainImg: 'img2',
  title: 'Metas definidas com sucesso!',
  contentText:
    'Obrigado por definir suas metas! Com elas, você está um passo mais perto de alcançar seus objetivos e viver uma vida mais saudável e feliz. Mantenha o foco e siga em frente!',
  buttonText: 'letsgo',
};

const achievements = {
  '3 dias ativos': {
    mainImg: 'img4',
    topInsigniaText: '3 dias seguidos',
    topInsigniaImg: 'img4',
  },
  '5 dias ativos': {
    mainImg: 'img5',
    topInsigniaText: '5 dias seguidos',
    topInsigniaImg: 'img5',
  },
  '7 dias ativos': {
    mainImg: 'img6',
    topInsigniaText: '7 dias seguidos',
    topInsigniaImg: 'img6',
  },
  '10 dias ativos': {
    mainImg: 'img7',
    topInsigniaText: '10 dias seguidos',
    topInsigniaImg: 'img7',
  },
  '15 dias ativos': {
    mainImg: 'img8',
    topInsigniaText: '15 dias seguidos',
    topInsigniaImg: 'img8',
  },
  '30 dias ativos': {
    mainImg: 'img9',
    topInsigniaText: '30 dias seguidos',
    topInsigniaImg: 'img9',
  },
};

export const useGamification = () => {
  const dispatch = useDispatch();

  const { setAlert } = useGlobalAlert();

  const eventsGenerationByHandleActivity = data => {
    try {
      if (data && data?.response && data.response?.is_achievement) {
        const goalModalData = formatAchievementToModalData(data?.response?.goal);

        showModalForGamefication(goalModalData, 'achievement');

        dispatch(setUserData(data.user));
      }
    } catch (error) {}
  };

  const formatAchievementToModalData = data => {
    const achievement = achievements[data.name];

    const nextModal = { ...newMetas[Object.keys(achievements).indexOf(data.name)] };
    nextModal.extraComponent = (
      <View style={{ alignItems: 'center', marginTop: -25 }}>
        <GoalPoints
          total_points={nextModal.points}
          style={{ paddingVertical: 6 }}
          iconSize={18}
          textStyle={textStyles.medium14White}
        />
      </View>
    );

    return {
      mainImg: achievement.mainImg,
      points: data.total_points,
      title: `Você concluiu ${data.name}!`,
      topInsigniaText: achievement.topInsigniaText,
      topInsigniaImg: insigniasOBJ[achievement.topInsigniaImg],
      contentText: data.goal_message,
      buttonText: 'elevation',
      action: 'modal',
      actionData: nextModal,
    };
  };

  const closeBoarding = () => {
    dispatch(setIsShowBoarding(false));
  };

  const openBoarding = async () => {
    dispatch(setIsShowBoarding(true));
  };

  const switchBoardingShowValue = value => {
    dispatch(setIsShowBoarding(value));
  };

  const onChangeCurrentGoal = data => {
    dispatch(setCurrentGoal(data));
  };

  const journeyHasBegunAction = async () => {
    try {
      dispatch(setStartedJourney(true));
      showModalForGamefication(journeyHasBegun);
      getUserGamificationData();
    } catch (error) {}
  };

  const showModalForGamefication = (modalData, type) => {
    const modalOptions = {
      text: textsForButton[modalData.buttonText],
      icon: [
        <Image
          source={modalData.useDirectImage ? modalData.mainImg : imagesForModal[modalData.mainImg]}
          style={{
            width: 140,
            height: 140,
            resizeMode: 'contain',
            marginBottom: type == 'achievement' ? 10 : 0,
          }}
        />,
      ],
      onPress: () => {
        if (modalData?.action == 'modal') {
          showModalForGamefication(modalData?.actionData, 'custom');
          return;
        }
        if (!modalData.onPress) {
          return;
        }
        modalData?.onPress();
      },
    };

    if (type == 'achievement') {
      modalOptions.topTag = (
        <TopInsignia source={modalData.topInsigniaImg} text={modalData.topInsigniaText} />
      );
      modalOptions.icon.push(
        <View style={{ position: 'absolute', bottom: 0, alignSelf: 'center' }}>
          <GoalPoints
            total_points={modalData.points}
            style={{ paddingVertical: 6 }}
            iconSize={18}
            textStyle={textStyles.medium14White}
          />
        </View>
      );
    }

    if (type == 'custom') {
      modalOptions.additionalComponent = (
        <BoxDescription
          text={modalData.contentText}
          extra={[
            modalData?.extraText && (
              <Text style={{ ...textStyles.medium14, color: colors.darkPro2 }}>
                {modalData.extraText}
              </Text>
            ),
            modalData?.extraComponent,
          ]}
        />
      );
      if (modalData?.topTitle) {
        modalOptions.topTitle = modalData.topTitle;
      }
    }

    setAlert(modalData?.title, type != 'custom' ? modalData.contentText : '', modalOptions);
  };

  const openGoalsModal = () => {
    showModalForGamefication(setGoalModalData);
  };

  return {
    eventsGenerationByHandleActivity,
    closeBoarding,
    openBoarding,
    switchBoardingShowValue,
    onChangeCurrentGoal,
    journeyHasBegunAction,
    openGoalsModal,
  };
};
