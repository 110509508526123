import colors from 'app/styles/colors';
import React, { useEffect, useState } from 'react';
import { Dimensions, Image, Linking, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import ShadowView from '@liuyunjs/react-native-simple-shadow-view';
import { useSelector } from 'react-redux';
import { errorControl, errorMessage, explodeQuery } from 'app/services/utils';
import apiNode from 'app/services/apiNode';
import HeaderTitleNew from 'app/screens/core/home/components/HeaderTitleNew';
// import Carousel from 'react-native-snap-carousel';
import Swiper from 'react-native-web-swiper';
// import { Actions } from 'react-native-router-flux';

const width = Dimensions.get('window').width;

const CardBusinessAds = ({}) => {
  const { profile } = useSelector(state => state.user);
  const [ads, setAds] = useState([]);
  const [, setResolveLoading] = useState(false);

  const getAds = async () => {
    try {
      const { data } = await apiNode.get('business-ads');
      setAds(data);
    } catch (e) {
      console.log('ADS', errorControl(e));
    }
  };

  useEffect(() => {
    getAds();
  }, []);

  const handleClickAds = async item => {
    const { external_url, action } = item;

    if (action !== 'resolve') {
      Linking.openURL(item.external_url);
      return;
    }

    try {
      setResolveLoading(true);

      const params = new explodeQuery(external_url);
      if (!params?.action) {
        return;
      }

      const { data } = await apiNode.get('business-ads/resolve', {
        params,
      });

      setResolveLoading(false);

      if (!data.success) {
        errorMessage('Ocorreu um erro, tente novamente mais tarde.');
      }

      if (data?.redirectUrl) {
        return Linking.openURL(data?.redirectUrl);
      }


      if(data?.redirectScreen) {
        return  navigation.navigate(data?.redirectScreen, data?.params);
      }
    } catch (e) {
      console.log(errorControl(e));
      errorMessage('Erro ao buscar link de redirecionamento');
    }
  };

  const renderAd = ({ item }) => {
    return (
      <View style={[styles.container, {}]}>
        <TouchableOpacity
          onPress={() => handleClickAds(item)}
          activeOpacity={0.7}
          decelerationRate={0.5}
          style={styles.clickContainer}>
          <ShadowView style={styles.shadowContainer}>
            <Image source={{ uri: item.image_url }} style={styles.image} />
          </ShadowView>
        </TouchableOpacity>
      </View>
    );
  };

  return ads.length == 0 ? null : (
    <View style={{ marginBottom: -15 }}>
      <HeaderTitleNew title={profile?.business_title || 'Namu'} />
      <Swiper
        loop
        innerContainerStyle={{aspectRatio: 16/9, width: '100%', height: 'auto'}}
        controlsProps={{
          prevTitle: null,
          nextTitle: null,
          dotsPos: 'bottom',
          dotActiveStyle: {
            backgroundColor: colors.aquaMarineTwo
          },
        }}
      >
        {ads.map(ad => {
          return renderAd({item: ad});
        })}
      </Swiper>
    </View>
  );
};

const styles = StyleSheet.create({
  containerScroll: {
    flex: 1,
  },
  container: {
    flex: 1,
    marginBottom: 15,
  },
  clickContainer: {
    aspectRatio: 1.63,
    width: '100%',
    height: '100%',
    paddingBottom: 20,
    paddingHorizontal: 20,
  },
  shadowContainer: {
    backgroundColor: colors.white,
    borderRadius: 10,
    shadowColor: colors.black,
    shadowOpacity: 0.1,
    shadowRadius: 5,
    height: '100%',
    width: '100%',
    shadowOffset: { width: 0, height: 5 },
  },
  image: {
    height: '100%',
    width: '100%',
    borderRadius: 10,
    resizeMode: 'cover',
  },
});

export default CardBusinessAds;
