import pt from 'app/i18n/src/locale/pt';
import colors from 'app/styles/colors';
import fonts from 'app/styles/fonts';
import React, { useEffect, useState } from 'react';
import { Alert, Image, ScrollView, StyleSheet, Text, TouchableOpacity, View, Modal } from 'react-native';
import backgroundCustomSearch from 'app/images/backgroundCustomSearch.png';
import AnimatedLottieView from 'lottie-react-native';
import commons from 'app/styles/commons';
import CustomIcon from 'app/styles/icon';
import Icon from 'react-native-vector-icons/dist/FontAwesome5';
import textStyles from 'app/styles/textStyles';
import { translate } from 'app/i18n/src/locale';
import trash from 'app/images/trash.png';
import { errorControl } from 'app/services/utils';
import ButtonShadow from 'app/components/ButtonShadow';
import OptionsContextMenu from 'app/components/OptionsContextMenu';
import apiNode from 'app/services/apiNode';
import cardFlags from 'app/constants/cardFlags';

const SwitchCreditCardModal = ({ open, openAddCard, currentCard, onSelectCard, onClose }) => {
  const [loading, setLoading] = useState(true);
  const [selectedCard, setSelectedCard] = useState();
  const [data, setData] = useState([]);

  useEffect(() => {
    setSelectedCard(currentCard);
  }, [currentCard]);

  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if (open == true) {
      fetchData();
    }
  }, [open]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const { data: dataFromFetch } = await apiNode.get(
        `namu-pass/credit-card?rand=${new Date().getTime()}`
      );
      setData(dataFromFetch.data);
    } catch (e) {
      console.log(errorControl(e));
    }
    setLoading(false);
  };

  const setDefaultCard = async item => {
    setLoading(true);
    try {
      await apiNode.patch(`namu-pass/credit-card/default/${item?.cardId}`);
      setSelectedCard(item);
      onSelectCard(item);
      onClose();
    } catch (e) {
      console.log(errorControl(e));
    }
    setLoading(false);
  };

  const handleDelete = item => {
    Alert.alert(
      'Confirmação',
      'Deseja realmente excluir este cartão?',
      [
        {
          text: 'Cancelar',
          style: 'cancel',
        },
        {
          text: 'Confirmar',
          onPress: () => {
            deleteCard(item);
          },
        },
      ],
      { cancelable: false }
    );
  };

  const deleteCard = async item => {
    setLoading(true);
    try {
      const { data: dataDelete } = await apiNode.delete(`/namu-pass/credit-card/${item.cardId}`);

      // eslint-disable-next-line eqeqeq
      if (dataDelete.status == 200) {
        fetchData();

        if (selectedCard?.cardId === item.cardId) {
          onSelectCard(null);
          setSelectedCard(null);
          onClose();
        }
      }
    } catch (e) {
      console.log(errorControl(e));
    }
    setLoading(false);
  };

  const renderData = () => {
    return (
      <>
        <View style={{ ...commons.flexRow, marginHorizontal: 24, paddingBottom: 20 }}>
          <View style={commons.flexRow}>
            <TouchableOpacity
              onPress={onClose}
              hitSlop={commons.hitSlop}
              style={{ marginRight: 10 }}>
              <CustomIcon
                name={'chevron_right'}
                size={18}
                color={colors.aquaMarineTwo}
                style={{ transform: [{ rotateZ: '-180deg' }], marginRight: 10 }}
              />
            </TouchableOpacity>
            <Icon name={'wallet'} color={colors.aquaMarineTwo} size={20} />
            <Text style={{ ...styles.title, marginLeft: 10 }}>{translate(pt.payment_method)}</Text>
          </View>
        </View>

        <ScrollView style={{ flex: 1 }} contentContainerStyle={{ marginHorizontal: 24 }}>
          <Text style={{ ...styles.title, marginVertical: 20 }}>{translate(pt.cards)}</Text>

          {data.map(item => (
            <TouchableOpacity
              key={item.id}
              opacity={0.7}
              onPress={() => setDefaultCard(item)}
              // eslint-disable-next-line react-native/no-inline-styles
              style={{
                ...styles.creditCardItem,
                flex: 1,
                // eslint-disable-next-line eqeqeq
                backgroundColor: selectedCard?.id == item.id ? colors.aquaMarineTwo : colors.greyF5,
              }}>
              <View style={{ ...commons.flexRow }}>
                <Image
                  source={{ uri: cardFlags[item?.brand] || '' }}
                  style={{ width: 20, height: 18, resizeMode: 'contain' }}
                />
                <Text
                  style={{
                    ...styles.creditCardText,
                    // eslint-disable-next-line eqeqeq
                    color: selectedCard?.id == item.id ? colors.white : colors.charcoalGreyTwo,
                  }}>
                  •••• {item.lastDigits}
                </Text>
              </View>
              <OptionsContextMenu
                // eslint-disable-next-line eqeqeq
                buttonColor={selectedCard?.id == item.id ? colors.white : colors.greyBlue}
                items={[
                  {
                    title: 'Remover',
                    icon: <Image source={trash} style={styles.optionIcon} />,
                    onPress: () => handleDelete(item),
                  },
                ]}
              />
            </TouchableOpacity>
          ))}
        </ScrollView>
        <ButtonShadow
          onPress={() => openAddCard()}
          backgroundColor={colors.aquaMarineTwo}
          containerStyle={styles.buttonOk}
          title={translate(pt.add_new_credit_card)}
        />
      </>
    );
  };

  return (
    <>
      <Modal
        style={styles.container}
        coverScreen
        visible={open}
        backdropOpacity={0.2}
        onBackdropPress={onClose}>
        <View style={styles.content}>
          <View style={styles.wrapper}>
            {loading ? (
              <View style={{ marginTop: 100, width: 120, height: 120, alignSelf: 'center' }}>
                <AnimatedLottieView
                  source={require('app/lotties/loading.json')}
                  autoPlay
                  loop={true}
                  style={{ width: 120, transform: [{ scale: 1.3 }] }}
                />
              </View>
            ) : (
              renderData()
            )}
          </View>
          <Image
            source={backgroundCustomSearch}
            style={{
              position: 'absolute',
              opacity: 0.7,
              bottom: 0,
              width: '100%',
              transform: [{ scale: 1.2 }],
              resizeMode: 'contain',
              zIndex: -1,
            }}
          />
        </View>
      </Modal>
    </>
  );
};

export default SwitchCreditCardModal;

const styles = StyleSheet.create({
  container: {
    margin: 0,
    zIndex: 10,
  },
  wrapper: {
    paddingTop: 28,
    // paddingHorizontal: 24,
    flex: 1,
  },
  logo: {
    width: 70,
    height: 70,
    borderRadius: 10,
    resizeMode: 'cover',
    marginRight: 14,
  },
  content: {
    backgroundColor: colors.white,
    marginTop: 'auto',
    height: '95%',
    borderTopLeftRadius: 47,
    borderTopRightRadius: 47,
  },
  textStyle: {
    fontFamily: fonts.REGULAR,
    color: colors.greyLight4,
    fontSize: 14,
    paddingHorizontal: 20,
    textAlign: 'center',
  },
  textHeader: {
    fontFamily: fonts.BOLD,
    fontSize: 20,
    color: colors.aquaMarineTwo,
  },
  title: {
    ...textStyles.medium16,
    color: colors.charcoalGreyTwo,
  },
  optionIcon: {
    width: 20,
    height: 20,
    resizeMode: 'contain',
    tintColor: colors.greyBlue,
  },
  dailyPrice: {
    paddingHorizontal: 14,
    paddingVertical: 11,
    marginTop: 28,
    borderColor: colors.aquaMarineTwo,
    borderRadius: 10,
    borderWidth: 1,
  },
  hr: {
    width: '100%',
    height: 1,
    backgroundColor: colors.greyLight,
    marginVertical: 30,
  },
  creditCardItem: {
    marginBottom: 15,
    padding: 16,
    backgroundColor: colors.greyF5,
    borderRadius: 15,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  creditCardText: {
    ...textStyles.bold14,
    fontFamily: fonts.BLACK,
    marginLeft: 8,
    color: colors.charcoalGreyTwo,
  },
  buttonOk: {
    // width: '85%',
    marginHorizontal: 28,
    height: 50,
    marginTop: 20,
    zIndex: 1,
    marginBottom: 20,
  },
});
