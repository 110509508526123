/* eslint-disable react-native/no-inline-styles */
import React from 'react';

import { View, StyleSheet, Text, Image } from 'react-native';
import colors from '../../../../styles/colors';
import textStyles from '../../../../styles/textStyles';
import checkedOrange from '../../../../images/checkedOrange.png';

const DayPointer = ({ text, checked }) => (
  <View style={[styles.container, { backgroundColor: colors.white }]}>
    <Text
      style={[
        textStyles.bold16,
        { color: checked ? colors.white : colors.blackTwo, marginLeft: 14 },
      ]}>
      {text}
    </Text>
    <Image source={checkedOrange} style={styles.checked} />
  </View>
);

export default DayPointer;

const styles = StyleSheet.create({
  checked: { marginLeft: 8, width: 11, height: 6, tintColor: colors.white },
  container: {
    width: 'auto',
    alignSelf: 'baseline',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: 23,
    paddingRight: 12,
    marginBottom: 14,
    marginTop: 18,
    borderBottomRightRadius: 11.5,
    borderTopRightRadius: 11.5,
  },
});
