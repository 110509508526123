import React from 'react';
import { View, Text, StyleSheet, Image, Linking } from 'react-native';
import fonts from 'app/styles/fonts';
import colors from 'app/styles/colors';
import LinearGradient from 'react-native-linear-gradient';
import congratWoman from 'app/images/congratWoman.png';
import ButtonLink from 'app/components/ButtonLink';

const CardCongrats = ({ title, content, link, textTitleStyle, textMessageStyle }) => {
  return (
    <LinearGradient
      style={styles.textContainer}
      angle={65}
      useAngle
      colors={[colors.aquaMarineTwo, colors.aquaMarineGrd]}>
      <View style={styles.imageWomanWrapper}>
        <Image source={congratWoman} />
      </View>
      <View style={{ right: 0, left: 0 }}>
        <Text style={[styles.textCongratulations, styles.textCenter, textTitleStyle]}>{title}</Text>
        <Text style={[styles.textMessage, styles.textCenter, textMessageStyle]}>{content}</Text>
      </View>
      {link && (
        <ButtonLink
          title="Link do boleto"
          underline
          textStyle={styles.textLink}
          containerStyle={{ alignItems: 'center', marginTop: 15 }}
          onPress={() => {
            Linking.openURL(link);
          }}
        />
      )}
    </LinearGradient>
  );
};

export default CardCongrats;

const styles = StyleSheet.create({
  textLink: {
    color: colors.white,
    fontSize: 15,
    lineHeight: 20,
    fontFamily: fonts.LIGHT,
  },
  textContainer: {
    marginBottom: 25,
    backgroundColor: colors.aquaMarineTwo,
    // height: 178,
    width: 300,
    borderRadius: 12,
    paddingBottom: 32,
    paddingTop: 70,
  },
  imageWomanWrapper: {
    position: 'absolute',
    top: -130,
    right: 0,
    left: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  textCongratulations: {
    fontFamily: fonts.BOLD,
    fontSize: 24,
    color: colors.white,
    lineHeight: 32,
  },
  textCenter: {
    textAlign: 'center',
  },
  textMessage: {
    fontFamily: fonts.REGULAR,
    fontSize: 16,
    color: colors.white,
    lineHeight: 22,
    marginTop: 10,
  },
});
