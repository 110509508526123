import React from 'react';
import { View, Text, StyleSheet, Image } from 'react-native';
import fonts from 'app/styles/fonts';
import colors from 'app/styles/colors';
import LinearGradient from 'react-native-linear-gradient';
import chipIcon from 'app/images/chipIcon.png';
import masterCardIcon from 'app/images/masterCardIcon.png';

const CreditCard = ({ containerStyle, cardHolder, CVV, validThru, cardNumber }) => {
  const GroupText = ({ label, value, style }) => (
    <View style={[style]}>
      <Text style={styles.textLabel}>{label}</Text>
      <Text style={styles.textValue}>{value}</Text>
    </View>
  );

  const EncryptedCardNumber = ({ number }) => {
    const numberString = number.replace(/\s/g, '');
    const firstPart = numberString.slice(0, 4);
    const secondPart = numberString.slice(4, 8).replace(/./g, '*');
    const thirdPart = numberString.slice(8, 12).replace(/./g, '*');
    const fourthPart = numberString.slice(12, 16);

    return (
      <View style={{ flexDirection: 'row' }}>
        {[firstPart, secondPart, thirdPart, fourthPart].map(numbers => (
          <Text
            style={{ marginRight: 10, color: colors.white, fontSize: 16, fontFamily: fonts.BOLD }}>
            {numbers}
          </Text>
        ))}
      </View>
    );
  };

  return (
    <LinearGradient
      style={[styles.container, { backgroundColor: colors.aquaMarineTwo }, containerStyle]}
      angle={98}
      useAngle
      colors={[colors.aquaMarineTitle, colors.aquaMarineTwo2, colors.aquaMarineTwo2]}>
      <View style={{ justifyContent: 'space-between', flexDirection: 'row' }}>
        <Image source={chipIcon} />
        <Image source={masterCardIcon} />
      </View>

      <View>
        <EncryptedCardNumber number={cardNumber} />
      </View>

      <View style={styles.containerCardInfo}>
        <GroupText label="Cardholder" value={cardHolder} />
        <View style={{ flexDirection: 'row' }}>
          <GroupText label="CVV" value={CVV} />
          <GroupText label="Valid Thru" value={validThru} style={{ marginLeft: 20 }} />
        </View>
      </View>
    </LinearGradient>
  );
};

export default CreditCard;

const styles = StyleSheet.create({
  container: {
    height: 166,
    borderRadius: 20,
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderWidth: 2,
    borderColor: colors.aquaMarineGrd,
    paddingHorizontal: 21,
    paddingVertical: 24,
  },
  textLabel: {
    color: '#178485',
    fontSize: 11,
    fontFamily: fonts.REGULAR,
  },
  textValue: {
    color: colors.white,
    fontSize: 13,
    fontFamily: fonts.REGULAR,
  },
  containerCardInfo: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});
