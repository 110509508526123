import React from 'react';

import { View, Text, StyleSheet, Image } from 'react-native';

import fonts from 'app/styles/fonts';
import colors from 'app/styles/colors';
import starYellowFull from 'app/images/starYellowFull.png';

export const GymReviews = () => {
  const renderStars = note => {
    let stars = [];
    for (let i = 0; i < note; i++) {
      stars.push(
        <Image source={starYellowFull} style={{ width: 9, height: 9, resizeMode: 'contain' }} />
      );
    }
    return stars;
  };

  const renderReviewCard = review => {
    const tags = ['teste', 'teste2', 'teste3', 'teste4', 'teste3', 'teste4'];
    return (
      <View style={styles.cardContainer}>
        <View style={styles.headerCardContainer}>
          <Image style={styles.profileImg} source={starYellowFull} width={50} height={50} />
          <View style={{ marginTop: 5 }}>
            <Text>Amanda</Text>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Text style={{ marginRight: 5 }}>5.0</Text>
              {renderStars(5)}
            </View>
          </View>
          <Text style={styles.timeText}>02 de janeiro de 2023</Text>
        </View>
        <View style={styles.tagContainer}>
          {tags.map(text => (
            <Text style={styles.cardTag}>{text}</Text>
          ))}
        </View>
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <View>
        <Text style={styles.note}>5.0</Text>
        <View style={styles.starContainer}>{renderStars(5)}</View>
        <Text style={styles.totalReviews}>{'70'} avaliações no total.</Text>
      </View>
      <View style={styles.reviewsCardContainer}>{renderReviewCard()}</View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginHorizontal: 20,
    marginVertical: 20,
  },
  note: {
    fontSize: 26,
    color: colors.charcoalGreyTwo,
    fontFamily: fonts.BOLD,
  },
  starContainer: {
    flexDirection: 'row',
    marginVertical: 5,
  },
  totalReviews: {
    fontSize: 16,
    color: colors.charcoalGreyTwo,
    fontFamily: fonts.REGULAR,
  },
  reviewsCardContainer: {
    backgroundColor: colors.white,
    borderRadius: 15,
    marginTop: 10,
  },
  cardContainer: {
    padding: 10,
  },
  headerCardContainer: {
    flexDirection: 'row',
    position: 'relative',
    marginBottom: 5,
  },
  profileImg: {
    resizeMode: 'cover',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 50,
    marginLeft: 10,
    marginRight: 10,
  },
  timeText: {
    position: 'absolute',
    right: 15,
    top: 10,
    fontSize: 10,
    color: colors.charcoalGreyTwo,
  },
  tagContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  cardTag: {
    borderColor: colors.aquaMarineTwo,
    borderWidth: 2,
    padding: 10,
    color: colors.aquaMarineTwo,
    borderRadius: 10,
    width: 105,
    marginBottom: 10,
    marginRight: 10,
  },
});
