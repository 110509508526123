import React, { useRef, useState } from 'react';

import { View, StyleSheet, TouchableOpacity, Image } from 'react-native';
import { Menu, MenuItem } from 'react-native-enhanced-popup-menu';
import MenuItemContent from './MenuItemContent';
import { translate } from 'app/i18n/src/locale';
import pt from 'app/i18n/src/locale/pt';

import commons from 'app/styles/commons';
import garbageMenu from 'app/images/garbageMenu.png';
import moreVert from 'app/images/moreVert.png';
import colors from 'app/styles/colors';
import api from 'app/services/api';
import { showMessage } from 'react-native-flash-message';
import { errorControl } from 'app/services/utils';

const MoodOptionsContextMenu = ({ item, type = 2, onClose, onDelete }) => {
  const [open, setOpen] = useState(false);
  const menuRef = useRef();
  const buttonRef = useRef();
  const refRBSheet = useRef();

  const hideMenu = () => menuRef.current.hide();
  const showMenu = () => menuRef.current.show(buttonRef.current);
  const removeItem = async id => {
    try {
      await api.delete('mood/' + id);
      hideMenu();
      onDelete();
    } catch (err) {
      showMessage(errorControl(err));
    }
  };

  return (
    <View style={styles.more}>
      <TouchableOpacity
        onPress={showMenu}
        ref={buttonRef}
        style={styles.button}
        hitSlop={commons.hitSlop}>
        <Image source={moreVert} style={styles.image} />
      </TouchableOpacity>

      {/* <Modal animationInTiming={600} isVisible={modal}>
        <ModalRemove
          item={item}
          onClose={() => {
            setModal(false);
            onClose();
          }}
        />
      </Modal> */}

      <Menu
        ref={menuRef}
        onHidden={() => {
          if (open) {
            setOpen(false);
            refRBSheet.current.open();
          }
        }}>
        <MenuItem onPress={() => removeItem(item.id)}>
          <MenuItemContent
            title={translate(pt.delete)}
            icon={garbageMenu}
            iconColor={colors.darkRed}
          />
        </MenuItem>
        {/* <MenuDivider /> */}
      </Menu>
    </View>
  );
};

export default MoodOptionsContextMenu;

const styles = StyleSheet.create({
  button: {
    backgroundColor: colors.greyLight4 + '7A',
    width: 22,
    height: 22,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 20,
  },
  image: {
    tintColor: colors.white,
    transform: [{ rotate: '90deg' }],
    width: 18,
    height: 18,
    resizeMode: 'contain',
  },
  more: { position: 'absolute', right: 0, top: 10 },
});
