import React, { useState, useMemo } from 'react';
import { Image } from 'react-native';

const ScaleImage = props => {
  const { uri, headers } = props.source;
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const { scale = 3 } = props;
  useMemo(
    () =>
      Image.getSizeWithHeaders(uri, headers, (w, h) => {
        setWidth(w / scale);
        setHeight(h / scale);
      }),
    [uri, headers, scale]
  );

  return <Image animation="fadeIn" {...props} style={[props.style, { width, height }]} />;
};

export default ScaleImage;
