const iconList = {
  balloon: '💭',
  grinning: '😀',
  smiley: '😃',
  happy: '😁',
  sunglasses: '😎',
  sweat: '😓',
  pensive: '😔',
  confused: '😕',
  confounded: '😖',
  stuck_out_tongue: '😛',
  stuck_out_tongue_winking_eye: '😜',
  stuck_out_tongue_closed_eyes: '😝',
  disappointed: '😞',
  worried: '😟',
  persevere: '😣',
  triumph: '😤',
  disappointed_relieved: '😥',
  frowning: '😦',
  anguished: '😧',
  fearful: '😨',
  weary: '😩',
  sleepy: '😪',
  tired_face: '😫',
  grimacing: '😬',
  sob: '😭',
  face_exhaling: '😮‍💨',
  open_mouth: '😮',
  hushed: '😯',
  cold_sweat: '😰',
  scream: '😱',
  astonished: '😲',
  flushed: '😳',
  sleeping: '😴',
  face_with_spiral_eyes: '😵‍💫',
  dizzy_face: '😵',
  face_in_clouds: '😶‍🌫️',
  no_mouth: '😶',
  mask: '😷',
  turtle: '🐢',
  pessimistic: '👎🏽',
  radiant: '🤩',
  calm: '😌',
  creative: '🤓',
  blush_hands: '🤗',
  angry: '😠',
  careless: '😜',
  sensitive: '😢',
  focused: '🎯',
  stressed: '😡',
  career: '💼',
  heart: '❤️',
  health: '💊',
  financial: '💰',
  handshake: '🤝🏽',
  house: '🏠',
  enterprise: '🏢',
  person: '👤',
};

export default iconList;
