import React, { useEffect, useState } from 'react';
import { View, StyleSheet, RefreshControl, FlatList } from 'react-native';
import moment from 'moment';
import { translate } from 'app/i18n/src/locale';
import pt from 'app/i18n/src/locale/pt';
import colors from 'app/styles/colors';
// import * as Animatable from 'react-native-animatable';

import HeaderAquamarine from 'app/components/HeaderAquamarine';
import EmptyData from 'app/screens/core/professional/components/EmptyData';
import { errorControl } from 'app/services/utils';
import TabButton from 'app/components/TabButton';
import apiNode from 'app/services/apiNode';
import LastAttendanceCard from 'app/screens/core/professional/components/LastAttendanceCard';
import MissedAttendanceCard from 'app/screens/core/professional/components/MissedAttendanceCard';
import HeaderByMonth from 'app/screens/core/mood/components/HeaderByMonth';
import { useNavigation } from '@react-navigation/native';

const TABS = {
  NEXT: 0,
  DONE: 1,
  CANCELED: 2,
};

const AttendanceHistory = ({ staff }) => {
  const navigation = useNavigation();
  const [loading, setLoading] = useState(false);
  const [attendances, setAttendances] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);
  const [currentMonth, setCurrentMonth] = useState(moment());
  const suffixes = ['', '/done', '/missed'];

  useEffect(() => {
    if (!loading) {
      getData(true, true);
    }
  }, [currentTab]);

  useEffect(() => {
    if (!loading) {
      getData(true);
    }
  }, [currentMonth]);

  const paginate = () => {
    getData();
  };

  const getData = async (clear = false, resetDate = false) => {
    const fromItem = clear ? 0 : attendances.length;
    if (!clear && fromItem % 5 !== 0) {
      return;
    }
    setLoading(true);
    if (resetDate) {
      setCurrentMonth(moment());
    }

    try {
      const { data } = await apiNode.get('/users/sessions' + suffixes[currentTab], {
        params: {
          qtd: 5,
          fromItem,
          month: currentMonth.month() + 1,
          year: currentMonth.year(),
        },
      });
      setAttendances(clear ? data : [...attendances, ...data]);
    } catch (error) {
      console.log(errorControl(error));
    }
    setLoading(false);
  };

  const renderCard = ({ item }) => {
    return currentTab === TABS.NEXT ? (
      <LastAttendanceCard
        key={item.id}
        item={item}
        reload={getData}
        style={{ marginBottom: 15 }}
        staff
      />
    ) : currentTab === TABS.DONE ? (
      <LastAttendanceCard staff key={item.id} item={item} done style={{ marginBottom: 15 }} />
    ) : (
      <MissedAttendanceCard staff key={item.id} item={item} style={{ marginBottom: 15 }} />
    );
  };

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: colors.paleGrey,
      }}>
      <HeaderAquamarine title={translate(pt.orientation_history)} />
      <View style={styles.tabsContainer}>
        <TabButton
          active={currentTab === TABS.NEXT}
          onPress={() => setCurrentTab(TABS.NEXT)}
          title={'Próximos'}
          containerStyle={{ marginLeft: 0 }}
        />
        <TabButton
          active={currentTab === TABS.DONE}
          onPress={() =>
            setCurrentTab(TABS.DONE)
          }
          title={'Completos'}
        />
        <TabButton
          active={currentTab === TABS.CANCELED}
          onPress={() => setCurrentTab(TABS.CANCELED)}
          title={'Cancelados'}
        />
      </View>

      {currentTab !== TABS.NEXT && (
        <View animation="fadeInDown" style={{ marginHorizontal: 20 }}>
          <HeaderByMonth
            month={currentMonth.locale('pt-br')}
            previousMonth={() => {
              setCurrentMonth(moment(currentMonth).subtract(1, 'month'));
            }}
            nextMonth={() => {
              setCurrentMonth(moment(currentMonth).add(1, 'month'));
            }}
            canFuture={false}
          />
        </View>
      )}

      {!loading}
      <FlatList
        refreshControl={
          <RefreshControl
            refreshing={loading}
            colors={[colors.aquaMarineTwo]}
            tintColor={colors.aquaMarineTwo}
            onRefresh={getData}
          />
        }
        contentContainerStyle={{ flexGrow: 1 }}
        onEndReached={paginate}
        data={attendances}
        renderItem={renderCard}
        ListEmptyComponent={<EmptyData title={'Nenhum atendimento realizado.'} />}
      />
    </View>
  );
};

export default AttendanceHistory;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingBottom: 16,
  },
  tabsContainer: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    marginVertical: 16,
    width: '100%',
    paddingHorizontal: 20,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 8,
    borderBottomWidth: 0.8,
    borderBottomColor: 'rgba(0,0,0,0.1)',
    paddingBottom: 8,
  },
  purchaseShadowContainer: {
    backgroundColor: colors.white,
    borderRadius: 15,
    shadowColor: colors.black,
    shadowOpacity: 0.08,
    shadowRadius: 8,
    shadowOffset: { width: 0, height: 8 },
    marginBottom: 12,
  },
  purchaseContainer: {
    borderRadius: 15,
    backgroundColor: colors.white,
    paddingHorizontal: 16,
    paddingVertical: 12,
    flexDirection: 'row',
    alignItems: 'center',
  },
});
