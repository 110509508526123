import colors from 'app/styles/colors';
import fonts from 'app/styles/fonts';
import { translate } from 'app/i18n/src/locale';
import React from 'react';
import { Image, StyleSheet, Text, View, TouchableOpacity } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import pt from 'app/i18n/src/locale/pt';
import ShadowView from '@liuyunjs/react-native-simple-shadow-view';
import ButtonBaseIcon from 'app/components/ButtonBaseIcon';
import Icon from 'react-native-vector-icons/FontAwesome';

const CardProfessionalSmall = ({ onPress, telemed }) => {
  return (
    <ShadowView style={[styles.containerShadow]}>
      <TouchableOpacity activeOpacity={0.7} onPress={onPress} style={{overflow: 'hidden'}}>
        <LinearGradient
          useAngle
          angle={135}
          colors={
            telemed
              ? [colors.white, colors.white]
              : ['#3FD1D2', '#3FD1D2', '#3FD1D2', '#4DCCCD', '#4DCCCD']
          }
          style={styles.container}>
          <View
            style={{
              marginHorizontal: 14,
              width: '80%',
              paddingTop: 14,
            }}>
            <View>
              <Text style={[styles.textTitle, { color: telemed ? colors.darkPro : colors.white }]}>
                {translate(telemed ? pt.telemedicine : pt.orientation)}
              </Text>
            </View>
            <View>
              <Text style={[styles.textSub, { color: telemed ? colors.darkPro : colors.white }]}>
                {translate(
                  telemed ? pt.realize_medical_consultations : pt.receive_up_to_min_teleorientation
                )}
              </Text>
            </View>
          </View>
        </LinearGradient>
        <Image
          source={
            telemed
              ? require('app/images/telemedicine.png')
              : require('app/images/teleorientation_widget.png')
          }
          style={[styles.image, !telemed && { transform: [{ scaleX: -1 }] }]}
        />

        <ButtonBaseIcon
          useIcon
          backgroundColor={telemed ? colors.aquaMarineTwo : colors.white}
          onPress={onPress}
          icon={
            <Icon name={'chevron-right'} color={telemed ? colors.white : colors.aquaMarineTwo} />
          }
          style={styles.buttonBase}
        />
      </TouchableOpacity>
    </ShadowView>
  );
};

const styles = StyleSheet.create({
  containerShadow: {
    position: 'relative',
    height: 220,
    width: '47%',
    borderRadius: 18,
    borderBottomLeftRadius: 4,
    backgroundColor: colors.white,
    shadowColor: '#1C6363',
    shadowOpacity: 0.2,
    shadowRadius: 5,
    shadowOffset: { width: 0, height: 5 },
    overflow: 'hidden',
  },
  container: {
    height: 220,
    width: '100%',
    borderRadius: 18,
    overflow: 'hidden',
  },
  image: {
    position: 'absolute',
    width: 160,
    height: 130,
    left: -20,
    bottom: 0,
    resizeMode: 'contain',
  },
  buttonBase: {
    position: 'absolute',
    height: 40,
    width: 40,
    right: 20,
    bottom: 20,
    borderRadius: 10,
  },
  textTitle: {
    fontFamily: fonts.BOLD,
    color: colors.white,
    fontSize: 21,
    lineHeight: 26,
  },
  textSub: {
    fontFamily: fonts.REGULAR,
    fontSize: 11,
    color: colors.white,
  },
});

export default CardProfessionalSmall;
