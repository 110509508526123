import { translate } from 'app/i18n/src/locale';
import pt from 'app/i18n/src/locale/pt';
import HeaderTitle from 'app/screens/profile/components/HeaderTitle';
import colors from 'app/styles/colors';
import fonts from 'app/styles/fonts';
import React from 'react';
import Icon from 'react-native-vector-icons/dist/FontAwesome';


import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';

const FilterResult = ({ filters, onRemove, style }) => {
  return (
    <View style={[styles.container, style]}>
      <HeaderTitle
        title={translate(pt.filter_result)}
        style={{ backgroundColor: colors.transparent }}
      />
      <View style={styles.wrapper}>
        {filters.map(el => (
          <View key={el.name} style={styles.item}>
            <Text style={styles.textStyle}>{el.name}</Text>
            {!!onRemove && (
              <TouchableOpacity onPress={() => onRemove(el)} style={{ marginLeft: 10 }}>
                <Icon name="close" size={18} color={colors.brownGrey} />
              </TouchableOpacity>
            )}
          </View>
        ))}
      </View>
    </View>
  );
};

export default FilterResult;

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    flex: 1,
    marginHorizontal: 14,
    marginTop: 10,
  },
  container: {
    marginTop: 20,
  },
  item: {
    paddingHorizontal: 15,
    paddingVertical: 7,
    borderWidth: 2,
    borderRadius: 4,
    borderColor: colors.brownGrey,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginRight: 9,
    marginBottom: 7,
  },
  textStyle: {
    fontFamily: fonts.MEDIUM,
    color: colors.brownishGreyTwo,
    // marginRight: 10,
  },
});
