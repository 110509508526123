import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Linking } from 'react-native';
import colors from 'app/styles/colors';
import textStyles from 'app/styles/textStyles';
import moment from 'moment';
import Icon from 'react-native-vector-icons/dist/FontAwesome';
import fonts from 'app/styles/fonts';
// import ProgressCircle from 'react-native-progress-circle';
import { showAlert } from 'app/services/utils';
import { translate } from 'app/i18n/src/locale';
import pt from 'app/i18n/src/locale/pt';

import apiNode from 'app/services/apiNode';

const SendingMessage = props => {
  const [documentName, setDocumentName] = useState();
  const [documentLength, setDocumentLength] = useState();
  const [, setDocumentUri] = useState();
  const [createdAt, setCreatedAt] = useState();
  const [isSending, setIsSending] = useState(false);
  const [message] = useState(props?.message);
  const [, setFullDocumentName] = useState();

  const [isDone, setIsDone] = useState(false);

  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    console.log({ percentage });
    if (percentage === 100) {
      setIsDone(true);
    }
  }, [percentage]);

  useEffect(() => {
    if (message?.isSending) {
      const name = message.name;
      setDocumentName(name.length > 20 ? name.substring(0, 16) + '...' : name);
      setDocumentLength(bytesToSize(message.size));
      setCreatedAt(message.date.format('DD/MM/YYYY HH:mm'));
      setIsSending(true);
      setPercentage(props.percentage);
      console.log('perc' + props.percentage);
    } else if (message?.file) {
      const name = message.file.name;
      console.log(message.file);
      setDocumentName(name.length > 20 ? name.substring(0, 16) + '...' : name);
      setDocumentLength(bytesToSize(message.file.contentLength));
      setCreatedAt(moment(message.file.createdAt).format('DD/MM/YYYY HH:mm'));
      setDocumentUri(message.file.path);
      setFullDocumentName(message.file.name);
    }
  }, [message]);

  function bytesToSize(bytes) {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    // eslint-disable-next-line eqeqeq
    if (bytes == 0) {
      return '0 Byte';
    }
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
  }

  const [jobDownloadId] = useState();
  const [isDownloading, setIsDownloading] = useState(false);
  const [percentageDownload] = useState(0);
  const handleDocument = async () => {
    setIsDownloading(true);
    try {
      const fileData = await apiNode.get(`/files/object/chat/${message.file.aws_key}`);

      Linking.canOpenURL(fileData.data.url).then(supported => {
        if (supported) {
          Linking.openURL(fileData.data.url);
        } else {
          console.log("Don't know how to open URI: " + fileData.data.url);
        }
      });
    } catch (error) {
      console.log('🚀 ~ file: SendingMessage.js ~ line 98 ~ error', error);
      showAlert(translate(pt.download_error));
    }
    setIsDownloading(false);
  };

  const [selectedMessage, setSelectedMessage] = useState(false);

  const styleBox = {
    paddingLeft: 120,
  };

  return (
    <TouchableOpacity
      style={selectedMessage ? { ...styleBox, backgroundColor: colors.blueRGBA } : styleBox}
      onLongPress={() => {
        if (!selectedMessage) {
          setSelectedMessage(true);
          props.onLongPress(message, true);
        }
      }}
      onPress={() => {
        if (selectedMessage) {
          setSelectedMessage(false);
          props.onLongPress(message, false);
        } else if (props.selectedMessages.length > 0) {
          setSelectedMessage(true);
          props.onLongPress(message, true);
        } else if (isSending && !isDone) {
          props.cancelMessage(message.id);
        } else if (!isDownloading) {
          handleDocument();
        } else {
          stopDownload(jobDownloadId);
          setIsDownloading(false);
        }
      }}>
      <View style={styles.container}>
        <View style={styles.receiveBox}>
          <Text style={styles.fileName} numberOfLines={1}>
            {documentName}
          </Text>
          {isSending && !isDone ? (
            <View style={styles.containerCancel}>
              {/* <ProgressCircle
                percent={percentage}
                radius={12.5}
                color={colors.blackAlpha60}
                shadowColor={colors.aquaMarineA9}
                borderWidth={2}
                bgColor={colors.aquaMarineA9}>
                <Text style={styles.cancel}>{'X'}</Text>
              </ProgressCircle> */}
            </View>
          ) : (
            <>
              {!isDownloading ? (
                <Icon name="file" size={25} color={colors.blackAlpha60} />
              ) : (
                <View style={styles.containerCancel}>
                  {/* <ProgressCircle
                    percent={percentageDownload}
                    radius={12.5}
                    color={colors.blackAlpha60}
                    shadowColor={colors.aquaMarineA9}
                    borderWidth={2}
                    bgColor={colors.aquaMarineA9}>
                    <Text style={styles.cancel}>{'X'}</Text>
                  </ProgressCircle> */}
                </View>
              )}
            </>
          )}
        </View>
        <View style={styles.infoBox}>
          <Text style={styles.textInfos}>{documentLength}</Text>
          <Text style={styles.textInfos}>{createdAt}</Text>
        </View>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    margin: 10,
    shadowOffset: { width: 2, height: 2 },
    shadowRadius: 8,
    shadowColor: colors.blackAlpha60,
    elevation: 7,
    flexDirection: 'column',
    padding: 5,
    borderRadius: 8,
    backgroundColor: colors.aquaMarineTwo,
  },
  cancel: {
    textAlign: 'center',
    color: colors.bluePro,
    fontFamily: fonts.BOLD,
    fontSize: 10,
  },
  receiveBox: {
    backgroundColor: colors.aquaMarineA9,
    borderRadius: 8,
    padding: 8,
    paddingHorizontal: 15,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  infoBox: {
    paddingHorizontal: 20,
    paddingVertical: 5,
    justifyContent: 'space-between',
    flexDirection: 'row',
    color: colors.bluePro,
    fontSize: 12,
  },
  textInfos: {
    color: colors.bluePro,
    fontSize: 12,
  },
  fileName: {
    ...textStyles.regular16,
    color: colors.bluePro,
  },
  containerCancel: {
    width: 25,
    height: 25,
    borderRadius: 999,
    borderWidth: 1.5,
    borderColor: colors.bluePro,
    alignItems: 'center',
    justifyContent: 'center',
  },
  receiveImage: {
    marginTop: '-10%',
    marginLeft: '-12%',
    width: 46,
    height: 46,
    borderRadius: 50,
  },
  receiveText: {
    fontSize: 14,
    color: colors.white,
    maxWidth: 260,
    paddingHorizontal: 8,
  },
});

export default SendingMessage;
