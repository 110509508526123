import React, { useEffect, useState } from 'react';

import { View, Text, TouchableOpacity, Image, StyleSheet } from 'react-native';

import textStyles from '../../../../styles/textStyles';
import { translate } from '../../../../i18n/src/locale';
import pt from '../../../../i18n/src/locale/pt';
import closeGrey from '../../../../images/closeGrey.png';
import colors from '../../../../styles/colors';
import Strong from '../../../../components/Strong';
import ButtonBase from '../../../../components/ButtonBase';
import fonts from '../../../../styles/fonts';
import { Slider } from '@miblanchard/react-native-slider';
import { errorControl, showAlert } from 'app/services/utils';
import api from 'app/services/api';

const BottomSheetMuscle = ({ item, onPress, onClose }) => {
  const [level, setLevel] = useState(0);
  const [levelsData, setLevelsData] = useState([]);
  const [maxValue, setMaxValue] = useState(0);

  useEffect(() => {
    requestPainLevel();
  }, []);

  useEffect(() => {
    if (levelsData.length > 0) {
      setMaxValue(levelsData.length - 1);
    }
  }, [levelsData]);

  const requestPainLevel = async () => {
    try {
      const { data } = await api.get('pain-level');
      setLevelsData(data);
    } catch (error) {
      showAlert(errorControl(error));
    }
  };

  return (
    <View style={styles.bottomSheet}>
      <View style={styles.header}>
        <Text style={textStyles.subtitle}>
          {translate(pt.category_pain)}
          <Strong>{item?.name}</Strong>
        </Text>
        <TouchableOpacity onPress={onClose}>
          <Image source={closeGrey} />
        </TouchableOpacity>
      </View>
      <View style={{ paddingHorizontal: 20, paddingVertical: 10 }}>
        <View style={{ justifyContent: 'space-between', flexDirection: 'row' }}>
          <Text style={textStyles.regular14}>{levelsData[0]?.name}</Text>
          <Text style={[textStyles.regular14, { textAlign: 'right' }]}>
            {levelsData[levelsData.length - 1]?.name}
          </Text>
        </View>

        <View style={styles.slider}>
          <View style={styles.ballStart} />
          <View style={styles.ballEnd} />
          <Slider
            value={level}
            onValueChange={value => setLevel(value)}
            maximumValue={maxValue}
            step={1}
            minimumTrackTintColor={colors.aquaMarine}
            trackStyle={styles.track}
            thumbStyle={styles.thumb}
          />
        </View>
        <View style={styles.infoHolder}>
          <Text style={styles.info}>{levelsData[level]?.description}</Text>
        </View>
      </View>
      <ButtonBase
        onPress={() => {
          onPress(levelsData[level]);
        }}
        backgroundColor={colors.aquaMarineTwo}
        fill
        square
        title={translate(pt.procede)}
      />
    </View>
  );
};

export default BottomSheetMuscle;

const styles = StyleSheet.create({
  bottomSheet: { paddingTop: 10, backgroundColor: colors.paleGrey },
  header: {
    height: 60,
    paddingHorizontal: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: colors.paleGrey,
  },
  info: {
    fontFamily: fonts.REGULAR,
    fontSize: 15,
    textAlign: 'center',
    color: colors.greyBlue,
  },
  infoHolder: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 57,
    borderRadius: 37.5,
    marginTop: 16,
    padding: 8,
    backgroundColor: colors.white,
  },
  ballEnd: {
    right: 0,
    position: 'absolute',
    backgroundColor: colors.veryLightPink,
    borderRadius: 8,
    width: 16,
    height: 16,
  },
  ballStart: {
    position: 'absolute',
    backgroundColor: colors.aquaMarine,
    borderRadius: 8,
    width: 16,
    height: 16,
  },
  slider: { justifyContent: 'center', marginTop: 10 },
  thumb: {
    elevation: 4,
    backgroundColor: colors.white,
    borderRadius: 8,
    width: 16,
    height: 16,
    shadowColor: colors.black,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.35,
    shadowRadius: 2,
  },
  track: {
    backgroundColor: colors.veryLightPink,
    height: 5,
  },
});
