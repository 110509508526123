import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import colors from 'app/styles/colors';
import textStyles from 'app/styles/textStyles';
import CapsuleDayNight from 'app/components/CapsuleDayNight';

const ModalInfo = () => {
  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <View style={styles.capsule}>
          <Text style={textStyles.bold14}>Seu próximo treino</Text>
        </View>
        <CapsuleDayNight />
      </View>
      <Text style={styles.info}>Nome ou título do treino XPTO</Text>
    </View>
  );
};

export default ModalInfo;

const styles = StyleSheet.create({
  header: { flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' },
  info: { ...textStyles.semi16, marginLeft: 8 },
  capsule: {
    alignSelf: 'baseline',
    alignItems: 'center',
    justifyContent: 'center',
    height: 29,
    borderRadius: 22.5,
    backgroundColor: colors.whiteTwo,
    paddingHorizontal: 16,
    marginBottom: 8,
  },
  container: {
    padding: 8,
    height: 93,
    borderTopWidth: 6,
    borderTopColor: colors.aquaMarine,
    borderRadius: 12,
    backgroundColor: colors.white,
  },
});
