import ButtonBase from 'app/components/ButtonBase';
import colors from 'app/styles/colors';
import fonts from 'app/styles/fonts';
import { translate } from 'app/i18n/src/locale';
import React, { useState } from 'react';
import { Image, StyleSheet, Text, View } from 'react-native';
import pt from 'app/i18n/src/locale/pt';
import { useSelector } from 'react-redux';
// import { Actions } from 'react-native-router-flux';
import ShadowView from '@liuyunjs/react-native-simple-shadow-view';
import { debounce } from 'lodash';
import PERFORMANCE from 'app/configs/performance';
import { useNavigation } from '@react-navigation/native';

const CardManuChat = () => {
  const navigation = useNavigation();
  
  const openChat = async () => {
    navigation.navigate('Manuchat');
  };

  return (
    <ShadowView style={styles.shadowContainer}>
      <View style={styles.container}>
        <View style={{ alignItems: 'flex-start', marginLeft: 23 }}>
          <View style={styles.wrapper}>
            <Text style={styles.textProfessional}>{translate(pt.manu_chatgpt)}</Text>
          </View>
          <View style={{ width: '45%' }}>
            <Text style={styles.textContent}>{translate(pt.manuchat_widget_message)}</Text>
          </View>
        </View>
        <ButtonBase
          backgroundColor={colors.aquaMarineTwo}
          onPress={debounce(() => {
            openChat();
          }, PERFORMANCE.ON_PRESS_DEBOUNCE_TIME)}
          style={styles.buttonBase}
          textStyle={{ color: colors.white }}
          title={translate(pt.talk_to_manu)}
        />
        <Image source={require('app/images/manugpt.png')} style={styles.image} />
      </View>
    </ShadowView>
  );
};

const styles = StyleSheet.create({
  shadowContainer: {
    borderRadius: 12,
    marginHorizontal: 20,
    backgroundColor: colors.white,
    shadowColor: colors.black,
    shadowOpacity: 0.1,
    shadowRadius: 5,
    shadowOffset: { width: 0, height: 5 },
  },
  container: {
    flex: 1,
    borderRadius: 10,
    paddingVertical: 17,
    backgroundColor: colors.white,
    overflow: 'hidden',
  },
  image: {
    position: 'absolute',
    zIndex: -1,
    right: 20,
    bottom: 30,
    height: 130,
    width: 160,
    resizeMode: 'contain',
  },
  textNew: {
    textTransform: 'uppercase',
    fontFamily: fonts.BOLD,
    color: colors.white,
    fontSize: 10,
  },
  card: {
    marginTop: 6,
    borderRadius: 10,
    backgroundColor: colors.aquaMarineTwo + '99',
    paddingVertical: 3,
    paddingHorizontal: 12,
    alignItems: 'center',
  },
  buttonBase: {
    height: 40,
    marginRight: 17,
    paddingHorizontal: 20,
    borderRadius: 10,
    alignSelf: 'flex-end',
    shadowColor: colors.black,
    shadowOpacity: 0.1,
    shadowRadius: 5,
    shadowOffset: { width: 0, height: 5 },
  },
  wrapper: {
    width: '60%',
    marginVertical: 6,
  },
  textProfessional: {
    fontFamily: fonts.BOLD,
    color: colors.darkPro,
    fontSize: 21,
    lineHeight: 26,
  },
  textContent: {
    fontFamily: fonts.REGULAR,
    fontSize: 13,
    color: colors.darkPro,
  },
});

export default CardManuChat;
