import React from 'react';

import { View, StyleSheet, Text, Image } from 'react-native';
import colors from '../../../../styles/colors';
import { translate } from '../../../../i18n/src/locale';
import pt from '../../../../i18n/src/locale/pt';
import textStyles from '../../../../styles/textStyles';
import warningIcon from '../../../../images/warningIcon.png';
import Chip from '../../../../components/Chips';

const CardRestriction = ({ restrictions }) => (
  <View style={styles.container}>
    <View style={styles.header}>
      <Text style={[textStyles.bold12White, { marginRight: 10, flex: 1 }]}>
        {translate(pt.plate_has_restrictions)}
      </Text>
      <Image source={warningIcon} />
    </View>
    <View style={styles.content}>
      {restrictions.map(restriction => {
        return <Chip title={restriction.name} />;
      })}
    </View>
  </View>
);

export default CardRestriction;

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    paddingHorizontal: 14,
    paddingVertical: 10,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    backgroundColor: colors.yellowishOrange,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: colors.orangeYellow,
  },
  container: {
    marginTop: 20,
  },
  content: {
    backgroundColor: colors.white,
    borderBottomLeftRadius: 2,
    borderBottomRightRadius: 2,
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingTop: 14,
    paddingHorizontal: 14,
    paddingBottom: 8,
  },
});
