import { Alert, Dimensions, PixelRatio, Platform } from 'react-native';
import APP from 'app/configs/app';
import { translate, translateByCode } from 'app/i18n/src/locale';
import pt from 'app/i18n/src/locale/pt';
import momentTimezone from 'moment-timezone';
import api from 'app/services/api';
import QueryString from 'qs';
import { setModalNotInternetVisible } from '../store/modules/user/actions';
import { store } from '../store/';
import { RFValue } from 'react-native-responsive-fontsize';
import apiNode from 'app/services/apiNode';
import { formatNumber } from 'react-native-currency-input';
import { getMeta } from 'app/services/apiMetas';
import META from 'app/configs/meta';

const errorMessage = message => null;

const successMessage = message => null;

const infoMessage = message => null;

const userIMC = async () => {
  let weight = await getMeta(META.USER_WEIGHT);
  let height = await getMeta(META.USER_HEIGHT);

  if (weight && height) {
    weight = parseFloat(weight[0].meta_value);
    height = parseFloat(height[0].meta_value) / 100;

    const imc = (weight / (height * height)).toFixed(1);

    return Number(imc);
  } else {
    return 0;
  }
};

const showAlert = msg => {
  if (msg !== 'Network Error') {
    Alert.alert(APP.NAME, msg);
  }
};

const confirmMessage = (msg, action) => {
  Alert.alert(
    APP.NAME,
    msg,
    [
      {
        text: 'OK',
        onPress: () => {
          action();
        },
      },
    ],
    { cancelable: false }
  );
};

const errorControl = (error, functionName) => {
  console.log(error);
  if (functionName) {
    console.log(`${functionName} error`);
  }
  if (error.message === 'Network Error') {
    flashConnection(false);
    return 'Network Error';
  }

  try {
    const { data } = error.response;
    const { message } = data;
    if (message) {
      if (typeof message === 'string' || message instanceof String) {
        return message;
      } else if (typeof message === 'object') {
        const values = Object.values(message);
        const key = values[0][0];

        return translateByCode(key);
      } else {
        return translate(pt.unknow_error);
      }
    } else {
      return translate(pt.unknow_error);
    }
  } catch (e) {
    return translate(pt.unknow_error);
  }
};

const toFilterString = str => {
  let string = str;
  const mapaAcentosHex = {
    a: /[\xE0-\xE6]/g,
    e: /[\xE8-\xEB]/g,
    i: /[\xEC-\xEF]/g,
    o: /[\xF2-\xF6]/g,
    u: /[\xF9-\xFC]/g,
    c: /\xE7/g,
    n: /\xF1/g,
  };

  for (const letra in mapaAcentosHex) {
    const expressaoRegular = mapaAcentosHex[letra];
    string = string.replace(expressaoRegular, letra);
  }

  return string.toUpperCase();
};

const formatMoneyBR = number => {
  if (!number || number == null || number === 0) {
    return 'R$0,00';
  }
  return `R$${number
    .toFixed(2)
    .replace('.', ',')
    .replace(/(\d)(?=(\d{3})+,)/g, '$1.')}`;
};

const calcDuration = (time = 0, options = {}) => {
  let hours, minutes, seconds;
  const timeUnit = options.timeUnit || '';
  const timeSuffixExpanded = options.timeSuffixExpanded || false;
  switch (timeUnit) {
    case 'h':
      return `${time} ${timeSuffixExpanded ? 'horas' : 'h'}`;
    case 'min':
      if (time < 60) {
        return `${time} ${timeSuffixExpanded ? 'minutos' : 'min'}`;
      }
      hours = Math.floor(time / 60);
      minutes = Math.floor((time - hours * 60) / 60);
      return `${hours}${minutes > 0 ? `:${minutes}` : ''} ${timeSuffixExpanded ? 'horas' : 'h'}`;
    default:
      seconds = Math.floor(time);
      hours = Math.floor(time / 3600);
      minutes = Math.floor((seconds - hours * 3600) / 60);

      if (hours > 0) {
        return `${hours}${minutes > 0 ? `:${minutes}` : ''} ${timeSuffixExpanded ? 'horas' : 'h'}`;
      }
      if (minutes > 0) {
        return `${minutes} ${timeSuffixExpanded ? 'minutos' : 'min'}`;
      }

      return `${seconds} ${timeSuffixExpanded ? 'segundos' : 'seg'}`;
  }
};

const calcDurationNumber = (seconds = 0) => {
  const convertedSeconds = Math.floor(seconds % 60);
  const minutes = seconds / 60;
  const convertedMinutes = Math.floor(minutes);
  return `${convertedMinutes.toString().padStart(2, '0')}:${convertedSeconds
    .toString()
    .padStart(2, '0')}`;
};

const flashInfo = message => {
  console.log('flashInfo');
};

const flashConnection = (currentConnectionState = true) => {
  store.dispatch(setModalNotInternetVisible(!currentConnectionState));
};

const getTypeActivity = idCategory => {
  if (idCategory === 1) {
    return 'meal';
  }
  if (idCategory === 2) {
    return 'activity';
  }
  return 'meditation';
};

const shareItem = async ({ screen, title, image, description, id, defaultShare = false }) => {
  console.log(image);

  let options;

  if (defaultShare) {
    const url =
      Platform.OS === 'android'
        ? 'https://play.google.com/store/apps/details?id=br.com.namu&hl=pt'
        : 'https://apps.apple.com/br/app/namu-wellness/id1544610300';
    const appName = 'Namu';
    const message = !hasParams
      ? 'Estou melhorando minha rotina de hábitos saudáveis com o aplicativo Namu. Baixe o App você também.'
      : `Estou melhorando minha rotina de hábitos saudáveis com a ${type} de ${name} no aplicativo da Namu. Baixe o App você também.`;
    const icon = 'data:<data_type>/<file_extension>;base64,<base64_data>';

    options = Platform.select({
      ios: {
        activityItemSources: [
          {
            placeholderItem: { type: 'url', content: url },
            item: {
              default: { type: 'text', content: `${message} ${url}` },
            },
          },
          {
            placeholderItem: {
              type: 'url',
              content: icon,
            },
            item: {
              default: {
                type: 'text',
                content: `${message} ${url}`,
              },
            },
            linkMetadata: {
              title: appName,
              icon: icon,
            },
          },
        ],
      },
      default: {
        title: appName,
        subject: appName,
        message: `${message} ${url}`,
      },
    });
  } else {
    const shortLink = await createShortLink({
      image,
      query: `?k=${screen}&id=${id}`,
      title: title,
      descriptionText: description,
    });

    options = {
      title,
      subject: description,
      message: `Veja essa aula comigo!\n${shortLink}`,
    };
  }

  try {
    if (id === undefined) {
      sendActionEvent('share_app');
    } else {
      sendActionEvent('share_id_' + { id: id });
    }
  } catch (error) {
    console.log('Error =>', error);
  }
};

function normalize(size, based = 'width') {
  const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get('window');
  const wscale = SCREEN_WIDTH / 375;
  const hscale = SCREEN_HEIGHT / 667;
  const newSize = based === 'height' ? size * hscale : size * wscale;
  return Math.round(PixelRatio.roundToNearestPixel(newSize));
}

const sendActionEvent = async (eventName, data = {}, email) => {
  try {
    analytics().setUserId(String(email));
    await analytics().logEvent(eventName, data);
  } catch (e) {
    console.log(e);
  }
};

const getFormattedDate = (date, format = 'DD/MMM') => {
  return momentTimezone(date).tz('America/Sao_Paulo').format(format);
};

const deleteFcmToken = async () => {
  try {
    await messaging().deleteToken();
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const sendSessionHistoryEvent = async (
  id,
  sessionId,
  sessionHistoryType,
  isUserPro = false,
  text = null
) => {
  const sessionHistoryData = {
    userId: !isUserPro ? id : null,
    userProId: isUserPro ? id : null,
    sessionId: sessionId,
    type: sessionHistoryType,
  };

  if (text) {
    sessionHistoryType.text = text;
  }

  try {
    await apiNode.post('session-histories', sessionHistoryData);
    return true;
  } catch (error) {
    console.log('SendSessionHistoryEvent: ', error.response);
    return false;
  }
};

const toCurrencyFormat = (number, showSymbol = false) => {
  return formatNumber(Number(number ? number : 0), {
    separator: ',',
    prefix: showSymbol ? `${pt.currency_type} ` : undefined,
    precision: 2,
    delimiter: '.',
    signPosition: 'beforePrefix',
  });
};

const isObjectEmpty = object => {
  if (!object) {
    return true;
  }
  return Object.keys(object).length === 0;
};

const explodeQuery = query => {
  return query?.split('&').reduce((obj, curr) => {
    const [key, value] = curr.split('=');

    return key && value
      ? {
          ...obj,
          [key]: value,
        }
      : obj;
  }, {});
};

const explodeURL = url => {
  const [base, query] = url.split('?');
  return {
    base,
    query: explodeQuery(query) || {},
  };
};

const buildURL = ({ base, query }) => {
  return `${base}?${Object.entries(query)
    .map(pair => pair.join('='))
    .join('&')}`;
};

const getProfessionalColorName = categoryId => {
  const areas = {
    1: 'red',
    2: 'orange',
    3: 'blue',
    6: 'green',
    7: 'pink',
  };

  return areas[categoryId];
};

const createShortLink = async ({
  link = 'https://namu.com.br/beneficios-app-namu',
  title,
  descriptionText,
  image,
  query,
  suffix,
}) => {
  try {
    const shortLink = await dynamicLinks().buildShortLink(
      {
        link: link + query,
        domainUriPrefix: 'https://app.namu.com.br',
        social: {
          title,
          descriptionText,
          imageUrl: image,
        },
        ios: {
          bundleId: 'br.com.namu.app',
          appStoreId: '1544610300',
        },
        android: {
          packageName: 'br.com.namu',
        },
        navigation: {
          forcedRedirectEnabled: true,
        },
      },
      dynamicLinks.ShortLinkType.SHORT
    );

    return shortLink;
  } catch (err) {
    console.log('ERRR', err);
  }
};

// returns array with all possible times between the first and the second parameter in intervals of 30 minutes
const toInt = time => ((h, m) => h * 4 + m / 15)(...time.split(':').map(parseFloat)),
  toTime = int =>
    [Math.floor(int / 4), (int % 4) * 15].map(n => n.toString().padStart(2, '0')).join(':'),
  range = (from, to) =>
    Array(to - from + 1)
      .fill()
      .map((_, i) => from + i),
  eachQuarterHour = (t1, t2) => range(...[t1, t2].map(toInt)).map(toTime);
// how to use: eachQuarterHour(initialTime, Final time), Example: eachQuarterHour("06:00", "22:00")

const formatDistance = distance => {
  if (!distance) {
    return 0;
  }

  if (distance.toString().length > 3) {
    return (distance / 1000).toFixed(1) + ' km';
  }

  return distance + ' m';
};

const checkRequirements = (data, requirements) => {
  if (!requirements) {
    return true;
  }
  for (const requirement of requirements) {
    const isNot = requirement.startsWith('!');
    const keys = requirement.replace('!', '').split('.');

    let value = data;
    for (const key of keys) {
      if (!(key in value) && !isNot) {
        return false;
      }
      value = value[key];
    }
    if (isNot === value) {
      return false;
    }
  }
  return true;
};

export {
  errorMessage,
  confirmMessage,
  errorControl,
  successMessage,
  infoMessage,
  showAlert,
  toFilterString,
  formatMoneyBR,
  calcDuration,
  flashInfo,
  getTypeActivity,
  shareItem,
  sendActionEvent,
  getFormattedDate,
  deleteFcmToken,
  flashConnection,
  calcDurationNumber,
  RFValue,
  sendSessionHistoryEvent,
  toCurrencyFormat,
  isObjectEmpty,
  explodeQuery,
  explodeURL,
  buildURL,
  normalize,
  getProfessionalColorName,
  createShortLink,
  eachQuarterHour,
  formatDistance,
  userIMC,
  checkRequirements,
};
