import RowMood from 'app/screens/core/mood/components/RowMood';
import colors from 'app/styles/colors';
import fonts from 'app/styles/fonts';
import moment from 'moment';
import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

const CardMoodByDay = ({ date, moods, onDelete }) => {
  const dateTitle = moment(date).subtract(0, 'd').format('ddd, D [de] MMMM');
  return (
    <View style={styles.container}>
      <Text style={styles.title}>{dateTitle}</Text>

      <View style={{ marginTop: 15 }}>
        {moods.map(({ id, mood, cause, created_at }, index) => (
          <>
            <RowMood
              mood={{
                id,
                icon: mood.icon,
                name: mood.name,
                reason_name: cause.name,
                reason_icon: cause.icon,
                time: moment(created_at).format('HH[h]mm'),
              }}
              onDelete={onDelete}
            />
            {index + 1 !== moods.length && <View style={styles.separatorRowMood} />}
          </>
        ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    borderRadius: 8,
    backgroundColor: colors.white,
    paddingVertical: 18,
    paddingHorizontal: 23,
    marginBottom: 15,
  },
  title: {
    fontFamily: fonts.MEDIUM,
    fontSize: 16,
    color: colors.darkPro2,
  },
  separatorRowMood: {
    height: 20,
    width: 1,
    marginLeft: 16,
    backgroundColor: '#D7D7D7',
  },
});

export default CardMoodByDay;
