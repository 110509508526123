import React from 'react';

import { StyleSheet, Text, Image, View } from 'react-native';
import fonts from 'app/styles/fonts';
import colors from 'app/styles/colors';
import clock from 'app/images/clockNew.png';
import ShadowView from '@liuyunjs/react-native-simple-shadow-view';
import pt from 'app/i18n/src/locale/pt';
import { translate } from 'app/i18n/src/locale';
import { calcDuration } from 'app/services/utils';

const CapsuleClockCard = ({ style, isWeek, timeIn = 's', duration, textColor, gradientColors }) => {
  if (gradientColors) {
    return (
      <View style={styles.containerGradient}>
        <Image
          style={{ 
            tintColor: textColor ?? colors.grayText, 
            resizeMode: 'contain',  
            width: 15,
            height: 10 
          }}
          source={clock}
        />
        <Text style={[styles.capsuleText, { color: textColor ?? colors.grayText }]}>
          {isWeek ? translate(pt.weeks, { num: duration }) : calcDuration(duration)}
        </Text>
      </View>
    );
  }
  return (
    <ShadowView style={[styles.capsule, style]}>
      <Image
        style={{ tintColor: textColor ?? colors.grayText, resizeMode: 'contain', width: 15,
        height: 10 }}
        source={clock}
        
      />
      <Text style={[styles.capsuleText, { color: textColor ?? colors.grayText }]}>
        {isWeek
          ? translate(pt.weeks, { num: duration })
          : calcDuration(duration, { timeUnit: timeIn })}
      </Text>
    </ShadowView>
  );
};

export default CapsuleClockCard;

const styles = StyleSheet.create({
  capsuleText: {
    marginLeft: 2,
    fontFamily: fonts.BOLD,
    fontSize: 10,
    color: colors.grayText,
    textTransform: 'lowercase',
  },
  capsule: {
    paddingHorizontal: 4,
    flexDirection: 'row',
    alignItems: 'center',
    height: 18,
    borderWidth: 1,
    borderColor: '#F2F2F2',
    borderRadius: 4,
  },
  containerGradient: {
    borderColor: '#DBF5F559',
    borderWidth: 1,
    borderRadius: 4,
    overflow: 'hidden',
    flexDirection: 'row'
  },
  gradient: {
    paddingHorizontal: 8,
    paddingVertical: 2,
    flexDirection: 'row',
    alignItems: 'center',
  },
});
