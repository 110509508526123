import colors from 'app/styles/colors';
import fonts from 'app/styles/fonts';
import { translate } from 'app/i18n/src/locale';
import React, { useCallback, useEffect } from 'react';
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import pt from 'app/i18n/src/locale/pt';
// import { Actions } from 'react-native-router-flux';
import ShadowView from '@liuyunjs/react-native-simple-shadow-view';
import ButtonBaseIcon from 'app/components/ButtonBaseIcon';
import Icon from 'react-native-vector-icons/dist/FontAwesome';
import NamuPass from 'app/images/namupass.svg';
import { useNavigation } from '@react-navigation/native';
// import { PERMISSIONS, checkMultiple, requestMultiple } from 'react-native-permissions';

const CardGymWidget = () => {
  const navigation = useNavigation();
  // useEffect(() => {
  //   verifyPermissions();
  // }, []);

  // const verifyPermissions = useCallback(() => {
  //   if (Platform.OS === 'android') {
  //     requestMultiple([PERMISSIONS.ANDROID.ACCESS_FINE_LOCATION]).then(statuses => {
  //       console.log('REQUEST', statuses);
  //     });

  //     checkMultiple([PERMISSIONS.ANDROID.ACCESS_FINE_LOCATION]).then(statuses => {
  //       console.log('CHECK', statuses);
  //     });
  //   } else {
  //     requestMultiple([PERMISSIONS.IOS.LOCATION_WHEN_IN_USE]).then(statuses => {});
  //     checkMultiple([PERMISSIONS.IOS.LOCATION_WHEN_IN_USE]).then(statuses => {});
  //   }
  // }, []);

  return (
    <TouchableOpacity onPress={() => navigation.navigate('Academias')}>
      <ShadowView style={styles.shadowContainer}>
        <View style={styles.wrapper}>
          <LinearGradient
            colors={[colors.aquaMarineLighter, colors.aquaMarineTwo]}
            useAngle
            angle={270}
            style={styles.container}>
            <Image source={require('app/images/gym_woman.png')} style={styles.image} />

            <View style={{ flex: 1, marginVertical: 18, alignItems: 'flex-start' }}>
              <LinearGradient
                colors={[colors.white, '#ffffff59']}
                useAngle
                angle={90}
                style={styles.logoContainer}>
                <Image source={NamuPass} style={{width: 92, height: 30, resizeMode: 'contain'}} />
              </LinearGradient>
              <View style={{ width: '60%' }}>
                <Text style={styles.textContent}>{translate(pt.gym_widget_message)}</Text>
              </View>
            </View>
            <ButtonBaseIcon
              useIcon
              backgroundColor={colors.white}
              icon={<Icon name={'chevron-right'} color={colors.aquaMarineTwo} />}
              style={styles.buttonBase}
              onPress={() => navigation.navigate('Academias')}
            />
          </LinearGradient>
        </View>
      </ShadowView>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  shadowContainer: {
    marginHorizontal: 20,
    backgroundColor: colors.white,
    borderRadius: 10,
    shadowColor: colors.black,
    shadowOpacity: 0.1,
    shadowRadius: 5,
    shadowOffset: { width: 0, height: 5 },
  },
  container: {
    flex: 1,
    paddingHorizontal: 20,
    borderRadius: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: colors.aquaMarineTwo,
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
  },
  wrapper: {
    overflow: 'hidden',
    borderRadius: 10,
  },
  logoContainer: {
    paddingHorizontal: 25,
    paddingVertical: 10,
    borderRadius: 10,
  },
  card: {
    borderRadius: 10,
    backgroundColor: colors.blackTwo + '1A',
    paddingVertical: 3,
    paddingHorizontal: 12,
    alignItems: 'center',
  },
  buttonBase: {
    height: 40,
    width: 40,
    position: 'absolute',
    right: 20,
    borderRadius: 10,
    shadowColor: colors.black,
    shadowOpacity: 0.2,
    shadowRadius: 5,
    shadowOffset: { width: 0, height: 5 },
  },
  image: {
    aspectRatio: 1.314,
    width: '50%',
    height: '100%',
    position: 'absolute',
    // resizeMode: 'contain',
    right: 0,
  },
  textContent: {
    fontFamily: fonts.MEDIUM,
    fontSize: 13,
    lineHeight: 17,
    marginTop: 10,
    color: colors.white,
  },
});

export default CardGymWidget;
