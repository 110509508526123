import produce from 'immer';

const INITIAL_STATE = {
  trailsMeal: [],
  meals: [],
  recipes: [],
  onboardingCompleted: 0,
  filters: {},
  filtersFormated: [],
  typeMeal: '',
  isHowToLandScape: false,
};

export default function meal(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@meal/SET_MEAL_FILTER': {
        draft.filters = action.payload.filters;
        break;
      }
      case '@meal/SET_TRAILS_MEAL': {
        draft.trailsMeal = action.payload.trailsMeal;
        break;
      }
      case '@meal/SET_MEALS': {
        draft.meals = action.payload.meals;
        break;
      }
      case '@meal/SET_RECIPES': {
        draft.recipes = action.payload.recipes;
        break;
      }
      case '@meal/SET_ONBOARDING_COMPLETED': {
        draft.onboardingCompleted = action.payload.onboardingCompleted;
        break;
      }
      case '@meal/SET_TYPE_MEAL_FILTER': {
        draft.typeMeal = action.payload.typeMealFilter;
        break;
      }
      case '@meal/SET_VIDEO_HOW_TO_LANDSCAPE': {
        draft.isHowToLandScape = action.payload.isHowToLandScape;
        break;
      }
      case '@meal/SET_MEAL_FILTER_FORMATED': {
        draft.filtersFormated = action.payload.filtersFormated;
        break;
      }
      default:
        return state;
    }
  });
}
