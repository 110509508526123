import React, { useState } from 'react';
import { View, Text, Image, TouchableOpacity, Dimensions, StyleSheet } from 'react-native';
import ButtonBase from '../../../components/ButtonBase';
import colors from '../../../styles/colors';
import closeImage from '../../../images/closeGrey.png';
import commons from '../../../styles/commons';
import { translate } from 'app/i18n/src/locale';

import pt from 'app/i18n/src/locale/pt';
import fonts from 'app/styles/fonts';
import emptySelected from '../../../images/chechScheduleFalse.png';
import selectedIcon from '../../../images/chechScheduleTrue.png';

const { height } = Dimensions.get('window');

const BottomSheetScheduling = ({ onClose, serviceId, selectService }) => {
  const [packagesDiscount] = useState([
    { id: 0, packageCount: 0, discount: null },
    { id: 1, packageCount: 2, discount: 15 },
    { id: 2, packageCount: 4, discount: 20 },
    { id: 3, packageCount: 8, discount: 25 },
  ]);

  return (
    <View style={styles.containerBottomSheet}>
      <View style={styles.topContainer}>
        <Text style={styles.title}>{translate(pt.service_type)}</Text>
        <TouchableOpacity onPress={onClose} hitSlop={commons.hitSlop}>
          <Image source={closeImage} />
        </TouchableOpacity>
      </View>
      <View style={styles.packageContent}>
        {packagesDiscount.map(packagesDiscountMap => {
          return (
            <TouchableOpacity
              key={packagesDiscountMap.packageCount}
              activeOpacity={1}
              onPress={() => {
                selectService(packagesDiscountMap.id);
              }}
              style={styles.itemContainer}>
              <Text style={{ fontFamily: fonts.BOLD, marginStart: 16 }}>
                {packagesDiscountMap.packageCount
                  ? translate(pt.attendance_package, {
                      package: packagesDiscountMap.packageCount,
                    })
                  : translate(pt.attendance_unique)}
              </Text>
              {packagesDiscountMap.discount && (
                <View style={styles.offContainer}>
                  <Text style={styles.offTextStyle}>
                    {translate(pt.off, {
                      discount: packagesDiscountMap.discount,
                    })}
                  </Text>
                </View>
              )}
              <Image
                source={serviceId === packagesDiscountMap.id ? selectedIcon : emptySelected}
                style={styles.selectedImage}
              />
            </TouchableOpacity>
          );
        })}
        <ButtonBase title={translate(pt.confirm)} style={styles.buttonStyle} onPress={onClose} />
      </View>
    </View>
  );
};

export default BottomSheetScheduling;

const styles = StyleSheet.create({
  containerBottomSheet: {
    borderTopEndRadius: 47,
    borderTopStartRadius: 47,
  },
  topContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    marginTop: 14,
  },
  title: {
    textAlign: 'center',
    flex: 0.8,
    fontFamily: fonts.BOLD,
    fontSize: 18,
  },
  packageContent: {
    width: '80%',
    alignSelf: 'center',
    marginTop: height / 30,
  },
  itemContainer: {
    width: '100%',
    borderWidth: 1,
    height: '14%',
    alignItems: 'center',
    marginTop: 6,
    borderRadius: 4,
    borderColor: colors.aquaMarineTwo,
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  offContainer: {
    backgroundColor: colors.aquaMarineTwo,
    borderRadius: 59,
    justifyContent: 'center',
  },
  offTextStyle: {
    fontSize: 7,
    color: colors.white,
    fontFamily: fonts.BOLD,
    padding: 4,
  },
  selectedImage: { resizeMode: 'contain', marginEnd: 16 },
  buttonStyle: { width: '100%', height: height / 8 / 2 },
});
