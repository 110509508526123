import React from 'react';
import { View, StyleSheet, Text } from 'react-native';
import Slider from '@react-native-community/slider';
import colors from 'app/styles/colors';
import { calcDurationNumber } from 'app/services/utils';
import fonts from 'app/styles/fonts';

const ProgressBarNew2 = ({
  currentTime,
  duration,
  onSlideStart,
  onSlideComplete,
  showTrack,
  containerStyle,
}) => {
  return (
    <View style={[styles.wrapper, containerStyle]}>
      <View style={styles.flexRow}>
        <Text style={[styles.textTime, { color: colors.white }]}>
          {calcDurationNumber(currentTime)}
        </Text>
        <Slider
          style={{ flex: 1 }}
          value={Math.floor(currentTime)}
          minimumValue={0}
          maximumValue={duration}
          onSlidingStart={onSlideStart}
          onSlidingComplete={onSlideComplete}
          minimumTrackTintColor={colors.white}
          maximumTrackTintColor={colors.greyBlue}
          thumbImage={''}
          thumbTintColor={showTrack ? colors.aquaMarineTwo : colors.transparent}
        />
        <Text style={styles.textTime}> {calcDurationNumber(duration)}</Text>
      </View>
    </View>
  );
};
export default ProgressBarNew2;

const styles = StyleSheet.create({
  textTime: {
    fontFamily: fonts.BOLD,
    fontSize: 12,
    color: colors.greyBlue,
  },
  flexRow: {
    flexDirection: 'row',
  },
  subContainer: {
    flex: 1,
    marginHorizontal: 18,
    zIndex: 999,
    flexDirection: 'row',
  },
  container: {
    // flex: 1,
    // marginHorizontal: 18,
    // position: 'absolute',
    // zIndex: 1,
    // top: 0,
    // left: 0,
    // right: 0,
    // bottom: 0,
  },
  wrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'transparent',
    paddingHorizontal: 10,
    width: 450,
  },
  time: {
    fontSize: 12,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.white,
  },
  timeRight: {
    fontSize: 16,
    color: '#FFFFFF',
    textAlign: 'right',
    paddingRight: 10,
  },
});
