import React, { useState } from 'react';
import { View, StyleSheet, Text, Image, TouchableOpacity, ActivityIndicator } from 'react-native';

import Stars from 'app/components/Stars';
import Icon from 'react-native-vector-icons/FontAwesome';

import colors from 'app/styles/colors';
import textStyles from 'app/styles/textStyles';

import iconFavedEmpty from '../../../../images/heart.png';
import iconFavedFull from '../../../../images/favedFull.png';
import noPhotoImage from '../../../../images/no-photo.jpg';

import { useSelector, useDispatch } from 'react-redux';
import apiNode from 'app/services/apiNode';
import { useEffect } from 'react';
import { doReloadFavorites } from 'app/store/modules/user/actions';
import CapsuleHour from 'app/components/CapsuleHour';
import { translate } from 'app/i18n/src/locale';
import pt from 'app/i18n/src/locale/pt';

import flagPt from '../../../../images/bandeira.png';
import flagEn from '../../../../images/usFlag.png';
import flagSp from '../../../../images/esFlag.png';
import { toCurrencyFormat } from 'app/services/utils';
import { useNavigation } from '@react-navigation/native';

const ProfessionalData = ({
  userProId,
  registryName,
  showAddionalData = false,
  userProRatings = true,
  setUser,
}) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const [favorite, setFavorite] = useState(false);
  const [favoriteId, setFavoriteId] = useState();
  const [ratings, setRatings] = useState(null);

  const { profile, reloadFavorites } = useSelector(state => state.user);
  const [favLoading, setFavLoading] = useState(false);
  const [userPro, setUserPro] = useState(null);

  const userInfo = async () => {
    setFavLoading(true);
    try {
      const { data } = await apiNode.get(
        `users-pro/${userProId}?userId=${profile.user_id}&${reloadFavorites}`
      );
      // console.log({ data });
      setUser(data);
      setUserPro(data);
      setFavorite(data?.userFavorite?.favorited ? true : false);
      setFavoriteId(data?.userFavorite?.favorited ? data?.userFavorite?.id : null);

      if (userProRatings === true) {
        setRatings(data.ratingStars);
      } else if (userProRatings !== null && typeof userProRatings === 'number') {
        setRatings(userProRatings);
      } else {
        setRatings(null);
      }
    } catch (error) {
      console.log('UserInfo error: ', error.response);
    }
    setFavLoading(false);
  };

  useEffect(() => {
    if (!!userProId && !!profile) {
      userInfo();
    }
  }, [userProId, profile, reloadFavorites, userProRatings]);

  const toggleHeart = async () => {
    setFavLoading(true);
    try {
      if (!favorite) {
        const sendObject = {
          userId: profile.user_id,
          userProId: userProId,
        };

        const { data } = await apiNode.post('/user-favorites-user-pro', sendObject);
        setFavorite(true);
        setFavoriteId(data.id);
      } else {
        try {
          await apiNode.delete(`/user-favorites-user-pro/${favoriteId}`);
          setFavorite(false);
          setFavoriteId(null);
        } catch (error) {
          console.log('DELETE FAV: ', error.response);
        }
      }

      dispatch(doReloadFavorites());
    } catch (error) {
      console.log(error);
    }
    //setFavLoading(false);
  };

  function getFlagIcon(language) {
    if (language === 'Português') {
      return flagPt;
    } else if (language === 'Inglês') {
      return flagEn;
    } else if (language === 'Espanhol') {
      return flagSp;
    }
  }

  return (
    <>
      {userPro ? (
        <>
          <View style={styles.professionalContainer}>
            <TouchableOpacity
              style={styles.imageContainer}
              onPress={() => {
                navigation.navigate('ProfessionalProfile', { userProId })
              }}>
              <Image
                source={userPro.profileImageUrl ? { uri: userPro.profileImageUrl } : noPhotoImage}
                style={styles.photoStyle}
              />
            </TouchableOpacity>
            <View style={styles.infosContainer}>
              <View style={styles.header}>
                <View style={styles.nameContainer}>
                  <Text style={[textStyles.bold16]}>{userPro.name}</Text>
                </View>
                <View>
                  {!favLoading ? (
                    <TouchableOpacity onPress={toggleHeart} disabled={favLoading}>
                      <Image
                        source={favorite === true ? iconFavedFull : iconFavedEmpty}
                        style={{ height: 21.57, width: 23.53 }}
                      />
                    </TouchableOpacity>
                  ) : (
                    <ActivityIndicator size={'small'} animating color={colors.aquaMarineTwo} />
                  )}
                </View>
              </View>
              <View style={styles.starsContainer}>
                <Stars
                  disabled={true}
                  default={ratings}
                  count={5}
                  fullStar={<Icon name={'star'} size={20} color={'#FFA000'} style={styles.stars} />}
                  emptyStar={
                    <Icon name={'star-o'} size={20} color={'#D7D7D7'} style={styles.stars} />
                  }
                />
                <Text style={[textStyles.regular11, styles.minorText]}>
                  {userProRatings === true ? `(${userPro.ratingCount})` : null}
                </Text>
              </View>
            </View>
          </View>
          {showAddionalData && (
            <View style={styles.showAddionalData}>
              <View style={styles.languageContainer}>
                <Text style={textStyles.bold14}>{translate(pt.languages)}</Text>
                <View style={styles.flags}>
                  {userPro.languages.map(lang => (
                    <Image
                      key={lang}
                      source={getFlagIcon(lang.language.name)}
                      style={{ height: 20, width: 20, borderRadius: 50, marginRight: 5 }}
                    />
                  ))}
                </View>
                <View>
                  <CapsuleHour
                    timeIn={'min'}
                    duration={userPro.sessionDurationMinutes}
                    style={styles.capsule}
                  />
                </View>
              </View>
              <View style={styles.valuesContainer}>
                <Text style={textStyles.bold14}>{translate(pt.service_value)}</Text>
                <Text style={textStyles.bold16grey}>
                  {toCurrencyFormat(userPro.sessionHourPrice, true)}
                </Text>
              </View>
            </View>
          )}
        </>
      ) : (
        <ActivityIndicator
          animating
          size={'small'}
          color={colors.aquaBlue}
          style={{ marginTop: 15, alignSelf: 'center' }}
        />
      )}
    </>
  );
};

export default ProfessionalData;

const styles = StyleSheet.create({
  professionalContainer: {
    marginTop: 10,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomWidth: 0.8,
    borderBottomColor: 'rgba(0,0,0,0.1)',
    paddingBottom: 12,
    //borderWidth: 2,
  },
  infosContainer: {
    //borderWidth: 1,
    //width: '100%',
    marginHorizontal: 8,
    flex: 1,
  },
  imageContainer: {},
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  nameContainer: {
    width: '85%',
  },
  starsContainer: {
    maxWidth: 115,
    marginVertical: 3,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    // opacity: 0,
  },
  minorText: {
    color: '#676C88',
    fontSize: 12,
  },
  blueText: {
    color: colors.aquaMarineTwo,
  },
  description: {
    color: colors.greyBlue,
    maxWidth: 180,
    minHeight: 5,
    backgroundColor: colors.red,
  },
  category: {
    color: colors.greyBlue,
    maxWidth: 180,
  },
  onlineIndicator: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    paddingHorizontal: 2.5,
    paddingVertical: 2.5,
  },
  photoStyle: {
    width: 75,
    height: 75,
    resizeMode: 'cover',
    borderRadius: 100,
  },

  showAddionalData: {
    marginTop: 10,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomWidth: 0.8,
    borderBottomColor: 'rgba(0,0,0,0.1)',
    paddingBottom: 0,
  },

  languageContainer: {
    // flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    //borderWidth: 1,
    width: '100%',
  },
  flags: {
    flex: 1,
    flexDirection: 'row',
    //justifyContent: 'space-around',
    marginLeft: 10,
  },
  capsule: {
    backgroundColor: '#f9f9f9',
  },
  valuesContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 8,
    //borderWidth: 1,
    //borderBottomColor: 'rgba(0,0,0,0.1)',
    paddingBottom: 16,
    paddingRight: 5,
    width: '100%',
  },
  valuesHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 14,
    //borderWidth: 1,
  },
});
