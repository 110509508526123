import HeaderAquamarine from 'app/components/HeaderAquamarine';
import { translate } from 'app/i18n/src/locale';
import pt from 'app/i18n/src/locale/pt';
import apiNode from 'app/services/apiNode';
import colors from 'app/styles/colors';
import fonts from 'app/styles/fonts';
import React, { useEffect, useCallback, useState } from 'react';
import { StyleSheet, Text, ScrollView, View, Platform, ActivityIndicator, Modal } from 'react-native';
import ShadowView from '@liuyunjs/react-native-simple-shadow-view';
import ButtonBase from 'app/components/ButtonBase';
import ButtonLink from 'app/components/ButtonLink';
import MyMultiSelectView from 'app/components/MyMultiSelectView';
import { errorControl, showAlert } from 'app/services/utils';
import ModalSaveFilter from 'app/screens/core/explorer/components/ModalSaveFilter';
import _ from 'lodash';
// import {  } from 'react-native-paper';

const categories = {
  food: {
    id: 1,
    name: 'Alimentação',
  },
  body: {
    id: 2,
    name: 'Corpo',
  },
  mind: {
    id: 3,
    name: 'Mente',
  },
};

const FilterProgramsScreen = ({
  open,
  onClose,
  filters,
  onChangeCategory,
  onPressFilter,
  categoryId,
  headerColors = [],
  filterId,
  color,
}) => {
  const [initialFilters] = useState(filters);
  const [currentFilters, setCurrentFilters] = useState({});
  const [filterSectionNames, setFilterSectionNames] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [categorySelected, setCategorySelected] = useState(null);
  const [subcategoriesByCategory, setSubcategoriesByCategory] = useState({});

  useEffect(() => {
    requestInitialFilters();
  }, []);

  useEffect(() => {
    if (open) {
      setCurrentFilters(filters);
      setCategorySelected(
        filters.categories.some(x => x.active) ? filters.categories.findIndex(x => x.active) : null
      );
    }
  }, [open]);

  useEffect(() => {
    setCurrentFilters(prev => {
      let newFilters = _.clone(prev);
      switch (categorySelected) {
        case 0:
          newFilters.subcategories = subcategoriesByCategory.food;
          break;
        case 1:
          newFilters.subcategories = subcategoriesByCategory.body;
          break;
        case 2:
          newFilters.subcategories = subcategoriesByCategory.mind;
          break;
        default:
          newFilters.subcategories = [];
      }
      return newFilters;
    });
  }, [categorySelected]);

  useEffect(() => {
    const newFilterSectionNames = Object.keys(initialFilters).filter(name => name !== 'filter');
    setFilterSectionNames(newFilterSectionNames);
    setCurrentFilters(initialFilters);
  }, [initialFilters]);

  const requestInitialFilters = useCallback(async () => {
    try {
      setLoading(true);
      const { data: foodData } = await apiNode.get(
        `/classe-filters/?categoryId=${categories.food.id}`
      );
      const { data: bodyData } = await apiNode.get(
        `/classe-filters/?categoryId=${categories.body.id}`
      );
      const { data: mindData } = await apiNode.get(
        `/classe-filters/?categoryId=${categories.mind.id}`
      );

      setSubcategoriesByCategory({
        food: foodData.subcategories,
        body: bodyData.subcategories,
        mind: mindData.subcategories,
      });

      setLoading(false);
    } catch (error) {
      showAlert(errorControl(error));
      setLoading(false);
    }
  }, [categoryId, filterId]);

  const handleSelectionChange = (status, index, sectionName) => {
    const newFilters = _.clone(currentFilters);

    const newSection = newFilters[sectionName].map((item, itemIndex) => {
      if (sectionName === 'categories') {
        if (itemIndex !== index) {
          return { ...item, ...{ active: false } };
        } else {
          return { ...item, ...{ active: status } };
        }
      }

      if (itemIndex !== index) {
        return item;
      }
      if (itemIndex === index) {
        const newValue = { ...item, ...{ active: status } };
        return newValue;
      }
    });

    newFilters[sectionName] = newSection;
    setCurrentFilters(newFilters);

    if (sectionName === 'categories') {
      onChangeCategory(
        newSection.some(x => x.active === true)
          ? categories[Object.keys(categories)[newSection.findIndex(x => x.active === true)]].id
          : null
      );
      setCategorySelected(
        newSection.some(x => x.active === true)
          ? newSection.findIndex(x => x.active === true)
          : null
      );
    }
  };

  const desactiveCapsule = props => {
    return (
      <ShadowView style={[styles.card_choice, { borderColor: 'transparent' }]}>
        <Text style={[styles.text_choice, { color: colors.greyBlue }]}>{props.item.name}</Text>
      </ShadowView>
    );
  };

  const activeCapsule = props => {
    return (
      <ShadowView
        style={[
          styles.card_choice,
          { backgroundColor: getColorBase(), borderColor: getColorBase() },
        ]}>
        <Text style={[styles.text_choice, { color: colors.white }]}>{props.item.name}</Text>
      </ShadowView>
    );
  };

  const handlePressClear = () => {
    let emptyFilters = { ...currentFilters };
    Object.keys(currentFilters).map(sectionName => {
      emptyFilters[sectionName] = emptyFilters[sectionName].map(option => {
        return { ...option, active: false };
      });
    });
    setCurrentFilters(emptyFilters);
    setCategorySelected(null);
    onChangeCategory(null);
  };

  const getColorBase = () => {
    if (categorySelected === 0) {
      return colors.darkRed;
    }
    if (categorySelected === 2) {
      return colors.brightOrange;
    }
    return colors.aquaMarineTwo;
  };

  const handleSaveFilter = async filterName => {
    try {
      await apiNode.post('/user-classe-filter', {
        categoryId,
        name: filterName,
        description: filterName,
        filters: currentFilters,
      });
      requestSavedFilters();
    } catch (error) {
      showAlert(errorControl(error));
    }
  };

  return (
    <Modal style={styles.modalContainer} visible={open} onBackdropPress={onClose}>
      <View style={{ flex: 1, backgroundColor: colors.paleGrey }}>
        <HeaderAquamarine
          onPress={onClose}
          title="Filtro"
          noPadding={Platform.OS === 'android' ? true : false}
          textStyle={styles.headerText}
          useGradient={headerColors.length > 1}
          gradientColors={headerColors}
          color={color}
        />
        <View style={{ flex: 1, backgroundColor: colors.paleGrey, paddingTop: 25 }}>
          <ScrollView style={styles.container}>
            {loading ? (
              <View style={styles.flexCenter}>
                <ActivityIndicator size="large" color={colors.aquaMarineTwo} />
              </View>
            ) : (
              <>
                {filterSectionNames.map(
                  sectionName =>
                    currentFilters[sectionName].length > 0 && (
                      <View>
                        <Text style={styles.sectionTitle}>{translate(pt[sectionName])}</Text>
                        <View>
                          <MyMultiSelectView
                            checkedKey={'active'}
                            valueKey={'name'}
                            style={{ marginTop: 11, marginBottom: 30, flex: 1 }}
                            onSelectionStatusChange={(status, index) =>
                              handleSelectionChange(status, index, sectionName)
                            }
                            data={currentFilters[sectionName]}
                            desactiveView={desactiveCapsule}
                            activeView={activeCapsule}
                          />
                        </View>
                      </View>
                    )
                )}
              </>
            )}
          </ScrollView>
          <View style={styles.containerButtons}>
            <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
              <ButtonBase
                title={translate(pt.filter)}
                backgroundColor={getColorBase()}
                fill
                onPress={() => onPressFilter(currentFilters, categorySelected + 1)}
              />
            </View>
            <View
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 15,
                marginBottom: 36,
              }}>
              <ButtonLink title={translate(pt.clean_filter)} underline onPress={handlePressClear} />
            </View>
          </View>
          <ModalSaveFilter
            open={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            color={color}
            onSaveFilter={handleSaveFilter}
          />
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modalContainer: {
    margin: 0,
  },
  headerText: {
    color: colors.white,
  },
  container: {
    paddingHorizontal: 12,
    flex: 1,
  },
  dropdown: {
    borderRadius: 30,
    paddingHorizontal: 32,
  },
  dropdownText: {
    fontFamily: fonts.REGULAR,
    fontSize: 12,
    color: colors.greyBlue,
  },
  sectionTitle: {
    fontSize: 16,
    color: colors.slateGrey,
    fontFamily: fonts.BOLD,
    textTransform: 'uppercase',
    marginBottom: 10,
  },
  card_choice: {
    borderWidth: 1,
    marginRight: 12,
    marginBottom: 8,
    height: 37,
    borderRadius: 30,
    backgroundColor: colors.white,
    alignContent: 'center',
    justifyContent: 'center',
    paddingHorizontal: 16,
  },
  text_choice: {
    fontSize: 12,
    fontFamily: fonts.REGULAR,
  },
  containerButtons: {
    paddingHorizontal: 12,
  },
  buttonClear: {
    backgroundColor: 'transparent',
    borderColor: colors.greyBlue,
    borderWidth: 1,
  },
  textButtonClear: {
    color: colors.greyBlue,
  },
  flexCenter: { flex: 1, alignItems: 'center', justifyContent: 'center' },
});

export default FilterProgramsScreen;
