import React, { useState } from 'react';

import { View, StyleSheet, Text, TouchableOpacity, Image } from 'react-native';
import colors from '../styles/colors';
import commons from '../styles/commons';
import textStyles from '../styles/textStyles';
import checkedOrange from '../images/checkedOrange.png';

const RowCheckRight = ({ item, checked, onPress, index }) => {
  return (
    <TouchableOpacity
      onPress={() => {
        onPress(index);
      }}
      style={[
        styles.container,
        { backgroundColor: checked ? colors.aquaMarineTwo : colors.white },
      ]}>
      <View style={commons.flexRowBetween}>
        <Text style={[textStyles.semi16, { color: checked ? colors.white : colors.blackTwo }]}>
          {item.title}
        </Text>
        {checked && <Image style={{ tintColor: colors.white }} source={checkedOrange} />}
        {!checked && (
          <View
            style={{
              width: 16,
              height: 16,
              borderRadius: 8,
              borderStyle: 'solid',
              borderWidth: 1,
              borderColor: colors.aquaMarineTwo,
            }}
          />
        )}
      </View>
    </TouchableOpacity>
  );
};

export default RowCheckRight;

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 15,
    paddingVertical: 14,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: colors.veryLightPinkFour,
    backgroundColor: colors.white,
    marginBottom: 9,
  },
});
