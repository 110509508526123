import React, { useState } from 'react';

import { View, StyleSheet, Text, TouchableOpacity, Image } from 'react-native';
import colors from '../styles/colors';
import commons from '../styles/commons';
import textStyles from '../styles/textStyles';
import checkBoxOutlineBlank from '../images/checkBoxOutlineBlank.png';
import checkBox from '../images/checkBox.png';

const RowCheck = ({ item, onPress, index }) => {
  return (
    <TouchableOpacity
      onPress={() => {
        onPress(index);
      }}
      style={[
        styles.container,
        { backgroundColor: item.isSelected ? colors.aquaMarine : colors.white },
      ]}>
      <View style={commons.flexRowBetween}>
        <Text
          style={[textStyles.semi16, { color: item.isSelected ? colors.white : colors.blackTwo }]}>
          {item.name}
        </Text>
        <Image source={item.isSelected ? checkBox : checkBoxOutlineBlank} />
      </View>
      {item.isSelected && item.user_pain_level !== null && (
        <Text style={textStyles.regular14White}>{item.user_pain_level.name}</Text>
      )}
    </TouchableOpacity>
  );
};

export default RowCheck;

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 15,
    paddingVertical: 14,
    borderRadius: 5,
    backgroundColor: colors.white,
    marginBottom: 15,
  },
});
