import React, { useState, useCallback, useEffect } from 'react';
import { View, StyleSheet, Dimensions, TouchableOpacity, Image } from 'react-native';
import moment from 'moment';
import colors from 'app/styles/colors';
import ShadowView from '@liuyunjs/react-native-simple-shadow-view';
import chevronRight from 'app/images/chevronRight.png';
import chevronLeft from 'app/images/chevronLeft.png';
import { Text } from 'react-native-animatable';
import textStyles from 'app/styles/textStyles';
import api from 'app/services/api';
import { useDispatch } from 'react-redux';
import { getWaterIntakes } from 'app/services/apiWaterIntakes';
import { updateProfileSuccess } from 'app/store/modules/user/actions';

const { width } = Dimensions.get('screen');

const MonthSlider = () => {
  const [month, setMonth] = useState(moment());
  const dispatch = useDispatch();

  useEffect(() => {
    requestHistoryMonth();
  }, [requestHistoryMonth, month]);

  const requestHistoryMonth = useCallback(async () => {
    const { data: user } = await api.get('user');
    const water_intakes_result = await getWaterIntakes(moment(month).startOf('month'));
    user.monthly_history = water_intakes_result.monthly_history;
    user.sum_of_the_month = water_intakes_result.sum_of_the_month[0].ml
      ? water_intakes_result.sum_of_the_month[0].ml
      : 0;
    dispatch(updateProfileSuccess(user));
  }, [dispatch, month]);

  const nextMonth = () => {
    setMonth(moment(month).add(1, 'month'));
  };

  const previousMonth = () => {
    setMonth(moment(month).subtract(1, 'month'));
  };

  return (
    <ShadowView style={styles.container}>
      <View style={styles.month}>
        <TouchableOpacity onPress={previousMonth}>
          <Image source={chevronLeft} style={{ width: 20, height: 20 }} />
        </TouchableOpacity>

        <View>
          <Text style={[textStyles.semi16]}>{moment(month).format('MMMM YYYY')}</Text>
        </View>

        <TouchableOpacity onPress={nextMonth}>
          <Image source={chevronRight} style={{ width: 20, height: 20 }} />
        </TouchableOpacity>
      </View>
    </ShadowView>
  );
};

export default MonthSlider;

const styles = StyleSheet.create({
  container: {
    shadowColor: colors.blackTwo,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    backgroundColor: colors.white,
    shadowRadius: 18,
    shadowOpacity: 0.16,
    zIndex: 66,
    width: '100%'
  },
  month: {
    marginTop: 10,
    width: '100%',
    height: 30,
    marginBottom: 10,
    alignItems: 'center',
    paddingHorizontal: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});
