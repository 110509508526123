import React, { useRef, useEffect, useState, useCallback } from 'react';

import {
  View,
  StyleSheet,
  StatusBar,
  Text,
  TouchableOpacity,
  Image,
  Linking,
  Dimensions,
} from 'react-native';

import * as Animatable from 'react-native-animatable';
import { SafeAreaView } from 'react-native-safe-area-context';
import textStyles from '../../styles/textStyles';
import colors from '../../styles/colors';
import commons from '../../styles/commons';
import { translate } from '../../i18n/src/locale';
import pt from '../../i18n/src/locale/pt';
import RBSheet from 'react-native-raw-bottom-sheet';
import FacebookButton from './components/FacebookButton';
import fonts from 'app/styles/fonts';
import ButtonBase from 'app/components/ButtonBase';
import LogoSvg from 'app/components/icons/LogoSvg';
import intro1 from 'app/images/intro1.png';
import intro2 from 'app/images/intro2.png';
import intro3 from 'app/images/intro3.png';
import intro4 from 'app/images/intro4.png';
import CustomIcon from 'app/styles/icon';
import { useNavigation } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../services/api';
import EVENTS from '../../configs/events';
import QueryString from 'qs';
import logo_splash from 'app/lotties/logo_splash.json';
import AnimatedLottieView from 'lottie-react-native';
import { updateToken, resetAuth } from '../../store/modules/auth/actions';
import AsyncStorage from '@react-native-async-storage/async-storage';

const introImages = [intro1, intro2, intro3, intro4];
const introImage = introImages[Math.floor(Math.random() * introImages.length)];

const CreateAccountScreen = () => {
  const [isLoading, setIsLoading] = useState(true);

  const facebookButton = useRef(null);
  const googleButton = useRef(null);
  const central = useRef(null);
  const navigation = useNavigation();
  const companyRef = new URLSearchParams(window.location.search).get('company');
  const token = new URLSearchParams(window.location.search).get('token');
  const dispatch = useDispatch();

  const { loading, signed, token: userToken } = useSelector(state => state.auth);

  const handleInitialCheck = async () => {
    try {
      if (signed) {
        if (!!token && token !== userToken) {
          dispatch(resetAuth());
          dispatch(updateToken(token));
        }

        const savedCompany = await AsyncStorage.getItem('companyName');
        if (!!companyRef && !savedCompany) {
          AsyncStorage.setItem('companyName', companyRef);
        }
  
        if (companyRef !== savedCompany) {
          dispatch(resetAuth());
          AsyncStorage.setItem('companyName', companyRef);
          
          return;
        }

        const { data: goCore } = await api.post(
          'user-ui-event/filter',
          QueryString.stringify({
            ui_event_name: EVENTS.ONBOARDING,
            status: 1,
          })
        );
        
        navigation.navigate(goCore ? 'Main' : 'Onboarding');

        return;
      }

      if ((!companyRef && !token) || (token && !loading && !signed)) {
        navigation.navigate('NotLogged');

        return;
      };

      if (token) {
        dispatch(updateToken(token));
      }
    } catch (e) {
      console.log({e});
    }
    
    if (companyRef && !signed) {
      setIsLoading(false);
    }    
  };

  useEffect(() => {
    handleInitialCheck();
  }, [loading, signed]);

  if (isLoading) {
    return (
      <View style={[commons.flex, { backgroundColor: colors.paleGrey, justifyContent: 'center' }]}>
        <AnimatedLottieView
          source={logo_splash}
          autoPlay
          loop
          style={styles.logo}
        />
      </View>
    )
  }

  return (
    <View style={[commons.flex, { width: '100%' }]}>
      <StatusBar backgroundColor="transparent" translucent barStyle="light-content" />
      <View style={[commons.flex, { backgroundColor: colors.greyF5 }]}>
        <SafeAreaView style={[commons.flexCenter, { width: '100%' }]}>
          <LogoSvg width={135} style={{ marginTop: 10, alignSelf: 'center' }} />
          <Animatable.View
            ref={central}
            delay={0}
            style={styles.center}
            useNativeDriver
            animation="fadeInUp">
            <Image source={introImage} style={{ width: 260, height: 260, resizeMode: 'contain' }} />
            <Text style={styles.title}>{translate(pt.create_namu_account)}</Text>

            <ButtonBase
              onPress={() => {
                // if (!companyName) {
                //   navigation.navigate('NotLogged');

                //   return;
                // };
                
                navigation.navigate('Login', { method: 'enterprise', from: 'personal' });
              }}
              useIcon
              icon={<CustomIcon name={'user'} size={20} />}
              style={styles.buttonPersonal}
              textStyle={{ textAlign: 'left' }}
              title={translate(pt.create_account)}
            />

            <Text style={styles.terms}>
              Ao clicar em "Criar conta" você concorda com nossos{' '}
              <Text
                style={styles.termsLink}
                onPress={() => {
                  Linking.openURL('https://namu.com.br/termos-e-condicoes-de-uso-aplicativo/');
                }}>
                Termos
              </Text>{' '}
              e{' '}
              <Text
                style={styles.termsLink}
                onPress={() => {
                  Linking.openURL('https://namu.com.br/politica-de-privacidade-do-app/');
                }}>
                Política de privacidade
              </Text>
            </Text>
            <View style={styles.view_account}>
              <Text style={[textStyles.semi16, { color: '#444242' }]}>
                {translate(pt.already_account)}
              </Text>

              <TouchableOpacity
                hitSlop={{ bottom: 50, top: 50, left: 50, right: 50 }}
                onPress={() => {
                  navigation.navigate('Login', { method: 'login' });
                }}>
                <Text style={styles.button_now}>{translate(pt.login_now)}</Text>
              </TouchableOpacity>
            </View>
          </Animatable.View>
        </SafeAreaView>
        <RBSheet ref={facebookButton}>
          <FacebookButton />
        </RBSheet>
        <RBSheet ref={googleButton}>
          <FacebookButton />
        </RBSheet>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  button_now: {
    fontFamily: fonts.BOLD,
    fontSize: 16,
    color: colors.aquaMarineTwo,
    zIndex: 2,
    marginLeft: 5,
  },
  title: {
    fontSize: 18,
    fontFamily: fonts.BOLD,
    color: '#444242',
    marginVertical: 16,
  },
  view_account: {
    width: '100%',
    marginTop: 30,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    position: 'absolute',
    bottom: 30,
  },
  center: {
    flex: 1,
    paddingTop: 30,
    paddingHorizontal: 34,
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: "100%",
  },
  buttonPersonal: {
    width: "95%",
    height: 'auto',
    paddingVertical: 13,
    marginTop: 0,
    marginBottom: 15,
    marginHorizontal: 37,
  },
  terms: {
    color: '#B7B7B7',
    fontSize: 14,
    fontFamily: fonts.MEDIUM,
    textAlign: 'center',
    width: "80%"
  },
  termsLink: {
    fontFamily: fonts.BOLD,
    color: '#B7B7B7',
    textDecorationLine: 'underline',
  },
  logo: {
    width: 250,
    alignSelf: 'center',
  },
});

export default CreateAccountScreen;
