import colors from 'app/styles/colors';
import commons from 'app/styles/commons';
import React, { useCallback, useEffect, useState } from 'react';
import { StyleSheet, View, FlatList, Text, ActivityIndicator } from 'react-native';
import ShadowView from '@liuyunjs/react-native-simple-shadow-view';
import { debounce } from 'lodash';

import PERFORMANCE from 'app/configs/performance';
import Category from './components/Category';
import CardBlog from './components/BlogPost';
import { showAlert, errorControl, sendActionEvent } from 'app/services/utils';
import api from 'app/services/api';
import SearchBar from 'app/screens/core/forum/components/SearchBar';
import { useSelector } from 'react-redux';
import { SafeAreaView } from 'react-native-safe-area-context';
import textStyles from 'app/styles/textStyles';
import Spacer from 'app/components/Spacer';
import HandleActivity from 'app/screens/core/activities/components/HandleActivity';
import { useGamification } from 'app/hooks/useGamification';

const BlogScreen = () => {
  const [indexCat, setIndexCat] = useState(0);
  const [searching, setSearching] = useState(false);
  const [loading, setLoading] = useState(false);

  const categories = [
    {
      cat_name: 'Todas',
      key: 'all_blog',
      image_path: require('app/images/all_cats.png'),
    },
    {
      cat_name: 'Favoritos',
      key: 'favorite_blog',
      image_path: require('app/images/favorites-cat.png'),
    },
    {
      cat_name: 'Alimentação',
      key: 'food_blog',
      i18n_locale: 'pt-BR',
      id: 1,
      image_path: require('app/images/food-cat.png'),
      wp_cat_id: 15,
    },
    {
      cat_name: 'Corpo e Mente',
      key: 'body_and_mind_blog',
      i18n_locale: 'pt-BR',
      id: 2,
      image_path: require('app/images/bodymind-cat.png'),
      wp_cat_id: 1008,
    },
    {
      cat_name: 'Estética',
      key: 'aesthetics_blog',
      i18n_locale: 'pt-BR',
      id: 3,
      image_path: require('app/images/estetica-cat.png'),
      wp_cat_id: 1016,
    },
    {
      cat_name: 'Saúde',
      key: 'health_blog',
      i18n_locale: 'pt-BR',
      id: 4,
      image_path: require('app/images/saude-cat.png'),
      wp_cat_id: 1082,
    },
    {
      cat_name: 'Filosofia',
      key: 'philosophy_blog',
      i18n_locale: 'pt-BR',
      id: 5,
      image_path: require('app/images/filosofia-cat.png'),
      wp_cat_id: 1019,
    },
    {
      cat_name: 'Diversão e Viagens',
      key: 'fun_and_travel_blog',
      i18n_locale: 'pt-BR',
      id: 6,
      image_path: require('app/images/diversao-cat.png'),
      wp_cat_id: 1010,
    },
    {
      cat_name: 'Sustentabilidade',
      key: 'sustainability_blog',
      i18n_locale: 'pt-BR',
      id: 7,
      image_path: require('app/images/sustentabilidade-cat.png'),
      wp_cat_id: 1089,
    },
  ];

  const [idSubCategorySelected, setCategorySelected] = useState(0);
  const [query, setQuery] = useState('');
  const [blogs, setBlogs] = useState([]);
  const [filteredBlog, setFilteredBlog] = useState([]);
  const { profile } = useSelector(state => state.user);
  const { eventsGenerationByHandleActivity } = useGamification();

  const [page, setPage] = useState(1);

  useEffect(() => {
    if (query.length > 3) {
      setSearching(true);
      requestBlogPostSearch();
    } else {
      setSearching(false);
      setLoading(false);
    }
  }, [query, requestBlogPostSearch]);

  const getAllCategoriesIds = useCallback(() => {
    const arrayIds = categories
      .filter(category => {
        return category.wp_cat_id !== undefined;
      })
      .map(categoryMap => {
        return categoryMap.wp_cat_id;
      });
    return arrayIds.toString();
  }, [categories]);

  const handleChangeCategory = async (index, categoryId) => {
    if (index === 1) {
      setCategorySelected('favorite');
      await requestFavorites();
    } else {
      if (index === 0) {
        setCategorySelected(getAllCategoriesIds());
      } else {
        setCategorySelected(categoryId);
      }

      if (page === 1) {
        requestByCategory(index === 0 ? getAllCategoriesIds() : categoryId);
      }
    }

    setPage(1);
  };

  useEffect(() => {
    if (idSubCategorySelected !== 'favorite') {
      requestByCategory(indexCat === 0 ? getAllCategoriesIds() : idSubCategorySelected);
    }
  }, [page]);

  const handleLoadMore = async () => {
    setPage(page + 1);
  };

  const requestByCategory = useCallback(
    async categoryId => {
      try {
        const response = await api.get(
          `blog/posts/last-by-category?wp_cat_id=${categoryId}&page=${page}`
        );

        if (page === 1) {
          setBlogs(response.data.all_posts);
        } else {
          setBlogs([...blogs, ...response.data.all_posts]);
        }
      } catch (error) {
        console.log(error.response);
        showAlert(errorControl(error));
      }
    },
    [page, idSubCategorySelected]
  );

  const requestFavorites = async () => {
    try {
      const response = await api.get('blog/posts/favorites');
      setBlogs(response.data.all_posts);
    } catch (error) {
      showAlert(errorControl(error));
    }
  };

  const callbackFunction = childData => {
    setQuery(childData);
  };

  const requestBlogPostSearch = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get(`blog/post/search?search_by=${query}`);
      if (Object.values(response.data)[0] === query) {
        showAlert(`Nada encontrado para: ${query}`);
        setFilteredBlog([]);
        return;
      }
      if (Object.values(response.data).length > 0) {
        setFilteredBlog(Object.values(response.data));
      }
    } catch (error) {
      showAlert(errorControl(error));
    }
    setLoading(false);
  }, [query]);

  const categoryPress = async (index, catId) => {
    setIndexCat(index);
    await handleChangeCategory(index, catId);
  };

  const onPressCategoryButton = async (item, index) => {
    try {
      setLoading(true);
      await categoryPress(index, item.wp_cat_id);
      let text = item.cat_name;
      var mapaAcentosHex = {
        a: /[\xE0-\xE6]/g,
        e: /[\xE8-\xEB]/g,
        i: /[\xEC-\xEF]/g,
        o: /[\xF2-\xF6]/g,
        u: /[\xF9-\xFC]/g,
        c: /\xE7/g,
        n: /\xF1/g,
        _: / /g,
      };
      for (var letra in mapaAcentosHex) {
        var expressaoRegular = mapaAcentosHex[letra];
        text = text.replace(expressaoRegular, letra);
      }
      sendActionEvent('blog_category_' + text, { email: profile.email }, profile.email);
      const { data } = await HandleActivity({ routeName: item?.key });
      eventsGenerationByHandleActivity(data);
      setLoading(false);
    } catch (error) {}
  };

  return (
    <View style={{ ...styles.container }}>
      <SafeAreaView style={commons.flex}>
        <ShadowView style={styles.topShadow}>
          <View style={styles.titleBar}>
            <Text style={styles.title}>Blog</Text>
          </View>
          <SearchBar parentCallback={callbackFunction} />
        </ShadowView>

        <View style={styles.categories}>
          <FlatList
            horizontal
            showsHorizontalScrollIndicator={false}
            contentContainerStyle={{ paddingTop: 17, paddingHorizontal: 27 }}
            data={categories}
            keyExtractor={item => {
              item.cat_name;
            }}
            renderItem={({ item, index }) => {
              return (
                <Category
                  category={item}
                  index={index}
                  indexCat={indexCat}
                  onPress={debounce(
                    () => onPressCategoryButton(item, index),
                    PERFORMANCE.ON_PRESS_DEBOUNCE_TIME
                  )}
                />
              );
            }}
          />
        </View>

        {loading ? (
          <ActivityIndicator
            size={'small'}
            color={colors.aquaMarineTwo}
            style={{ marginTop: 50 }}
          />
        ) : (
          <FlatList
            contentContainerStyle={{ backgroundColor: colors.paleGrey }}
            renderItem={({ item, index }) => {
              return (
                <>
                  <CardBlog post={item} categoryId={idSubCategorySelected} />
                  {blogs.length - 1 === index && <Spacer value={60} />}
                </>
              );
            }}
            onEndReached={handleLoadMore}
            ListFooterComponent={
              idSubCategorySelected !== 'favorite' && (
                <ActivityIndicator
                  size={'small'}
                  color={colors.aquaMarineTwo}
                  style={{ marginBottom: 100, marginTop: -10 }}
                />
              )
            }
            onEndReachedThreshold={0.1}
            data={searching ? filteredBlog : blogs}
            keyExtractor={item => {
              return item.id;
            }}
            style={styles.flatList}
          />
        )}
      </SafeAreaView>
    </View>
  );
};

export default BlogScreen;

const styles = StyleSheet.create({
  titleBar: {
    marginBottom: 10,
  },
  title: {
    ...textStyles.bold18,
    textAlign: 'left',
  },
  flatList: {
    flex: 1,
    backgroundColor: colors.paleGreyThree,
  },
  categories: {
    height: 120,
    marginBottom: 10,
  },
  scroll: { height: 50 },
  topShadow: {
    ...commons.shadow,
    backgroundColor: colors.white,
    zIndex: 9,
    padding: 15,
  },
  container: {
    flex: 1,
    backgroundColor: colors.paleGrey,
  },
  scrollableTabView: {
    paddingTop: 10,
    paddingLeft: 10,
    height: 50,
  },
});
