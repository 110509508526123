import React, { useState, useCallback, useEffect } from 'react';
import { TouchableOpacity, Text, StyleSheet } from 'react-native';
import colors from 'app/styles/colors';
import fonts from 'app/styles/fonts';
import { translate } from 'app/i18n/src/locale';
import pt from 'app/i18n/src/locale/pt';
import { useSelector } from 'react-redux';
import apiNode from 'app/services/apiNode';
import { useNavigation } from '@react-navigation/native';

const ButtonAttachments = ({ userProId, style, textStyle }) => {
  const [attachmentsNotRead, setAttachmentsNotRead] = useState(0);

  const navigation = useNavigation();

  const { profile } = useSelector(state => state.user);
  const getAttachmentsData = useCallback(async () => {
    try {
      const { data } = await apiNode.post('/chats', {
        userProId: userProId,
        userId: profile.user_id,
      });

      const chatMessages = await apiNode.get(`chat-messages/not-reading-chat/${data.id}/pro`);
      setAttachmentsNotRead(chatMessages.data?.count);
    } catch (error) {
      console.log(error);
    }
  }, [userProId, profile]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (userProId !== null) {
        getAttachmentsData();
      }
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [userProId, getAttachmentsData]);

  return (
    <TouchableOpacity
      activeOpacity={0.6}
      style={[styles.buttonContainer, style]}
      onPress={() => {
       navigation.navigate('ChatAttachments', { userProId });
      }}>
      <Text style={[styles.text, textStyle]}>
        {translate(pt.attachments)}
        {attachmentsNotRead !== null && attachmentsNotRead !== 0 && (
          <Text style={{ color: colors.aquaMarineTwo }}>{` (${attachmentsNotRead})`}</Text>
        )}
      </Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  buttonContainer: {
    borderRadius: 4,
    borderWidth: 1,
    paddingVertical: 10,
    borderColor: colors.cornflowerBlue,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    fontFamily: fonts.BOLD,
    fontSize: 16,
    textAlign: 'center',
    color: colors.cornflowerBlue,
  },
});
export default ButtonAttachments;
