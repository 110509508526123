import React from 'react';
import { Text, View, StyleSheet, TouchableOpacity, Image } from 'react-native';
import { SvgCssUri } from 'react-native-svg';
import textStyles from 'app/styles/textStyles';
import colors from 'app/styles/colors';

const Category = ({ category, onPress, indexCat, index, imageIcon }) => {
  return (
    <TouchableOpacity
      onPress={pressed => {
        onPress(pressed);
      }}
      style={styles.container}>
      {/* <SvgCssUri
        width={56}
        height={56}
        uri={category?.image_path}
        style={styles.oval}
        opacity={indexCat === index ? 1 : 0.3}
      /> */}
      <View
        style={[
          styles.oval,
          { backgroundColor: indexCat === index ? colors.aquaMarineTwo : colors.white },
        ]}>
        <Image
          source={category.image_path}
          style={{
            tintColor: indexCat === index ? colors.white : colors.aquaMarineTwo,
            width: 40,
            height: 40,
            resizeMode: 'contain',
          }}
        />
      </View>
      <Text style={textStyles.semi12}>{category?.cat_name}</Text>
    </TouchableOpacity>
  );
};

export default Category;

const styles = StyleSheet.create({
  container: {
    height: 96,
    marginRight: 17,
    alignItems: 'center',
  },
  oval: {
    backgroundColor: 'white',
    marginBottom: 9,
    alignItems: 'center',
    justifyContent: 'center',
    width: 70,
    height: 70,
    borderRadius: 35,
  },
});
