import React from 'react';
import building from 'app/images/building.svg';
import user from 'app/images/user.svg';
import check_circle from 'app/images/check_circle.svg';
import email from 'app/images/emailMenu.svg';
import phone from 'app/images/phone.svg';
import arrow_right from 'app/images/arrowRight.svg';
import memo from 'app/images/memo.svg';
import weight from 'app/images/weight.svg';
import utensils from 'app/images/utensils.svg';
import meditation from 'app/images/meditation.svg';
import graduation from 'app/images/graduation.svg';
import chat from 'app/images/chat.svg';
import smile from 'app/images/smile.svg';
import star from 'app/images/star.svg';
import star_circle from 'app/images/starCircle.svg';
import close_circle from 'app/images/closeCircle.svg';
import check_circle_o from 'app/images/checkCircleOutline.svg';
import close from 'app/images/closex.svg';
import game_point from 'app/images/gamePoint.svg';
import namu_pass from 'app/images/namupass.svg';
import sun from 'app/images/sun.svg';
import pin from 'app/images/pin.svg';
import pin_full from 'app/images/pin_full.svg';
import chevron_right from 'app/images/chevron_right.svg';
import filter from 'app/images/filter.svg';
import search from 'app/images/search.svg';
import sun_filled from 'app/images/sun_filled.svg';
import namu_vitalscan from 'app/images/namu_vitalscan.svg';
import paper_arrow from 'app/images/paper_arrow.svg';
import { Image } from 'react-native';
/**
 * @typedef {keyof typeof Icons} IconList
 */

export const Icons = {
  building,
  user,
  check_circle,
  email,
  phone,
  memo,
  arrow_right,
  weight,
  utensils,
  meditation,
  graduation,
  chat,
  smile,
  star_circle,
  close_circle,
  check_circle_o,
  close,
  game_point,
  namu_pass,
  star,
  sun,
  pin,
  pin_full,
  chevron_right,
  filter,
  search,
  sun_filled,
  namu_vitalscan,
  paper_arrow
};

/**
 * @param {Object} prop
 * @param {IconList} prop.name
 * @param {number} prop.size
 * @param {string} prop.color
 * @param {import('react-native').StyleProp<import('react-native').ViewStyle>} prop.style
 *
 */
const CustomIcon = ({ name, size = 12, color, style }) => {
  const Icon = Icons[name];
  return <Image source={Icon} style={[{width: size, height: size, resizeMode: 'contain'}, color && { tintColor: color }, style]} />;
};

export default CustomIcon;
