import React, { useState, useEffect, useCallback } from 'react';
import { View, StyleSheet, TouchableOpacity, Image } from 'react-native';
import moment from 'moment';
import colors from 'app/styles/colors';
import ShadowView from '@liuyunjs/react-native-simple-shadow-view';
import chevronRight from 'app/images/chevronRight.png';
import chevronLeft from 'app/images/chevronLeft.png';
import { Text } from 'react-native-animatable';
import textStyles from 'app/styles/textStyles';
import api from 'app/services/api';
import { getWaterIntakes } from 'app/services/apiWaterIntakes';
import { useDispatch, useSelector } from 'react-redux';
import { updateProfileSuccess } from 'app/store/modules/user/actions';

const WeekSlider = () => {
  const [daysOfWeek, setDayOfWeek] = useState([]);
  const [dateHeader, setDateHeader] = useState(moment());
  const dispatch = useDispatch();
  const { profile } = useSelector(state => state.user);

  useEffect(() => {
    requestHistoryWeek();
  }, [requestHistoryWeek, dateHeader]);

  useEffect(() => {
    var startOfWeek = moment()
      .startOf('isoWeek')
      .isoWeekday(0);
    var endOfWeek = moment()
      .endOf('isoWeek')
      .isoWeekday(6);

    var days = [];
    var day = startOfWeek;

    while (day <= endOfWeek) {
      days.push(day.toDate());
      day = day.clone().add(1, 'd');
    }

    setDayOfWeek(days);
  }, []);

  const getFirstLastDaysFromWeek = () => {
    const firstDay = moment(daysOfWeek[0]).date();
    const lastDay = moment(daysOfWeek[daysOfWeek.length - 1]).date();
    return `${firstDay} - ${lastDay}`;
  };

  const previousWeek = () => {
    var startOfWeek = moment(dateHeader)
      .subtract(1, 'weeks')
      .startOf('isoWeek')
      .isoWeekday(0);
    var endOfWeek = moment(dateHeader)
      .subtract(1, 'weeks')
      .endOf('isoWeek')
      .isoWeekday(6);

    var days = [];
    var day = startOfWeek;

    while (day <= endOfWeek) {
      days.push(day.toDate());
      day = day.clone().add(1, 'd');
    }
    setDateHeader(moment(dateHeader).subtract(1, 'week'));
    setDayOfWeek(days);
  };

  const nextWeek = () => {
    var startOfWeek = moment(dateHeader)
      .add(1, 'week')
      .startOf('isoWeek')
      .isoWeekday(0);
    var endOfWeek = moment(dateHeader)
      .add(1, 'week')
      .endOf('isoWeek')
      .isoWeekday(6);

    var days = [];
    var day = startOfWeek;

    while (day <= endOfWeek) {
      days.push(day.toDate());
      day = day.clone().add(1, 'd');
    }
    setDateHeader(moment(dateHeader).add(1, 'week'));
    setDayOfWeek(days);
  };

  const requestHistoryWeek = useCallback(async () => {
    const { data: user } = await api.get('user');
    const water_intakes_result = await getWaterIntakes(dateHeader);
    user.weekly_history = water_intakes_result.weekly_history;
    user.sum_of_the_week = water_intakes_result.sum_of_the_week[0].ml
      ? water_intakes_result.sum_of_the_week[0].ml
      : 0;

    dispatch(updateProfileSuccess(user));
  }, [dispatch, dateHeader, daysOfWeek]);

  const getUser = async () => {
    const user = { ...profile };
    const water_intakes_result = await getWaterIntakes(dateHeader);
    user.weekly_history = water_intakes_result.weekly_history;
    user.sum_of_the_week = water_intakes_result.sum_of_the_week[0].ml
      ? water_intakes_result.sum_of_the_week[0].ml
      : 0;
    user.daysRange = daysOfWeek;

    dispatch(updateProfileSuccess(user));
  };

  useEffect(() => {
    setTimeout(() => {
      getUser();
    }, 500);
  }, [daysOfWeek]);

  return (
    <ShadowView style={styles.container}>
      <View style={styles.month}>
        <View style={styles.rowWeek}>
          <TouchableOpacity onPress={previousWeek}>
            <Image source={chevronLeft} style={{ width: 20, height: 20 }} />
          </TouchableOpacity>
          <Text style={[textStyles.semi16]}>{moment(daysOfWeek[0]).format('MMMM YYYY')}</Text>
        </View>

        <View style={styles.rowWeek}>
          <Text style={[textStyles.semi16]}>{getFirstLastDaysFromWeek()}</Text>
          <TouchableOpacity onPress={nextWeek}>
            <Image source={chevronRight} style={{ width: 20, height: 20 }} />
          </TouchableOpacity>
        </View>
      </View>
    </ShadowView>
  );
};

export default WeekSlider;

const styles = StyleSheet.create({
  container: {
    shadowColor: colors.blackTwo,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    backgroundColor: colors.white,
    shadowRadius: 18,
    shadowOpacity: 0.16,
    zIndex: 66,
  },
  rowWeek: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  month: {
    marginVertical: 10,
    height: 30,
    alignItems: 'center',
    paddingHorizontal: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});
