import colors from 'app/styles/colors';
import React, { useEffect, useState } from 'react';
import { ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import moment from 'moment';
import { setNameShare } from 'app/store/modules/category/actions';
import { useDispatch } from 'react-redux';
import { debounce } from 'lodash';
import PERFORMANCE from 'app/configs/performance';
import { useNavigation } from '@react-navigation/native';
import CardClassesHorizontal from '../screens/profile/components/CardClassesHorizontal';
import LinearGradient from 'react-native-linear-gradient';

const BarSchedule = ({ schedule, onOpenTrail, getGradientColor }) => {
  const [activeHours, setActiveHours] = useState([]);
  const [currentSelectedHour, setCurrentSelectedHour] = useState(null);
  const [scheduleHours, setScheduleHours] = useState([]);
  const [itemsToShow, setItemsToShow] = useState([]);
  const dispatch = useDispatch();

  const navigation = useNavigation();

  const convertDateToShow = date => {
    const str = date.toLocaleTimeString('pt-BR');
    const arr = str.split(':');
    return `${arr[0]}h${arr[1]}`;
  };

  useEffect(() => {
    verifyActiveHours();
    verifyItems();
    changeHourToShow(scheduleHours[0]);
  }, [schedule]);

  // eslint-disable-next-line eqeqeq
  const isActive = date => activeHours.some(x => x == date);

  const verifyActiveHours = () => {
    const newScheduleHours = Array.from(new Set(schedule.map(u => u.start_date)));

    setScheduleHours(newScheduleHours);
    setActiveHours(newScheduleHours.filter(hour => convertStringToDate(hour) <= new Date()));
  };

  const formatDataForRender = data => {
    // eslint-disable-next-line eqeqeq
    if (data && data.category_item && (data.category_item === 1 || data.category_item.id == 1)) {
      return {
        ...data,
        class_id: data.class_id || data.dish_id || data.meal_id || data.id,
        dish: data.dish_id ? true : false,
        class_name: data.meal_name || data.dish_name,
        image_url: { path: data?.meal_images[0]?.path || data.image_url.path },
        class_payment_level: data.meal_payment_level || data.class_payment_level,
      };
    }
    return data;
  };

  const verifyItems = () => {
    const items = schedule
      .filter(userClass => userClass.start_date === currentSelectedHour)
      .map(s => formatDataForRender(s));
    setItemsToShow(items);
  };

  const convertStringToDate = string => moment(string, 'YYYY-MM-DD hh:mm:ss').toDate();

  const changeHourToShow = hour => {
    const aulas = schedule.filter(s => s.start_date === hour).map(s => formatDataForRender(s));
    setItemsToShow(aulas);
    setCurrentSelectedHour(hour);
  };

  function renderItems() {
    return (
      <View>
        {itemsToShow.map((item, index) => (
          <CardClassesHorizontal
            key={index}
            type={item.subcategory || item.subcategories[0].name}
            title={item.class_name}
            image={item.image_url.path}
            difficulty={item.difficulty}
            duration={item.duration}
            kcal={null}
            plus={null}
            minus={null}
            paymentLevel={item.class_payment_level}
            onPress={debounce(() => {
              if (item.class_payment_level === 1) {
                navigation.navigate('Paywall');
              } else {
                if (!item.dish) {
                  onOpenTrail(
                    item.category_item.id ? item.category_item.id : item.category_item,
                    item.class_id
                  );
                } else {
                  navigation.navigate('PlateDetail',{ plateId: item.class_id });
                }
              }
              dispatch(setNameShare(item.name));
            }, PERFORMANCE.ON_PRESS_DEBOUNCE_TIME)}
            style={styles.leftCard}
            tag={!!item.tags.length && item.tags[0].name}
            gradientColor={getGradientColor(
              item.category_item.id ? item.category_item.id : item.category_item
            )}
            favorite={null}
          />
        ))}
      </View>
    );
  }

  return (
    <>
      {scheduleHours && itemsToShow ? (
        <View>
          <ScrollView horizontal showsHorizontalScrollIndicator={false}>
            <View style={styles.container}>
              <View style={styles.hoursContainer}>
                {scheduleHours.map(hour => (
                  <TouchableOpacity
                    style={[
                      styles.hour,
                      {
                        borderBottomColor:
                          currentSelectedHour === hour ? colors.aquaMarineTwo : 'transparent',
                      },
                    ]}
                    onPress={() => changeHourToShow(hour)}>
                    <Text
                      style={[
                        styles.text,
                        {
                          color:
                            currentSelectedHour === hour
                              ? colors.aquaMarineTwo
                              : isActive(hour)
                              ? colors.aquaMarinePale
                              : colors.paleBlue,
                        },
                      ]}>
                      {convertDateToShow(convertStringToDate(hour))}
                    </Text>
                  </TouchableOpacity>
                ))}
              </View>
              <View style={styles.disabledBar}>
                <LinearGradient
                  colors={[colors.aquaMarineTwo, colors.aquaMarineGrd]}
                  useAngle
                  angle={64}
                  style={[styles.linear, { width: activeHours.length * 70 }]}
                />
              </View>
            </View>
          </ScrollView>
          {renderItems()}
        </View>
      ) : null}
    </>
  );
};

export default BarSchedule;

const styles = StyleSheet.create({
  container: {
    marginLeft: 20,
    alignSelf: 'flex-start',
    marginBottom: 10,
  },
  hoursContainer: {
    flexDirection: 'row',
  },
  hour: {
    borderBottomWidth: 4,
    borderBottomColor: colors.aquaMarinePaler,
    paddingBottom: 5,
    width: 70,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
  },
  text: {
    fontSize: 12,
    fontWeight: 'bold',
    lineHeight: 16,
  },
  disabledBar: {
    backgroundColor: colors.aquaMarinePaler,
    height: 4,
    borderRadius: 12,
    marginTop: -4,
  },
  linear: {
    height: 4,
    borderRadius: 12,
  },
  leftCard: { marginHorizontal: 20 },
});
