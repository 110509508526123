/* eslint-disable react-native/no-inline-styles */
import React from 'react';

import { TouchableOpacity, Text, StyleSheet } from 'react-native';
import textStyles from '../styles/textStyles';
import colors from '../styles/colors';

const ButtonBaseInvert = ({ fill, style, title, onPress, color = colors.aquaMarine }) => (
  <TouchableOpacity
    style={{
      ...styles.buttonEnter,
      alignSelf: fill ? 'stretch' : 'center',
      borderColor: color,
      ...style,
    }}
    onPress={onPress}>
    <Text style={{ ...textStyles.bold14White, color: color }}>{title}</Text>
  </TouchableOpacity>
);

const styles = StyleSheet.create({
  buttonEnter: {
    marginTop: 10,
    paddingHorizontal: 48,
    minWidth: 154,
    justifyContent: 'center',
    height: 39,
    borderRadius: 4,
    borderWidth: 1,
  },
});

export default ButtonBaseInvert;
