import React, { Fragment } from 'react';

import { View, StatusBar, Image, TouchableOpacity, StyleSheet, Text } from 'react-native';
import { useSafeArea } from 'react-native-safe-area-context';
import colors from '../styles/colors';
import chevronLeft from '../images/chevronLeft.png';
import fonts from 'app/styles/fonts';
import { useNavigation } from '@react-navigation/native';

const HeaderGrey = props => {
  const safeAreaInsets = useSafeArea();
  const navigation = useNavigation();

  return (
    <Fragment>
      <StatusBar translucent backgroundColor={colors.greyBlue} barStyle="light-content" />
      <View
        style={{
          paddingTop: props.noPadding ? 0 : safeAreaInsets.top,
          backgroundColor: colors.greyBlue,
        }}>
        <View style={styles.bar}>
          <TouchableOpacity style={styles.backButton} onPress={props.onPress || navigation.goBack}>
            <Image source={chevronLeft} style={[styles.backImage, { tintColor: colors.white }]} />
          </TouchableOpacity>
          <View style={styles.textContent}>
            <Text numberOfLines={1} style={styles.title}>
              {props.title}
            </Text>
          </View>
        </View>
      </View>
    </Fragment>
  );
};

export default HeaderGrey;

const styles = StyleSheet.create({
  title: {
    color: colors.white,
    margin: 'auto',
    fontSize: 18,
    position: 'relative',
    textAlign: 'left',
    fontFamily: fonts.BOLD,
  },
  textContent: {
    flex: 1,
    flexDirection: 'row',
    paddingLeft: 12,
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  bar: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: 50,
    paddingHorizontal: 8,
    backgroundColor: colors.greyBlue,
  },
  backButton: {
    width: 26,
    height: 26,
    marginLeft: 6,
  },
  backImage: {
    resizeMode: 'contain',
    width: 26,
    height: 26,
  },
});
