const colors = {
  base: '#D4d4d4',
  back: '#e4e4e4',
  baseGray: '#F4F4F4',
  transparent: 'rgba(255, 255, 255, 0)',
  darkPro: '#3F4059',
  darkPro2: '#485465',
  greyDark: '#444242',
  greyDesc: '#7B7C8F',
  primaryPurple: '#6240FF',
  aquaMarineTitle: '#1DA4A5',
  aquaMarineLight: '#B2EDED',
  aquaMarineLight2: '#94FEFF',
  aquaMarine: '#4fd5ae',
  aquaMarine80: '#218B6C',
  aquaMarineTwo: '#3ed1d2',
  aquaMarineTwo2: '#5CE5E6',
  aquaMarineTwoEC: '#A6ECEC',
  aquaMarineTwo20: '#D8F6F6',
  aquaMarine50: 'rgba(79, 213, 174, 0.5)',
  aquaMarine40: 'rgba(79, 213, 174, 0.4)',
  aquaMarineDiff: '#71CDCE',
  aquaMarinePale: '#70CDCE',
  aquaMarinePaler: '#EAF6F7',
  aquaMarinePalerTwo: '#E7F9F9',
  aquaMarineLighter: '#68FEFF',
  aquaMarineGrd: '#BFF3F3',
  aquaGreenLight: '#30C8CF',
  aquaGreenDark: '#4AB8CB',
  black: '#272727',
  lightPeriwinkle: '#c9cee7',
  white: '#ffffff',
  white15: 'rgba(255, 255, 255, .15)',
  veryLightPink: '#d8d8d8',
  veryLightPink0: '#d8d8d800',
  brownGrey: '#979797',
  blackTwo: '#3a3a3a',
  blackAlpha18: 'rgba(0,0,0,0.18)',
  blackAlpha40: 'rgba(0,0,0,0.40)',
  blackAlpha60: 'rgba(0,0,0,0.60)',
  aquaBlue: '#00AAFF',
  blueD8: '#4968D8',
  blueF5: '#9FB1F5',
  blueF4: '#54F3F4',
  facebook: '#4267b2',
  twitter: '#1da1f2',
  google: '#f34a38',
  pastelOrange: '#ff8850',
  pastelOrange2: '#FCAB61',
  pastelOrange3: '#FFBB48',
  pastelOrange80: '#ff8850CC',
  pastelOrange40: 'rgba(255, 136, 80, 0.4)',
  greyBlue: '#676c88',
  topaz: '#18afaf',
  aquaMarineThree: '#38d5d6',
  apricot: '#f8c26b',
  apricot2: '#FFD184',
  charcoalGrey: '#313132',
  charcoalGrey40: '#80313132',
  slateGrey: '#646673',
  slateGreyWithOpacity: '#646673A6',
  brownishGrey: '#616161',
  tealish: '#2bcc9e',
  tiffanyBlue: '#66f2d8',
  paleGrey: '#f7f8fa',
  palePurple: '#a6a6b2',
  whiteTwo: '#f9f9f9',
  grayF0: '#F0EFEF',
  aquaMarineFour: '#34d0d1',
  blueyGrey: '#94969d',
  greyF5: '#F5F6FA',
  greyEF: '#EFEFEF',
  grayLight: '#E0E5EC',
  charcoalGreyTwo: '#4d4e53',
  strawberry: '#e9393a',
  coral: '#ff4848',
  tealishTwo: '#31cdce',
  orangeyRed: '#fa3333',
  orangeyRed80: '#fa3333CC',
  orangeyRed60: '#993333CC',
  orangeyRed70: '#FF4E4E',
  orangeyRed40: 'rgba(250,51,51,0.4)',
  cornflowerBlue: '#4969d8',
  blueRGBA: 'rgba(62, 209, 210, 0.25)',
  aquaMarineA9: '#A9F1F1',
  tomato: '#e53636',
  veryLightPinkTwo: '#fee4e4',
  brownishGreyTwo: '#656565',
  greyBlue42: 'rgba(103, 108, 136, 0.42)',
  greyBlue22: 'rgba(103, 108, 136, 0.22)',
  veryLightPinkThree: '#f2f0f0',
  disabledGray: '#f2f2f2',
  paleLilac: '#e6e7ee',
  coralTwo: '#ff4343',
  tealishThree: '#2ccc9f',
  azure: '#0989e8',
  softBlue: '#59b1f4',
  greenBlue73: 'rgba(2, 183, 131, 0.73)',
  coralThree: '#ff3e3e',
  azureTwo: '#0889e9',
  blueyGreyTwo: '#989db3',
  paleGreyThree: '#fbfcfc',
  paleBlue: '#CFE3E4',
  paleGreyFour: '#f6f6f7',
  peach: '#ffbd88',
  veryLightPinkFour: '#e3e3e3',
  dustyOrange: '#ff8964',
  wheat: '#ffcd7d',
  tagGrey: '#d7d7d7',
  grapefruit: '#ff5959',
  barbiePink: '#f0549c',
  tomatoThree: '#e93939',
  pinkRed: '#ff0066',
  dustyOrangeTwo: '#fa7733',
  red: '#d80b0b',
  frenchRose: '#FF4B8B',
  peachyPink: '#ff8c8c',
  rosyPink: '#FF6794',
  pinkSherbet: '#FF8BAE',
  grapefruitTwo: '#fd6262',
  darkRed: '#FD4545',
  yellowishOrange: '#fea40d',
  orangeYellow: '#ff9f00',
  black6: 'rgba(0, 0, 0, 0.06)',
  lightGreyBlue: '#a0a5bd',
  greyblue26: 'rgba(118, 171, 172, 0.26)',
  topazTwo: '#12a5bf',
  topazThree: '#12b8bf',
  lightMustard: '#f5c169',
  warmPink: '#ff4b8b',
  grayText: '#5B607B',
  blackThree: '#000000',
  black4: '#333333',
  black2: '#3F4246',
  pastelRed: '#e65050',
  salmon: '#FD7170',
  lighterSalmon: '#FF9983',
  yellowOrange: '#ffb600',
  yellowOrange50: '#ffb600CC',
  lipstick: '#cf297c',
  barbiePinkTwo: '#f8469d',
  ceruleanBlue: '#0286e8',
  softBlueTwo: '#5fb4f5',
  purplishBlue: '#5f25f5',
  cornflower: '#8665fc',
  deepSkyBlue: '#007aff',
  brightSkyBlue: '#00b2ff',
  brightOrange: '#FCAB61',
  veryLightGrat: '#EAEAEA',
  softGray: '#E4E6F3',
  ligthGray: '#A9A9A9',
  shadowBlue: '#bff3f3',
  aquaBlueDark: '#A5EDED',
  redUltraLight: '#FFCDCD',
  orangeUltraLight: '#FFE0AF',
  grayWeak: '#C2C3C7',
  greyLight: '#E4E6F3',
  orangeOne: '#F58857',
  orangeTwo: '#FF8E53',
  orangeThree: '#F9C26C',
  greyLight3: '#A9BBC9',
  greyLight2: '#C2C3C7',
  brownLight: '#ce7e7e',
  brown: '#9d5d5d',
  violet: '#8273c5',
  violetTwo: '#9d88ff',
  peachOrange: '#fb9e6a',
  grey09: '#A9A9A9',
  greyMedium: '#7d7890',
  greyMedium2: '#a09dad',
  green019: 'rgba(191, 243, 243, 0.19)',
  neutralGray: '#757575',
  grayGreen: '#8492A6',
  whiteIce: '#E5E5E5',
  descriptionNewMessage: '#676C88',
  grey75: '#757575',
  greyLight4: '#676C88',
  greyLight5: '#A4A7B8',
  aquaMarineFive: '#4FD5AE',
  blackAlpha03: 'rgba(117, 117, 117, 0.31)',
  royalBlue: '#4968D8',
  peranoBlue: '#9FB1F5',
  bittersweetRed: '#FE5F5F',
  blizzardBlue: '#B3F0F0',
};

export default colors;
