import React from 'react';

import { StyleSheet, Text, Image } from 'react-native';
import fonts from '../styles/fonts';
import colors from '../styles/colors';
import level1 from '../images/nivelFCil.png';
import level2 from '../images/level2.png';
import level3 from '../images/nivelAvanAdo.png';
import { translate } from '../i18n/src/locale';
import pt from '../i18n/src/locale/pt';
import ShadowView from '@liuyunjs/react-native-simple-shadow-view';

const CapsuleLevelNew = ({ style, difficulty, textColor = colors.grayText }) => {
  const validLevel = () => {
    if (difficulty === 1) {
      return level1;
    }
    if (difficulty === 2) {
      return level2;
    }
    return level3;
  };
  return (
    <ShadowView style={[styles.capsule, style]}>
      <Text style={[styles.capsuleText, { color: textColor }]}>{translate(pt.level)}</Text>
      <Image style={styles.image} source={validLevel()} />
    </ShadowView>
  );
};

export default CapsuleLevelNew;

const styles = StyleSheet.create({
  capsuleText: {
    fontFamily: fonts.BOLD,
    fontSize: 10,
    color: colors.grayText,
  },
  capsule: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 18,
    borderRadius: 3,
    borderWidth: 1,
    borderColor: colors.disabledGray,
    paddingHorizontal: 4,
  },
  image: {
    width: 9,
    height: 7,
  },
});
