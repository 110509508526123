import React from 'react';

import { View, StyleSheet, Text, Image } from 'react-native';
import fonts from '../styles/fonts';
import colors from '../styles/colors';
import timeIcon from '../images/timeIcon.png';
import ShadowView from '@liuyunjs/react-native-simple-shadow-view';
import { translate } from 'app/i18n/src/locale';
import pt from 'app/i18n/src/locale/pt';

const CapsuleCalendar = ({ style, duration = 0 }) => (
  <ShadowView style={[styles.capsule, style]}>
    <Image source={timeIcon} style={{width: 14, height: 14}} />
    <Text style={styles.capsuleText}>{`${duration} ${translate(pt.weekly_abbreviation)}`}</Text>
  </ShadowView>
);

export default CapsuleCalendar;

const styles = StyleSheet.create({
  capsuleText: {
    marginLeft: 4,
    fontFamily: fonts.REGULAR,
    fontSize: 14,
    opacity: 0.9,
    color: colors.brownishGreyTwo,
  },
  capsule: {
    paddingLeft: 10,
    flexDirection: 'row',
    alignItems: 'center',
    width: 86,
    height: 29,
    borderRadius: 18.2,
    backgroundColor: colors.whiteTwo,
  },
});
