import React, { useState, useRef, useEffect } from 'react';

import { StyleSheet, Text, TouchableWithoutFeedback, View, Image } from 'react-native';
import colors from '../../../../styles/colors';
import textStyles from '../../../../styles/textStyles';
import chevronRight from '../../../../images/chevronRight.png';
// import {  } from 'react-native-animatable';
import pinAccordion from '../../../../images/pinAccordion.png';

const Accordion = props => {
  const chevron = useRef();
  const [height, setHeight] = useState(45);
  const [open, setOpen] = useState(false);

  const Animations = {
    rotateDown: {
      0: { rotate: '0deg' },
      1: { rotate: '90deg' },
    },
    rotateUp: {
      0: { rotate: '90deg' },
      1: { rotate: '0deg' },
    },
  };

  useEffect(() => {
    setHeight(open ? 150 : 45);
  }, [open]);

  const getRow = () => {
    //console.log(props.arrayItens);
    return props.arrayItens.map(item => {
      return (
        <View style={styles.row}>
          <Image source={pinAccordion} style={{ tintColor: props.color, width: 12, height: 12 }} />
          <Text style={[textStyles.regular14, styles.title]}>{item.meta_value || item}</Text>
        </View>
      );
    });
  };

  return (
    <View transition="minHeight" style={[styles.container, { minHeight: height }]}>
      <TouchableWithoutFeedback
        onPress={() => {
          setOpen(!open);
        }}>
        <View style={styles.header}>
          <Text style={textStyles.bold14}> {props.title}</Text>
          <Image
            animation={open ? Animations.rotateDown : Animations.rotateUp}
            duration={500}
            ref={chevron}
            style={{ tintColor: props.color, width: 18, height: 18 }}
            source={chevronRight}
          />
        </View>
      </TouchableWithoutFeedback>
      {open && (
        <View animation="fadeIn" style={styles.content}>
          {getRow()}
        </View>
      )}
    </View>
  );
};

export default Accordion;

const styles = StyleSheet.create({
  content: {
    borderTopWidth: 1,
    paddingTop: 8,
    marginBottom: 8,
    borderTopColor: colors.veryLightPinkThree,
  },
  title: { marginLeft: 8 },
  row: {
    flexDirection: 'row',
    marginBottom: 8,
    paddingHorizontal: 15,
    alignItems: 'center',
  },
  header: {
    flexDirection: 'row',
    height: 45,
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 15,
  },
  container: {
    marginBottom: 16,
    borderRadius: 4,
    backgroundColor: colors.white,
  },
});
