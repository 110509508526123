import React from 'react';
import { Image, StyleSheet, TextInput, TouchableOpacity, View } from 'react-native';
import { translate } from '../i18n/src/locale';
import pt from '../i18n/src/locale/pt';
import magnifier from 'app/images/magnifier.png';
import colors from '../styles/colors';
import fonts from '../styles/fonts';

const SearchBarNew = ({ value, onSearch, onChange }) => {
  return (
    <View style={[styles.search]}>
      <TouchableOpacity onPress={onSearch}>
        <Image source={magnifier} style={{ width: 20, height: 20 }} />
      </TouchableOpacity>
      <TextInput
        returnKeyType="search"
        value={value}
        onChangeText={onChange}
        style={styles.input}
        autoCorrect={false}
        autoCapitalize="none"
        onSubmitEditing={onSearch}
        placeholder={translate(pt.search_content)}
        placeholderTextColor={colors.greyBlue}
      />
    </View>
  );
};

export default SearchBarNew;

const styles = StyleSheet.create({
  input: {
    flex: 1,
    marginLeft: 16,
    height: 45,
    fontSize: 13,
    fontFamily: fonts.REGULAR,
  },
  search: {
    paddingHorizontal: 13,
    flexDirection: 'row',
    alignItems: 'center',
    height: 45,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: colors.veryLightPinkThree,
    borderRadius: 4,
    backgroundColor: colors.paleGreyThree,
  },
});
