import React from 'react';

import { View, Text, Image, StyleSheet } from 'react-native';
import textStyles from '../../../../styles/textStyles';

const MenuItemContent = ({ title, icon }) => (
  <View style={styles.container}>
    <Text style={textStyles.semi14}>{title}</Text>
    <Image source={icon} style={{ width: 24, height: 24 }} />
  </View>
);

export default MenuItemContent;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    width: 197,
    paddingHorizontal: 16,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});
