const META = {
  USER_WEIGHT: 'user_weight',
  USER_HEIGHT: 'user_height',
  USER_ALARM_CLOCK: 'user_alarm_clock',
  USER_WATER_INTAKE: 'user_water_intake',
  USER_WATER_NOTIFICATION: 'user_water_notification',
  USER_AGENDA_ACKNOWLEDGE: 'user_agenda_acknowledge',
  USER_WATER_ENABLE: 'user_water_enable',
};

export default META;
