import produce from 'immer';
const INITIAL_STATE = {
  favorites: [],
  showFavorites: false,
};

export default function favorites(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@favorites/HANDLE_FAVORITE': {
        console.log('RODANDO');
        console.log(draft);
        if (
          draft.favorites.some(
            x => x.type === action.payload.favorite.type && x.id === action.payload.favorite.id
          )
        ) {
          let newFavorites = draft.favorites.map(x => {
            if (x.type === action.payload.favorite.type && x.id === action.payload.favorite.id) {
              return { ...x, checked: action.payload.favorite.checked };
            }
            return x;
          });
          draft.favorites = [...newFavorites];
        } else {
          draft.favorites = [...draft.favorites, action.payload.favorite];
        }
        break;
      }
      case '@favorites/SET_SHOW_FAVORITES': {
        draft.showFavorites = action.payload.showFavorites;
        break;
      }
      default:
        return state;
    }
  });
}
