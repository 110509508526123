import Img1 from 'app/assets/gamification/imagens/img1.png';
import Img2 from 'app/assets/gamification/imagens/img2.png';
import Img4 from 'app/assets/gamification/imagens/img4.png';
import Img5 from 'app/assets/gamification/imagens/img5.png';
import Img6 from 'app/assets/gamification/imagens/img6.png';
import Img7 from 'app/assets/gamification/imagens/img7.png';
import Img8 from 'app/assets/gamification/imagens/img8.png';
import Img9 from 'app/assets/gamification/imagens/img9.png';
import daily from 'app/assets/gamification/imagens/imgdaily.png';
import PontosBranco from 'app/assets/gamification/imagens/pontosBranco.png';
import Pontos from 'app/assets/gamification/imagens/pontos.png';

import emblema03 from 'app/assets/gamification/insignia/emblema03.png';
import emblema05 from 'app/assets/gamification/insignia/emblema05.png';
import emblema07 from 'app/assets/gamification/insignia/emblema07.png';
import emblema10 from 'app/assets/gamification/insignia/emblema10.png';
import emblema15 from 'app/assets/gamification/insignia/emblema15.png';
import emblema30 from 'app/assets/gamification/insignia/emblema30.png';

export const imagesForModal = {
  img1: Img1,
  img2: Img2,
  img4: Img4,
  img5: Img5,
  img6: Img6,
  img7: Img7,
  img8: Img8,
  img9: Img9,
  imgdaily: daily,
  whitePoints: PontosBranco,
  points: Pontos,
};

export const textsForButton = {
  letsgo: 'Vamos lá!',
  points: 'Legal!',
  elevation: 'Elevar minha meta',
  continue: 'Continuar transformação',
};

export const insigniasOBJ = {
  img4: emblema03,
  img5: emblema05,
  img6: emblema07,
  img7: emblema10,
  img8: emblema15,
  img9: emblema30,
};
