/* eslint-disable react-native/no-inline-styles */
import React, { useState, useEffect, useCallback, useRef } from 'react';

import {
  View,
  Text,
  StyleSheet,
  ImageBackground,
  ScrollView,
  Dimensions,
  ActivityIndicator,
  TouchableWithoutFeedback,
  TouchableOpacity,
  StatusBar,
  Image,
} from 'react-native';

import RowClass from '../meditation/components/RowClass';
import colors from '../../../styles/colors';
import ChallengeChip from '../../../components/ChallengeChip';
import textStyles from '../../../styles/textStyles';
import commons from '../../../styles/commons';
import LinearGradient from 'react-native-linear-gradient';
import CapsuleCalendar from '../../../components/CapsuleCalendar';
import CapsuleLevel from '../../../components/CapsuleLevel';
import HeaderTitle from '../../profile/components/HeaderTitle';
import RowTeachers from './components/RowTeachers';
import Chip from '../../../components/Chips';
import { translate } from '../../../i18n/src/locale';
import pt from '../../../i18n/src/locale/pt';
import Accordion from '../../../components/Accordion';
import ButtonBase from '../../../components/ButtonBase';
import { useSafeArea } from 'react-native-safe-area-context';
import HeaderOver from '../../../components/HeaderOver';
import DayPointer from './components/DayPointer';
import { showAlert, errorControl, flashInfo, sendActionEvent } from 'app/services/utils';
import api from 'app/services/api';
import FavoriteButton from 'app/components/FavoriteButton';
import { useDispatch, useSelector } from 'react-redux';

//video dependencies
import Orientation from 'react-native-orientation-locker';
import Video from '@lnormanha/react-native-web-video/build/Video';
import ControlsVideo from 'app/components/ControlsVideo';
import ThumbnailVideo from 'app/components/ThumbnailVideo';
import fullscreenClose from '../../../images/fullscreenClose.png';
import fullscreenOpen from '../../../images/fullscreenOpen.png';
import ProgressBarWithTimer from 'app/components/ProgressBarWithTimer';
import { setTrailSelected } from 'app/store/modules/trail/actions';
import apiNode from 'app/services/apiNode';
import apiVimeo from 'app/services/apiVimeo';
import { useNavigation } from '@react-navigation/native';

const { width } = Dimensions.get('window');

const MeditationTrailScreen = ({ route }) => {
  const safeAreaInsets = useSafeArea();

  const { meditationId } = route?.params;

  const navigation = useNavigation();

  const dispatch = useDispatch();
  const { profile } = useSelector(state => state.user);
  const [scrollY, setScrollY] = useState(0);
  const [started, setStarted] = useState(false);
  const [trailDetail, setTrailDetail] = useState({});
  const [activitiesSorted, setActivitiesSorted] = useState([]);
  const [fullscreen, setFullscreen] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);
  const [isShowVideo, setIsShowVideo] = useState(false);
  const [isVideoLogin, setIsVideoLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const videoRef = useRef([]);
  const [state, setState] = useState({
    play: false,
    currentTime: 0,
    duration: 0,
    showControls: false,
    endVideo: false,
  });

  useEffect(() => {
    const fetchData = async () => {
      await requestTrailDetails();
    };
    fetchData();
  }, [requestTrailDetails]);

  const handleScroll = event => {
    setScrollY(event.nativeEvent.contentOffset.y);
  };

  const handleStart = () => {
    if (started === false) {
      dispatch(
        setTrailSelected({
          id: trailDetail?.trail?.id,
          subcategory: {
            name: trailDetail?.trail?.subcategories[0]?.name,
            category_id: trailDetail?.trail?.subcategories[0].category_id,
          },
          duration: trailDetail?.trail?.duration_in_weeks,
          name: trailDetail?.trail?.name_translate,
          image: { path: trailDetail?.trail?.image_path },
          difficulty: trailDetail?.trail?.difficulty,
          calories: trailDetail?.trail?.calories,
          payment_level: trailDetail?.trail?.trail_payment_level,
          meal_trail: false,
          goals: trailDetail?.tags.map(t => ({ goal_name: t.name })),
          favorite: trailDetail?.trail?.favorite,
        })
      );
      sendActionEvent(
        'meditation_trail_begin',
        { id: trailDetail.trail.id, email: profile.email },
        profile.email
      );
      // return Actions.start_calendar({ categoryId: 3 });
    }

    return cancelTrail(trailDetail?.activities[0].classes[0].item_id);
  };

  const cancelTrail = async itemId => {
    try {
      await api.delete('user-schedule', {
        params: {
          item_id: itemId,
          recurrence: 1,
        },
      });

      setStarted(false);

      flashInfo('O programa foi removida da sua agenda com sucesso');
      Actions.explorer();
    } catch (error) {
      showAlert(errorControl(error));
    }
  };

  const setTrailStarted = isTrailStarted => {
    if (isTrailStarted) {
      setStarted(true);
      return;
    }

    setStarted(false);
  };

  const sortActivityArray = activities => {
    const arraySorted = activities.sort((activityOne, activityTwo) => {
      return activityOne.day - activityTwo.day;
    });
    setActivitiesSorted(arraySorted);
  };

  const requestTrailDetails = useCallback(async () => {
    try {
      const response = await api.get(`trail/detail?trail_id=${meditationId}`);
      const favdata = await apiNode.get(`trails/favorite/${meditationId}`);
      const data = {
        ...response.data,
        ...favdata.data,
      };

      setTrailDetail(data);
      sortActivityArray(data.activities);
      setTrailStarted(data.activities[0].label.includes('/'));
      getVideoVimeo(
        data.trail.presentation_video ? data.trail.presentation_video.split('/') : null
      );
    } catch (error) {
      showAlert(errorControl(error));
    }
  }, [meditationId]);

  const getBenefitName = () => {
    if (trailDetail.benefits === '') {
      return [];
    }
    const arrayBenefits = trailDetail.benefits.split(',');
    return arrayBenefits;
  };

  const getRequirementName = () => {
    if (trailDetail.requirements === '') {
      return [];
    }
    const arrayRequirements = trailDetail.requirements.split(',');
    return arrayRequirements;
  };

  const onPressItem = async id => {
    setIsLoading(true);
    let exercise = null;
    try {
      const { data } = await api.get(`class/detail?class_id=${id}`);
      exercise = data?.exercises_series && data?.exercises_series[0]?.exercises;
      sendActionEvent(
        'click_class_in_trail',
        {
          class_id: id,
          email: profile.email,
        },
        profile.email
      );
    } catch (error) {
      showAlert(errorControl(error));
      setIsLoading(false);
    }

    if (exercise && exercise[0]?.media?.extension === 'mp3') {
      navigation.navigate('PlayMeditation', { idClass: id });
    } else {
      navigation.navigate('LessonMeditation', { classId: id });
    }
    setTimeout(() => setIsLoading(false), 500);
  };

  //video player functions
  const getVideoVimeo = async idVimeo => {
    setIsVideoLogin(true);

    if (idVimeo) {
      const videoId = idVimeo[idVimeo.length - 1];

      try {
        const { data } = await apiVimeo.get(`/videos/${videoId}`);

        const video360 = data.download.find(v => v.rendition === '360p');
        setVideoUrl(video360.link);
      } catch (error) {
        console.log('Error', error);
      }
    } else {
      setVideoUrl(null);
    }
    setIsVideoLogin(false);
  };

  const onLoadEnd = async data => {
    setState(prevState => ({
      ...prevState,
      duration: data.duration,
      currentTime: data.currentTime,
    }));
    sendActionEvent(
      'start_presentation',
      { id: meditationId, email: profile.email },
      profile.email
    );
  };

  const onProgress = async data => {
    setState(prevState => ({
      ...prevState,
      currentTime: data.currentTime,
    }));
  };

  const onEnd = async () => {
    setState({ ...state, play: false });
    videoRef.current.seek(0);
    setIsShowVideo(false);
    sendActionEvent(
      'watch_full_presentation',
      { id: meditationId, email: profile.email },
      profile.email
    );
  };

  const handlePlayPause = () => {
    if (state.play) {
      setState({ ...state, play: false, showControls: true });
      return;
    }

    setState({ ...state, play: true, showControls: false });
  };

  const onSeek = data => {
    videoRef.current.seek(data.seekTime);
    setState({ ...state, currentTime: data.seekTime });
  };

  const showControls = () => {
    state.showControls
      ? setState({ ...state, showControls: false })
      : setState({ ...state, showControls: true });
  };

  const handleOrientation = () => {
    Orientation.getOrientation(deviceOrientation => {
      deviceOrientation === 'LANDSCAPE-LEFT' || deviceOrientation === 'LANDSCAPE-RIGHT'
        ? (setFullscreen(true), StatusBar.setHidden(true))
        : (setFullscreen(false), StatusBar.setHidden(false));
    });
  };

  const handleFullscreen = () => {
    fullscreen ? Orientation.unlockAllOrientations() : Orientation.lockToLandscapeLeft();
  };

  const showComponentVideo = () => {
    setState({ ...state, play: true });
    setIsShowVideo(true);
  };

  useEffect(() => {
    Orientation.addOrientationListener(handleOrientation);

    return () => {
      Orientation.removeOrientationListener(handleOrientation);
    };
  }, []);

  const hasPaymentLevel = () => {
    if (trailDetail.trail.trail_payment_level === 1) {
      return true;
    }
    return false;
  };

  return (
    <>
      {!trailDetail.trail || isLoading ? (
        <View style={{ alignItems: 'center', justifyContent: 'center', flex: 1 }}>
          <ActivityIndicator size={'large'} color={colors.orangeYellow} />
        </View>
      ) : (
        <View style={!fullscreen ? [styles.container] : {}}>
          {!fullscreen && (
            <>
              <HeaderOver
                title={trailDetail?.trail?.name_translate}
                color={colors.pastelOrange}
                position={scrollY}
                maxSize={426}
                share={false}
                shareData={{
                  title: trailDetail?.trail?.name_translate,
                  screen: 'meditation_trail',
                  image: trailDetail?.trail?.image_path,
                  description: trailDetail?.trail?.description,
                  id: trailDetail?.trail?.id,
                }}
              />
            </>
          )}

          <ScrollView
            scrollEventThrottle={16}
            onScroll={handleScroll}
            contentContainerStyle={{
              flexGrow: 1,
              paddingBottom: !fullscreen ? safeAreaInsets.bottom : 0,
            }}>
            <View>
              {!fullscreen && (
                <>
                  <View style={styles.topImage}>
                    <ImageBackground
                      source={{
                        uri: trailDetail.trail.image_path,
                        headers: {
                          Authorization: api.defaults.headers.Authorization,
                        },
                      }}
                      style={{ height: 426 }}>
                      <View style={styles.topper}>
                        <LinearGradient
                          colors={[colors.transparent, colors.pastelOrange80]}
                          style={styles.linear}
                        />

                        <ChallengeChip text={translate(pt.trail)} />

                        <View style={styles.holderTitle}>
                          <Text style={[textStyles.bold24White]}>
                            {trailDetail.trail.name_translate}
                          </Text>
                          <FavoriteButton
                            isFavorited={trailDetail?.favorite}
                            propType="trailId"
                            itemId={trailDetail?.trail?.id}
                          />
                        </View>
                      </View>
                    </ImageBackground>
                  </View>
                  <View style={styles.capsuleHolder}>
                    <CapsuleCalendar
                      style={styles.capsule}
                      duration={trailDetail.trail.duration_in_weeks}
                    />
                    <CapsuleLevel
                      style={styles.capsule}
                      difficulty={trailDetail.trail.difficulty}
                    />
                  </View>
                </>
              )}
            </View>

            {!fullscreen && (
              <>
                <View style={styles.content}>
                  <Text style={textStyles.regular14}>{trailDetail.trail.description}</Text>
                </View>

                <HeaderTitle title={translate(pt.author)} />
                <View style={styles.content}>
                  {trailDetail.instructors.map(instructor => {
                    return (
                      <RowTeachers
                        image={instructor.teacher_image_path}
                        name={instructor.teacher_name}
                        subCategoryName={trailDetail?.trail.subcategories[0].name}
                      />
                    );
                  })}

                  <View style={styles.insideContainerRow}>
                    {trailDetail.tags.map(tag => {
                      return <Chip title={tag.name} />;
                    })}
                  </View>
                </View>

                {/* <View style={styles.content}>
                  <AddPlaylist
                    paymentLevel={trailDetail.trail.trail_payment_level}
                    trailId={trailDetail.trail.id}
                  />
                </View> */}

                <View style={styles.content}>
                  {!!trailDetail?.benefits && (
                    <Accordion
                      title={translate(pt.see_benefits)}
                      color={colors.pastelOrange}
                      arrayItens={getBenefitName()}
                    />
                  )}
                  {!!trailDetail?.prerequisites && (
                    <Accordion
                      title={translate(pt.prerequisites)}
                      color={colors.pastelOrange}
                      arrayItens={getRequirementName()}
                    />
                  )}
                </View>

                {/* <HeaderTitle title={translate(pt.meditation)} />
                <View style={styles.content}>
                  <CardMeditation
                    hoursWeek={trailDetail.hours_by_week}
                    minutesDay={trailDetail.minutes_by_day}
                  />
                </View> */}
              </>
            )}

            {!!videoUrl && (
              <>
                {!fullscreen && <HeaderTitle title={translate(pt.trail_presentation)} />}
                <View style={!fullscreen && styles.content}>
                  {isShowVideo ? (
                    <TouchableWithoutFeedback onPress={showControls}>
                      <View style={!fullscreen && styles.containerVideo}>
                        <Video
                          ref={videoRef}
                          style={fullscreen ? styles.fullscreenVideo : styles.video}
                          source={{
                            uri: videoUrl,
                          }}
                          muted={false}
                          controls={false}
                          resizeMode="contain"
                          onLoad={onLoadEnd}
                          onProgress={onProgress}
                          onEnd={onEnd}
                          paused={!state.play}
                          repeat={false}
                          ignoreSilentSwitch="ignore"
                        />
                        {isVideoLogin && (
                          <ActivityIndicator
                            color={colors.aquaMarine}
                            style={{
                              position: 'absolute',
                              top: 0,
                              bottom: 0,
                              left: 0,
                              zIndex: 111,
                              right: 0,
                            }}
                          />
                        )}
                        {state.showControls && (
                          <View style={styles.controlOverlay}>
                            <TouchableOpacity
                              onPress={handleFullscreen}
                              style={[
                                styles.fullscreenButton,
                                styles.fullscreenControls,

                                { top: fullscreen ? 20 : 0, right: fullscreen ? 20 : 0 },
                              ]}>
                              {state.fullscreen ? (
                                <Image style={styles.fullscreenOpen} source={fullscreenClose} />
                              ) : (
                                <Image style={styles.fullscreenClose} source={fullscreenOpen} />
                              )}
                            </TouchableOpacity>
                            <ControlsVideo
                              onPlay={handlePlayPause}
                              onPause={handlePlayPause}
                              playing={state.play}
                            />
                            <ProgressBarWithTimer
                              currentTime={state.currentTime}
                              duration={state.duration > 0 ? state.duration : 0}
                              onSlideStart={handlePlayPause}
                              onSlideComplete={handlePlayPause}
                              onSlideCapture={onSeek}
                            />
                          </View>
                        )}
                      </View>
                    </TouchableWithoutFeedback>
                  ) : (
                    <ThumbnailVideo
                      image={trailDetail?.trail?.thumbnail_path}
                      onPress={showComponentVideo}
                    />
                  )}
                </View>
              </>
            )}

            {!fullscreen && (
              <>
                <HeaderTitle title={translate(pt.trail_activity)} />
                <View style={styles.content}>
                  {activitiesSorted.map(activities => {
                    return (
                      <>
                        <DayPointer text={activities.label} />
                        {activities.classes.map((item, key) => (
                          <RowClass
                            key={key}
                            payment_level={item.trail_class_payment_level}
                            onPress={() => onPressItem(item.id)}
                            classObj={item}
                          />
                        ))}
                      </>
                    );
                  })}
                </View>
              </>
            )}
          </ScrollView>
          {/* {!fullscreen && (
            <>
              <View style={{ backgroundColor: 'white', paddingBottom: safeAreaInsets.bottom + 20 }}>
                <View style={{ paddingHorizontal: 20 }}>
                  <ButtonBase
                    backgroundColor={started ? colors.white : colors.pastelOrange}
                    textStyle={{ color: started ? colors.coralThree : colors.white }}
                    onPress={hasPaymentLevel() ? Actions.paywall : handleStart}
                    style={{ height: 49 }}
                    fill
                    title={translate(started ? pt.cancel_trail : pt.begin_trail)}
                  />
                </View>
              </View>
            </>
          )} */}
        </View>
      )}
    </>
  );
};

export default MeditationTrailScreen;

const styles = StyleSheet.create({
  add: { flex: 1, flexDirection: 'column', alignItems: 'flex-start' },
  topper: { padding: 14, flex: 1, justifyContent: 'flex-end' },
  content: { paddingHorizontal: 14, marginBottom: 14 },
  holderTitle: { flexDirection: 'row', marginVertical: 10, alignItems: 'center' },
  linear: { position: 'absolute', width: '100%', height: 200, left: 0, bottom: 0 },
  capsuleHolder: {
    flexDirection: 'row',
    width: 170,
    marginTop: -10,
    marginBottom: 20,
    justifyContent: 'space-between',
    marginLeft: 16,
  },
  capsule: {
    backgroundColor: colors.whiteTwo,
    borderColor: '#eee',
    borderWidth: StyleSheet.hairlineWidth,
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 6,
    shadowOpacity: 0.2,
  },
  topImage: {
    height: 426,
    borderBottomRightRadius: 36,
    backgroundColor: 'grey',
    overflow: 'hidden',
  },
  container: {
    height: '100%',
    backgroundColor: colors.paleGrey,
  },
  insideContainerRow: {
    marginTop: 10,
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 0,
  },
  video: {
    height: Dimensions.get('window').width * (9 / 17),
    backgroundColor: colors.paleGrey,
  },
  fullscreenVideo: {
    height: Dimensions.get('screen').width,
    width: Dimensions.get('screen').height,
    backgroundColor: 'black',
  },
  fullscreenButton: {
    flex: 1,
    flexDirection: 'row',
    alignSelf: 'flex-end',
    alignItems: 'center',
  },
  controlOverlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#00000080',
    justifyContent: 'space-between',
  },
  containerVideo: {
    marginHorizontal: 12,
  },
  fullscreenControls: {
    padding: 5,
    position: 'absolute',
    zIndex: 2,
  },
  fullscreenClose: {
    width: 20,
    height: 20,
  },
  fullscreenOpen: {
    width: 20,
    height: 20,
  },
  containerTitle: {
    flexDirection: 'row',
    paddingHorizontal: 15,
    height: 40,
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: colors.paleGrey,
  },
});
