import React from 'react';

import { StyleSheet, Image } from 'react-native';
import colors from '../styles/colors';
import minus from '../images/minus.png';
import { TouchableOpacity } from 'react-native';

const Minus = ({ style, onPress }) => (
  <TouchableOpacity onPress={onPress} style={[styles.icon, style]}>
    <Image source={minus} style={{ width: 16, height: 16 }} />
  </TouchableOpacity>
);

export default Minus;

const styles = StyleSheet.create({
  icon: {
    zIndex: 10,
    right: 0,
    marginTop: 0,
    alignItems: 'center',
    justifyContent: 'center',
    width: 27,
    position: 'absolute',
    height: 27,
    borderRadius: 3.1,
    backgroundColor: colors.orangeyRed,
  },
});
