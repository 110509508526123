import ButtonBase from 'app/components/ButtonBase';
import colors from 'app/styles/colors';
import fonts from 'app/styles/fonts';
import { translate } from 'app/i18n/src/locale';
import React from 'react';
import { Image, StyleSheet, Text, View, TouchableOpacity } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import pt from 'app/i18n/src/locale/pt';
import { debounce } from 'lodash';
import PERFORMANCE from 'app/configs/performance';
import ShadowView from '@liuyunjs/react-native-simple-shadow-view';
import { useSelector } from 'react-redux';
import MaskLevelPaymentNew from 'app/components/MaskLevelPaymentNew';
import { useNavigation } from '@react-navigation/native';

const PerformCheckinWidget = ({ coords }) => {
  const navigation = useNavigation();
  const { profile } = useSelector(state => state.user);

  return (
    <ShadowView style={styles.shadowContainer}>
      <TouchableOpacity
        activeOpacity={0.7}
        style={{flex: 1}}
        onPress={debounce(() => {
          navigation.navigate('ShowGyms', { coordinates: coords || null });
        }, PERFORMANCE)}>
      <LinearGradient
        colors={[colors.aquaMarineLighter, colors.aquaMarineTwo]}
        useAngle
        angle={270}
        style={styles.container}>
          {!profile?.plan_meta?.mood_enabled && (
            <MaskLevelPaymentNew containerStyles={{ height: '100%', width: '100%' }} />
          )}
          <Image source={require('app/images/woman_gym_phone.png')} style={styles.image} />
          <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <View
              style={{
                width: '45%',
                marginVertical: 17,
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                marginLeft: 23,
              }}>
              <View style={styles.textWrapper}>
                <Text style={styles.textTitle}>{'Descubra academias nas proximidades'}</Text>
              </View>
              <View>
                <Text style={styles.textContent}>{'Encontre uma academia perto de você!'}</Text>
              </View>
            </View>
            <ButtonBase
              backgroundColor={colors.white}
              style={styles.buttonBase}
              onPress={debounce(() => {
                navigation.navigate('ShowGyms', { coordinates: coords || null });
              }, PERFORMANCE)}
              textStyle={{ color: colors.aquaMarineTwo }}
              title={translate(pt.see_gyms)}
            />
          </View>
      </LinearGradient>
      </TouchableOpacity>
    </ShadowView>
  );
};

const styles = StyleSheet.create({
  shadowContainer: {
    backgroundColor: colors.white,
    borderRadius: 10,
    shadowColor: colors.black,
    minHeight: 130,
    marginHorizontal: 20,
    shadowOpacity: 0.1,
    shadowRadius: 5,
    shadowOffset: { width: 0, height: 5 },
  },
  container: {
    flex: 1,
    borderRadius: 10,
    backgroundColor: colors.white,
    overflow: 'hidden',
    position: 'relative',
  },
  image: {
    height: '100%',
    width: '60%',
    resizeMode: 'contain',
    right: 0,
    top: 0,
    position: 'absolute',
  },
  buttonBase: {
    height: 40,
    alignSelf: 'flex-end',
    marginRight: 17,
    marginBottom: 5,
    paddingHorizontal: 15,
    minWidth: 100,
    borderRadius: 10,
    shadowColor: colors.black,
    shadowOpacity: 0.1,
    shadowRadius: 5,
    shadowOffset: { width: 0, height: 5 },
  },
  textWrapper: {
    marginVertical: 6,
  },
  textTitle: {
    fontFamily: fonts.BOLD,
    color: colors.white,
    fontSize: 16,
    lineHeight: 17,
  },
  textContent: {
    fontFamily: fonts.REGULAR,
    fontSize: 13,
    lineHeight: 17,
    color: colors.white,
  },
});

export default PerformCheckinWidget;
