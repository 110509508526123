import api from 'app/services/api';
import React, { useState } from 'react';
import { TouchableOpacity, StyleSheet } from 'react-native';
import { Dimensions } from 'react-native';
import { Image } from 'react-native';
import { View } from 'react-native';
import videoPlay from '../images/videoPlay.png';
import colors from '../styles/colors';

const ThumbnailVideo = ({ image, fallback, onPress }) => {
  const [thumb, setThumb] = useState({
    uri: image ?? '',
    headers: {
      Authorization: api.defaults.headers.Authorization,
    },
  });

  return (
    <View style={styles.container}>
      <TouchableOpacity onPress={onPress} style={styles.playButton}>
        <Image source={videoPlay} style={{width: 26, height: 26, tintColor: image ? 'white' : colors.darkPro, resizeMode: 'contain'}} />
      </TouchableOpacity>
      <Image
        style={styles.imageThumb}
        source={thumb}
        onError={curr => {
          if (fallback) {
            curr.onError = null;
            setThumb(fallback);
          }
        }}
      />
    </View>
  );
};

export default ThumbnailVideo;

const styles = StyleSheet.create({
  imageThumb: {
    height: Dimensions.get('window').width * (9 / 17),
    width: '100%',
    resizeMode: 'cover',
  },
  container: { marginHorizontal: 12 },
  playButton: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
