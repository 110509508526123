import React from 'react';
import { View, Text, StyleSheet, ImageBackground, Image, TouchableOpacity } from 'react-native';
import fonts from 'app/styles/fonts';
import colors from 'app/styles/colors';
import arrowRight from 'app/images/arrowRightThin.png';
import LinearGradient from 'react-native-linear-gradient';
import FavoriteButtonShow from 'app/components/FavoriteButtonShow';
import { translate } from 'app/i18n/src/locale';
import pt from 'app/i18n/src/locale/pt';
import MaskLevelPaymentNew from 'app/components/MaskLevelPaymentNew';
import PERFORMANCE from 'app/configs/performance';
import { debounce } from 'lodash';

const CardCourses = ({
  image,
  title = '',
  favorite = false,
  style,
  id,
  onPress,
  goal,
  color = colors.aquaMarinePale,
  paymentLevel,
  full = false,
}) => {
  const getBackgroundColor = () => {
    if (color === colors.aquaMarinePale) {
      return colors.aquaMarineTwo;
    }
    return color;
  };

  return (
    <ImageBackground
      source={image}
      style={[styles.backgroundImage, style, full ? styles.fullContainer : {}]}
      imageStyle={{ borderRadius: 20 }}>
      {paymentLevel === 1 && (
        <MaskLevelPaymentNew containerStyles={{ height: '100%', width: '100%' }} />
      )}
      <View style={styles.topItems}>
        <View
          style={[
            styles.typeWrapper,
            {
              backgroundColor: getBackgroundColor() + 'CC',
              opacity: goal.length === 0 ? 0 : 1,
              fontSize: full ? 14 : 11,
            },
          ]}>
          <Text style={[styles.types, { fontSize: full ? 11 : 9 }]} numberOfLines={1}>
            {goal}
          </Text>
        </View>
        <FavoriteButtonShow isFavorited={favorite} propType="courseId" itemId={id} size={22} />
      </View>
      <LinearGradient
        colors={['rgba(65, 68, 86, .5)', '#292D44']}
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 1 }}
        location={[-0.845, 0.9]}
        useAngle
        angle={166.46}
        style={[styles.container, full ? styles.fullContainer : {}]}>
        <View style={{ alignItems: 'center', marginTop: 'auto', marginBottom: 22 }}>
          <Text style={[styles.textTitle, { fontSize: full ? 20 : 16 }]}>{title}</Text>
          <TouchableOpacity
            onPress={debounce(() => onPress(), PERFORMANCE.ON_PRESS_DEBOUNCE_TIME)}
            style={[
              styles.button,
              { borderColor: color, width: full ? 250 : 200, height: full ? 40 : 33 },
            ]}>
            <Text style={styles.textButton}>{translate(pt.access_course)}</Text>
            <Image
              source={arrowRight}
              style={{ marginLeft: 8, marginTop: 2, width: 15, resizeMode: 'contain' }}
            />
          </TouchableOpacity>
        </View>
      </LinearGradient>
    </ImageBackground>
  );
};

export default CardCourses;

const styles = StyleSheet.create({
  textButton: {
    fontFamily: fonts.BOLD,
    fontSize: 12,
    color: colors.white,
  },
  button: {
    marginTop: 26,
    borderRadius: 4,
    borderWidth: 1,
    width: 200,
    height: 33,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
  backgroundImage: {
    flex: 1,
    resizeMode: 'cover',
    height: 220,
    width: 280,
    position: 'relative',
  },
  container: {
    height: 220,
    width: 280,
    borderRadius: 20,
    alignItems: 'center',
  },
  topItems: {
    position: 'absolute',
    zIndex: 2,
    top: 16,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    paddingHorizontal: 16,
    borderRadius: 20,
  },
  textTitle: {
    fontFamily: fonts.BOLD,
    fontSize: 16,
    color: colors.white,
    textAlign: 'center',
    marginHorizontal: 16,
  },
  typeWrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
    paddingHorizontal: 8,
    paddingVertical: 4,
    alignSelf: 'flex-start',
    borderRadius: 4,
    marginTop: 'auto',
  },
  types: {
    color: colors.white,
    fontSize: 9,
    fontFamily: fonts.BOLD,
  },
  fullContainer: {
    width: '100%',
    height: 'auto',
    aspectRatio: 1,
  },
});
