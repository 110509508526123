import I18n from 'i18n-js';
import pt from './pt';

const moment = require('moment');
require('moment/locale/pt');

// Função responsável por adquirir o idioma utilizado no device
export const getLanguageByDevice = () => {
  let systemLanguage = 'pt';

  try {
    const languageCode = systemLanguage.substring(0, 2);
    return languageCode;
  } catch (err) {
    return 'pt';
  }
};

// Aqui setamos os idiomas que o I18N irá dar suporte
I18n.translations = {
  pt,
  // en,
};

// Função responsável por verificar se o idioma atual do device está sendo suportado, caso não ele irá setar como 'en'
const setLanguageToI18n = () => {
  const language = getLanguageByDevice();
  moment.locale(language);
  const iHaveThisLanguage = I18n.translations.hasOwnProperty(language);
  I18n.locale = iHaveThisLanguage ? language : 'pt';
  I18n.defaultLocale = 'pt';
};

setLanguageToI18n();

function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value);
}

export const translate = (value, option = null) => {
  const key = getKeyByValue(pt, value);
  return I18n.t(key, option);
};

export const translateByCode = (key, option = null) => {
  return I18n.t(key, option);
};
