import ButtonBase from 'app/components/ButtonBase';
import pt from 'app/i18n/src/locale/pt';
import colors from 'app/styles/colors';
import fonts from 'app/styles/fonts';
import React, { useEffect, useRef, useState } from 'react';
import {
  Dimensions,
  Image,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  Modal
} from 'react-native';
import backgroundCustomSearch from 'app/images/backgroundCustomSearch.png';
import AnimatedLottieView from 'lottie-react-native';
import commons from 'app/styles/commons';
import CustomIcon from 'app/styles/icon';
import Icon from 'react-native-vector-icons/dist/FontAwesome5';
import textStyles from 'app/styles/textStyles';
import { translate } from 'app/i18n/src/locale';
import ButtonShadow from 'app/components/ButtonShadow';
import CreditCard from 'app/screens/core/payment/components/CreditCard';
import card_banners from 'app/images/card_banners.png';
import { TextInputMask } from 'react-native-masked-text';
import * as yup from 'yup';
import apiNode from 'app/services/apiNode';
const { width, height } = Dimensions.get('screen');

const AddCreditCardModal = ({ open, onClose }) => {
  const [loading, setLoading] = useState(false);

  const [cardName, setCardName] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [validThru, setValidThru] = useState('');
  const [cardCVV, setCardCVV] = useState('');

  const flashMessageRef = useRef();

  useEffect(() => {
    if (open) {
      setCardName('');
      setCardNumber('');
      setValidThru('');
      setCardCVV('');
    }
  }, [open]);

  const handleAddCard = async () => {
    try {
      setLoading(true);
      const schema = yup.object().shape({
        cardName: yup.string().required(translate(pt.field_required, { field: 'Nome no cartão' })),
        cardNumber: yup
          .string()
          .required(translate(pt.field_required, { field: 'Número do cartão' })),
        validThru: yup.string().required(translate(pt.field_required, { field: 'Vencimento' })),
        cardCVV: yup.string().required(translate(pt.field_required, { field: 'CVV' })),
      });

      schema
        .validate({
          cardName,
          cardNumber,
          validThru,
          cardCVV,
        })
        .then(async () => {
          const { data } = await apiNode.post('namu-pass/credit-card', {
            cardNumber,
            since: validThru,
            holderName: cardName,
            cvv: cardCVV,
          });

          if (data?.success) {
            // flashMessageRef.current.showMessage({
            //   floating: true,
            //   animationDuration: 500,
            //   message: 'Cartão adicionado com sucesso!',
            //   type: 'success',
            //   icon: 'success',
            // });
            onClose();
          }
          setLoading(false);
        })
        .catch(error => {
          // flashMessageRef.current.showMessage({
          //   floating: true,
          //   message: error.response?.data?.message || error.message,
          //   type: 'danger',
          //   icon: 'danger',
          // });
          setLoading(false);
        });
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const renderData = () => {
    return (
      <>
        <View style={{ ...commons.flexRow, marginHorizontal: 24, paddingBottom: 20 }}>
          <View style={commons.flexRow}>
            <TouchableOpacity
              onPress={onClose}
              hitSlop={commons.hitSlop}
              style={{ marginRight: 10 }}>
              <CustomIcon
                name={'chevron_right'}
                size={18}
                color={colors.aquaMarineTwo}
                style={{ transform: [{ rotateZ: '-180deg' }], marginRight: 10 }}
              />
            </TouchableOpacity>
            <Icon name={'wallet'} color={colors.aquaMarineTwo} size={20} />
            <Text style={{ ...styles.title, marginLeft: 10 }}>{translate(pt.add_credit_card)}</Text>
          </View>
        </View>

        <ScrollView style={{ flex: 1 }} contentContainerStyle={{ marginHorizontal: 24 }}>
          <View>
            <View style={styles.inputContainer}>
              <View style={[styles.row, { marginVertical: 10 }]}>
                <Text style={styles.text}>{translate(pt.cc_banners)}</Text>
                <Image style={styles.card_banners} source={card_banners} />
              </View>
            </View>
            <View style={{ marginVertical: 22 }}>
              <CreditCard
                cardHolder={cardName}
                CVV={cardCVV}
                validThru={validThru}
                cardNumber={cardNumber}
              />
            </View>
            <View style={styles.inputContainer}>
              <Text style={styles.inputLabel}>{translate(pt.name_on_card)}</Text>
              <TextInput
                autoCapitalize="words"
                onChangeText={value => setCardName(value)}
                value={cardName}
                keyboardType="default"
                placeholder={translate(pt.name_on_card)}
                style={styles.input}
              />
            </View>
            <View style={styles.inputContainer}>
              <Text style={styles.inputLabel}>{translate(pt.card_number)}</Text>
              <TextInput
                autoCapitalize="none"
                onChangeText={(e) => setCardNumber(e.replace(/\D+/g, ''))}
                value={cardNumber}
                keyboardType="number-pad"
                maxLength={16}
                placeholder={translate(pt.card_number)}
                style={styles.input}
              />
            </View>
            <View style={styles.row}>
              <View style={styles.inputContainer}>
                <Text style={styles.inputLabel}>{translate(pt.valid_thru)}</Text>
                <TextInputMask
                  type={'custom'}
                  options={{
                    mask: '99/99',
                  }}
                  autoCapitalize="none"
                  onChangeText={setValidThru}
                  value={validThru}
                  keyboardType="number-pad"
                  placeholder={'MM/AA'}
                  style={[styles.input, { marginRight: 25 }]}
                />
              </View>
              <View style={styles.inputContainer}>
                <Text style={styles.inputLabel}>{translate(pt.cc_cvv)}</Text>
                <TextInputMask
                  type={'custom'}
                  options={{
                    mask: '999',
                  }}
                  autoCapitalize="none"
                  onChangeText={setCardCVV}
                  value={cardCVV}
                  keyboardType="number-pad"
                  placeholder={'CVV'}
                  style={styles.input}
                />
              </View>
            </View>
          </View>
        </ScrollView>
        <ButtonShadow
          onPress={handleAddCard}
          backgroundColor={colors.aquaMarineTwo}
          containerStyle={styles.buttonOk}
          title={translate(pt.add_credit_card)}
        />
      </>
    );
  };

  return (
    <Modal
      style={styles.container}
      coverScreen
      visible={open}
      transparent={true}
      backdropOpacity={0.2}
      onBackdropPress={onClose}>
      {/* <FlashMessage ref={flashMessageRef} /> */}
      <KeyboardAvoidingView
        behavior={Platform.OS == 'ios' ? 'padding' : 'height'}
        style={styles.content}>
        <View style={styles.wrapper}>
          {loading ? (
            <View style={{ marginTop: 100, width: 120, height: 120, alignSelf: 'center' }}>
              <AnimatedLottieView
                source={require('app/lotties/loading.json')}
                autoPlay
                loop={true}
                style={{ width: 120, transform: [{ scale: 1.3 }] }}
              />
            </View>
          ) : (
            renderData()
          )}
        </View>
        <Image
          source={backgroundCustomSearch}
          style={{
            position: 'absolute',
            opacity: 0.7,
            bottom: 0,
            width: '100%',
            transform: [{ scale: 1.2 }],
            resizeMode: 'contain',
            zIndex: -1,
          }}
        />
      </KeyboardAvoidingView>
    </Modal>
  );
};

export default AddCreditCardModal;

const styles = StyleSheet.create({
  container: {
    margin: 0,
  },
  wrapper: {
    paddingTop: 30,
    // paddingHorizontal: 24,
    flex: 1,
  },
  logo: {
    width: 70,
    height: 70,
    borderRadius: 10,
    resizeMode: 'cover',
    marginRight: 14,
  },
  content: {
    backgroundColor: colors.white,
    marginTop: 'auto',
    height: '95%',
    borderTopLeftRadius: 47,
    borderTopRightRadius: 47,
  },
  textStyle: {
    fontFamily: fonts.REGULAR,
    color: colors.greyLight4,
    fontSize: 14,
    paddingHorizontal: 20,
    textAlign: 'center',
  },
  textHeader: {
    fontFamily: fonts.BOLD,
    fontSize: 20,
    color: colors.aquaMarineTwo,
  },
  title: {
    ...textStyles.bold16,
    color: colors.charcoalGreyTwo,
  },
  dailyPrice: {
    paddingHorizontal: 14,
    paddingVertical: 11,
    marginTop: 28,
    borderColor: colors.aquaMarineTwo,
    borderRadius: 10,
    borderWidth: 1,
  },
  hr: {
    width: '100%',
    height: 1,
    backgroundColor: colors.greyLight,
    marginVertical: 30,
  },
  creditCardButton: {
    padding: 16,
    backgroundColor: colors.greyF5,
    borderRadius: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  creditCardText: {
    ...textStyles.bold14,
    marginLeft: 8,
    color: colors.charcoalGreyTwo,
  },
  buttonOk: {
    // width: '85%',
    marginHorizontal: 28,
    height: 50,
    marginTop: 20,
    zIndex: 1,
    marginBottom: 20,
  },

  card_banners: {
    width: '60%',
    resizeMode: 'contain',
  },
  loading: {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    position: 'absolute',
    zIndex: 99,
    width: width,
    height: height,
    justifyContent: 'center',
    alignItems: 'center',
  },
  screen: {
    backgroundColor: colors.whiteTwo,
  },
  paymentMethodsContainer: {
    paddingTop: 22,
    paddingHorizontal: 22,
    paddingBottom: 30,
  },
  paymentMethodTitle: {
    borderWidth: 2,
    borderColor: colors.cornflowerBlue,
    color: colors.cornflowerBlue,
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'center',
    lineHeight: 35,
    borderRadius: 4,
    marginTop: 15,
    marginBottom: 15,
    paddingHorizontal: 14,
  },
  card: {
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    backgroundColor: colors.white,
    paddingTop: 32,
    paddingHorizontal: 22,
    paddingBottom: 32 + 45,
    top: 30,
  },
  inputContainer: {
    flex: 1,
  },
  inputLabel: {
    fontFamily: fonts.MEDIUM,
    fontSize: 15,
    color: colors.blackThree,
  },
  input: {
    marginTop: 9,
    marginBottom: 15,
    paddingHorizontal: 14,
    height: 45,
    borderRadius: 5,
    backgroundColor: colors.white,
    fontFamily: fonts.MEDIUM,
    color: colors.black,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: colors.lightPeriwinkle,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  text: {
    fontFamily: fonts.MEDIUM,
    fontSize: 16,
    color: colors.blackThree,
  },
  checkboxLabel: {
    fontSize: 12,
    fontWeight: 'bold',
    color: colors.greyBlue,
  },
  applyDiscountCodeButton: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    height: 45,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 4,
  },
  divider: {
    marginVertical: 22,
    height: 1,
    width: '100%',
    backgroundColor: colors.lightPeriwinkle,
  },
  textGreen: {
    fontWeight: 'bold',
    fontSize: 15,
    color: colors.greenBlue73,
    marginTop: 6,
  },
  summaryText: {
    fontSize: 14,
    fontWeight: '400',
    color: colors.black4,
    maxWidth: Dimensions.get('screen').width - 100,
  },
  summaryInfo: {
    fontSize: 14,
    fontWeight: '700',
    color: colors.greyLight5,
  },
  summaryTextTotal: {
    fontSize: 14,
    fontWeight: '700',
    color: colors.blackThree,
    maxWidth: Dimensions.get('screen').width - 100,
  },
  summaryInfoTotal: {
    fontSize: 14,
    fontWeight: '700',
    color: colors.greyBlue,
  },
  concludeButtonContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
  concludeButton: {
    width: Dimensions.get('screen').width,
    height: 45,
  },
});
