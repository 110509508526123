/* eslint-disable react-native/no-inline-styles */
import TagCard from 'app/components/TagCard';
import React from 'react';
import { Image, ImageBackground, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import ShadowView from '@liuyunjs/react-native-simple-shadow-view';
import colors from 'app/styles/colors';
import fonts from 'app/styles/fonts';
import { formatDistance } from 'app/services/utils';
import { translate } from 'app/i18n/src/locale';
import pt from 'app/i18n/src/locale/pt';
import CustomIcon from 'app/styles/icon';
import commons from 'app/styles/commons';
import { useNavigation } from '@react-navigation/native';

const CardGym = ({
  id,
  title,
  daily_price,
  address,
  working_hours,
  phone,
  images,
  distance,
  main_activity,
  activities,
  currentLocation,
  style,
  containerStyle,
  onPress,
}) => {
  const navigation = useNavigation();

  return (
    <TouchableOpacity
      activeOpacity={0.6}
      style={containerStyle}
      onPress={() => navigation.navigate('GymDetail', { id, currentLocation })}>
      <ShadowView style={[styles.card, style]}>
        <ImageBackground
          source={{
            uri: images.gallery.length ? images.gallery[0] : images.logo,
          }}
          imageStyle={{
            borderRadius: 10,
          }}
          style={{
            justifyContent: 'flex-start',
            height: 117,
            width: '100%',
          }}>
          <LinearGradient
            colors={[colors.black, colors.transparent, colors.transparent]}
            useAngle
            angle={0}
            style={styles.linear}>
            <Image source={{ uri: images.logo }} style={styles.logo} />
            {distance && (
              <View
                textColor={colors.darkPro}
                style={{
                  ...commons.flexRow,
                  paddingHorizontal: 8,
                  paddingVertical: 5,
                  borderRadius: 4,
                  backgroundColor: colors.white,
                }}>
                <CustomIcon name={'pin'} size={10} style={{ marginRight: 2 }} />
                <Text style={{ fontFamily: fonts.MEDIUM, fontSize: 10, color: colors.darkPro }}>
                  {formatDistance(distance)}
                </Text>
              </View>
            )}
          </LinearGradient>
        </ImageBackground>
        <View style={styles.white}>
          <View style={styles.infoWrapper}>
            <View style={styles.dailyPrice}>
              <CustomIcon
                name={'sun'}
                size={9}
                style={{ marginRight: 3 }}
                color={colors.aquaMarineTwo}
              />
              <Text style={styles.dailyText}>{translate(pt.daily_a)}</Text>
              <Text style={styles.priceText}>R$ {parseInt(daily_price, 10) || 0}</Text>
            </View>
          </View>
          <Text numberOfLines={2} style={[styles.title]}>
            {title}
          </Text>

          {main_activity?.title && (
            <View style={[commons.flexRow, { flexWrap: 'wrap' }]}>
              <TagCard style={{ marginRight: 5, marginTop: 2 }} title={main_activity.title} />
            </View>
          )}
        </View>
      </ShadowView>
    </TouchableOpacity>
  );
};

export default CardGym;

const styles = StyleSheet.create({
  content: {
    fontSize: 14,
    fontFamily: fonts.REGULAR,
    color: colors.blackTwo,
    marginHorizontal: 16,
    marginTop: 16,
  },
  linear: {
    // position: 'absolute',
    paddingHorizontal: 10,
    paddingVertical: 10,
    width: '100%',
    height: 117,
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    flexDirection: 'row',
    borderRadius: 10,
  },
  title: {
    marginTop: 8,
    fontFamily: fonts.BOLD,
    fontSize: 14,
    lineHeight: 17,
    color: colors.charcoalGreyTwo,
    minHeight: 34,
  },
  logoWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  card: {
    width: 163,
    height: 227,
    backgroundColor: colors.white,
    borderRadius: 12,
    shadowColor: 'black',
    shadowOpacity: 0.1,
    shadowRadius: 5,
    shadowOffset: { width: 0, height: 5 },
  },
  infoWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  dailyPrice: {
    borderColor: colors.aquaMarineGrd,
    borderWidth: 1,
    borderRadius: 3,
    paddingVertical: 2,
    paddingHorizontal: 5,
    flexDirection: 'row',
    alignItems: 'center',
  },
  dailyText: {
    fontSize: 9,
    fontFamily: fonts.MEDIUM,
    color: colors.aquaMarineTwo,
  },
  priceText: {
    marginLeft: 3,
    fontSize: 10,
    fontFamily: fonts.BOLD,
    color: colors.charcoalGreyTwo,
  },
  white: {
    paddingVertical: 13,
    paddingHorizontal: 7,
    height: 130,
  },
  maskContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    fontSize: 9,
    width: 40,
    resizeMode: 'cover',
    height: 40,
    borderRadius: 10,
  },
});
