import React, { useState, useEffect, useCallback } from 'react';

import { View, StyleSheet, Text, Image, TouchableOpacity, ScrollView } from 'react-native';
import colors from '../../../styles/colors';
import HeaderGeneric from '../../../components/HeaderGeneric';
import { SafeAreaView } from 'react-native-safe-area-context';
import textStyles from '../../../styles/textStyles';
import { translate } from '../../../i18n/src/locale';
import pt from '../../../i18n/src/locale/pt';
import Spacer from '../../../components/Spacer';
import ButtonBase from '../../../components/ButtonBase';
// import { WheelPicker } from 'react-native-wheel-picker-android';
import Icon from 'react-native-vector-icons/dist/FontAwesome';

import clockGrayBig from '../../../images/clockGrayBig.png';
import fonts from '../../../styles/fonts';
import { updateMeta } from 'app/services/apiMetas';
import META from 'app/configs/meta';
import { updateProfileSuccess } from 'app/store/modules/user/actions';
import { useDispatch, useSelector } from 'react-redux';
import { updateEvent } from 'app/services/apiEvents';
import EVENTS from 'app/configs/events';
import { showAlert, errorControl } from 'app/services/utils';
import { sendActionEvent } from '../../../services/utils';
import { useNavigation } from '@react-navigation/native';
// import DatePicker from 'react-native-date-picker';
import { TimePicker, TimePickerModal } from 'react-native-paper-dates';
import ButtonBaseIcon from '../../../components/ButtonBaseIcon';
import { PaperProvider, MD3LightTheme } from 'react-native-paper';
import { Picker } from '@react-native-picker/picker';
// import Picker from 'react-native-picker-select';
const HomeChangeHourScreen = ({ route }) => {

  const { hour } = route?.params;

  const navigation = useNavigation();

  const wheelPickerData = Array.from(Array(24).keys()).map(h => `${h}:00`);
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState(0);
  const [currentMinutes, setCurrentMinutes] = useState(0);
  const { profile } = useSelector(state => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (hour) {
      setCurrent(Number(hour.substr(0, 2)));
    }
  }, []);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const hourSubmit = `1970-01-01 ${wheelPickerData[current]}:00`;
      await updateMeta(META.USER_ALARM_CLOCK, hourSubmit);
      await updateEvent(EVENTS.SET_ALARM_CLOCK);
      setLoading(false);

      dispatch(updateProfileSuccess({ alarm_clock: hourSubmit }));
      sendActionEvent('profile_initday_update', { email: profile.email }, profile.email);
      navigation.goBack();
      // setTimeout(() => Actions.refresh({ refresh: Math.random() }));
    } catch (error) {
      showAlert(errorControl(error));
    }
  };

  const [visible, setVisible] = useState(false)
  const onDismiss = useCallback(() => {
    setVisible(false)
  }, [setVisible])

  const onConfirm = useCallback(
    ({ hours, minutes }) => {
      setVisible(false);
      console.log({ hours, minutes });
    },
    [setVisible]
  );

  const renderHours = () => {
    const items = []
    for (let index = 0; index < 24; index++) {
      items.push(<Picker.Item label={index.toString().padStart(2, '0') + ':00'} value={index} />)                     
    }

    return items;
  }

  return (
    <SafeAreaView style={styles.container}>
      <HeaderGeneric />
      <ScrollView style={styles.flex} contentContainerStyle={styles.grow}>
        <View style={styles.flexCenter}>
          <Image source={clockGrayBig} style={{width: 32, height: 32}} />
          <Spacer value={10} />
          <Text style={[{ textAlign: 'center' }, textStyles.bold20]}>
            {translate(pt.tell_hour)}
          </Text>
          <Spacer value={10} />
          <Text style={[{ textAlign: 'center' }, textStyles.bold16]}>
            {translate(pt.tell_hour_content)}
          </Text>
          <View style={{flexDirection: 'row', alignItems: 'center', marginTop: 16, backgroundColor: colors.white, borderRadius: 7, paddingVertical: 5, paddingHorizontal: 10}}>
          <Picker
            style={{paddingVertical: 10, fontSize: 24, fontFamily: fonts.BOLD, borderWidth: 0, width: '100%'}}
            selectedValue={current}
            onValueChange={(itemValue, itemIndex) =>
              setCurrent(itemValue)
            }>
            {
              renderHours()
            }
          </Picker>
            {/* <Text>18:00</Text>
            <TouchableOpacity onPress={() => setVisible(true)}>
              <Image source={require('app/images/editIcon.png')} style={{width: 18, height: 18}} />
            </TouchableOpacity> */}
          </View>
          {/* <PaperProvider theme={{
                ...MD3LightTheme,
                colors: {
                  ...MD3LightTheme.colors,
                  primary: colors.aquaMarineTwo,
                  onSurface: colors.darkPro,
                  onPrimaryContainer: colors.darkPro,
                  primaryContainer: colors.aquaMarineTwo + '22',
                  surfaceVariant: colors.aquaMarineTwo + '22',
                  secondaryContainer: colors.aquaMarineTwo + '22',
                  // onSurfaceVariant: colors.aquaMarineTwo
                }
              }}> */}
              {/* <View style={{marginTop: 10}}>
                <TimePicker
                  locale={pt}
                  inputType={'picker'}
                  use24HourClock
                  // inputFontSize={inputFontSize}
                  // focused={focused}
                  hours={current}
                  minutes={currentMinutes}
                  onChange={(v) => (console.log(v), setCurrent(v))}
                  // onFocusInput={onFocusInput}
                />
              </View> */}
              {/* <TimePickerModal
                visible={visible}
                use24HourClock
                cancelLabel='Cancelar'
                label='Selecione o horário'
                clockIcon={<Icon name={'clock'} color={colors.darkPro}/>}
                keyboardIcon={<Icon name={'keyboard'} color={colors.darkPro}/>}
                onDismiss={onDismiss}
                onConfirm={onConfirm}
                hours={current}
                minutes={0}
              /> */}

            {/* </PaperProvider> */}
{/* 
          <WheelPicker
            style={{ width: 300, flex: 1, marginTop: 10 }}
            hideIndicator
            selectedItemTextFontFamily={fonts.BOLD}
            itemStyle={{ fontSize: 36, fontFamily: fonts.BOLD }}
            itemTextSize={24}
            selectedItemTextSize={36}
            initPosition={parseInt(hour.split(':')[0], 10)}
            isCurved
            isCyclic
            lineMul={1}
            selectedItem={current}
            data={wheelPickerData}
            onItemSelected={setCurrent}
          /> */}
        </View>
        <View style={styles.bottom}>
          <ButtonBase
            loading={loading}
            onPress={handleSubmit}
            backgroundColor={colors.aquaMarineTwo}
            title={translate(pt.save)}
            fill
            square
          />
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default HomeChangeHourScreen;

const styles = StyleSheet.create({
  flex: { flex: 1 },
  grow: { flexGrow: 1 },
  bottom: {
    justifyContent: 'flex-end',
    alignSelf: 'stretch',
    paddingBottom: 0,
  },
  flexCenter: {
    flex: 1,
    alignItems: 'center',
    paddingHorizontal: 18,
    // justifyContent: 'space-between',
  },
  container: {
    // flex: 1,
    height: '100dvh',
    backgroundColor: colors.paleGrey,
  },
});
