import ButtonBase from 'app/components/ButtonBase';
import { translate } from 'app/i18n/src/locale';
import pt from 'app/i18n/src/locale/pt';
import colors from 'app/styles/colors';
import fonts from 'app/styles/fonts';
import React, { useState } from 'react';
import { StyleSheet, Text, View, TextInput, Modal } from 'react-native';

const ModalSaveFilter = ({ open, onClose, onSaveFilter, color }) => {
  const [filterName, setFilterName] = useState('');

  const handleConfirm = () => {
    onSaveFilter(filterName);
    onClose();
  };

  return (
    <Modal style={styles.container} visible={open} onBackdropPress={onClose}>
      <View style={styles.content}>
        <View style={styles.wrapper}>
          <Text style={styles.textStyle}>{translate(pt.save_filters)}</Text>
          <TextInput
            placeholder={translate(pt.choose_name_to_filter)}
            style={styles.input}
            onChangeText={text => setFilterName(text)}
            value={filterName}
          />
          <ButtonBase title={pt.confirm} backgroundColor={color} fill onPress={handleConfirm} />
        </View>
      </View>
    </Modal>
  );
};

export default ModalSaveFilter;

const styles = StyleSheet.create({
  container: {
    margin: 0,
  },
  wrapper: {
    alignItems: 'center',
  },
  content: {
    backgroundColor: colors.white,
    marginTop: 'auto',
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    paddingTop: 42,
    paddingHorizontal: 28,
    paddingBottom: 40,
  },
  textStyle: {
    fontFamily: fonts.BOLD,
    color: colors.black,
    fontSize: 18,
  },
  input: {
    borderRadius: 3,
    fontFamily: fonts.REGULAR,
    borderWidth: 1,
    borderColor: colors.lightPeriwinkle,
    width: '100%',
    marginBottom: 25,
    marginTop: 33,
    paddingHorizontal: 15,
  },
});
