import React, { useState, useCallback } from 'react';

import { View, StyleSheet, Text, Image, ImageBackground, TouchableOpacity } from 'react-native';
import ShadowView from '@liuyunjs/react-native-simple-shadow-view';
import { debounce } from 'lodash';
import colors from '../../../../styles/colors';
import { translate } from '../../../../i18n/src/locale';
import pt from '../../../../i18n/src/locale/pt';
import minus from '../../../../images/minus.png';
import plus from '../../../../images/plus.png';
import glassWater from 'app/images/water_glass.png';
import fonts from '../../../../styles/fonts';
import api from 'app/services/api';
import QueryString from 'qs';
import { updateProfileSuccess } from 'app/store/modules/user/actions';
import { useSelector, useDispatch } from 'react-redux';
import { showAlert, errorControl } from 'app/services/utils';
import moment from 'moment';
import { getWaterIntakes } from 'app/services/apiWaterIntakes';
import { sendActionEvent } from '../../../../services/utils';
import ShowMoreButton from 'app/components/ShowMoreButton';
import { useEffect } from 'react';
import { useNavigation } from '@react-navigation/native';

const CardWater = () => {
  const navigation = useNavigation();
  const [quantityAddMl, setQuantityAddMl] = useState(1);
  const [quantityRemoveMl, setQuantityRemoveMl] = useState(1);
  const dispatch = useDispatch();
  const { profile, day } = useSelector(state => state.user);

  useEffect(() => {
    getWater();
  }, []);

  const requestWaterIntakes = async mls => {
    const { data: user } = await api.get('user');
    try {
      await api.post(
        'water-intake',
        QueryString.stringify({
          ml: mls,
          time_stamp: moment().format('YYYY-MM-DD'),
          date: moment().format('YYYY-MM-DD HH:mm:ss'),
        })
      );
      await addWaterInstakes(mls);
      const water_intakes_result = await getWaterIntakes(moment());
      user.weekly_history = water_intakes_result.weekly_history;
      user.monthly_history = water_intakes_result.monthly_history;
      dispatch(updateProfileSuccess(user));
    } catch (error) {
      showAlert(errorControl(error));
    }
  };

  const getWater = async () => {
    const _user = {}
    const water_intakes_result = await getWaterIntakes(moment());
    _user.sum_of_the_day = water_intakes_result.sum_of_the_day[0].ML
      ? water_intakes_result.sum_of_the_day[0].ML
      : 0;
    _user.sum_of_the_week = water_intakes_result.sum_of_the_week[0].ml
      ? water_intakes_result.sum_of_the_week[0].ml
      : 0;

    _user.sum_of_the_month = water_intakes_result.sum_of_the_month[0].ml
      ? water_intakes_result.sum_of_the_month[0].ml
      : 0;

    _user.weekly_history = water_intakes_result.weekly_history;
    _user.monthly_history = water_intakes_result.monthly_history;
    dispatch(updateProfileSuccess(_user));
  };

  const addWaterInstakes = async mls => {
    if (Math.sign(mls) > 0) {
      setQuantityAddMl(1);
      dispatch(
        updateProfileSuccess({ sum_of_the_week: parseFloat(profile.sum_of_the_week) + 100 })
      );
      dispatch(
        updateProfileSuccess({ sum_of_the_month: parseFloat(profile.sum_of_the_month) + 100 })
      );
    }
    if (Math.sign(mls) < 0) {
      setQuantityRemoveMl(1);
      dispatch(
        updateProfileSuccess({ sum_of_the_week: parseFloat(profile.sum_of_the_week) - 100 })
      );
      dispatch(
        updateProfileSuccess({ sum_of_the_month: parseFloat(profile.sum_of_the_month) - 100 })
      );
    }
  };

  const formatWaterInstakesValue = waterIntakes => {
    const waterInstakesFormated = parseFloat(waterIntakes / 1000);
    return waterInstakesFormated.toString().replace('.', ',');
  };

  const sendAddMlsRequest = (countMl, day) => {
    const totalMls = countMl * 100;
    requestWaterIntakes(totalMls, day);
  };

  const sendRemoveMlsRequest = (countMl, day) => {
    const totalMls = countMl * -100;
    requestWaterIntakes(totalMls, day);
  };

  const handlerAddMls = useCallback(debounce(sendAddMlsRequest, 1000), []);
  const handlerRemoveMls = useCallback(debounce(sendRemoveMlsRequest, 1000), []);

  const addMls = () => {
    setQuantityAddMl(quantityAddMl + 1);
    dispatch(updateProfileSuccess({ sum_of_the_day: parseFloat(profile.sum_of_the_day) + 100 }));
    handlerAddMls(quantityAddMl, day);
    sendActionEvent('hydration_more', { email: profile.email }, profile.email);
  };

  const removeMls = () => {
    if (parseFloat(profile.sum_of_the_day) > 0) {
      setQuantityRemoveMl(quantityRemoveMl + 1);
      dispatch(updateProfileSuccess({ sum_of_the_day: parseFloat(profile.sum_of_the_day) - 100 }));
      handlerRemoveMls(quantityRemoveMl, day);
      sendActionEvent('hydration_less', { email: profile.email }, profile.email);
    }
  };

  return (
    <ShadowView style={styles.containerContent}>
      <View style={styles.containerGoalInfo}>
        <Text style={styles.textGoalInfo}>{translate(pt.still_missing)}</Text>
        <Text style={styles.labelGoalQuantity}>{formatWaterInstakesValue(
              (3000 - profile.sum_of_the_day) * 10 || 0
            )}</Text>
        <Text style={styles.textGoalInfo}>{translate(pt.liters_to_the_goal)}</Text>
        <ShowMoreButton
          color={colors.aquaMarineTwo}
          onPress={() => navigation.navigate('Hydration')}
          style={styles.showMoreButton}
        />
      </View>
      <View style={styles.containerButtons}>
        <TouchableOpacity onPress={addMls} style={styles.miniButton}>
          <Image source={plus} style={{ width: 16, height: 16, resizeMode: 'contain' }} />
        </TouchableOpacity>
        <TouchableOpacity
          disabled={profile.sum_of_the_day <= 0 ? true : false}
          onPress={removeMls}
          style={[styles.miniButton, { backgroundColor: profile.sum_of_the_day <= 0 ? colors.aquaMarineLight : colors.aquaMarineTwo}]}>
          <Image source={minus} style={{ width: 16, height: 16, resizeMode: 'contain' }} />
        </TouchableOpacity>
      </View>

      <View style={styles.containerWater}>
        <ImageBackground resizeMode="contain" style={styles.imgGlass} source={glassWater} >
          <Text style={styles.labelQuantityTotal}>{translate(pt.liters, { num: 3 })}</Text>
          <View style={styles.containerQuantity}>
            <Text style={styles.labelQuantity}>
              {`${formatWaterInstakesValue(
                profile.sum_of_the_day * 10 || 0
              )}`}
            </Text>
            <Text style={styles.labelQuantityLiters}>Copos</Text>
          </View>
        </ImageBackground>
      </View>
    </ShadowView>
  );
};

export default CardWater;

const styles = StyleSheet.create({
  containerContent: {
    marginHorizontal: 20,
    paddingHorizontal: 28,
    borderRadius: 10,
    flex: 1,
    height: 170,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    shadowColor: colors.black,
    shadowOpacity: 0.1,
    shadowRadius: 5,
    shadowOffset: { width: 0, height: 5 },
    backgroundColor: colors.white,
  },
  containerGoalInfo: {
    justifyContent: 'center',
    height: '100%',
  },
  containerWater: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  imgGlass: {
    height: 170,
    width: 100,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  textGoalInfo: {
    fontFamily: fonts.BOLD,
    fontSize: 14,
    color: colors.greyBlue,
  },
  labelGoalQuantity: {
    fontFamily: fonts.BLACK,
    fontSize: 32,
    color: colors.aquaMarineTwo,
  },
  showMoreButton: {
    marginTop: 10
  },
  showMoreButtonText: {
    color: colors.cornflowerBlue,
    fontFamily: fonts.BOLD,
    fontSize: 14,
    marginRight: 11,
  },
  labelQuantity: {
    fontSize: 30,
    fontFamily: fonts.BLACK,
    color: colors.darkPro,
  },
  labelQuantityLiters: {
    fontSize: 12,
    fontFamily: fonts.BOLD,
    color: colors.darkPro,
    fontWeight: 'bold',
    marginTop: -5,
  },
  containerQuantity: {
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 30,
  },
  labelQuantityTotal: {
    fontFamily: fonts.BOLD,
    color: colors.darkPro,
    fontSize: 14,
    marginTop: 30,
  },
  miniButton: {
    marginTop: 12,
    width: 33,
    height: 33,
    borderRadius: 3.2,
    backgroundColor: colors.aquaMarineTwo,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 0,
  },
  containerWaterWithButtons: {
    // flexDirection: 'row',
  },
  containerButtons: {},
});
