/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { ScrollView, StyleSheet, Text, View } from 'react-native';
// import RBSheet from 'react-native-raw-bottom-sheet';
import { useSafeArea } from 'react-native-safe-area-context';
import ButtonBase from '../../../components/ButtonBase';
import HeaderGreenLinear from '../../../components/HeaderGreenLinear';
import RowCheck from '../../../components/RowCheck';
import { translate } from '../../../i18n/src/locale';
import pt from '../../../i18n/src/locale/pt';
import iconYoga from '../../../images/iconYoga.png';
import { ProfileContext } from '../../../ProfileContext';
import colors from '../../../styles/colors';
import commons from '../../../styles/commons';
import textStyles from '../../../styles/textStyles';
import BottomSheetMuscle from './components/BottomSheetMuscle';
import { useSelector } from 'react-redux';
import { errorControl, showAlert } from 'app/services/utils';
import api from 'app/services/api';
import { sendActionEvent } from '../../../services/utils';
import { useNavigation } from '@react-navigation/native';

const ActivityOnboardingCheckList = () => {
  const [state, setState] = useContext(ProfileContext);
  const { nameCategorySelected } = useSelector(stateCategoryName => stateCategoryName.category);
  const { profile } = useSelector(profileSelectorState => profileSelectorState.user);

  const navigation = useNavigation();

  const [muscleSelected, setMuscleSelected] = useState(null);

  const [muscles, setMuscles] = useState([]);

  const refRBSheet = useRef();
  const insetsSafeArea = useSafeArea();
  const numSelected = muscles.filter(m => m.isSelected).length;

  useEffect(() => {
    requestMuscleGroup();
  }, [requestMuscleGroup]);

  const verifyPainLevelByMuscle = musclesData => {
    const newMusclesArray = musclesData.map(muscle => {
      return muscle.user_pain_level !== null ? { ...muscle, isSelected: true } : muscle;
    });
    setMuscles(newMusclesArray);
  };

  const requestMuscleGroup = useCallback(async () => {
    try {
      const { data } = await api.get('muscle-group');
      setMuscles(data);
      verifyPainLevelByMuscle(data);
    } catch (error) {
      showAlert(errorControl(error));
    }
  }, []);

  const requestSaveMuscleGroup = async () => {
    try {
      await api.patch('user-muscle-restrictions', getBodyRestrictMuscle());
    } catch (error) {
      showAlert(errorControl(error));
    }
  };

  const getBodyRestrictMuscle = () => {
    const body = muscles.reduce((item, data) => {
      if (!item.data) {
        item.data = [];
      }
      if (data.isSelected) {
        item.data.push({ muscle_restriction_id: data.id, muscle_pain_id: data.user_pain_level.id });
      }
      return item;
    }, {});
    return body;
  };

  const selectMuscle = muscle => {
    sendActionEvent('profile_muscle_update', {}, profile.email);
    if (!muscle.isSelected) {
      setMuscleSelected(muscle);
      refRBSheet.current.open();
      return;
    }
    const newMusclesArray = muscles.map(mus => {
      return mus.id === muscle.id ? { ...mus, isSelected: false, user_pain_level: null } : mus;
    });
    setMuscles(newMusclesArray);
  };

  const setPainLevel = item => {
    const newMusclesArray = muscles.map(mus => {
      return mus.id === muscleSelected.id
        ? { ...mus, isSelected: true, user_pain_level: item }
        : mus;
    });
    setMuscles(newMusclesArray);
    refRBSheet.current.close();
  };

  const closeBottomMuscle = () => {
    refRBSheet.current.close();
    const newMusclesArray = muscles.map(mus => {
      return mus.id === muscleSelected.id
        ? { ...mus, isSelected: false, user_pain_level: null }
        : mus;
    });
    setMuscles(newMusclesArray);
  };

  return (
    <View style={styles.container}>
      <HeaderGreenLinear title={nameCategorySelected} image={iconYoga} />
      <View
        style={{
          flex: 1,
          paddingBottom: insetsSafeArea.bottom,
        }}>
        <ScrollView style={[commons.flex]} contentContainerStyle={{ flexGrow: 1, paddingTop: 20 }}>
          <View useNativeDriver animation="fadeInUp" style={{ paddingHorizontal: 22 }}>
            <Text style={[textStyles.bold20, { textAlign: 'center' }]}>
              {translate(pt.physical_restriction)}
            </Text>
            <Text
              style={[textStyles.semi16, { textAlign: 'center', marginTop: 14, marginBottom: 30 }]}>
              {translate(pt.physical_restriction_content)}
            </Text>

            {muscles.map((item, index) => (
              <RowCheck
                key={index}
                item={item}
                onPress={() => {
                  selectMuscle(item);
                }}
              />
            ))}
          </View>
          {/* <RBSheet
            closeOnDragDown={false}
            closeOnPressBack={false}
            closeOnPressMask={false}
            height={340}
            ref={refRBSheet}>
            <BottomSheetMuscle
              onPress={setPainLevel}
              item={muscleSelected}
              onClose={closeBottomMuscle}
            />
          </RBSheet> */}
        </ScrollView>
        <View style={{ padding: 22 }}>
          <ButtonBase
            onPress={() => {
              requestSaveMuscleGroup();
              setState({ ...state, yoga: true });
              navigation.goBack();
              // setTimeout(() => navigation.({ refresh: Math.random() }));
            }}
            style={{ marginTop: 0, height: 49 }}
            fill
            title={translate(numSelected > 0 ? pt.conclude : pt.no_pain)}
          />
        </View>
      </View>
    </View>
  );
};

export default ActivityOnboardingCheckList;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.paleGrey,
  },
});
