export function setPrograms(payload) {
  return {
    type: '@programs/SET_PROGRAMS',
    payload,
  };
}
export function fetchPrograms(category, filters = {}, queryParams = '') {
  return {
    type: '@programs/FETCH_PROGRAMS',
    payload: { category, filters, queryParams },
  };
}