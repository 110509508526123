import React, { useState } from 'react';

import { View, StyleSheet, Text, TouchableOpacity } from 'react-native';
import ButtonBase from '../../../../components/ButtonBase';
import colors from '../../../../styles/colors';
import fonts from '../../../../styles/fonts';
import textStyles from '../../../../styles/textStyles';
import pt from '../../../../i18n/src/locale/pt';
import { translate } from '../../../../i18n/src/locale';
import RowSelect from '../../../../components/RowSelect';
import RowCheckRight from '../../../../components/RowCheckRight';

const ModalChangeIngredient = ({ onClose }) => {
  const [itens, setItens] = useState([{ title: 'Nome do Item' }, { title: 'Nome do Item ' }]);

  return (
    <View style={styles.modal}>
      <View style={styles.card}>
        <Text style={textStyles.semi16}>{translate(pt.want_change_ingredient)}</Text>
        <Text style={[textStyles.regular14, { marginTop: 0 }]}>
          {translate(pt.may_change_result)}
        </Text>
        <View style={{ marginTop: 10 }}>
          {itens.map((item, index) => (
            <RowCheckRight
              index={index}
              key={item.title}
              item={item}
              checked={item.checked}
              onPress={selectedIndex => {
                setItens(
                  itens.map((item, i) => {
                    return {
                      ...item,
                      checked: i === selectedIndex ? !item.checked : false,
                    };
                  })
                );
              }}
            />
          ))}
        </View>
        <TouchableOpacity onPress={onClose}>
          <Text style={styles.back_button}>{translate(pt.save_back)}</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default ModalChangeIngredient;

const styles = StyleSheet.create({
  back_button: {
    marginTop: 20,
    alignSelf: 'center',
    fontFamily: fonts.BOLD,
    fontSize: 14,
    color: colors.aquaMarine,
  },
  card: {
    alignSelf: 'stretch',
    marginHorizontal: 30,
    paddingHorizontal: 30,
    paddingVertical: 20,
    borderRadius: 5,
    backgroundColor: colors.white,
  },
  modal: { flex: 1, alignItems: 'center', justifyContent: 'center' },
});
