import React, { useState } from 'react';
import { View, Text, Image, StyleSheet, TouchableOpacity, TouchableWithoutFeedback } from 'react-native';
import sixDots from 'app/images/sixDots.png';
import colors from 'app/styles/colors';
import fonts from 'app/styles/fonts';
import CheckBox from 'app/components/CheckBox';
import { useEffect } from 'react';

const RowItemSelect = ({ title, onPress, isSelected, isEditable }) => {
  return (
    <TouchableOpacity style={styles.container} onPress={onPress}>
      <View style={styles.buttonContainer}>
        <Text style={styles.label}>{title}</Text>
        {isEditable ? (
          <TouchableWithoutFeedback>
            <CheckBox checked={isSelected} />
          </TouchableWithoutFeedback>
        ) : null}
      </View>
    </TouchableOpacity>
  );
};

export default RowItemSelect;

const styles = StyleSheet.create({
  container: {
    paddingVertical: 10,
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  dragIcon: {
    // padding: 10,
    height: 15,
    width: 9,
  },
  containerDragIcon: {
    padding: 10,
  },
  buttonContainer: {
    borderWidth: 1,
    borderColor: colors.lightPeriwinkle,
    paddingHorizontal: 15,
    borderRadius: 4,
    backgroundColor: colors.white,
    height: 47,
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  label: {
    fontSize: 15,
    fontFamily: fonts.BOLD,
  },
  checkBox: {
    borderRadius: 15,
  },
});
