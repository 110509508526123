/* eslint-disable radix */
/* eslint-disable react-native/no-inline-styles */
import React, { useState, useCallback, useEffect, useRef } from 'react';

import {
  View,
  Text,
  StyleSheet,
  Image,
  ImageBackground,
  ScrollView,
  Dimensions,
  TouchableOpacity,
  ActivityIndicator,
  Linking,
} from 'react-native';
import colors from '../../../styles/colors';
import ChallengeChip from '../../../components/ChallengeChip';
import textStyles from '../../../styles/textStyles';
// import ProgressCircle from 'react-native-progress-circle';
import commons from '../../../styles/commons';
import fonts from '../../../styles/fonts';
import LinearGradient from 'react-native-linear-gradient';
import timeIcon from '../../../images/timeIcon.png';
import ShadowView from '@liuyunjs/react-native-simple-shadow-view';
import HeaderTitle from '../../profile/components/HeaderTitle';
import RowTeachers from './components/RowTeachers';
import { translate } from '../../../i18n/src/locale';
import pt from '../../../i18n/src/locale/pt';
import Accordion from './components/Accordion';
import { useSafeArea } from 'react-native-safe-area-context';
import HeaderOver from '../../../components/HeaderOver';
// import { Actions } from 'react-native-router-flux';
import RowMaterial from '../masterclass/components/RowMaterial';
import { showAlert, errorControl } from 'app/services/utils';
import api from 'app/services/api';
import RowClassInternal from 'app/screens/core/explorer/components/RowClassInternal';
import { useSelector } from 'react-redux';
import ButtonBase from 'app/components/ButtonBase';
import ClassesAccordion from 'app/screens/core/explorer/components/ClassesAccordion';
import apiNode from 'app/services/apiNode';
import HandleActivity from 'app/screens/core/activities/components/HandleActivity';
import apiVimeo from 'app/services/apiVimeo';
import { useGamification } from 'app/hooks/useGamification';
// import {CircularProgress} from 'react-native-circular-progress';
import { useNavigation } from '@react-navigation/native';
import WebView from 'react-native-webview';

const { width } = Dimensions.get('window');

const MasterClassDetailScreen = ({ route }) => {
  const {
    coursepress_class_id,
    showDuration = false,
    isSipat,
    colorBase = colors.aquaMarineTwo,
  } = route?.params;
  
  const navigation = useNavigation();
  const safeAreaInsets = useSafeArea();
  const [scrollY, setScrollY] = useState(0);
  const [masterClassDetail, setMasterClassDetail] = useState({
    titles_and_picture: [],
    benefits_prerequisites: [],
    times_and_instructor: [],
  });
  const { typeShareSelected, nameShareSelected } = useSelector(state => state.category);
  const [isLoading, setIsLoading] = useState(false);
  const [benefits, setBenefits] = useState([]);
  const [prerequisites, setPrerequisites] = useState([]);
  const [classes, setClasses] = useState([]);
  const [, setForumTopics] = useState([]);
  const [material, setMaterial] = useState([]);
  const [, setPrices] = useState({ productPrice: 0, productSalePrice: 0 });
  const [collapsedDescription, setCollapsedDescription] = useState(true);
  const [ webviewH, setWebviewH ] = useState();

  const { eventsGenerationByHandleActivity } = useGamification();

  const webRef = useRef();

  useEffect(() => {
    const interval = setInterval(() => {
      if(isLoading) return;
      const iframe = document.querySelector('iframe')?.contentDocument;
      if(!iframe) return;
      const height = iframe.querySelector('.screenHeight')?.textContent;
      if(height){
        setWebviewH(parseInt(height) + 80);
        clearInterval(interval);
      }

    }, 300);

    return () => clearInterval(interval);

  }, []);

  useEffect(() => {
    requestDetailMasterClass();
  }, [requestDetailMasterClass]);

  const handleScroll = event => {
    setScrollY(event.nativeEvent.contentOffset.y);
  };

  const fillClassesInfo = async _classes => {
    if (!_classes[0] || !_classes[0].modules) {
      setClasses(_classes);
      setIsLoading(false);
      return;
    }

    let _filledClasses;

    try {
      const {
        data: { value: vimeo_access_token },
      } = await apiNode.get('/meta/vimeo_token');

      const headers = {
        Referer: 'https://namu.com.br',
        Authorization: 'bearer ' + vimeo_access_token,
      };
      _classes = _classes.map(c => {
        return {
          ...c,
          modules: c.modules.map(m => {
            const id = m.video_url.split('/').pop();
            const uri = '/videos/' + id;
            return { ...m, uri: uri };
          }),
        };
      });

      const video_uris = _classes
        .map(c => {
          return c.modules
            .filter(m => !!m.video_url)
            .map(m => {
              const id = m.video_url.split('/').pop();
              return '/videos/' + id;
            });
        })
        .flat();

      const { data } = await apiVimeo.get('/videos', {
        params: {
          fields: 'uri,name,duration,pictures.base_link',
          per_page: 100,
          uris: video_uris.join(','),
        },
      });

      _filledClasses = _classes.map(c => ({
        ...c,
        modules: c.modules.map(m => {
          let _vimeoData = data.data.find(x => x.uri === m.uri);
          _vimeoData.thumb = _vimeoData.pictures.base_link;
          return { ...m, ...data.data.find(x => x.uri === m.uri) };
        }),
      }));
    } catch (error) {
      console.log('Error', errorControl(error));
      _filledClasses = _classes;
    }
    setClasses(_filledClasses);
    setIsLoading(false);
  };

  const requestDetailMasterClass = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await api.get(`masterclass/course?course_id=${coursepress_class_id}`);
      setMasterClassDetail(data);
      getBenefits(data.benefits_prerequisites);
      getPrerequisite(data.benefits_prerequisites);

      setForumTopics(data.forum_topic);
      setMaterial(data.times_and_instructor);
      setPrices({
        productPrice:
          data.course_prices[0].meta_value === '' ? 0 : parseInt(data.course_prices[0].meta_value),
        productSalePrice:
          data.course_prices[1].meta_value === '' ? 0 : parseInt(data.course_prices[1].meta_value),
      });

      let _classes = data.classes;
      console.log('FILL CLASSESS', _classes);
      fillClassesInfo(_classes);
    } catch (error) {
      showAlert(errorControl(error));
    }
  }, [coursepress_class_id, getBenefits, getPrerequisite]);

  const getBenefits = useCallback(benefitsList => {
    const arrayBenefits = benefitsList
      .filter(benefit => {
        return benefit.meta_key.includes('cp_benefit_');
      })
      .filter(benefitText => {
        return benefitText.meta_key.includes('_text');
      });
    setBenefits(arrayBenefits);
  }, []);

  const getPrerequisite = useCallback(prerequisiteList => {
    const arrayPrerequisites = prerequisiteList.filter(prerequisite => {
      return prerequisite.meta_key.includes('prerequisite');
    });
    setPrerequisites(arrayPrerequisites);
  }, []);

  const handleOnComplete = id => {
    if (classes[0] && classes[0].modules) {
      setClasses(prev => {
        return prev.map(u => {
          return {
            ...u,
            modules: u.modules.map(m => {
              // eslint-disable-next-line eqeqeq
              return { ...m, completed: m.id == id ? true : m.completed };
            }),
          };
        });
      });
    } else {
      setClasses(prev => {
        let _newClasses = [...prev];
        return _newClasses.map(c => {
          // eslint-disable-next-line eqeqeq
          return { ...c, completed: c.id == id ? true : c.completed };
        });
      });
    }
  };

  const handleStartCourse = index => {
    const _classes =
      !!classes[0] && !!classes[0].modules ? classes.map(c => c.modules).flat() : classes;

    const playlist = _classes.map(c => ({ type: 'course', id: c.class_id || c.id, series: 0 }));
    const exercises = _classes.map(c => {
      return {
        id: c.class_id || c.id,
        name: c.class_title || c.title,
        completed: c.completed,
        media: { path: c.video_url },
      };
    });
    navigation.navigate('ActivityPlayer', {
      playlist,
      exercises,
      startFrom: index,
      onComplete: handleOnComplete,
      playerType: 'course',
    });
  };

  const getAllClasses = () => {
    return !!classes[0] && !!classes[0].modules ? classes.map(c => c.modules).flat() : classes;
  };

  const downloadFile = async () => {
    try {
      Linking.openURL(masterClassDetail?.files[0].url);
      if (!masterClassDetail?.files[0].downloaded) {
        await api.post('masterclass/complete/' + masterClassDetail?.files[0].id);
        setMasterClassDetail(prev => {
          let _new = { ...prev };
          _new.files[0].downloaded = true;
          return _new;
        });
        const { data } = await HandleActivity({
          routeName: 'masterclass_file',
          params: { id: masterClassDetail.files[0].id },
        });
        eventsGenerationByHandleActivity(data);
      }
    } catch (error) {}
  };

  const getCourseTotalDuration = () => {
    const allClasses = getAllClasses();
    if (allClasses.some(c => c.duration)) {
      return (
        Math.round(allClasses.reduce((prev, cur) => prev + cur.duration, 0) / 60 / 60) + ' horas'
      );
    }

    return masterClassDetail.times_and_instructor[0]
      ? masterClassDetail.times_and_instructor[0]?.duration
      : '-';
  };

  const getPercentDone = () => {
    const allClasses = getAllClasses();
    return Math.floor(
      allClasses.length === 0
        ? 100
        : ((allClasses.filter(c => c.completed).length +
            (masterClassDetail.files[0] && masterClassDetail.files[0].downloaded ? 1 : 0)) *
            100) /
            (allClasses.length + (masterClassDetail.files[0] ? 1 : 0))
    );
  };

  const renderClasses = () => {
    if (!!classes[0] && !!classes[0].modules) {
      return classes.map((unit, index) => (
        <ClassesAccordion title={unit.title} color={colors.aquaMarineTwo} counter={unit.modules}>
          {unit.modules.map((item, mIndex) => {
            return (
              <RowClassInternal
                masterclass_id={coursepress_class_id}
                showDuration={showDuration}
                vimeoInfo={isSipat}
                checked={item.completed}
                coverImage={masterClassDetail.titles_and_picture[0]?.course_image}
                payment_level={masterClassDetail.titles_and_picture[0]?.access_granted === 0}
                onPress={() =>
                  masterClassDetail.titles_and_picture[0]?.access_granted === 0
                    ? null //Actions.paywall({ masterclass_id: coursepress_class_id })
                    : handleStartCourse(getAllClasses().findIndex(c => c.id == item.id))
                }
                classItem={item}
              />
            );
          })}
        </ClassesAccordion>
      ));
    } else {
      return classes.map((item, index) => {
        return (
          <RowClassInternal
            masterclass_id={coursepress_class_id}
            showDuration={showDuration}
            vimeoInfo={isSipat}
            coverImage={masterClassDetail.titles_and_picture[0]?.course_image}
            payment_level={masterClassDetail.titles_and_picture[0]?.access_granted === 0}
            onPress={() =>
              masterClassDetail.titles_and_picture[0]?.access_granted === 0
                ? null //Actions.paywall({ masterclass_id: coursepress_class_id })
                : handleStartCourse(index)
            }
            classItem={item}
          />
        );
      });
    }
  };

  return (
    <>
      {isLoading ? (
        <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
          <ActivityIndicator color={colors.aquaMarineTwo} />
        </View>
      ) : (
        <View style={styles.container}>
          <HeaderOver
            color={colorBase}
            title={masterClassDetail.titles_and_picture[0]?.course_title}
            position={scrollY}
            maxSize={272}
            type={typeShareSelected}
            name={nameShareSelected}
            share={false}
          />

          <ScrollView
            scrollEventThrottle={16}
            onScroll={handleScroll}
            contentContainerStyle={{ flexGrow: 1, paddingBottom: safeAreaInsets.bottom }}>
            <View>
              <View style={styles.topImage}>
                <ImageBackground
                  source={{
                    uri: masterClassDetail.titles_and_picture[0]?.course_image,
                    headers: {
                      Authorization: api.defaults.headers.Authorization,
                    },
                  }}
                  style={{ height: 272 }}>
                  <View style={styles.topper}>
                    <LinearGradient
                      colors={[colors.transparent, colors.greyBlue42]}
                      style={styles.linear}
                    />

                    <ChallengeChip text={translate(pt.master_class)} />

                    <View style={styles.holderTitle}>
                      <View style={{ flex: 1 }}>
                        <Text style={[textStyles.bold24White]}>
                          {masterClassDetail.titles_and_picture[0]?.course_title}
                        </Text>
                      </View>
                      {/* <FavoriteButton
                        isFavorited={classData?.favorite}
                        propType="classId"
                        itemId={classData?.id}
                      /> */}
                    </View>
                  </View>
                </ImageBackground>
              </View>
              <View style={styles.capsuleHolder}>
                <ShadowView style={[styles.capsuleShadow, styles.capsule]}>
                  <Image source={timeIcon} />
                  <Text style={styles.capsuleText}>{`${getCourseTotalDuration()}`}</Text>
                </ShadowView>
                {isSipat && (
                  <ShadowView
                    style={[
                      styles.capsuleShadow,
                      styles.capsule,
                      {
                        marginLeft: 10,
                        backgroundColor:
                          getPercentDone() === 100 ? colors.aquaMarineTwo : colors.white,
                      },
                    ]}>
                    {/* {getPercentDone() !== 100 ? (
                      <CircularProgress
                        fill={getPercentDone()}
                        width={10}
                        size={20}
                        borderWidth={2}
                        containerStyle={{}}
                        tintColor={colors.aquaMarineTwo}
                        backgroundColor="#ddd"
                        // backgroundColor="#fff"
                      />
                    ) : (
                      <Image
                        source={require('app/images/checkedCircleWhite.png')}
                        style={{ width: 18, height: 18 }}
                      />
                    )} */}
                    <Text
                      style={[
                        styles.capsuleText,
                        getPercentDone() === 100 && { color: colors.white },
                      ]}>
                      {getPercentDone() === 0
                        ? 'A iniciar'
                        : getPercentDone() === 100
                        ? 'Concluído'
                        : `${getPercentDone()}% concluído`}
                    </Text>
                  </ShadowView>
                )}
              </View>
            </View>

            <View style={styles.content}>
              <View style={{ height: collapsedDescription ? 150 : 'auto', overflow: 'hidden' }}>
                {collapsedDescription && (
                  <LinearGradient
                    colors={[colors.transparent, colors.transparent, colors.paleGrey]}
                    style={[styles.linear, { zIndex: 1 }]}
                  />
                )}
                <View style={{...styles.topView, height: webviewH || 'auto'}}>
                  <WebView
                    ref={webRef}
                    source={{ html: `
                      <style>
                        @import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,300;0,6..12,400;0,6..12,500;0,6..12,600;0,6..12,700;0,6..12,800;1,6..12,300;1,6..12,400;1,6..12,500;1,6..12,600;1,6..12,700;1,6..12,800&display=swap'); 
                        * { font-family: "Nunito Sans", sans-serif; }
                        body {
                          overflow: hidden;
                        }
                        .screenHeight{
                          display:  none;
                        }
                      </style>
                      ${masterClassDetail.titles_and_picture[0]?.post_excerpt}
                      <span class="screenHeight"></span>
                      <script>
                      setTimeout(function() { 
                        document.documentElement.querySelector('.screenHeight').textContent = document.documentElement.scrollHeight;
                      },500);
                      const anchors = document.querySelectorAll('a');
                      for(let a of anchors){
                        a.addEventListener('click', e => {
                          e.preventDefault();
    
                          window.open(e.currentTarget.href, '_blank');
                        })
                      }
                      </script>
                      ` }}
                    scalesPageToFit={true}
                    automaticallyAdjustContentInsets={false}
                    allowsInlineMediaPlayback
                    originWhitelist={['*']}
                    mediaPlaybackRequiresUserAction={false}
                    startInLoadingState
                    javaScriptEnabled
                    domStorageEnabled
                    style={{flex: 1}}
                  />
                </View>
                {/* <HTML html={masterClassDetail.titles_and_picture[0]?.post_excerpt} /> */}
              </View>
              <TouchableOpacity onPress={() => setCollapsedDescription(prev => !prev)}>
                <Text style={{ color: colors.aquaMarineTwo }}>
                  {collapsedDescription ? translate(pt.show_more) : translate(pt.show_less)}
                </Text>
              </TouchableOpacity>
            </View>

            {masterClassDetail.times_and_instructor.length > 0 && (
              <>
                <HeaderTitle title={translate(pt.teacher)} />
                <View style={styles.content}>
                  <RowTeachers
                    image={masterClassDetail.times_and_instructor[0]?.instructor_pic_url}
                    name={masterClassDetail.times_and_instructor[0]?.instructor_name}
                  />

                  {masterClassDetail.times_and_instructor[0]?.instructor_description && (
                    <Text style={textStyles.regular14}>
                      {masterClassDetail.times_and_instructor[0]?.instructor_description}
                    </Text>
                  )}
                </View>
              </>
            )}

            <View style={styles.content}>
              {/* <AddCalendar style={styles.add} /> */}
              {/* <Spacer /> */}

              {benefits.length > 0 &&
                // eslint-disable-next-line eqeqeq
                benefits[0]?.meta_value != 'false' &&
                // eslint-disable-next-line eqeqeq
                benefits[0]?.meta_value != '' && (
                  <Accordion
                    title={translate(pt.see_benefits)}
                    color={colors.aquaMarineTwo}
                    arrayItens={benefits}
                    item={'benefits'}
                    type={'master_classes'}
                    id={coursepress_class_id}
                  />
                )}
              {prerequisites.length > 0 &&
              // eslint-disable-next-line eqeqeq
              prerequisites[0]?.meta_value != 'false' &&
              // eslint-disable-next-line eqeqeq
              prerequisites[0]?.meta_value != '' ? (
                <Accordion
                  title={translate(pt.prerequisites)}
                  color={colors.aquaMarineTwo}
                  arrayItens={prerequisites}
                  item={'requsites'}
                  type={'master_classes'}
                  id={coursepress_class_id}
                />
              ) : null}
            </View>

            <HeaderTitle title={translate(pt.classes)} />

            <View style={styles.content}>{renderClasses()}</View>

            <HeaderTitle title={translate(pt.material_complement)} />
            <View style={styles.content}>
              {material &&
              material.length > 0 &&
              material[0].material_pic_url.length > 0 &&
              material[0].title_material ? (
                <RowMaterial
                  masterclass_id={coursepress_class_id}
                  onPress={() => downloadFile()}
                  material={material[0]}
                  downloaded={isSipat ? masterClassDetail.files[0].downloaded : null}
                  payment_level={masterClassDetail.titles_and_picture[0]?.access_granted === 0}
                />
              ) : (
                <ButtonBase
                  backgroundColor={colorBase}
                  icon={require('app/images/download.png')}
                  iconTintColor={'white'}
                  title={translate(pt.download_material)}
                  style={{ height: 49, marginTop: 5, marginBottom: 15 }}
                  onPress={() => downloadFile()}
                />
              )}
            </View>

            {/* <View style={{ flexDirection: 'row' }}>
              <View
                style={{
                  flex: 1,
                  paddingHorizontal: 14,
                  paddingVertical: 8,
                  backgroundColor: colors.white,
                }}>
                <Text style={{ ...textStyles.regular12grey, textDecorationLine: 'line-through' }}>
                  {`De ${formatMoneyBR(prices.productPrice)}`}
                </Text>
                <Text style={textStyles.createAccount18}>{`Por ${formatMoneyBR(
                  prices.productSalePrice
                )}`}</Text>
                <Text style={textStyles.regular12grey}>{`até 12x de ${formatMoneyBR(
                  prices.productSalePrice / 12
                )}*`}</Text>
              </View>
              {/* <TouchableOpacity
                onPress={() => Actions.paywall({ masterclass_id: coursepress_class_id })}
                style={{
                  flex: 1,
                  backgroundColor: colors.greyBlue,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <Text style={textStyles.bold16White}>{translate(pt.buy)}</Text>
              </TouchableOpacity>
            </View> */}
          </ScrollView>
        </View>
      )}
    </>
  );
};

export default MasterClassDetailScreen;

const styles = StyleSheet.create({
  capsuleText: {
    marginLeft: 4,
    fontFamily: fonts.REGULAR,
    fontSize: 14,
    opacity: 0.9,
    color: colors.brownishGreyTwo,
  },
  capsuleShadow: {
    paddingHorizontal: 10,
    flexDirection: 'row',
    alignItems: 'center',
    height: 29,
    borderRadius: 18.2,
    backgroundColor: colors.whiteTwo,
  },
  add: {
    flex: 1,
    flexDirection: 'row-reverse',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  topper: { padding: 14, flex: 1, justifyContent: 'flex-end' },
  content: { paddingHorizontal: 14, marginBottom: 5 },
  holderTitle: { flexDirection: 'row', marginVertical: 10, alignItems: 'center' },
  linear: { position: 'absolute', bottom: 0, width: '100%', left: 0, height: 200 },
  capsuleHolder: {
    flexDirection: 'row',
    marginTop: -10,
    marginBottom: 20,
    justifyContent: 'flex-start',
    marginLeft: 16,
  },
  capsule: {
    backgroundColor: colors.whiteTwo,
    borderColor: '#eee',
    borderWidth: StyleSheet.hairlineWidth,
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 6,
    shadowOpacity: 0.2,
  },
  topImage: {
    height: 272,
    borderBottomRightRadius: 36,
    backgroundColor: 'grey',
    overflow: 'hidden',
  },
  container: {
    // flex: 1,
    height: '100dvh',
    backgroundColor: colors.paleGrey,
  },
  insideContainerRow: {
    marginTop: 10,
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 0,
  },
});
