import React, { useEffect, useRef, useState } from "react";

const Modal = ({
  isOpen,
  title,
  children
}) => {
  const ref = useRef(null);

  useEffect(() => {
    const changeState = () => {
      const el = ref.current;
      if (!el) return;
      if (isOpen) {
        el.showModal();
      } else {
        el.close();
      }
    }
    changeState();
  }, [isOpen]);

  return (
    <dialog ref={ref} className="modal">
      <h1 style={styles.title}>{title}</h1>
      {children}
    </dialog>
  )
}

export const ModalButton = ({
  text,
  onClick
}) => {
  return (
    <div className="modal-button" onClick={onClick}>
      {text}
    </div>
  )
}

const styles = {
  title: {
    margin: 0,
    marginBottom: '1rem',
  },
};

export default Modal;
