import produce from 'immer';

const INITIAL_STATE = {
  percentage: 0,
};

export default function water(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@chat/PERCENTAGE': {
        draft.percentage = action.payload.percentage;
      }
    }
  });
}
