export function setGlobalAlertData(globalAlert) {
  return {
    type: '@alert/SET_GLOBAL_ALERT_DATA',
    payload: { globalAlert },
  };
}

export function closeGlobalAlert() {
  return {
    type: '@alert/CLOSE_GLOBAL_ALERT',
  };
}
