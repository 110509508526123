import React from 'react';
import { View, Text, StyleSheet, Image } from 'react-native';
import womanExercisePayment from 'app/images/womanExercisePayment.png';
import fonts from 'app/styles/fonts';
import colors from 'app/styles/colors';
import ListItemChecked from 'app/screens/core/payment/components/ListItemChecked';
import textStyles from 'app/styles/textStyles';
import ShadowView from '@liuyunjs/react-native-simple-shadow-view';
import MaskLevelPaymentNew from 'app/components/MaskLevelPaymentNew';

const CardBenefits = ({ description, showImage, imageStyle, benefits, locked, containerStyle }) => {
  return (
    <ShadowView style={[styles.container, containerStyle]}>
      <View style={{ backgroundColor: colors.white, borderRadius: 10 }}>
        {locked && <MaskLevelPaymentNew containerStyles={{ height: '100%', width: '100%' }} />}
        <View style={{ padding: 24, paddingBottom: 14 }}>
          {description && (
            <Text style={[textStyles.regular14, styles.description]}>{description}</Text>
          )}
          {benefits.map(benefit => (
            <ListItemChecked
              iconName={benefit?.icon}
              iconSize={20}
              title={benefit?.text ? benefit.text : benefit}
            />
          ))}
          {showImage && (
            <Image style={[styles.imageWoman, imageStyle]} source={womanExercisePayment} />
          )}
        </View>
      </View>
    </ShadowView>
  );
};

export default CardBenefits;

const styles = StyleSheet.create({
  container: {
    width: '100%',
    backgroundColor: colors.white,
    borderRadius: 10,
    shadowColor: colors.black,
    shadowOpacity: 0.1,
    shadowRadius: 5,
    shadowOffset: { width: 0, height: 5 },
  },
  containerListItem: {
    flex: 1,
    alignItems: 'center',
    flexDirection: 'row',
  },
  description: {
    color: colors.grayText,
    marginHorizontal: 10,
    textAlign: 'center',
    marginBottom: 20,
  },
  textListItem: {
    fontFamily: fonts.BOLD,
    color: colors.greyBlue,
    fontSize: 12,
    lineHeight: 16,
  },
  imageWoman: {
    width: 180,
    height: 120,
    resizeMode: 'contain',
    position: 'absolute',
    bottom: -20,
    right: -30,
  },
});
