import apiNode from 'app/services/apiNode';
import api from 'app/services/api';
import { all, call, put, select, takeLatest, takeEvery } from 'redux-saga/effects';
import { setClasses, setClassesToShow, setLoadingClassesToShow } from './actions';
import { buildURL, explodeQuery } from 'app/services/utils';

const categoryIds = {
  FOOD: 1,
  BODY: 2,
  MIND: 3,
};

const categories = {
  food: {
    id: 1,
    name: 'Alimentação',
  },
  body: {
    id: 2,
    name: 'Corpo',
  },
  mind: {
    id: 3,
    name: 'Mente',
  },
};

const categoryNameById = id => {
  return Object.entries(categories)
    .map(([_key, value]) => value)
    .find(category => category.id === id).name;
};

const fetchClassesByUrl = async ([url, node = true]) => {
  const { data } = node ? await apiNode.get(url) : await api.get(url);
  return data;
};

export const generateQueryParamsForFilter = (filters, type = 'classes') => {
  const queryList = Object.keys(filters).map(sectionName => {
    const activeOptions = filters[sectionName].filter(option => option.active);
    let activeOptionNames;
    if (type === 'programs') {
      activeOptionNames = activeOptions.map(option => {
        if (sectionName === 'difficultyLevels') {
          switch (option.name) {
            case 'Fácil':
              return 1;
            case 'Normal':
              return 2;
            case 'Difícil':
              return 3;
            default:
              return option.name;
          }
        }
      });
    } else {
      activeOptionNames = activeOptions.map(option => option.name);
    }
    const joinedOptionNames = activeOptionNames.join('|');
    return `${sectionName}=${joinedOptionNames}`;
  });
  return queryList.join('&');
};

export function* requestClasses({ payload }) {
  try {
    const { bodyFilters, mindFilters, foodFilters } = yield select(state => state.classes);
    const { categoryId, userId } = payload;
    yield put(setLoadingClassesToShow(true));

    let selectedFilter =
      categoryId === categoryIds.BODY
        ? bodyFilters
        : categoryId === categoryIds.MIND
        ? mindFilters
        : foodFilters;

    const queryParams = generateQueryParamsForFilter(selectedFilter);
    const categoryName = categoryNameById(categoryId);

    const query = {
      limit: 50,
      groupBy: 'subcategory',
      category: categoryName,
      ...explodeQuery(queryParams),
    };

    if (userId) {
      query.userId = userId;
    }
    if (Object.keys(query).length <= 4) {
      query.limitBySubcategory = 10;
    }

    const classes = yield call(fetchClassesByUrl, [
      buildURL({
        base: 'classes',
        query,
      }),
    ]);
    yield put(setClasses(classes, categoryId));
    yield put(setLoadingClassesToShow(false));
  } catch (error) {
    console.log(error);
    // showAlert(errorControl(error));
  }
}

export function* requestClassesByUrl({ payload }) {
  try {
    const { url, categoryId } = payload;
    yield put(setLoadingClassesToShow(true));
    const classes = yield call(fetchClassesByUrl, [url]);

    yield put(setClasses(classes, categoryId));
    yield put(setLoadingClassesToShow(false));
  } catch (error) {
    // showAlert(errorControl(error));
  }
}

export function* requestClassesToShowByUrl({ payload }) {
  try {
    const { url, node } = payload;
    yield put(setLoadingClassesToShow(true));
    const classes = yield call(fetchClassesByUrl, [url, node]);
    yield put(setLoadingClassesToShow(false));
    yield put(setClassesToShow(classes));
  } catch (error) {
    // showAlert(errorControl(error));
  }
}

export default all([
  takeEvery('@classes/FETCH_CLASSES', requestClasses),
  takeLatest('@classes/FETCH_CLASSES_BY_URL', requestClassesByUrl),
  takeEvery('@classes/FETCH_CLASSES_TO_SHOW_BY_URL', requestClassesToShowByUrl),
]);
