import React, { useState, useRef, useEffect } from 'react';

import { StyleSheet, Text, TouchableWithoutFeedback, View, Image, TouchableOpacity } from 'react-native';
import colors from '../styles/colors';
import textStyles from '../styles/textStyles';
import chevronTop from '../images/chevronTop.png';
// import {  } from 'react-native-animatable';
import pinAccordion from '../images/pinAccordion.png';
import { sendActionEvent } from 'app/services/utils';
import { useSelector } from 'react-redux';

export const AccordionForText = props => {
  const { isReverse } = props;

  const { profile } = useSelector(state => state.user);
  const chevron = useRef();
  const [height, setHeight] = useState(45);
  const [open, setOpen] = useState(false);

  const Animations = {
    rotateDown: {
      0: { rotate: '0deg' },
      1: { rotate: '180deg' },
    },
    rotateUp: {
      0: { rotate: '180deg' },
      1: { rotate: '0deg' },
    },
  };

  const ReverseAnimations = {
    rotateDown: {
      0: { rotate: '180deg' },
      1: { rotate: '0deg' },
    },
    rotateUp: {
      0: { rotate: '0deg' },
      1: { rotate: '180deg' },
    },
  };

  useEffect(() => {
    setHeight(open ? 150 : 45);
  }, [open]);

  const Row = () => (
    <View style={styles.row}>
      <Text style={[textStyles.regular14, styles.title]} />
    </View>
  );

  const getRow = renderComponent => {
    return <View style={styles.row}>{renderComponent()}</View>;
  };

  const currentAnimation = isReverse ? ReverseAnimations : Animations;

  return (
    <View transition="minHeight" style={[styles.container, { minHeight: height }]}>
      <TouchableOpacity
        activeOpacity={0.8}
        onPress={() => {
          setOpen(!open);
          if (!open) {
            sendActionEvent(
              'accordion',
              {
                email: profile.email,
                accordion_item: props.item,
                item_type: props.type,
                item_id: props.id,
              },
              profile.email
            );
          }
        }}>
        <View style={[styles.header, open ? styles.headerActived : styles.headerDisabled]}>
          <Text style={textStyles.bold14WhiteWithOutAlign}> {props.title}</Text>
          <Image
            animation={open ? currentAnimation.rotateDown : currentAnimation.rotateUp}
            duration={500}
            ref={chevron}
            style={{ tintColor: props.color, width: 20, height: 20 }}
            source={chevronTop}
          />
        </View>
      </TouchableOpacity>
      {open && (
        <View animation="fadeIn" style={styles.content}>
          {getRow(props.renderComponent)}
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  content: {
    borderTopWidth: 1,
    paddingTop: 8,
    marginBottom: 8,
    borderTopColor: colors.veryLightPinkThree,
    maxWidth: 320,
  },
  title: { marginLeft: 8 },
  row: {
    flexDirection: 'row',
    marginBottom: 8,
    paddingHorizontal: 15,
    alignItems: 'center',
  },
  header: {
    flexDirection: 'row',
    width: 320,
    height: 45,
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 10,
    paddingHorizontal: 15,
  },
  headerDisabled: {
    backgroundColor: colors.blueyGrey,
  },
  headerActived: {
    backgroundColor: colors.aquaMarineTwo,
  },
  container: {
    marginBottom: 16,
  },
});
