/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useEffect, useState } from 'react';
import {
  Dimensions,
  ImageBackground,
  ScrollView,
  StyleSheet,
  Text,
  View,
  ActivityIndicator,
} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { useSafeArea } from 'react-native-safe-area-context';
import Accordion from '../../../components/Accordion';
import ButtonBase from '../../../components/ButtonBase';
import CapsuleHour from '../../../components/CapsuleHour';
import CapsuleLevel from '../../../components/CapsuleLevel';
import ChallengeChip from '../../../components/ChallengeChip';
import Chip from '../../../components/Chips';
import HeaderOver from '../../../components/HeaderOver';
import Spacer from '../../../components/Spacer';
import { translate } from '../../../i18n/src/locale';
import pt from '../../../i18n/src/locale/pt';
import colors from '../../../styles/colors';
import commons from '../../../styles/commons';
import textStyles from '../../../styles/textStyles';
import HeaderTitle from '../../profile/components/HeaderTitle';
// import AddCalendar from './components/AddCalendar';
import RowTeachers from './components/RowTeachers';
import { errorControl, showAlert } from 'app/services/utils';
import api from 'app/services/api';
import FavoriteButton from 'app/components/FavoriteButton';
import fallback from 'app/images/fallback_mind2.jpg';
import CongratsModal from 'app/screens/core/explorer/components/CongratsModal';
import { useNavigation } from '@react-navigation/native';

const { width } = Dimensions.get('window');

const MeditationLessonScreen = ({ route }) => {

  const { classId, trailId } = route?.params;

  const navigation = useNavigation();

  const safeAreaInsets = useSafeArea();
  const [scrollY, setScrollY] = useState(0);
  const [classes, setClasses] = useState({});
  const [started] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [imageTop, setImageTop] = useState(fallback);
  const [playlist, setPlaylist] = useState([]);
  const [exercises, setExercises] = useState([]);
  const [doneModal, setDoneModal] = useState(false);

  const handleScroll = event => {
    setScrollY(event.nativeEvent.contentOffset.y);
  };

  useEffect(() => {
    requestClassDetails();
  }, [requestClassDetails]);

  const requestClassDetails = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`class/detail?class_id=${classId}`);
      setClasses(data);
      setPlaylist(data.exercise_data.tree);
      setExercises(data.exercise_data.exercises);
      setLoading(false);
      setImageTop({ uri: data?.image?.path });
    } catch (error) {
      showAlert(errorControl(error));
      setLoading(false);
    }
  }, [classId]);

  const getBenefitName = () => {
    if (classes?.benefits === '') {
      return [];
    }
    const arrayBenefits = classes?.benefits?.split(',');
    return arrayBenefits;
  };

  const getRequirementName = () => {
    if (classes?.prerequisites === '') {
      return [];
    }
    if (classes?.prerequisites?.indexOf(',') > -1) {
      const arrayRequirements = classes?.prerequisites?.split(',');
      return arrayRequirements;
    }
    return [classes?.prerequisites];
  };

  const getRiskName = () => {
    if (classes?.risks === '') {
      return [];
    }
    const arrayRisks = classes?.risks?.split(',');
    return arrayRisks;
  };

  const sceneType = () => {
    if (exercises && exercises[0]?.media?.extension === 'mp3') {
      return navigation.navigate('PlayMeditation', { idClass: classId, onFinish: () => setDoneModal(true) });
    }

    navigation.navigate('ActivityPlayer',{
      lessonId: classId,
      playerType: 'meditation',
      colorBase: colors.pastelOrange,
      playlist: playlist,
      exercises: exercises,
      onFinish: () => setDoneModal(true),
    });
  };

  const exercise = classes?.exercises_series && classes?.exercises_series[0]?.exercises;

  return (
    <View style={styles.container}>
      <HeaderOver
        title={classes?.name}
        color={colors.pastelOrange}
        position={scrollY}
        maxSize={426}
        share={false}
        shareData={{
          title: classes?.name,
          screen: 'lesson_meditation',
          image: classes?.image?.path,
          description: classes?.description,
          id: classes?.id,
        }}
      />
      {isLoading ? (
        <View style={{ alignItems: 'center', justifyContent: 'center', flex: 1 }}>
          <ActivityIndicator size="large" color={colors.pastelOrange} />
        </View>
      ) : (
        <React.Fragment>
          <ScrollView
            scrollEventThrottle={16}
            onScroll={handleScroll}
            contentContainerStyle={{ flexGrow: 1, paddingBottom: safeAreaInsets.bottom }}>
            <View>
              <View style={styles.topImage}>
                <ImageBackground
                  source={imageTop}
                  onError={currentTarget => {
                    currentTarget.onError = null;
                    setImageTop(fallback);
                  }}
                  style={{ height: 426 }}>
                  <View style={styles.topper}>
                    <LinearGradient
                      colors={[colors.transparent, colors.pastelOrange80]}
                      style={styles.linear}
                    />

                    <ChallengeChip text={translate(pt.lesson)} />

                    <View style={styles.holderTitle}>
                      <View style={{ flex: 1 }}>
                        <Text style={[textStyles.bold24White]}>
                          {classes?.name}
                        </Text>
                      </View>
                      <FavoriteButton
                        isFavorited={classes?.favorite === 1}
                        propType="classId"
                        itemId={classes?.id}
                      />
                    </View>
                  </View>
                </ImageBackground>
              </View>
              <View style={styles.capsuleHolder}>
                <CapsuleHour style={styles.capsule} duration={classes?.duration} />
                <CapsuleLevel
                  difficulty={classes?.difficulty}
                  style={{ ...styles.capsule, marginLeft: 10 }}
                />
              </View>
            </View>

            <View style={styles.content}>
              <Text style={textStyles.regular14}>{classes?.description}</Text>
            </View>

            <HeaderTitle title={translate(pt.author)} />
            <View style={styles.content}>
              <RowTeachers
                subCategoryName={classes?.subcategory?.name}
                name={exercise && exercise[0]?.main_teacher?.name}
                image={exercise && exercise[0]?.main_teacher?.user_picture}
              />
            </View>

            {exercise && exercise[0]?.sub_teacher && (
              <>
                <HeaderTitle title={translate(pt.voice)} />
                <View style={styles.content}>
                  <RowTeachers
                    subCategoryName={classes?.subcategory?.name}
                    name={exercise[0]?.sub_teacher?.name}
                    image={exercise[0]?.sub_teacher?.user_picture}
                  />
                </View>
              </>
            )}

            <View style={styles.content}>
              <View style={styles.insideContainerRow}>
                {classes?.tags?.map(tag => {
                  return <Chip title={tag.name} />;
                })}
              </View>
            </View>

            <View style={styles.content}>
              {/* <View style={commons.flexRowBetween}> */}
                {/* <AddCalendar style={styles.add} typeSchedule="class" id={classId} /> */}
                {/* <Spacer value={10} />
                  <AddPlaylist style={styles.add} typeItem="class" id={classes.id} /> */}
              {/* </View> */}
              <Spacer />
              {!!classes?.benefits && (
                <Accordion
                  title={translate(pt.see_benefits)}
                  color={colors.pastelOrange}
                  arrayItens={getBenefitName()}
                  item={'benefits'}
                  type={'classes'}
                  id={classId}
                />
              )}
              {!!classes?.risks && (
                <Accordion
                  title={translate(pt.tips_and_tricks)}
                  color={colors.pastelOrange}
                  arrayItens={getRiskName()}
                  item={'risks'}
                  type={'classes'}
                  id={classId}
                />
              )}
              {!!classes?.prerequisites && (
                <Accordion
                  title={translate(pt.prerequisites)}
                  color={colors.pastelOrange}
                  arrayItens={getRequirementName()}
                  item={'requisites'}
                  type={'classes'}
                  id={classId}
                />
              )}
            </View>
          </ScrollView>
          <View style={{ backgroundColor: 'white', paddingBottom: safeAreaInsets.bottom + 20 }}>
            <View style={{ paddingHorizontal: 20 }}>
              <ButtonBase
                backgroundColor={started ? colors.white : colors.pastelOrange}
                textStyle={{ color: started ? colors.coralThree : colors.white }}
                onPress={() => sceneType()}
                fill
                style={{ height: 50 }}
                title={translate(pt.start)}
              />
            </View>
          </View>
        </React.Fragment>
      )}
      <CongratsModal
        open={doneModal}
        color={colors.orangeTwo}
        name={classes?.name}
        onClose={() => setDoneModal(false)}
        type={'class'}
        onPress={() => navigation.goBack()}
      />
    </View>
  );
};

export default MeditationLessonScreen;

const styles = StyleSheet.create({
  add: { flex: 1, backgroundColor: colors.pastelOrange },
  topper: { padding: 14, flex: 1, justifyContent: 'flex-end' },
  content: { paddingHorizontal: 14, marginBottom: 14 },
  holderTitle: { flexDirection: 'row', marginVertical: 10, alignItems: 'center' },
  linear: { position: 'absolute', width: '100%', height: 200, left: 0, bottom: 0 },
  capsuleHolder: {
    flexDirection: 'row',
    marginTop: -10,
    marginBottom: 20,
    justifyContent: 'flex-start',
    marginLeft: 16,
  },
  capsule: {
    backgroundColor: colors.whiteTwo,
    borderColor: '#eee',
    borderWidth: StyleSheet.hairlineWidth,
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 6,
    shadowOpacity: 0.2,
  },
  topImage: {
    height: 426,
    borderBottomRightRadius: 36,
    backgroundColor: 'grey',
    overflow: 'hidden',
  },
  container: {
    // flex: 1,
    height: '100dvh',
    backgroundColor: colors.paleGrey,
  },
  insideContainerRow: {
    marginTop: 10,
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 0,
  },
});
