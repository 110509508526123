import { translate } from 'app/i18n/src/locale';
import pt from 'app/i18n/src/locale/pt';
import React from 'react';
import { Image, StyleSheet, Text, View } from 'react-native';
import appleIcon from '../images/appleIcon.png';
import colors from '../styles/colors';

const CapsuleCalsNew = ({ style, kcal }) => (
  <View style={[styles.capsule, style]}>
    <Image source={appleIcon} />
    <Text style={[styles.capsuleText]}>{kcal}kcal</Text>
  </View>
);

export default CapsuleCalsNew;

const styles = StyleSheet.create({
  capsuleText: {
    marginLeft: 2,
    fontWeight: '400',
    fontSize: 10,
    color: colors.grayText,
  },
  capsule: {
    paddingHorizontal: 4,
    flexDirection: 'row',
    alignItems: 'center',
    height: 18,
    borderWidth: 1,
    borderColor: colors.disabledGray,
    borderRadius: 3,
    backgroundColor: colors.white,
  },
});
