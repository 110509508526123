/* eslint-disable react-native/no-inline-styles */
import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, Image, View, TouchableOpacity, StatusBar } from 'react-native';
import { useSafeArea } from 'react-native-safe-area-context';

import colors from '../../../styles/colors';
import textStyles from '../../../styles/textStyles';
import CustomIcon from 'app/styles/icon';
import fonts from 'app/styles/fonts';
import 'moment';
import 'moment/locale/pt-br';
import moment from 'moment';
import backgroundCustomSearch from 'app/images/backgroundCustomSearch.png';
import AnimatedLottieView from 'lottie-react-native';
import chevronLeft from 'app/images/chevronLeft.png';
import apiNode from 'app/services/apiNode';
import { useNavigation } from '@react-navigation/native';

moment.locale('pt-br');

const CheckinConfirmationScreen = ({ route }) => {
  const { gymId } = route?.params;
  const [isLoading, setIsLoading] = useState(true);
  const [success, setSuccess] = useState();
  const [title, setTitle] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [errorCode, setErrorCode] = useState();
  const [token, setToken] = useState('');
  const insets = useSafeArea();

  const navigation = useNavigation();

  useEffect(() => {
    check();
  }, []);

  const check = async () => {
    try {
      const { data } = await apiNode.post(`namu-pass/book/${gymId}`);

      if (data.success) {
        const tokenResponse = data.token;
        setToken(tokenResponse);
        setSuccess(true);
      }
    } catch (err) {
      const titleError = err?.response?.data?.title || 'Check-in não realizado';
      const messageError =
        err?.response?.data?.message ||
        'Ops, parece que ocorreu um erro. Tente novamente mais tarde.';
      const _errorCode =
        err?.response?.data?.errorCode || false;
      setTitle(titleError);
      setErrorCode(_errorCode);
      setErrorMessage(messageError);
      console.log('ER', JSON.stringify(err));
    }
    setIsLoading(false);
  };

  return (
    <View
      style={{
        ...styles.container,
        backgroundColor: isLoading ? colors.greyF5 : success ? colors.aquaMarineTwo : '#FF4E4E',
      }}>
      <StatusBar
        translucent
        backgroundColor="transparent"
        barStyle={isLoading ? 'dark-content' : 'light-content'}
      />
      {isLoading ? (
        <View
          style={{
            alignItems: 'center',
            backgroundColor: colors.greyF5,
            justifyContent: 'center',
            flex: 1,
          }}>
          <View style={{ marginBottom: 100 }}>
            <View style={{ marginBottom: 5, width: 120, height: 120, alignSelf: 'center' }}>
              <AnimatedLottieView
                source={require('app/lotties/loading.json')}
                autoPlay
                loop={true}
                style={{ width: 120, transform: [{ scale: 1.3 }] }}
              />
            </View>
            <Text style={textStyles.bold24blackTwo}>{'Confirmando reserva'}</Text>
            <Text style={{ ...textStyles.medium16, color: colors.greyDesc, marginTop: 5 }}>
              {'Por favor, aguarde alguns instantes'}
            </Text>
          </View>
        </View>
      ) : (
        <>
          <View
            useNativeDriver
            animation="fadeInDown"
            style={{
              position: 'absolute',
              top: 0,
              left: 20,
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              zIndex: 9,
              marginTop: insets.top + 20,
            }}>
            <TouchableOpacity
              style={{ ...styles.backButton, marginRight: 30 }}
              onPress={() => navigation.goBack()}>
              <Image
                source={chevronLeft}
                style={{
                  ...styles.backIcon,
                  tintColor: success ? colors.aquaMarineTwo : colors.darkRed,
                }}
              />
            </TouchableOpacity>
            <Text style={textStyles.bold18White}>{success ? 'Check-in confirmado' : title}</Text>
          </View>

          <View style={{ flex: 1, justifyContent: 'center' }}>
            {success ? (
              <View
                delay={400}
                useNativeDriver
                animation="fadeInDown"
                style={{ backgroundColor: colors.white, borderRadius: 10, padding: 25 }}>
                <View
                  style={{
                    backgroundColor: colors.white,
                    padding: 15,
                    borderRadius: 120,
                    alignSelf: 'center',
                    marginTop: -95,
                  }}>
                  <CustomIcon name={'check_circle_o'} color={colors.aquaMarineTwo} size={100} />
                </View>

                <Text style={{ ...textStyles.bold16, color: colors.greyDesc, textAlign: 'center' }}>
                  A academia recebeu sua reserva. Comunique na recepção seu check-in via Namu Pass.
                </Text>

                {!!token && (
                  <>
                    <Text
                      style={{
                        ...textStyles.regular16,
                        color: colors.greyDesc,
                        marginVertical: 15,
                        textAlign: 'center',
                      }}>
                      Caso solicitado, informe o código
                    </Text>

                    <View
                      style={{
                        backgroundColor: colors.aquaMarineTwo,
                        borderRadius: 60,
                        padding: 15,
                      }}>
                      <Text
                        style={{
                          ...textStyles.bold20White,
                          letterSpacing: 8,
                          textAlign: 'center',
                        }}>
                        {token}
                      </Text>
                    </View>
                  </>
                )}
              </View>
            ) : (
              <View
                delay={400}
                useNativeDriver
                style={{ alignItems: 'center', marginBottom: 50, marginHorizontal: 40 }}
                animation="fadeInDown">
                {errorCode && <Text
                  style={{
                    fontFamily: fonts.MEDIUM,
                    fontSize: 18,
                    color: colors.white,
                    textAlign: 'center',
                    marginBottom: 20,
                  }}>
                  Código do erro: {errorCode}
                </Text>}
                <CustomIcon name={'close_circle'} color={colors.white} size={82} />
                <Text
                  style={{
                    fontFamily: fonts.MEDIUM,
                    fontSize: 18,
                    color: colors.white,
                    textAlign: 'center',
                    marginTop: 30,
                  }}>
                  {errorMessage}
                </Text>
              </View>
            )}
          </View>
        </>
      )}

      <Image
        source={backgroundCustomSearch}
        style={{
          position: 'absolute',
          tintColor: isLoading ? colors.aquaMarineTwo : 'white',
          bottom: 0,
          width: '100%',
          transform: [{ scale: 1.2 }],
          resizeMode: 'contain',
          zIndex: -1,
        }}
      />
    </View>
  );
};

export default CheckinConfirmationScreen;

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    height: '100vh',
    backgroundColor: '#5AD8DA',
    flexDirection: 'row',
    paddingHorizontal: 20,
  },
  backIcon: { width: 28, height: 28, tintColor: colors.aquaMarineTwo },
  backButton: {
    width: 40,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.white,
    borderRadius: 4,
  },
});
