import React, { useCallback, useEffect, useState, Fragment } from 'react';

import { View, ScrollView, StyleSheet, Text, SafeAreaView } from 'react-native';
import HeaderTitle from './HeaderTitle';
import { translate } from '../../../i18n/src/locale';
import pt from '../../../i18n/src/locale/pt';
import RowWeight from './row/RowWeight';
import colors from '../../../styles/colors';
import editIcon from '../../../images/editIcon.png';
import Chip from '../../../components/Chips';
import commons from '../../../styles/commons';
import HeaderContentWhite from './HeaderContentWhite';

import fonts from '../../../styles/fonts';
import { errorControl, showAlert } from 'app/services/utils';
import api from 'app/services/api';
import apiNode from 'app/services/apiNode';
import { useSelector } from 'react-redux';
import { GoalChip } from 'app/screens/core/gamification/GoalChip';
import moment from 'moment';
import genders from 'app/constants/genders';
// import { useIsFocused } from 'react-navigation-hooks';
import { useNavigation, useIsFocused } from '@react-navigation/native';

const ProfileView = ({ index, refresh }) => {

  const navigation = useNavigation();

  const [infoUser, setInfoUser] = useState({});
  const [userChronicDisease, setUserChronicDisease] = useState([]);
  const [formatedHour, setFormatedHour] = useState('00:00');
  const { profile } = useSelector(state => state.user);
  const { currentGoal } = useSelector(state => state.gamification);
  const [achievements, setAchievements] = useState({});

  const isFocused = useIsFocused();

  const getAchievements = async () => {
    try {
      const { data } = await apiNode.get('/game-user-achievements/max-points');

      setAchievements(data);
    } catch (error) {}
  };

  useEffect(() => {
    if (isFocused) {
      requestMyInfo();
      profile?.b2b_user && profile?.plan_meta?.game_enabled && getAchievements();
    }
  }, [requestMyInfo, isFocused]);

  useEffect(() => {
    requestUserDisease();
  }, []);

  const requestMyInfo = useCallback(async () => {
    try {
      const { data } = await api.get('user/profile');

      setInfoUser(data);
      if (data.alarm) {
        getFormatedHour(data.alarm);
      }
    } catch (error) {
      showAlert(errorControl(error));
    }
  }, [isFocused]);

  const requestUserDisease = async () => {
    try {
      const { data } = await api.get('chronic-diseases/associated-diseases');

      setUserChronicDisease(data);
    } catch (error) {
      showAlert(errorControl(error));
    }
  };

  const getFormatedHour = hour => {
    const hourMinSec = hour.split(' ')[1];
    if (hourMinSec.length === 7) {
      setFormatedHour(`0${hourMinSec.substring(0, hourMinSec.length - 3)}`);
      return;
    }
    setFormatedHour(hourMinSec.substring(0, hourMinSec.length - 3));
  };

  const userInterests = infoUser?.interests?.map(interest => {
    return {
      id: interest.id,
      name: interest.name,
    };
  });
  let userRestriction = [];
  infoUser?.dietary_restrictions?.forEach(dietRestriction =>
    userRestriction.push({ name: dietRestriction.dietary_restriction_name, id: dietRestriction.id })
  );

  return (
    <SafeAreaView style={commons.flex}>
      <ScrollView style={styles.scroll} contentContainerStyle={styles.container}>
        <View style={styles.insideContainer}>
          {infoUser?.weight && <RowWeight weight={infoUser?.weight} date={infoUser?.weight_date} />}
        </View>
        <>
          <HeaderTitle
            title={translate(pt.birthdate)}
            buttonIcon={editIcon}
            onPress={() => navigation.navigate('BirthdateEditing', { profile })}
          />
          <View style={styles.insideContainer}>
            <View style={styles.chipsContainer}>
              <Chip
                title={
                  <Fragment>
                    <Text style={styles.metaTextStyle}>
                      {profile?.birthdate
                        ? moment(profile?.birthdate).format('DD/MM/YYYY')
                        : 'Data de nascimento não informada'}
                    </Text>
                  </Fragment>
                }
              />
            </View>
          </View>
        </>

        <HeaderTitle
          title={translate(pt.gender)}
          buttonIcon={editIcon}
          onPress={() => navigation.navigate('GenderEditing', { profile })}
        />
        <View style={styles.insideContainer}>
          <View style={styles.chipsContainer}>
            <Chip
              title={
                <Fragment>
                  <Text style={styles.metaTextStyle}>
                    {genders.find(g => g.value === profile?.gender)?.text}
                  </Text>
                </Fragment>
              }
            />
          </View>
        </View>

        {profile?.phone_number && (
          <>
            <HeaderTitle title={translate(pt.cellphone)} />
            <View style={styles.insideContainer}>
              <View style={styles.chipsContainer}>
                <Chip
                  title={
                    <Fragment>
                      <Text style={styles.metaTextStyle}>
                        {profile?.phone_number ? profile.phone_number : 'Sem Número'}
                      </Text>
                    </Fragment>
                  }
                />
              </View>
            </View>
          </>
        )}

        {profile?.b2b_user && profile?.plan_meta?.game_enabled && (
          <Fragment>
            <HeaderTitle title={translate(pt.greatest_achievements)} />
            <View style={styles.insideContainer}>
              <View style={styles.chipsContainer}>
                {achievements?.goal_achievement &&
                  !!Object.keys(achievements?.goal_achievement).length && (
                    <GoalChip goal={achievements?.goal_achievement} />
                  )}
                {achievements?.rank_achievement &&
                  !!Object.keys(achievements?.rank_achievement).length && (
                    <GoalChip goal={achievements.rank_achievement} />
                  )}
              </View>
            </View>

            <HeaderTitle
              title={translate(pt.goals)}
              buttonIcon={editIcon}
              onPress={() => navigation.navigate('GoalEditing')}
            />
            <View style={styles.insideContainer}>
              <View style={styles.chipsContainer}>
                {currentGoal && currentGoal?.name && (
                  <Chip
                    title={
                      <Fragment>
                        <Text style={styles.metaTextStyle}>Meta de dias ativos:</Text>{' '}
                        {currentGoal?.name}
                      </Fragment>
                    }
                  />
                )}
              </View>
            </View>
          </Fragment>
        )}

        <HeaderTitle
          title={translate(pt.restrict_muscle)}
          buttonIcon={editIcon}
          onPress={() => navigation.navigate('ActivityOnboardingCheckList')}
        />
        <View style={styles.insideContainerRow}>
          {infoUser?.muscle_restrictions?.map(muscleRestriction => {
            return <Chip title={muscleRestriction.name} />;
          })}
        </View>
        <HeaderTitle
          title={translate(pt.objectives)}
          buttonIcon={editIcon}
          onPress={() => navigation.navigate('Objectives')}
        />
        <View style={styles.insideContainerRow}>
          {infoUser?.goals?.map(goal => {
            return <Chip title={goal.goal_name} />;
          })}
        </View>
        <HeaderTitle
          title={translate(pt.interests)}
          buttonIcon={editIcon}
          onPress={() => navigation.navigate('Interests', { userInterests })}
        />
        <View style={styles.insideContainerRow}>
          {infoUser?.interests?.map(interest => {
            return <Chip title={interest.name} />;
          })}
        </View>
        <HeaderTitle
          title={translate(pt.food_restrict)}
          buttonIcon={editIcon}
          onPress={() =>
            navigation.navigate('MealOnboardingRestrict', { profile: true, userRestrictions: userRestriction })
          }
        />
        <View style={styles.insideContainer}>
          <View style={styles.chipsContainer}>
            {infoUser?.dietary_restrictions?.map(dietaryRestriction => {
              return <Chip title={dietaryRestriction.dietary_restriction_name} />;
            })}
          </View>
        </View>

        <HeaderTitle
          title={translate(pt.chronic_disease)}
          buttonIcon={editIcon}
          onPress={() =>
            navigation.navigate('ChronicDeseaseOnboardingRestrict', {
              profile: true,
              userChronicDisease: userChronicDisease,
            })
          }
        />
        <View style={styles.insideContainer}>
          <View style={styles.chipsContainer}>
            {userChronicDisease.map(dietaryRestriction => {
              return <Chip key={dietaryRestriction.name} title={dietaryRestriction.name} />;
            })}
          </View>
        </View>

        <HeaderTitle
          title={translate(pt.begin_day)}
          buttonIcon={editIcon}
          onPress={() => navigation.navigate('HomeChangeHour',{ hour: formatedHour })}
        />
        <View style={styles.insideContainer}>
          <HeaderContentWhite title={translate(pt.begin_activities)} content={formatedHour} />
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default ProfileView;

const styles = StyleSheet.create({
  tabUnderline: {
    backgroundColor: colors.charcoalGreyTwo,
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
  },
  tabTitle: { fontFamily: fonts.REGULAR, fontSize: 14 },
  scrollableTabView: {
    marginTop: 20,
    paddingLeft: 10,
    borderBottomWidth: 0,
    backgroundColor: 'white',
  },
  data_goals: { marginHorizontal: 15 },
  chipsContainer: { flexDirection: 'row', flexWrap: 'wrap' },
  button: { marginBottom: 15 },
  insideContainer: {
    justifyContent: 'center',
    marginHorizontal: 15,
    marginBottom: 0,
  },
  insideContainerRow: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginHorizontal: 15,
    marginBottom: 0,
  },
  container: {
    paddingVertical: 20,
  },
  scroll: {
    flex: 1,
  },
  metaTextStyle: {
    fontFamily: fonts.BOLD,
    fontSize: 12,
    color: colors.brownishGrey,
  },
});
