import React, { useState, useRef, useContext, useEffect, useCallback } from 'react';

import { View, StyleSheet, Text, ScrollView, TouchableOpacity } from 'react-native';
import HeaderRedLinear from '../../../components/HeaderRedLinear';
import { translate } from '../../../i18n/src/locale';
import pt from '../../../i18n/src/locale/pt';
import iconMeal from '../../../images/iconMeal.png';
import colors from '../../../styles/colors';
import commons from '../../../styles/commons';
import Spacer from '../../../components/Spacer';
import textStyles from '../../../styles/textStyles';
import { sampleAllergies } from '../../../mocks';
import CardSelect from '../../../components/CardSelect';
import ButtonBase from '../../../components/ButtonBase';
import { ProfileContext } from '../../../ProfileContext';
import api from 'app/services/api';
import { errorControl, showAlert } from 'app/services/utils';
import { ActivityIndicator } from 'react-native';
import { sendActionEvent } from '../../../services/utils';
import { useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';

const ChronicDiseaseOnboardingRestrictScreen = ({ route }) => {

  const { id, userChronicDisease, profile } = route?.params;

  const navigation = useNavigation();

  const [restrictions, setRestrictions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useContext(ProfileContext);
  const user = useSelector(state => state.user);

  useEffect(() => {
    requestRestrict();
  }, [requestRestrict]);

  const setSelecteds = newArrayRestrictions => {
    const uniqueArray = newArrayRestrictions.reduce((unique, restrict) => {
      if (!unique.some(obj => obj.id === restrict.id)) {
        unique.push(restrict);
      }
      return unique;
    }, []);
    const restrictionsSorted = uniqueArray.sort((restrictOne, restrictTwo) => {
      return restrictOne.id - restrictTwo.id;
    });
    setRestrictions(restrictionsSorted);
  };

  const setUserRestrctions = restrictionsReturn => {
    const diff = userChronicDisease.filter(
      restriction => !restrictionsReturn.includes(restriction)
    );
    const dietRestrictionsSelected = diff.map(res => {
      return { ...res, isSelected: true };
    });
    const newArrayRestrictions = [...dietRestrictionsSelected, ...restrictionsReturn];
    setSelecteds(newArrayRestrictions);
  };

  const requestRestrict = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await api.get('chronic-diseases');
      if (userChronicDisease) {
        setUserRestrctions(data);
      } else {
        setRestrictions(data);
      }
    } catch (error) {
      showAlert(errorControl(error));
    }
    setIsLoading(false);
  }, []);

  const selectItemRestrict = idRestrict => {
    const newArrayRestrictions = restrictions.map(restrict => {
      return restrict.id === idRestrict
        ? { ...restrict, isSelected: !restrict.isSelected }
        : restrict;
    });
    setRestrictions(newArrayRestrictions);
  };

  const createBodyRestrictRequest = () => {
    const arrayDiseases = restrictions
      .filter(item => item.isSelected)
      .map(item => {
        return item.id;
      });

    const body = {
      diseases: arrayDiseases,
    };
    return body;
  };

  const requestSaveRestrictions = async () => {
    try {
      await api.patch('chronic-diseases', createBodyRestrictRequest());
      setState({ ...state, meal: true });
      profile ? navigation.goBack() : navigation.reset();
      sendActionEvent('profile_meal_update', { email: user.profile.email }, profile.email);
      // setTimeout(() => Actions.refresh({ refresh: Math.random() }));
    } catch (error) {
      showAlert(errorControl(error));
    }
  };

  return (
    <View style={styles.container}>
      <HeaderRedLinear
        title={translate(pt.chronic_disease)}
        bgColor={[colors.aquaMarineTwo, colors.aquaMarineTwo2]}
      />
      {isLoading ? (
        <View style={styles.containerLoading}>
          <ActivityIndicator color={colors.orangeyRed} />
        </View>
      ) : (
        <ScrollView style={commons.flex} contentContainerStyle={styles.contentContainerStyle}>
          <Text style={styles.title}>{translate(pt.chronic_disease)}</Text>
          <Text style={textStyles.semi14}>{translate(pt.what_chronic_illnesses_do_you_have)}</Text>

          <Spacer value={26} />
          <View style={styles.containerCards}>
            {restrictions.map(restriction => {
              return (
                <TouchableOpacity
                  onPress={() => {
                    selectItemRestrict(restriction.id);
                  }}>
                  <CardSelect restriction={restriction} />
                </TouchableOpacity>
              );
            })}
          </View>
          <ButtonBase
            backgroundColor={colors.aquaMarineTwo}
            title={translate(pt.procede)}
            onPress={requestSaveRestrictions}
          />
        </ScrollView>
      )}
    </View>
  );
};

export default ChronicDiseaseOnboardingRestrictScreen;

const styles = StyleSheet.create({
  containerCards: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    flex: 1,
  },
  multiSelectView: { marginTop: 20, alignItems: 'center', justifyContent: 'center' },
  containerLoading: { flex: 1, justifyContent: 'center', alignItems: 'center' },
  title: {
    marginBottom: 8,
    fontSize: 20,
    fontWeight: '500',
    textAlign: 'center',
    color: colors.black,
  },
  contentContainerStyle: {
    alignItems: 'center',
    paddingHorizontal: 27,
    paddingVertical: 37,
  },
  container: {
    // flex: 1,
    height: '100dvh',
    backgroundColor: colors.paleGrey,
  },
});
