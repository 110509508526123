import React, { useState, useEffect } from 'react';
import { View, StyleSheet, FlatList, ActivityIndicator, ScrollView } from 'react-native';
import fonts from 'app/styles/fonts';
import colors from 'app/styles/colors';
import HeaderAquamarine from 'app/components/HeaderAquamarine';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useSelector } from 'react-redux';
import CardClassesNew from 'app/screens/profile/components/CardClassesNew';
import { debounce } from 'lodash';
import { buildURL, explodeURL, sendActionEvent } from 'app/services/utils';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PERFORMANCE from 'app/configs/performance';
import { setNameShare } from 'app/store/modules/category/actions';
import SearchBarNew from 'app/components/SearchBarNew';
import apiNode from 'app/services/apiNode';
import api from 'app/services/api';
import FilterResult from 'app/screens/core/explorer/components/FilterResult';
import { useNavigation } from '@react-navigation/native';

const cardTypes = {
  VERTICAL: 1,
  HORIZONTAL: 2,
  LAST_ACCESSED: 3,
  PROGRAMS: 4,
  RECIPE_SUGGESTION: 5,
};

const ListClassesScreen = ({ route }) => {
  const {
    title = 'Todas as aulas',
    categoryId,
    color,
    url,
    isCalendar = false,
    node = true,
    type,
    paginate = false,
    canSearch = true,
    onPressAdd,
    filters = [],
  } = route?.params;

  const navigation = useNavigation();
  const dispatch = useDispatch();
  const { profile } = useSelector(state => state.user);
  const [formattedClasses, setFormattedClasses] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const [searching, setSearching] = useState(false);
  const [ended, setEnded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [classes, setClasses] = useState([]);
  const [page, setPage] = useState(0);
  const { base, query } = explodeURL(url);
  const API = node ? apiNode : api;

  useEffect(() => {
    console.log(filters);
    requestClasses();
  }, []);

  const nextPage = useCallback(async () => {
    if (!loading && !ended) {
      setPage(page + 1);
      query.page = page;
      requestClasses();
    }
  });

  const requestClasses = useCallback(async () => {
    setLoading(true);
    const { data } = await API.get(
      buildURL({
        base,
        query: {
          ...query,
          userId: profile.user_id,
          like: searchQuery,
        },
      })
    );
    if (!data.length) {
      setEnded(true);
      setLoading(false);
      return;
    }
    setClasses(data);
  }, [searchQuery]);

  useEffect(() => {
    setLoading(true);

    let formattedFilteredClasses = formatDataForRender(classes);
    if (url.includes('/user-favorites-classes/get-by-user-id/')) {
      formattedFilteredClasses = formattedFilteredClasses.map(item => {
        return {
          ...item,
          favorite: true,
        };
      });
    }
    if (!searching) {
      setFormattedClasses([...formattedClasses, ...formattedFilteredClasses]);
    } else {
      setFormattedClasses(formattedFilteredClasses);
      setSearching(false);
    }
    setLoading(false);
  }, [classes]);

  const openTrailNew = useCallback((categoryIdFromTrail, id, typeFromTrail) => {
    if (categoryIdFromTrail === 1) {
      navigation.navigate('PlateDetail', { plateId: id });
    } else if (categoryIdFromTrail === 2) {
      navigation.navigate('LessonActivity', { lessonId: id });
    } else if (categoryIdFromTrail === 3) {
      navigation.navigate('LessonMeditation', { classId: id });
    }
  }, []);

  const formatDataForRender = data => {
    if (!data) {
      return [];
    }
    return data.map(item => {
      if (item.class) {
        return item.class;
      }
      return item;
    });
  };

  const getGradientColor = categoryIdFromGradient => {
    if (categoryIdFromGradient === 1) {
      return 'red';
    }
    if (categoryIdFromGradient === 2) {
      return 'green';
    }
    return 'orange';
  };

  const getHeaderGradientColor = () => {
    if (categoryId === 1 || color === colors.grapefruit) {
      return [colors.grapefruit, colors.orangeyRed];
    }
    if (categoryId === 3 || color === colors.pastelOrange) {
      return [colors.orangeTwo, colors.orangeThree];
    }
    return [colors.aquaMarineTwo, colors.aquaMarineGrd];
  };

  useEffect(() => {
    if (searching) {
      setFormattedClasses([]);
      requestClasses();
    }
  }, [searching]);

  const handleSearch = () => {
    setSearching(true);
  };

  return (
    <View style={{ height: '100dvh', backgroundColor: colors.paleGrey }}>
      <HeaderAquamarine
        title={title}
        textStyle={styles.headerText}
        useGradient
        gradientColors={getHeaderGradientColor()}
        color={color}
        onPress={()=>navigation.canGoBack() && navigation.goBack()}
      />
      <ScrollView style={{ flex: 1, paddingTop: 20 }}>
        {canSearch && (
          <View style={{ paddingHorizontal: 20, paddingBottom: 10 }}>
            <SearchBarNew value={searchQuery} onChange={setSearchQuery} onSearch={handleSearch} />
          </View>
        )}
        {loading && !formattedClasses.length ? (
          <View style={styles.flexCenter}>
            <ActivityIndicator size="large" color={colors.aquaMarineTwo} />
          </View>
        ) : (
          <View style={{ alignItems: 'center', width: '100%' }}>
            <FlatList
              showsVerticalScrollIndicator={true}
              data={formattedClasses}
              // contentContainerStyle={{alignItems: 'center',}}
              keyExtractor={item => item.id}
              numColumns={6}
              onEndReached={paginate && nextPage}
              style={{ width: '100%', paddingHorizontal: 18 }}
              columnWrapperStyle={{ justifyContent: 'center', flexWrap: 'wrap', flex: 1 }}
              // columnWrapperStyle={{ flexWrap: 'wrap', flex: 1, marginTop: 5 }}
              horizontal={false}
              ListHeaderComponent={
                !!filters.length &&
                !loading && (
                  <FilterResult style={{ marginBottom: 20, marginTop: 0 }} filters={filters} />
                )
              }
              ListFooterComponent={
                loading && (
                  <View style={styles.flexCenter}>
                    <ActivityIndicator size="small" color={colors.aquaMarineTwo} />
                  </View>
                )
              }
              renderItem={({ item }) => {
                return type === cardTypes.RECIPE_SUGGESTION ? (
                  <CardClassesNew
                    type={item.subcategory_name}
                    title={item.name}
                    image={item.image_path}
                    difficulty={item.difficulty}
                    duration={item.duration}
                    kcal={parseInt(item.calories, 10)}
                    plus={null}
                    minus={null}
                    paymentLevel={item.payment_level}
                    onPress={debounce(() => {
                      item.payment_level === 1
                        ? null//Actions.paywall()
                        : navigation.navigate('PlateDetail', { plateId: item.id });
                      dispatch(setNameShare(item.name));
                    }, PERFORMANCE.ON_PRESS_DEBOUNCE_TIME)}
                    containerStyle={{ marginHorizontal: 5, marginVertical: 9 }}
                    gradientColor={getGradientColor(item.category_id)}
                    goals={[]}
                    // goals={item.tags.map(tag => ({
                    //   goal_name: tag
                    // }))}
                    id={item.id}
                  />
                ) : (
                  <CardClassesNew
                    type={item.subcategory?.name}
                    title={item.name}
                    image={item.image.path}
                    difficulty={item.difficulty}
                    duration={item.duration || item.duration_in_weeks}
                    durationInWeek={!!item.duration_in_weeks}
                    kcal={parseInt(item.calories, 10)}
                    plus={null}
                    minus={null}
                    paymentLevel={item.payment_level}
                    onPress={debounce(() => {
                      item.payment_level === 1
                        ? null//Actions.paywall()
                        : openTrailNew(
                            item.subcategory?.category_id,
                            item.id,
                            item.subcategory?.name
                          );
                      dispatch(setNameShare(item.name));
                    }, PERFORMANCE.ON_PRESS_DEBOUNCE_TIME)}
                    containerStyle={{ marginHorizontal: 5, marginVertical: 9 }}
                    gradientColor={getGradientColor(item.subcategory?.category_id)}
                    goals={item?.goals ? item?.goals : []}
                    isAgenda={isCalendar}
                    onPressAdd={() => onPressAdd(item)}
                    favorite={item.favorite}
                    id={item.id}
                  />
                );
              }}
            />
          </View>
        )}
      </ScrollView>
    </View>
  );
};

export default ListClassesScreen;

const styles = StyleSheet.create({
  headerText: {
    color: colors.white,
    fontSize: 18,
    marginRight: 60,
    position: 'relative',
    textAlign: 'left',
    fontFamily: fonts.BOLD,
  },
  flexCenter: { flex: 1, alignItems: 'center', paddingVertical: 15, justifyContent: 'center' },
});
