/* eslint-disable react-native/no-inline-styles */
import React from 'react';

import { View, Text, StyleSheet } from 'react-native';
import colors from '../styles/colors';
import fonts from '../styles/fonts';
import ShadowView from '@liuyunjs/react-native-simple-shadow-view';

const CardSelect = ({ restriction, style }) => {
  return (
    <ShadowView
      style={[
        styles.card,
        style,
        {
          shadowOpacity: restriction.isSelected ? 0.2 : 0,
          backgroundColor: restriction.isSelected ? colors.greyBlue : colors.white,
        },
      ]}>
      <View style={styles.rowCenter}>
        <Text
          style={[
            styles.title,
            { color: restriction.isSelected ? colors.white : colors.brownishGrey },
          ]}>
          {restriction.name}
        </Text>
      </View>
    </ShadowView>
  );
};

export default CardSelect;

const styles = StyleSheet.create({
  rowCenter: { flexDirection: 'row', alignItems: 'center' },
  title: {
    alignItems: 'center',
    fontFamily: fonts.MEDIUM,
    fontSize: 16,
    color: colors.brownishGrey,
  },
  card: {
    marginRight: 11,
    marginBottom: 19,
    alignItems: 'center',
    height: 39,
    borderRadius: 5,
    paddingHorizontal: 16,
    justifyContent: 'center',
    shadowColor: colors.greyBlue,
    shadowOffset: {
      width: 0,
      height: 6,
    },
    shadowRadius: 9,
  },
});
