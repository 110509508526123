import React from 'react';
import { View, StyleSheet, Text, Image } from 'react-native';
import colors from 'app/styles/colors';
import textStyles from 'app/styles/textStyles';
import Icon from 'react-native-vector-icons/FontAwesome';
import Stars from 'app/components/Stars';

import moment from 'moment';

const Comment = ({ avatar, name, rating, date, text }) => {
  return (
    <View style={styles.contaier}>
      <View style={styles.header}>
        <View style={styles.personalData}>
          <Image source={{ uri: avatar }} style={styles.avatar} />
          <View>
            <Text style={textStyles.bold14}>{name}</Text>
            <View style={styles.starsContainer}>
              <Stars
                disabled={true}
                default={rating}
                count={5}
                spacing={6}
                style={styles.stars}
                fullStar={<Icon name={'star'} size={13} color={colors.yellowOrange} />}
                emptyStar={<Icon name={'star-o'} size={13} color={colors.yellowOrange} />}
              />
            </View>
          </View>
        </View>
        <Text style={textStyles.regular12grey}>{moment(date).format('DD [de] MMMM')}</Text>
      </View>
      <View style={styles.content}>
        <Text style={[styles.comment, textStyles.regular12grey]}>{text}</Text>
      </View>
    </View>
  );
};

export default Comment;

const styles = StyleSheet.create({
  contaier: {
    flex: 1,
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0,0,0,0.05)',
    paddingBottom: 16,
    marginTop: 16,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  personalData: {
    flexDirection: 'row',
  },
  avatar: {
    borderRadius: 50,
    marginRight: 12,
    width: 40,
    height: 40,
  },
  starsContainer: {
    maxWidth: 140,
    marginVertical: 8,
    alignItems: 'flex-start',
  },
  comment: {
    paddingLeft: 52,
  },
});
