/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useEffect, useState } from 'react';
import {
  Dimensions,
  ImageBackground,
  ScrollView,
  StyleSheet,
  Text,
  Image,
  ActivityIndicator,
  View,
  TouchableOpacity
} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { useSafeArea } from 'react-native-safe-area-context';
import Accordion from '../../../components/Accordion';
import ButtonBase from '../../../components/ButtonBase';
import CapsuleHour from '../../../components/CapsuleHour';
import CapsuleLevel from '../../../components/CapsuleLevel';
import ChallengeChip from '../../../components/ChallengeChip';
import Chip from '../../../components/Chips';
import HeaderOver from '../../../components/HeaderOver';
import Spacer from '../../../components/Spacer';
import { translate } from '../../../i18n/src/locale';
import pt from '../../../i18n/src/locale/pt';
import colors from '../../../styles/colors';
import commons from '../../../styles/commons';
import textStyles from '../../../styles/textStyles';
import HeaderTitle from '../../profile/components/HeaderTitle';
import RowExerciseTurns from '../../profile/components/row/RowExerciseTurns';
// import AddCalendar from './components/AddCalendar';
import RowTeachers from './components/RowTeachers';
import circle_question from 'app/images/circle_question.png';
import { errorControl, sendActionEvent, showAlert } from 'app/services/utils';
import api from 'app/services/api';
import FavoriteButton from 'app/components/FavoriteButton';
import CircuitHelpModal from 'app/screens/core/explorer/components/CircuitHelpModal';
import { useSelector } from 'react-redux';
import apiNode from 'app/services/apiNode';
import fallback from 'app/images/fallback_fitness2.jpg';
import CongratsModal from 'app/screens/core/explorer/components/CongratsModal';
import { useNavigation } from '@react-navigation/native';
const { width } = Dimensions.get('window');

const LessonScreen = ({ route }) => {

  const { lessonId, trailId } = route?.params;
  const navigation = useNavigation();

  const { profile } = useSelector(state => state.user);
  const [scrollY, setScrollY] = useState(0);
  const [classData, setClassData] = useState({});
  const [playlist, setPlaylist] = useState([]);
  const [exercises, setExercises] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [imageTop, setImageTop] = useState(fallback);
  const [doneModal, setDoneModal] = useState(false);

  const insets = useSafeArea();

  useEffect(() => {
    requestClassDetail();
    // requestPlaylist();
  }, [requestClassDetail]);

  const handleScroll = event => {
    setScrollY(event.nativeEvent.contentOffset.y);
  };

  const handleStart = () => {
    navigation.navigate('ActivityPlayer', {
      lessonId: lessonId,
      playlist: playlist,
      exercises: exercises,
      subcategory: classData.subcategory.name,
      onFinish,
    });
  };

  const requestClassDetail = useCallback(async () => {
    try {
      let data;
      if (trailId) {
        const response = await api.get(`class/detail?class_id=${lessonId}&trail_id=${trailId}`);
        data = response.data;
      } else {
        const response = await api.get(`class/detail?class_id=${lessonId}`);
        const favdata = await apiNode.get(`classes/favorite/${lessonId}`);
        data = {
          ...response.data,
          ...favdata.data,
        };
      }
      setClassData(data);
      setPlaylist(data.exercise_data.tree);
      setExercises(data.exercise_data.exercises);
      setImageTop({
        uri: data?.image?.path,
        headers: {
          Authorization: api.defaults.headers.Authorization,
        },
      });
    } catch (error) {
      showAlert(errorControl(error));
    }
  }, [lessonId, trailId]);

  const getBenefitName = () => {
    if (classData?.benefits === '') {
      return [];
    }
    const arrayBenefits = classData?.benefits?.split(',');
    return arrayBenefits;
  };

  const getRequirementName = () => {
    if (classData?.prerequisites === '') {
      return [];
    }
    if (classData?.prerequisites?.indexOf(',') > -1) {
      const arrayRequirements = classData?.prerequisites?.split(',');
      return arrayRequirements;
    }
    return [classData?.prerequisites];
  };

  const getRiskName = () => {
    if (classData?.risks === '') {
      return [];
    }
    const arrayRisks = classData?.risks?.split(',');
    return arrayRisks;
  };

  const goToExerciseDetail = async exercise => {
    setIsLoading(true);
    try {
      let data;
      if (lessonId) {
        const response = await api.get(
          `exercise/filter?exercise_id=${exercise.id}&class_id=${lessonId}`
        );
        data = response.data;
      } else {
        const response = await api.get(`exercise/filter?exercise_id=${exercise.id}`);
        data = response.data;
      }
      sendActionEvent('click_exercise_in_class', {
        id_exercise: exercise.id,
        id_class: lessonId,
        email: profile.email,
      });
      navigation.navigate('ExerciseClassScreen', { exerciseId: exercise.id, exercise: data }, profile.email);
    } catch (error) {
      showAlert(error.message);
      showAlert(errorControl(error));
    }
    setTimeout(() => setIsLoading(false), 500);
  };

  const getExerciseItem = (exercise, style) => {
    return (
      <RowExerciseTurns
        onPress={() =>
          verifyPaymentLevel() === 1 ? null : goToExerciseDetail(exercise)
        }
        payment_level={verifyPaymentLevel()}
        exercise={exercise}
        style={style}
      />
    );
  };
  const verifyPaymentLevel = () => {
    if (!classData?.trail_class_payment_level) {
      return classData?.trail_class_payment_level;
    }
    return classData?.payment_level;
  };

  const onFinish = () => {
    setDoneModal(true);
  };

  const createExerciseSeries = () => {
    if (exercises.length > 0) {
      return playlist.map(item => {
        let exercise = exercises.find(x => x.id === item.id);
        exercise = { ...exercise, tree: item };
        return (
          <>
            {item.type === 'exercise' ? (
              item.series > 1 ? (
                <View style={styles.seriesExercises}>
                  <View style={styles.seriesTitle}>
                    <HeaderTitle
                      style={{ marginLeft: 20, paddingLeft: 0, backgroundColor: 'transparent' }}
                      title={item.series + ' SÉRIES'}
                    />
                  </View>
                  {getExerciseItem(exercise, { marginHorizontal: 31 })}
                </View>
              ) : (
                getExerciseItem(exercise, { marginHorizontal: 31 })
              )
            ) : (
              item.type === 'circuit' && (
                <View style={styles.seriesExercises}>
                  <View style={styles.seriesTitle}>
                    <HeaderTitle
                      style={{ marginLeft: 20, paddingLeft: 0, backgroundColor: 'transparent' }}
                      title={'CIRCUITO - ' + item.series + ' SÉRIES'}
                    />
                    <TouchableOpacity onPress={() => setModalOpen(true)}>
                      <Image
                        source={circle_question}
                        style={{ width: 18, height: 18, marginRight: 20 }}
                      />
                    </TouchableOpacity>
                  </View>
                  {item.exercises.map(i => {
                    let exerciseFromList = exercises.find(x => x.id === i.id);
                    exerciseFromList = { ...exerciseFromList, tree: i };
                    return getExerciseItem(exerciseFromList, { marginHorizontal: 20 });
                  })}
                </View>
              )
            )}
          </>
        );
      });
    }
    return null;
  };

  return (
    <View style={styles.container}>
      {!classData.name || isLoading ? (
        <View style={{ alignItems: 'center', justifyContent: 'center', flex: 1 }}>
          <ActivityIndicator size={'large'} color={colors.aquaMarineTwo} />
        </View>
      ) : (
        <React.Fragment>
          <HeaderOver
            title={classData?.name}
            position={scrollY}
            maxSize={426}
            share={false}
          />

          <ScrollView
            scrollEventThrottle={16}
            onScroll={handleScroll}
            contentContainerStyle={{ flexGrow: 1, paddingBottom: insets.bottom + 48 }}>
            <View>
              <View style={styles.topImage}>
                <ImageBackground
                  source={imageTop}
                  onError={currentTarget => {
                    currentTarget.onError = null;
                    setImageTop(fallback);
                  }}
                  style={{ height: 426 }}>
                  <View style={styles.topper}>
                    <LinearGradient
                      colors={[colors.transparent, colors.aquaMarineTwo]}
                      style={styles.linear}
                    />

                    <ChallengeChip text={translate(pt.lesson)} />

                    <View style={styles.holderTitle}>
                      <View style={{ flex: 1 }}>
                        <Text style={[textStyles.bold24White]}>
                          {classData?.name}
                        </Text>
                      </View>
                      <FavoriteButton
                        isFavorited={classData?.favorite}
                        propType="classId"
                        itemId={classData?.id}
                      />
                    </View>
                  </View>
                </ImageBackground>
              </View>
              <View style={styles.capsuleHolder}>
                <CapsuleHour style={styles.capsule} duration={classData?.duration} />
                <CapsuleLevel
                  style={{ ...styles.capsule, marginLeft: 10 }}
                  difficulty={classData?.difficulty}
                />
              </View>
            </View>

            <View style={styles.content}>
              <Text style={textStyles.regular14}>{classData?.description}</Text>
            </View>

            <HeaderTitle title={translate(pt.teachers)} />
            <View style={styles.content}>
              {classData?.teachers?.map(teacher => {
                return (
                  <RowTeachers
                    name={teacher.name}
                    image={teacher.user_picture}
                    subCategoryName={classData.subcategory.name}
                  />
                );
              })}
              <View style={styles.insideContainerRow}>
                {classData?.tags?.map(tag => {
                  return <Chip title={tag.name} />;
                })}
              </View>
            </View>

            <View style={styles.content}>
              <View style={commons.flexRowBetween}>
                {/* <AddCalendar style={styles.add} typeSchedule="class" id={lessonId} /> */}
                {/* <Spacer value={10} />
                <AddPlaylist style={styles.add} typeItem="class" id={classData.id} /> */}
              </View>
              <Spacer />
              {!!classData?.benefits && (
                <Accordion
                  title={translate(pt.see_benefits)}
                  color={colors.aquaMarineTwo}
                  arrayItens={getBenefitName()}
                  item={'benefits'}
                  type={'classes'}
                  id={classData?.id}
                />
              )}
              {!!classData?.risks && (
                <Accordion
                  title={translate(pt.tips_and_tricks)}
                  color={colors.aquaMarineTwo}
                  arrayItens={getRiskName()}
                  item={'risks'}
                  type={'classes'}
                  id={classData?.id}
                />
              )}
              {!!classData?.prerequisites && (
                <Accordion
                  title={translate(pt.prerequisites)}
                  color={colors.aquaMarineTwo}
                  arrayItens={getRequirementName()}
                  item={'requisites'}
                  type={'classes'}
                  id={classData?.id}
                />
              )}
            </View>

            <HeaderTitle title={translate(pt.exercices)} />

            {createExerciseSeries()}
          </ScrollView>
          <View
            style={{
              backgroundColor: 'white',
              position: 'absolute',
              bottom: 0,
              paddingBottom: insets.bottom + 20,
              left: 0,
              right: 0,
            }}>
            <View style={{ paddingHorizontal: 20 }}>
              <ButtonBase
                textStyle={{ color: colors.white }}
                onPress={handleStart}
                fill
                style={{ height: 50 }}
                title={translate(pt.begin_lesson)}
              />
            </View>
          </View>
          <View style={{ paddingBottom: 40 }} />
        </React.Fragment>
      )}
      <CircuitHelpModal open={modalOpen} onClose={() => setModalOpen(false)} />
      <CongratsModal
        open={doneModal}
        name={classData?.name}
        onClose={() => setDoneModal(false)}
        type={'class'}
        onPress={() => navigation.goBack()}
      />
    </View>
  );
};

export default LessonScreen;

const styles = StyleSheet.create({
  add: { flex: 1, backgroundColor: colors.aquaMarineTwo },
  topper: { padding: 14, flex: 1, justifyContent: 'flex-end' },
  content: { paddingHorizontal: 14, marginBottom: 14 },
  holderTitle: { flexDirection: 'row', marginVertical: 10, alignItems: 'center' },
  linear: { position: 'absolute', width: '100%', height: 200,left: 0, bottom: 0 },
  capsuleHolder: {
    flexDirection: 'row',
    marginTop: -10,
    marginBottom: 20,
    justifyContent: 'flex-start',
    marginLeft: 16,
    
  },
  capsule: {
    backgroundColor: colors.whiteTwo,
    borderColor: '#eee',
    borderWidth: StyleSheet.hairlineWidth,
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 6,
    shadowOpacity: 0.2,
  },
  topImage: {
    height: 426,
    borderBottomRightRadius: 36,
    backgroundColor: 'grey',
    overflow: 'hidden',
  },
  container: {
    // flex: 1,
    height: '100dvh',
    backgroundColor: colors.paleGrey,
  },
  insideContainerRow: {
    marginTop: 10,
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 0,
  },
  seriesExercises: {
    backgroundColor: '#EBECF2',
    paddingVertical: 8,
    borderRadius: 5,
    marginBottom: 25,
    marginTop: 10,
    marginHorizontal: 11,
  },
  seriesTitle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 8,
  },
});
