import React from 'react';
import { View, Text, StyleSheet, Image, ImageBackground, TouchableOpacity }  from 'react-native';
import fonts from 'app/styles/fonts';
import colors from 'app/styles/colors';
import womanExerciseLastImage from 'app/images/womanExerciseLastCard.png';
import arrowRight from 'app/images/arrowRightThin.png';
import { translate } from 'app/i18n/src/locale';
import pt from 'app/i18n/src/locale/pt';

const CardClassesLast = ({ title = 'Taichi', color = colors.aquaMarineTwo, onPress, style }) => {
  return (
    <ImageBackground
      style={[styles.card, style]}
      source={womanExerciseLastImage}
      resizeMode="cover">
      <View
        style={[
          styles.card,
          { backgroundColor: color, opacity: 0.9, height: '100%', width: '100%' },
        ]}>
        <Text style={styles.title}>{title}</Text>
        <TouchableOpacity style={styles.button} onPress={onPress}>
          <Text style={styles.textButton}>{translate(pt.see_all2)}</Text>
          <Image
            source={arrowRight}
            style={{ marginLeft: 8, marginTop: 2, width: 15, height: 10, resizeMode: 'contain' }}
          />
        </TouchableOpacity>
      </View>
    </ImageBackground>
  );
};

export default CardClassesLast;

const styles = StyleSheet.create({
  card: {
    width: 163,
    height: 224,
    borderRadius: 4,
    backgroundColor: colors.aquaMarineTwo,
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  title: {
    fontFamily: fonts.BOLD,
    fontSize: 16,
    color: colors.white,
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  button: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 4,
    borderWidth: 1,
    borderColor: colors.white,
    justifyContent: 'center',
    width: 132,
    height: 33,
  },
  textButton: {
    fontFamily: fonts.BOLD,
    // textAlign: 'center',
    fontSize: 12,
    color: colors.white,
  },
});
