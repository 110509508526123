import React from 'react';

import { View, Text, StyleSheet, Image, TouchableOpacity } from 'react-native';
import textStyles from '../../../../styles/textStyles';
import CapsuleClock from '../../../../components/CapsuleClock';
import colors from '../../../../styles/colors';
import ShadowView from '@liuyunjs/react-native-simple-shadow-view';
import CapsuleDayNight from '../../../../components/CapsuleDayNight';
import ballFoodOpaque from '../../../../images/ballFoodOpaque.png';
import MoreContextMenu from './MoreContextMenu';
import api from 'app/services/api';
import fonts from '../../../../styles/fonts';
import { useNavigation } from '@react-navigation/native';

const RowDualPhotos = ({ item, style, checked, onCloseModal }) => {
  const navigation = useNavigation();

  const componentImage = image => {
    return (
      <Image
        source={{
          uri: image,
          headers: {
            Authorization: api.defaults.headers.Authorization,
          },
        }}
        style={styles.image}
      />
    );
  };

  const renderImage = () => {
    if (item.images.length <= 2) {
      return item.images.map(image => {
        return componentImage(image.path);
      });
    }
    return multipleImages();
  };

  const multipleImages = () => {
    return (
      <>
        {componentImage(item.images[0].path)}
        <View>
          {componentImage(item.images[1].path)}
          <View style={[styles.image, styles.maskImage]} />
          <View style={styles.containerText}>
            <Text style={styles.countImage}>{`+${item.images.length - 2}`}</Text>
          </View>
        </View>
      </>
    );
  };

  const onPress = () => {
    if (item.class_payment_level === 1) {
      // Actions.paywall()
    } else {
      if (!!item.dish_id) {
        return navigation.navigate('PlateDetail', { plateId: item.dish_id });
        // return Actions.plate_detail({ plateId: item.dish_id });
      }

      if (!!item.meal_id) {
        return navigation.navigate('MealDetail', { mealId: item.meal_id, trailId: item.trail_id || null });
        // return Actions.meal_detail({ mealId: item.meal_id, trailId: item.trail_id || null });
      }

      if (!!item.class_id && type === 2) {
        return navigation.navigate('LessonActivity', { lessonId: item.class_id, trailId: item.trail_id || null });
        // return Actions.lesson_activity({ lessonId: item.class_id, trailId: item.trail_id || null });
      }

      if (!!item.class_id && type === 3) {
        return navigation.navigate('LessionMeditaion', { classId: item.class_id, trailId: item.trail_id || null });
        // return Actions.lesson_meditation({ classId: item.class_id, trailId: item.trail_id || null });
      }
    }
  }

  return (
    <TouchableOpacity onPress={onPress} disabled={checked} style={{ opacity: checked ? 0.5 : 1 }}>
      <ShadowView style={[styles.card, style]}>
        {renderImage()}
        <View style={styles.holder}>
          <Text numberOfLines={2} style={[textStyles.semi14, { marginRight: 0 }]}>
            {item.name}
          </Text>

          <MoreContextMenu item={item} type={item.category_item} onClose={onCloseModal} />

          <View style={styles.capsules}>
            <CapsuleDayNight small period={item.day_period_item} time={item.start_date} />
            <CapsuleClock small duration={item.duration} noShadow={true} />
            <Image style={styles.ball} source={ballFoodOpaque} />
          </View>
        </View>
      </ShadowView>
    </TouchableOpacity>
  );
};

export default RowDualPhotos;

const styles = StyleSheet.create({
  maskImage: { backgroundColor: colors.black, opacity: 0.6, position: 'absolute' },
  containerText: {
    width: 53,
    height: 53,
    position: 'absolute',
    zIndex: 5,
    marginRight: 10,
    left: 8,
    alignItems: 'center',
    justifyContent: 'center',
  },
  countImage: {
    color: colors.white,
    position: 'absolute',
    fontFamily: fonts.BOLD,
    fontSize: 17,
  },
  ball: { width: 21, height: 21 },
  capsules: {
    maxWidth: 180,
    alignSelf: 'stretch',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginTop: 16,
  },
  holder: {
    marginLeft: 10,
    paddingVertical: 10,
    paddingRight: 20,
    flex: 1,
    justifyContent: 'center',
  },
  image: {
    width: 53,
    height: 53,
    marginRight: 10,
    left: 8,
    borderRadius: 3,
    backgroundColor: colors.base,
  },
  card: {
    flexDirection: 'row',
    marginBottom: 16,
    borderRadius: 2,
    alignItems: 'center',
    backgroundColor: colors.white,
    shadowColor: colors.black,
    marginHorizontal: 14,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 4,
    shadowOpacity: 0.1,
  },
});
