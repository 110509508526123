import React from 'react';

import { View, StyleSheet, Text, Image } from 'react-native';
import fonts from '../styles/fonts';
import colors from '../styles/colors';
import appleIcon from '../images/appleIcon.png';

const CapsuleCals = ({ small, style, kcal }) => (
  <View style={[styles.capsule, style, small && { width: 70, height: 21 }]}>
    <Image source={appleIcon} style={[small && { height: 15, width: 15 }]} />
    <Text style={[styles.capsuleText, small && { fontSize: 12 }]}>{kcal}</Text>
  </View>
);

export default CapsuleCals;

const styles = StyleSheet.create({
  capsuleText: {
    marginLeft: 4,
    fontFamily: fonts.REGULAR,
    fontSize: 14,
    opacity: 0.9,
    color: colors.brownishGreyTwo,
  },
  capsule: {
    paddingLeft: 8,
    paddingRight: 8,
    flexDirection: 'row',
    alignItems: 'center',
    height: 29,
    borderRadius: 18.2,
    backgroundColor: colors.whiteTwo,
  },
});
