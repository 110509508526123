import React, { useState, useCallback, useEffect, useRef } from 'react';

import { View, Text, TouchableOpacity, Image, StyleSheet, ImageBackground, Alert, Modal, TouchableWithoutFeedback } from 'react-native';

import textStyles from '../../../../styles/textStyles';
import { translate } from '../../../../i18n/src/locale';
import pt from '../../../../i18n/src/locale/pt';
import ballFood from '../../../../images/ballFood.png';
import activityBall from '../../../../images/activityBall.png';
import meditationBall from '../../../../images/meditationBall.png';
import colors from '../../../../styles/colors';
import api from 'app/services/api';
import { showAlert, errorControl, sendActionEvent } from 'app/services/utils';
import { useSelector } from 'react-redux';
// import RBSheet from 'react-native-raw-bottom-sheet';
import commons from 'app/styles/commons';
import closeImage from 'app/images/closeGrey.png';
import fonts from 'app/styles/fonts';
import HourSelectNamuAttendance from 'app/screens/core/schedule/HourSelectNamuAttendance';
import CapsuleHour from 'app/components/CapsuleHour';
import ButtonBase from 'app/components/ButtonBase';
import ProfessionalData from 'app/screens/core/professionalFilterResult/components/ProfessionalData';
import CheckBox from '@react-native-community/checkbox';
import moment from 'moment';
import apiNode from 'app/services/apiNode';
import ConversationIcon from 'app/images/chat_circle.svg';

moment.locale('pt-br');

const areas = {
  1: {
    color: colors.grapefruit,
  },
  2: {
    color: colors.apricot,
  },
  3: {
    color: colors.aquaMarineTwo,
  },
  6: {
    color: colors.tealish,
  },
  7: {
    color: colors.rosyPink,
  },
  8: {
    color: colors.brown,
  },
  9: {
    color: colors.dustyOrange,
  },
  10: {
    color: colors.violet,
  },
  11: {
    color: colors.transparent,
  }
};

const STEPS = {
  CHOOSE_HOUR: 0,
  CONFIRMATION: 1,
  CONGRATULATIONS: 2,
};

const BottomSheetStartNamuAttendance = ({
  date,
  onFinish,
  onClose,
  category,
  stepIndex = STEPS.CHOOSE_HOUR,
  sheetRef,
}) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(stepIndex);
  const [balance, setBalance] = useState({});
  const [availableCredits, setAvailableCredits] = useState({ type: 'client', credits: 0 });
  const [teleDuration, setTeleDuration] = useState(15);
  const [selectedDate, setSelectedDate] = useState(null);
  const [profileInfoShow, setProfileInfoShow] = useState(false);
  const { profile } = useSelector(state => state.user);
  const [userPro, setUserPro] = useState(null);

  const [isLoadingSchedule, setIsLoadingSchedule] = useState(false);

  const steps = [
    { title: translate(pt.choose_hour) },
    { title: translate(pt.scheduling_confirmation) },
    { title: translate(pt.orientation_scheduled_with) },
  ];

  const getData = async () => {
    const { data: _balance } = await api.get('balance');
    const {
      data: { value },
    } = await apiNode.get('meta/tele_duration');
    setBalance(_balance);
    setTeleDuration(value);
  };

  useEffect(() => {
    handleAvailableCredits();
  }, [balance]);

  useEffect(() => {
    if (sheetRef) {
      getData();
      setCurrentStepIndex(stepIndex);
      setSelectedDate(date);
    }
  }, [sheetRef, stepIndex, date]);

  const handleConfirmHour = date => {
    setSelectedDate(date);
    setCurrentStepIndex(STEPS.CONFIRMATION);
  };

  const scheduleSession = async () => {
    setIsLoadingSchedule(true);
    try {
      const { data } = await apiNode.post('/sessions/namu-staff', {
        userId: profile.user_id,
        sessionDate: moment(selectedDate).toISOString(),
        categoryId: category.id,
      });
      setUserPro(data.userPro);
      setCurrentStepIndex(STEPS.CONGRATULATIONS);
    } catch (e) {
      console.log(e.response.data);
      switch (e.response.data.type) {
        case 'schedule_not_available': {
          Alert.alert(translate(pt.failed_to_schedule), translate(pt.not_available_anymore));
          break;
        }
        case 'no_funds': {
          Alert.alert(translate(pt.failed_to_schedule), translate(pt.no_funds));
          break;
        }
        default: {
          Alert.alert(translate(pt.failed_to_schedule), translate(pt.schedule_error));
        }
      }
    }
    setIsLoadingSchedule(false);
  };

  const handleAvailableCredits = () => {
    const userCredits = parseInt(balance?.user) || 0;
    const businessCredits = parseInt(balance?.business) || 0;

    const credits = businessCredits > 0 ? businessCredits : userCredits;

    setAvailableCredits({
      type:
        profile?.plan_meta?.tele_enabled && businessCredits > 0
          ? profile?.plan_meta?.tele_balance || 'user'
          : 'client',
      credits: credits,
    });
  };

  const getCreditUsageMessage = () => {
    switch (availableCredits.type) {
      case 'pool':
        return (
          <>
            Você irá utilizar <Text style={styles.activeText}>{1} crédito</Text>{' '}
            <Text style={styles.boldText}>corporativo</Text> compartilhado. Deseja confirmar o
            agendamento?
          </>
        );
      case 'user':
        return (
          <>
            Você irá utilizar <Text style={styles.activeText}>{1} crédito</Text>, restando{' '}
            <Text style={styles.activeText}>{availableCredits.credits - 1} créditos</Text>{' '}
            <Text style={styles.boldText}>corporativos</Text> para esse mês. Deseja confirmar o
            agendamento?
          </>
        );
      default:
        return (
          <>
            Você irá utilizar <Text style={styles.activeText}>{1} crédito</Text> pessoal, restando{' '}
            <Text style={styles.activeText}>{availableCredits.credits - 1} créditos</Text> pessoais.
            Deseja confirmar o agendamento?
          </>
        );
    }
  };

  const BgRender = ({ children, ...props }) => {
    if(category.id == 11) {
      return <ImageBackground source={require('app/images/bg_lgbt.png')} {...props}>{children}</ImageBackground>
    }
    return <View {...props}>{children}</View>
  }


  return (
    <Modal
      style={{ margin: 0, bottom: 0, position: 'absolute', width: '100%', zIndex: 100 }}
      visible={sheetRef}
      transparent={true}>
        <TouchableWithoutFeedback onPress={onClose} style={{position: 'fixed', zIndex: -1, width: '100%', height: '100%'}}>
          <View style={{position: 'fixed', zIndex: -1, width: '100%', height: '100%'}}/>
        </TouchableWithoutFeedback>
        <View style={styles.containerBottomSheet}>
          <View style={{ height: '100%' }}>
            <View style={styles.topContainer}>
              <Text style={styles.title}>
                {steps[currentStepIndex].title}
                {currentStepIndex === STEPS.CONGRATULATIONS && (
                  <Text style={{ color: colors.aquaMarineTwo }}>{' sucesso!'}</Text>
                )}
              </Text>
              <TouchableOpacity onPress={onClose} hitSlop={commons.hitSlop}>
                <Image source={closeImage} style={{ tintColor: colors.black, width: 20, height: 20 }} />
              </TouchableOpacity>
            </View>
            <View style={{ paddingHorizontal: 30 }}>
              {currentStepIndex === STEPS.CHOOSE_HOUR ? (
                <>
                  <Text
                    style={{
                      ...textStyles.regular14,
                      color: colors.greyBlue,
                      textAlign: 'center',
                      marginBottom: 15,
                    }}>
                    {'Orientação com '}
                    <Text style={textStyles.bold14}>{category?.name}</Text> até{' '}
                    <Text style={textStyles.bold14}>{'20 minutos'}</Text>
                  </Text>
                  <HourSelectNamuAttendance
                    scrollStyle={{ maxHeight: 400 }}
                    available
                    categoryId={category?.id}
                    onConfirm={handleConfirmHour}
                  />
                </>
              ) : currentStepIndex === STEPS.CONFIRMATION ? (
                <View>
                  <View style={styles.professionalContainer}>
                    <Image source={ConversationIcon} style={{width: 60, height:60, resizeMode: 'contain'}} />
                    <View style={styles.infosContainer}>
                      <View style={{ marginLeft: 15 }}>
                        <View style={styles.nameContainer}>
                          <Text style={[textStyles.medium16]}>{translate(pt.namu_attendance)}</Text>
                        </View>
                        <Text style={[textStyles.regular12grey, { color: colors.greyBlue }]}>
                          {category.name}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View style={styles.resumeHourContainer}>
                    <BgRender
                      style={[
                        styles.categoryIcon,
                        { backgroundColor: areas[category.id].color + '45' },
                      ]}>
                      <Image
                        source={{ uri: category.icon }}
                        style={{
                          tintColor: category.id == 11 ? colors.white : areas[category.id].color,
                          width: 20,
                          height: 20,
                          resizeMode: 'contain',
                        }}
                      />
                    </BgRender>
                    <Text style={styles.resumeDate}>
                      {moment(selectedDate).format('ddd - DD  MMMM')} -{' '}
                      {moment(selectedDate).format('HH:mm')}
                    </Text>
                  </View>
                  <View style={{ marginTop: 30 }}>
                    <Text style={styles.confirmationText}>{getCreditUsageMessage()}</Text>
                  </View>
                  <ButtonBase
                    title={translate(pt.yes_confirm)}
                    fill
                    loading={isLoadingSchedule}
                    disabled={isLoadingSchedule}
                    style={{ height: 49 }}
                    onPress={scheduleSession}
                  />
                  <ButtonBase
                    title={translate(pt.cancel)}
                    fill
                    style={{ height: 49 }}
                    backgroundColor={colors.transparent}
                    color={colors.darkPro}
                    textStyle={{ color: colors.darkPro }}
                    onPress={onClose}
                  />
                </View>
              ) : currentStepIndex === STEPS.CONGRATULATIONS ? (
                <View>
                  <View style={{ paddingHorizontal: 15 }}>
                    <ProfessionalData
                      userProRatings={false}
                      staff
                      hideProfessionalNumber
                      userProId={userPro}
                      linear={true}
                      hideFavoriteButton
                    />
                    <View style={styles.resumeHourContainer}>
                      <View
                        style={[
                          styles.categoryIcon,
                          { backgroundColor: areas[category.id].color + '59' },
                        ]}>
                        <Image
                          source={{ uri: category.icon }}
                          style={{
                            tintColor: areas[category.id].color,
                            resizeMode: 'contain',
                            width: 20,
                            height: 20,
                          }}
                        />
                      </View>
                      <Text style={styles.resumeDate}>
                        {moment(selectedDate).format('ddd - DD  MMMM')} -{' '}
                        {moment(selectedDate).format('HH:mm')}
                      </Text>
                    </View>
                  </View>
                  {/* <View style={{flexDirection: 'row', alignItems: 'center', marginTop: 15}}>
                  <CheckBox value={profileInfoShow} onValueChange={setProfileInfoShow}/>
                  <Text style={{textDecorationLine: 'underline'}}>{translate(pt.release_profile)}</Text>
                </View> */}
                  <ButtonBase
                    title={translate(pt.my_orientations)}
                    fill
                    backgroundColor={colors.aquaMarineTwo}
                    style={{ height: 49 }}
                    onPress={() => {
                      onClose();
                      onFinish && onFinish();
                    }}
                  />
                </View>
              ) : null}
            </View>
          </View>
        </View>
    </Modal>
  );
};

export default BottomSheetStartNamuAttendance;

const styles = StyleSheet.create({
  container: {},
  containerBottomSheet: {
    position: 'absolute',
    borderTopEndRadius: 47,
    borderTopStartRadius: 47,
    paddingBottom: 30,
    backgroundColor: 'white',
    bottom: 0,
    width: '100%'
    // maxHeight: '80%',
  },
  topContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    marginVertical: 20,
  },
  title: {
    textAlign: 'center',
    flex: 0.8,
    fontFamily: fonts.BOLD,
    fontSize: 18,
  },
  bottomSheet: { height: 245, backgroundColor: colors.white },
  header: {
    height: 60,
    paddingHorizontal: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: colors.paleGrey,
  },
  menu_item: {
    height: 53,
    borderTopWidth: StyleSheet.hairlineWidth,
    borderColor: colors.greyBlue22,
    paddingHorizontal: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: colors.white,
  },
  professionalContainer: {
    marginTop: 10,
    paddingHorizontal: 10,
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomWidth: 2,
    borderBottomColor: 'rgba(0,0,0,0.05)',
    paddingBottom: 12,
    //borderWidth: 2,
  },
  photoStyle: {
    width: 75,
    height: 75,
    resizeMode: 'cover',
    borderRadius: 100,
  },
  starsContainer: {
    maxWidth: 115,
    marginVertical: 3,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  capsule: {
    backgroundColor: '#f9f9f9',
  },
  resumeHourContainer: {
    borderColor: colors.aquaMarineGrd,
    borderWidth: 1,
    borderRadius: 10,
    marginTop: 20,
    padding: 13,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    shadowColor: colors.aquaMarineGrd,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 4.65,

    elevation: 8,
  },
  categoryIcon: {
    width: 35,
    height: 35,
    overflow: 'hidden',
    resizeMode: 'contain',
    borderRadius: 35,
    backgroundColor: colors.aquaMarineGrd,
    alignItems: 'center',
    justifyContent: 'center',
  },
  resumeDate: {
    marginLeft: 10,
    fontFamily: fonts.BOLD,
    fontSize: 18,
    textTransform: 'capitalize',
  },
  confirmationText: {
    color: colors.greyLight4,
    fontFamily: fonts.MEDIUM,
  },
  activeText: { color: colors.aquaMarineTwo, fontFamily: fonts.BOLD },
  boldText: { fontFamily: fonts.BOLD },
});
