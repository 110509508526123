import colors from 'app/styles/colors';
import fonts from 'app/styles/fonts';
/* eslint-disable react-native/no-inline-styles */
import React, { useRef, useState } from 'react';

import { View, Text, Image, StyleSheet, Alert } from 'react-native';
import ShadowView from '@liuyunjs/react-native-simple-shadow-view';

import { translate } from '../../../../i18n/src/locale';
import pt from '../../../../i18n/src/locale/pt';
import ButtonBaseWhite from '../../../../components/ButtonBaseWhite';
import { useEffect } from 'react';
import apiNode from 'app/services/apiNode';
import moment from 'moment';
import { useSelector } from 'react-redux';
import ProfessionalData from 'app/screens/core/professionalFilterResult/components/ProfessionalData';
import BottomSheetUpdateDecision from './BottomSheetUpdateDecision';
import BottomSheetConfirmCancel from 'app/screens/core/professional/components/BottomSheetConfirmCancel';
import BottomSheetUpdateTime from 'app/screens/core/professional/components/BottomSheetUpdateTime';
import BottomSheetConfirmUpdate from 'app/screens/core/professional/components/BottomSheetConfirmUpdate';
import ButtonAttachments from 'app/screens/core/home/components/ButtonAttachments';
import useGlobalAlert from 'app/hooks/useGlobalAlert';
// import { Camera } from 'react-native-vision-camera';
import { useNavigation } from '@react-navigation/native';
import BottomModal from '../../explorer/components/BottomModal';

const MissedAttendanceCard = ({ done, onClose, onOpen, item, reload, style, staff }) => {
  const [ refUpdateDecisionSheet, setRefUpdateDecisionSheet ] = useState(false);
  const [ refCancelSheet, setRefCancelSheet ] = useState(false);
  const [ refUpdateTimeSheet, setRefUpdateTimeSheet ] = useState(false);
  const [ refConfirmUpdateSheet, setRefConfirmUpdateSheet ] = useState(false);

  const navigation = useNavigation();

  const [favorite, setFavorite] = useState(false);
  const [favoriteId, setFavoriteId] = useState();
  const [userPro, setUserPro] = useState([]);
  const [categoryIconUrl, setCategoryIconUrl] = useState('');
  const [sessionMinutes, setSessionsMinutes] = useState('');
  const [date, setDate] = useState('');
  const { profile } = useSelector(state => state.user);

  const [disableUpdated, setDisableUpdated] = useState(false);

  const { setAlert } = useGlobalAlert();


  const [hoursUpdateSession, setHoursUpdateSession] = useState(12);
  const [hoursEnterSession, setEnterSession] = useState(-5);

  const [ratings, setRatings] = useState(null);

  useEffect(() => {
    if (item.sessionDate && hoursUpdateSession) {
      const difference = moment.duration(moment().diff(moment(item.sessionDate))).asMinutes();
      // setDisableGetIn(difference <= hoursEnterSession);
      setDisableUpdated(moment(item.sessionDate).subtract(hoursUpdateSession, 'hours') < moment());
    }
  }, [item, hoursUpdateSession]);

  const disableGetIn = () => {
    if(item.sessionDate && hoursEnterSession){
      const difference = moment.duration(moment().diff(moment(item.sessionDate))).asMinutes();
      return difference <= hoursEnterSession;
    }
    return false;
  }

  useEffect(() => {
    if (profile && item) {
      getUserProInfos();

      if (item?.sessionFeedbacks?.length > 0) {
        const toUserProRating = item.sessionFeedbacks.find(sf => sf.toUser === false);
        if (toUserProRating) {
          setRatings(toUserProRating.ratingStars);
        }
      }
    }
  }, [item, profile]);

  const getUserProInfos = async () => {
    const { data } = await apiNode.get(`/users-pro/${item.userProId}?userId=${profile.user_id}`);
    const { categories } = data;
    categories ? setCategoryIconUrl(categories[0].professionalCategory.iconUrl) : '';
    setUserPro(data);
    setSessionsMinutes(item.sessionDurationMinutes);
    setFavorite(data.userFavorite.favorited);
    setFavoriteId(data.userFavorite.id);
    setDate(moment(item.sessionDate).format('ddd DD [de] MMMM - HH[hrs] mm[min]'));
  };

  const joinRoom = async () => {
    // await Camera.requestCameraPermission();
    // await Camera.requestMicrophonePermission();

    navigation.navigate('CallService', { sessionId: item.id })
  }

  return (
    <View
      style={[styles.container, style]}
      opacity={
        item.status !== 'done' &&
        (item.status === 'canceled' ||
          moment(item.sessionDate).add(item.sessionDurationMinutes, 'minutes') < moment())
          ? 0.5
          : 1
      }>
      <ShadowView style={[styles.professionalCard]}>
        <View style={styles.containerCard}>
          <ProfessionalData list hideLinearStaff userProId={userPro.id} hideFavoriteButton={staff} userProRatings={!staff} staff={staff} hideProfessionalNumber={staff} linear={false} />
          <ShadowView
            style={[
              styles.categoryAndHourContainer,
              { borderColor: done ? colors.black6 : colors.shadowBlue },
            ]}>
            <View style={styles.categoryBlock}>
              <Image
                source={{
                  uri: categoryIconUrl,
                }}
                style={styles.categoryImageStyle}
              />
            </View>

            <Text style={styles.textHourStyle}>{date}</Text>
          </ShadowView>
          {/*
          <View style={styles.buttonContainer}>
            <ButtonBaseWhite
              style={{
                ...styles.buttonStyle,
                borderColor: done ? colors.aquaMarine : colors.greyBlue,
                backgroundColor: done ? colors.aquaMarine : colors.white,
              }}
              onPress={onOpen}
              square
              color={done ? colors.white : colors.greyBlue}
              title={translate(done ? pt.reschedule : pt.reschedule_service)}
            />
            <ButtonBaseWhite
              style={{
                ...styles.buttonStyle,
                borderColor: colors.cornflowerBlue,
              }}
              onPress={() => {
                Actions.jump('chat');
              }}
              color={colors.cornflowerBlue}
              square
              title={translate(pt.message)}
            />
          </View>
          */}

          {!['canceled', 'done'].includes(item.status) &&
            moment(item.sessionDate).add(item.sessionDurationMinutes, 'minutes') > moment() && (
              <View>
                <View style={styles.buttonContainer}>
                  <ButtonBaseWhite
                    style={{
                      ...styles.buttonStyle,
                      borderColor: colors.aquaMarine,
                      backgroundColor: colors.aquaMarine,
                    }}
                    onPress={() =>
                      disableGetIn() === true
                        ? setAlert(
                              translate(pt.msgAttention),
                              `${translate(pt.msgAlertEnterRoom)} ${Math.abs(
                                hoursEnterSession
                              )} ${translate(userPro.namuStaff ? pt.msgAlertEnterRoomOrientationComplete : pt.msgAlertEnterRoomComplete)}`
                            )
                        : joinRoom()
                    }
                    square
                    color={colors.white}
                    title={'Entrar na sala'}
                    disabledFake={disableGetIn()}
                  />
                  <ButtonBaseWhite
                    style={{
                      ...styles.buttonStyle,
                      borderColor: colors.aquaMarine,
                      backgroundColor: colors.aquaMarine,
                    }}
                    onPress={() => {
                      !disableUpdated === false
                        ? Alert.alert(
                            translate(pt.msgAlert),
                            `${translate(pt.msgChangeHour)} ${hoursUpdateSession} ${translate(
                              pt.msgChangeHourComplete
                            )}`
                          )
                        : setRefUpdateDecisionSheet(true)
                    }}
                    square
                    color={colors.white}
                    title={'Alterar'}
                    disabledFake={disableUpdated}
                  />
                </View>
              </View>
            )}
          {!(
            item.status !== 'done' &&
            (item.status === 'canceled' ||
              moment(item.sessionDate).add(item.sessionDurationMinutes, 'minutes') < moment())
          ) && <ButtonAttachments userProId={item.userProId} />}
        </View>
      </ShadowView>
      <BottomModal open={refUpdateDecisionSheet} onClose={() => setRefUpdateDecisionSheet(false)}>
        <BottomSheetUpdateDecision
          onClose={() => {
            setRefUpdateDecisionSheet(false)
          }}
          doCancel={() => {
            setRefUpdateDecisionSheet(false)
            setTimeout(() => {
              setRefCancelSheet(true);
            }, 500);
          }}
          doUpdate={() => {
            setRefUpdateDecisionSheet(false)
            setTimeout(() => {
              setRefUpdateTimeSheet(true);
            }, 500);
          }}
        />
      </BottomModal>
      <BottomModal open={refCancelSheet} onClose={() => setRefCancelSheet(false)}>
        <BottomSheetConfirmCancel
          userPro={userPro}
          sessionId={item.id}
          onClose={() => {
            setRefCancelSheet(false)
          }}
          reload={reload}
        />
      </BottomModal>
      <BottomModal open={refUpdateTimeSheet} onClose={() => setRefUpdateTimeSheet(false)}>
        <BottomSheetUpdateTime
          userProId={userPro.id}
          onClose={() => {
            setRefUpdateTimeSheet(false)
          }}
          doCancel={() => {
            setRefUpdateTimeSheet(false)
            setTimeout(() => {
              refCancelSheet.current.open();
            }, 500);
          }}
          doUpdate={() => {
            setRefUpdateTimeSheet(false)
            setTimeout(() => {
              setRefConfirmUpdateSheet(true);
            }, 500);
          }}
          sessionId={item.id}
        />
      </BottomModal>
      <BottomModal open={refConfirmUpdateSheet} onClose={() => setRefConfirmUpdateSheet(false)}>
        <BottomSheetConfirmUpdate
          userPro={userPro}
          sessionId={item.id}
          onClose={() => {
            reload();
            setRefConfirmUpdateSheet(false)
          }}
          categoryIconUrl={categoryIconUrl}
        />
      </BottomModal>
    </View>
  );
};

export default MissedAttendanceCard;

const styles = StyleSheet.create({
  container: {
    // padding: 16,
    paddingHorizontal: 20,

  },
  professionalCard: {
    borderRadius: 6,
    backgroundColor: colors.white,
    marginTop: 4,
    shadowColor: colors.blackTwoAlpha01,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowRadius: 6,
    shadowOpacity: 0.06,
    paddingHorizontal: 20,
  },
  containerCard: {
    paddingBottom: 20,
    marginTop: 10,
  },
  topContentCard: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 16,
    marginVertical: 10,
  },
  topMiddleContent: {
    flexDirection: 'column',
    alignSelf: 'center',
    justifyContent: 'space-between',
    minHeight: 45,
    flex: 1,
  },
  professionalNameStyle: {
    fontSize: 16,
    fontFamily: fonts.MEDIUM,
    color: colors.blackTwo,
    marginStart: 4,
  },
  photoStyle: {
    resizeMode: 'contain',
    height: 71,
    width: 74,
    borderRadius: 50,
  },
  divisor: {
    width: '100%',
    height: 1,
    backgroundColor: colors.greyBlue,
    opacity: 0.2,
    alignSelf: 'center',
  },
  timeAndSpecialtyContainer: {
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'space-around',
    width: '100%',
    alignSelf: 'center',
    marginTop: 16,
  },
  specialtyTextStyle: {
    color: colors.ligthGray,
    fontSize: 10,
    fontFamily: fonts.REGULAR,
  },
  credentialStyle: {
    fontFamily: fonts.BOLD,
    color: colors.aquaMarineTwo,
  },
  locationStyle: {
    color: colors.ligthGray,
    fontFamily: fonts.REGULAR,
    fontSize: 10,
    lineHeight: 18,
  },
  containerTime: {
    flexDirection: 'row',
    backgroundColor: colors.whiteTwo,
    maxHeight: 30,
    borderRadius: 18.15,
    padding: 8,
    alignItems: 'center',
    alignSelf: 'center',
  },
  clockStyle: {
    width: 14.4,
    height: 14.4,
    resizeMode: 'contain',
  },
  categoryAndHourContainer: {
    borderWidth: 1,
    borderRadius: 9,
    padding: 8,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 16,
    shadowColor: colors.shadowBlue,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.18,
    elevation: 40,
  },
  textHourStyle: {
    marginStart: 5,
    fontSize: 15,
    fontFamily: fonts.BOLD,
  },
  categoryBlock: {
    backgroundColor: colors.shadowBlue,
    borderRadius: 50,
    padding: 8,
  },
  categoryImageStyle: {
    width: 23.48,
    height: 22,
    resizeMode: 'contain',
    tintColor: colors.aquaMarineTwo,
  },
  buttonContainer: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    paddingTop: 15,
    marginBottom: 15,
  },
  buttonStyle: {
    maxWidth: '47%',
    minWidth: '47%',
    paddingHorizontal: 0,
  },
  clockTextStyle: {
    fontFamily: fonts.LIGHT,
    color: colors.brownishGreyTwo,
    fontSize: 12,
  },
});
