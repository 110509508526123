import { LocalVideoTrack, RemoteUser, useClientEvent, useJoin, useLocalCameraTrack, useLocalMicrophoneTrack, usePublish, useRTCClient, useRemoteUsers } from "agora-rtc-react";
import React, { useEffect, useState } from "react";
import WaitingRoom from "./WaitingRoom";
import APP from "../../../../../configs/app";
import colors from "../../../../../styles/colors";
import fonts from "../../../../../styles/fonts";
import CallControllerBar from "../../components/CallControllerBar";

const CallRoom = ({ channelId, session, sessionStarted, endSession, peerLeft }) => {
  const client = useRTCClient();
  const remoteUsers = useRemoteUsers();
  const [hasPeer, setHasPeer] = useState(false);
  const [isMicEnabled, setIsMicEnabled] = useState(true);
  const [isCamEnabled, setIsCamEnabled] = useState(true);

  const { localCameraTrack } = useLocalCameraTrack();
  const { localMicrophoneTrack } = useLocalMicrophoneTrack();

  usePublish([localCameraTrack, localMicrophoneTrack]);

  useEffect(() => {
    console.log(remoteUsers)
    if (remoteUsers.length)
      setHasPeer(true);
  }, [remoteUsers]);

  useJoin({
    appid: APP.AGORA_APP_ID,
    channel: channelId,
    token: null,
    uuid: null
  });

  useClientEvent(client, "user-left", () => {
    peerLeft();
  });

  const switchCamera = () => {}

  const hangUp = () => {
    endSession(true);
  }

  const toggleMic = () => {
    localMicrophoneTrack.setEnabled(!isMicEnabled);
    setIsMicEnabled(!isMicEnabled);
  }

  const toggleCam = () => {
    localCameraTrack.setMuted(isCamEnabled);
    setIsCamEnabled(!isCamEnabled);
  }

  return (sessionStarted || hasPeer) ? (
    <div style={styles.fullView}>
      <div style={styles.remote}>
        <RemoteUser user={remoteUsers[0]} playVideo={true} playAudio={true}/>
      </div>
      <div style={styles.selfBox}>
        <LocalVideoTrack track={localCameraTrack} play={true}/>
      </div>
      <CallControllerBar
        phoneType={'running'}
        endCall={hangUp}
        chatButton={false}
        toggleMic={toggleMic}
        toggleCam={toggleCam}
        isMicEnabled={isMicEnabled}
        isCamEnabled={isCamEnabled}
        switchCamera={switchCamera}
        userProId={0}
      />
    </div>
  ):(
    <WaitingRoom session={session} endSession={endSession}/>
  );
}

const styles = {
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 20,
  },
  remainingTimeBox: {
    flex: 1,
    alignSelf: 'flex-start',
    flexDirection: 'row',
  },
  remainingTimeLabel: {
    color: colors.white,
    fontFamily: fonts.REGULAR,
    fontSize: 14,
    fontWeight: '400',
  },
  remainingTimeText: {
    color: colors.white,
    fontFamily: fonts.REGULAR,
    fontSize: 31,
    fontWeight: '400',
  },
  middleBox: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 50,
  },
  messageBox: {
    marginTop: 20,
    textAlign: 'center',
    color: colors.white,
    fontFamily: fonts.REGULAR,
    fontSize: 18,
    fontWeight: '700',
    lineHeight: 24.5,
  },
  tmpBox: {
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
  },
  sheetHeader: {
    backgroundColor: colors.white,
    borderTopLeftRadius: 47,
    borderTopRightRadius: 47,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  sheetContent: {
    padding: 15,
    backgroundColor: colors.white,
    height: 370,
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingBottom: 40,
  },
  horBar: {
    height: 1,
    backgroundColor: colors.greyBlue,
    width: '50%',
    marginTop: -30,
  },
  sheetTopIcon: {
    padding: 10,
    borderRadius: 100,
    borderWidth: 1,
    backgroundColor: colors.white,
    justifyContent: 'center',
    alignItems: 'center',
    width: 100,
    height: 100,
    alignSelf: 'center',
    marginTop: -50,
  },
  sheetTopLeft: {},
  sheetTopRight: {
    marginRight: 25,
    marginTop: 25,
  },
  sheetText: {
    fontSize: 20,
    color: colors.black,
    fontFamily: fonts.REGULAR,
    fontWeight: '700',
    paddingHorizontal: 40,
    textAlign: 'center',
    marginTop: 15,
  },
  buttonsBox: {
    width: '100%',
  },
  rescheduleButton: {
    backgroundColor: colors.aquaMarineTwo,
    borderRadius: 4,
    paddingHorizontal: 15,
    paddingVertical: 10,
    justifyContent: 'center',
    height: 50,
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 10,
  },
  rescheduleButtonText: {
    fontSize: 14,
    color: colors.white,
    fontFamily: fonts.REGULAR,
    fontWeight: '700',
  },
  requestCancelButton: {
    backgroundColor: colors.white,
    borderWidth: 1,
    borderColor: colors.orangeyRed,
    borderRadius: 4,
    paddingHorizontal: 15,
    paddingVertical: 10,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 10,
    height: 50,
  },
  requestCancelButtonText: {
    fontSize: 14,
    color: colors.orangeyRed,
    fontFamily: fonts.REGULAR,
    fontWeight: '700',
  },
  cancelTextPolicy: {
    color: colors.greyBlue,
    fontSize: 12,
    fontFamily: fonts.REGULAR,
    fontWeight: '400',
  },
  sheetBottom: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: -50,
  },
  fullView: {
    width: '100vw',
    height: '100vh',
    position: 'absolute',
    backgroundColor: '#000000',
  },
  remote: {
    width: '100%',
    height: '100%',
    backgroundColor: 'green',
  },
  selfBox: {
    position: 'absolute',
    top: 10,
    right: 10,
    width: 93,
    height: 140,
    borderRadius: 5,
    borderWidth: 2,
    borderColor: 'grey',
    backgroundColor: 'pink'
  },
  self: {
    width: 93,
    height: 140,
    borderRadius: 5,
    flex: 1,
    backgroundColor: 'white',
  },
  no_video: {
    flex: 1,
    width: '100%',
    height: '100%',
    position: 'absolute',
    backgroundColor: 'rgba(0,0,0,.5)',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'pink' // remove
  },
  timeBox: {
    backgroundColor: colors.blackAlpha03,
    height: 86,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  buttonsBar: {
    flex: 1,
    padding: 20,
  },
  addTimeButton: {
    backgroundColor: colors.grey75,
    flexDirection: 'row',
    padding: 5,
    paddingHorizontal: 15,
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 4,
    marginLeft: 20,
  },
  addTimeButtonText: {
    marginLeft: 10,
    textAlign: 'center',
    color: colors.white,
    fontFamily: fonts.REGULAR,
    fontSize: 11,
    fontWeight: '700',
    lineHeight: 15,
    shadowColor: colors.white,
    shadowOffset: {
      width: 5,
      height: 5,
    },
    shadowOpacity: 0.1,
    shadowRadius: 5,
    elevation: 5,
  },
  timeLabel: {
    textAlign: 'center',
    color: colors.white,
    fontFamily: fonts.REGULAR,
    fontSize: 10,
    fontWeight: '400',
    lineHeight: 13.6,
  },
  curentTimeText: {
    color: colors.white,
    fontFamily: fonts.REGULAR,
    fontSize: 30,
    fontWeight: '400',
    lineHeight: 40.9,
    textAlign: 'right',
  },
  thumbBox: {
    shadowColor: colors.white,
    shadowOffset: {
      width: 5,
      height: 5,
    },
    shadowOpacity: 0.1,
    shadowRadius: 5,
    elevation: 5,
    marginLeft: 25,
    marginTop: -20,
    width: 63,
    height: 140,
  },
  thumbImage: {
    borderRadius: 5,
    height: 140,
    width: 92,
  },
  timeBoxRight: {
    alignItems: 'flex-end',
    flex: 1,
    justifyContent: 'flex-end',
  },
  denyButton: { backgroundColor: colors.white, width: '100%', borderColor: colors.lightgreyC9 },
  approveButton: { backgroundColor: colors.aquaMarine, width: '100%' },
  minText: { fontFamily: fonts.BOLD, alignSelf: 'flex-end', marginBottom: 8 },
  clientRequestTime: { color: colors.blueD8, fontSize: 38, fontFamily: fonts.REGULAR },
  rowCenterContainer: { flexDirection: 'row', alignItems: 'center' },
  requestedTimeContainer: {
    width: '100%',
    borderWidth: 0.5,
    padding: 8,
    borderRadius: 9,
    borderColor: colors.blueD8,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  closeRequestPopup: { alignSelf: 'flex-end', marginTop: 16 },
  dropDownContainer: {
    width: '60%',
    alignSelf: 'flex-start',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  remoteContainer: {
    width: '100%',
    height: 150,
    position: 'absolute',
    top: 5,
  },
};

export default CallRoom;

