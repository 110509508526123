const categories = {
  FOOD: 1,
  BODY: 2,
  MIND: 3,
};

export function setClasses(classes, categoryId) {
  switch (categoryId) {
    case categories.BODY:
      return {
        type: '@classes/SET_BODY_CLASSES',
        payload: { classes },
      };
    case categories.MIND:
      return {
        type: '@classes/SET_MIND_CLASSES',
        payload: { classes },
      };
    case categories.FOOD:
      return {
        type: '@classes/SET_FOOD_CLASSES',
        payload: { classes },
      };
  }
}

export function setFilters(filters, categoryId) {
  switch (categoryId) {
    case categories.BODY:
      return {
        type: '@classes/SET_BODY_FILTERS',
        payload: { filters },
      };
    case categories.MIND:
      return {
        type: '@classes/SET_MIND_FILTERS',
        payload: { filters },
      };
    case categories.FOOD:
      return {
        type: '@classes/SET_FOOD_FILTERS',
        payload: { filters },
      };
  }
}

export function fetchClasses(categoryId, userId) {
  return {
    type: '@classes/FETCH_CLASSES',
    payload: { categoryId, userId },
  };
}

export function fetchClassesByUrl(url, categoryId) {
  return {
    type: '@classes/FETCH_CLASSES_BY_URL',
    payload: { categoryId, url },
  };
}

export function setClassesToShow(classes) {
  return {
    type: '@classes/SET_CLASSES_TO_SHOW',
    payload: { classes },
  };
}

export function setLoadingClassesToShow(loading) {
  return {
    type: '@classes/SET_LOADING_CLASSES_TO_SHOW',
    payload: { loading },
  };
}

export function fetchClassesToShowByUrl(url, node) {
  return {
    type: '@classes/FETCH_CLASSES_TO_SHOW_BY_URL',
    payload: { url, node },
  };
}

export function setExplorerTabIndex(tabIndex) {
  return {
    type: '@classes/SET_EXPLORER_TAB_INDEX',
    payload: { tabIndex },
  };
}
