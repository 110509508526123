import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import colors from 'app/styles/colors';
import fonts from 'app/styles/fonts';
import LinearGradient from 'react-native-linear-gradient';
import { calcDurationNumber } from 'app/services/utils';

const CapsuleTimeToPrepare = ({ duration, currentTime, style }) => {
  return (
    <View style={[styles.border, style]}>
      <LinearGradient
        style={styles.container}
        angle={64}
        useAngle
        colors={[colors.aquaMarineTwo, colors.aquaMarineTwo, colors.shadowBlue]}>
        <Text style={styles.textCurrentTime}>Prepare-se, começa em</Text>
        <View>
          <Text style={styles.textDuration}>
            {calcDurationNumber(duration >= 0 ? duration : 0)}
          </Text>
        </View>
      </LinearGradient>
    </View>
  );
};

export default CapsuleTimeToPrepare;

const styles = StyleSheet.create({
  border: {
    borderRadius: 27,
    borderWidth: 2,
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: colors.aquaBlueDark,
  },
  container: {
    paddingHorizontal: 18,
    paddingVertical: 12,
    borderRadius: 25,
    width: 112,
    marginTop: -0.5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  textCurrentTime: {
    fontSize: 13,
    fontFamily: fonts.MEDIUM,
    lineHeight: 15,
    color: colors.white,
  },
  textDuration: {
    fontSize: 24,
    fontFamily: fonts.BOLD,
    color: colors.white,
  },
});
