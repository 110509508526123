import React, { useState, useEffect, useRef } from 'react';
import { View, StyleSheet, FlatList, Dimensions, ActivityIndicator, useWindowDimensions } from 'react-native';
import fonts from 'app/styles/fonts';
import colors from 'app/styles/colors';
import HeaderAquamarine from 'app/components/HeaderAquamarine';
import { useSafeArea } from 'react-native-safe-area-context';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';
// import { Actions } from 'react-native-router-flux';
import { sendActionEvent } from 'app/services/utils';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PERFORMANCE from 'app/configs/performance';
import { setNameShare } from 'app/store/modules/category/actions';
import SearchBarNew from 'app/components/SearchBarNew';
// import { , FAB } from 'react-native-paper';
import CardProgram from 'app/components/CardProgram';
import api from 'app/services/api';
import FilterProgramsScreen from 'app/screens/core/explorer/FilterProgramsScreen';
import { useNavigation } from '@react-navigation/native';
import { FAB } from 'react-native-paper';

const CategoriesIds = [
  { name: 'Alimentação', id: 1 },
  { name: 'Corpo', id: 2 },
  { name: 'Mente', id: 3 },
];
const DifficultyIds = [
  { name: 'Fácil', id: 1 },
  { name: 'Intermediário', id: 2 },
  { name: 'Difícil', id: 3 },
];


const ListProgramsScreen = ({ route }) => {
  const dispatch = useDispatch();

  const {
    title = 'Todos os programas',
    color,
    urlToFetch,
    category = null,
    isCalendar = false,
    node = true,
    type,
  } = route?.params;

  const navigation = useNavigation();
  
  const safeAreaInsets = useSafeArea();
  const { loadingClassesToShow } = useSelector(state => state.classes);
  const { profile } = useSelector(state => state.user);
  const [formattedClasses, setFormattedClasses] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [currentFilters, setCurrentFilters] = useState({
    categories: [
      { active: category == 1, name: 'Alimentação' },
      { active: category == 2, name: 'Corpo' },
      { active: category == 3, name: 'Mente' },
    ],
    subcategories: [],
    difficultyLevels: [
      { active: false, name: 'Fácil' },
      { active: false, name: 'Intermediário' },
      { active: false, name: 'Difícil' },
    ],
  });
  const [page, setPage] = useState(1);
  const [nextPage, setNextPage] = useState(false);
  const flatListRef = useRef();
  const [categoryId, setCategoryId] = useState(null);

  const windowDimensions = useWindowDimensions();

  useEffect(() => {
    requestPrograms();
  }, [page, currentFilters]);

  const handleLoadMore = () => {
    setPage(prev => prev + 1);
  };

  const requestPrograms = async () => {
    try {
      const formattedFilters = generateQueryParamsForFilter(currentFilters);
      const { data } = await api.get(
        `trails/filter?page=${page}&${formattedFilters}&like=${searchQuery}`
      );
      if (page === 1) {
        setFormattedClasses(formatDataForRender(data));
      } else {
        setFormattedClasses(prev => [...prev, ...formatDataForRender(data)]);
      }
      if (!data.links.nextPage) {
        setNextPage(false);
      } else {
        setNextPage(true);
      }
    } catch (error) {
      showAlert(errorControl(error));
    }
  };

  const generateQueryParamsForFilter = filters => {
    const queryList = Object.keys(filters).map(sectionName => {
      const activeOptions = filters[sectionName].filter(option => option.active);
      const activeOptionNames = activeOptions.map(option => option.name);
      const joinedOptionNames = activeOptionNames.join('|');
      if (sectionName === 'categories' && activeOptions.length) {
        const value = CategoriesIds.find(
          x => x.name === activeOptions.map(option => option.name)[0]
        ).id;
        return `${sectionName}=${value}`;
      }
      if (sectionName === 'difficultyLevels' && activeOptions.length) {
        const values = DifficultyIds.filter(d => activeOptionNames.includes(d.name)).map(d => d.id);
        return `${sectionName}=${values.join('|')}`;
      }
      return `${sectionName}=${joinedOptionNames}`;
    });
    return queryList.join('&');
  };

  const handleFilter = async filters => {
    try {
      setFilterModalOpen(false);
      setCategoryId(filters.categories.findIndex(x => x.active) + 1);
      flatListRef.current.scrollToOffset({ animated: false, offset: 0 });
      setCurrentFilters(filters);
      setPage(1);
    } catch (error) {
      showAlert(errorControl(error));
    }
  };

  useEffect(() => {
    if (filterModalOpen === false) {
      if (currentFilters && currentFilters.categories != null) {
        setCategoryId(currentFilters.categories.findIndex(x => x.active) + 1);
      } else {
        setCategoryId(null);
      }
    }
  }, [filterModalOpen]);

  const openTrailNew = useCallback((categoryIdFromTrail, id, typeFromTrail) => {
    if (categoryIdFromTrail === 1) {
      navigation.navigate('MealTrail', { mealTrailId: id });
      sendActionEvent('click_at_trail', {
        id: id,
        email: profile.email,
      });
    } else if (categoryIdFromTrail === 2) {
      navigation.navigate('Trail',{ trailId: id });
      sendActionEvent('click_at_fitness_trail', {
        id: id,
        email: profile.email,
      });
    } else if (categoryIdFromTrail === 3) {
      navigation.navigate('MeditationTrail',{ meditationId: id });
      sendActionEvent('click_at_meditation_trail', {
        id: id,
        email: profile.email,
      });
    }
  }, []);

  const formatDataForRender = data => {
    if (!data) {
      return [];
    }
    if (data.data) {
      return data.data;
    }
    return data.map(item => {
      if (item.class) {
        return item.class;
      }
      return item;
    });
  };

  const getGradientColor = categoryIdFromGradient => {
    if (categoryIdFromGradient === 1) {
      return 'red';
    }
    if (categoryIdFromGradient === 2) {
      return 'green';
    }
    return 'orange';
  };

  const getColorBase = () => {
    if (categoryId === 1) {
      return colors.darkRed;
    }
    if (categoryId === 3) {
      return colors.brightOrange;
    }
    return colors.aquaMarineTwo;
  };

  const getHeaderGradientColor = () => {
    if (categoryId === 1 || color === colors.grapefruit) {
      return [colors.grapefruit, colors.orangeyRed];
    }
    if (categoryId === 3 || color === colors.pastelOrange) {
      return [colors.orangeTwo, colors.orangeThree];
    }
    return [colors.aquaMarineTwo, colors.aquaMarineGrd];
  };

  const handleSearch = () => {
    requestPrograms();
  };

  return (
    <View style={{ height: '100dvh', backgroundColor: colors.paleGrey }}>
      <HeaderAquamarine
        title={title}
        textStyle={styles.headerText}
        useGradient
        gradientColors={getHeaderGradientColor()}
        color={color}
        onPress={navigation.goBack}
      />
      <View style={{ flex: 1, paddingTop: 20 }}>
        <View style={{ paddingHorizontal: 20, paddingBottom: 10 }}>
          <SearchBarNew value={searchQuery} onChange={setSearchQuery} onSearch={handleSearch} />
        </View>
        {loadingClassesToShow ? (
          <View style={styles.flexCenter}>
            <ActivityIndicator size="large" color={colors.aquaMarineTwo} />
          </View>
        ) : (
          <View style={{ alignItems: 'center', flex: 1 }}>
            <FlatList
              showsVerticalScrollIndicator={false}
              data={formattedClasses}
              ref={flatListRef}
              keyExtractor={item => item.id}
              numColumns={2}
              onEndReached={() => handleLoadMore()}
              style={{ width: '100%', paddingHorizontal: 18, paddingBottom: safeAreaInsets.bottom }}
              columnWrapperStyle={{ justifyContent: 'space-between' }}
              horizontal={false}
              ListFooterComponent={
                nextPage && (
                  <View style={styles.flexCenter}>
                    <ActivityIndicator size="small" color={colors.aquaMarineTwo} />
                  </View>
                )
              }
              renderItem={({ item }) => {
                return (
                  <CardProgram
                    type={item.subcategory_name}
                    title={item.name}
                    image={item.image_path}
                    difficulty={item.difficulty}
                    duration={item.duration_in_weeks} // in weeks
                    kcal={parseInt(item.calories, 10)}
                    plus={null}
                    minus={null}
                    paymentLevel={item.payment_level || item.trail_payment_level}
                    onPress={debounce(() => {
                      item.payment_level === 1 || item.trail_payment_level === 1
                        ? null //Actions.paywall()
                        : openTrailNew(item.category_id, item.id, 'activities');
                      dispatch(setNameShare(item.name));
                    }, PERFORMANCE.ON_PRESS_DEBOUNCE_TIME)}
                    size={ windowDimensions.width / 2 - 25}
                    style={{ marginBottom: 12 }}
                    textStyle={{ fontSize: 14 }}
                    gradientColor={getGradientColor(item.category_id)}
                    // goals={item?.goals ? item?.goals : []}
                    favorite={item.favorite}
                    id={item.id}
                  />
                );
              }}
            />
          </View>
        )}
        <FilterProgramsScreen
          open={filterModalOpen}
          onClose={() => setFilterModalOpen(false)}
          onPressFilter={handleFilter}
          onChangeCategory={setCategoryId}
          filters={currentFilters}
          color={colors.aquaMarineTwo}
          headerColors={getHeaderGradientColor()}
        />
        <FAB
          style={[styles.fab, { backgroundColor: getColorBase(), bottom: safeAreaInsets.bottom }]}
          color={colors.white}
          icon={require('app/images/filter.png')}
          onPress={() => {
            setFilterModalOpen(true);
          }}
        />
      </View>
    </View>
  );
};

export default ListProgramsScreen;

const styles = StyleSheet.create({
  headerText: {
    color: colors.white,
    fontSize: 18,
    marginRight: 60,
    position: 'relative',
    textAlign: 'left',
    fontFamily: fonts.BOLD,
  },
  flexCenter: { flex: 1, alignItems: 'center', paddingVertical: 15, justifyContent: 'center' },
  fab: {
    position: 'absolute',
    margin: 16,
    right: 0,
    bottom: 15,
  },
});
