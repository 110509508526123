import colors from 'app/styles/colors';
import fonts from 'app/styles/fonts';
import React from 'react';
import { Image, StyleSheet, Text, View } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import ButtonBaseIcon from 'app/components/ButtonBaseIcon';
import ShadowView from '@liuyunjs/react-native-simple-shadow-view';
import CustomIcon from 'app/styles/icon';

const CardWidgetNoData = ({
  onPress,
  imageStyle,
  gradientColors,
  color,
  title,
  description,
  image,
  buttonTitle,
}) => {
  return (
    <ShadowView style={styles.shadowContainer}>
      {gradientColors ? (
        <LinearGradient
          useAngle
          angle={135}
          colors={gradientColors || [colors.white, colors.white]}
          style={[styles.container]}>
          <Image source={image} style={[styles.image, imageStyle]}/>
          <View style={{ flex: 1, marginLeft: 20, justifyContent: 'center' }}>
            <View style={{width: '65%'}}>
              <View style={styles.professionalWrapper}>
                <Text style={styles.textProfessional}>{title}</Text>
              </View>
              <View>
                <Text style={styles.textContent}>{description}</Text>
              </View>
            </View>
            <ButtonBaseIcon
              useIcon
              backgroundColor={colors.white}
              onPress={onPress}
              icon={<CustomIcon name={'chevron_right'} color={color}/>}
              style={styles.buttonBase}
            />
          </View>
        </LinearGradient>
      ) : (
      <View>
        <View source={image} style={[styles.container, { backgroundColor: colors.white }]}>
          <View style={{ flex: 1, marginLeft: 20, justifyContent: 'center' }}>
            <View style={styles.professionalWrapper}>
              <Text style={[styles.textProfessional, { color: colors.darkPro }]}>{title}</Text>
            </View>
            <View>
              <Text style={[styles.textContent, { color: colors.darkPro }]}>{description}</Text>
            </View>
          </View>
          <Image source={image} style={[styles.image, imageStyle]} />
          <ButtonBaseIcon
            useIcon
            backgroundColor={color}
            onPress={onPress}
            icon={<CustomIcon name={'chevron_right'} color={colors.white} />}
            style={styles.buttonBase}
          />
        </View>
      </View>    
      )}
    </ShadowView>
  );
};

const styles = StyleSheet.create({
  shadowContainer: {
    marginHorizontal: 20,
    borderRadius: 10,
    backgroundColor: colors.white,
    shadowColor: colors.black,
    shadowOpacity: 0.1,
    shadowRadius: 5,
    shadowOffset: { width: 0, height: 5 },
  },
  container: {
    flex: 1,
    width: '100%',
    height: '100%',
    paddingVertical: 20,
    borderRadius: 10,
    position: 'relative',
    flexDirection: 'row',
    justifyContent: 'space-between',
    overflow: 'hidden',
  },
  image: {
    height: '100%',
    alignSelf: 'flex-end',
    // width: '50%',
    resizeMode: 'contain',
    aspectRatio: 1,
    // marginBott;om: -20,
    position: 'absolute',
    bottom: 0,
    right: 0
  },
  card: {
    marginTop: 23,
    marginLeft: 23,
    marginBottom: 9,
    borderRadius: 9.5,
    backgroundColor: colors.blackTwo + '1A',
    paddingVertical: 3,
    paddingHorizontal: 9,
    width: 75,
    alignItems: 'center',
  },
  buttonContainer: {
    position: 'absolute',
    justifyContent: 'flex-end',
    backgroundColor: 'red',
    width: '100%',
  },
  buttonBase: {
    position: 'absolute',
    zIndex: 200,
    height: 40,
    width: 40,
    alignSelf: 'center',
    right: 13,
    borderRadius: 10,
    shadowColor: colors.black,
    shadowOpacity: 0.2,
    shadowRadius: 5,
    shadowOffset: { width: 0, height: 5 },
  },
  professionalWrapper: {
    marginBottom: 6,
  },
  textProfessional: {
    fontFamily: fonts.BOLD,
    color: colors.white,
    fontSize: 18,
    lineHeight: 26,
  },
  textContent: {
    fontFamily: fonts.REGULAR,
    fontSize: 12,
    lineHeight: 17,
    color: colors.white,
    maxWidth: '60%'
  },
});

export default CardWidgetNoData;
