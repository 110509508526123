import React from 'react';

import { View, StatusBar, Image, Text, StyleSheet, TouchableOpacity } from 'react-native';
import TopFix from './TopFix';
import textStyles from '../styles/textStyles';
import chevronLeft from 'app/images/chevronLeft.png';
import colors from '../styles/colors';
import commons from '../styles/commons';
import { useNavigation } from '@react-navigation/native';

const HeaderWhite = ({ children, title, onPress, style }) => {
  const navigation = useNavigation();
  return <View>
    <StatusBar translucent backgroundColor="transparent" barStyle="dark-content" />
    <TopFix backgroundColor="white" />
    <View style={styles.container}>
      <TouchableOpacity hitSlop={commons.hitSlop} onPress={onPress || navigation.goBack}>
        <Image style={styles.icon} source={chevronLeft} />
      </TouchableOpacity>
      <Text style={{ ...textStyles.bold18, flex: 1 }}>{title}</Text>
      {children}
    </View>
  </View>
};

export default HeaderWhite;

const styles = StyleSheet.create({
  icon: { tintColor: colors.blackTwo, marginRight: 16,width: 24, height: 24 },
  container: {
    height: 56,
    backgroundColor: colors.white,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 20,
  },
});
