/* eslint-disable react-native/no-inline-styles */
import React from 'react';

import { Image, StyleSheet, TouchableOpacity } from 'react-native';
import colors from '../styles/colors';
import commons from '../styles/commons';
import ShadowView from '@liuyunjs/react-native-simple-shadow-view';

const adjust = (color, amount) => {
  try {
    return (
      '#' +
      color
        .replace(/^#/, '')
        .replace(/../g, color_ =>
          ('0' + Math.min(255, Math.max(0, parseInt(color_, 16) + amount)).toString(16)).substr(-2)
        )
    );
  } catch (e) {
    return '#FF0000';
  }
};

const ButtonBaseIcon = ({
  backgroundColor,
  containerStyle,
  onPress,
  fill,
  style,
  useIcon,
  icon,
  disabled,
}) => {
  const color = backgroundColor || colors.aquaMarine;
  const darken = adjust(color, -50);
  return (
    <ShadowView
        style={{
          ...styles.buttonEnter,
          backgroundColor: color,
          alignSelf: fill ? 'stretch' : 'center',
          ...style,
        }}>
        <TouchableOpacity
          hitSlop={commons.hitSlop}
          style={[containerStyle, { justifyContent: 'center' }]}
          underlayColor={darken}
          activeOpacity={0.6}
          onPress={onPress}>
        {useIcon ? icon : <Image source={icon} style={styles.icon} />}
    </TouchableOpacity>
      </ShadowView>
  );
};

const styles = StyleSheet.create({
  buttonEnter: {
    flexDirection: 'row',
    // marginTop: 20,
    paddingHorizontal: 16,
    width: 154,
    alignItems: 'center',
    justifyContent: 'center',
    height: 39,
    borderRadius: 4,
    shadowColor: colors.black,
    shadowOpacity: 0.08,
    shadowRadius: 8,
    shadowOffset: { width: 0, height: 8 },
  },
  icon: {
    width: 28,
    height: 28,
    resizeMode: 'contain',
  },
});

export default ButtonBaseIcon;
