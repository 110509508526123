import React, { useEffect, useState } from 'react';

import ShadowView from '@liuyunjs/react-native-simple-shadow-view';
import { View, StyleSheet, Dimensions, TouchableOpacity, Image } from 'react-native';
import moment from 'moment';
import WeekDay from './WeekDay';
import chevronLeft from 'app/images/chevronLeft.png';
import textStyles from 'app/styles/textStyles';
import { Text } from 'react-native-animatable';
import chevronRight from 'app/images/chevronRight.png';
import colors from 'app/styles/colors';
import { useDispatch } from 'react-redux';
import { updateProfileSuccess } from 'app/store/modules/user/actions';
import { getWaterIntakes } from 'app/services/apiWaterIntakes';

const { width } = Dimensions.get('screen');

const CalendarWeekSlide = ({ parentCallback, withMonth }) => {
  const dispatch = useDispatch();
  const [daysOfWeek, setDayOfWeek] = useState([1, 2, 3]);

  useEffect(() => {
    var startOfWeek = moment().startOf('isoWeek').isoWeekday(0);
    var endOfWeek = moment().endOf('isoWeek').isoWeekday(6);

    var days = [];
    var day = startOfWeek;

    while (day <= endOfWeek) {
      const isSameDay = moment(day).isSame(new Date(), 'day');
      days.push({ day: day.toDate(), isSelected: isSameDay });
      day = day.clone().add(1, 'd');
    }

    setDayOfWeek(days);
  }, []);

  const selectDay = index => {
    const daySelected = daysOfWeek.find((day, indexx) => {
      return index === indexx;
    });
    const newArray = daysOfWeek.map((day, indexx) => {
      if (index === indexx) {
        return { ...day, isSelected: true };
      }
      if (index !== indexx) {
        return { ...day, isSelected: false };
      }
    });
    //console.log(daySelected.day);
    parentCallback(daySelected.day);
    setDayOfWeek(newArray);
    requestWaterIntakesDay(daySelected.day);
  };

  const requestWaterIntakesDay = async day => {
    const user = {};
    const water_intakes_result = await getWaterIntakes(moment(day));
    user.sum_of_the_day = water_intakes_result.sum_of_the_day[0].ml
      ? water_intakes_result.sum_of_the_day[0].ml
      : 0;

    dispatch(updateProfileSuccess(user));
  };

  return (
    <ShadowView
      style={[
        styles.container,
        // eslint-disable-next-line react-native/no-inline-styles
        {
          height: withMonth ? 104 : 64,
          backgroundColor: withMonth ? '#FAFAFA' : colors.white,
        },
      ]}>
      {withMonth && (
        <View style={styles.month}>
          <TouchableOpacity>
            <Image source={chevronLeft} style={{ width: 20, height: 20 }} />
          </TouchableOpacity>

          <View>
            <Text style={[textStyles.semi16]}>{moment().format('MMMM')}</Text>
          </View>

          <TouchableOpacity>
            <Image source={chevronRight} style={{ width: 20, height: 20 }} />
          </TouchableOpacity>
        </View>
      )}
      <View style={styles.calendar}>
        {daysOfWeek.map((day, index) => {
          return (
            <WeekDay
              day={day}
              actionSelectDay={() => {
                selectDay(index);
              }}
            />
          );
        })}
      </View>
    </ShadowView>
  );
};

export default CalendarWeekSlide;

const styles = StyleSheet.create({
  calendar: {
    width: '100%',
    flex: 1,
    marginTop: 20,
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  container: {
    shadowColor: colors.blackTwo,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowRadius: 18,
    shadowOpacity: 0.16,
    zIndex: 66,
  },
  month: {
    marginTop: 10,
    width: '100%',
    height: 30,
    marginBottom: 10,
    alignItems: 'center',
    paddingHorizontal: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});
