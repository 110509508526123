import React, { useState, useEffect } from 'react';
import { View, StyleSheet, Text, TouchableOpacity, Image } from 'react-native';
import pt from 'app/i18n/src/locale/pt';
import { translate } from 'app/i18n/src/locale';
import colors from 'app/styles/colors';
import fonts from 'app/styles/fonts';
import WeeklyInternalScreen from './WeeklyInternalScreen';
import MonthlyInternalScreen from 'app/screens/core/hydration/MonthlyInternalScreen';
import { sendActionEvent } from '../../../services/utils';
import { useSelector } from 'react-redux';
import HeaderAquamarine from 'app/components/HeaderAquamarine';
import DiaryInternalScreen from './DiaryInternalScreen';

const HydrationScreen = () => {
  const { profile } = useSelector(state => state.user);
  const [activeTab, setActiveTab] = useState('diary');
  const [routes] = useState([
    { key: 'diary', title: translate(pt.daily) },
    { key: 'weekly', title: translate(pt.weekly) },
    { key: 'monthly', title: translate(pt.monthly) }, // TODO: fix monthly reports
  ]);

  const renderScene = () => {
    switch (activeTab) {
      case 'weekly':
        return <WeeklyInternalScreen />;
      case 'monthly':
        return <MonthlyInternalScreen />
      default:
        return <DiaryInternalScreen />
    }
  }

  const handlePressTab = async (route) => {
    sendActionEvent(`category_${route.key}`, {}, profile.email);
    setActiveTab(route.key);
  }

  useEffect(() => {
    sendActionEvent('hydration_open', { email: profile.email }, profile.email);
  }, []);

  return (
    <View style={styles.container}>
      <HeaderAquamarine title={translate(pt.hydration)} />
      <View style={styles.tabsContainer}>
        {routes.map(route => {
          return (
            <TouchableOpacity
              style={[
                { marginLeft: 20, height: '100%', justifyContent: 'center' }, 
                activeTab === route.key ? styles.activeTab : styles.inactiveTab
              ]}
              onPress={() => handlePressTab(route)}
            >
              <Text 
                style={[
                  styles.tabTitle, 
                  activeTab === route.key 
                    ? styles.activeTabTitle :
                    styles.inactiveTabTitle
                ]}>
                  {route.title}
                </Text>
            </TouchableOpacity>
          );
        })}
      </View>
      {renderScene()}
    </View>
  );
};

export default HydrationScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  indicatorStyle: {
    backgroundColor: colors.greyBlue,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    height: 4,
    left: 10,
  },
  tabsContainer: {
    flexDirection: 'row',
    backgroundColor: colors.white,
    height: 50,
    alignItems: 'center'
  },
  tabTitle: {
    fontFamily: fonts.BOLD,
    color: colors.black,
    size: 18,
  },
  inactiveTab: {
    borderBottomWidth: 3,
    borderBottomColor: colors.white
  },
  activeTab: {
    borderBottomWidth: 3,
    borderBottomColor: colors.black,
  },
  inactiveTabTitle: {
    opacity: 0.4
  },
  activeTabTitle: {
    opacity: 1
  }
});
