import React from 'react';

import { View, Text, TouchableOpacity, Image, StyleSheet } from 'react-native';

// import { Actions } from 'react-native-router-flux';
import textStyles from '../../../../styles/textStyles';
import { translate } from '../../../../i18n/src/locale';
import pt from '../../../../i18n/src/locale/pt';
import closeGrey from '../../../../images/closeGrey.png';
import ballFood from '../../../../images/ballFood.png';
import activityBall from '../../../../images/activityBall.png';
import meditationBall from '../../../../images/meditationBall.png';
import colors from '../../../../styles/colors';
import { sendActionEvent } from 'app/services/utils';
import { useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';

const MenuItem = ({ title, icon, onPress }) => (
  <TouchableOpacity style={styles.menu_item} onPress={onPress}>
    <Text style={textStyles.semi14}>{title}</Text>
    <Image source={icon} style={{ width: 24, height: 24 }} />
  </TouchableOpacity>
);

const BottomSheetAddSchedule = ({ onClose }) => {
  const navigation = useNavigation();
  const { profile } = useSelector(state => state.user);

  const categoryIds = {
    FOOD: 1,
    BODY: 2,
    MIND: 3,
  };

  const categories = [
    {
      id: categoryIds.FOOD,
      name: pt.feeding,
      color: colors.grapefruit,
      colors: [colors.orangeyRed, colors.grapefruit],
      borderColor: colors.redUltraLight,
    },
    {
      id: categoryIds.BODY,
      name: pt.activities,
      color: colors.aquaMarineTwo,
      colors: [colors.aquaMarineTwo, colors.aquaMarineGrd],
      borderColor: colors.aquaBlueDark,
    },
    {
      id: categoryIds.MIND,
      name: pt.meditation,
      color: colors.pastelOrange,
      colors: [colors.pastelOrange, colors.apricot],
      borderColor: colors.orangeUltraLight,
    },
  ];

  const getIcon = categoryId => {
    if (categoryId === 1) {
      return ballFood;
    }
    if (categoryId === 2) {
      return activityBall;
    }
    return meditationBall;
  };

  const renderRowCategory = () => {
    return categories.map((category, index) => {
      return (
        <MenuItem
          key={index}
          title={category.name}
          icon={getIcon(category.id)}
          onPress={() => {
            let text = category.name;
            var mapaAcentosHex = {
              a: /[\xE0-\xE6]/g,
              e: /[\xE8-\xEB]/g,
              i: /[\xEC-\xEF]/g,
              o: /[\xF2-\xF6]/g,
              u: /[\xF9-\xFC]/g,
              c: /\xE7/g,
              n: /\xF1/g,
              _: / /g,
            };
            for (var letra in mapaAcentosHex) {
              var expressaoRegular = mapaAcentosHex[letra];
              text = text.replace(expressaoRegular, letra);
            }
            sendActionEvent('click_add_new_item_in_agenda_' + text, {}, profile.email);
            navigation.navigate('ExplorerScheduleAdd', { isCalendar: true, categoryId: category.id });
            onClose();
          }}
        />
      );
    });
  };

  return (
    <View style={styles.bottomSheet}>
      <View style={styles.header}>
        <Text style={textStyles.subtitle}>{translate(pt.add_to_schedule)}</Text>
        <TouchableOpacity onPress={onClose}>
          <Image source={closeGrey} style={{ width: 18, height: 18 }} />
        </TouchableOpacity>
      </View>
      {renderRowCategory()}
    </View>
  );
};

export default BottomSheetAddSchedule;

const styles = StyleSheet.create({
  bottomSheet: { height: 245, backgroundColor: colors.white },
  header: {
    height: 60,
    paddingHorizontal: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: colors.paleGrey,
  },
  menu_item: {
    height: 53,
    borderTopWidth: StyleSheet.hairlineWidth,
    borderColor: colors.greyBlue22,
    paddingHorizontal: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: colors.white,
  },
});
