import ButtonBase from 'app/components/ButtonBase';
import colors from 'app/styles/colors';
import fonts from 'app/styles/fonts';
import { translate } from 'app/i18n/src/locale';
import React, { useCallback, useEffect, useState } from 'react';
import { Image, StyleSheet, Text, View } from 'react-native';
import pt from 'app/i18n/src/locale/pt';
import { debounce } from 'lodash';
import PERFORMANCE from 'app/configs/performance';
// import { Actions } from 'react-native-router-flux';
import apiNode from 'app/services/apiNode';
import { errorControl } from 'app/services/utils';
import ShadowView from '@liuyunjs/react-native-simple-shadow-view';
import { useNavigation } from '@react-navigation/native';

const CardSipatWidget = () => {
  
  const navigation = useNavigation();
  const [ sipatId, setSipatId ] = useState(null);

  useEffect(()=>{
    requestGetSipatCourseId();
  }, []);

  const requestGetSipatCourseId = useCallback(async () => {
    try {
      const { data } = await apiNode.get('meta/sipat_id');
      setSipatId(data.value);
    } catch (e) {
      console.log(errorControl(e));
    }
  }, []);

  return (
    <ShadowView style={styles.shadowContainer}>
      <View style={styles.container}>
        <View style={{ alignItems: 'flex-start', width: '60%', marginLeft: 23 }}>
          <View style={styles.card}>
            <Text style={styles.textNew}>{translate(pt.course)}</Text>
          </View>
          <View style={styles.sipatWrapper}>
            <Text style={styles.textProfessional}>{translate(pt.sipat_namu)}</Text>
          </View>
          <View>
            <Text style={styles.textContent} numberOfLines={4}>
              {translate(pt.sipat_widget_message)}
            </Text>
          </View>
        </View>
        <ButtonBase
          backgroundColor={colors.aquaMarineTwo}
          onPress={debounce(()=>{
            navigation.navigate('MasterclassDetail',{coursepress_class_id: sipatId, showDuration: true, isSipat: true})
          }, PERFORMANCE.ON_PRESS_DEBOUNCE_TIME)}
          style={styles.buttonBase}
          textStyle={{ color: colors.white }}
          title={translate(pt.access_course)}
        />
        <Image source={require('app/images/sipat_widget.png')} style={styles.sipatImage} />
      </View>
    </ShadowView>
  );
};

const styles = StyleSheet.create({
  shadowContainer: {
    borderRadius: 12,
    marginHorizontal: 20,
    backgroundColor: colors.white,
    shadowColor: colors.black,
    shadowOpacity: 0.1,
    shadowRadius: 5,
    shadowOffset: { width: 0, height: 5 },
  },
  container: {
    flex: 1,
    borderRadius: 10,
    paddingVertical: 17,
    backgroundColor: colors.white,
    overflow: 'hidden',
  },
  sipatImage: {
    position: 'absolute',
    zIndex: -1,
    right: 0,
    bottom: 0,
    height: 170,
    width: 170,
    resizeMode: 'contain',
  },
  textNew: {
    textTransform: 'uppercase',
    fontFamily: fonts.BOLD,
    color: colors.white,
    fontSize: 10,
  },
  card: {
    marginTop: 6,
    borderRadius: 10,
    backgroundColor: colors.aquaMarineTwo + '99',
    paddingVertical: 3,
    paddingHorizontal: 12,
    alignItems: 'center',
  },
  buttonBase: {
    height: 40,
    marginRight: 17,
    borderRadius: 10,
    alignSelf: 'flex-end',
    shadowColor: colors.black,
    shadowOpacity: 0.1,
    shadowRadius: 5,
    shadowOffset: { width: 0, height: 5 },
  },
  sipatWrapper: {
    marginVertical: 6,
  },
  textProfessional: {
    fontFamily: fonts.BOLD,
    color: colors.darkPro,
    fontSize: 21,
    lineHeight: 26,
  },
  textContent: {
    fontFamily: fonts.REGULAR,
    fontSize: 13,
    color: colors.darkPro,
  },
});

export default CardSipatWidget;
