import HeaderAquamarine from 'app/components/HeaderAquamarine';
import { translate } from 'app/i18n/src/locale';
import pt from 'app/i18n/src/locale/pt';
import apiNode from 'app/services/apiNode';
import colors from 'app/styles/colors';
import fonts from 'app/styles/fonts';
import React, { useEffect, useCallback, useState } from 'react';
import { StyleSheet, Text, ScrollView, View } from 'react-native';
import ShadowView from '@liuyunjs/react-native-simple-shadow-view';
import ButtonBase from 'app/components/ButtonBase';
import ButtonLink from 'app/components/ButtonLink';
import MyMultiSelectView from 'app/components/MyMultiSelectView';
import { errorControl, isObjectEmpty, showAlert } from 'app/services/utils';
import { useDispatch, useSelector } from 'react-redux';
import { fetchClasses, setFilters } from 'app/store/modules/classes/actions';
import _ from 'lodash';
import { ActivityIndicator } from 'react-native-paper';
import { useSafeArea } from 'react-native-safe-area-context';
import { useNavigation } from '@react-navigation/native';

const categories = {
  food: {
    id: 1,
    name: 'Alimentação',
  },
  body: {
    id: 2,
    name: 'Corpo',
  },
  mind: {
    id: 3,
    name: 'Mente',
  },
};

const FilterScreen = ({ route }) => {
  const { categoryId, headerColors = [], filterId, color } = route?.params;
  const navigation = useNavigation();

  const safeAreaInsets = useSafeArea();
  const dispatch = useDispatch();
  const { bodyFilters, mindFilters, foodFilters } = useSelector(state => state.classes);
  const { profile } = useSelector(state => state.user);
  const [initialFilters, setInitialFilters] = useState({});
  const [currentFilters, setCurrentFilters] = useState({});
  const [filterSectionNames, setFilterSectionNames] = useState([]);
  const [savedFilters] = useState([]);
  const [, setFormattedSavedFilters] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (categoryId === categories.body.id && !isObjectEmpty(bodyFilters)) {
      return setInitialFilters(bodyFilters);
    } else if (categoryId === categories.mind.id && !isObjectEmpty(mindFilters)) {
      return setInitialFilters(mindFilters);
    } else if (categoryId === categories.food.id && !isObjectEmpty(foodFilters)) {
      return setInitialFilters(foodFilters);
    }
    requestInitialFilters();
  }, [bodyFilters, categoryId, foodFilters, mindFilters, requestInitialFilters, dispatch]);

  useEffect(() => {
    const newFilterSectionNames = Object.keys(initialFilters).filter(name => name !== 'filter');
    setFilterSectionNames(newFilterSectionNames);
    setCurrentFilters(initialFilters);
    console.log(initialFilters)
  }, [initialFilters]);

  const requestInitialFilters = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await apiNode.get(
        `/classe-filters/?categoryId=${categoryId}&filterId=${filterId}`
      );

      if (categoryId !== categories.food.id && !!data.ingredients) {
        delete data.ingredients;
      }
      // eslint-disable-next-line no-extra-boolean-cast
      if (!!data.filter) {
        delete data.filter;
      }

      setInitialFilters(data);
    } catch (error) {
      showAlert(errorControl(error));
    }
    setLoading(false);
  }, [categoryId, filterId]);

  const handleSelectionChange = (status, index, sectionName) => {
    const newFilters = _.clone(currentFilters);

    const newSection = newFilters[sectionName].map((item, itemIndex) => {
      if (itemIndex !== index) {
        return item;
      }
      if (itemIndex === index) {
        const newValue = { ...item, ...{ active: status } };
        return newValue;
      }
    });

    newFilters[sectionName] = newSection;

    setCurrentFilters(newFilters);
  };

  const handlePressFilter = async () => {
    navigation.goBack();
    dispatch(setFilters({ ...currentFilters }, categoryId));
    dispatch(fetchClasses(categoryId, profile.user_id));
  };

  const desactiveCapsule = props => {
    return (
      <ShadowView style={[styles.card_choice, { borderColor: 'transparent' }]}>
        <Text style={[styles.text_choice, { color: colors.greyBlue }]}>{props.item.name}</Text>
      </ShadowView>
    );
  };

  const activeCapsule = props => {
    return (
      <ShadowView style={[styles.card_choice, { backgroundColor: color, borderColor: color }]}>
        <Text style={[styles.text_choice, { color: colors.white }]}>{props.item.name}</Text>
      </ShadowView>
    );
  };

  const handlePressClear = () => {
    let emptyFilters = { ...currentFilters };
    Object.keys(currentFilters).map(sectionName => {
      emptyFilters[sectionName] = emptyFilters[sectionName].map(option => {
        return { ...option, active: false };
      });
    });
    setCurrentFilters(emptyFilters);
  };

  const filterDescriptions = filter => {
    const fieldNames = Object.values(filter.values)
      .flat()
      .filter(item => item.active)
      .map(item => {
        return item.name;
      });
    return fieldNames.join(', ');
  };

  const formattedSavedFiltersToDropdown = savedFiltersFormatted => {
    return savedFiltersFormatted.map(filter => {
      return {
        ...filter,
        title: filter.name,
        description: filterDescriptions(filter),
      };
    });
  };

  useEffect(() => {
    const formattedFilters = formattedSavedFiltersToDropdown(savedFilters);
    setFormattedSavedFilters(formattedFilters);
  }, [savedFilters]);

  return (
    <View style={{ height: '100vh', backgroundColor: colors.paleGrey }}>
      <HeaderAquamarine
        title="Filtro"
        textStyle={styles.headerText}
        useGradient={headerColors.length > 1}
        gradientColors={headerColors}
        color={color}
      />
      <View style={{ flex: 1, paddingBottom: safeAreaInsets.bottom }}>
        <ScrollView style={styles.container}>
          {loading ? (
            <View style={styles.flexCenter}>
              <ActivityIndicator size="large" color={colors.aquaMarineTwo} />
            </View>
          ) : (
            <>
              {filterSectionNames.map(sectionName => (
                <View>
                  <Text style={styles.sectionTitle}>{translate(pt[sectionName])}</Text>
                  <View>
                    <MyMultiSelectView
                      checkedKey={'active'}
                      valueKey={'name'}
                      style={{ marginTop: 11, marginBottom: 30, flex: 1 }}
                      onSelectionStatusChange={(status, index) =>
                        handleSelectionChange(status, index, sectionName)
                      }
                      data={currentFilters[sectionName]}
                      desactiveView={desactiveCapsule}
                      activeView={activeCapsule}
                    />
                  </View>
                </View>
              ))}
            </>
          )}
        </ScrollView>
        <View style={styles.containerButtons}>
          <View style={{ flexDirection: 'row', justifyContent: 'space-around' }}>
            <ButtonBase
              title={translate(pt.filter)}
              backgroundColor={color}
              fill
              onPress={handlePressFilter}
            />
          </View>
          <View style={{ justifyContent: 'center', alignItems: 'center', marginVertical: 36 }}>
            <ButtonLink title={translate(pt.clean_filter)} underline onPress={handlePressClear} />
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  headerText: {
    color: colors.white,
  },
  container: {
    paddingHorizontal: 12,
    paddingTop: 16,
    flex: 1,
  },
  dropdown: {
    borderRadius: 30,
    paddingHorizontal: 32,
  },
  dropdownText: {
    fontFamily: fonts.REGULAR,
    fontSize: 12,
    color: colors.greyBlue,
  },
  sectionTitle: {
    fontSize: 16,
    color: colors.slateGrey,
    fontFamily: fonts.BOLD,
    textTransform: 'uppercase',
    marginBottom: 10,
  },
  card_choice: {
    borderWidth: 1,
    marginRight: 12,
    marginBottom: 8,
    height: 37,
    borderRadius: 30,
    backgroundColor: colors.white,
    alignContent: 'center',
    justifyContent: 'center',
    paddingHorizontal: 16,
  },
  text_choice: {
    fontSize: 12,
    fontFamily: fonts.REGULAR,
  },
  containerButtons: {
    paddingHorizontal: 12,
  },
  buttonClear: {
    backgroundColor: 'transparent',
    borderColor: colors.greyBlue,
    borderWidth: 1,
  },
  textButtonClear: {
    color: colors.greyBlue,
  },
  flexCenter: { flex: 1, alignItems: 'center', justifyContent: 'center' },
});

export default FilterScreen;
