/* eslint-disable react-native/no-inline-styles */
import React from 'react';

import { TouchableOpacity, Text, StyleSheet, Image, View } from 'react-native';
import textStyles from '../styles/textStyles';
import colors from '../styles/colors';

const ButtonBaseInvert = ({
  fill,
  style,
  title,
  onPress,
  disabledFake,
  useIcon,
  icon,
  iconStyle,
  iconTintColor,
  color = colors.aquaMarine,
  disabled = false,
}) => (
  <TouchableOpacity
    disabled={disabled}
    activeOpacity={0.5}
    style={{
      ...styles.buttonEnter,
      alignSelf: fill ? 'stretch' : 'center',
      borderColor: color,
      ...style,
      // ...{ opacity: disabledFake ? 0.5 : 1 },
      ...(disabledFake ? { backgroundColor: '#CCC' } : {}),
    }}
    onPress={onPress}>
    {icon &&
      (useIcon ? (
        <View style={{ marginRight: 10 }}>{icon}</View>
      ) : (
        <Image
          source={icon}
          style={{
            tintColor: iconTintColor,
            width: 20,
            height: 20,
            marginRight: 10,
            resizeMode: 'contain',
            ...iconStyle,
          }}
        />
      ))}
    <Text
      style={{
        ...textStyles.bold14White,
        color: color,
      }}>
      {title}
    </Text>
  </TouchableOpacity>
);

const styles = StyleSheet.create({
  buttonEnter: {
    //marginTop: 20,
    paddingHorizontal: 48,
    minWidth: 154,
    justifyContent: 'center',
    height: 39,
    borderRadius: 4,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default ButtonBaseInvert;
