import produce from 'immer';

const INITIAL_STATE = {
  active: false,
  uid: null,
  channelId: null,
  onTheTop: false,
};

export default function call(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@call/SET_CALL': {
        draft.uid = action.payload.uid;
        draft.channelId = action.payload.user;
        draft.active = true;
        break;
      }
      case '@call/UNSET_CALL': {
        draft.uid = null;
        draft.channelId = null;
        draft.active = true;
        break;
      }
      case '@call/ENABLE_VIDEO_CALL_ON_THE_TOP': {
        draft.onTheTop = true;
        break;
      }
      case '@call/DISABLE_VIDEO_CALL_ON_THE_TOP': {
        draft.onTheTop = false;
        break;
      }
      default:
        return state;
    }
  });
}
