/* eslint-disable react-native/no-inline-styles */
import CapsuleCalsNew from 'app/components/CapsuleCalsNew';
import CapsuleClockCard from 'app/components/CapsuleClockCard';
import CapsuleLevelNew from 'app/components/CapsuleLevelNew';
import FavoriteButtonShow from 'app/components/FavoriteButtonShow';
import MaskLevelPaymentNew from 'app/components/MaskLevelPaymentNew';
import TagCard from 'app/components/TagCard';
import api from 'app/services/api';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { ImageBackground, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import ShadowView from '@liuyunjs/react-native-simple-shadow-view';
import Minus from '../../../components/Minus';
import Plus from '../../../components/Plus';
import colors from '../../../styles/colors';
import fonts from '../../../styles/fonts';
import fallback_food from 'app/images/fallback_food3.jpg';
import fallback_fitness from 'app/images/fallback_fitness.jpg';
import fallback_mind from 'app/images/fallback_mind.jpg';

const CardClassesHorizontal = ({
  type,
  style,
  title,
  image,
  propType = 'classId',
  difficulty,
  duration = 5,
  durationInWeek,
  kcal,
  plus,
  minus,
  gradientColor = 'none',
  paymentLevel,
  onPress,
  onPressAdd,
  favorite,
  id,
  goals,
  tag,
}) => {
  const [thumbnail, setThumbnail] = useState({
    uri: image,
    headers: {
      Authorization: api.defaults.headers.Authorization,
    },
  });

  useEffect(() => {
    setThumbnail({
      uri: image,
      headers: {
        Authorization: api.defaults.headers.Authorization,
      },
    });
  }, [image]);

  const getGradientColor = () => {
    if (gradientColor === 'red') {
      return colors.orangeyRed40;
    }
    if (gradientColor === 'green') {
      return colors.aquaMarine40;
    }
    if (gradientColor === 'orange') {
      return colors.pastelOrange40;
    }
    return colors.transparent;
  };

  const handleFallbackImage = () => {
    if (gradientColor === 'red') {
      setThumbnail(fallback_food);
    } else if (gradientColor === 'green') {
      setThumbnail(fallback_fitness);
    } else {
      setThumbnail(fallback_mind);
    }
  };

  const getBackgroundColor = () => {
    if (gradientColor === 'red') {
      return colors.darkRed;
    }
    if (gradientColor === 'green') {
      return colors.aquaMarineTwo;
    }
    if (gradientColor === 'orange') {
      return colors.brightOrange;
    }
    return colors.transparent;
  };
  return (
    <TouchableOpacity activeOpacity={0.6} onPress={onPress} disabled={!onPress}>
      {plus && <Plus onPress={onPressAdd} style={{ marginTop: 30 }} />}
      {minus && <Minus onPress={onPressAdd} style={{ marginTop: 30 }} />}
      <ShadowView style={[styles.card, style]}>
        <View style={styles.containerWrapper}>
          <ImageBackground
            source={thumbnail}
            imageStyle={{
              borderRadius: 10,
            }}
            onError={currentTarget => {
              currentTarget.onError = null;
              handleFallbackImage();
            }}
            style={{
              overflow: 'hidden',
              height: 117,
              width: 119,
            }}>
            <LinearGradient
              colors={[colors.transparent, getGradientColor()]}
              style={styles.linear}
            />
          </ImageBackground>
          {paymentLevel === 1 && (
            <MaskLevelPaymentNew containerStyles={{ height: '100%', width: '100%' }} />
          )}
          <View style={[styles.white]}>
            <View style={styles.wrapper}>
              <View style={styles.capsuleWrapper}>
                {!!difficulty && !kcal && (
                  <CapsuleLevelNew
                    difficulty={difficulty}
                    style={{
                      backgroundColor: colors.white,
                      borderColor: '#D7D7D7',
                      borderWidth: 1,
                    }}
                  />
                )}
                {!!kcal && <CapsuleCalsNew style={{ marginLeft: 5 }} kcal={kcal} />}
                <CapsuleClockCard
                  style={{
                    backgroundColor: colors.white,
                    borderColor: '#D7D7D7',
                    borderWidth: 1,
                    marginLeft: 5,
                  }}
                  isWeek={durationInWeek}
                  duration={duration}
                />
              </View>
              {favorite != null && (
                <FavoriteButtonShow
                  isFavorited={favorite}
                  propType={propType}
                  itemId={id}
                  color={colors.lightGreyBlue}
                />
              )}
            </View>
            <Text numberOfLines={2} style={[styles.title]}>
              {title}
            </Text>
            {type && (
              <View style={styles.tagWrapper}>
                <View
                  style={[styles.typeWrapper, { backgroundColor: getBackgroundColor() + 'CC' }]}>
                  <Text style={styles.types} numberOfLines={1}>
                    {type}
                  </Text>
                </View>
                {!!tag && <TagCard style={{ marginLeft: 6 }} title={tag} />}
                {!!goals && goals.length > 0
                  ? goals
                      .slice(0, 1)
                      .map(goal => <TagCard style={{ marginLeft: 6 }} title={goal.goal_name} />)
                  : null}
              </View>
            )}
          </View>
        </View>
      </ShadowView>
    </TouchableOpacity>
  );
};

export default CardClassesHorizontal;

CardClassesHorizontal.propTypes = {
  minus: PropTypes.bool,
  onPress: PropTypes.func,
  plus: PropTypes.bool,
  style: PropTypes.any,
  title: PropTypes.string,
  type: PropTypes.oneOf(['meditation', 'activity', 'meal', 'masterclass']),
};

const styles = StyleSheet.create({
  linear: { position: 'absolute', width: 119, height: 117, borderRadius: 10 },
  title: {
    marginBottom: 8,
    fontFamily: fonts.BOLD,
    fontSize: 14,
    lineHeight: 17,
    color: colors.charcoalGreyTwo,
    minHeight: 34,
    width: '100%',
  },
  typeWrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
    paddingHorizontal: 8,
    paddingVertical: 4,
    alignSelf: 'flex-start',
    borderRadius: 4,
    marginTop: 'auto',
  },
  card: {
    height: 117,
    marginBottom: 15,
    flex: 1,
    backgroundColor: colors.white,
    flexDirection: 'row',
    borderRadius: 12,
    shadowColor: 'black',
    shadowOpacity: 0.1,
    shadowRadius: 5,
    shadowOffset: { width: 0, height: 5 },
  },
  capsuleWrapper: {
    flexDirection: 'row',
  },
  wrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
    position: 'relative',
  },
  containerWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    borderRadius: 10,
    overflow: 'hidden',
  },
  white: {
    paddingVertical: 13,
    paddingHorizontal: 12,
    flex: 1,
  },
  maskContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  types: {
    color: colors.white,
    fontSize: 9,
    fontFamily: fonts.BOLD,
  },
  tagWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});
