import colors from "app/styles/colors";
import fonts from "app/styles/fonts";
import React from 'react';
import { Image, StyleSheet, Text, TouchableOpacity, View } from "react-native";
// import SvgUri from 'react-native-svg-uri';

const styleSelected = {
  backgroundColor: colors.aquaMarineTwo2,
};

const styleNotSelected = {
  backgroundColor: colors.white,
};

const FilterOption = ({ title, img, iconColor = '#B9C2CD', onPress, isSelected = false }) => {
  return (
    <TouchableOpacity style={{ marginBottom: 10 }} onPress={onPress}>
      <View style={styles.filterOptionContainer}>
        <View style={[styles.filterOptionImageContainer, isSelected ? styleSelected : styleNotSelected]}>
          <Image
            source={{ uri: img }}
            style={[styles.filterOptionImg, { tintColor: isSelected ? 'white' : iconColor }]}
          />
        </View>
        <Text style={styles.filterOptionText}>{title}</Text>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  filterOptionContainer: {
    flex: 1,
    width: 65,
    height: 75,
    justifyContent: 'center',
    alignItems: 'center',
  },
  filterOptionImageContainer: {
    width: 40,
    height: 40,
    borderRadius: 40,
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center'
  },
  filterOptionImg: {
    width: 25,
    height: 25,
    resizeMode: 'contain'
    // padding: 10,
  },
  filterOptionText: {
    fontFamily: fonts.MEDIUM,
    fontSize: 8,
    marginTop: 8,
    height: 24,
    textAlign: 'center',
    color: colors.greyBlue,
  },
});

export default FilterOption;