import colors from 'app/styles/colors';
import fonts from 'app/styles/fonts';
import React from 'react';
import { StyleSheet, View, Modal as ReactNativeModal } from 'react-native';

const BottomModal = ({ open, onClose, children }) => {
  console.log(open)
  return (
    <ReactNativeModal transparent style={styles.container} visible={open} onBackdropPress={onClose}>
      <View style={styles.content}>
        <View style={styles.wrapper}>
          {children}
        </View>
        
      </View>
    </ReactNativeModal>
  );
};

export default BottomModal;

const styles = StyleSheet.create({
  container: {
    margin: 0,
  },
  wrapper: {
    alignItems: 'center',
    paddingTop: 42,
    paddingHorizontal: 28,
  },
  circleQuestion: {
    width: 122,
    height: 122,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 2,
    borderColor: colors.aquaMarineTwo,
    borderRadius: 122,
    shadowColor: colors.blackTwo,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowRadius: 18,
    shadowOpacity: 0.16,
    backgroundColor: 'white',
    elevation: 5,
    marginTop: -56,
  },
  content: {
    alignItems: 'center',
    backgroundColor: colors.white,
    marginTop: 'auto',
    height: 500,
    borderTopLeftRadius: 47,
    borderTopRightRadius: 47,
    paddingBottom: 40,
  },
  textStyle: {
    fontFamily: fonts.REGULAR,
    color: colors.greyLight4,
    fontSize: 14,
    paddingHorizontal: 20,
    textAlign: 'center',
  },
  textHeader: {
    fontFamily: fonts.BOLD,
    fontSize: 20,
    color: colors.aquaMarineTwo,
  },
  buttonOk: {
    width: 220,
    height: 50,
    marginTop: 52,
    zIndex: 1,
  },
});
