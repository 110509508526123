import React, { useRef } from 'react';
import { View, StyleSheet, Platform } from 'react-native';
import { WebView } from 'react-native-webview';

import pt from 'app/i18n/src/locale/pt';
import { translate } from 'app/i18n/src/locale';
import { useSafeArea } from 'react-native-safe-area-context';
import HeaderAquaIcon from 'app/components/HeaderAquaIcon';
import { useSelector } from 'react-redux';

const ManuChat = ({}) => {
  const safeAreaInsets = useSafeArea();
  const webView = useRef(null);

  const { token } = useSelector(state => state.auth);

  return (
    <>
      <View
        style={[
          styles.container,
        ]}>
        <HeaderAquaIcon title={translate(pt.manu_label)} />
        <View style={[styles.webview, { marginBottom: safeAreaInsets.bottom }]}>
          <WebView
            ref={webView}
            source={{ uri: 'https://business.namu.com.br/manu?hideHeader=1&token=' + token }}
            scalesPageToFit={true}
            automaticallyAdjustContentInsets={false}
            nestedScrollEnabled
            allowsInlineMediaPlayback
            originWhitelist={['*']}
            mediaPlaybackRequiresUserAction={false}
            startInLoadingState
            javaScriptEnabled
            domStorageEnabled
          />
        </View>
      </View>
    </>
  );
};

export default ManuChat;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
  webview: {
    flex: 1,
  },
});
