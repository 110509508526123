import colors from 'app/styles/colors';
import React from 'react';
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import ShadowView from '@liuyunjs/react-native-simple-shadow-view';
import NamuCoin from 'app/images/namu_coin.svg';
import PlusIcon from 'app/images/plus_circle.svg';
import fonts from 'app/styles/fonts';
import { translate } from 'app/i18n/src/locale';
import pt from 'app/i18n/src/locale/pt';
import commons from 'app/styles/commons';

const PersonalCreditsCard = ({ onPress, credits }) => {
  return (
    <View style={styles.container}>
      <Text style={styles.label}>{translate(pt.personal)}</Text>
      <ShadowView style={styles.shadowContainer}>
        <View style={styles.wrapper}>
          <Image source={NamuCoin} style={{width: 31, height: 31}} />
          <View style={[commons.flexRowEnd]}>
            <Text style={styles.quantity}>{credits || 0}</Text>
            <TouchableOpacity onPress={onPress}>
              <Image source={PlusIcon} style={{width: 21, height: 21}} />
            </TouchableOpacity>
          </View>
        </View>
      </ShadowView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    maxWidth: '50%',
  },
  shadowContainer: {
    backgroundColor: colors.white,
    borderRadius: 40,
    shadowColor: colors.black,
    shadowOpacity: 0.1,
    shadowRadius: 5,
    shadowOffset: { width: 0, height: 5 },
  },
  wrapper: {
    borderRadius: 45,
    padding: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: colors.white,
  },
  label: {
    fontFamily: fonts.MEDIUM,
    fontSize: 14,
    color: colors.darkPro2,
    marginLeft: 4,
    marginBottom: 6,
  },
  quantity: {
    fontFamily: fonts.BOLD,
    color: colors.darkPro,
    fontSize: 16,
    marginHorizontal: 9,
  },
});

export default PersonalCreditsCard;
