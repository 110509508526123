import React, { useState, useRef, useCallback, useEffect } from 'react';
import {
  View,
  ScrollView,
  StyleSheet,
  Text,
  Dimensions,
  ImageBackground,
  Image,
  TouchableOpacity,
  ActivityIndicator,
  Easing,
  Animated
} from 'react-native';
import Video from '@lnormanha/react-native-web-video/build/Video';
import Modal from 'react-native-modal';
import Slider from '@miblanchard/react-native-slider';

import HeaderAquamarine from 'app/components/HeaderAquamarine';
import { translate } from 'app/i18n/src/locale';
import pt from '../../../i18n/src/locale/pt';
import colors from 'app/styles/colors';
import textStyles from 'app/styles/textStyles';
import Stars from 'app/components/Stars';
import Icon from 'react-native-vector-icons/FontAwesome';

import CapsuleHour from 'app/components/CapsuleHour';
import ChipOutline from './components/ChipOutline';
import ButtonBase from 'app/components/ButtonBase';
import Comment from './components/Comment';

import playVideo from 'app/images/playButton.png';
import closeWhite from 'app/images/closeWhite.png';
import playIcon from 'app/images/white_play.png';
import fullScreenIcon from 'app/images/fullscreenIcon.png';
import fullScreenIcon2 from 'app/images/fullscreenIcon2.png';
import pauseIcon from 'app/images/pauseIcon.png';
import iconFavedEmpty from '../../../images/heartNotFaved.png';
import iconFavedFull from '../../../images/favedFull.png';
import RBSheet from 'react-native-raw-bottom-sheet';
import apiNode from 'app/services/apiNode';
import SchedulerBox from 'app/components/SchedulerBox';

import BottomSheetHourSelect from '../schedule/BottomSheetHourSelect';
import BottomSheetScheduling from '../schedule/BottomSheetScheduling';
import { useSelector, useDispatch } from 'react-redux';

import brFlag from '../../../images/bandeira.png';
import usFlag from '../../../images/usFlag.png';
import esFlag from '../../../images/esFlag.png';

import noPhotoImage from '../../../images/no-photo.jpg';

import fonts from 'app/styles/fonts';
import moment from 'moment';
import { normalize, RFValue, toCurrencyFormat } from 'app/services/utils';
import { doReloadFavorites } from 'app/store/modules/user/actions';
import BottomSheetInterested from 'app/screens/core/schedule/BottomSheetInterested';
import { useSafeArea } from 'react-native-safe-area-context';
import IconCorp from 'app/screens/core/professionalFilterResult/components/IconCorp';
import { useNavigation, useRoute } from '@react-navigation/native';

const { width } = Dimensions.get('screen');

const ProfessionalProfile = () => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const { userProId, openSchedule, openInterest } = useRoute().params

  const safeAreaInsets = useSafeArea();

  const [serviceId, setServiceId] = useState('');
  const [selectHour, setSelectHour] = useState('');

  const refRBSheet = useRef();
  const refHourSheet = useRef();
  const refInterestSheet = useRef();

  const selectService = useCallback(id => {
    setServiceId(id);
  }, []);

  const hourSetter = useCallback(hour => {
    setSelectHour(hour);
  }, []);

  const [url, setUrl] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [namuStaff, setNamuStaff] = useState(false);
  const [name, setName] = useState('');
  const [avatar, setAvatar] = useState('');
  const [bio, setBio] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [professionalNumber, setProfessionalNumber] = useState('');
  const [sessionDuration, setSessionDuration] = useState('');
  const [sessionPrice, setSessionPrice] = useState('');
  const [rating, setRating] = useState('');
  const [ratingCount, setRatingCount] = useState('');
  const [professionalCategories, setProfessionalCategories] = useState([]);
  const [professionalLanguages, setProfessionalLanguages] = useState([]);
  const [professionalExperiences, setProfessionalExperiences] = useState([]);
  const [professionalSpecialties, setProfessionalSpecialties] = useState([]);
  const [professionalFormation, setProfessionalFormation] = useState([]);
  const [professionalRatings, setProfessionalRatings] = useState([]);
  const [rescheduleTerms, setRescheduleTerms] = useState(null);

  const [schedulerState, setSchedulerState] = useState({
    calendarId: -1,
    selectedHour: '',
    selectedDate: moment(),
  });
  const [calendar, setCalendar] = useState(false);
  const [seeMoreExperiencies, setSeeMoreExperiencies] = useState(false);
  const [favorited, setFavorited] = useState(false);
  const { profile } = useSelector(st => st.user);
  const [loadingFav, setLoadingFav] = useState(false);

  const [isPartner, setIsPartner] = useState(false);

  const [discountPartner, setDiscountPartner] = useState(0);

  const [freeHoursItensQtd, setFreeHoursItensQtd] = useState(0);

  const [shouldOpenSchedule, setShouldOpenSchedule] = useState(openSchedule);

  const [shouldOpenInterest, setShouldOpenInterest] = useState(openInterest);
  const [haveFreeCalendar, setHaveFreeCalendar] = useState(false);
  const getClosestFree = useCallback(async () => {
    try {
      const { data } = await apiNode.get(`/calendar/get-closest-free?id=${userProId}`);
      if (data?.id) {
        setHaveFreeCalendar(true);
      }
    } catch (error) {
      console.log(error);
    }
  });
  let isLoading = false;
  const toggleHeart = async () => {
    if (isLoading) {
      return;
    }
    setLoadingFav(true);
    try {
      isLoading = true;
      if (!favorited) {
        const sendObject = {
          userId: profile.user_id,
          userProId: userProId,
        };

        const { data } = await apiNode.post('/user-favorites-user-pro', sendObject);
        setFavorited({ id: data.id });
      } else {
        try {
          await apiNode.delete(`/user-favorites-user-pro/${favorited.id}`);
          setFavorited(false);
        } catch (error) {
          console.log(error);
        }
      }
      dispatch(doReloadFavorites());
    } catch (error) {
      console.log(error);
    }
    setLoadingFav(false);
    isLoading = false;
  };

  async function hasSchedule() {
    try {
      await apiNode.get(`calendar/get-closest-free?id=${userProId}`);
      setCalendar(true);
    } catch (error) {
      setCalendar(false);
      console.log({ error });
    }
  }
  useEffect(() => {
    hasSchedule();
  }, []);

  useEffect(() => {
    if (schedulerState.calendarId !== -1) {
      setSelectHour(schedulerState.selectedHour);
      refHourSheet.current.open();
    }
  }, [schedulerState]);

  useEffect(() => {
    getProfile();
    getRatings();
    getTerms();
    getClosestFree();
  }, []);

  const [loading, setLoading] = useState({
    profile: true,
    ratings: true,
    terms: true,
  });

  const getDiscounts = async partnerDiscountId => {
    try {
      const { data } = await apiNode.get(`/partner-discount/by-user-pro/${userProId}`);

      setDiscountPartner(data.discount);
    } catch (error) {
      console.log('getDiscounts', userProId);
    }
  };

  const percentCustomerCalc = () => {
    const percent = discountPartner * 0.01;

    const value = Number(sessionPrice) * percent;

    return value;
  };

  const goToSchedule = (calendarId, datetime) => {
    navigation.navigate('SummaryScheduling', {
      userProId,
      calendarId,
      datetimeSession: datetime
    });
  };

  const getProfile = useCallback(async () => {
    try {
      const { data } = await apiNode.get(
        `/users-pro/${userProId}?userId=${profile.user_id}&qtd=10`
      );

      setNamuStaff(data.namuStaff);
      setName(data.name);
      setAvatar(data.profileImageUrl);
      setIsPartner(data.isPartner);
      getDiscounts(data?.partnerDiscountId);
      setBio(data.bio);
      setCity(data.city);
      setState(data.state);
      setProfessionalNumber(data.professionalNumber);
      setSessionDuration(data.sessionDurationMinutes);
      setSessionPrice(data.sessionHourPrice);
      setProfessionalExperiences(data.experiencies);
      setProfessionalSpecialties(data.specialities);
      //setProfessionalSpecialties(data.specialities);
      setRating(data.ratingStars);
      setRatingCount(data.ratingCount);
      setUrl(data.profileVideoUrl);
      setFavorited(data.userFavorite?.favorited ? data.userFavorite : false);
      setProfessionalCategories(data.categories[0].professionalCategory);
      setProfessionalFormation(data.academicHistories);

      setLoading(prevState => ({ ...prevState, profile: false }));
    } catch (error) {
      console.log(error);
    }
  }, [userProId]);

  const getRatings = async () => {
    try {
      const { data } = await apiNode.get(`session-feedbacks/to-user-pro/${userProId}?stars=4,5`);
      setProfessionalRatings(data);
    } catch (error) {
      console.log(error);
    }
    setLoading(prevState => ({ ...prevState, ratings: false }));
  };

  const getTerms = useCallback(async () => {
    try {
      const { data } = await apiNode.get('cancellation-policy');
      setRescheduleTerms(data[0]?.policy);
    } catch (error) {
      console.log(error);
    }
    setLoading(prevState => ({ ...prevState, terms: false }));
  }, []);

  function getFlagIcon(language) {
    if (language === 'Português') {
      return brFlag;
    } else if (language === 'Inglês') {
      return usFlag;
    } else if (language === 'Espanhol') {
      return esFlag;
    }
  }

  const [refHourSheetOpen, setRefHourSheetOpen] = useState(false);

  useEffect(() => {
    if (freeHoursItensQtd !== null) {
      if (refHourSheetOpen) {
        refHourSheet.current.open();
      } else {
        refHourSheet.current.close();
      }
    }
  }, [freeHoursItensQtd]);

  useEffect(() => {
    if (shouldOpenSchedule === true) {
      setRefHourSheetOpen(true);
      setShouldOpenSchedule(false);
      setTimeout(() => {
        refHourSheet.current.open();
      }, 500);
    }
  }, [shouldOpenSchedule]);

  useEffect(() => {
    if (shouldOpenInterest === true) {
      setShouldOpenInterest(false);
      setTimeout(() => {
        refInterestSheet.current.open();
      }, 500);
    }
  }, [shouldOpenInterest]);

  return (
    <View style={{ flex: 1, paddingBottom: safeAreaInsets.bottom }}>
      <View style={{ flex: 1, backgroundColor: colors.paleGrey }}>
        <View>
          <HeaderAquamarine title={translate(pt.profile)} />
        </View>
        {!loading.profile && !loading.ratings && !loading.terms ? (
          <ScrollView contentContainerStyle={{ paddingBottom: 30 }}>
            <VideoProfile
              urlVideo={url}
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
            />

            <View style={styles.container}>
              <ImageBackground
                source={avatar ? { uri: avatar } : noPhotoImage}
                style={styles.videoContainer}
                imageStyle={{
                  borderRadius: 9,
                  resizeMode: avatar ? 'cover' : 'contain',
                  backgroundColor: colors.white,
                }}>
                {url && (
                  <TouchableOpacity
                    onPress={() => {
                      setIsModalVisible(!isModalVisible);
                    }}>
                    <Image
                      source={playVideo}
                      style={{ height: 50, width: 50, paddingRight: 16, marginBottom: 16 }}
                    />
                  </TouchableOpacity>
                )}
              </ImageBackground>
              <View style={styles.header}>
                <View style={styles.personalData}>
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                    }}>
                    <Text
                      style={[
                        textStyles.bold20,
                        { textAlign: 'left', marginRight: normalize(10), width: normalize(175) },
                      ]}>
                      {name}
                    </Text>
                    {isPartner && profile.b2b_user && (
                      <IconCorp
                        style={{
                          paddingHorizontal: 8,
                          marginBottom: 6,
                        }}
                        userType="partner"
                      />
                    )}
                  </View>
                  <View style={styles.starsContainer}>
                    <Stars
                      disabled={true}
                      default={rating}
                      count={5}
                      spacing={6}
                      starSize={50}
                      style={styles.stars}
                      fullStar={<Icon name={'star'} size={24} color={colors.yellowOrange} />}
                      emptyStar={<Icon name={'star-o'} size={24} color={colors.yellowOrange} />}
                    />
                    <Text style={[textStyles.regular12grey, styles.ratingText]}>
                      ({ratingCount})
                    </Text>
                  </View>
                  <Text style={[textStyles.regular14, { color: colors.greyBlue }]}>
                    {`${professionalCategories?.name} ${
                      professionalSpecialties.length > 0
                        ? `Especialista em ${professionalSpecialties[0]?.speciality?.name}`
                        : ''
                    }`}
                  </Text>
                  <View style={{ 
                    flexDirection: 'row', 
                    marginTop: 0, 
                    borderBottomColor: 'rgba(0,0,0,0.1)', 
                    borderBottomWidth: 1,
                    paddingBottom: 10,
                    width: width - 40
                  }}>
                    <Text style={[textStyles.bold12, styles.blueText]}>
                      {professionalCategories?.registryName &&
                        `${professionalCategories?.registryName} ${professionalNumber}`}
                    </Text>
                    <Text> | </Text>
                    <Text style={[textStyles.regular11, styles.minorText]}>
                      {city} / {state}
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 35,
                    height: 35,
                  }}>
                  {!loadingFav ? (
                    <TouchableOpacity onPress={toggleHeart}>
                      <Image
                        source={favorited ? iconFavedFull : iconFavedEmpty}
                        style={{ height: 30, width: 32 }}
                      />
                    </TouchableOpacity>
                  ) : (
                    <ActivityIndicator size={'small'} color={colors.aquaMarine} animating />
                  )}
                </View>
              </View>
              <View style={{ height: 120 }} />
              <View style={styles.languageContainer}>
                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                  <Text style={textStyles.bold14}>{translate(pt.languages)}</Text>
                  <View style={styles.flags}>
                    {professionalLanguages.length > 0 ? (
                      professionalLanguages.map(lang => (
                        <Image
                          key={lang}
                          source={getFlagIcon(lang)}
                          style={{ height: 30, width: 30, borderRadius: 50, marginRight: 5 }}
                        />
                      ))
                    ) : (
                      <Image source={brFlag} style={{ height: 30, width: 30, borderRadius: 50, marginRight: 10 }} />
                    )}
                  </View>
                </View>
                <View style={styles.timerContainer}>
                  <CapsuleHour timeIn={'min'} duration={sessionDuration} style={styles.capsule} />
                </View>
              </View>
              <View style={styles.valuesContainer}>
                <View style={styles.valuesHeader}>
                  <Text style={!!isPartner && !!profile.b2b_user ? textStyles.regular14grey : textStyles.bold14}>{translate(pt.service_value)}</Text>
                  <View>
                    <Text style={textStyles.bold16grey}>R$ {sessionPrice}</Text>
                    {!!isPartner && !!profile.b2b_user && <View style={styles.line} />}
                  </View>
                </View>
                {isPartner && profile.b2b_user && (
                  <View>
                    <View style={styles.valuesHeader}>
                      <Text style={[textStyles.bold14, { color: colors.blueD8 }]}>
                        {translate(pt.valueForNamuBusiness)}
                      </Text>
                      {discountPartner ? (
                        <Text style={[textStyles.bold16grey, { color: colors.blueD8 }]}>
                          {translate(pt.prefix)}{' '}
                          {toCurrencyFormat(sessionPrice - percentCustomerCalc())}
                        </Text>
                      ) : (
                        <ActivityIndicator size="small" color={colors.blueD8} />
                      )}
                    </View>
                    <Text style={styles.descriptionNamuComission}>
                      {translate(pt.value_with_discount)}
                    </Text>
                  </View>
                )}
              </View>
              <View style={styles.experienceContainer}>
                <Text style={[textStyles.bold15]}>{translate(pt.experience)}</Text>
                <View style={styles.experienceChips}>
                  {professionalExperiences.length > 0 ? (
                    professionalExperiences
                      .slice(
                        0,
                        professionalExperiences.length > 5
                          ? seeMoreExperiencies
                            ? professionalExperiences.length
                            : 5
                          : professionalExperiences.length
                      )
                      .map(experience => (
                        <ChipOutline key={experience.id} title={experience.experiency.name} />
                      ))
                  ) : (
                    <Text style={textStyles.bold12}>{translate(pt.no_specialities)}</Text>
                  )}
                </View>

                {!seeMoreExperiencies && professionalExperiences.length > 5 && (
                  <TouchableOpacity
                    onPress={() => {
                      setSeeMoreExperiencies(true);
                    }}>
                    <Text style={[textStyles.bold12, styles.blueText]}>
                      {translate(pt.see_more)}
                    </Text>
                  </TouchableOpacity>
                )}
                {/* <ButtonBase style={styles.button} title={translate(pt.send_message)} /> */}
              </View>
              {!namuStaff && (
                <View style={styles.schedulerContainer}>
                  <SchedulerBox userProId={userProId} onSelected={goToSchedule} />
                </View>
              )}

              <Ratings professionalRatings={professionalRatings} />

              <Bio bio={bio} />
              <View style={styles.specialtiesContainer}>
                <View style={styles.containerShadowBorder}>
                  <Text style={[textStyles.bold15]}>{translate(pt.specialties)}</Text>
                </View>
                <View style={styles.specialties}>
                  {professionalSpecialties.length > 0 ? (
                    professionalSpecialties.map(spec => (
                      <ChipOutline key={spec.speciality.id} title={spec.speciality.name} />
                    ))
                  ) : (
                    <Text style={textStyles.bold12}>
                      Você não tem nenhum registro de especialidades 😟
                    </Text>
                  )}
                </View>
              </View>
              <View style={styles.qualificationContainer}>
                <View style={styles.containerShadowBorder}>
                  <Text style={[textStyles.bold15]}>{translate(pt.qualification)}</Text>
                </View>
                {professionalFormation.length > 0 ? (
                  professionalFormation.map(form => (
                    <View key={form.id} style={styles.academicContainer}>
                      <View style={{ flexDirection: 'row' }}>
                        <Text style={{ fontFamily: fonts.BOLD, color: colors.black }}>
                          {translate(pt.course)}:{' '}
                        </Text>
                        <Text style={{ fontFamily: fonts.REGULAR, color: colors.greyOpaque }}>
                          {form?.courseName}
                        </Text>
                      </View>
                      <View style={{ flexDirection: 'row' }}>
                        <Text style={{ fontFamily: fonts.BOLD, color: colors.black }}>
                          {translate(pt.institution)}:{' '}
                        </Text>
                        <Text style={{ fontFamily: fonts.REGULAR, color: colors.greyOpaque }}>
                          {form?.institutionName}
                        </Text>
                      </View>

                      {!form?.isInProgress && (
                        <View style={{ flexDirection: 'row' }}>
                          <Text style={{ fontFamily: fonts.BOLD, color: colors.black }}>
                            {translate(pt.conclusion_year)}:{' '}
                          </Text>
                          <Text style={{ fontFamily: fonts.REGULAR, color: colors.greyOpaque }}>
                            {form?.conclusionYear}
                          </Text>
                        </View>
                      )}
                    </View>
                  ))
                ) : (
                  <Text style={[textStyles.bold12, { marginTop: 12 }]}>
                    {translate(pt.no_academic_history)}
                  </Text>
                )}
              </View>

              <CancelText rescheduleTerms={rescheduleTerms} />
            </View>
          </ScrollView>
        ) : (
          <View style={styles.loadingContainer}>
            <ActivityIndicator size={'large'} color={colors.aquaMarineTwo} />
          </View>
        )}

        {!namuStaff && !loading.profile && !loading.ratings && !loading.terms && haveFreeCalendar && (
          <View style={styles.scheduleButtonContainer}>
            <ButtonBase
              // eslint-disable-next-line react-native/no-inline-styles
              style={{
                width: width,
                height: 43,
                borderRadius: 0,
                backgroundColor: calendar ? colors.aquaMarine : colors.cornflowerBlue,
              }}
              title={calendar ? translate(pt.schedule) : translate(pt.show_interest)}
              onPress={() => {
                setRefHourSheetOpen(true);
                refHourSheet.current.open();
              }}
            />
          </View>
        )}

        {!loading.profile && !loading.ratings && !loading.terms && !haveFreeCalendar && (
          <View style={styles.scheduleButtonContainer}>
            <ButtonBase
              style={styles.scheduleButton}
              backgroundColor={colors.cornflowerBlue}
              title={translate(pt.show_interest)}
              onPress={() => {
                refInterestSheet.current.open();
              }}
            />
          </View>
        )}
        <RBSheet
          ref={refRBSheet}
          height={320}
          customStyles={{ container: { borderTopEndRadius: 47, borderTopStartRadius: 47 } }}>
          <BottomSheetScheduling
            selectService={selectService}
            serviceId={serviceId}
            onClose={() => {
              refRBSheet.current.close();
              refHourSheet.current.open();
            }}
          />
        </RBSheet>
        <RBSheet
          ref={refInterestSheet}
          height={320}
          customStyles={{ container: { borderTopEndRadius: 47, borderTopStartRadius: 47 } }}>
          <BottomSheetInterested
            userProId={userProId}
            onClose={() => {
              refInterestSheet.current.close();
            }}
          />
        </RBSheet>
        <RBSheet
          ref={refHourSheet}
          height={RFValue(250 + Math.ceil(Number(freeHoursItensQtd) / 3) * 50)}
          customStyles={{ container: { borderTopEndRadius: 47, borderTopStartRadius: 47 } }}>
          <BottomSheetHourSelect
            setFreeHoursItensQtd={setFreeHoursItensQtd}
            userProId={userProId}
            hourSetter={hourSetter}
            selectedCalendarId={schedulerState.calendarId}
            hourSelected={selectHour}
            selectedDate={moment(schedulerState.selectedDate)}
            onClose={() => {
              setRefHourSheetOpen(false);
              refHourSheet.current.close();
            }}
          />
        </RBSheet>
      </View>
    </View>
  );
};

export default ProfessionalProfile;

const VideoProfile = ({ isModalVisible, urlVideo, setIsModalVisible }) => {
  const [url, setUrl] = useState(null);
  const [full, setFull] = useState(false);
  const [pause, setPause] = useState(false);
  const [progressVideo, setProgressVideo] = useState({});
  const [sliderValue, setSliderValue] = useState(0);
  const [maxSlider, setMaxSlider] = useState(0);
  const [seekVideo, setSeekVideo] = useState(0);
  const [animation, setAnimation] = useState(new Animated.Value(0));
  const [fadeAnimation, setFadeAnimation] = useState(new Animated.Value(1));
  const [videoLoading, setVideoLoading] = useState(false);

  useEffect(() => {
    if (urlVideo) {
      setUrl(urlVideo);
    }
  }, [urlVideo]);

  const setMaxTime = useCallback(
    time => {
      if (time) {
        setMaxSlider(+Number(time).toFixed(0));
      }
    },
    [maxSlider]
  );

  const currentTime = useCallback(
    time => {
      if (time) {
        setSliderValue(time);
      }
    },
    [progressVideo]
  );

  const rotate = () => {
    // Will change fadeAnim value to 1 in 5 seconds
    Animated.timing(animation, {
      toValue: 1,
      duration: 100,
      easing: Easing.linear,
      useNativeDriver: true,
    }).start();
  };
  const spin = animation.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '-90deg'],
  });

  const rotateBack = () => {
    // Will change fadeAnim value to 1 in 5 seconds
    Animated.timing(animation, {
      toValue: 0,
      duration: 100,
      easing: Easing.linear,
      useNativeDriver: true,
    }).start();
  };
  const fadeOut = () => {
    fadeIn();
    setTimeout(() => {
      Animated.timing(fadeAnimation, {
        toValue: 0,
        duration: 3000,
        easing: Easing.linear,
      }).start();
    }, 1000);
  };

  const fadeIn = () => {
    Animated.timing(fadeAnimation, {
      toValue: 1,
      duration: 100,
      easing: Easing.linear,
    }).start();
  };

  return (
    <Modal isVisible={isModalVisible} style={styles.modal}>
      <TouchableOpacity
        onPress={() => {
          setFull(false);
          setIsModalVisible(!isModalVisible);
          rotateBack();
          fadeOut();
        }}
        style={[
          styles.closeButton,
          {
            left: full ? 15 : null,
          },
        ]}>
        <Image source={closeWhite} />
      </TouchableOpacity>

      <View
        style={{
          flex: 1,
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Video
          source={{ uri: url }}
          style={{
            width: full ? '195%' : '90%',
            height: full ? '100%' : '30%',
          }}
          fullscreenAutorotate
          fullscreenOrientation={'portrait'}
          resizeMode={full ? 'contain' : 'contain'}
          paused={pause}
          rotation={full ? 270 : 0}
          onProgress={value => {
            setMaxTime(value?.seekableDuration);
            currentTime(value?.currentTime);
            setProgressVideo(value);
          }}
          onLoadStart={() => {
            setVideoLoading(true);
          }}
          onLoad={() => {
            setVideoLoading(false);
          }}
          seek={seekVideo}
          ignoreSilentSwitch="ignore"
        />

        {videoLoading && (
          <View
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 999,
            }}>
            <ActivityIndicator animating size={'large'} color={colors.white} />
          </View>
        )}
      </View>

      {full && (
        <TouchableOpacity
          hitSlop={{ bottom: 100, right: 100, left: 100, top: 100 }}
          onPress={() => {
            setPause(!pause);
            fadeOut();
          }}
          style={styles.playButtonCenter}>
          <Animated.View
            style={{
              opacity: fadeAnimation,
            }}>
            <Image source={pause ? playIcon : pauseIcon} style={styles.playImage} />
          </Animated.View>
        </TouchableOpacity>
      )}

      <Animated.View
        style={{
          position: 'absolute',
          right: full ? -height / 2.7 : 15,
          bottom: full ? height / 2.4 : 10,
          width: full ? height / 1.15 : '90%',
          transform: [{ rotate: spin }],
        }}>
        <View style={{ flexDirection: 'row', alignContent: 'center' }}>
          {!full && (
            <TouchableOpacity onPress={() => setPause(!pause)} style={{ flex: 0.1 }}>
              <Image source={pause ? playIcon : pauseIcon} style={styles.playImagePortrait} />
            </TouchableOpacity>
          )}

          <Text style={styles.progressVideoText}>
            {`${moment(progressVideo?.currentTime ? progressVideo?.currentTime * 1000 : 0).format(
              'mm:ss'
            )}/${moment(
              progressVideo?.seekableDuration ? progressVideo?.seekableDuration * 1000 : 0
            ).format('mm:ss')}`}
          </Text>
          <TouchableOpacity
            onPress={() => {
              if (full) {
                setFull(!full);
                rotateBack();
                fadeOut();
              } else {
                setFull(!full);
                rotate();
                fadeOut();
              }
            }}>
            <Image source={full ? fullScreenIcon2 : fullScreenIcon} />
          </TouchableOpacity>
        </View>

        <Slider
          value={sliderValue}
          minimumValue={0}
          maximumValue={maxSlider || 100}
          onValueChange={value => {
            setSeekVideo(value);
            setSliderValue(value);
          }}
          minimumTrackTintColor={colors.white}
          maximumTrackTintColor={colors.grey75}
          thumbImage={require('app/images/thumbSlider.png')}
          thumbStyle={styles.thumbStyle}
          trackStyle={styles.trackStyle}
          style={styles.slider}
        />
      </Animated.View>
    </Modal>
  );
};

const Ratings = ({ professionalRatings }) => {
  const [ratings, setRatings] = useState(0);

  useEffect(() => {
    if (professionalRatings) {
      setRatings(professionalRatings.slice(0, 5));
    }
  }, [professionalRatings]);

  return (
    <View style={styles.ratingContainer}>
      <View style={styles.containerShadowBorder}>
        <Text style={[textStyles.bold15]}>{translate(pt.rating)}</Text>
      </View>
      {ratings !== null && ratings.length > 0 ? (
        <>
          {ratings.map(rating => (
            <Comment
              key={rating.id}
              name={rating.session.user.name}
              rating={rating.ratingStars}
              avatar={rating.session.user.user_picture}
              date={rating.createdAt}
              text={rating.text}
            />
          ))}
        </>
      ) : (
        <Text style={[textStyles.bold12, { marginTop: 12 }]}>{translate(pt.no_ratings_yet)}</Text>
      )}
      {professionalRatings.length > 5 && professionalRatings.length !== ratings.length && (
        <TouchableOpacity
          onPress={() => {
            setRatings(professionalRatings);
          }}>
          <Text style={[textStyles.bold12, styles.blueText]}>{translate(pt.see_more)}</Text>
        </TouchableOpacity>
      )}
    </View>
  );
};

const Bio = ({ bio }) => {
  const [numLines, setNumLines] = useState(2);

  return (
    <View style={styles.bioContainer}>
      <View style={styles.containerShadowBorder}>
        <Text style={[textStyles.bold15]}>{translate(pt.aboutMe)}</Text>
      </View>
      <Text numberOfLines={numLines} style={[textStyles.regular12grey, styles.bioText]}>
        {bio}
      </Text>
      {numLines !== 0 && (
        <TouchableOpacity
          onPress={() => {
            setNumLines(0);
          }}>
          <Text style={[textStyles.bold12, styles.blueText]}>{translate(pt.see_more)}</Text>
        </TouchableOpacity>
      )}
    </View>
  );
};

const CancelText = ({ rescheduleTerms }) => {
  const [numLines, setNumLines] = useState(2);

  return (
    <View style={styles.reschedulingContainer}>
      <View style={styles.containerShadowBorder}>
        <Text style={[textStyles.bold15]}>{translate(pt.rescheduling_and_cancellation)}</Text>
      </View>
      <Text numberOfLines={numLines} style={[textStyles.regular12grey, styles.bioText]}>
        {rescheduleTerms ? rescheduleTerms : 'Carregando...'}
      </Text>
      {numLines !== 0 && (
        <TouchableOpacity
          onPress={() => {
            setNumLines(0);
          }}>
          <Text style={[textStyles.bold12, styles.blueText]}>{translate(pt.see_more)}</Text>
        </TouchableOpacity>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: 18,
    flex: 1,
    flexDirection: 'column',
    marginHorizontal: 18,
    borderRadius: 9,
  },
  videoContainer: {
    height: 228,
    borderRadius: 9,
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    paddingRight: 22,
    paddingBottom: 6,
  },
  descriptionNamuComission: {
    fontSize: normalize(11),
    color: '#000',
    fontFamily: fonts.LIGHT,
  },
  header: {
    flex: 0,
    flexDirection: 'row',
    marginVertical: 12,
    paddingBottom: 16,
  },
  personalData: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  starsContainer: {
    maxWidth: 140,
    marginVertical: 8,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  ratingText: {
    marginLeft: 8,
    fontSize: 16,
  },
  minorText: {
    color: colors.blueyGrey,
    fontSize: 12,
  },
  blueText: {
    color: colors.aquaMarineTwo,
  },
  languageContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  flags: {
    flexDirection: 'row',
    marginLeft: 10,
    alignItems: 'center',
  },
  capsule: {
    backgroundColor: '#f9f9f9',
  },
  valuesContainer: {
    flexDirection: 'column',
    marginTop: 8,
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0,0,0,0.1)',
    paddingBottom: 16,
  },
  valuesHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 14,
  },
  packageValue: {
    marginBottom: 8,
  },
  experienceContainer: {
    marginTop: 14,
    marginBottom: 14,
  },
  experienceChips: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    marginTop: 10,
  },
  button: {
    width: width - 36,
    backgroundColor: '#4969D8',
  },
  ratingContainer: {
    marginBottom: 14,
  },
  containerShadowBorder: {
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0,0,0,0.1)',
    paddingBottom: 14,
    marginTop: 14,
  },
  academicContainer: {
    marginVertical: 10,
  },
  line: {
    zIndex: 1000,
    position: 'absolute',
    backgroundColor: '#656565',
    height: normalize(1),
    width: '100%',
    top: normalize(12),
  },
  bioContainer: {
    marginBottom: 14,
  },
  bioText: {
    marginVertical: 14,
  },
  descriptionText: {
    marginTop: 14,
  },
  specialties: {
    marginTop: 14,
    marginBottom: 14,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  articles: {
    flexDirection: 'row',
    marginTop: 14,
  },
  schedulerContainer: {
    marginTop: 14,
    marginBottom: 14,
  },
  reschedulingContainer: {
    marginBottom: 34,
    marginTop: 14,
  },
  scheduleButtonContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
  },
  scheduleButton: {
    width: width,
    height: 43,
    borderRadius: 0,
  },
  loadingContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  playImagePortrait: { resizeMode: 'contain', width: 15, height: 15, tintColor: 'white' },
  modal: { flex: 1, margin: 0, justifyContent: 'center', alignItems: 'center' },
  slider: {
    flex: 1,
    height: 20,
    marginTop: 8,

    width: '100%',
  },
  thumbStyle: {
    backgroundColor: 'rgba(255,255,255,0)',
    width: 20,
    height: 21,
    alignItems: 'center',
  },
  trackStyle: {
    height: 6,
  },
  closeButton: {
    position: 'absolute',
    top: 15,
    right: 15,
    width: 34,
    height: 34,
    backgroundColor: colors.black02,
    borderRadius: 50,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 99,
  },
  playButtonCenter: {
    zIndex: 90,
    height: 50,
    width: 40,
    position: 'absolute',
  },
  playImage: {
    resizeMode: 'contain',
    width: 50,
    height: 50,
    transform: [{ rotate: '270deg' }],
  },
  progressVideoText: {
    color: 'white',
    flex: 1,
    fontFamily: fonts.MEDIUM,
    fontSize: 11,
  },
});
