import colors from 'app/styles/colors';
import React from 'react';
import { View } from 'react-native';

const CarouselSteps = ({ index, steps = 1, color = colors.aquaMarineTwo, style }) => {
  return (
    <View style={[{ flexDirection: 'row' }, style]}>
      {Array(steps)
        .fill(0)
        .map((_, i) => {
          return (
            <View
              style={{
                backgroundColor: index === i ? color : colors.greyBlue,
                opacity: index === i ? 1 : 0.15,
                borderRadius: 10,
                width: 7,
                height: 7,
                marginHorizontal: 4,
              }}
            />
          );
        })}
    </View>
  );
};

export default CarouselSteps;
